import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const RuleRankBox = () => {
  // 규정 접근이력 top5
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [ruleRanking, setRuleRanking] = useState([]);

  useEffect(() => {
    const ranking = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/log/rule/limit5`,
        });
        const ruleCopy = res.data.map(el => ({
          _id: el._id,
          ruleName: el.ruleName,
          count: el.count,
          topUsers: el.topUsers,
        }));
        setRuleRanking(ruleCopy);
      } catch (err) {
        console.log(err);
      }
    };
    ranking();
  }, []);

  return (
    <div className='dateSearch__ruleRanking'>
      <span>{t('userlog.ruleRank.title')}</span>
      <div>
        <Table>
          <thead>
            <tr>
              <th width='70%'>{t('userlog.ruleRank.ruleName')}</th>
              <th width='20%'>{t('userlog.ruleRank.accessTop3')}</th>
              <th width='10%'>{t('userlog.ruleRank.views')}</th>
            </tr>
          </thead>
          {ruleRanking &&
            ruleRanking.map(el => (
              <tbody key={el._id}>
                <tr>
                  <td>{el.ruleName}</td>
                  <td>
                    {el.topUsers
                      .map(user => user.username)
                      .slice(0, 3)
                      .join(', ')}
                  </td>
                  <td>{el.count}</td>
                </tr>
              </tbody>
            ))}
        </Table>
      </div>
    </div>
  );
};

export default RuleRankBox;
