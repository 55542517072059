import { useContext, useEffect } from 'react';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import { toast } from 'react-toastify';
import NotificationContext from '../../store/NotificationProvider';
import useAuth from './useAuth';
import useAxiosAuth from './useAxiosAuth';

const EventSource = NativeEventSource || EventSourcePolyfill;

const useNotification = () => {
  const { setNotis } = useContext(NotificationContext);
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    const notificationUpdate = async () => {
      await axiosAuth
        .get(`/api/approval/all/notification/${auth.employno}`)
        .then(res => {
          setNotis(prev => ({
            ...prev,
            data: res.data,
            count: res.data.length,
          }));
        })
        .catch(err => {
          if (err) {
            console.log(err);
          }
        });
    };
    if (auth.accessToken) {
      notificationUpdate();
    }
    // sse 연결 담당
    // 데이터 업데이트 각 컴포넌트에서 처리할 것
    const sse = new EventSource(
      `${process.env.REACT_APP_ORIGIN_URL}/api/stream`,
      {
        withCredentials: true,
      }
    );

    sse.addEventListener('approval', e => {
      const jsonData = e.data;
      const notiArr = JSON.parse(jsonData);
      const notiData = notiArr.find(noti => noti.employno === auth.employno);

      if (!notiData) {
        return;
      }
      toast(notiData.content, {
        position: 'bottom-right',
        closeOnClick: true,
        autoClose: false,
        hideProgressBar: false,
        draggable: true,
      });
      notificationUpdate();
    });

    sse.onerror = error => {
      if (error) {
        sse.close();
      }
    };

    if (!auth.accessToken) {
      return sse.close();
    }

    return () => {
      sse.close();
    };
  }, [auth]);

  return useContext(NotificationContext);
};

export default useNotification;
