import React from 'react';

const DevChapContents = ({ chapterContents }) => {
  return (
    <div className='draft__contents__chapterinfo__contents'>
      <div style={{ fontSize: '1.2rem' }}>{chapterContents.header}</div>
      <div style={{ paddingLeft: '1vw' }}>
        {chapterContents.children.map(child => (
          <div key={`keyin_${child}`}>{child}</div>
        ))}
      </div>
    </div>
  );
};

export default DevChapContents;
