import Form from 'react-bootstrap/Form';
import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { Button, InputGroup } from 'react-bootstrap';
import '../scss/SearchBar.scss';

const SearchBar = ({ value, onChange, searchText, handleKeyDown }) => {
  const { t } = useTranslation();
  return (
    <InputGroup className='SearchBar'>
      <Form.Control
        value={value}
        onChange={onChange}
        className='SearchBar__input'
        placeholder={t('searchbar.placeholder')}
        aria-label="Recipient's username"
        aria-describedby='basic-addon2'
        onKeyDown={handleKeyDown}
      />
      <Button className='SearchBar__btn' onClick={searchText}>
        <BsSearch className='SearchBar__btn__icon' />
        {/* 검색 */}
      </Button>
    </InputGroup>
  );
};

export default SearchBar;
