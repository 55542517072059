import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SortChaptersDnD from './SortChaptersDnD';
import useModRule from '../../../common/hooks/useModRule';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';

const SortChapters = () => {
  const { modRule, setModRule } = useModRule();
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  const [chs, setChs] = useState([]);
  const [apps, setApps] = useState([]);
  const [delChs, setDelChs] = useState([]);
  const [delApps, setDelApps] = useState([]);

  useEffect(() => {
    const { refRule, modChapterRef } = modRule;
    const newMods = modChapterRef.filter(mod => mod.modType === 'new');
    const replaceMods = modChapterRef.filter(mod => mod.modType === 'replace');
    const originChaps = refRule
      ? refRule.chapterRef.filter(
          chap =>
            !replaceMods.some(modChap => modChap.oldChapterId === chap._id)
        )
      : [];
    const chaptersArr = [...newMods, ...replaceMods, ...originChaps];
    const chsCopy = chaptersArr
      .filter(chap => chap.chapterType === 'CH')
      .sort((a, b) => a.chapterIdx - b.chapterIdx);
    setChs(chsCopy);

    const appsCopy = chaptersArr
      .filter(chap => chap.chapterType === 'APP')
      .sort((a, b) => a.chapterIdx - b.chapterIdx);
    setApps(appsCopy);

    const delChsArr = modChapterRef.filter(
      mod => mod.modType === 'delete' && mod.chapterType === 'CH'
    );
    setDelChs(delChsArr);

    const delAppsArr = modChapterRef.filter(
      mod => mod.modType === 'delete' && mod.chapterType === 'APP'
    );
    setDelApps(delAppsArr);
  }, [modRule]);
  // 방법 1 : chapters 그대로 쓴다.
  // 방법 2 : useState 배열로 3개

  const submitSortChapters = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/modrule/amendsort',
        data: {
          modRuleId: modRule._id,
          chs,
          apps,
        },
      });
      setModRule(prev => ({ ...prev, ...res.data, sortOpen: false }));
    } catch (err) {
      console.log(err);
    }
  };

  const closeSortChapters = () => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
  };

  return (
    <>
      <div
        className='sortchapter__container'
        style={{ overflowY: 'auto', maxHeight: '76vh', padding: '1vh 1vw' }}>
        <div
          className='sortchapter__title'
          style={{
            height: '4vh',
            lineHeight: '4vh',
            borderBottom: '2px solid navy',
          }}>
          CH
        </div>
        <div className='sortchapter__listwrapper'>
          <SortChaptersDnD chapters={chs} setChapters={setChs} />
        </div>
        {delChs?.length > 0 && (
          <>
            <div
              style={{
                height: '4vh',
                lineHeight: '4vh',
                borderBottom: '2px solid red',
              }}>
              CH {t('modrule.deleted-details')}
            </div>
            <div
              style={{
                height: '5vh',
                display: 'flex',
                gap: '1vh',
                alignItems: 'center',
              }}>
              {delChs.map(el => (
                <span
                  key={el._id}
                  style={{ border: '2px solid red', padding: '5px 10px' }}>
                  {el.chapterNumber}
                </span>
              ))}
            </div>
          </>
        )}
        <div
          className='sortchapter__title'
          style={{
            height: '4vh',
            lineHeight: '4vh',
            borderBottom: '2px solid navy',
          }}>
          APP
        </div>
        <div
          className='sortchapter__listwrapper'
          // style={{ padding: '0.5vh 0.5vw' }}
        >
          <SortChaptersDnD chapters={apps} setChapters={setApps} />
        </div>
        {delApps?.length > 0 && (
          <>
            <div
              className='sortchapter__title'
              style={{
                height: '4vh',
                lineHeight: '4vh',
                borderBottom: '2px solid red',
              }}>
              APP {t('modrule.deleted-details')}
            </div>
            <div>
              {delApps.map(el => (
                <span
                  key={el._id}
                  style={{ border: '2px solid red', padding: '5px 10px' }}>
                  {el.chapterNumber}
                </span>
              ))}
            </div>
          </>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1vh' }}>
        <Button onClick={submitSortChapters}>{t('button.edit')}</Button>
        <Button onClick={closeSortChapters}>{t('button.back')}</Button>
      </div>
    </>
  );
};

export default SortChapters;
