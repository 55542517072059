import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import '../../scss/ManagerAuth/ManagerAuthList.scss';
import { TiChevronRight } from 'react-icons/ti';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const AuthRuleList = forwardRef(
  ({ targetCategory, setTargetCategory, searchStr }, ref) => {
    const { t } = useTranslation();
    const [sidenav, setSidenav] = useState([]);
    const [filternav, setFilternav] = useState([]);
    const axiosAuth = useAxiosAuth();
    useEffect(() => {
      const controller = new AbortController();
      const fetchSideNav = async () => {
        const response = await axiosAuth({
          method: 'GET',
          url: '/api/category/all/kor',
        });
        setFilternav(response.data);
        setSidenav(response.data);
      };
      fetchSideNav();
      return () => {
        controller.abort();
      };
    }, []);

    const contentFilter = () => {
      const reg = new RegExp(searchStr, 'gi');
      if (searchStr === '') {
        setFilternav(sidenav);
      } else {
        const navCopy = sidenav.filter(
          el => el.category.match(reg) || el.categoryNumber.match(reg)
        );
        setFilternav(navCopy);
      }
    };

    useImperativeHandle(ref, () => ({
      contentFilter,
    }));

    const handleCategory = cate => {
      const { categoryNumber, category, sortNum } = cate;
      setTargetCategory(prev => ({
        ...prev,
        categoryNumber,
        category,
        sortNum,
      }));
    };
    return (
      <div className='ManagerAuthList'>
        <div className='ManagerAuthList__rulelistTitle'>
          <span>{t('managerauth.rule')}</span>
        </div>
        <div className='Authcollapse'>
          <div>
            {filternav &&
              filternav.map(cate => (
                <div
                  className={`Authcollapse__item ${
                    targetCategory.categoryNumber === cate.categoryNumber
                      ? 'select'
                      : ''
                  }`}
                  key={cate._id}
                  aria-hidden='true'
                  onClick={() => handleCategory(cate)}>
                  <span className='Authcollapse__title'>
                    {cate && `[${cate.categoryNumber}] : ${cate.category}`}
                  </span>
                  {targetCategory.categoryNumber === cate.categoryNumber && (
                    <span>
                      <TiChevronRight size='10' />
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
);
export default AuthRuleList;
