import React, { useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import useModRule from '../../../common/hooks/useModRule';
import useModChapter from '../../../common/hooks/useModChapter';
import '../../../scss/ModRule/EnactRuleAddChapter.scss';
import useAxiosMultipart from '../../../common/hooks/useAxiosMultipart';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import CustomFileInput from '../../DatePicker/FileInput';

const AmendRuleAddFile = () => {
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const { t, i18n } = useTranslation();

  const handlePreviewUpload = async e => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/modrule/filepreview',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setModChapter(prev => ({
        ...prev,
        uri: res.data.url,
        fileType: res.data.docType,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const submitDeleteChapter = async () => {
    if (window.confirm(t('modrule.del-chap-q'))) {
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/dev/oldchapterfile/new',
          data: {
            oldRuleId: modRule.oldRule._id,
            chapterId: modChapter._id,
            modRuleId: modRule._id,
          },
        });
        if (response.status === 200) {
          toast(t('modrule.del-chap'));
          setModChapter({
            fileType: '',
            modType: '',
            content: '',
            uri: '',
            chapterIdx: 0,
            chapterNumber: '',
            chapterName: '',
            chapterContents: {
              header: '',
              children: [],
            },
          });
          setModRule(prev => ({ ...prev, ...response.data }));
        }
      } catch (error) {
        toast(t('modrule.del-chap-error'));
      }
    } else {
      toast(t('modrule.del-chap-cancel'));
    }
  };

  const submitNewFile = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('rulePath', modRule.ruleNumber);
    formData.append('modRuleId', modRule._id);
    formData.append('oldRuleId', modRule.oldRule._id);
    formData.append('chapterType', modChapter.chapterType);
    formData.append('chapterNumber', modChapter.chapterNumber);
    formData.append('chapterName', modChapter.chapterName);
    formData.append('lan', modRule.lan);
    formData.append('file', file);

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/dev/oldchapterfile/del',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setModRule(prev => ({ ...prev, ...res.data }));
      setModChapter({
        fileType: '',
        modType: '',
        chapterType: '',
        content: '',
        uri: '',
        chapterIdx: 0,
        chapterNumber: '',
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  const submitAmendFile = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('rulePath', modRule.ruleNumber);
    formData.append('modRuleId', modRule._id);
    formData.append('oldRuleId', modRule.oldRule._id);
    formData.append('currChapterId', modChapter._id);
    formData.append('chapterType', modChapter.chapterType);
    formData.append('sortNum', modChapter.sortNum);
    formData.append('chapterIdx', modChapter.chapterIdx);
    formData.append('lan', modRule.lan);
    formData.append('chapterNumber', modChapter.chapterNumber);
    formData.append('chapterName', modChapter.chapterName);
    formData.append('file', file);

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/dev/oldchapterfile/replace',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setModRule(prev => ({ ...prev, ...res.data }));
      setModChapter({
        fileType: '',
        modType: '',
        chapterType: '',
        content: '',
        uri: '',
        chapterIdx: 0,
        chapterNumber: '',
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      setUploading(false);
    } catch (err) {
      console.log(err);
      setUploading(false);
    }
  };

  if (modRule.viewType === 'contents') {
    return (
      <div>
        {i18n.language === 'ko' ? (
          <>
            <p>텍스트 타입의 규정에는 파일 챕터를 등록할 수 없습니다.</p>
            <p>
              규정타입을
              <span style={{ color: 'orange' }}> 파일</span> 혹은
              <span style={{ color: 'orange' }}> 혼합</span>으로 변경하고
              등록해주세요.
            </p>
          </>
        ) : (
          <>
            <p>You cannot register file chapters for text-type regulations.</p>
            <p>
              Please change the regulation type to
              <span style={{ color: 'orange' }}> File</span> or
              <span style={{ color: 'orange' }}> Mixed</span> and register it.
            </p>
          </>
        )}
      </div>
    );
  }

  return (
    <div className='addfile'>
      <div className='addfile__side'>
        <div className='addfile__side__submitbtn'>
          {(() => {
            if (modChapter.modType !== 'new') {
              return (
                <>
                  <Button style={{ width: '100%' }} onClick={submitAmendFile}>
                    {uploading && (
                      <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </Spinner>
                    )}
                    <span className='chapBtnSvg'>
                      <MdOutlineKeyboardDoubleArrowLeft />
                      <MdOutlineKeyboardDoubleArrowLeft />
                    </span>
                    {t('modrule.amend-chap')}
                  </Button>
                  <Button
                    style={{ width: '100%' }}
                    onClick={submitDeleteChapter}>
                    <span className='chapBtnSvg'>
                      <MdOutlineKeyboardDoubleArrowLeft />
                      <MdOutlineKeyboardDoubleArrowLeft />
                    </span>
                    {t('modrule.add-chap')}
                  </Button>
                </>
              );
            }
            return (
              <Button style={{ width: '100%' }} onClick={submitNewFile}>
                <span className='chapBtnSvg'>
                  <MdOutlineKeyboardDoubleArrowLeft />
                  <MdOutlineKeyboardDoubleArrowLeft />
                </span>
                {t('modrule.del-chap-btn')}
              </Button>
            );
          })()}
        </div>
      </div>
      <div className='addfile__fileinput'>
        <div>
          <Form.Group
            controlId='formFileMultiple'
            encType='multipart/form-data'
            className='mb-3 formContentTop__form'>
            {/* <Form.Control
              type='file'
              name='file'
              multiple
              onChange={handlePreviewUpload}
            /> */}
            <CustomFileInput
              type='file'
              name='file'
              multiple
              onChange={handlePreviewUpload}
              style={{ width: '100%' }}
            />
            <Form.Text className='text-muted'>
              {t('modrule.extension')} : doc / docx / xls / xlsx / pdf
            </Form.Text>
          </Form.Group>
        </div>
        {modChapter?.uri && modChapter.uri !== '' && (
          <DocViewer
            key='modChapter_preview'
            id='modChapter_previewviewer'
            prefetchMethod='GET'
            documents={[modChapter]}
            pluginRenderers={DocViewerRenderers}
            config={{ header: { disableHeader: true } }}
            className='docviewer__container'
          />
        )}
      </div>
    </div>
  );
};

export default AmendRuleAddFile;
