import React, { createContext, useState, useMemo } from 'react';

const DcnContext = createContext();
// 1개로 처리해보기
export const DcnProvider = ({ children, initialState }) => {
  const [dcn, setDcn] = useState(initialState);
  const [cir, setCir] = useState(initialState);
  const value = useMemo(() => ({ dcn, setDcn, cir, setCir }), [dcn, cir]);

  return <DcnContext.Provider value={value}>{children}</DcnContext.Provider>;
};

export default DcnContext;
