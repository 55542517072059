import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import '../../scss/Mobile/MobileRule/MobileRuleSearch.scss';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import parse from 'html-react-parser';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const parsingRuleResult = (result, searchText) => {
  const regex = new RegExp(searchText, 'i');

  const ruleIndex = result.ruleName
    .toLowerCase()
    .indexOf(searchText.toLowerCase());

  const originruleStr = result.ruleName.slice(
    ruleIndex,
    ruleIndex + searchText.length
  );

  return result.ruleName.replace(regex, `<mark>${originruleStr}</mark>`);
};

const parsingChapterContent = result => {
  const { texts } = result.highlights[0];
  const partContent = texts
    .slice(0, 3)
    .map((text, i) => {
      if (text.type === 'hit') {
        return `<mark>${text.value
          .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
          .replace(/&nbsp;/g, '')}</mark>`;
      }
      if (text.type === 'text' && text.value.length > 30) {
        if (i === 0) {
          return text.value
            .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
            .replace(/&nbsp;/g, '')
            .slice(-45);
        }
        return text.value
          .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
          .replace(/&nbsp;/g, '')
          .slice(0, 45);
      }
      return text.value
        .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
        .replace(/&nbsp;/g, '');
    })
    .join('');

  return partContent;
};

const ResultTr = ({ result, searchText }) => {
  // chapter_____________________________________________________
  const ruleNumber = result.rules?.[0]?.ruleNumber;
  const ruleName = result.rules?.[0]?.ruleName;
  const refCategory = result.rules?.[0]?.refCategory?.category;
  const { chapterNumber } = result;
  const fileTypePath = result.fileType; // 'content' || 'mix'
  // ____________________________________________________________

  // 컬렉션이 rule 형식
  if (result.search === 'rule') {
    return (
      <tr>
        <td width='40%' align='left'>
          <p style={{ fontSize: '0.7rem' }}>{result.refCategory.category}</p>
          <span>
            <MdSubdirectoryArrowRight />
            {result.ruleNumber && `[${result.ruleNumber}] ${result.ruleName}`}
          </span>
        </td>
        <td width='60%' align='left'>
          {result.ruleNumber && (
            <NavLink
              to={
                fileTypePath === 'content'
                  ? `/contents/${result.ruleNumber}`
                  : `/mix/${result.ruleNumber}`
              }
              style={{ textDecoration: 'none', color: 'black' }}>
              <span style={{ textOverflow: 'ellipsis' }}>
                {parse(parsingRuleResult(result, searchText))}
              </span>
            </NavLink>
          )}
        </td>
      </tr>
    );
  }

  // 컬렉션 chapter 형식
  return (
    <tr>
      <td width='40%' align='left'>
        <p style={{ fontSize: '0.7rem' }}>{refCategory}</p>
        <span>
          <MdSubdirectoryArrowRight />
          {ruleNumber && `[${ruleNumber}] ${ruleName}`}
        </span>
      </td>
      <td width='60%' align='left'>
        {ruleNumber && chapterNumber && (
          <NavLink
            to={
              fileTypePath === 'content'
                ? `/contents/${ruleNumber}/${chapterNumber}/${searchText}`
                : `/mix/${ruleNumber}/${chapterNumber}/${searchText}`
            }
            style={{ textDecoration: 'none', color: 'black' }}>
            <span style={{ textOverflow: 'ellipsis' }}>
              {parse(parsingChapterContent(result))}
            </span>
          </NavLink>
        )}
      </td>
    </tr>
  );
};

const MobileRuleSearch = () => {
  const { searchText } = useParams();
  const axiosAuth = useAxiosAuth();
  const [results, setResults] = useState([]); // 검색 결과
  const [isLoading, setIsLoading] = useState(false);
  const [filterType, setFilterType] = useState('all'); //! 필터 상태
  const [filterTypeLength, setFilterTypeLength] = useState({
    rule: '',
    chapterContent: '',
    chapterName: '',
  });
  const { t } = useTranslation();

  // filter type map 배열
  const filterTypes = [
    { id: 'all', name: t('table.search-result'), count: results.length },
    { id: 'rule', name: t('table.rule-title'), count: filterTypeLength.rule },
    {
      id: 'chapterContent',
      name: t('table.rule-content'),
      count: filterTypeLength.chapterContent,
    },
    {
      id: 'chapterName',
      name: t('table.chap-title'),
      count: filterTypeLength.chapterName,
    },
  ];

  const filterResults = () => {
    switch (filterType) {
      case 'rule': // 규정명
        return results.filter(result => result.search === 'rule');
      case 'chapterContent': // 규정내용
        return results.filter(result => result.search === 'chapterContent');
      case 'chapterName': // 챕터제목
        return results.filter(result => result.search === 'chapterName');
      default:
        return results;
    }
  };

  // 필터된 랜더링 리스트 길이
  useEffect(() => {
    const ruleLength = results.filter(
      result => result.search === 'rule'
    ).length;
    const chapterContentLength = results.filter(
      result => result.search === 'chapterContent'
    ).length;
    const chapterNameLength = results.filter(
      result => result.search === 'chapterName'
    ).length;

    setFilterTypeLength({
      rule: ruleLength,
      chapterContent: chapterContentLength,
      chapterName: chapterNameLength,
    });
  }, [results]);

  useEffect(() => {
    let isMounted = true; // 마운트 상태 추적 변수

    if (searchText !== '') {
      const searchRule = async () => {
        setIsLoading(true);
        try {
          const response = await axiosAuth({
            method: 'POST',
            url: '/api/rule/searchrule',
            data: { searchText },
          });
          if (isMounted) {
            // 마운트된 상태 -> 업데이트
            setResults(response.data);
            setIsLoading(false);
          }
        } catch (error) {
          console.error(error);
          if (isMounted) {
            // 마운트된 상태 -> 에러
            setIsLoading(false);
          }
        }
      };
      searchRule();
    }
    // + 클린업 함수,,, 마운트 상태 false
    return () => {
      isMounted = false;
    };
  }, [searchText]);

  return (
    <div className='mobileRuleSearch'>
      <div className='mobileRuleSearch__filterTabs'>
        {filterTypes.map(el => (
          <div
            key={el.id}
            role='button'
            tabIndex='0'
            onClick={() => setFilterType(el.id)}
            onKeyDown={e => e.key === 'Enter' && setFilterType(el.id)}
            style={{
              cursor: 'pointer',
              padding: '10px',
              color: filterType === el.id ? '#F18546' : '',
            }}>
            {el.name} : <span>{el.count}</span>
          </div>
        ))}
      </div>

      <div className='mobileRuleSearch__main'>
        <table className='mobileRuleSearch__main__table'>
          <thead className='mobileRuleSearch__main__table__header'>
            <tr>
              <th width='40%'>{t('table.rule-title')}</th>
              <th width='60%'>{t('table.search-content')}</th>
            </tr>
          </thead>
          {isLoading ? (
            <tbody className='mobileRuleSearch__main__loading'>
              <tr>
                <td colSpan='7'>
                  <div className='mobileRuleSearch__main__loading__spinner'>
                    <Spinner
                      animation='border'
                      role='status'
                      style={{
                        width: '4rem',
                        height: '4rem',
                      }}>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className='mobileRuleSearch__main__table__body'>
              {results &&
                filterResults().map(result => (
                  <ResultTr
                    key={`${result.ruleRef}-${result._id}-S${
                      result.chapterId
                    }-${result.chapterNumber}-${new Date().getTime()}`}
                    result={result}
                    searchText={searchText}
                  />
                ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default MobileRuleSearch;
