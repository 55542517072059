import React from 'react';
import { useTranslation } from 'react-i18next';

const ModRuleCirDefault = () => {
  const { t } = useTranslation();

  return <div className='modcirdefault'>{t('modrule.select-cir')}</div>;
};

export default ModRuleCirDefault;
