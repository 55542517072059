import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import '../../scss/RevStatus/RevStatusMain.scss';

const RevStatusMain = () => {
  const { t } = useTranslation();
  return (
    <div className='revstatus mainsection'>
      <h4 className='revstatus__title'>{t('dcn.title')}</h4>
      <Outlet />
    </div>
  );
};

export default RevStatusMain;
