import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { RiDownload2Fill } from 'react-icons/ri';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAuth from '../../common/hooks/useAuth';
import Roles from '../../common/auth/Roles';

const TeamManualOne = () => {
  const { t } = useTranslation();
  const iframeRef = useRef(null);
  const { orgId, manualNumber } = useParams();
  const { auth } = useAuth();
  const navi = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [currentManual, setCurrentManual] = useState({ _id: '', url: '' });
  const [writerInfo, setWriterInfo] = useState({ _id: '', username: '' });
  const [oldManual, setOldManual] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [oldNote, setOldNote] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const fetchManual = async () => {
      try {
        setLoading(true);
        const res = await axiosAuth.get(`/api/manual/${manualNumber}`);
        const { manual, oldmanual } = res.data;
        const manualCopy = {
          ...manual,
          manualNum: manual.manualNumber
            .replace(manual.orgId._id, '')
            .replace('-', ''),
          orgName: manual.orgId.name,
          lastdate: manual.createdAt.slice(0, 10),
        };
        setCurrentManual(manualCopy);
        setWriterInfo(manual.writer);
        setOldManual(oldmanual);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchManual();

    return () => {
      controller.abort();
    };
  }, [manualNumber]);

  const downloadManualOne = async m => {
    try {
      const resDown = await axiosAuth({
        method: 'POST',
        url: '/api/manual/download',
        responseType: 'blob',
        data: {
          url: m.url,
        },
      });
      const blob = new Blob([resDown.data]);
      const fileName = `${m.manualNumber
        .replace(currentManual.orgId._id, '')
        .replace('-', '')} ${m.manualName}.${m.docType}`;
      const downUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downUrl);
    } catch (err2) {
      console.log(`error resDown : ${err2}`);
    }
  };

  const handleMouseEnter = el => {
    setOldNote(el);
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const submitDeleteManual = async () => {
    if (window.confirm(t('components.teamManual.manual-del-q'))) {
      try {
        const resDel = await axiosAuth({
          method: 'POST',
          url: '/api/manual/delete',
          data: {
            manualId: currentManual._id,
          },
        });
        if (resDel.status === 200) {
          toast(
            <div>
              {t('components.teamManual.manual-del')}
              <br />
              {t('components.teamManual.manual')}: {resDel.data.manualName}
            </div>
          );
          navi(`/teammanual/${orgId}`);
        }
      } catch (err2) {
        console.log(`error resDel : ${err2}`);
      }
      navi(`/teammanual/${orgId}`);
    } else {
      toast(t('components.teamManual.manual-cancel'));
    }
  };

  return (
    <>
      <div className='teamManual__fakeheader'>
        <h4>
          {`${currentManual.orgName} ${currentManual.manualNum} ${currentManual.manualName}`}{' '}
          <Button
            onClick={() => {
              downloadManualOne(currentManual);
            }}>
            {t('button.file-down')}
          </Button>
          {(auth?.roles?.includes(Roles.ADMIN) ||
            auth?.roles?.includes(Roles.DEVELOPER) ||
            auth?.roles?.includes(Roles.TEAMMANUAL)) &&
          auth.org === orgId ? (
            <>
              <Button
                type='submit'
                onClick={() => {
                  navi(`/teammanual/manualone/${manualNumber}/update`);
                }}>
                {t('button.update')}
              </Button>
              <Button
                onClick={() => {
                  navi(`/teammanual/manualone/${manualNumber}/edit`);
                }}>
                {t('button.edit')}
              </Button>
              <Button type='submit' onClick={submitDeleteManual}>
                {t('button.delete')}
              </Button>
            </>
          ) : null}
        </h4>
      </div>
      <div className='teamManual__one'>
        <div className='teamManual__one__left'>
          <div className='teamManual__one__left__top'>
            <dl>
              <dt>{t('table.date')} </dt>
              <dd>{currentManual.lastdate}</dd>
            </dl>
            <dl>
              <dt>{t('table.writer')} </dt>
              <dd>
                {writerInfo
                  ? writerInfo.username
                  : t('components.teamManual.manager')}
              </dd>
            </dl>
            <dl>
              <dt>{t('manual.cate-manualSum')} </dt>
              <dd>{currentManual.summary}</dd>
            </dl>
            <dl>
              <dt>{t('manual.cate-manualNote')} </dt>
              <dd>{currentManual.note}</dd>
            </dl>
          </div>
          <div className='teamManual__one__left__bottom'>
            <span className='title'>[{t('manual.history')}]</span>
            <div>
              {oldManual && oldManual.length !== 0 ? (
                oldManual.map(el => (
                  <div
                    className='oldManualBox'
                    key={el._id}
                    onMouseEnter={() => handleMouseEnter(el)}
                    onMouseLeave={handleMouseLeave}>
                    <dl>
                      {/* 메뉴얼 제목 및 업데이트 날짜 */}
                      <dt>
                        [{el.manualName}: {el.createdAt.slice(0, 10)}]
                      </dt>
                      {/* 개정내용 */}
                      <dd>{el.summary}</dd>
                    </dl>
                    <dl>
                      <Button variant='secondary'>
                        <RiDownload2Fill
                          size={20}
                          onClick={() => downloadManualOne(el)}
                        />
                      </Button>
                    </dl>
                    <div
                      className={`${
                        oldNote === el && hovered ? 'hoveredBox' : 'leaveBox'
                      }`}>
                      <div>
                        [{t('components.teamManual.remark')} :{' '}
                        {el.createdAt.slice(0, 10)}]
                      </div>
                      <div>
                        {oldNote.note !== '' ? (
                          <span>{oldNote.note}</span>
                        ) : (
                          <span style={{ color: '#ccc' }}>
                            {t('components.teamManual.no-write')}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ color: '#ccc' }}>
                  {t('components.teamManual.no-record')}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='teamManual__one__right'>
          {loading && currentManual?.url !== '' ? (
            <Spinner
              animation='border'
              role='status'
              className='teamManual__one__right__spinner'
              style={{ width: '4rem', height: '4rem' }}>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          ) : (
            <iframe
              ref={iframeRef}
              sandbox='allow-scripts allow-same-origin allow-forms'
              onLoad={() => setLoading(false)}
              title={currentManual.manualName}
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${currentManual?.url}`}
              width='100%'
              height='100%'
              frameBorder='0'>
              This is an embedded{' '}
              <a href='http://office.com'>Microsoft Office</a> document, powered
              by <a href='http://office.com/webapps'>Office Online</a>
            </iframe>
          )}
        </div>
        {/* 매뉴얼 history */}
      </div>
    </>
  );
};

export default TeamManualOne;

/*  */
/* /* <div className='teamManual__one__oldManuals__body'>
            <div className='teamManualBox'>
              <Table className='teamManualBox__table'>
                <thead>
                  <tr>
                    <td width='15%'>작성일</td>
                    <td width='15%'>작성자</td>
                    <td width='55%'>개정내용</td>
                    <td width='15%'>다운로드</td>
                  </tr>
                </thead>
                <tbody>
                  {oldManuals !== [] &&
                    oldManuals.map(oldManual => (
                      <tr>
                        <td>{oldManual.createdAt.slice(0, 10)}</td>
                        <td>{oldManual.writer.username}</td>
                        <td>{oldManual.summary}</td>
                        <td>
                          <Button
                            variant='secondary'
                            onClick={() => console.log('down')}>
                            <RiDownload2Fill size={20} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div> */
