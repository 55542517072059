import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Table, ToggleButton } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil, BiPlus } from 'react-icons/bi';
import { MdOutlineDownloadDone } from 'react-icons/md';
import '../../../scss/ModRule/ModRuleStatus.scss';
import SearchBar from '../../SearchBar';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import ModRuleStatusModal from './ModRuleStatusModal';
import useModRule from '../../../common/hooks/useModRule';
import ModRuleStatusInfoModal from './ModRuleStatusInfoModal';
import ModRuleStatusPublishModal from './ModRuleStatusPublishModal';
import useAuth from '../../../common/hooks/useAuth';
import Roles from '../../../common/auth/Roles';
import { formatDate } from '../../../common/utils/tzUtiles';

const StatusBadge = ({ modRule }) => {
  const { status, draftedAt, rejectedAt, examinedAt, approvedAt } = modRule;
  const { t, i18n } = useTranslation();

  const getStatusCode = () => {
    switch (status) {
      case 'draft':
        return i18n.language === 'ko' ? '검토중' : 'Under Review';
      case 'rejected':
        return i18n.language === 'ko' ? '반려' : 'Rejected';
      case 'examined':
        return i18n.language === 'ko' ? '승인대기' : 'Pending Approval';
      case 'approved':
        return i18n.language === 'ko' ? '승인완료' : 'Approved';
      default:
        return i18n.language === 'ko' ? '기록 없음' : 'no record';
    }
  };

  const getStatusDate = () => {
    switch (status) {
      case 'draft':
        return formatDate(draftedAt);
      case 'rejected':
        return formatDate(rejectedAt);
      case 'examined':
        return formatDate(examinedAt);
      case 'approved':
        return formatDate(approvedAt);
      default:
        return i18n.language === 'ko' ? '기록 없음' : 'no record';
    }
  };
  // color , 번역 , 상태에 맞는 날짜 선택

  return (
    <>
      <div className={`modrulestatus__badge badge${status}`}>
        <span>{getStatusCode()}</span>
      </div>
      <span style={{ marginLeft: '0.5vw' }}>{getStatusDate()}</span>
    </>
  );
};

const RuleStatusTable = ({ modRule }) => {
  const { t } = useTranslation();

  return (
    <tr key={modRule._id}>
      <td width='10%' className='text-center'>
        {modRule.classification === 'enact'
          ? t('modrule.enact')
          : t('modrule.amend')}
      </td>
      <td width='10%' className='text-center'>
        {modRule.lan.toUpperCase()}
      </td>
      <td width='40%'>
        <NavLink
          to={`${modRule._id}`}
          style={{
            textDecoration: 'none',
            color: 'black',
          }}>
          {`[${modRule.ruleNumber}]:${modRule.ruleName}`}
        </NavLink>
      </td>
      <td width='20%' className='text-center'>
        {formatDate(modRule.draftedAt)}
      </td>
      <td width='20%' className='text-center'>
        <StatusBadge modRule={modRule} />
      </td>
    </tr>
  );
};

const OPTIONS = [
  { value: 'all', nameKo: '전체', nameEn: 'ALL' },
  { value: 'classification', nameKo: '제•개정', nameEn: 'ENACT/AMEND' },
  {
    value: 'lan',
    nameKo: '언어',
    nameEn: 'LANGUAGE',
  },
  {
    value: 'modRuleName',
    nameKo: '규정',
    nameEn: 'RULE TITLE',
  },
  {
    value: 'remarks',
    nameKo: '개정번호',
    nameEn: 'DCN. NO',
  },
];

const ModRuleStatusDcn = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [dcn, setDcn] = useState({});
  const { setModRule } = useModRule();
  const [filterMods, setfilterMods] = useState([]);
  const [searchOption, setSearchOption] = useState(t('modrule.tab1'));
  const [searchStr, setSearchStr] = useState('');
  const [newOpen, setNewOpen] = useState(false);
  const [update, setUpdate] = useState(false);

  const [dcnInfoOpen, setDcnInfoOpen] = useState(false); // dcn 정보 modal
  const [dcnPublishOpen, setDcnPublishOpen] = useState(false); // dcn 배포 modal

  const axiosAuth = useAxiosAuth();
  const { auth } = useAuth();

  const [listType, setListType] = useState('all');

  useEffect(() => {
    const controller = new AbortController();
    const modRules = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: '/api/dcn/current/dcn',
        });
        setDcn(res.data);
        setfilterMods(res.data.modRuleRef);
        setModRule({
          ruleNumber: '',
          ruleName: '',
          draftedAt: '',
          comments: [],
          modChapterRef: [],
          sortOpen: false,
        });
        setUpdate(false);
      } catch (error) {
        console.log(error);
      }
    };
    modRules();
    return () => {
      controller.abort();
    };
  }, [update]);

  const goPage = page => {
    navigate(`/modrule/${page}`, {
      state: {
        number: dcn.number,
      },
    });
  };

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  const viewTypes = [
    { id: 'type1', label: t('modrule.tab1'), value: 'all' },
    { id: 'type2', label: t('modrule.tab3'), value: 'draft' },
    { id: 'type3', label: t('modrule.tab4'), value: 'rejected' },
    { id: 'type4', label: t('modrule.tab5'), value: 'examined' },
    { id: 'type5', label: t('modrule.complete-approve'), value: 'approved' },
  ];

  const listFilter = val => {
    if (val === 'draft') {
      const filteredList = dcn.modRuleRef.filter(
        modRule => modRule.status === 'draft'
      );
      setfilterMods(filteredList);
      setListType('draft');
      return;
    }
    if (val === 'rejected') {
      const filteredList = dcn.modRuleRef.filter(
        modRule => modRule.status === 'rejected'
      );
      setfilterMods(filteredList);
      setListType('rejected');
      return;
    }
    if (val === 'examined') {
      const filteredList = dcn.modRuleRef.filter(
        modRule => modRule.status === 'examined'
      );
      setfilterMods(filteredList);
      setListType('examined');
      return;
    }
    if (val === 'approved') {
      const filteredList = dcn.modRuleRef.filter(
        modRule => modRule.status === 'approved'
      );
      setfilterMods(filteredList);
      setListType('approved');
      return;
    }
    setListType('all');
    setfilterMods(dcn.modRuleRef);
  };

  // DCN 배포 모달 Open_func
  const handleDcnPublishOpen = () => {
    const modRules = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: '/api/dcn/current/dcn',
        });
        setDcn(res.data);
        setfilterMods(res.data.modRuleRef);
        setModRule({
          ruleNumber: '',
          ruleName: '',
          draftedAt: '',
          comments: [],
          modChapterRef: [],
          sortOpen: false,
        });
        setUpdate(false);
      } catch (error) {
        console.log(error);
      }
    };
    modRules();

    setDcnPublishOpen(true);
  };

  return (
    <>
      <div className='modrulestatus__main__tableheader'>
        {dcn._id ? (
          <>
            <div className='modrulestatus__main__tableheader__title'>
              <span
                style={{
                  fontSize: '1.2rem',
                  lineHeight: '5vh',
                  display: 'inline-block',
                }}>
                {`DCN ${dcn.number}`}
              </span>

              <span
                style={{
                  fontSize: '1.2rem',
                  lineHeight: '5vh',
                  display: 'inline-block',
                  margin: '0 1vw',
                  color: 'orange',
                }}>
                {' '}
                |{' '}
              </span>
              <span
                style={{
                  fontSize: '1.2rem',
                  display: 'inline-block',
                  marginRight: '2vw',
                }}>
                {formatDate(dcn.revisionTime)}
              </span>

              <Button
                type='button'
                className='btns__item'
                variant='outline-primary'
                onClick={() => goPage('enactrule')}>
                <BiSolidPencil size='15' />
                <span className='hide__name'> {t('modrule.enact')}</span>
              </Button>
              <Button
                type='button'
                className='btns__item'
                variant='outline-primary'
                onClick={() => goPage('amendrule')}>
                <span>
                  <BiSolidPencil size='15' />
                </span>
                <span className='hide__name'> {t('modrule.amend')}</span>
              </Button>

              {auth.accessToken &&
                auth.roles.find(role => [Roles.ADMIN].includes(role)) && (
                  <>
                    <Button
                      type='button'
                      className='btns__item'
                      variant='outline-primary'
                      onClick={() => setDcnInfoOpen(true)}>
                      <span>
                        <MdOutlineDownloadDone size='15' />
                      </span>
                      <span className='hide__name'>
                        DCN {t('modrule.info')}
                      </span>
                    </Button>
                    <Button
                      type='button'
                      className='btns__item'
                      variant='outline-primary'
                      onClick={handleDcnPublishOpen}>
                      <span>
                        <MdOutlineDownloadDone size='15' />
                      </span>
                      <span className='hide__name'>
                        DCN {t('modrule.distribution')}
                      </span>
                    </Button>
                  </>
                )}
            </div>
            <div className='modrulestatus__main__tableheader__searchbar'>
              <ButtonGroup
                style={{
                  marginRight: '3vh',
                  borderRadius: '1rem',
                  width: 'auto',
                }}>
                {viewTypes.map(radio => (
                  <ToggleButton
                    style={{
                      padding: '7px 10px',
                      width: i18n.language === 'ko' ? '5rem' : '',
                    }}
                    key={radio.id}
                    id={`radio-${radio.id}`}
                    type='radio'
                    variant='outline-primary'
                    name='radio'
                    value={radio.value}
                    checked={listType === radio.value}
                    onChange={e => listFilter(e.currentTarget.value)}>
                    {radio.label}
                  </ToggleButton>
                ))}
              </ButtonGroup>

              <select onChange={e => setSearchOption(e.target.value)}>
                {OPTIONS.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                    defaultValue={searchOption === option.value}>
                    {i18n.language === 'ko' ? option.nameKo : option.nameEn}
                  </option>
                ))}
              </select>

              <div className='headerLeft__searchBox'>
                <SearchBar value={searchStr} onChange={handlesearchStr} />
              </div>
            </div>

            {dcnInfoOpen && (
              <ModRuleStatusInfoModal
                dcnNum={dcn.number}
                setUpdate={setUpdate}
                dcnInfoOpen={dcnInfoOpen}
                setDcnInfoOpen={setDcnInfoOpen}
              />
            )}

            {dcnPublishOpen && (
              <ModRuleStatusPublishModal
                dcn={dcn}
                setUpdate={setUpdate}
                dcnPublishOpen={dcnPublishOpen}
                setDcnPublishOpen={setDcnPublishOpen}
              />
            )}
          </>
        ) : (
          <>
            <div className='modrulestatus__main__tableheader__title'>
              <span
                style={{
                  fontSize: '1.2rem',
                  lineHeight: '5vh',
                }}>
                {t('modrule.no-under-dcn')}
              </span>
              <Button
                type='button'
                className='btns__item'
                variant='outline-primary'
                onClick={() => setNewOpen(true)}>
                <span>
                  <BiPlus size='15' />
                </span>
                <span className='hide__name'>DCN {t('modrule.create')}</span>
              </Button>
            </div>

            {newOpen && (
              <ModRuleStatusModal
                setUpdate={setUpdate}
                newOpen={newOpen}
                setNewOpen={setNewOpen}
              />
            )}
          </>
        )}
      </div>

      {/* 제/개정 리스트 */}
      <div className='modrulestatus__main__tablecontainer'>
        <Table className='modrulestatus__main__table' hover>
          <thead className='modrulestatus__main__table__header'>
            <tr className='text-center'>
              <th width='10%'>{t('table.part')}</th>
              <th width='10%'>{t('modrule.lan')}</th>
              <th width='40%'>
                [{t('table.rule-num')}]: {t('table.rule-title')}{' '}
              </th>
              <th width='20%'>{t('table.draft-date')}</th>
              <th width='20%'>{t('table.status')}</th>
            </tr>
          </thead>
          <tbody className='modrulestatus__main__table__body'>
            {filterMods?.length > 0 &&
              filterMods.map((modRule, index) => (
                <RuleStatusTable
                  key={modRule._id}
                  index={index}
                  modRule={modRule}
                />
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ModRuleStatusDcn;
