import { useContext, useDebugValue } from 'react';
import ModChapterContext from '../../store/ModChapterProvider';

const useModChapter = () => {
  const { modChapter } = useContext(ModChapterContext);
  useDebugValue(modChapter, el => (el ? 'ondata' : 'offdata'));
  return useContext(ModChapterContext);
};

export default useModChapter;
