import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import '../../scss/RuleInfo/RuleList.scss';
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { Table } from 'react-bootstrap';
import RuleBreadCrumb from './RuleBreadCrumb';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const RuleTr = ({ rule }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <tr>
      <td width='15%' align='center'>
        <NavLink
          to={
            rule.viewType === 'contents'
              ? `/ruleinfo/contents/${rule.ruleNumber}`
              : `/ruleinfo/mix/${rule.ruleNumber}`
          }
          style={{ textDecoration: 'none', color: 'black' }}>
          {`[${rule.ruleNumber}]`}
        </NavLink>
      </td>
      <td width='65%'>
        <NavLink
          to={
            rule.viewType === 'contents'
              ? `/ruleinfo/contents/${rule.ruleNumber}`
              : `/ruleinfo/mix/${rule.ruleNumber}`
          }
          style={{ textDecoration: 'none', color: 'black' }}>
          {`${rule.ruleName}`}
        </NavLink>
      </td>
      {rule.revisedAt ? (
        <td className='text-center'>
          {timezoneFormat(
            utcToZonedTime(
              toDate(rule.revisedAt, {
                timeZone: 'UTC',
              }),
              timezone
            ),
            'yyyy-MM-dd',
            { timeZone: timezone }
          )}
        </td>
      ) : (
        <td className='text-center'>-</td>
      )}
      <td width='10%' className='text-center'>
        -
      </td>
    </tr>
  );
};

const DummyTr = () => {
  const len = [];

  return (
    <>
      {len.map(el => (
        <tr key={el}>
          <td />
          <td className='text-center'>
            <BsFileEarmarkPdf />
          </td>
          <td className='text-center' />
        </tr>
      ))}
    </>
  );
};

const RuleList = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const [rules, setRules] = useState([]);
  const { categoryNumber } = useParams();
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    const fetchIndex = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/init/kor`,
      });
      const categoriesData = response.data;
      const breadCrumbsArr = [];
      breadCrumbsArr.push({
        path: 'category',
        _id: categoriesData._id,
        name: `[${categoriesData.categoryNumber}] ${categoriesData.category}`,
        active: 'active',
      });
      setRules(categoriesData.ruleRef);
      setBreadCrumbs(breadCrumbsArr);
    };

    const fetchRules = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/${categoryNumber}/${auth.id}`,
      });
      const categoriesData = response.data;
      const breadCrumbsArr = [];
      breadCrumbsArr.push({
        path: 'category',
        _id: categoriesData._id,
        name: `[${categoriesData.categoryNumber}] ${categoriesData.category}`,
        active: 'active',
      });
      setRules(categoriesData.ruleRef);
      setBreadCrumbs(breadCrumbsArr);
    };

    if (categoryNumber) {
      fetchRules();
      return;
    }
    fetchIndex();
  }, [categoryNumber]);

  return (
    <div className='ruleinfo__rulelist'>
      <div className='ruleinfo__rulelist__header'>
        <RuleBreadCrumb breadCrumbs={breadCrumbs} />
      </div>
      <div className='ruleinfo__rulelist__tableBox'>
        <Table className='table rulelist__table'>
          <thead className='text-center'>
            <tr>
              <th scope='col' width='15%'>
                {t('table.rule-num')}
              </th>
              <th scope='col' width='65%'>
                {t('table.rule-title')}
              </th>
              <th scope='col' width='10%'>
                {t('table.new-date')}
              </th>
              <th scope='col' width='10%'>
                {t('table.remark')}
              </th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            {rules && rules.map(rule => <RuleTr key={rule._id} rule={rule} />)}
            <DummyTr />
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RuleList;
