import React, { useEffect, useState } from 'react';
import { BiX } from 'react-icons/bi';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { MdFolderDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../../common/utils/SelectStyles';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';

const ModRuleCirRuleDeleteModal = ({ setCirModal, cate, updateData }) => {
  const { t, i18n } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const [rules, setRules] = useState([]);
  const [rule, setRule] = useState(null);

  useEffect(() => {
    if (cate?.ruleRef?.length > 0) {
      const rulesCopy = cate.ruleRef.map(el => ({
        label: `${el.ruleNumber} : ${el.ruleName}`,
        value: el,
      }));
      setRules(rulesCopy);
    }
  }, [cate]);

  const handleTargetRule = e => {
    setRule(e.value);
  };

  const submitDeleteCirRule = async () => {
    if (
      window.confirm(
        `${t('modrule.del-cir-group-q')}\n CIR : ${rule.ruleNumber}`
      )
    ) {
      try {
        const res = await axiosAuth({
          method: 'DELETE',
          url: `/api/cir/delete`,
          data: {
            ruleId: rule._id,
          },
        });
        if (res.status === 200) {
          toast(t('modrule.del-cir-group'));
          setCirModal('');
          updateData();
        }
      } catch (error) {
        toast(t('modrule.del-cir-group-error'));
      }
    }
  };

  return (
    <div className='cirpopup'>
      <div className='cirpopup__header'>
        <span className='cirpopup__title'>
          {t('modrule.del-cir-group-btn')}
        </span>
        <BiX size='30' onClick={() => setCirModal('')} />
      </div>
      <div className='cirpopup__body'>
        <div className='cirpopup__body__cateBox'>
          <span>
            <MdFolderDelete size='30' />
          </span>
          <span>{`[${cate.categoryNumber}] ${cate.category}`}</span>
        </div>
        <div
          className='cirpopup__body__selectBox'
          style={{
            marginTop: '20px',
          }}>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.select-del-cir-group')}</div>}
            isSearchable
            options={rules}
            onChange={handleTargetRule}
          />
        </div>
        <div
          style={{
            border: '2px solid #E71A21',
            padding: '0.5vh',
            fontSize: '1rem',
            backgroundColor: '#FFF3F2',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            marginTop: '20px',
          }}>
          <FaTriangleExclamation fill='#E71A21' size={20} />
          {/* 경고 아이콘 */}
          {i18n.language === 'ko' ? (
            <p
              style={{
                marginLeft: '10px',
                marginTop: '10px',
              }}>
              CIR 분류 삭제시{' '}
              <span
                style={{
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  backgroundColor: '#FFF3F2',
                  color: '#20265B',
                }}>
                분류에 포함된 모든 CIRCULAR
              </span>
              가 삭제됩니다.
            </p>
          ) : (
            <p
              style={{
                marginLeft: '10px',
                marginTop: '10px',
              }}>
              When deleting a CIR category,{' '}
              <span
                style={{
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  backgroundColor: '#FFF3F2',
                  color: '#20265B',
                }}>
                all CIRCULARs included in the category
              </span>{' '}
              will also be deleted.
            </p>
          )}
        </div>
      </div>
      <div className='cirpopup__bottom'>
        <Button variant='secondary' onClick={() => setCirModal('')}>
          {t('button.cancel')}
        </Button>
        <Button variant='secondary' onClick={() => submitDeleteCirRule()}>
          {t('button.delete')}
        </Button>
      </div>
    </div>
  );
};

export default ModRuleCirRuleDeleteModal;
