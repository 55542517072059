import { useContext, useDebugValue } from 'react';
import DcnContext from '../../store/DcnProvider';

const useDcn = () => {
  const { dcn, cir } = useContext(DcnContext);
  useDebugValue(dcn, cir, el => (el ? 'ondata' : 'offdata'));
  return useContext(DcnContext);
};

export default useDcn;
