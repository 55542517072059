import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../scss/Mobile/MobileRuleChart.scss';
import useAxiosAuth from '../common/hooks/useAxiosAuth';

const KorChartList = ({ korNav }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <td width='50%'>{t('rulechart.category')}</td>
          <td width='10%'>{t('rulechart.rule')}</td>
          <td width='10%'>{t('rulechart.chapter')}</td>
          <td width='10%'>{t('rulechart.print')}</td>
          <td width='10%'>{t('rulechart.down')}</td>
          <td width='10%'>{t('rulechart.views')}</td>
        </tr>
      </thead>
      <tbody>
        {korNav &&
          korNav.map(el => (
            <tr key={el._id}>
              <td>{el.category}</td>
              <td
                style={{
                  color: el.ruleNum === 0 ? 'red' : 'black',
                }}>
                {el.ruleNum}
              </td>
              <td>{el.chapNum}</td>
              <td>{el.printNum}</td>
              <td>{el.downNum}</td>
              <td>{el.viewNum}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
const EngChartList = ({ engNav }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <td width='50%'>{t('rulechart.category')}</td>
          <td width='10%'>{t('rulechart.rule')}</td>
          <td width='10%'>{t('rulechart.chapter')}</td>
          <td width='10%'>{t('rulechart.print')}</td>
          <td width='10%'>{t('rulechart.down')}</td>
          <td width='10%'>{t('rulechart.views')}</td>
        </tr>
      </thead>
      <tbody>
        {engNav &&
          engNav.map(el => (
            <tr key={el._id}>
              <td>{el.category}</td>
              <td
                style={{
                  color: el.ruleNum === 0 ? 'red' : 'black',
                }}>
                {el.ruleNum}
              </td>
              <td>{el.chapNum}</td>
              <td>{el.printNum}</td>
              <td>{el.downNum}</td>
              <td>{el.viewNum}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const MobileRuleChart = () => {
  const axiosAuth = useAxiosAuth();
  const { i18n, t } = useTranslation();
  const [korNav, setKorNav] = useState();
  const [engNav, setEngNav] = useState();
  const [activeTab, setActiveTab] = useState('kor');

  useEffect(() => {
    const controller = new AbortController();
    const modRules = async () => {
      try {
        const res = await axiosAuth.get('/api/chart/all');
        const korNavCopy = res.data.filter(
          el => !el.categoryNumber.includes('(E)')
        );
        const engNavCopy = res.data.filter(el =>
          el.categoryNumber.includes('(E)')
        );
        setKorNav(korNavCopy);
        setEngNav(engNavCopy);
      } catch (err) {
        console.log(err);
      }
    };
    modRules();
    return () => {
      controller.abort();
    };
  }, []);

  const makeUpdateTime = () => {
    const now = new Date();
    if (i18n.language === 'ko') {
      return `${now.getFullYear()}년 ${
        now.getMonth() + 1
      }월 ${now.getDate()}일 ${now.getHours()}시 00분`;
    }
    return `${now.getFullYear()}.${
      now.getMonth() + 1
    }.${now.getDate()} ${now.getHours()}:00`;
  };
  const handleClick = tab => {
    setActiveTab(tab);
  };

  return (
    <div className='mobileRuleChart'>
      <div className='mobileRuleChart__header'>
        <span>
          {t('rulechart.currentUpdate')}
          {`: ${makeUpdateTime()}`}
        </span>
      </div>

      {/* 국문규정, 영문규정 Btn */}
      <div className='mobileRuleChart__lanBtn'>
        <Button
          role='button'
          aria-hidden='true'
          className={`tabmenu-item ${activeTab === 'kor' ? 'active' : ''}`}
          onClick={() => handleClick('kor')}
          as={Link}>
          {t('rulechart.korean')}
        </Button>
        <Button
          role='button'
          aria-hidden='true'
          className={`tabmenu-item ${activeTab === 'eng' ? 'active' : ''}`}
          onClick={() => handleClick('eng')}
          as={Link}>
          {t('rulechart.english')}
        </Button>
      </div>

      <div className='mobileRuleChart__body'>
        <div>
          {activeTab === 'kor' ? (
            <KorChartList korNav={korNav} />
          ) : (
            <EngChartList engNav={engNav} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileRuleChart;
