import React from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

const ProtectedModRule = () => {
  const { state } = useLocation();
  const { id } = useParams();

  return !state && !id ? <Navigate to='/modrule/dcn' /> : <Outlet />;
};

export default ProtectedModRule;
