import React, { useEffect, useRef } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import {
  MdBarChart,
  MdNotifications,
  MdOutlineScreenSearchDesktop,
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import '../scss/Mobile/MobileSideNav.scss';
import { ImBooks } from 'react-icons/im';
import { TbSettings } from 'react-icons/tb';
import { BiGlobe, BiNotification } from 'react-icons/bi';
import { BsFileEarmarkRuled } from 'react-icons/bs';
import MobilePortal from './MobilePortal';
import useModalOpen from '../common/hooks/useModalOpen';
import useAuth from '../common/hooks/useAuth';

const MobileSideNav = () => {
  // 메뉴 순번 바꾸기
  const { auth } = useAuth();
  const { modalOpen, setModalOpen } = useModalOpen();
  const navi = useNavigate();
  const { i18n, t } = useTranslation();
  const modalRef = useRef(); // 모달 외부 클릭 시 닫기 ref

  const handleLanguage = language => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  const menuOnClick = menu => {
    navi(`/${menu}`);
    setModalOpen({ ...modalOpen, leftside: false });
  };

  // 모달 외부 클릭 시 닫기 useEffect
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen({ ...modalOpen, leftside: false });
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalOpen, setModalOpen]);

  if (!modalOpen.leftside) return null;

  return (
    <MobilePortal wrapperId='portal-sidenav'>
      <div className='mobile-sidenav'>
        <div className='mobile-sidenav__menubtns' ref={modalRef}>
          <DropdownButton
            align='end'
            title={
              i18n.language === 'ko' ? (
                <span>
                  KOR&nbsp;
                  <BiGlobe size={24} />
                </span>
              ) : (
                <span>
                  ENG&nbsp;
                  <BiGlobe size={24} />
                </span>
              )
            }
            variant='primary'
            id='dropdown-menu-align-end'>
            <Dropdown.Item eventKey='1' onClick={() => handleLanguage('ko')}>
              KOREAN
            </Dropdown.Item>
            <Dropdown.Item eventKey='2' onClick={() => handleLanguage('en')}>
              ENGLISH
            </Dropdown.Item>
          </DropdownButton>
          <Button
            className=''
            onClick={() => {
              menuOnClick('notices');
            }}>
            <MdNotifications />
            <span>{t('sidenav.notice')}</span>
          </Button>
          <Button
            className=''
            onClick={() => {
              menuOnClick('ruleinfo');
            }}>
            <ImBooks />
            <span>{t('sidenav.ruleinfo')}</span>
          </Button>
          <Button
            className=''
            onClick={() => {
              menuOnClick(`teammanual/${auth?.org}`);
            }}>
            <BsFileEarmarkRuled />
            <span>{t('sidenav.teammanual')}</span>
          </Button>
          <Button
            className=''
            onClick={() => {
              menuOnClick('revstatus');
            }}>
            <BiNotification />
            <span>{t('sidenav.revstatus')}</span>
          </Button>
          <Button
            className=''
            onClick={() => {
              menuOnClick('userlog');
            }}>
            <MdOutlineScreenSearchDesktop />
            <span>{t('sidenav.userlog')}</span>
          </Button>
          <Button
            className=''
            onClick={() => {
              menuOnClick('rulechart');
            }}>
            <MdBarChart />
            <span>{t('sidenav.rulechart')}</span>
          </Button>
          <Button
            className=''
            onClick={() => {
              menuOnClick('setting');
            }}>
            <TbSettings />
            <span>{t('sidenav.setting')}</span>
          </Button>
        </div>
      </div>
    </MobilePortal>
  );
};

export default MobileSideNav;
