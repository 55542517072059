import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const RuleEditRevStatus = ({
  revStatus,
  chapoptions,
  setRevStatus,
  setRevModalOpen,
}) => {
  const { t } = useTranslation();
  const [revCopy, setRevCopy] = useState([]);

  useEffect(() => {
    if (chapoptions && revStatus) {
      setRevCopy(revStatus);
    }
  }, []);

  const close = () => {
    setRevModalOpen(false);
  };

  const updateState = index => e => {
    const newArray = revCopy.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          [e.target.name]: [e.target.value],
        };
      }
      return item;
    });
    setRevCopy(newArray);
  };

  const submitRevStatus = () => {
    if (revCopy.map(rev => rev.summary.length).includes(0)) {
      toast(t('modrule.check-rev-sum'));
    } else {
      setRevStatus(revCopy);
      toast(t('modrule.submit-rev-history-sum'));
      close();
    }
  };

  return (
    <div className='revstatusSection'>
      <div className='revstatusSection__header'>{t('modrule.rev-history')}</div>
      <div className='revstatusSection__body'>
        <div className='revstatusSection__body__contents'>
          <Table className='text-center'>
            <thead>
              <tr>
                <td>{t('table.chapter')}</td>
                <td>{t('table.summary-content')}</td>
              </tr>
            </thead>
            <tbody>
              {revCopy.length > 0 &&
                revCopy.map((revs, i) => (
                  <tr
                    key={revs.chapter.join(',')}
                    className='revstatusSection__oneTr'>
                    <td>{revs.chapter.join(',')}</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`input-revsummary_${i}`}
                        name='summary'
                        placeholder={t('modrule.write-summary')}
                        value={revs.summary.join('')}
                        onChange={updateState(i)}
                        required
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className='revstatusSection__bottom'>
        <Button type='submit' variant='secondary' onClick={close}>
          {t('button.cancel')}
        </Button>
        <Button type='submit' variant='secondary' onClick={submitRevStatus}>
          {t('button.submit')}
        </Button>
      </div>
    </div>
  );
};

export default RuleEditRevStatus;
