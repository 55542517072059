import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import useDcn from '../../common/hooks/useDcn';
import useModRule from '../../common/hooks/useModRule';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useModChapter from '../../common/hooks/useModChapter';

const ModRuleDraftHeaderOld = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navi = useNavigate();
  const { dcn } = useDcn();
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  const submitModRule = async () => {
    const classificationKr =
      modRule.classification === 'enact'
        ? t('modrule.enact')
        : t('modrule.amend');
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/revision/entryrevision',
        data: {
          modRuleId: modRule._id,
          ruleId: modRule.refRule._id,
        },
      });
      if (res.status === 200) {
        toast(
          `${res.data.ruleNumber} ${classificationKr} ${t(
            'modrule.complete-draft'
          )}`
        );
        navi('/entry/dcn');
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.complete-draft-failed'));
    }
  };

  const DeleteModRuleAndExit = async () => {
    if (window.confirm(t('modrule.close-del-draft'))) {
      try {
        const res = await axiosAuth({
          method: 'post',
          url: `/api/modrule/delete`,
          data: {
            dcnId: dcn._id,
            modRuleId: modRule._id,
          },
        });
        if (res.status === 200) {
          navi('/modrule/dcn');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const openRevStatus = () => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
    if (modChapter.fileType === 'revstatus') {
      setModChapter(prev => ({
        ...prev,
        fileType: '',
      }));
      return;
    }
    setModChapter(prev => ({
      ...prev,
      fileType: 'revstatus',
    }));
  };

  const openSortChapter = () => {
    setModRule(prev => ({
      ...prev,
      sortOpen: !prev.sortOpen,
    }));
    setModChapter({
      fileType: '',
      modType: '',
      content: '',
      uri: '',
      chapterIdx: 0,
      chapterNumber: '',
      chapterName: '',
      chapterContents: {
        header: '',
        children: [],
      },
    });
  };

  return (
    <div className='enactrule__main__header'>
      <div className='enactrule__main__header__dcninfo'>
        <span
          style={{
            fontSize: '1.2rem',
            display: 'inline-block',
          }}>
          {`DCN ${dcn.number}`}
        </span>
        <span
          style={{
            fontSize: '1.2rem',
            display: 'inline-block',
            margin: '0 1vw',
            color: 'orange',
          }}>
          {' '}
          |{' '}
        </span>
        <span
          style={{
            fontSize: '1.2rem',
            display: 'inline-block',
            marginRight: '2vw',
          }}>
          {timezoneFormat(
            utcToZonedTime(
              toDate(dcn.revisionTime, {
                timeZone: 'UTC',
              }),
              timezone
            ),
            'yyyy-MM-dd',
            { timeZone: timezone }
          )}
        </span>
      </div>
      <div className='enactrule__main__header__btns'>
        <Button
          variant='secondary'
          style={{ color: `${modRule?.sortOpen ? 'orange' : ''}` }}
          type='submit'
          onClick={openSortChapter}>
          {t('modrule.set-chap-order')}
        </Button>

        {modRule?.classification === 'amend' && (
          <Button
            variant='secondary'
            style={{
              color: `${modChapter?.fileType === 'revstatus' ? 'orange' : ''}`,
            }}
            type='submit'
            onClick={openRevStatus}>
            {t('modrule.rev-sum')}
          </Button>
        )}

        <Button variant='secondary' type='submit' onClick={submitModRule}>
          {t('modrule.register-draft-btn')}
        </Button>
        <Button
          variant='secondary'
          type='submit'
          onClick={DeleteModRuleAndExit}>
          {t('modrule.write-cancel-btn')}
        </Button>
      </div>
    </div>
  );
};

export default ModRuleDraftHeaderOld;
