import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import parse from 'html-react-parser';
import '../../scss/Mobile/MobileRule/MobileRuleMix.scss';
import { useParams, useNavigate } from 'react-router-dom';
import { BiUndo } from 'react-icons/bi';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import { formatDate } from '../../common/utils/tzUtiles';

const MobileRuleMix = () => {
  const navi = useNavigate();
  const axiosAuth = useAxiosAuth();
  const { ruleNum, chapterNumber } = useParams();
  const [ruleMix, setRuleMix] = useState({});
  const [mixDocs, setMixDocs] = useState([]);
  const [activeDocument, setActiveDocument] = useState({
    content: '',
    fileType: '',
    uri: '',
  });
  const viewerRef = useRef(null);

  useEffect(() => {
    const fetchRule = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `api/rule/${ruleNum}`,
      });

      setRuleMix(response.data);
      setMixDocs(response.data.chapterRef);
      if (chapterNumber) {
        const targetChapter = response.data.chapterRef.find(
          chap => chap.chapterNumber === chapterNumber
        );
        setActiveDocument(targetChapter);
        return;
      }
      setActiveDocument(response.data.chapterRef[0]);
    };
    fetchRule();
  }, [ruleNum]);

  const handleDocumentChange = document => {
    setActiveDocument(document);
  };

  const goBack = () => {
    navi(-1);
  };

  return (
    <div className='MobileRuleMix'>
      <div className='MobileRuleMix__header__back'>
        <Button onClick={goBack}>
          <BiUndo type='button' size={26} />
        </Button>
      </div>

      <div className='MobileRuleMix__header'>
        <span>
          {ruleMix &&
            ruleMix.ruleNumber &&
            `[${ruleMix.ruleNumber}] ${ruleMix.ruleName}`}
        </span>
        <span>
          {activeDocument?.createdAt && formatDate(activeDocument?.createdAt)}
        </span>
      </div>
      <div className='MobileRuleMix__body'>
        <div className='MobileRuleMix__body__sidetabs'>
          <div className='MobileRuleMix__body__sidetabs__wrapper'>
            {mixDocs &&
              mixDocs.length > 0 &&
              mixDocs.map(formpart => (
                <Button
                  key={formpart._id}
                  onClick={() => handleDocumentChange(formpart)}
                  className={`MobileRuleMix__body__sidetabs__item btn ${
                    activeDocument && activeDocument._id === formpart._id
                      ? 'active'
                      : ''
                  }`}>
                  <span>
                    {`${formpart.chapterNumber.slice(
                      formpart.chapterNumber.indexOf(
                        '-',
                        formpart.chapterNumber.indexOf('-') + 1
                      ) + 1
                    )}: ${formpart.chapterName}`}
                  </span>
                </Button>
              ))}
          </div>
        </div>
        <div className='MobileRuleMix__body__contents'>
          {activeDocument?.fileType === 'content' ? (
            <div
              id='overide-css-mobileRuleCon'
              className='MobileRuleMix__body__contents__text'>
              <div key={activeDocument._id} className='ck-mobileContent'>
                {parse(activeDocument.content)}
              </div>
            </div>
          ) : (
            <div className='MobileRuleMix__body__contents__viewer'>
              <DocViewer
                ref={viewerRef}
                prefetchMethod='GET'
                activeDocument={activeDocument}
                documents={mixDocs}
                pluginRenderers={DocViewerRenderers}
                onDocumentChange={handleDocumentChange}
                config={{
                  header: {
                    disableHeader: true,
                  },
                  csvDelimiter: ',',
                  pdfZoom: {
                    defaultZoom: 0.9,
                  },
                  pdfVerticalScrollByDefault: true,
                }}
                className='docviewer__container'
              />
            </div>
          )}
        </div>
      </div>
      <iframe
        id='print-data-container'
        title='print-rule'
        aria-hidden='true'
        tabIndex='-1'
      />
    </div>
  );
};

export default MobileRuleMix;
