import React, { useRef, useState, useEffect } from 'react';
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import Select from 'react-select';
import { format } from 'date-fns';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { toast } from 'react-toastify';
import '../../scss/Dev/NewDevRule.scss';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';
import RuleChapContents from '../ModRule/RuleChapContents';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import DatePicker from '../DatePicker/DatePicker';
import CustomFileInput from '../DatePicker/FileInput';

window.Buffer = window.Buffer || require('buffer').Buffer;

const DevSet = () => {
  // direct set dev
  const editorRef = useRef(null);
  const [category, setCategory] = useState({ label: '', value: '' });
  const [lan, setLan] = useState('kor');
  const [options, setOptions] = useState([]);
  const [ruleOptions, setRuleOptions] = useState([]);
  const [rule, setRule] = useState(null);
  const [newRule, setNewRule] = useState({
    _id: '',
    ruleNumber: '',
    ruleName: '',
    viewType: '',
    chapterRef: [],
  });
  const [isNew, setIsNew] = useState(false);
  const [chapter, setChapter] = useState({
    _id: '',
    chapterNumber: '',
    chapterName: '',
    chapterDate: '',
  });
  const [chapterContents, setChapterContents] = useState({
    header: '',
    children: [],
  });
  const [doc, setDoc] = useState(null);
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const categorySelectInit = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/category/all/kor',
      });
      const optionsCopy = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(optionsCopy);
    };
    categorySelectInit();
  }, []);

  useEffect(() => {
    const categoryLanChange = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/all/${lan}`,
      });
      const optionsCopy = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(optionsCopy);
    };
    categoryLanChange();
  }, [lan]);

  useEffect(() => {
    const initRulelist = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/${category.value._id}/${auth.id}`,
      });
      const { ruleRef } = response.data;
      const ruleOptionsCopy = ruleRef.map(ruleOption => ({
        label: `${ruleOption.ruleNumber} : ${ruleOption.ruleName}`,
        value: ruleOption,
      }));
      setRuleOptions(ruleOptionsCopy);
    };
    if (category.value) {
      initRulelist();
    }
  }, [category]);

  const handleRuleValue = async e => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/modrule/rule',
        data: { id: e.value._id },
      });
      const { ruleNumber, chapterRef } = res.data;

      setRule(res.data);

      const chapternum = chapterRef ? chapterRef.length + 1 : 1;
      setChapter({
        ...chapter,
        chapterNumber: `${ruleNumber}-${chapternum}`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleValue = e => {
    setCategory(e);
  };

  const onChangeRuleNumber = e => {
    setNewRule(prev => ({ ...prev, ruleNumber: e.target.value }));
  };

  const onChangeRuleName = e => {
    setNewRule(prev => ({ ...prev, ruleName: e.target.value }));
  };

  const ruleSubmit = async () => {
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/rule/new',
        data: {
          id: category.value._id,
          category: category.value.category,
          viewType: newRule.viewType,
          ruleNumber: newRule.ruleNumber,
          ruleName: newRule.ruleName,
          lan,
        },
      });
      const { _id, ruleNumber, ruleName } = response.data;
      setRule({ ...rule, _id, ruleNumber, ruleName });
      setChapter({ ...chapter, chapterNumber: `${ruleNumber}-1` });
    } catch (error) {
      console.log(error);
      toast(t('modrule.submit-complete-rule-failed'));
    }
  };

  const ruleDelete = () => {
    setRule({ ...rule, _id: '', ruleNumber: '', ruleName: '' });
  };

  const onChangeChapterNumber = e => {
    setChapter({ ...chapter, chapterNumber: e.target.value });
  };

  const onChangeChapterName = e => {
    setChapter({ ...chapter, chapterName: e.target.value });
  };

  const onChangeChapterDate = e => {
    setChapter({ ...chapter, chapterDate: e.target.value });
  };

  const handleIsNew = () => {
    setRule(null);
    setChapter({
      _id: '',
      chapterNumber: '',
      chapterName: '',
      chapterDate: '',
    });
    setIsNew(true);
  };

  const createContents = docData => {
    const domparser = new DOMParser();
    const docStr = domparser.parseFromString(docData, 'text/html');
    if (docData.match(/<h2>/)) {
      const h2title = docStr
        .getElementsByTagName('h2')[0]
        .innerHTML.replace(/(<([^>]+)>)/gi, '')
        .replace(/&nbsp;/g, '')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>');
      setChapterContents(prev => ({
        ...prev,
        header: h2title,
      }));
    } else {
      setChapterContents(prev => ({
        ...prev,
        header: '',
      }));
    }
    if (docData.match(/<h3>/)) {
      const h3title = [...docStr.getElementsByTagName('h3')].map(tag =>
        tag.innerHTML
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/&nbsp;/g, '')
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
      );
      setChapterContents(prev => ({
        ...prev,
        children: [...h3title],
      }));
    } else {
      setChapterContents(prev => ({
        ...prev,
        children: [],
      }));
    }
  };

  const chapterSubmit = async () => {
    const ckInstance = editorRef.current;
    if (ckInstance) {
      const editorData = ckInstance.editor.getData({ pagination: true });
      createContents(editorData);
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/modrule/newchapter',
          data: {
            refRuleId: rule._id,
            chapterNumber: chapter.chapterNumber,
            chapterName: chapter.chapterName,
            content: editorData,
            createdAt: format(
              new Date(chapter.chapterDate),
              'yyyy-MM-dd HH:mm:ss'
            ),
            lan,
            chapterContents,
            examines: [],
          },
        });
        toast(`${response.status} ${t('modrule.add-chap-text')}`);
        // navi('');
      } catch (error) {
        toast(t('modrule.add-chap-error'));
      }
    } else {
      alert(t('modrule.add-chap-retry'));
    }
  };

  const submitChapterContent = () => {
    const ckInstance = editorRef.current;
    if (ckInstance) {
      const editorData = ckInstance.editor.getData({ pagination: true });
      createContents(editorData);
    }
  };

  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  const handleDoc = e => {
    setDoc(e.target.files[0]);
  };

  const chapterFileSubmit = async () => {
    const formData = new FormData();
    formData.append('rulePath', rule.ruleNumber);
    formData.append('writer', auth.id);
    formData.append('refCategoryId', category.value._id);
    formData.append('refRuleId', rule._id);
    formData.append('chapterNumber', chapter.chapterNumber);
    formData.append('chapterName', chapter.chapterName);
    formData.append(
      'createdAt',
      format(new Date(chapter.chapterDate), 'yyyy-MM-dd HH:mm:ss')
    );
    formData.append('lan', lan);
    formData.append('file', doc);
    try {
      const response = await axiosMultipart({
        method: 'post',
        url: '/api/modrule/newfilechapter',
        data: formData,
      });
      toast(`${response.status} ${t('modrule.add-chap-text')}`);
      setDoc(null);
    } catch (error) {
      toast(t('modrule.add-chap-error'));
    }
  };

  function customUploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(file => {
            data.append('file', file);
            axiosMultipart
              .post('/api/modrule/imageupload', data)
              .then(res => resolve({ default: res.data.imageUrl }))
              .catch(err => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    const editorinstance = editor;
    editorinstance.plugins.get('FileRepository').createUploadAdapter =
      loader => {
        return customUploadAdapter(loader);
      };
  }

  const viewtypes = [
    { name: t('modrule.text'), value: 'contents' },
    { name: t('modrule.file'), value: 'files' },
    { name: t('modrule.mix'), value: 'mix' },
  ];

  return (
    <div className='devdraft'>
      <div className='devdraft__side'>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='editor-lanbtn-kor'>
            {t('modrule.select-rule-lan')}
          </label>

          <ButtonGroup className='devdraft__side__lanradiobtn'>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={radio.value}
                id={`radio-${idx}`}
                type='radio'
                variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                name='radio'
                value={radio.value}
                checked={lan === radio.value}
                onChange={e => setLan(e.currentTarget.value)}>
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='editor-lanbtn-kor'>
            {t('modrule.select-rule-manage-type')}
          </label>
          <ButtonGroup className='devdraft__side__lanradiobtn'>
            {viewtypes.map(radio => (
              <Button
                variant={
                  radio.value === newRule.viewType
                    ? 'primary'
                    : 'outline-primary'
                }
                onClick={() =>
                  setNewRule({ ...newRule, viewType: radio.value })
                }>
                {radio.name}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='input-rulenumber'>
            {t('modrule.cate')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.cate-placeholder')}</div>}
            isSearchable
            className='devdraft__side__category__select'
            options={options}
            onChange={handleValue}
          />
        </div>
        {isNew ? (
          <>
            <div className='devdraft__side__wrapper'>
              <label className='draft__label' htmlFor='input-rulenumber'>
                {t('modrule.rule')} No.
              </label>

              <input
                type='text'
                className='form-control input__rulenumber'
                id='input-rulenumber'
                placeholder={`${t('modrule.rule')} No.`}
                value={rule ? rule.ruleNumber : newRule.ruleNumber}
                onChange={onChangeRuleNumber}
                required
                disabled={rule}
              />
            </div>

            <div className='devdraft__side__wrapper'>
              <label className='draft__label' htmlFor='input-rulename'>
                {t('modrule.rule-title')}
              </label>
              <input
                type='text'
                className='form-control'
                id='input-rulename'
                placeholder={t('modrule.rule-title')}
                value={rule ? rule.ruleName : newRule.ruleName}
                onChange={onChangeRuleName}
                required
                disabled={rule}
              />
            </div>

            <div className='devdraft__side_btns'>
              <Button type='submit' onClick={ruleDelete}>
                {t('modrule.submit-cancel')}
              </Button>
              <Button type='submit' onClick={ruleSubmit} disabled={rule}>
                {t('modrule.submit-rule')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className='devdraft__side__wrapper'>
              <label className='draft__label' htmlFor='select-rule'>
                {t('modrule.rule')}
              </label>
              <Select
                styles={blackStyles}
                placeholder={<div>{t('modrule.select-add-chap-rule')}</div>}
                isSearchable
                id='select-rule'
                className='draft__side__rule__select'
                options={ruleOptions}
                onChange={handleRuleValue}
              />
            </div>
            <div className='devdraft__side__wrapper'>
              <Button
                className='devdraft__side__changebtn'
                type='button'
                variant='outline-primary'
                onClick={handleIsNew}>
                + {t('modrule.add-new-rule')}
              </Button>
            </div>
          </>
        )}
        <div className='devdraft__side__chapterinfos'>
          <div className='devdraft__side__oldchapters'>
            <h5>{t('modrule.register-chap-list')}</h5>
            {rule && rule.chapterRef
              ? rule.chapterRef.map(oldChapter => (
                  <div key={oldChapter._id}>{oldChapter.chapterNumber}</div>
                ))
              : null}
          </div>
          <div className='devdraft__side__chaptercontents'>
            <h5>
              {t('modrule.List')}{' '}
              <Button onClick={submitChapterContent}>
                {t('button.update')}
              </Button>
            </h5>
            <RuleChapContents chapterContents={chapterContents} />
          </div>
        </div>
      </div>

      <div className='devdraft__main'>
        <div className='devdraft__main__setheader'>
          <label className='devdraft__label' htmlFor='input-chapternumber'>
            {t('modrule.chap')} No.
            <input
              type='text'
              className='form-control'
              id='input-chapternumber'
              placeholder={t('modrule.add-chap-num')}
              defaultValue={chapter.chapterNumber || ''}
              onChange={onChangeChapterNumber}
              required
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chaptername'>
            {t('modrule.add-chap-num')}
            <input
              type='text'
              className='form-control'
              id='input-chaptername'
              placeholder={t('modrule.add-chap-num')}
              defaultValue={chapter.chapterName || ''}
              onChange={onChangeChapterName}
              required
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chapterdate'>
            {t('form.enact-date')}
            <DatePicker
              name='chapterDate'
              startdate={chapter.chapterDate}
              onChangeDate={onChangeChapterDate}
              language={i18n.language}
              style={{ width: '100%', border: '1px solid #ccc' }}
            />
          </label>
        </div>
        <div>
          <Form.Group
            controlId='formFileMultiple'
            encType='multipart/form-data'
            className='mb-3 formContentTop__form'>
            {/* <Form.Control
              type='file'
              name='file'
              multiple
              onChange={handleDoc}
              disabled={doc}
            /> */}
            <CustomFileInput
              type='file'
              name='file'
              multiple
              onChange={handleDoc}
              disabled={doc}
              style={{ width: '100%' }}
            />
          </Form.Group>
          <Button disabled={!doc} onClick={chapterFileSubmit}>
            {t('modrule.add-file')}
          </Button>
          <Button disabled={doc} type='submit' onClick={chapterSubmit}>
            {t('modrule.Draft-rule')}
          </Button>
        </div>
        <div className='devdraft__main__editor' id='document-container'>
          <div id='toolbar-container' className='toolbar-container' />
          <div id='editor-container' className='editor-container' />
          <CKEditor
            ref={editorRef}
            editor={Editor}
            config={{
              // licenseKey: LICENSE,
              heading: {
                options: [
                  {
                    model: 'paragraph',
                    title: i18n.language === 'ko' ? '본문' : 'CONTENT',
                    class: 'ck-heading_paragraph',
                  },
                  {
                    model: 'heading2',
                    view: 'h2',
                    title: i18n.language === 'ko' ? '제목 1' : 'TITLE 1',
                    class: 'ck-heading_heading2',
                  },
                  {
                    model: 'heading3',
                    view: 'h3',
                    title: i18n.language === 'ko' ? '제목 2' : 'TITLE 2',
                    class: 'ck-heading_heading3',
                  },
                  {
                    model: 'heading4',
                    view: 'h4',
                    title: i18n.language === 'ko' ? '제목 3' : 'TITLE 3',
                    class: 'ck-heading_heading4',
                  },
                ],
              },
              list: {
                properties: {
                  styles: true,
                  startIndex: true,
                  reversed: true,
                },
              },
              extraPlugins: [uploadPlugin],
              autosave: {
                save(editor) {
                  if (editor) {
                    const data = editor.getData({ pagination: true });
                    console.log(data);
                  }
                },
                waitingTime: 2000,
              },
            }}
            onReady={editor => {
              const editorContainer =
                document.querySelector('#editor-container');
              const toolbarContainer =
                document.querySelector('#toolbar-container');

              toolbarContainer.appendChild(editor.ui.view.toolbar.element);
              editorContainer.appendChild(editor.ui.view.editable.element);

              editor.ui.update();
            }}
            onError={(error, editor) => {
              if (editor.willEditorRestart) {
                editor.ui.view.toolbar.element.remove();
              }
              if (error) {
                console.log(error);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DevSet;
