/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Form from 'react-validation/build/form';
import '../scss/Login.scss';
import useAuth from '../common/hooks/useAuth';

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const form = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [checkbox, setCheckbox] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('email')) {
      setEmail(JSON.parse(localStorage.getItem('email')));
      setCheckbox(true);
    }
  }, []);

  const onChangeUsername = e => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
  };

  const handleLogin = async e => {
    e.preventDefault();

    setMessage('');
    setLoading(true);

    await axios
      .post(
        '/api/auth/signin',
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .then(res => {
        if (res.data.accessToken) {
          localStorage.setItem('persist', true);
          const user = jwtDecode(res.data.accessToken);
          setAuth(prev => ({
            ...prev,
            ...user,
            roles: res.data.roles,
            accessToken: res.data.accessToken,
          }));
          if (checkbox) {
            localStorage.setItem('email', JSON.stringify(user.email));
          } else {
            localStorage.removeItem('email');
          }
          setEmail('');
          setPassword('');
          navigate('/', { state: { pwExpired: res.data.pwExpired } });
        }
      })
      .catch(err => {
        if (!err?.response) {
          setMessage(t('login.manager-inquiry'));
        } else if (err.response?.status === 400) {
          setMessage(t('login.id-pw'));
        } else if (err.response?.status === 401) {
          setMessage(t('login.access-failed'));
        } else {
          setMessage(t('login.login-failed'));
        }
        setLoading(false);
      });
  };

  return (
    <div className='loginpage'>
      <div className='loginpage__logo'>
        <img
          width='100px'
          style={{ paddingTop: '2.5vh' }}
          src={`${process.env.PUBLIC_URL}/images/hmm_watermark.png`}
          alt='companyLogoImg'
        />
      </div>
      <div className='loginpage__main'>
        <Form onSubmit={handleLogin} ref={form} className='loginForm'>
          <div className='form__group'>
            <div className='form-floating mb-3'>
              <input
                type='valid'
                className='form-control'
                id='floatingInput'
                placeholder='name@example.com'
                value={email}
                onChange={onChangeUsername}
                required
                // validations={[required]}
              />
              <label htmlFor='floatingInput' className='form-label'>
                {t('login.email')}
              </label>
            </div>

            <div className='form-floating mb-3'>
              <input
                type='password'
                className='form-control'
                id='floatingPassword'
                placeholder='Password'
                value={password}
                onChange={onChangePassword}
                required
                autoComplete='new-password'
              />
              <label htmlFor='floatingPassword'>{t('login.password')}</label>
            </div>
          </div>

          <div className='failedBox'>
            <div>
              <span>{t('login.rememberMe')}</span>
              <input
                className='checkbox'
                type='checkbox'
                checked={checkbox}
                onChange={() => setCheckbox(!checkbox)}
              />
            </div>
            <div className='failedBox__messageBox'>
              {message && <div className='alert alert-danger'>{message}</div>}
            </div>
          </div>
          <Button type='submit' className='loginButton' disabled={loading}>
            <span>{t('login.loginButton')}</span>
            {loading && <Spinner animation='grow' size='sm' />}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
