import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import SearchBar from '../SearchBar';
import '../../scss/RevStatus/RevStatusList.scss';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import RevStatusOne from './RevStatusOne';

const RevStatusList = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { t } = useTranslation();
  const [record, setRecord] = useState([]);
  const [filterRecord, setFilterRecord] = useState([]);
  const axiosAuth = useAxiosAuth();
  const [targetDcn, setTargetDcn] = useState({
    _id: null,
    number: null,
    modRuleRef: [],
  });
  const [searchStr, setSearchStr] = useState('');
  const [sortValues, setSortValues] = useState({
    sortType: 'rev',
    sortUpDown: false,
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchNotice = async () => {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/revstatus/dcns`,
      });
      const dcnList = res.data.map(el => ({
        ...el,
        count: el.modRuleRef.length,
      }));
      setFilterRecord(dcnList);
      setRecord(dcnList);
    };
    fetchNotice();
    return () => {
      controller.abort();
    };
  }, []);

  // Search Bar
  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  useEffect(() => {
    const reg = new RegExp(searchStr, 'gi');
    if (searchStr !== '') {
      const filteredRec = record.filter(
        el =>
          (typeof el.number === 'string' && el.number.match(reg)) ||
          (el.revisionTime && el.revisionTime.match(reg))
      );
      setFilterRecord(filteredRec);
    } else {
      setFilterRecord(record);
    }
  }, [searchStr]);

  // Sorting
  const resetNumber = num => {
    if (num === 'rev') {
      setSortValues(prev => ({
        ...prev,
        sortType: 'rev',
        sortUpDown: !prev.sortUpDown,
      }));
      if (sortValues.sortUpDown) {
        setFilterRecord(
          [...filterRecord].sort((a, b) => {
            return +(a.number < b.number) || -(a.number > b.number);
          })
        );
      } else if (!sortValues.sortUpDown) {
        setFilterRecord(
          [...filterRecord].sort((a, b) => {
            return +(a.number > b.number) || -(a.number < b.number);
          })
        );
      } else {
        setFilterRecord(record);
      }
    }
    if (num === 'date') {
      setSortValues(prev => ({
        ...prev,
        sortType: 'date',
        sortUpDown: !prev.sortUpDown,
      }));
      if (sortValues.sortUpDown) {
        setFilterRecord(
          [...filterRecord].sort((a, b) => {
            return (
              +(a.revisionTime < b.revisionTime) ||
              -(a.revisionTime > b.revisionTime)
            );
          })
        );
      } else if (!sortValues.sortUpDown) {
        setFilterRecord(
          [...filterRecord].sort((a, b) => {
            return (
              +(a.revisionTime > b.revisionTime) ||
              -(a.revisionTime < b.revisionTime)
            );
          })
        );
      } else {
        setFilterRecord(record);
      }
    }
  };

  const onClickDcn = dcn => {
    setTargetDcn(dcn);
  };

  return (
    <div className='revstatus__main'>
      <div className='revstatus__leftBox'>
        {/* DCN 상단 */}
        <div className='leftBox__header'>
          <div className='leftBox__header__searchBox'>
            <div>
              <SearchBar value={searchStr} onChange={handlesearchStr} />
            </div>
          </div>
        </div>
        {/* DCN 리스트 */}
        <div className='leftBox__table'>
          <Table>
            <thead>
              <tr>
                <td>
                  <Button
                    variant='secondary'
                    style={{ padding: '0', border: 'none' }}
                    onClick={() => resetNumber('rev')}>
                    {t('dcn.dcn-number')}{' '}
                    {sortValues.sortType === 'rev' && sortValues.sortUpDown ? (
                      <TiArrowSortedUp
                        size={20}
                        color={
                          sortValues.sortType === 'rev' ? 'orange' : 'gray'
                        }
                      />
                    ) : (
                      <TiArrowSortedDown
                        size={20}
                        color={
                          sortValues.sortType === 'rev' ? 'orange' : 'gray'
                        }
                      />
                    )}
                  </Button>
                </td>
                <td width='18%'>{t('dcn.count')}</td>
                <td>
                  <Button
                    variant='secondary'
                    style={{ padding: '0', border: 'none' }}
                    onClick={() => resetNumber('date')}>
                    {t('dcn.table-date')}{' '}
                    {sortValues.sortType === 'date' && sortValues.sortUpDown ? (
                      <TiArrowSortedUp
                        size={20}
                        color={
                          sortValues.sortType === 'date' ? 'orange' : 'gray'
                        }
                      />
                    ) : (
                      <TiArrowSortedDown
                        size={20}
                        color={
                          sortValues.sortType === 'date' ? 'orange' : 'gray'
                        }
                      />
                    )}
                  </Button>
                </td>
              </tr>
            </thead>
            <tbody>
              {filterRecord &&
                filterRecord.map(rev => (
                  <tr
                    key={rev._id}
                    onClick={() => onClickDcn(rev)}
                    style={{
                      backgroundColor: targetDcn === rev._id ? '#f5f5f5' : '',
                      color: targetDcn === rev._id ? 'rgb(241, 133, 70)' : '',
                    }}>
                    <td>{`DCN${rev.number}`}</td>
                    <td>{rev.count}</td>
                    <td>
                      {timezoneFormat(
                        utcToZonedTime(
                          toDate(rev.revisionTime, {
                            timeZone: 'UTC',
                          }),
                          timezone
                        ),
                        'yyyy-MM-dd',
                        { timeZone: timezone }
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      <div className='revstatus__rightBox'>
        <h4 className='dcnTitle'>
          {t('manual.history')} -{' '}
          {targetDcn?._id ? (
            `DCN${targetDcn.number}`
          ) : (
            <span style={{ color: '#ccc' }}>{t('dcn.dcnlist-checking')}</span>
          )}
        </h4>
        <RevStatusOne dcnRuleList={targetDcn.modRuleRef} />
      </div>
    </div>
  );
};

export default RevStatusList;
