import React, { useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import parse from 'html-react-parser';
import HtmlDiff from 'htmldiff-js';
import { useTranslation } from 'react-i18next';
import useDiff from '../../common/hooks/useDiff';
import useCompare from '../../common/hooks/useCompare';
import { formatDate } from '../../common/utils/tzUtiles';

const CompareRuleDiff = () => {
  const { compare } = useCompare();
  const { diff } = useDiff();
  const [diffNew, setDiffNew] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (
      diff.oldChapter.fileType === 'content' &&
      diff.newChapter.fileType === 'content'
    ) {
      const output = HtmlDiff.execute(
        diff.oldChapter.content,
        diff.newChapter.content
      );
      setDiffNew(output);
    }
  }, [diff]);

  return (
    <div className='compare__mainbody__diffcontents'>
      {(() => {
        if (
          diff.oldChapter?.fileType === 'content' &&
          diff.newChapter?.fileType === 'content'
        ) {
          return (
            <>
              <div className='compare__mainbody__diffcontents__item'>
                <div className='diffcontents__item__title'>
                  {`${t('components.compare.revisionAfter')} ${formatDate(
                    compare.revisedAt
                  )}`}
                  <span className='diffcontents__item__title__icon'>N</span>
                </div>
                <div className='diffcontents__item__new'>
                  {(() => {
                    if (diff.newChapter?.modType === 'replace') {
                      return parse(diffNew);
                    }
                    if (diff.newChapter?.modType === 'delete') {
                      return (
                        <div className='diffcontents__item__old__nodata'>
                          {t('components.compare.delchap')}
                        </div>
                      );
                    }
                    if (
                      diff.newChapter.modType === null ||
                      diff.newChapter?.modType === 'new'
                    ) {
                      return parse(diff.newChapter.content);
                    }
                    return parse(diff.newChapter.content);
                  })()}
                </div>
              </div>
              <div className='compare__mainbody__diffcontents__item'>
                <div className='diffcontents__item__title'>
                  {`${t('components.compare.revisionBefore')} ${formatDate(
                    compare.oldRule.revisedAt
                  )}`}
                </div>
                <div className='diffcontents__item__old'>
                  {diff.newChapter.modType === 'new' ? (
                    <div className='diffcontents__item__old__nodata'>
                      {t('components.compare.addChap')}
                    </div>
                  ) : (
                    parse(diff.oldChapter.content)
                  )}
                </div>
              </div>
            </>
          );
        }
        if (
          diff.newChapter.fileType !== 'content' &&
          diff.newChapter.modType === 'new'
        ) {
          return (
            <>
              <div className='compare__mainbody__diffcontents__item'>
                <div className='diffcontents__item__title'>
                  {`${t('components.compare.revisionAfter')} ${formatDate(
                    compare.revisedAt
                  )}`}
                  <span className='diffcontents__item__title__icon'>N</span>
                </div>
                <div className='diffcontents__item__new'>
                  <DocViewer
                    prefetchMethod='GET'
                    activeDocument={diff.newChapter}
                    documents={[diff.newChapter]}
                    pluginRenderers={DocViewerRenderers}
                    config={{ header: { disableHeader: true } }}
                    className='docviewer__container'
                  />
                </div>
              </div>
              <div className='compare__mainbody__diffcontents__item'>
                <div className='diffcontents__item__title'>
                  {t('components.compare.revisionBefore')}
                </div>
                <div className='diffcontents__item__old'>
                  <div className='diffcontents__item__old__nodata'>
                    {t('components.compare.addChap')}
                  </div>
                </div>
              </div>
            </>
          );
        }
        return (
          <>
            <div className='compare__mainbody__diffcontents__item'>
              <div className='diffcontents__item__title'>
                {`${t('components.compare.revisionAfter')} ${formatDate(
                  compare.revisedAt
                )}`}
                <span className='diffcontents__item__title__icon'>N</span>
              </div>
              {diff.newChapter.modType === 'delete' ? (
                <div className='diffcontents__item__new'>
                  <div className='diffcontents__item__old__nodata'>
                    {t('components.compare.delChap')}
                  </div>
                </div>
              ) : (
                <div className='diffcontents__item__new'>
                  {diff.newChapter?.fileType === 'content' ? (
                    diff && parse(diff.newChapter.content)
                  ) : (
                    <DocViewer
                      prefetchMethod='GET'
                      activeDocument={diff.newChapter}
                      documents={[diff.newChapter]}
                      pluginRenderers={DocViewerRenderers}
                      config={{ header: { disableHeader: true } }}
                      className='docviewer__container'
                    />
                  )}
                </div>
              )}
            </div>
            <div className='compare__mainbody__diffcontents__item'>
              {diff.newChapter.modType === 'new' ? (
                <>
                  <div className='diffcontents__item__title'>
                    {t('components.compare.revisionBefore')}
                  </div>
                  <div className='diffcontents__item__old'>
                    <div className='diffcontents__item__old__nodata'>
                      {t('components.compare.addChap')}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='diffcontents__item__title'>
                    {`${t('components.compare.revisionBefore')} ${formatDate(
                      compare.oldRule.revisedAt
                    )}`}
                  </div>
                  <div className='diffcontents__item__old'>
                    {diff.oldChapter?.fileType === 'content' ? (
                      diff && parse(diff.oldChapter.content)
                    ) : (
                      <DocViewer
                        prefetchMethod='GET'
                        activeDocument={diff.oldChapter}
                        documents={[diff.oldChapter]}
                        pluginRenderers={DocViewerRenderers}
                        config={{ header: { disableHeader: true } }}
                        className='docviewer__container'
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        );
      })()}
    </div>
  );
};

export default CompareRuleDiff;
