import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useModRule from '../../../common/hooks/useModRule';
import useModChapter from '../../../common/hooks/useModChapter';
import AmendRuleAddContent from './AmendRuleAddContent';
import AmendRuleAddFile from './AmendRuleAddFile';
import AmendRuleRevStatus from './AmendRuleRevStatus';

const OPTIONS = [
  { i: 0, value: 'CH', sortNum: 2, label: 'CHAPTER' },
  { i: 1, value: 'APP', sortNum: 3, label: 'APPENDIX' },
];

const AmendRuleRightMain = () => {
  const { modRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const { t } = useTranslation();

  const handleFileType = target => {
    if (modChapter.modType === 'new') {
      const chapterIndex = modRule.modChapterRef.filter(
        el => el.chapterType === target
      ).length;
      setModChapter({
        fileType: target, // type : content / file
        modType: 'new',
        chapterType: 'CH',
        content: '',
        uri: '',
        chapterIdx: chapterIndex,
        chapterNumber: `${modRule.ruleNumber}-${target}${chapterIndex + 1}`,
        sortNum: 2,
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      return;
    }
    setModChapter(prev => ({
      ...prev,
      fileType: target,
    }));
  };

  const handleChapterType = e => {
    e.preventDefault();
    const { value, sortNum } = e.target;
    const chapterIndex = modRule.modChapterRef.filter(
      el => el.chapterType === value
    ).length;

    setModChapter(prev => ({
      ...prev,
      chapterType: value,
      chapterIdx: chapterIndex,
      sortNum,
      chapterNumber: `${modRule.ruleNumber}-${value}${chapterIndex + 1}`,
    }));
  };

  const onChangeModChapter = e => {
    const { value, name } = e.target;
    setModChapter({
      ...modChapter,
      [name]: value,
    });
  };

  return !modRule?._id ? (
    <div>{t('modrule.select-category-q')}</div>
  ) : (
    <div className='enactrule__main__addchapter'>
      {modChapter?.fileType !== 'revstatus' && (
        <div className='addchapter__header'>
          <div className='addchapter__header__tabs'>
            {modRule.viewType !== 'files' && (
              <button
                type='button'
                aria-hidden='true'
                className={`addchapter__tabs__item ${
                  modChapter?.fileType === 'content' && 'active'
                }`}
                onClick={() => handleFileType('content')}>
                {t('modrule.text')}
              </button>
            )}
            {modRule.viewType !== 'contents' && (
              <button
                type='button'
                aria-hidden='true'
                className={`addchapter__tabs__item ${
                  ['pdf', 'xls', 'xlsx', 'doc', 'docx'].some(
                    docType => modChapter?.fileType === docType
                  ) && 'active'
                }`}
                onClick={() => handleFileType('pdf')}>
                {t('modrule.file')}
              </button>
            )}
          </div>
          <div className='addchapter__header__modtypeselect'>
            <Form.Select
              aria-label='Default select example'
              onChange={handleChapterType}
              disabled={modChapter._id}
              value={modChapter.chapterType}>
              {OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='addchapter__header__numberinput'>
            <InputGroup>
              <InputGroup.Text>{t('modrule.chap-num')}</InputGroup.Text>
              <Form.Control
                aria-label='chapterNumber'
                name='chapterNumber'
                onChange={onChangeModChapter}
                value={modChapter.chapterNumber}
              />
            </InputGroup>
          </div>
          <div className='addchapter__header__nameinput'>
            <InputGroup>
              <InputGroup.Text>{t('modrule.chap-title')}</InputGroup.Text>
              <Form.Control
                aria-label='chapterName'
                name='chapterName'
                onChange={onChangeModChapter}
                value={modChapter.chapterName}
              />
            </InputGroup>
          </div>
        </div>
      )}
      {modChapter?.fileType === '' && <div>{t('modrule.select-chapType')}</div>}
      {modChapter?.fileType === 'revstatus' && <AmendRuleRevStatus />}
      {modChapter?.fileType === 'content' && <AmendRuleAddContent />}
      {['pdf', 'xls', 'xlsx', 'doc', 'docx'].some(
        docType => modChapter?.fileType === docType
      ) && <AmendRuleAddFile />}
    </div>
  );
};

export default AmendRuleRightMain;
