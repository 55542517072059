import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import '../../scss/MyPage/MyPageMain.scss';
import { Link } from 'react-router-dom';
import MyProfile from './MyProfile';
import ProfileUpdate from './ProfileUpdate';
import SecuritySetting from './SecuritySetting';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const MyPageMain = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const [myProfile, setMyProfile] = useState({
    company: '',
    contact: '',
    email: '',
    employno: '',
    grade: '',
    hiredate: '',
    id: '',
    officecontact: '',
    org: {},
    position: {},
    refreshToken: '',
    roles: [],
    username: '',
    profileImg: '',
    signatureImg: '',
  });
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    const profile = async () => {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/mypage/user',
          data: {
            email: auth.email,
          },
        });
        setMyProfile(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    profile();
  }, []);

  const handleClick = tab => {
    setActiveTab(tab);
  };

  return (
    <div className='mypage mainsection'>
      <h4>{t('mypage.title')}</h4>
      <div className='tabmenu'>
        <Button
          role='button'
          aria-hidden='true'
          className={`tabmenu-item ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => handleClick(1)}
          as={Link}>
          {myProfile && myProfile.username}
          {t('mypage.myProfile')}
        </Button>
        <Button
          role='button'
          aria-hidden='true'
          className={`tabmenu-item ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => handleClick(2)}
          as={Link}>
          {t('mypage.profileUpdate.title')}
        </Button>
        <Button
          role='button'
          aria-hidden='true'
          className={`tabmenu-item ${activeTab === 3 ? 'active' : ''}`}
          onClick={() => handleClick(3)}
          as={Link}>
          {t('mypage.security')}
        </Button>
      </div>
      {(() => {
        if (activeTab === 1) {
          return <MyProfile myProfile={myProfile} />;
        }
        if (activeTab === 2) {
          return <ProfileUpdate myProfile={myProfile} />;
        }
        return <SecuritySetting />;
      })()}
    </div>
  );
};

export default MyPageMain;
