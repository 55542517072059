import React, { createContext, useState, useMemo } from 'react';

const initialState = {
  data: [
    {
      _id: '',
      email: '',
      username: '',
      company: '',
      ip: '',
      menu: '',
      action: '',
      createdAt: '',
      __v: 0,
    },
  ],
  totalLogs: 0,
  totalPages: 0,
  currentPage: 0,
};

const LogContext = createContext();

export const LogProvider = ({ children }) => {
  const [log, setLog] = useState(initialState);
  const value = useMemo(() => ({ log, setLog }), [log]);

  return <LogContext.Provider value={value}>{children}</LogContext.Provider>;
};

export default LogContext;
