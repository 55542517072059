import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import { MdOutlineRestartAlt } from 'react-icons/md';
import { BsSearch } from 'react-icons/bs';
import { BiCalendar } from 'react-icons/bi';
import '../../scss/Mobile/MobileLog/MobileLog.scss';
import ReactPaginate from 'react-paginate';
import { format } from 'date-fns';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import MobileSearchBar from '../MobileSearchBar';
import useLog from '../../common/hooks/useLog';
import { formatFullDate } from '../../common/utils/tzUtiles';
import DatePicker from '../../components/DatePicker/DatePicker';

const OPTIONS = [
  { value: '', nameKo: '전체', nameEn: 'ALL' },
  { value: 'ip', nameKo: 'IP', nameEn: 'IP' },
  { value: 'username', nameKo: '이름', nameEn: 'USERNAME' },
  { value: 'email', nameKo: '이메일', nameEn: 'EMAIL' },
];

const MobileLoginLog = () => {
  const axiosAuth = useAxiosAuth();
  const { log, setLog } = useLog();
  const [searchOption, setSearchOption] = useState('');
  const [userLog, setUserLog] = useState({}); // eslint-disable-line no-unused-vars
  const [userLogPagesNum, setUserLogPagesNum] = useState(1); // 페이지네이션_pageNum
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [dateInputs, setDateInputs] = useState({
    startdate: '',
    enddate: '',
  });
  const { startdate, enddate } = dateInputs;
  const { i18n, t } = useTranslation();

  const fetchAllLogs = async (
    start = '',
    end = '',
    page = 1,
    search = '',
    searchBy = ''
  ) => {
    try {
      const response = await axiosAuth.get(
        `/api/log/user-login?startDate=${start}&endDate=${end}&page=${page}&search=${search}&searchBy=${searchBy}`
      );
      setUserLog(response.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeDate = e => {
    const { value, name } = e.target;
    setDateInputs({
      ...dateInputs,
      [name]: value,
    });
  };

  const handleDateClick = async i => {
    setLog(prev => ({ ...prev, period: i }));
    const formatDate = date => date.toISOString().split('T')[0];
    const today = new Date();
    let startDate;
    let endDate = formatDate(today);

    switch (i) {
      case '전체':
        await fetchAllLogs('', '', 1, searchStr, searchOption);
        setDateInputs({ startdate: '', enddate: '' });
        setUserLogPagesNum(1);
        break;
      case '당일':
        startDate = endDate;
        await fetchAllLogs(
          startDate,
          endDate,
          userLogPagesNum,
          searchStr,
          searchOption
        );
        setDateInputs({ startdate: startDate, enddate: endDate });
        setUserLogPagesNum(1);
        break;
      case '전일':
        {
          const yesterday = new Date(today.setDate(today.getDate() - 1));
          startDate = formatDate(yesterday);
          endDate = formatDate(yesterday);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case '1주일':
        {
          const lastWeek = new Date(today.setDate(today.getDate() - 7));
          startDate = formatDate(lastWeek);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case '1개월':
        {
          const lastMonth = new Date(today.setMonth(today.getMonth() - 1));
          startDate = formatDate(lastMonth);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case '6개월':
        {
          const lastSixMonths = new Date(today.setMonth(today.getMonth() - 6));
          startDate = formatDate(lastSixMonths);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      default:
        break;
    }
  };

  const submitDate = async () => {
    await fetchAllLogs(
      startdate,
      enddate,
      userLogPagesNum,
      searchStr,
      searchOption
    );
  };

  const handleReset = logPeriod => {
    if (searchStr) {
      setSearchStr('');
      handleDateClick(logPeriod); // 전체, 당일, 전일, 1주일, 1개월, 6개월 -> re data
      fetchAllLogs();
    }
  };

  useEffect(() => {
    fetchAllLogs(startdate, enddate, userLogPagesNum, searchStr, searchOption);
  }, [userLogPagesNum]);

  const handlePageClick = event => {
    const newPageNum = event.selected + 1;
    setUserLogPagesNum(newPageNum);
  };

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  const handleClick = () => {
    setUserLogPagesNum(1);
    fetchAllLogs(startdate, enddate, userLogPagesNum, searchStr, searchOption);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const LoginLogDownload = async () => {
    setIsLoading(true);
    try {
      const res = await axiosAuth({
        responseType: 'blob',
        method: 'POST',
        url: `/api/log/user-login/download?startDate=${
          startdate || ''
        }&endDate=${enddate || ''}&search=${searchStr || ''}&searchBy=${
          searchOption || ''
        }`,
      });

      if (res.status === 200 && userLog.data) {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        let filename;
        if (startdate !== '' && enddate !== '') {
          filename = `${userLog.data[0].company}_LoginLog_${format(
            new Date(startdate),
            'yyyyMMdd'
          )}-${format(new Date(enddate), 'yyyyMMdd')}.xlsx`;
        } else if (startdate !== '' && enddate === '') {
          const today = new Date();
          filename = `${userLog.data[0].company}_LoginLog_${format(
            new Date(startdate),
            'yyyyMMdd'
          )}-${format(today, 'yyyyMMdd')}.xlsx`;
        } else {
          filename = `${userLog.data[0].company}_LoginLog.xlsx`;
        }

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();

        // 다운로드 후 링크 제거
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // URL 객체 해제
      } else {
        console.error('Failed to download the file.');
      }
    } catch (err) {
      console.error('Error during download process:', err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section
        className='mobileUserLog__subHeader'
        style={{ position: 'relative' }}>
        <div className='dateSearch'>
          <div className='periodbtns'>
            <Button
              className={log.period === '전체' ? 'active' : ''}
              onClick={() => handleDateClick('전체')}>
              {t('userlog.all')}
            </Button>
            <Button
              className={log.period === '당일' ? 'active' : ''}
              onClick={() => handleDateClick('당일')}>
              {t('userlog.today')}
            </Button>
            <Button
              className={log.period === '전일' ? 'active' : ''}
              onClick={() => handleDateClick('전일')}>
              {t('userlog.aday')}
            </Button>
            <Button
              className={log.period === '1주일' ? 'active' : ''}
              onClick={() => handleDateClick('1주일')}>
              {t('userlog.week')}
            </Button>
            <Button
              className={log.period === '1개월' ? 'active' : ''}
              onClick={() => handleDateClick('1개월')}>
              {t('userlog.month')}
            </Button>
            <Button
              className={log.period === '6개월' ? 'active' : ''}
              onClick={() => handleDateClick('6개월')}>
              {t('userlog.6months')}
            </Button>
          </div>

          <div className='dateSelect'>
            <Button
              className={`dateSelect__dateBtn ${
                log.period === '기간' ? 'active' : ''
              }`}
              onClick={() => handleDateClick('기간')}>
              <BiCalendar size={16} />
            </Button>
            <DatePicker
              name='startdate'
              startdate={startdate}
              onChangeDate={onChangeDate}
              language={i18n.language}
              disabled={log.period !== '기간'}
            />
            <span>~</span>
            <DatePicker
              name='enddate'
              startdate={enddate}
              onChangeDate={onChangeDate}
              language={i18n.language}
              disabled={log.period !== '기간'}
            />
            <Button onClick={submitDate}>
              <BsSearch size={12} />
            </Button>
          </div>
        </div>
      </section>
      <section className='mobileUserLogBody'>
        <div className='mobileUserLogBody__subHeader'>
          <div className='mobilelogSearch'>
            <select
              style={{ margin: '0 10px', border: 'none' }}
              onChange={e => setSearchOption(e.target.value)}>
              {OPTIONS.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                  defaultValue={searchOption === option.value}>
                  {i18n.language === 'ko' ? option.nameKo : option.nameEn}
                </option>
              ))}
            </select>
            <div className='mobilelogSearch__searchBox'>
              <MobileSearchBar
                value={searchStr}
                onChange={handlesearchStr}
                handleKeyDown={e => handleKeyDown(e)}
                searchText={handleClick}
              />
            </div>
            <Button
              className='reset__btn'
              onClick={() => handleReset(log.period)}>
              <MdOutlineRestartAlt size={18} />
            </Button>
          </div>
        </div>
        <div className='mobileUserLogBody__table'>
          {userLog &&
            userLog.data &&
            userLog.data.length !== 0 &&
            userLog.data?.map(el => (
              <div
                key={el.id}
                className='mobileUserLogBody__table__loginContents'>
                <div>
                  <span>{el.ip}</span>
                  <span>{el.createdAt && formatFullDate(el.createdAt)}</span>
                </div>
                <div>
                  {el.username} ({el.email})
                </div>
              </div>
            ))}

          {userLog &&
            userLog.data &&
            userLog.data.length > 0 &&
            userLog.data.length < 12 &&
            Array.from({ length: 12 - userLog.data.length }).map((_, index) => (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={`empty-${index}`}>
                <td
                  style={{
                    verticalAlign: 'middle', // 'align-middle'
                    fontSize: '0.875rem', // 'text-sm' = font-size: 14px
                    color: '#1a202c', // 'text-gray-900' = #1a202c (gray-900)
                  }}>
                  &nbsp;
                </td>
                <td
                  style={{
                    fontSize: '0.875rem', // 'text-sm'
                    color: '#6b7280', // 'text-gray-500' = #6b7280 (gray-500)
                  }}>
                  &nbsp;
                </td>
                <td
                  style={{
                    fontSize: '0.875rem', // 'text-sm'
                    verticalAlign: 'middle', // 'align-middle'
                    color: '#1a202c', // 'text-gray-900'
                  }}>
                  &nbsp;
                </td>
                <td
                  style={{
                    fontSize: '0.875rem', // 'text-sm'
                    verticalAlign: 'middle', // 'align-middle'
                    color: '#1a202c', // 'text-gray-900'
                  }}>
                  &nbsp;
                </td>
              </tr>
            ))}
          {userLog &&
            userLog.data &&
            userLog.data.length > 0 &&
            userLog.data.length < 12 &&
            Array.from({ length: 12 - userLog.data.length }).map((_, index) => (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={`empty-${index}`}>
                <td
                  style={{
                    verticalAlign: 'middle', // 'align-middle'
                    fontSize: '0.875rem', // 'text-sm' = font-size: 14px
                    color: '#1a202c', // 'text-gray-900' = #1a202c (gray-900)
                  }}>
                  &nbsp;
                </td>
                <td
                  style={{
                    fontSize: '0.875rem', // 'text-sm'
                    color: '#6b7280', // 'text-gray-500' = #6b7280 (gray-500)
                  }}>
                  &nbsp;
                </td>
                <td
                  style={{
                    fontSize: '0.875rem', // 'text-sm'
                    verticalAlign: 'middle', // 'align-middle'
                    color: '#1a202c', // 'text-gray-900'
                  }}>
                  &nbsp;
                </td>
                <td
                  style={{
                    fontSize: '0.875rem', // 'text-sm'
                    verticalAlign: 'middle', // 'align-middle'
                    color: '#1a202c', // 'text-gray-900'
                  }}>
                  &nbsp;
                </td>
              </tr>
            ))}
        </div>
        <ReactPaginate
          breakLabel='...'
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={totalPages > 0 ? totalPages : 1} // 최소 1을 보장
          forcePage={userLogPagesNum - 1} // 0 기반 인덱스
          previousLabel='Prev'
          nextLabel='Next'
          renderOnZeroPageCount={null}
          containerClassName='pagination-mobile'
          pageLinkClassName='pagination__link'
          activeLinkClassName='pagination__link__active'
        />

        {isLoading && (
          <div
            className='spinner-container'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}>
            <Spinner
              animation='border'
              role='status'
              style={{
                width: '4rem',
                height: '4rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                color: '#20265b',
              }}>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        )}
      </section>
      <button
        type='button'
        onClick={LoginLogDownload}
        style={{
          marginLeft: 'auto',
          border: 'none',
          borderRadius: '5px',
          backgroundColor: '#20265b',
          color: '#fff',
          padding: '8px',
          top: '2vh',
          right: 0,
          position: 'absolute',
        }}>
        {t('userlog.down')}
      </button>
    </>
  );
};

MobileLoginLog.defaultProps = {
  logs: [],
};

export default MobileLoginLog;
