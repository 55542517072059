import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  BsFillCaretUpFill,
  BsFillCaretUpSquareFill,
  BsFillCaretDownSquareFill,
} from 'react-icons/bs';
import { RiCloseFill } from 'react-icons/ri';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import {
  MdAddCircleOutline,
  MdClose,
  MdPrint,
  MdFileDownload,
  MdFileDownloadOff,
  MdPrintDisabled,
  MdOutlineFileDownload,
} from 'react-icons/md';
import Select from 'react-select';
import '../../scss/GroupChart/GradeChart.scss';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const AuthBadge = ({ catenum, gradenum, updateFunc }) => {
  const { t } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const id = catenum._id;
  const gradeId = gradenum.id;
  const currentNum = catenum.authority;
  let authStr;
  if (currentNum === 'admin') {
    authStr = t('groupChart.gradeChart.manager');
  } else if (currentNum === 'user') {
    authStr = t('groupChart.gradeChart.user');
  } else if (currentNum === 'userlog') {
    authStr = t('groupChart.gradeChart.userlog');
  } else if (currentNum === 'print') {
    authStr = t('groupChart.gradeChart.rulePrint');
  } else if (currentNum === 'down') {
    authStr = t('groupChart.gradeChart.ruleDown');
  } else if (currentNum === 'rulemanager') {
    authStr = t('groupChart.gradeChart.draft');
  } else if (currentNum === 'examine') {
    authStr = t('groupChart.gradeChart.reference');
  } else if (currentNum === 'approve') {
    authStr = t('groupChart.gradeChart.approve');
  } else if (currentNum === 'notice') {
    authStr = t('groupChart.gradeChart.notice');
  } else if (currentNum === 'teamManual') {
    authStr = t('groupChart.gradeChart.manual');
  } else {
    authStr = currentNum;
  }

  const handleDel = async () => {
    if (window.confirm(t('groupChart.gradeChart.del-permission-q'))) {
      try {
        const res = await axiosAuth({
          method: 'DELETE',
          url: `/api/grade/graderoles/${gradeId}/${id}`,
        });
        toast(res.data.message || t('groupChart.gradeChart.del-permission'));
        updateFunc();
      } catch (err) {
        toast(t('groupChart.gradeChart.del-failed'));
        console.log(err);
      }
    }
  };

  return (
    <div className='authbadge'>
      <span className='authbadge__rolebtn'>{authStr}</span>
      <span className='authbadge__delbtn'>
        <MdClose size={20} onClick={handleDel} />
      </span>
    </div>
  );
};

const ViewBadge = ({ catenum, gradenum, updateFunc }) => {
  const axiosAuth = useAxiosAuth();
  const id = catenum._id;
  const gradeId = gradenum.id;
  let currentCate = catenum.categoryNumber;
  let currentClass = 'default';
  if (currentCate.includes('M')) {
    currentClass = 'm';
  } else if (currentCate.length > 7) {
    currentClass = 'etc';
    currentCate = catenum.categoryNumber.slice(0, 7);
  } else if (currentCate.includes('SP')) {
    currentClass = 'sp';
  } else if (currentCate.includes('P')) {
    currentClass = 'p';
  } else if (currentCate.includes('I')) {
    currentClass = 'i';
  } else if (currentCate.includes('OCD')) {
    currentClass = 'ocd';
  } else if (currentCate.includes('D')) {
    currentClass = 'd';
  } else if (currentCate.includes('E')) {
    currentClass = 'e';
  }

  const { t } = useTranslation();

  const handleGradeDel = async () => {
    if (window.confirm(t('groupChart.gradeChart.del-permission-q'))) {
      try {
        const res = await axiosAuth({
          method: 'DELETE',
          url: `/api/grade/gradepermissions/${gradeId}/${id}`,
        });
        toast(res.data.message || t('groupChart.gradeChart.del-permission'));
        updateFunc();
      } catch (err) {
        toast(t('groupChart.gradeChart.del-failed'));
        console.log(err);
      }
    }
  };

  const handleSwitch = async per => {
    if (window.confirm(t('groupChart.gradeChart.status-change-q'))) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/grade/gradepermissions/edit`,
          data: {
            gradeperId: catenum._id,
            per,
            perValue: !catenum[per],
          },
        });
        toast(res.data.message || t('groupChart.gradeChart.status-change'));
        updateFunc();
      } catch (err) {
        toast(t('groupChart.gradeChart.status-failed'));
        console.log(err);
      }
    }
  };

  return (
    <div className={`viewbadge ${currentClass}`}>
      <span className='viewbadge__roleBtn'>{currentCate}</span>
      <span className='viewbadge__svgBtn'>
        {catenum.print ? (
          <MdPrint size={23} onClick={() => handleSwitch('print')} />
        ) : (
          <MdPrintDisabled
            size={23}
            onClick={() => handleSwitch('print')}
            style={{ fill: '#ccc' }}
          />
        )}
      </span>
      <span className='viewbadge__svgBtn'>
        {catenum.down ? (
          <MdFileDownload size={23} onClick={() => handleSwitch('down')} />
        ) : (
          <MdFileDownloadOff
            size={23}
            onClick={() => handleSwitch('down')}
            style={{ fill: '#ccc' }}
          />
        )}
      </span>
      <span>
        <MdClose
          id='viewbadge__closeBtn'
          size={20}
          onClick={handleGradeDel}
          style={{ fill: '#000' }}
        />
      </span>
    </div>
  );
};

const CollapseGrade = ({
  grade,
  gradeList,
  setUpdate,
  authsModalOpen,
  setAuthsModalOpen,
}) => {
  // 등급 본체 tr + 접근권한 tr
  const axiosAuth = useAxiosAuth();
  const [clicked, setClicked] = useState(false);
  const [rolesAddOpen, setRolesAddOpen] = useState(false);
  const [graderoles, setGraderoles] = useState();
  const [currentRoleId, setCurrentRoleId] = useState();
  const [catesOptions, setCatesOptions] = useState([]);
  const [targetCate, setTargetCate] = useState({});
  const [gradeChange, setGradeChange] = useState(false);
  const [userList, setUserList] = useState();
  const [currentGrade, setCurrentGrade] = useState();
  const [changeGrade, setChangeGrade] = useState();
  const [targetUserId, setTargetUserId] = useState();
  const [targetGradeId, setTargetGradeId] = useState();
  const { t } = useTranslation();

  const updateAuthView = () => {
    setUpdate(prev => !prev);
  };

  const handleToggle = () => {
    setClicked(!clicked);
  };

  // 유저 등급변경
  const clickGradeChange = async () => {
    setGradeChange(!gradeChange);
    const { id, gradeName } = grade;
    try {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/management/searchUser-grade/${id}`,
      });
      const optionsCopy = [];
      for (let i = 0; i < res.data.length; i += 1) {
        optionsCopy.push({
          label: `${res.data[i].username} (${res.data[i].email})`,
          value: res.data[i]._id,
          grade: gradeName,
        });
      }
      setUserList(optionsCopy);
    } catch (err) {
      console.log(err);
    }
  };

  const currentUserOption = e => {
    setCurrentGrade(e.grade);
    setTargetUserId(e.value);
  };

  const currentGradeOption = e => {
    setTargetGradeId(e.value);
    setChangeGrade(e.label);
  };

  const ChangeGradeSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/management/edit/usergrade`,
        data: {
          grade_id: targetGradeId,
          user_id: targetUserId,
        },
      });
      toast(res.data.message || t('groupChart.gradeChart.group-change'));
    } catch (err) {
      console.log(err);
    }
  };

  // graderoles 추가
  const validauth = text => {
    let result = '';
    if (text === 'admin') {
      result = t('groupChart.gradeChart.manager');
    } else if (text === 'user') {
      result = t('groupChart.gradeChart.user');
    } else if (text === 'userlog') {
      result = t('groupChart.gradeChart.userlog');
    } else if (text === 'print') {
      result = t('groupChart.gradeChart.rulePrint');
    } else if (text === 'down') {
      result = t('groupChart.gradeChart.ruleDown');
    } else if (text === 'rulemanager') {
      result = t('groupChart.gradeChart.draft');
    } else if (text === 'examine') {
      result = t('groupChart.gradeChart.reference');
    } else if (text === 'approve') {
      result = t('groupChart.gradeChart.approve');
    } else if (text === 'notice') {
      result = t('groupChart.gradeChart.notice');
    } else if (text === 'teamManual') {
      result = t('groupChart.gradeChart.manual');
    }
    return result;
  };
  const handleAuthsAdd = async gradeId => {
    setAuthsModalOpen(gradeId);
    const id = gradeId;
    try {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/grade/graderoles/${id}`,
      });
      const grolesCopy = res.data.map(el => ({
        id: el._id,
        roleId: el.roleId,
        authority: validauth(el.authority),
      }));
      setGraderoles(grolesCopy);
    } catch (err) {
      console.log(err);
    }
  };

  const onClick = el => {
    setCurrentRoleId(el.id);
  };

  const graderolesAdd = async gradeId => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/grade/graderoles/add`,
        data: {
          gradeId,
          roleId: currentRoleId,
        },
      });
      toast(res.data.message || t('groupChart.gradeChart.add-permission'));
      setUpdate(prev => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  const handleGradeperAdd = async () => {
    setRolesAddOpen(!rolesAddOpen);
    const { id } = grade;
    try {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/grade/addperslist/${id}`,
      });
      const sortData = res.data.sort(
        (a, b) =>
          a.sortNum - b.sortNum ||
          a.categoryNumber.localeCompare(b.categoryNumber)
      );
      const optionsCopy = sortData.map(option => ({
        label: `[${option.categoryNumber}] ${option.category}`,
        value: option,
      }));
      setCatesOptions(optionsCopy);
    } catch (err) {
      console.log(err);
    }
  };

  const currentRuleOption = e => {
    setTargetCate(e.value);
  };

  const onSubmit = async () => {
    const { id } = grade;
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/grade/gradepermissions/add`,
        data: {
          gradeId: id,
          category: targetCate.category,
          cateNum: targetCate.categoryNumber,
          sortNum: targetCate.sortNum,
          print: false,
          down: false,
        },
      });
      toast(res.data.message || t('groupChart.gradeChart.add-permission'));
      setUpdate(prev => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  // gradepermissions 일괄 적용
  const handleGradeApply = async () => {
    const { id } = grade;
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/grade/gradeapply/all`,
        data: {
          grade_id: id,
        },
      });
      toast(res.data.message || t('groupChart.gradeChart.clear-permission'));
    } catch (err) {
      console.log(err);
    }
  };
  const handelUpFloor = async () => {
    const { id, sortNum } = grade;
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/grade/gradesort`,
        data: {
          gradeId: id,
          sortNum,
          sort: -1,
        },
      });
      setUpdate(prev => !prev);
      toast(res.data.message || t('groupChart.gradeChart.clear-permission'));
    } catch (err) {
      console.log(err);
    }
  };

  const handelDownFloor = async () => {
    const { id, sortNum } = grade;
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/grade/gradesort`,
        data: {
          gradeId: id,
          sortNum,
          sort: 1,
        },
      });
      setUpdate(prev => !prev);
      toast(res.data.message || t('groupChart.gradeChart.clear-permission'));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <tr className='collapsegrade__body'>
        <td>
          <div>
            {grade.sortNum !== 1 ? (
              <BsFillCaretUpSquareFill size={20} onClick={handelUpFloor} />
            ) : (
              <span>{null}</span>
            )}
            <span>{grade.index}</span>
            {grade.sortNum !== (gradeList && gradeList.length) ? (
              <BsFillCaretDownSquareFill size={20} onClick={handelDownFloor} />
            ) : (
              <span>{null}</span>
            )}
          </div>
        </td>
        <td>{grade.gradeName}</td>
        <td className='authsrolesTd'>
          {grade.auths.length === 0 ? (
            <div className='authsrolesTd__empty'>
              {t('groupChart.gradeChart.no-permission')}
            </div>
          ) : (
            grade.auths.map(graderole => (
              <AuthBadge
                key={graderole._id}
                catenum={graderole}
                gradenum={grade}
                updateFunc={updateAuthView}
              />
            ))
          )}
        </td>
        <td className='addbtnTd'>
          <div
            className={`addbtnTd__addBox ${
              authsModalOpen === grade.id ? 'open' : ''
            }`}>
            <MdAddCircleOutline
              size={26}
              onClick={() => handleAuthsAdd(grade.id)}
            />
          </div>
          {authsModalOpen === grade.id ? (
            <div className='addbtnTd__addModal'>
              <div className='addbtnTd__addModal__header'>
                <span>{grade.gradeName}</span>
                <Button onClick={() => setAuthsModalOpen('')}>
                  <RiCloseFill />
                </Button>
              </div>
              <div className='addbtnTd__addModal__body'>
                {graderoles &&
                  graderoles.map(el => (
                    <div key={el.id} className='addbtnTd__addModal__list'>
                      <span>{el.authority}</span>
                      <input
                        type='checkbox'
                        onClick={() => onClick(el)}
                        value={el.roleId}
                      />
                    </div>
                  ))}
              </div>
              <Button
                className='addbtnTd__addModal__addBtn'
                variant='secondary'
                onClick={() => graderolesAdd(grade.id)}>
                {t('button.add')}
              </Button>
            </div>
          ) : null}
        </td>
        <td className='collapsegrade__body__view'>
          <BsFillCaretUpFill
            className={`collapsegrade__body__togglebtn ${
              clicked ? 'open' : 'hide'
            }`}
            onClick={handleToggle}
          />
        </td>
        <td className='collapsegrade__body__gradeChange'>
          <CgArrowsExchangeAltV size={26} onClick={clickGradeChange} />
        </td>
        {gradeChange ? (
          <Modal
            className='gradeChange__modal'
            show={gradeChange}
            size='lg'
            onHide={setGradeChange}
            backdrop='static'
            keyboard={false}>
            <Modal.Header closeButton className='gradeChange__modal__title'>
              <Modal.Title>{t('groupChart.manageGrade.editGrade')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='gradeChange__modal__selectBox'>
              <div className='selectBox__users'>
                <Select
                  styles={blackStyles}
                  placeholder={
                    <div>{t('groupChart.manageGrade.pleaseSelect3')}</div>
                  }
                  isSearchable
                  options={userList}
                  onChange={currentUserOption}
                />
                <div>
                  <span>
                    {t('groupChart.manageGrade.editGradeDescription1')} (
                    {currentGrade}){' '}
                    {t('groupChart.manageGrade.editGradelastexpression1')}
                  </span>
                </div>
              </div>
              <div className='selectBox__grades'>
                <Select
                  styles={blackStyles}
                  placeholder={
                    <div>{t('groupChart.manageGrade.pleaseSelect4')}</div>
                  }
                  isSearchable
                  options={gradeList}
                  onChange={currentGradeOption}
                />
                <div>
                  <span>
                    {t('groupChart.manageGrade.editGradeDescription2')} (
                    {changeGrade}){' '}
                    {t('groupChart.manageGrade.editGradelastexpression2')}
                  </span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className='gradeChange__modal__footer'>
              <Button
                type='submit'
                variant='secondary'
                onClick={ChangeGradeSubmit}>
                {t('button.edit')}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </tr>
      {clicked && (
        <tr className={`collapsegrade__toggle ${clicked ? 'open' : 'Hide'}`}>
          <td className='viewrolesTd' colSpan='5'>
            <div className='viewrolesTd__head'>
              {t('groupChart.manageGrade.ruleAccess')}
            </div>
            <div className='viewrolesTd__badges'>
              {grade.viewroles.map(viewrole => (
                <ViewBadge
                  key={viewrole._id}
                  catenum={viewrole}
                  gradenum={grade}
                  updateFunc={updateAuthView}
                />
              ))}
            </div>
          </td>
          <td className='viewrolesBtns' align='center'>
            <div>
              <MdAddCircleOutline
                className='viewrolesTd__addBox'
                size={26}
                onClick={handleGradeperAdd}
              />
            </div>
            {/* <div> */}
            <Button onClick={handleGradeApply}>{t('button.editAll')}</Button>
            {/* </div> */}
          </td>
          {rolesAddOpen ? (
            <Modal
              className='ruleAdd__modal'
              show={rolesAddOpen}
              size='lg'
              onHide={setRolesAddOpen}
              backdrop='static'
              keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title className='modal__title'>
                  {t('groupChart.manageGrade.addRuleView')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Select
                  styles={blackStyles}
                  placeholder={
                    <div>{t('groupChart.manageGrade.pleaseSelect2')}</div>
                  }
                  isSearchable
                  className='category__select'
                  options={catesOptions}
                  onChange={currentRuleOption}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button type='submit' variant='secondary' onClick={onSubmit}>
                  {t('button.submit')}
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </tr>
      )}
    </>
  );
};

// 뱃지에 커서 올리면 x 버튼 생성
// 뱃지 우측상단에 x 아이콘 추가

// express grades sort gradeId
// express roles sort roleId

const GradeChart = () => {
  const [grades, setGrades] = useState([]);
  const [update, setUpdate] = useState(false);
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const [authsModalOpen, setAuthsModalOpen] = useState('');
  const [gradeList, setGradeList] = useState();
  const [groupAdd, setGroupAdd] = useState(false);
  const [downOpen, setDownOpen] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [targetOrg, setTargetOrg] = useState({
    _id: '',
    name: '',
    path: '',
    company: '',
  });
  const { t } = useTranslation();

  const rolesList = [
    { id: 1, roleName: t('groupChart.gradeChart.manager') },
    { id: 2, roleName: t('groupChart.gradeChart.userlog') },
    { id: 3, roleName: t('groupChart.gradeChart.draft') },
    { id: 4, roleName: t('groupChart.gradeChart.reference') },
    { id: 5, roleName: t('groupChart.gradeChart.approve') },
    { id: 6, roleName: t('groupChart.gradeChart.notice') },
    { id: 7, roleName: t('groupChart.gradeChart.manual') },
  ];

  useEffect(() => {
    const fetchGrades = async () => {
      await axiosAuth
        .get(`/api/grade/all/${auth.company}`)
        .then(res => {
          // console.log(res.data);
          const gradesCopy = res.data.map((gradeCopy, i) => ({
            id: gradeCopy._id,
            index: i + 1,
            gradeName: gradeCopy.gradeName,
            sortNum: gradeCopy.sortNum,
            auths: gradeCopy.graderoles.filter(el => el.authority !== 'user'),
            viewroles: gradeCopy.gradepermissions.sort(
              (a, b) =>
                a.sortNum - b.sortNum ||
                a.categoryNumber.localeCompare(b.categoryNumber)
            ),
          }));
          setGrades(gradesCopy);
          const gradeOptionsCopy = [];
          for (let i = 0; i < res.data.length; i += 1) {
            gradeOptionsCopy.push({
              label: res.data[i].gradeName,
              value: res.data[i]._id,
            });
          }
          setGradeList(gradeOptionsCopy);
        })
        .catch(err => console.log(err));
    };
    fetchGrades();
  }, []);

  useEffect(() => {
    // 권한 삭제, 등록시 state 업데이트
    const fetchGrades = async () => {
      await axiosAuth
        .get(`/api/grade/all/${auth.company}`)
        .then(res => {
          const gradesCopy = res.data.map((gradeCopy, i) => ({
            id: gradeCopy._id,
            index: i + 1,
            gradeName: gradeCopy.gradeName,
            sortNum: gradeCopy.sortNum,
            auths: gradeCopy.graderoles.filter(el => el.authority !== 'user'),
            viewroles: gradeCopy.gradepermissions.sort(
              (a, b) =>
                a.sortNum - b.sortNum ||
                a.categoryNumber.localeCompare(b.categoryNumber)
            ),
          }));
          setGrades(gradesCopy);
          setUpdate(false);
        })
        .catch(err => console.log(err));
    };
    fetchGrades();
  }, [update]);

  const downloadGradeList = async () => {
    if (targetOrg._id === '') {
      toast.error(t('groupChart.gradeChart.department-selet'));
      return;
    }
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/grade/fileDownload',
        responseType: 'blob',
        data: {
          org: targetOrg._id,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const today = new Date();
      link.setAttribute(
        'download',
        `${t('groupChart.gradeChart.group-manage-list')}_${
          targetOrg.name
        }_${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);
    }
  };

  const currentOrg = e => {
    setTargetOrg(e.value);
  };

  const handleDownOpen = async () => {
    if (downOpen === true) {
      setDownOpen(false);
      return;
    }
    setDownOpen(true);
    await axiosAuth
      .get(`/api/org/all/${auth.company}`)
      .then(res => {
        const orgsCopy = res.data.map(orgEl => ({
          label: orgEl.name,
          value: orgEl,
        }));
        setOrgList(orgsCopy);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className='gradechart'>
      <div className='gradechart__header'>
        <Button onClick={() => handleDownOpen()}>
          {t('groupChart.manageGrade.downloadGradelist')}
          <MdOutlineFileDownload size={24} />
        </Button>
      </div>
      {downOpen && (
        <div className='PopupSection'>
          <div className='PopupSection__header'>
            {t('groupChart.manageGrade.downloadGradelist')}
          </div>
          <div className='PopupSection__body'>
            <Select
              styles={blackStyles}
              placeholder={
                <div>{t('groupChart.manageGrade.pleaseSelect1')}</div>
              }
              isSearchable
              options={orgList}
              onChange={currentOrg}
            />
            <div className='PopupSection__body__description'>
              <span>**</span>
              <span>
                {t('groupChart.manageGrade.downloadDescription1')} <br />
                {t('groupChart.manageGrade.downloadDescription2')}
              </span>
            </div>
          </div>
          <div className='PopupSection__bottom'>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => setDownOpen(false)}>
              {t('button.cancel')}
            </Button>
            <Button
              type='submit'
              variant='secondary'
              onClick={downloadGradeList}>
              {t('button.file-down')}
            </Button>
          </div>
          <div className='PopupSection__tail'>
            <div>{t('groupChart.manageGrade.canchecked')}</div>
            <div className='PopupSection__tail__rolesitem'>
              {rolesList.map(role => (
                <span key={role.id}>{role.roleName}</span>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className='gradechart__scrollwrap'>
        {groupAdd ? (
          <Modal
            className='modal'
            show={groupAdd}
            size='m'
            onHide={setGroupAdd}
            backdrop='static'
            keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title className='modal__title'>
                {t('groupChart.manageGrade.addGrade')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>{t('groupChart.manageGrade.gradeName')} :</span>
              <input type='text' />
              <Select
                styles={blackStyles}
                placeholder={
                  <div>{t('groupChart.manageGrade.pleaseSelect2')}</div>
                }
                isSearchable
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                //  variant='secondary'
                // onClick={}
              >
                {t('button.submit')}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
        <Table className='gradechart__table'>
          <thead>
            <tr>
              {/* <th width='5%'>No.</th> */}
              <th width='6%'>{t('groupChart.manageGrade.index')}</th>
              <th width='15%'>{t('groupChart.manageGrade.gradeName')}</th>
              <th width='65%' colSpan='2'>
                {t('groupChart.manageGrade.basicRoles')}
              </th>
              <th width='6%'>{t('groupChart.manageGrade.ruleAccess')}</th>
              <th width='8%'>{t('groupChart.manageGrade.editGrade')}</th>
            </tr>
          </thead>
          <tbody>
            {grades.map(grade => (
              <CollapseGrade
                index={grade.index}
                key={grade.id}
                grade={grade}
                gradeList={gradeList}
                setUpdate={setUpdate}
                authsModalOpen={authsModalOpen}
                setAuthsModalOpen={setAuthsModalOpen}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default GradeChart;
