const Roles = {
  DEVELOPER: 'ROLE_IDX1',
  ADMIN: 'ROLE_IDX2',
  USER: 'ROLE_IDX3',
  USERLOG: 'ROLE_IDX5',
  RULEMANAGER: 'ROLE_IDX6',
  APPROVE: 'ROLE_IDX8',
  TEAMMANUAL: 'ROLE_IDX10',
};

export default Roles;
