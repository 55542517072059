import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import '../../scss/Mobile/MobileRevStatus/MobileRevStatusList.scss';
import { useNavigate } from 'react-router-dom';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
// import MobileRevOne from './MobileRevOne';
import MobileSearchBar from '../MobileSearchBar';

const MobileRevStatusList = () => {
  const navi = useNavigate();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { t } = useTranslation();
  const [record, setRecord] = useState([]);
  const [filterRecord, setFilterRecord] = useState([]);
  const axiosAuth = useAxiosAuth();
  const [searchStr, setSearchStr] = useState('');
  const [sortValues, setSortValues] = useState({
    sortType: 'rev',
    sortUpDown: false,
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchNotice = async () => {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/revstatus/dcns`,
      });
      const dcnList = res.data.map(el => ({
        ...el,
        count: el.modRuleRef.length,
      }));
      setFilterRecord(dcnList);
      setRecord(dcnList);
    };
    fetchNotice();
    return () => {
      controller.abort();
    };
  }, []);

  // Search Bar
  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  useEffect(() => {
    const reg = new RegExp(searchStr, 'gi');
    if (searchStr !== '') {
      const filteredRec = record.filter(
        el =>
          (typeof el.number === 'string' && el.number.match(reg)) ||
          (el.revisionTime && el.revisionTime.match(reg))
      );
      setFilterRecord(filteredRec);
    } else {
      setFilterRecord(record);
    }
  }, [searchStr]);

  // Sorting
  const resetNumber = num => {
    if (num === 'rev') {
      setSortValues(prev => ({
        ...prev,
        sortType: 'rev',
        sortUpDown: !prev.sortUpDown,
      }));
      if (sortValues.sortUpDown) {
        setFilterRecord(
          [...filterRecord].sort((a, b) => {
            return +(a.number < b.number) || -(a.number > b.number);
          })
        );
      } else if (!sortValues.sortUpDown) {
        setFilterRecord(
          [...filterRecord].sort((a, b) => {
            return +(a.number > b.number) || -(a.number < b.number);
          })
        );
      } else {
        setFilterRecord(record);
      }
    }
    if (num === 'date') {
      setSortValues(prev => ({
        ...prev,
        sortType: 'date',
        sortUpDown: !prev.sortUpDown,
      }));
      if (sortValues.sortUpDown) {
        setFilterRecord(
          [...filterRecord].sort((a, b) => {
            return (
              +(a.revisionTime < b.revisionTime) ||
              -(a.revisionTime > b.revisionTime)
            );
          })
        );
      } else if (!sortValues.sortUpDown) {
        setFilterRecord(
          [...filterRecord].sort((a, b) => {
            return (
              +(a.revisionTime > b.revisionTime) ||
              -(a.revisionTime < b.revisionTime)
            );
          })
        );
      } else {
        setFilterRecord(record);
      }
    }
  };

  const onClickDcn = dcn => {
    navi(`/revstatus/${dcn.number}`);
    // 이전 팝업.ver
    // setTargetDcn(dcn);
    // setOpen(true);
    // if (targetDcn.number) navi(`/revstatus/${targetDcn.number}`);
  };

  // 이전 팝업.ver
  // const [targetDcn, setTargetDcn] = useState({
  //   _id: null,
  //   number: null,
  //   modRuleRef: [],
  // });
  // const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   const reg = new RegExp(searchStr, 'gi');
  //   if (searchStr !== '') {
  //     const filteredRec = record.filter(
  //       el =>
  //         (el.number && el.number.match(reg)) ||
  //         (el.revisionTime && el.revisionTime.match(reg))
  //     );
  //     setFilterRecord(filteredRec);
  //   } else {
  //     setFilterRecord(record);
  //   }
  // }, [searchStr]);

  return (
    <div className='mobileRevstatus__main'>
      <div className='mobileRevstatus__listBox'>
        {/* DCN 상단 */}
        <div className='listBox__header'>
          <div className='listBox__header__searchBox'>
            <MobileSearchBar value={searchStr} onChange={handlesearchStr} />
          </div>
        </div>

        {/* DCN 리스트 */}
        <div className='listBox__table'>
          <Table>
            <thead>
              <tr>
                <td>
                  <Button
                    variant='secondary'
                    style={{ padding: '0', border: 'none' }}
                    onClick={() => resetNumber('rev')}>
                    {t('dcn.dcn-number')}{' '}
                    {sortValues.sortType === 'rev' && sortValues.sortUpDown ? (
                      <TiArrowSortedUp
                        size={20}
                        color={
                          sortValues.sortType === 'rev' ? 'orange' : 'gray'
                        }
                      />
                    ) : (
                      <TiArrowSortedDown
                        size={20}
                        color={
                          sortValues.sortType === 'rev' ? 'orange' : 'gray'
                        }
                      />
                    )}
                  </Button>
                </td>
                <td width='18%'>{t('dcn.count')}</td>
                <td>
                  <Button
                    variant='secondary'
                    style={{ padding: '0', border: 'none' }}
                    onClick={() => resetNumber('date')}>
                    {t('dcn.table-date')}{' '}
                    {sortValues.sortType === 'date' && sortValues.sortUpDown ? (
                      <TiArrowSortedUp
                        size={20}
                        color={
                          sortValues.sortType === 'date' ? 'orange' : 'gray'
                        }
                      />
                    ) : (
                      <TiArrowSortedDown
                        size={20}
                        color={
                          sortValues.sortType === 'date' ? 'orange' : 'gray'
                        }
                      />
                    )}
                  </Button>
                </td>
              </tr>
            </thead>
            <tbody>
              {filterRecord &&
                filterRecord.map(rev => (
                  <tr
                    key={rev._id}
                    onClick={() => onClickDcn(rev)}
                    // style={{
                    //   backgroundColor:
                    //     targetDcn === rev._id && open ? '#f5f5f5' : '',
                    //   color:
                    //     targetDcn === rev._id && open
                    //       ? 'rgb(241, 133, 70)'
                    //       : '',
                    // }}
                  >
                    <td>{`DCN${rev.number}`}</td>
                    <td>{rev.count}</td>
                    <td>
                      {timezoneFormat(
                        utcToZonedTime(
                          toDate(rev.revisionTime, {
                            timeZone: 'UTC',
                          }),
                          timezone
                        ),
                        'yyyy-MM-dd',
                        { timeZone: timezone }
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* 이전 팝업.ver */}
      {/* {open && (
        <div className='mobileRevstatus__popupBox'>
          <MobileRevOne
            dcnRuleList={targetDcn.modRuleRef}
            dcnNum={targetDcn.number}
            setOpen={setOpen}
          />
        </div>
      )} */}
    </div>
  );
};

export default MobileRevStatusList;
