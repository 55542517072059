import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MainNav from './MainNav';
import SideNav from './SideNav';
import { EntireProvider } from '../store/EntireProvider';

const initialState = {
  sideNav: {
    lan: 'kor',
  },
};

const Layout = () => {
  const location = useLocation().pathname;
  return (
    <>
      <MainNav path={location} />

      <EntireProvider initialState={initialState}>
        <div className='main-container'>
          <SideNav path={location} />
          <Outlet />
        </div>
      </EntireProvider>
    </>
  );
};

export default Layout;
