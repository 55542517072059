import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import {
  BsCaretDownSquareFill,
  BsCaretRightSquareFill,
  BsCircle,
  BsXLg,
} from 'react-icons/bs';
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from 'react-icons/md';
import '../../../scss/ModRule/ModRuleApprovals.scss';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import useModRule from '../../../common/hooks/useModRule';

const OrgItem = ({ org, handleOrg, targetOrg }) => {
  const click = id => {
    handleOrg(id);
  };

  return (
    <div className='list__orgs__item'>
      <div
        className={`list__orgs__item__title ${
          targetOrg === org._id ? 'active' : ''
        }`}
        onClick={() => click(org._id)}
        aria-hidden='true'>
        <span>
          {(() => {
            if (!org.path) {
              return <HiOutlineOfficeBuilding />;
            }
            if (org.children.length !== 0) {
              return <BsCaretDownSquareFill />;
            }
            return <BsCaretRightSquareFill />;
          })()}
        </span>
        <span>{org.name}</span>
      </div>
      <div className='orgchild'>
        {org.children.length > 0 &&
          org.children.map(child => (
            <OrgItem
              key={`org_${child._id}`}
              org={child}
              handleOrg={handleOrg}
              targetOrg={targetOrg}
            />
          ))}
      </div>
    </div>
  );
};

const ModRuleChangeApproval = ({ changeOpen, setChangeOpen }) => {
  const { modRule, setModRule } = useModRule();
  const [isLoading, setIsLoading] = useState(false);
  const [currentUsers, setCurrentUsers] = useState([]);

  const [examList, setExamList] = useState([]);
  const [apprList, setApprList] = useState([]);
  const [referList, setReferList] = useState([]);

  const [orgs, setOrgs] = useState({});
  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);

  const [targetOrg, setTargetOrg] = useState(null);
  const [targetUser, setTargetUser] = useState({ _id: '', username: '' });
  const [delUser, setDelUser] = useState({ _id: '', username: '' });
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const userList = async () => {
      await axios
        .all([
          axiosAuth.get(`/api/org/orgtree/hos21`),
          axiosAuth.get('/api/modrule/users/all'),
        ])
        .then(
          axios.spread((resOrgs, resUsers) => {
            setOrgs(resOrgs.data);
            const userCopys = resUsers.data.map(el => ({
              ...el,
              fontlen: el.username.length,
            }));
            setUsers(userCopys);
            setFilterUsers(userCopys);
            setIsLoading(false);
          })
        )
        .catch(err => console.log(err));
    };
    userList();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const modRules = async () => {
      try {
        setIsLoading(true);
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/modrule/${modRule._id}`,
        });
        const { examines, approve, reference } = res.data;
        const oriExamines = examines.map(el => ({
          ...el,
          disabled: el.checked,
        }));
        const oriApprove = approve.map(el => ({
          ...el,
          disabled: el.checked,
        }));
        const oriReference = reference.map(el => ({
          ...el,
          disabled: el.checked,
        }));
        setCurrentUsers([...examines, ...approve, ...reference]);

        setExamList(oriExamines);
        setApprList(oriApprove);
        setReferList(oriReference);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    modRules();
  }, []);

  const handleTargetUser = userobj => {
    setTargetUser(prev => ({
      ...prev,
      orgName: userobj.org.name,
      positionName: userobj.position.positionName,
      checked: false,
      ...userobj,
    }));
  };

  const handleOrg = id => {
    setTargetOrg(id);
    if (id === process.env.REACT_APP_HOSTCOMPANY) {
      setFilterUsers(users);
      return;
    }
    const usersCopy = users
      .filter(el => el.org._id === id)
      .sort((a, b) => a.employno - b.employno);
    setFilterUsers(usersCopy);
  };

  const handleAddArrow = type => {
    if (targetUser._id === '') {
      toast.error(t('modrule.select-add-employno'));
      return;
    }
    if (currentUsers.find(el => el.employno === targetUser.employno)) {
      toast.error(t('modrule.same-add-employno'));
      return;
    }

    if (type === 'exam') {
      if (!targetUser.roles.map(el => el.authority).includes('rulemanager')) {
        toast.error(t('modrule.no-examiner-employno'));
        return;
      }
      setCurrentUsers(prevArray => [...prevArray, targetUser]);
      setExamList(prevArr => [...prevArr, targetUser]);
    } else if (type === 'appr') {
      if (!targetUser.roles.map(el => el.authority).includes('approve')) {
        toast.error(t('modrule.no-approve-employno'));
        return;
      }
      setCurrentUsers(prevArray => [...prevArray, targetUser]);
      setApprList(prevArr => [...prevArr, targetUser]);
    } else if (type === 'refer') {
      setCurrentUsers(prevArray => [...prevArray, targetUser]);
      setReferList(prevArr => [...prevArr, targetUser]);
    }
  };

  const handleDelArrow = status => {
    if (delUser._id === '') {
      toast.error(t('modrule.select-exception-employno'));
      return;
    }
    if (status === 'exam') {
      setCurrentUsers(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
      setExamList(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
    } else if (status === 'appr') {
      setCurrentUsers(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
      setApprList(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
    } else {
      setCurrentUsers(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
      setReferList(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
    }
  };

  const changePaymentList = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/approval/draft`,
        data: {
          modRuleId: modRule._id,
          examines: examList,
          approve: apprList,
          reference: referList,
        },
      });
      if (res.status === 200) {
        setModRule(prev => ({
          ...prev,
          examines: res.data.examines,
          approve: res.data.approve,
          reference: res.data.reference,
        }));
        toast(t('modrule.change-approval'));
        setChangeOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      className='modRuleOne__changeModal'
      backdropClassName='double'
      show={changeOpen}
      // size='lg'
      onHide={setChangeOpen}
      backdrop='static'
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{t('modrule.title-change-approval')}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='approvalmodal__warning'>
          <div>{t('modrule.no-change-approval')}</div>
        </section>
        <section className='approvalmodal__body'>
          <div className='list'>
            <div className='list__orgs'>
              <div className='list__orgs__body'>
                {!isLoading && orgs._id && (
                  <OrgItem
                    org={orgs}
                    key={`org_${orgs._id}`}
                    handleOrg={handleOrg}
                    targetOrg={targetOrg}
                  />
                )}
              </div>
            </div>
            <div className='list__users'>
              <div className='list__users__body'>
                {!isLoading && (
                  <table>
                    <thead>
                      <tr>
                        <th width='25%'>{t('table.positionName')}</th>
                        <th width='50%'>{t('table.username')}</th>
                        <th width='12%'>{t('table.rulemanager')}</th>
                        <th width='13%'>{t('table.approve')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterUsers &&
                        filterUsers.map(userel => (
                          <tr
                            className={`list__users__item ${
                              targetUser.username === userel.username
                                ? 'active'
                                : ''
                            }`}
                            key={userel._id}
                            onClick={() => handleTargetUser(userel)}>
                            <td>{`${userel.position.positionName}`}</td>
                            <td
                              style={
                                userel.fontlen > 5
                                  ? {
                                      letterSpacing: '-1px',
                                      fontSize: '0.9rem',
                                    }
                                  : {}
                              }>{`${userel.username}`}</td>
                            <td className='list__users__item__rulemanager'>
                              {userel.roles
                                .map(el => el.authority)
                                .includes('rulemanager') ? (
                                <BsCircle color='green' />
                              ) : (
                                <BsXLg color='red' />
                              )}
                            </td>
                            <td className='list__users__item__approve'>
                              {userel.roles
                                .map(el => el.authority)
                                .includes('approve') ? (
                                <BsCircle color='green' />
                              ) : (
                                <BsXLg color='red' />
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className='approvalContainer'>
            <div className='examineBox'>
              <div className='arrowBox'>
                <div>
                  <MdOutlineKeyboardDoubleArrowRight
                    size={30}
                    onClick={() => handleAddArrow('exam')}
                  />
                </div>
                <div>
                  <MdOutlineKeyboardDoubleArrowLeft
                    size={30}
                    onClick={() => handleDelArrow('exam')}
                  />
                </div>
              </div>
              <div className='listContainer'>
                <span className='examinBox__title'>
                  {t('modrule.examiner-list')}
                </span>
                <div className='listBox'>
                  {examList &&
                    examList.map(ex => (
                      <Button
                        key={ex._id}
                        variant='secondary'
                        disabled={ex.disabled}
                        onClick={() => setDelUser(ex)}>
                        <span>{ex.positionName}</span>
                        {'  '}
                        <span>{ex.username}</span>
                      </Button>
                    ))}
                </div>
              </div>
            </div>
            <div className='approveBox'>
              <div className='arrowBox'>
                <div>
                  <MdOutlineKeyboardDoubleArrowRight
                    size={30}
                    onClick={() => handleAddArrow('appr')}
                  />
                </div>
                <div>
                  <MdOutlineKeyboardDoubleArrowLeft
                    size={30}
                    onClick={() => handleDelArrow('appr')}
                  />
                </div>
              </div>
              <div className='listContainer'>
                <span className='approveBox__title'>
                  {t('modrule.approve-list')}
                </span>
                <div className='listBox'>
                  {apprList &&
                    apprList.map(ap => (
                      <Button
                        key={ap._id}
                        variant='secondary'
                        disabled={ap.disabled}
                        onClick={() => setDelUser(ap)}>
                        <span>{ap.positionName}</span>
                        {'  '}
                        <span>{ap.username}</span>
                      </Button>
                    ))}
                </div>
              </div>
            </div>
            <div className='referenceBox'>
              <div className='arrowBox'>
                <div>
                  <MdOutlineKeyboardDoubleArrowRight
                    size={30}
                    onClick={() => handleAddArrow('refer')}
                  />
                </div>
                <div>
                  <MdOutlineKeyboardDoubleArrowLeft
                    size={30}
                    onClick={() => handleDelArrow('refer')}
                  />
                </div>
              </div>
              <div className='listContainer'>
                <span>{t('modrule.reference-list')}</span>
                <div className='listBox'>
                  {referList &&
                    referList.map(re => (
                      <Button
                        key={re._id}
                        variant='secondary'
                        disabled={re.disabled}
                        onClick={() => setDelUser(re)}>
                        <span>{re.positionName}</span>
                        {'  '}
                        <span>{re.username}</span>
                      </Button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setChangeOpen(false)}>
          {t('button.cancel')}
        </Button>
        <Button
          className='modRuleOne__changeModal__btn'
          data-bs-toggle='modal'
          data-bs-target='#staticBackdrop'
          variant='secondary'
          onClick={changePaymentList}>
          {t('button.edit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModRuleChangeApproval;
