import React, { useEffect, useState } from 'react';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const Tr = ({ one }) => {
  const { t } = useTranslation();
  return (
    <>
      {one.map(rev => (
        <tr key={`${rev.revisedAt} + ${rev._id}`}>
          <td>{rev.revno}</td>
          <td>
            {rev.dcnNumber !== ''
              ? rev.dcnNumber
              : t('components.ruleInfo.enact')}
          </td>
          {rev.revstatus && rev.revstatus.length === 0 ? (
            <td align='left' colSpan={2}>
              {'  '}
            </td>
          ) : (
            <td align='left' colSpan={2}>
              {rev &&
                rev.revstatus &&
                rev.revstatus.map(el => (
                  <tr key={el._id}>
                    <td className='chaptd'>
                      {el.chapter.map(chap => (
                        <p key={chap}>{chap}</p>
                      ))}
                    </td>
                    <td className='sumtd'>
                      {el.summary.map(sum => (
                        <p key={sum}>{sum}</p>
                      ))}
                    </td>
                  </tr>
                ))}
            </td>
          )}
          <td>{rev.revisedAt}</td>
        </tr>
      ))}
    </>
  );
};
const RuleNoticeModal = ({ ruleId }) => {
  const { t } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const [parsedRev, setParsedRev] = useState([]);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    const ruleNoticeFetch = async () => {
      await axiosAuth
        .get(`/api/revstatus/${ruleId}`)
        .then(res => {
          const pasingData = res.data.map(rev => ({
            id: rev._id,
            revno: rev.revno,
            ruleNumber: rev.ruleNumber,
            dcnNumber: rev.dcnNumber ? rev.dcnNumber : '',
            revisedAt: timezoneFormat(
              utcToZonedTime(
                toDate(rev.revisedAt, {
                  timeZone: 'UTC',
                }),
                timezone
              ),
              'yyyy-MM-dd',
              { timeZone: timezone }
            ),
            revstatus: rev.revstatus.map(x => ({
              chapter: x.chapter,
              summary: x.summary,
              _id: x._id,
            })),
            classification: rev.classification,
            remarks: rev.remarks,
            lan: rev.lan,
          }));
          setParsedRev(pasingData);
        })
        .catch(err => {
          console.log(err);
          setParsedRev([]);
        });
    };
    ruleNoticeFetch();
  }, [ruleId]);

  return (
    <table className='modal__revstatus__table'>
      <thead>
        <tr>
          <th width='7%'>{t('table.rev-num')}</th>
          <th width='10%'>{t('table.dcn-num')}</th>
          <th width='13%'>{t('table.chapter')}</th>
          <th width='60%'>{t('table.revision-summary')}</th>
          <th width='10%'>{t('table.date')}</th>
        </tr>
      </thead>
      <tbody>
        {parsedRev.length === 0 ? (
          <tr>
            <td colSpan='5'>{t('components.ruleInfo.data-register')}</td>
          </tr>
        ) : (
          <Tr one={parsedRev} />
        )}
      </tbody>
    </table>
  );
};

export default RuleNoticeModal;
