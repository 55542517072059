import React, { useEffect, useState } from 'react';
import { BiX } from 'react-icons/bi';
import { MdOutlineCreateNewFolder } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';

const ModRuleCirRuleCreateModal = ({ setCirModal, cate, updateData }) => {
  const axiosAuth = useAxiosAuth();
  const [ruleCirInputs, setRuleCirInputs] = useState({
    ruleNumber: '',
    ruleName: '',
  });
  const { ruleNumber, ruleName } = ruleCirInputs;
  const { t } = useTranslation();

  useEffect(() => {
    setRuleCirInputs(prev => ({
      ...prev,
      ruleNumber: cate.categoryNumber,
      ruleName: '',
    }));
  }, [cate]);

  const onChangeRuleCirInputs = e => {
    const { value, name } = e.target;
    setRuleCirInputs(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitCirCreate = async () => {
    if (!ruleNumber.includes(cate.categoryNumber)) {
      toast(t('modrule.include-cir-group-num-category'), {
        autoClose: 3000,
        style: { width: '20vw' },
      });
      return;
    }

    if (ruleNumber === cate.categoryNumber) {
      toast(
        `${t('modrule.same-cir-group-num-category')}\nCATEGORY: ${
          cate.categoryNumber
        }\nCIR: ${ruleNumber}`
      );
      return;
    }

    if (
      window.confirm(
        `${t(
          'modrule.add-cir-group-q'
        )}\nNUMBER : ${ruleNumber}\nNAME: ${ruleName}`
      )
    ) {
      try {
        const { status } = await axiosAuth({
          method: 'POST',
          url: '/api/cir/create',
          data: {
            categoryId: cate._id,
            ruleNumber,
            ruleName,
          },
        });
        if (status === 200) {
          toast(t('modrule.add-cir-group'));
          setCirModal('');
          updateData();
        }
      } catch (error) {
        toast(t('modrule.add-cir-group-failed'));
      }
    } else {
      toast(t('modrule.add-cir-group-cancel'));
    }
  };

  return (
    <div className='cirpopup'>
      <div className='cirpopup__header'>
        <span className='cirpopup__title'>{t('modrule.create-cir-group')}</span>
        <BiX size='30' onClick={() => setCirModal('')} />
      </div>
      <div className='cirpopup__body'>
        <div className='cirpopup__body__cateBox'>
          <span>
            <MdOutlineCreateNewFolder size='30' />
          </span>
          <span>{`[${cate.categoryNumber}] ${cate.category}`}</span>
        </div>
        <div className='cirpopup__body__inputBox'>
          <span>{t('modrule.num')}</span>
          <input
            className='numBox'
            type='text'
            id='cirpopupinput-rulenumber'
            name='ruleNumber'
            value={ruleNumber}
            onChange={onChangeRuleCirInputs}
            placeholder={t('modrule.create-cir-group-num-placeholder')}
          />
        </div>
        <div className='cirpopup__body__inputBox'>
          <span>{t('modrule.name')}</span>
          <input
            className='nameBox'
            type='text'
            id='cirpopupinput-rulename'
            name='ruleName'
            value={ruleName}
            onChange={onChangeRuleCirInputs}
            placeholder={t('modrule.create-cir-group-name-placeholder')}
          />
        </div>
      </div>
      <div className='cirpopup__bottom'>
        <Button variant='secondary' onClick={() => setCirModal('')}>
          {t('button.cancel')}
        </Button>
        <Button variant='secondary' onClick={() => submitCirCreate()}>
          {t('button.create')}
        </Button>
      </div>
    </div>
  );
};

export default ModRuleCirRuleCreateModal;
