import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import '../../scss/Mobile/MobileNotice/MobileNoticeList.scss';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const MobileNoticeList = () => {
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchNotice = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/notice/all',
      });
      setNotices(response.data);
    };
    fetchNotice();
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <div className='mobileNoticeList'>
      <section className='mobileNoticeList__header'>
        <div className='mobileNoticeList__header__count'>
          <span>
            {t('notice.total')}{' '}
            <span style={{ color: '#aaa' }}>{notices.length}</span>
            {t('notice.case')}
          </span>
        </div>
      </section>
      {/* 공지사항 리스트 */}
      <section className='mobileNoticeList__body'>
        {notices &&
          notices.reverse().map(notice => (
            <div className='mobileNoticeList__body__contents' key={notice._id}>
              <ul>
                <li>
                  <NavLink to={`${notice._id}`}>{notice.title}</NavLink>
                </li>
                <li>
                  {notice.updatedAt
                    ? notice.updatedAt.slice(0, 10)
                    : notice.createdAt.slice(0, 10)}
                </li>
              </ul>
              <div>
                <NavLink to={`${notice._id}`}>
                  <MdKeyboardArrowRight size={26} />
                </NavLink>
              </div>
            </div>
          ))}
      </section>
    </div>
  );
};

export default MobileNoticeList;
