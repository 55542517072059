import React, { useRef, useState } from 'react';
import axios from 'axios';
import { BiUndo, BiUpload, BiXCircle } from 'react-icons/bi';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import '../../scss/Notice/NoticeNew.scss';
import { toast } from 'react-toastify';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';

const NoticeNew = () => {
  const { t } = useTranslation();
  const navi = useNavigate();
  const axiosMultipart = useAxiosMultipart();
  const fileInputRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [noticeInputs, setNoticeInputs] = useState({
    title: '',
    content: '',
  });
  const { title, content } = noticeInputs;

  const fileInputClick = () => {
    fileInputRef.current.click();
  };

  const goBack = () => {
    navi('/notices');
  };

  const handleAddFile = e => {
    const { files } = e.target;
    setFileList(prev => [...prev, ...files]);
  };

  const handleDelFile = index => {
    const newFileList = [...fileList];
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const noticeSave = async () => {
    const formData = new FormData();
    const pathDate = format(new Date(), 'yyyy-MM-dd');
    formData.append('noticePath', pathDate);
    formData.append('title', noticeInputs.title);
    formData.append('content', noticeInputs.content);
    if (fileList) {
      for (let i = 0; i < fileList.length; i += 1) {
        formData.append('file', fileList[i]);
      }
    }

    try {
      const response = await axiosMultipart.post('/api/notice/new', formData);
      if (response.status === 200) {
        toast(t('notice.notice-regist'));
        navi('/notices');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 400) {
          toast.error(
            error.response.data.errorMessage || t('notice.title-content-input')
          );
        } else {
          toast.error('An unexpected error occurred.');
        }
      } else {
        toast.error('An unexpected error occurred.');
      }
    }
  };

  const handleDel = () => {
    if (window.confirm(t('notice.writing-cancel'))) {
      setNoticeInputs({
        title: '',
        content: '',
      });
      setFileList(null);
      navi('/notices');
    }
  };

  const onChangeNotice = e => {
    const { value, name } = e.target;
    setNoticeInputs({
      ...noticeInputs,
      [name]: value,
    });
  };

  return (
    <div className='noticeEdit'>
      <section className='noticeEdit_header'>
        <BiUndo size='2.5em' type='button' onClick={goBack} />
      </section>
      <section className='noticeEdit__main'>
        <form action='upload' encType='multipart/form-data'>
          <div className='noticeEdit__main__title'>
            <div>
              <span>{t('table.title')}</span>
              <span>:</span>
            </div>
            <div>
              <input
                type='text'
                value={title}
                name='title'
                placeholder={t('table.placeholder-title')}
                onChange={onChangeNotice}
              />
            </div>
          </div>
          <div className='noticeEdit__main__sub'>
            <div style={{ flexShrink: 1 }}>
              <span>{t('table.attachment')}</span>
              <span>:</span>
            </div>
            <div>
              <input
                ref={fileInputRef}
                multiple
                type='file'
                style={{ display: 'none' }} // input 태그 숨기기
                name='noticefile'
                onChange={handleAddFile}
              />
              <Button variant='primary' type='button' onClick={fileInputClick}>
                <BiUpload size='22' />
                {t('button.upload')}
              </Button>
            </div>
            <div className='noticeEdit__main__filelistcontainer'>
              {fileList?.length > 0 &&
                fileList.map((file, i) => (
                  <div className='filebadge' key={file.name}>
                    <span>{file.name}</span>
                    <span>
                      <BiXCircle size='20' onClick={() => handleDelFile(i)} />
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className='noticeEdit__main__body'>
            <textarea
              type='text'
              value={content}
              name='content'
              placeholder={t('table.placeholder-input')}
              onChange={onChangeNotice}
              className='noticeEdit__main__body'
            />
          </div>
        </form>
      </section>
      <section style={{ gap: '1rem' }} className='noticeEdit__bottom'>
        <Button className='noticeEdit__bottom__btn' onClick={handleDel}>
          {t('button.cancel')}
        </Button>
        <Button
          className='noticeEdit__bottom__btn'
          type='submit'
          onClick={noticeSave}>
          {t('button.submit')}
        </Button>
      </section>
    </div>
  );
};

export default NoticeNew;
