import { useContext, useDebugValue } from 'react';
import EntireContext from '../../store/EntireProvider';

const useEntire = () => {
  const { entire } = useContext(EntireContext);
  useDebugValue(entire, el => (el ? 'ondata' : 'offdata'));
  return useContext(EntireContext);
};

export default useEntire;
