import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsFillPlusCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import DatePicker from '../DatePicker/DatePicker';

const RevStatusInputPopup = ({ targetRev, setTargetRev }) => {
  const axiosAuth = useAxiosAuth();
  const nextSummId = useRef(1);
  const nextChapId = useRef(1);
  const { type } = targetRev;
  const { t, i18n } = useTranslation();
  const [revInfoInput, setRevInfoInput] = useState({
    revNumber: 0,
    dcnNumber: '',
    revisedAt: '',
    lan: '',
    classification: 'amend',
    revstatus: [],
    refRule: '',
  });
  const [chapterItems, setChapterItems] = useState([{ id: 0, text: '' }]);
  const [summaryItems, setSummaryItems] = useState([{ id: 0, text: '' }]);
  useEffect(() => {
    if (targetRev.refRule._id !== '') {
      const id = targetRev.refRule._id;
      (async () => {
        const response = await axiosAuth.get(`/api/revstatus/maxrevnum/${id}`);
        setRevInfoInput(prev => ({
          ...prev,
          revNumber:
            response.data?.length !== 0 ? response.data[0].revNumber : 0,
        }));
      })();
    }
  }, [targetRev.refRule._id]);
  const handleClose = () => {
    setTargetRev({ type: '' });
  };

  const handlesetRevInfoInput = e => {
    const { value, name } = e.target;
    setRevInfoInput({
      ...revInfoInput,
      [name]: value,
    });
  };

  const revSubmit = async () => {
    try {
      const response = await axiosAuth({
        method: 'POST',
        url: '/api/revstatus/dev/newrevstatus',
        data: {
          revNumber: revInfoInput.revNumber,
          dcnNumber: revInfoInput.dcnNumber,
          revisedAt: new Date(revInfoInput.revisedAt),
          revstatus: revInfoInput.revstatus,
          classification: revInfoInput.classification,
          lan: targetRev.refRule.lan,
          refRule: targetRev.refRule._id,
        },
      });
      toast(`${response.status} ${t('revStatus.add-enactAmend-history')}`);
    } catch (error) {
      console.log(error);
      toast(t('revStatus.add-history-failed'));
    }
  };

  const deleteRevstatus = i => {
    const revstatusCopy = revInfoInput.revstatus;
    revstatusCopy.splice(i, 1);
    setRevInfoInput({ ...revInfoInput, revstatus: revstatusCopy });
  };

  const addChapterInput = () => {
    const item = {
      id: nextChapId.current,
      text: '',
    };
    setChapterItems([...chapterItems, item]);
    nextChapId.current += 1;
  };

  const deleteChapterInput = target => {
    setChapterItems(chapterItems.filter(item => item.id !== target));
  };

  const handleChapterChange = (e, index) => {
    if (index > chapterItems.length) return;
    const { value } = e.target;
    setChapterItems(prev =>
      prev.map(one => (one.id === index ? { ...one, text: value } : one))
    );
  };

  const addSummaryInput = () => {
    const item = {
      id: nextSummId.current,
      text: '',
    };
    setSummaryItems([...summaryItems, item]);
    nextSummId.current += 1;
  };

  const deleteSummaryInput = target => {
    setSummaryItems(summaryItems.filter(item => item.id !== target));
  };

  const handleSummaryChange = (e, index) => {
    if (index > summaryItems.length) return;
    const { value } = e.target;
    setSummaryItems(prev =>
      prev.map(one => (one.id === index ? { ...one, text: value } : one))
    );
  };

  const addOneRevstatus = () => {
    const chapterArr = chapterItems.map(chap => chap.text);
    const summaryArr = summaryItems.map(summ => summ.text);
    const revstatusCopy = [...revInfoInput.revstatus];
    revstatusCopy.push({
      chapter: chapterArr,
      summary: summaryArr,
    });
    setRevInfoInput(prev => ({
      ...prev,
      revstatus: revstatusCopy,
    }));
  };

  return (
    <Modal
      backdropClassName='double'
      dialogClassName='revInput__popup'
      contentClassName='revInput__popup__content'
      show={type === 'new'}
      // onHide={() => setTargetRev({ type: '' })}
      backdrop='static'
      keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <span>{t('revStatus.add-amend-history')}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th width='5%'>{t('dcn.rev-num')}</th>
              <th width='10%'>{t('dcn.table-number')}</th>
              <th width='15%'>{t('table.enact-date')}</th>
              <th width='30%'>{t('table.chap-num')}</th>
              <th width='40%'>{t('table.rev-sum')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                rowSpan={revInfoInput.revstatus.length}
                style={{ borderLeft: 'none' }}>
                <input
                  style={{ width: '100%' }}
                  type='text'
                  name='revNumber'
                  onChange={handlesetRevInfoInput}
                  value={revInfoInput.revNumber}
                />
              </td>
              <td rowSpan={revInfoInput.revstatus.length}>
                <input
                  style={{ width: '100%' }}
                  type='text'
                  name='dcnNumber'
                  onChange={handlesetRevInfoInput}
                  value={revInfoInput.dcnNumber}
                />
              </td>
              <td rowSpan={revInfoInput.revstatus.length}>
                <DatePicker
                  name='revisedAt'
                  startdate={revInfoInput.revisedAt}
                  onChangeDate={handlesetRevInfoInput}
                  language={i18n.language}
                  style={{ width: '100%' }}
                />
              </td>
              <td>
                <table>
                  {revInfoInput.revstatus.map(rev => (
                    <tr>
                      <td>
                        {rev.chapter.map(chap => (
                          <div key={chap}>{chap}</div>
                        ))}
                      </td>
                    </tr>
                  ))}
                </table>
              </td>
              <td>
                <table>
                  {revInfoInput.revstatus.map((rev, i) => (
                    <tr>
                      <td>
                        {rev.summary.map(summ => (
                          <div key={summ}>{summ}</div>
                        ))}
                      </td>
                      <td>
                        <BsFillXCircleFill onClick={() => deleteRevstatus(i)} />
                      </td>
                    </tr>
                  ))}
                </table>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td />
              <td />
              <td />
              <td style={{ verticalAlign: 'top' }}>
                {chapterItems.map(chap => (
                  <div key={`chap_${chap.id}`}>
                    <span style={{ width: '10%', padding: '0 5px' }}>-</span>
                    <input
                      style={{ width: '80%' }}
                      type='text'
                      onChange={e => handleChapterChange(e, chap.id)}
                      value={chap.text}
                    />
                    <span style={{ width: '10%', padding: '0 5px' }}>
                      {chap.id === 0 && (
                        <BsFillPlusCircleFill onClick={addChapterInput} />
                      )}
                      {chap.id > 0 && (
                        <BsFillXCircleFill
                          onClick={() => deleteChapterInput(chap.id)}
                        />
                      )}
                    </span>
                  </div>
                ))}
              </td>
              <td style={{ verticalAlign: 'top' }}>
                {summaryItems.map(summ => (
                  <div key={`sum_${summ.id}`}>
                    <span
                      style={{
                        width: '5%',
                        display: 'inline-block',
                        padding: '0 10px',
                      }}>
                      -
                    </span>
                    <input
                      style={{ width: '90%' }}
                      type='text'
                      onChange={e => handleSummaryChange(e, summ.id)}
                      value={summ.text}
                    />
                    <span
                      style={{
                        width: '5%',
                        display: 'inline-block',
                        padding: '0 10px',
                      }}>
                      {summ.id === 0 && (
                        <BsFillPlusCircleFill onClick={addSummaryInput} />
                      )}
                      {summ.id > 0 && (
                        <BsFillXCircleFill
                          onClick={() => deleteSummaryInput(summ.id)}
                        />
                      )}
                    </span>
                  </div>
                ))}
                <div>
                  <Button style={{ float: 'right' }} onClick={addOneRevstatus}>
                    {t('button.add-sum')}
                  </Button>
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={revSubmit}>{t('button.submit-new')}</Button>
        <Button onClick={handleClose}>{t('button.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RevStatusInputPopup;
