import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import htmldiffMin from 'htmldiff-js';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../../common/utils/SelectStyles';
import useModRule from '../../../common/hooks/useModRule';
import useModChapter from '../../../common/hooks/useModChapter';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import EnactRuleFileDocViewer from '../EnactRule/EnactRuleFileDocViewer';

const ModRuleOneAmend = () => {
  const axiosAuth = useAxiosAuth();
  const { modRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const [diffNew, setDiffNew] = useState('');
  const [newConList, setNewConList] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (modRule.modChapterRef.length > 0) {
      const optioncopy = modRule.modChapterRef.map(newc => ({
        label: `${newc.chapterNumber} : ${newc.chapterName}`,
        value: newc,
      }));
      setNewConList(optioncopy);
    }
  }, []);

  useEffect(() => {
    if (
      modChapter?.fileType === 'content' &&
      modChapter.modType === 'replace'
    ) {
      const output = htmldiffMin.execute(
        modChapter.oldChapterId.content,
        modChapter.content
      );
      setDiffNew(output);
    }
    if (modChapter?.fileType === 'content' && modChapter.modType === 'new') {
      setDiffNew(modChapter.content);
    }
  }, [modChapter]);

  const handleChapter = async e => {
    const { value } = e;
    try {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/approval/oldcapture/${value._id}`,
      });
      setModChapter(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className='modRuleOne__content__header'>
        <div className='modRuleOne__content__header__left'>
          <Select
            styles={blackStyles}
            isSearchable
            placeholder={t('modrule.details-amend')}
            options={newConList}
            onChange={handleChapter}
          />
          <span>{t('modrule.chap-amend-num')} : </span>
          <span>{modRule.modChapterRef && modRule.modChapterRef.length}</span>
        </div>
      </div>
      <div className='modRuleOne__content__amend'>
        {(() => {
          if (modChapter?._id && modChapter.fileType === 'content') {
            return (
              <>
                <div className='modRuleOne__content__amend__oldRule'>
                  <div>
                    <span>
                      {modChapter.modType === 'new'
                        ? t('modrule.already-create-chap')
                        : parse(modChapter.oldChapterId.content)}
                    </span>
                  </div>
                </div>
                <div className='modRuleOne__content__amend__NewRule'>
                  <span>
                    {modChapter.modType === 'delete'
                      ? t('modrule.already-del-chap')
                      : parse(diffNew)}
                  </span>
                </div>
              </>
            );
          }
          if (modChapter?._id && modChapter.fileType !== 'content') {
            return (
              <>
                <div className='modRuleOne__content__amend__oldRule'>
                  {modChapter.modType !== 'new' ? (
                    <EnactRuleFileDocViewer
                      propUri={modChapter.oldChapterId.uri}
                      propFileType={modChapter.oldChapterId.fileType}
                    />
                  ) : (
                    <span>{t('modrule.already-create-chap')}</span>
                  )}
                </div>
                <div className='modRuleOne__content__amend__NewRule'>
                  {modChapter.modType !== 'delete' ? (
                    <EnactRuleFileDocViewer
                      propUri={modChapter.uri}
                      propFileType={modChapter.fileType}
                    />
                  ) : (
                    <span>{t('modrule.already-del-chap')}</span>
                  )}
                </div>
              </>
            );
          }
          return <div>{t('modrule.no-select-chap')}</div>;
        })()}
      </div>
    </>
  );
};

export default ModRuleOneAmend;
