import React, { useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import useModRule from '../../../common/hooks/useModRule';
import useModChapter from '../../../common/hooks/useModChapter';
import '../../../scss/ModRule/EnactRuleAddChapter.scss';
import useAxiosMultipart from '../../../common/hooks/useAxiosMultipart';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import EnactRuleFileDocViewer from './EnactRuleFileDocViewer';
import CustomFileInput from '../../DatePicker/FileInput';

const EnactRuleAddFile = () => {
  const { t, i18n } = useTranslation();
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const [file, setFile] = useState();
  const prevFileRef = useRef(modChapter.uri);
  const [uploading, setUploading] = useState(false);

  const axiosMultipart = useAxiosMultipart();
  const axiosAuth = useAxiosAuth();

  const handlePreviewUpload = async e => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/approval/file/preview',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setModChapter(prev => ({
        ...prev,
        uri: res.data.url,
        fileType: res.data.docType,
      }));
    } catch (err) {
      toast(t('modrule.preview-failed'));
    }
  };

  const submitEditFile = async () => {
    if (!modChapter.chapterNumber.includes(modRule.ruleNumber)) {
      toast(`${t('modrule.includes-rule-chap-ex')} ${modRule.ruleNumber}-CH1`);
      return;
    }

    if (prevFileRef === modChapter.uri) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/approval/chapter/editfileinfo',
          data: {
            modRuleId: modRule._id,
            modChapterId: modChapter._id,
            chapterType: modChapter.chapterType,
            chapterIdx: modChapter.chapterIdx,
            chapterNumber: modChapter.chapterNumber,
            chapterName: modChapter.chapterName,
          },
        });
        toast(
          `${t('modrule.chap')} ${modChapter.chapterNumber} ${t(
            'modrule.edit-complete'
          )}`
        );
        setModRule(prev => ({ ...prev, ...res.data }));
        setModChapter({
          fileType: '',
          modType: '',
          chapterType: '',
          content: '',
          uri: '',
          chapterIdx: 0,
          chapterNumber: '',
          chapterName: '',
          chapterContents: {
            header: '',
            children: [],
          },
        });
        setUploading(false);
      } catch (err) {
        toast(t('modrule.edit-chap-failed'));
        setUploading(false);
      }
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append('rulePath', modRule.ruleNumber);
    formData.append('modRuleId', modRule._id);
    formData.append('modChapterId', modChapter._id);
    formData.append('chapterType', modChapter.chapterType);
    formData.append('chapterIdx', modChapter.chapterIdx);
    formData.append('chapterNumber', modChapter.chapterNumber);
    formData.append('chapterName', modChapter.chapterName);
    formData.append('prevUrl', prevFileRef.current);
    formData.append('file', file);

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/approval/chapter/editfile',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });

      // modRule 업데이트 해야함
      toast(
        `${t('modrule.chap')} ${modChapter.chapterNumber} ${t(
          'modrule.edit-complete'
        )}`
      );
      setModRule(prev => ({ ...prev, ...res.data }));
      setModChapter({
        fileType: '',
        modType: '',
        chapterType: '',
        content: '',
        uri: '',
        chapterIdx: 0,
        chapterNumber: '',
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      prevFileRef.current = '';
      setUploading(false);
    } catch (err) {
      toast(t('modrule.edit-chap-failed'));
      setUploading(false);
    }
  };

  const submitAddFile = async () => {
    if (!modChapter.chapterNumber.includes(modRule.ruleNumber)) {
      toast(`${t('modrule.includes-rule-chap-ex')} ${modRule.ruleNumber}-CH1`);
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append('rulePath', modRule.ruleNumber);
    formData.append('modRuleId', modRule._id);
    formData.append('modType', modChapter.modType);
    formData.append('chapterType', modChapter.chapterType);
    formData.append('chapterIdx', modChapter.chapterIdx);
    formData.append('chapterNumber', modChapter.chapterNumber);
    formData.append('chapterName', modChapter.chapterName);
    formData.append('lan', modRule.lan);
    formData.append('file', file);

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/approval/chapter/newfile',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setModRule(prev => ({ ...prev, ...res.data }));
      setModChapter({
        fileType: '',
        modType: '',
        chapterType: '',
        content: '',
        uri: '',
        chapterIdx: 0,
        chapterNumber: '',
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      setUploading(false);
    } catch (err) {
      toast(t('modrule.create-chap-failed'));
      setUploading(false);
    }
  };

  const submitDeleteFile = async () => {
    setUploading(true);

    try {
      const res = await axiosAuth({
        method: 'DELETE',
        url: '/api/approval/chapter/deletefile/enact',
        data: {
          modRuleId: modRule._id,
          modChapterId: modChapter._id,
        },
      });
      setModRule(prev => ({ ...prev, ...res.data }));
      setModChapter({
        fileType: '',
        modType: '',
        chapterType: '',
        content: '',
        uri: '',
        chapterIdx: 0,
        chapterNumber: '',
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      setUploading(false);
    } catch (err) {
      toast(t('modrule.del-chap-error'));
      setUploading(false);
    }
  };

  if (modRule.viewType === 'contents') {
    return (
      <div>
        {i18n.language === 'ko' ? (
          <>
            <p>텍스트 타입의 규정에는 파일 챕터를 등록할 수 없습니다.</p>
            <p>
              규정타입을
              <span style={{ color: 'orange' }}> 파일</span> 혹은
              <span style={{ color: 'orange' }}> 혼합</span>으로 변경하고
              등록해주세요.
            </p>
          </>
        ) : (
          <>
            <p>File chapters cannot be registered for text-type regulations.</p>
            <p>
              Please change the regulation type to
              <span style={{ color: 'orange' }}> File</span> or
              <span style={{ color: 'orange' }}> Mixed</span> and register it.
            </p>
          </>
        )}
      </div>
    );
  }

  return (
    <div className='addfile'>
      <div className='addfile__side'>
        <div className='addfile__side__submitbtn'>
          {modChapter._id && modChapter._id !== '' ? (
            <>
              <Button style={{ width: '100%' }} onClick={submitEditFile}>
                {uploading && (
                  <Spinner animation='border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                )}
                <span className='chapBtnSvg'>
                  <MdOutlineKeyboardDoubleArrowLeft />
                  <MdOutlineKeyboardDoubleArrowLeft />
                </span>
                {t('modrule.edit-chap-btn')}
              </Button>
              <Button
                style={{
                  width: '100%',
                  marginTop: '10px',
                }}
                onClick={submitDeleteFile}>
                {uploading && (
                  <Spinner animation='border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                )}
                <span className='chapBtnSvg'>
                  <MdOutlineKeyboardDoubleArrowLeft />
                  <MdOutlineKeyboardDoubleArrowLeft />
                </span>
                {t('modrule.del-chap-btn')}
              </Button>
            </>
          ) : (
            <Button style={{ width: '100%' }} onClick={submitAddFile}>
              {uploading && (
                <Spinner animation='border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              )}
              <span className='chapBtnSvg'>
                <MdOutlineKeyboardDoubleArrowLeft />
                <MdOutlineKeyboardDoubleArrowLeft />
              </span>
              {t('modrule.create-chap-btn')}
            </Button>
          )}
        </div>
      </div>
      <div className='addfile__fileinput'>
        <div>
          <Form.Group
            controlId='formFileMultiple'
            encType='multipart/form-data'
            className='mb-3 formContentTop__form'>
            <CustomFileInput
              type='file'
              name='file'
              multiple
              onChange={handlePreviewUpload}
              style={{ width: '100%' }}
            />
            <Form.Text className='text-muted'>
              {t('modrule.extension')} : doc / docx / xls / xlsx / pdf
            </Form.Text>
          </Form.Group>
        </div>
        <div className='addfile__previewer'>
          {modChapter?.uri && modChapter.uri !== '' && (
            <EnactRuleFileDocViewer
              propUri={modChapter.uri}
              propFileType={modChapter.fileType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(EnactRuleAddFile);
