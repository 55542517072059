import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { BiX } from 'react-icons/bi';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';

const ModRuleCirDel = ({ cir }) => {
  const [targetCir, setTargetCir] = useState({});
  const [chapter, setChapter] = useState({});
  const [docs, setDocs] = useState([]);
  const axiosAuth = useAxiosAuth();
  const viewerRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCir = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/cir/list/${cir._id}`,
        });
        setTargetCir(res.data);
        setDocs(res.data.chapterRef);
        setChapter(res.data.chapterRef[0]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCir();
  }, [cir]);

  const handleDocumentChange = document => {
    if (document) {
      setChapter(document);
    }
  };

  const submitDelCir = async chap => {
    if (window.confirm(t('modrule.cancel-cir-q'))) {
      try {
        const res = await axiosAuth({
          method: 'DELETE',
          url: '/api/cir/chapter/delete',
          data: {
            chapterNumber: chap.chapterNumber,
            ruleId: targetCir._id,
          },
        });
        setTargetCir(res.data);
        setDocs(res.data.chapterRef);
        setChapter(res.data.chapterRef[0]);
        toast(`${chap.chapterNumber} ${t('modrule.cancel-cir')}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  //   캔슬 되돌리기
  //   @ cir 삭제 취소
  // url: api/cir/approval/restore
  // method: PUT
  // body: { chapterNumber, ruleId } (편집됨)

  return (
    <div className='modcirdel'>
      <div className='modcirdel__side'>
        <h4 style={{ padding: '0 10px' }}>
          {targetCir.ruleNumber} {targetCir.ruleName}{' '}
          <span className='modcirdel__side__titletype'>
            {t('button.delete')}
          </span>
        </h4>
        <div className='modcirdel__side__currentcirlist'>
          {targetCir?.chapterRef?.length > 0 &&
            targetCir.chapterRef.map(chap => (
              <div className='modcirdel__side__currentcirlist__item'>
                <div
                  aria-hidden='true'
                  onClick={() => handleDocumentChange(chap)}
                  style={{
                    color: chap._id === chapter._id ? 'orange' : '',
                    textDecoration:
                      chap.version === 'cancelled' && 'line-through red',
                  }}
                  key={chap._id}>
                  {chap.chapterNumber} {chap.chapterName}{' '}
                </div>
                <div>
                  {chap.version !== 'cancelled' && (
                    <Button
                      variant='secondary'
                      size='sm'
                      className='modcirdel__side__currentcirlist__item__btn'
                      type='button'
                      onClick={() => submitDelCir(chap)}>
                      <BiX size='25' fill='red' />
                    </Button>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div>
        <div>
          <span>{t('modrule.preview-cir')}</span>
        </div>
        <div className='modcirnew__previewer'>
          <DocViewer
            ref={viewerRef}
            prefetchMethod='GET'
            activeDocument={chapter}
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            onDocumentChange={handleDocumentChange}
            config={{
              header: {
                disableHeader: true,
              },
              csvDelimiter: ',',
              pdfZoom: {
                defaultZoom: 0.9,
              },
              pdfVerticalScrollByDefault: true,
            }}
            className='docviewer__container'
          />
        </div>
      </div>
    </div>
  );
};

export default ModRuleCirDel;
