import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeXls,
  BsFiletypeXlsx,
} from 'react-icons/bs';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import '../../scss/Mobile/MobileTeamManual/MobileTeamManual.scss';
import useAuth from '../../common/hooks/useAuth';

const MobileTeamManual = () => {
  const { orgId } = useParams();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const [manuals, setManuals] = useState([]);
  // const [filterManuals, setFilterManuals] = useState([]);
  const [teamlist, setTeamlist] = useState([]);
  const navi = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const fetchManuals = async () => {
      try {
        const res = await axiosAuth.get(`/api/manual/all/${orgId}`);
        setManuals(
          res.data.map(el => ({
            ...el,
            parsedNumber:
              el.manualNumber.replace(orgId, '').replace('-', '') ||
              el.manualNumber,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchManuals();
    return () => {
      controller.abort();
    };
  }, [orgId]);

  useEffect(() => {
    const fetchTeamlist = async () => {
      try {
        const res = await axiosAuth.post(`/api/manualPer/manuals`, {
          userId: auth.id,
          email: auth.email,
          company: auth.company,
        });
        const teamlistCopy = res.data.map(el => ({
          value: el._id,
          label: el.name,
        }));
        setTeamlist(teamlistCopy);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeamlist();
  }, []);

  const handleChange = e => {
    navi(`/teammanual/${e.target.value}`);
  };

  return (
    <>
      <section className='teammanual__header'>
        <select onChange={handleChange}>
          {teamlist.map(team => (
            <option
              key={team.value}
              defaultValue={orgId === team.value}
              value={team.value}>
              {team.label}
            </option>
          ))}
        </select>
      </section>

      <section className='teammanual__manuallist'>
        {manuals.map(manual => (
          <div
            className='teammanual__manuallist__item'
            key={manual._id}
            aria-hidden='true'
            onClick={() => {
              navi(`/teammanual/${manual.orgId._id}/${manual.manualNumber}`);
            }}>
            <div>
              {(() => {
                if (manual.docType === 'doc') {
                  return <BsFiletypeDoc />;
                }
                if (manual.docType === 'docx') {
                  return <BsFiletypeDocx />;
                }
                if (manual.docType === 'xlsx') {
                  return <BsFiletypeXlsx />;
                }
                return <BsFiletypeXls />;
              })()}
            </div>
            <div>
              <div>
                <span>{manual.parsedNumber}</span>
                <span>{manual.manualName}</span>
              </div>
              <span>{manual.createdAt.slice(0, 10)}</span>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default MobileTeamManual;
