import React, { useState, useEffect, useRef } from 'react';
import jwtDecode from 'jwt-decode';
import '../scss/Mobile/MobileLogin.scss';
import Form from 'react-validation/build/form';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import useAuth from '../common/hooks/useAuth';

const MobileLogin = () => {
  const { t } = useTranslation();
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const form = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [checkbox, setCheckbox] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('email')) {
      setEmail(JSON.parse(localStorage.getItem('email')));
      setCheckbox(true);
    }
  }, []);

  const onChangeUsername = e => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
  };

  const handleLogin = async e => {
    e.preventDefault();

    setMessage('');
    setLoading(true);

    await axios
      .post(
        '/api/auth/signin',
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .then(res => {
        if (res.data.accessToken) {
          localStorage.setItem('persist', true);
          const user = jwtDecode(res.data.accessToken);
          setAuth(prev => ({
            ...prev,
            ...user,
            roles: res.data.roles,
            accessToken: res.data.accessToken,
          }));
          if (checkbox) {
            localStorage.setItem('email', JSON.stringify(user.email));
          } else {
            localStorage.removeItem('email');
          }
          setEmail('');
          setPassword('');
          navigate('/');
        }
      })
      .catch(err => {
        if (!err?.response) {
          setMessage(t('login.manager-inquiry'));
        } else if (err.response?.status === 400) {
          setMessage(t('login.id-pw'));
        } else if (err.response?.status === 401) {
          setMessage(t('login.access-failed'));
        } else {
          setMessage(t('login.login-failed'));
        }
        setLoading(false);
      });
  };
  return (
    <div>
      <Form onSubmit={handleLogin} ref={form} className='loginForm'>
        <div className='loginForm__title'>
          <span>{t('login.loginButton')}</span>
        </div>
        <div className='loginForm__inputsBox'>
          <div>
            <label htmlFor='floatingInput' className='form-label'>
              {t('login.email')}
            </label>
            <input
              type='valid'
              className='form-control'
              id='floatingInput'
              placeholder='name@example.com'
              value={email}
              onChange={onChangeUsername}
              required
              // validations={[required]}
            />
          </div>
          <div>
            <label htmlFor='floatingPassword' className='form-label'>
              {t('login.password')}
            </label>
            <input
              type='password'
              className='form-control'
              id='floatingPassword'
              placeholder='Password'
              value={password}
              onChange={onChangePassword}
              required
              autoComplete='new-password'
            />
          </div>
        </div>
        <div className='loginForm__checkBox'>
          <span>{t('login.rememberMe')}</span>
          <input
            className='checkbox'
            type='checkbox'
            checked={checkbox}
            onChange={() => setCheckbox(!checkbox)}
          />
        </div>
        <div className='loginForm__messageBox'>
          {message && <div className='alert alert-danger'>{message}</div>}
        </div>
        <div className='loginForm__saveBox'>
          <Button
            type='submit'
            disabled={loading}
            className='loginButton'
            // onClick={() => {
            //   console.log('로그인');
            // }}
          >
            <span>{t('login.loginButton')}</span>
          </Button>
          {/* <Button
            // type='submit'
            className='loginButton'
            // onClick={() => {
            //   console.log('비밀번호 찾기');
            // }}
          >
            <span> 비밀번호 찾기</span>
          </Button> */}
        </div>
      </Form>
    </div>
  );
};

export default MobileLogin;
