import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import '../../../scss/ModRule/ModRuleApprovals.scss';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../common/hooks/useAuth';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import useModRule from '../../../common/hooks/useModRule';
import { formatDate } from '../../../common/utils/tzUtiles';

const ModRuleConfirmApproval = ({ confirmOpen, setConfirmOpen }) => {
  const { auth } = useAuth();
  const { modRule, setModRule } = useModRule();
  const axiosAuth = useAxiosAuth();
  const [update, setUpdate] = useState(false);
  const { t, i18n } = useTranslation();

  const parsingStatus = type => {
    switch (type) {
      case 'rejected':
        return i18n.language === 'ko' ? '반려' : 'Rejected';
      case 'approve':
        return i18n.language === 'ko' ? '승인' : 'Approved';
      case 'examine':
        return i18n.language === 'ko' ? '검토' : 'Review';
      default:
        return i18n.language === 'ko' ? '결재' : 'Approval';
    }
  };

  const signExamine = async type => {
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `결재 (${parsingStatus(type)}) 처리 하시겠습니까?`
          : `Approval (${parsingStatus(
              type
            )}) will be processed. Do you want to proceed?`
      )
    ) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/approval/sign',
          data: {
            modRuleId: modRule._id,
            employno: auth.employno,
          },
        });
        if (res.status === 200) {
          setModRule(prev => ({
            ...prev,
            status: res.data?.status,
            examines: res.data?.examines,
            approve: res.data?.approve,
            reference: res.data?.reference,
          }));
          toast(t('modrule.complete-approval'));
        }
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const signApprove = async type => {
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `결재 (${parsingStatus(type)}) 처리 하시겠습니까?`
          : `Approval (${parsingStatus(
              type
            )}) will be processed. Do you want to proceed?`
      )
    ) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/approval/approve',
          data: {
            modRuleId: modRule._id,
            employno: auth.employno,
          },
        });
        if (res.status === 200) {
          setModRule(prev => ({
            ...prev,
            status: res.data?.status,
            examines: res.data?.examines,
            approve: res.data?.approve,
            reference: res.data?.reference,
          }));
          toast(t('modrule.complete-approval'));
        }
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const rejectExamine = async () => {
    if (window.confirm(t('modrule.proceed-rejection-q'))) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/approval/sign/return`,
          data: {
            modRuleId: modRule._id,
            employno: auth.employno,
          },
        });
        if (res.status === 200) {
          setModRule(prev => ({
            ...prev,
            status: res.data?.status,
            examines: res.data?.examines,
            approve: res.data?.approve,
            reference: res.data?.reference,
          }));
          toast(t('modrule.proceed-rejection'));
        }
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const rejectApprove = async () => {
    if (window.confirm(t('modrule.proceed-rejection-q'))) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/approval/approve/return`,
          data: {
            modRuleId: modRule._id,
            employno: auth.employno,
          },
        });
        if (res.status === 200) {
          setModRule(prev => ({
            ...prev,
            status: res.data?.status,
            examines: res.data?.examines,
            approve: res.data?.approve,
            reference: res.data?.reference,
          }));
          toast(t('modrule.proceed-rejection'));
        }
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Modal
      dialogClassName='modRuleOne__confirmModal'
      centered
      show={confirmOpen}
      onHide={setConfirmOpen}
      backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{t('modrule.proceed-approval')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='modRuleOne__line'>
          <div className='modRuleOne__line__itemBox'>
            {/* 결재자 설정 */}
            <div className='drafterBox modRuleBox'>
              <div className='drafterBox__thead modRuleBox__thead'>
                <span>{t('modrule.drafter')}</span>
              </div>
              <div className='drafterBox__tbody modRuleBox__tbody'>
                {modRule._id && (
                  <ul>
                    <li>{modRule.writer.positionName}</li>
                    <li>
                      {modRule.writer.signatureImg !== '' ? (
                        <img
                          src={
                            modRule.writer.signatureImg ||
                            `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                          }
                          className='signatureimg'
                          alt='signatureImg'
                        />
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                          className='dd'
                          alt='signatureImg'
                        />
                      )}
                    </li>
                    <li>
                      {modRule.draftedAt ? formatDate(modRule.draftedAt) : null}
                    </li>
                    <li>{modRule.writer.username}</li>
                  </ul>
                )}
              </div>
            </div>
            <div className='modRuleOne__line__itemBox__subSection'>
              {modRule._id &&
                modRule.examines.map(el => (
                  <div key={el._id} className='reviewerBox modRuleBox'>
                    <div className='reviewerBox__thead  modRuleBox__thead'>
                      <span>{t('modrule.reviewer')}</span>
                    </div>
                    <div className='reviewerBox__tbody  modRuleBox__tbody'>
                      <ul>
                        <li>{el.positionName}</li>
                        {modRule._id && (
                          <li style={{ color: el.checked && 'orange' }}>
                            {(() => {
                              switch (true) {
                                case el.checked === 2:
                                  return (
                                    <span style={{ color: '#bbb' }}>
                                      {t('modrule.proceed-rejection')}
                                    </span>
                                  );
                                case (el.employno === auth.employno &&
                                  el.checked !== 0) ||
                                  el.checked !== 0:
                                  return el && el.signatureImg ? (
                                    <img
                                      src={
                                        el.signatureImg ||
                                        `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                                      }
                                      className='signatureimg'
                                      alt='signatureImg'
                                    />
                                  ) : (
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                                      className='signatureimg'
                                      alt='signatureImg'
                                    />
                                  );
                                case el.employno === auth.employno:
                                  return (
                                    <>
                                      <Button
                                        onClick={() => signExamine('examine')}>
                                        {t('button.approval')}
                                      </Button>
                                      <Button onClick={() => rejectExamine()}>
                                        {t('button.rejected')}
                                      </Button>
                                    </>
                                  );

                                default:
                                  return (
                                    <span style={{ color: '#aaa' }}>
                                      {t('modrule.unconfirmed')}
                                    </span>
                                  );
                              }
                            })()}
                          </li>
                        )}
                        <li>
                          {el.checkedAt ? formatDate(el.checkedAt) : null}
                        </li>
                        <li>{el.username}</li>
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
            <div className='modRuleOne__line__itemBox__subSection'>
              {modRule._id &&
                modRule.approve.map(el => (
                  <div key={el._id} className='paymentBox modRuleBox'>
                    <div className='paymentBox__thead  modRuleBox__thead'>
                      <span>{t('modrule.approve')}</span>
                    </div>
                    <div className='paymentBox__tbody  modRuleBox__tbody'>
                      <ul>
                        <li>{el.positionName}</li>
                        {modRule._id && (
                          <li style={{ color: el.checked && 'orange' }}>
                            {(() => {
                              switch (true) {
                                case el.checked === 2:
                                  return (
                                    <span style={{ color: '#bbb' }}>
                                      {t('modrule.proceed-rejection')}
                                    </span>
                                  );
                                case (el.employno === auth.employno &&
                                  el.checked !== 0) ||
                                  el.checked !== 0:
                                  return el && el.signatureImg ? (
                                    <img
                                      src={
                                        el.signatureImg ||
                                        `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                                      }
                                      className='signatureimg'
                                      alt='signatureImg'
                                    />
                                  ) : (
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                                      className='signatureimg'
                                      alt='signatureImg'
                                    />
                                  );
                                case el.employno === auth.employno &&
                                  modRule._id &&
                                  modRule.examines
                                    .map(a => a.checked)
                                    .every(val => val === 1):
                                  return (
                                    <>
                                      <Button
                                        onClick={() => signApprove('approve')}>
                                        {t('button.approval')}
                                      </Button>
                                      <Button onClick={() => rejectApprove()}>
                                        {t('button.rejected')}
                                      </Button>
                                    </>
                                  );
                                case modRule._id &&
                                  modRule.examines
                                    .map(a => a.checked)
                                    .every(val => val === 1):
                                  return (
                                    <span style={{ color: '#aaa' }}>
                                      {t('modrule.unconfirmed')}
                                    </span>
                                  );
                                default:
                                  return (
                                    <span style={{ color: '#aaa' }}>
                                      {t('modrule.under-review')}
                                    </span>
                                  );
                              }
                            })()}
                          </li>
                        )}
                        <li>{el.checkedAt && formatDate(el.checkedAt)}</li>
                        <li>{el.username}</li>
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
            {/* 참조자 설정 */}
            <div className='readerBox modRuleBox'>
              <div className='readerBox__thead  modRuleBox__thead'>
                <span>{t('modrule.reference')}</span>
              </div>
              <div className='readerBox__tbody  modRuleBox__tbody'>
                {modRule._id && modRule.reference.length !== 0 ? (
                  modRule.reference.map(el => (
                    <ul key={el._id}>
                      <li>{`${el.positionName} ${el.username}`}</li>
                    </ul>
                  ))
                ) : (
                  <ul>
                    <li>{t('modrule.no-reference')}</li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setConfirmOpen(false)}>
          {t('button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModRuleConfirmApproval;
