const blackStyles = {
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#FFF' : '#FFF',
      zIndex: 9999999,
      color: '#000',
      overflowX: 'hidden',
      overflowY: 'hidden',
    };
  },
  // container: provided => {
  //   return {
  //     ...provided,
  //     backgroundColor: '#fff',
  //     border: 'none',
  //     borderRadius: '1vh',
  //     outline: 0,
  //   };
  // },
  control: provided => {
    return {
      ...provided,
      // backgroundColor: '#fff',
      border: 'none',
      boxShadow: 'none',
      outline: 0,
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRight: 'none',
      borderRadius: '4px 0 0 4px',
      color: '#000',
      height: 'auto',
    };
  },
  indicatorsContainer: base => ({
    ...base,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderLeft: 'none',
    color: '#000',
    borderRadius: '0 4px 4px 0',
    height: 'auto',
  }),
  dropdownIndicator: base => ({
    ...base,
    backgroundColor: '#fff',
    color: '#ccc',
    borderRadius: '0 4px 4px 0',
    height: 'auto',
  }),
  menu: base => ({
    ...base,
    zIndex: 9999,
    // backgroundColor: '#fff',
    color: '#000',
    marginTop: 0,
  }),
  singleValue: base => ({
    ...base,
    color: '#000',
  }),
};

export default blackStyles;
