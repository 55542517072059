import React, { useEffect, useState } from 'react';
import '../scss/Mobile/MobileCateList.scss';
import { useLocation, NavLink } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { BsFillCaretUpFill } from 'react-icons/bs';
import useAuth from '../common/hooks/useAuth';
import useAxiosAuth from '../common/hooks/useAxiosAuth';

const CollapseItem = ({ cate, userId }) => {
  const axiosAuth = useAxiosAuth();
  const [authorityRule, setAuthorityRule] = useState();
  const [clicked, setClicked] = useState(false);

  const handleToggle = async () => {
    setClicked(prev => !prev);

    try {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/category/${cate.categoryNumber}/${userId}`,
      });
      setAuthorityRule(res.data.ruleRef);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className='togglecollapse__header'>
        {/* 카테고리 토글 */}
        <span>
          <Button
            variant='secondary'
            onClick={handleToggle}
            title={cate && cate.category}>
            {cate && `[${cate.categoryNumber}] : ${cate.category}`}
          </Button>
        </span>
        <span>
          <BsFillCaretUpFill
            className={`toggleBtn ${clicked ? 'open' : ''}`}
            onClick={handleToggle}
          />
        </span>
      </div>
      <div className={`togglecollapse__content ${clicked ? 'open' : ''}`}>
        {authorityRule &&
          authorityRule.map(rule => (
            <NavLink
              to={
                rule.viewType === 'contents'
                  ? `/contents/${rule.ruleNumber}`
                  : `/mix/${rule.ruleNumber}`
              }
              style={{ textDecoration: 'none', color: 'black' }}
              key={rule._id}>
              {/* 룰 토글  */}
              <div
                className='togglecollapse__content__sub'
                title={rule.ruleName}>
                {`[${rule.ruleNumber}] : ${rule.ruleName}`}
              </div>
            </NavLink>
          ))}
      </div>
    </div>
  );
};

const CollapseForm = ({ cate }) => {
  const [clicked, setClicked] = useState(false);
  const handleToggle = () => {
    setClicked(prev => !prev);
  };

  return (
    <div className='togglecollapse__item'>
      <div className='togglecollapse__header'>
        {/* 서식 토글 */}
        <span>
          <Button
            variant='secondary'
            onClick={handleToggle}
            title={cate && `[${cate.categoryNumber}] : ${cate.category}`}>
            {cate && `[${cate.categoryNumber}] : ${cate.category}`}
          </Button>
        </span>
        <span>
          <BsFillCaretUpFill
            className={`toggleBtn ${clicked ? 'open' : ''}`}
            onClick={handleToggle}
          />
        </span>
      </div>
      <div className={`togglecollapse__content ${clicked ? 'open' : ''}`}>
        {cate.ruleRef &&
          cate.ruleRef.map(rule => (
            <NavLink
              to={
                rule.viewType === 'contents'
                  ? `/contents/${rule.ruleNumber}`
                  : `/mix/${rule.ruleNumber}`
              }
              style={{ textDecoration: 'none', color: 'black' }}
              key={rule._id}>
              {/* 서식 토글 */}
              <div
                className='togglecollapse__content__sub'
                title={rule.ruleName}>
                {`[${rule.ruleNumber}] : ${rule.ruleName}`}
              </div>
            </NavLink>
          ))}
      </div>
    </div>
  );
};

const MobileCateList = () => {
  const path = useLocation().pathname.split('/')[1];
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const [lan, setLan] = useState('kor');
  const [sidenav, setSidenav] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);

  const tabSlide = newPosition => {
    const toTranslate = 100 * newPosition;
    const elem = document.querySelector('span.lanTab__glide');
    elem.style.transform = `translateX(${toTranslate}%)`;
  };

  const handlelan = index => {
    const types = ['kor', 'eng'];
    const type = types[index];
    setLan(type);
    tabSlide(index);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchRuleNav = async () => {
      setIsLoading(true);
      let url = '';
      if (path === 'forms') {
        url = `/api/form/categories/${lan}`;
      } else if (path === 'ruleinfo') {
        url = `/api/category/all/${lan}`;
      } else {
        return;
      }
      await axiosAuth
        .get(url)
        .then(res => {
          // console.log(res.data);
          setSidenav(res.data);
          setIsLoading(false);
        })
        .catch(err => {
          console.log(err);
        });
    };
    if (lan || path) {
      fetchRuleNav();
    }
    return () => {
      controller.abort();
    };
  }, [lan, path]);
  return (
    <div className='ruleInfo'>
      <div className='lanTabs'>
        <span
          className={`lanTab ${lan === 'kor' ? ' open' : ''}`}
          role='button'
          aria-hidden='true'
          onClick={() => handlelan(0)}>
          KOREAN
        </span>
        <span
          className={`lanTab ${lan === 'eng' ? ' open' : ''}`}
          role='button'
          aria-hidden='true'
          onClick={() => handlelan(1)}>
          ENGLISH
        </span>
        <span className='lanTab__glide' />
      </div>
      <div className='listBox'>
        {isLoading ? (
          <Spinner
            animation='border'
            role='status'
            style={{
              width: '4rem',
              height: '4rem',
              margin: 'auto',
            }}>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        ) : (
          <div className='togglecollapse'>
            {/* {sidenav &&
              sidenav.map(cate => <CollapseItem key={cate._id} cate={cate} />)} */}
            {path === 'ruleinfo'
              ? sidenav &&
                sidenav.map(cate => <CollapseItem key={cate._id} cate={cate} />)
              : sidenav &&
                sidenav.map(cate => (
                  <CollapseForm key={cate._id} cate={cate} userId={auth.id} />
                ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileCateList;
