import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import useCompare from '../../common/hooks/useCompare';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import { formatDate } from '../../common/utils/tzUtiles';
import useDiff from '../../common/hooks/useDiff';
import getColorForModType from '../../common/utils/SelectOptionParser';

const CompareRuleSelect = () => {
  // DCN 에 따른 ModRule 리스트 가져오기
  const { ruleNumber } = useParams();
  const { compare, setCompare } = useCompare();
  const { setDiff } = useDiff();
  const axiosAuth = useAxiosAuth();

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [modRuleList, setModRuleList] = useState([]);
  const [selectedMod, setSelectedMod] = useState(null);
  const [chapterList, setChapterList] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState(null);

  useEffect(() => {
    const fetchModRuleList = async () => {
      try {
        setIsLoading(true);
        const res = await axiosAuth.get(`api/compare/getmodrule/${ruleNumber}`);

        if (res.data.length === 0) {
          setIsLoading(false);
          return;
        }
        const modRuleListArr = res.data.map(el => ({
          label: `[${el.dcnNumber}] ${formatDate(el.revisedAt)} (${
            el.modChapterRef.length
          })`,
          value: el,
          classification: el.classification,
        }));

        setModRuleList(modRuleListArr);
        setSelectedMod(modRuleListArr[0]);

        const initData = res.data[0];
        const initChapter = initData.oldRule.chapterRef[0];

        setCompare(prev => ({ ...prev, ...initData }));
        const chapterListArr = initData.oldRule?.chapterRef.map(ch => {
          if (
            initData.modChapterRef?.find(
              modCh =>
                modCh.oldChapterId === ch._id && modCh.modType === 'delete'
            )
          ) {
            const changed = ` [${t('components.compare.del')}]`;
            return {
              label: `${ch.chapterNumber}:${ch.chapterName}${changed}`,
              value: ch,
              modType: 'delete',
            };
          }
          if (
            initData.modChapterRef?.find(
              modCh =>
                modCh.oldChapterId === ch._id && modCh.modType === 'replace'
            )
          ) {
            const changed = ` [${t('components.compare.revision')}]`;
            return {
              label: `${ch.chapterNumber}:${ch.chapterName}${changed}`,
              value: ch,
              modType: 'replace',
            };
          }
          return {
            label: `${ch.chapterNumber}:${ch.chapterName}`,
            value: ch,
          };
        });
        const newModChs = initData.modChapterRef
          .filter(modCh => modCh.modType === 'new')
          .map(modCh => ({
            label: `${modCh.chapterNumber}:${modCh.chapterName} [${t(
              'components.compare.add'
            )}]`,
            value: modCh,
            modType: 'new',
          }));
        setChapterList([...chapterListArr, ...newModChs]);
        setSelectedChapter(chapterListArr[0]);

        const initNewChapter = initData.modChapterRef?.find(
          modCh => modCh.oldChapterId === initChapter._id
        );
        if (initNewChapter) {
          setDiff(prev => ({
            ...prev,
            oldChapter: initChapter,
            newChapter: initNewChapter,
          }));
        } else {
          setDiff(prev => ({
            ...prev,
            oldChapter: initChapter,
            newChapter: initChapter,
          }));
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchModRuleList();
  }, []);

  const handleModRule = e => {
    const { value } = e;
    setSelectedMod(e);

    if (value.classification === 'enact') {
      setCompare(prev => ({ ...prev, ...value }));
      const newModChs = value.modChapterRef
        .filter(modCh => modCh.modType === 'new')
        .map(modCh => ({
          label: `${modCh.chapterNumber}:${modCh.chapterName} [${t(
            'components.compare.add'
          )}]`,
          value: modCh,
          modType: 'new',
        }));
      setChapterList(newModChs);
      setSelectedChapter(newModChs[0]);

      setDiff(prev => ({
        ...prev,
        oldChapter: { fileType: '', content: '', uri: '' },
        newChapter: value.modChapterRef[0],
      }));
      return;
    }

    const initChapter = value.oldRule?.chapterRef[0];
    setCompare(prev => ({ ...prev, ...value }));
    const chapterListArr = value.oldRule?.chapterRef.map(ch => {
      if (
        value.modChapterRef?.find(
          modCh => modCh.oldChapterId === ch._id && modCh.modType === 'delete'
        )
      ) {
        const changed = ` [${t('components.compare.del')}]`;
        return {
          label: `${ch.chapterNumber}:${ch.chapterName}${changed}`,
          value: ch,
          modType: 'delete',
        };
      }
      if (
        value.modChapterRef?.find(
          modCh => modCh.oldChapterId === ch._id && modCh.modType === 'replace'
        )
      ) {
        const changed = ` [${t('components.compare.revision')}]`;
        return {
          label: `${ch.chapterNumber}:${ch.chapterName}${changed}`,
          value: ch,
          modType: 'replace',
        };
      }
      return {
        label: `${ch.chapterNumber}:${ch.chapterName}`,
        value: ch,
      };
    });
    const newModChs = value.modChapterRef
      .filter(modCh => modCh.modType === 'new')
      .map(modCh => ({
        label: `${modCh.chapterNumber}:${modCh.chapterName} [${t(
          'components.compare.add'
        )}]`,
        value: modCh,
        modType: 'new',
      }));
    setChapterList([...chapterListArr, ...newModChs]);
    setSelectedChapter(chapterListArr[0]);
    const initNewChapter = value.modChapterRef?.find(
      modCh => modCh.oldChapterId === initChapter._id
    );
    if (initNewChapter) {
      setDiff(prev => ({
        ...prev,
        oldChapter: initChapter,
        newChapter: initNewChapter,
      }));
    } else {
      setDiff(prev => ({
        ...prev,
        oldChapter: initChapter,
        newChapter: initChapter,
      }));
    }
  };

  const handleChapter = e => {
    setSelectedChapter(e);
    if (e.value.modType === 'new') {
      setDiff(prev => ({
        ...prev,
        oldChapter: { fileType: '', content: '', uri: '' },
        newChapter: e.value,
      }));
      return;
    }
    const modChapter = compare.modChapterRef.find(
      modCh => modCh.oldChapterId === e.value._id
    );
    if (modChapter) {
      setDiff(prev => ({
        ...prev,
        oldChapter: e.value,
        newChapter: modChapter,
      }));
    } else {
      setDiff(prev => ({
        ...prev,
        oldChapter: e.value,
        newChapter: e.value,
      }));
    }
  };

  return (
    <div className='compare__mainbody__ruleselect'>
      <Select
        isSearchable
        value={selectedMod}
        placeholder={t('ruleCompare.pleaseselect')}
        className='ruleselect__dcn'
        options={modRuleList}
        onChange={handleModRule}
        isLoading={isLoading} // 로딩 상태
        loadingMessage={() => 'Now DATA Loading...'}
        styles={{
          control: provided => ({
            ...provided,
            boxShadow: '1px ',
            minWidth: '14vw',
            marginRight: '0.5vw',
            outline: 0,
          }),
          valueContainer: provided => ({
            ...provided,
          }),
          indicatorsContainer: base => ({
            ...base,
          }),
          menu: base => ({
            ...base,
            zIndex: 9999,
            marginTop: '0.5vh',
          }),
        }}
      />
      <Select
        isSearchable
        value={selectedChapter}
        placeholder={t('ruleCompare.showAfterSelect')}
        className='ruleselect__chapter'
        options={chapterList}
        onChange={handleChapter}
        isLoading={isLoading} // 로딩 상태
        loadingMessage={() => 'Now DATA Loading...'}
        styles={{
          control: provided => ({
            ...provided,
            minWidth: '20vw',
            outline: 0,
          }),
          valueContainer: provided => ({
            ...provided,
          }),
          indicatorsContainer: base => ({
            ...base,
          }),
          menu: base => ({
            ...base,
            zIndex: 9999,
            marginTop: '0.5vh',
          }),
          option: (provided, state) => ({
            ...provided,
            color: getColorForModType(state.data.modType),
          }),
        }}
      />
    </div>
  );
};

export default CompareRuleSelect;
