import React from 'react';
import { Outlet } from 'react-router-dom';
import '../scss/ModRule/ModRule.scss';
import { DcnProvider } from '../store/DcnProvider';
import { ModRuleProvider } from '../store/ModRuleProvider';

const initialState = {
  type: '',
  status: '',
  modRuleRef: [],
};

const DevDcn = () => {
  return (
    <DcnProvider initialState={initialState}>
      <ModRuleProvider>
        <div className='rulemain draftsection'>
          <Outlet />
        </div>
      </ModRuleProvider>
    </DcnProvider>
  );
};

export default DevDcn;
