import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../scss/Mobile/MobileBreadCrumb.scss';

const MobileBreadCrumb = ({ form, breadCrumbs }) => {
  const { t } = useTranslation();

  // console.log(breadCrumbs);

  return (
    <nav className='mobilebreadcrumb' aria-label='breadcrumb'>
      <ol className='breadcrumb breadcrumb-line text-mute fs-6 fw-bold'>
        <li className='breadcrumb-item'>
          {form ? (
            <Link to='/forms'>{t('mainmenu.form')}</Link>
          ) : (
            <Link to='/ruleinfo'>{t('mainmenu.rule-info')}</Link>
          )}
        </li>
        {(() => {
          if (breadCrumbs) {
            if (form) {
              return breadCrumbs.map(breadCrumb => (
                <li
                  className={`breadcrumb-item ${breadCrumb.active}`}
                  key={breadCrumb._id}>
                  {/* <Link to={`/forms/${breadCrumb.path}/${breadCrumb._id}`}>
                  {breadCrumb.name}
                  </Link> */}
                  <Link to='/ruleinfo'>{breadCrumb.name}</Link>
                </li>
              ));
            }
            return breadCrumbs.map(breadCrumb => (
              <li
                className={`breadcrumb-item ${breadCrumb.active}`}
                key={breadCrumb._id}>
                <Link to='/ruleinfo'>{breadCrumb.name}</Link>
              </li>
            ));
          }
          return (
            <li className='breadcrumb-item active'>
              <Link to='/ruleinfo'>{t('button.dual')}</Link>
            </li>
          );
        })()}
      </ol>
    </nav>
  );
};

export default MobileBreadCrumb;
