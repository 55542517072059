import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../scss/TeamManual/TeamManual.scss';

const TeamManualLayout = () => {
  const { t } = useTranslation();
  const path = useLocation();

  return (
    <div className='teamManual mainsection'>
      {(() => {
        if (path.pathname.includes('/admin')) {
          return <h4>{t('manual.title-admin')}</h4>;
        }
        if (path.pathname.includes('/update')) {
          return <h4>{t('manual.title-update')}</h4>;
        }
        if (path.pathname.includes('/edit')) {
          return <h4>{t('manual.title-edit')}</h4>;
        }
        if (path.pathname.includes('/teamManual/manualone')) {
          return null;
        }
        return <h4>{t('manual.title')}</h4>;
      })()}
      <Outlet />
    </div>
  );
};

export default TeamManualLayout;
