import React, { useState, useEffect, useRef } from 'react';
import { BiUndo, BiX } from 'react-icons/bi';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { format, startOfDay } from 'date-fns';
import '../../scss/Notice/NoticeUpdate.scss';
import { toast } from 'react-toastify';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAuth from '../../common/hooks/useAuth';
import CustomFileInput from '../DatePicker/FileInput';

const NoticeUpdate = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const navi = useNavigate();
  const goBack = () => {
    navi('/notices');
  };

  const [files, setFiles] = useState();
  const [updateNotice, setUpdateNotice] = useState();
  const [updateFile, setUpdateFile] = useState(null);
  const fileinputRef = useRef(null);
  const onChangeNotice = e => {
    setUpdateNotice({ ...updateNotice, [e.target.name]: e.target.value });
  };

  const handleChangeFile = () => {
    setUpdateFile(fileinputRef.current.files);
  };

  const noticeSave = async () => {
    const formData = new FormData();
    const pathDate = format(startOfDay(new Date()), 'yyyy-MM-dd');
    formData.append('noticePath', pathDate);
    formData.append('title', updateNotice.title);
    formData.append('content', updateNotice.content);
    formData.append('id', id);
    if (updateFile) {
      for (let i = 0; i < updateFile.length; i += 1) {
        formData.append('file', updateFile[i]);
      }
    }
    try {
      const response = await axiosAuth.post('/api/notice/edit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        toast(t('notice.notice-edit'));
        navi('/notices');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDel = () => {
    if (window.confirm(t('notice.notice-edit-cancel'))) {
      toast(t('notice.cancel'));
      navi('/notices');
    }
  };

  const urlDelete = async (fileIndex, url) => {
    const deleteUrl = url;
    const updatedFiles = [...files];
    updatedFiles.splice(fileIndex, 1);
    setFiles(updatedFiles);
    try {
      const response = await axiosAuth({
        method: 'POST',
        url: '/api/notice/delUrl',
        data: {
          url: deleteUrl,
          id,
        },
      });
      if (response.status === 200) {
        toast(t('notice.file-del'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const notice = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/notice/byone/${id}/${auth.id}`,
        });
        setUpdateNotice(res.data.doc);
        const filesCopy = res.data.doc.url.map(u => {
          const lastSlash = u.lastIndexOf('/');
          const fileName = u.substring(lastSlash + 1);
          return {
            label: decodeURIComponent(fileName),
            url: u,
          };
        });
        setFiles(filesCopy);
      } catch (err) {
        console.log(err);
      }
    };
    notice();
  }, [id]);

  return (
    <div className='noticeUpdate'>
      <section className='noticeUpdate_header'>
        <BiUndo type='button' onClick={goBack} />
      </section>
      <section className='noticeUpdate__main'>
        <form action='upload' encType='multipart/form-data'>
          <div className='noticeUpdate__main__title'>
            <dl>
              <dt>{t('table.title')} :</dt>
              <dd>
                <input
                  type='text'
                  defaultValue={updateNotice && updateNotice.title}
                  name='title'
                  onChange={onChangeNotice}
                />
              </dd>
            </dl>
          </div>
          <div className='noticeUpdate__main__sub'>
            <div className='noticeUpdate__main__sub__basic'>
              {files?.length > 0 &&
                files.map((file, i) => (
                  <div className='filebadge' key={file.label}>
                    <span>{i + 1}</span>
                    <span>{file.label}</span>
                    <span>
                      <BiX size='20' onClick={() => urlDelete(i, file.url)} />
                    </span>
                  </div>
                ))}
            </div>
            <div className='noticeUpdate__main__sub__newFile'>
              <Form.Group
                controlId='formFileMultiple'
                encType='multipart/form-data'
                className='noticeUpdate__main__sub__newFile__form'>
                <Form.Label>
                  {t('table.attachment')} {t('table.add')}:{' '}
                </Form.Label>
                <CustomFileInput
                  onChange={handleChangeFile}
                  type='file'
                  name='file'
                  ref={fileinputRef}
                  multiple
                  style={{ width: '400px' }}
                />
              </Form.Group>
            </div>
          </div>
          <div className='noticeUpdate__main__body'>
            <textarea
              type='text'
              defaultValue={updateNotice && updateNotice.content}
              name='content'
              onChange={onChangeNotice}
              className='noticeUpdate__main__body'
            />
          </div>
        </form>
      </section>
      <section className='noticeUpdate__bottom'>
        <Button className='noticeUpdate__bottom__btn' onClick={handleDel}>
          {t('button.edit')} {t('button.cancel')}
        </Button>
        <Button
          className='noticeUpdate__bottom__btn'
          type='submit'
          onClick={noticeSave}>
          {t('button.edit')} {t('button.submit')}
        </Button>
      </section>
    </div>
  );
};
export default NoticeUpdate;
