import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillCaretRightFill, BsFillCaretUpFill } from 'react-icons/bs';
import { BiFolderMinus, BiFolderPlus } from 'react-icons/bi';
import { RiAdminFill } from 'react-icons/ri';
import { Button, Spinner } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import useAuth from '../common/hooks/useAuth';
import useAxiosAuth from '../common/hooks/useAxiosAuth';
import ToggleListAddCate from './ToggleList/ToggleListAddCate';
import ToggleListDelCate from './ToggleList/ToggleListDelCate';
import useEntire from '../common/hooks/useEntire';

const CollapseItem = ({ cate, userId, targetRule }) => {
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [authorityRule, setAuthorityRule] = useState();
  const [clicked, setClicked] = useState(false);

  const handleToggle = async () => {
    setClicked(prev => !prev);

    if (!clicked) {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/category/${cate.categoryNumber}/${userId}`,
        });
        setAuthorityRule(res.data.ruleRef);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className='togglecollapse__item'>
      <div className='togglecollapse__header'>
        {cate.type === 'rule' && (
          <span
            className={`togglecollapse__title ${clicked ? 'on' : ''}`}
            title={cate && cate.category}
            aria-hidden='true'
            onClick={() =>
              navigate(`/ruleinfo/category/${cate.categoryNumber}`)
            }>
            {cate && `[${cate.categoryNumber}] : ${cate.category}`}
          </span>
        )}
        {cate.type === 'form' && (
          <span
            className='togglecollapse__title'
            title={cate && cate.category}
            aria-hidden='true'
            onClick={() =>
              navigate(
                `/ruleinfo/formlist/${cate.lan}#category-${cate.categoryNumber}`
              )
            }>
            {cate && `[${cate.categoryNumber}] : ${cate.category}`}
          </span>
          // <NavLink
          //   to={`/ruleinfo/formlist/${cate.lan}#${cate.categoryNumber}`}
          //   style={{ textDecoration: 'none', color: '#fff' }}
          //   className='togglecollapse__title'>
          //   {cate && `[${cate.categoryNumber}] : ${cate.category}`}
          // </NavLink>
        )}
        <span className='togglecollapse__togglewrapper'>
          <BsFillCaretUpFill
            className={`togglecollapse__toggle ${clicked ? 'open' : ''}`}
            onClick={handleToggle}
          />
        </span>
      </div>
      <div className={`togglecollapse__content ${clicked ? 'open' : ''}`}>
        {authorityRule &&
          authorityRule.map(rule => (
            <NavLink
              to={
                rule.viewType === 'contents'
                  ? `/ruleinfo/contents/${rule.ruleNumber}`
                  : `/ruleinfo/mix/${rule.ruleNumber}`
              }
              style={{
                textDecoration: 'none',
                color: location.includes(rule.ruleNumber) ? 'orange' : 'white',
              }}
              key={rule._id}>
              <div
                className={`togglecollapse__content__sub ${
                  rule._id === targetRule ? 'on' : ''
                }`}
                title={rule.ruleName}>
                {`[${rule.ruleNumber}] : ${rule.ruleName}`}
              </div>
            </NavLink>
          ))}
      </div>
    </div>
  );
};

const CollapseForm = ({ cate, targetRule }) => {
  const [clicked, setClicked] = useState(false);
  const handleToggle = () => {
    setClicked(prev => !prev);
  };

  return (
    <div className='togglecollapse__item'>
      <div className='togglecollapse__header'>
        {/* 서식 토글 */}
        <span
          className='togglecollapse__title'
          title={cate && `[${cate.categoryNumber}] : ${cate.category}`}>
          <NavLink
            to={`/forms/${cate._id}`}
            style={{ textDecoration: 'none', color: 'white' }}>
            {cate && `[${cate.categoryNumber}] : ${cate.category}`}
          </NavLink>
        </span>
        <span>
          <BsFillCaretUpFill
            className={`togglecollapse__toggle ${clicked ? 'open' : ''}`}
            onClick={handleToggle}
          />
        </span>
      </div>
      <div className={`togglecollapse__content ${clicked ? 'open' : ''}`}>
        {cate.ruleRef &&
          cate.ruleRef.map(rule => (
            <NavLink
              to={`/forms/form/${rule._id}`}
              style={{ textDecoration: 'none', color: 'white' }}
              key={rule._id}>
              {/* 서식 토글  */}
              <div
                className={`togglecollapse__content__sub ${
                  rule._id === targetRule ? 'on' : ''
                }`}
                title={rule.ruleName}>
                {`[${rule.ruleNumber}] : ${rule.ruleName}`}
              </div>
            </NavLink>
          ))}
      </div>
    </div>
  );
};

const NoCollapseManual = ({ manual }) => {
  const { orgId } = useParams();

  return (
    <div key={manual.id} className='togglecollapse__item'>
      <div className='togglecollapse__headermanual'>
        <span
          className={`togglecollapse__name ${
            orgId === manual._id ? 'open' : ''
          }`}
          title={manual && manual.name}>
          <NavLink
            to={`teammanual/${manual._id}`}
            style={{ textDecoration: 'none' }}>
            {manual && `${manual.name}`}
          </NavLink>
        </span>
        {orgId === manual._id ? (
          <span>
            <BsFillCaretRightFill />
          </span>
        ) : null}
      </div>
    </div>
  );
};

const ToggleList = () => {
  const path = useLocation().pathname.split('/')[1];
  const navi = useNavigate();
  const { auth } = useAuth();
  const { t } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const [sidenav, setSidenav] = useState([]);
  const [lan, setLan] = useState('kor');
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  // category edite
  const [update, setUpdate] = useState(false);
  const [addPopup, setAddPopup] = useState(false);

  const [delPopup, setDelPopup] = useState(false);
  const tabSlide = newPosition => {
    const toTranslate = 100 * newPosition;
    const elem = document.querySelector('span.toggle__menu__tab__glide');
    elem.style.transform = `translateX(${toTranslate}%)`;
  };
  const { setEntire } = useEntire();

  const handlelan = index => {
    const types = ['kor', 'eng'];
    const type = types[index];
    setLan(type);
    // 규정정보 RULE START => lan 상태 변경
    setEntire(prevEntire => ({
      ...prevEntire,
      sideNav: {
        ...prevEntire.sideNav,
        lan: type,
      },
    }));
    tabSlide(index);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchRuleNav = async () => {
      setIsLoading(true);
      let url = '';
      try {
        if (path === 'ruleinfo') {
          url = `/api/category/all/${lan}`;
          const res = await axiosAuth.get(url, { signal: controller.signal });
          setSidenav(res.data);
          setIsLoading(false);
        }
        if (path === 'teammanual') {
          url = `/api/manualper/manuals`;
          const res2 = await axiosAuth.post(url, {
            userId: auth.id,
            email: auth.email,
            company: auth.company,
          });
          setSidenav(res2.data);
          setIsLoading(false);
          return;
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchRuleNav();

    return () => {
      controller.abort();
    };
  }, [lan, path, update]);

  // CATEGORY 생성
  const openCate = () => {
    setAddPopup(prev => !prev);
    setDelPopup(false);
  };

  // CATEGORY 삭제
  const openCateDel = () => {
    setDelPopup(prev => !prev);
    setAddPopup(false);
  };

  // CATEGORY 편집 popup close
  const resetCateAddDel = () => {
    setAddPopup(false);
    setDelPopup(false);
  };

  return (
    <div className='toggle__menu'>
      <div className='toggle__menu__header'>
        <div className='toggle__menu__tabs'>
          <span
            className={`toggle__menu__tab ${lan === 'kor' ? ' open' : ''}`}
            role='button'
            aria-hidden='true'
            onClick={() => handlelan(0)}>
            KOREAN
          </span>
          <span
            className={`toggle__menu__tab ${lan === 'eng' ? ' open' : ''}`}
            role='button'
            aria-hidden='true'
            onClick={() => handlelan(1)}>
            ENGLISH
          </span>
          <span className='toggle__menu__tab__glide' />
        </div>
        {path !== 'teammanual' &&
        auth.roles.some(
          role => role === 'ROLE_IDX1' || role === 'ROLE_IDX2'
        ) ? (
          <>
            <div className='toggle__menu__folderSetting'>
              {/* 생성 */}
              <Button variant='secondary' onClick={openCate}>
                <span>{t('sidenav.createCate')}</span>
                <BiFolderPlus fill='#fff' size={20} />
              </Button>
              {/* 삭제 */}
              <Button variant='secondary' onClick={openCateDel}>
                <span>{t('sidenav.editCate')}</span>
                <BiFolderMinus fill='#fff' size={20} />
              </Button>
            </div>
            {addPopup && (
              <ToggleListAddCate
                openCate={openCate}
                setUpdate={setUpdate}
                resetCateAddDel={resetCateAddDel}
              />
            )}
            {delPopup && (
              <ToggleListDelCate
                openCateDel={openCateDel}
                setUpdate={setUpdate}
                resetCateAddDel={resetCateAddDel}
              />
            )}
          </>
        ) : null}
        {path === 'teammanual' &&
          auth.roles.some(
            role =>
              role === 'ROLE_IDX1' ||
              role === 'ROLE_IDX2' ||
              role === 'ROLE_IDX10'
          ) && (
            <div className='toggle__menu__folderSetting'>
              <Button
                variant='secondary'
                onClick={() => navi('/teammanual/admin')}>
                <span>{t('sidenav.adminTeamManual')}</span>
                <RiAdminFill fill='#fff' size={20} />
              </Button>
            </div>
          )}
      </div>
      <div
        className={` ${
          auth.roles.some(role => role === 'ROLE_IDX1' || role === 'ROLE_IDX2')
            ? 'adminList'
            : 'userToggleList'
        }`}>
        {isLoading ? (
          <Spinner
            animation='border'
            role='status'
            style={{
              width: '4rem',
              height: '4rem',
              margin: 'auto',
            }}>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        ) : (
          <div className='togglecollapse'>
            {(() => {
              if (path === 'ruleinfo' && sidenav) {
                return sidenav.map(cate => (
                  <CollapseItem key={cate._id} cate={cate} targetRule={id} />
                ));
              }
              if (path === 'forms' && sidenav) {
                return sidenav.map(cate => (
                  <CollapseForm
                    key={cate._id}
                    cate={cate}
                    userId={auth.id}
                    targetRule={id}
                  />
                ));
              }
              if (path === 'teammanual' && sidenav) {
                return sidenav.map(manual => (
                  <NoCollapseManual key={manual._id} manual={manual} />
                ));
              }
              return null;
            })()}
          </div>
        )}
      </div>
    </div>
  );
};
export default ToggleList;
