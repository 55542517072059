import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
// import { format } from 'date-fns';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useTranslation } from 'react-i18next';
import useModRule from '../../../common/hooks/useModRule';
import RuleChapContents from '../RuleChapContents';
import useModChapter from '../../../common/hooks/useModChapter';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../../common/hooks/useAxiosMultipart';
import '../../../scss/ModRule/EnactRuleAddChapter.scss';

const AmendRuleAddContent = () => {
  const { t, i18n } = useTranslation();
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const editorRef = useRef(null);
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const [update, setUpdate] = useState(false);

  const createChapContents = docData => {
    const domparser = new DOMParser();
    const docStr = domparser.parseFromString(docData, 'text/html');
    if (docData.match(/<h2>/)) {
      const h2title = docStr
        .getElementsByTagName('h2')[0]
        .innerHTML.replace(/(<([^>]+)>)/gi, '')
        .replace(/&nbsp;/g, '')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>');
      setModChapter(prev => ({
        ...prev,
        chapterContents: { ...prev.chapterContents, header: h2title },
      }));
    } else {
      setModChapter(prev => ({
        ...prev,
        chapterContents: { ...prev.chapterContents, header: '' },
      }));
    }
    if (docData.match(/<h3>/)) {
      const h3title = [...docStr.getElementsByTagName('h3')].map(tag =>
        tag.innerHTML
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/&nbsp;/g, '')
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
      );
      setModChapter(prev => ({
        ...prev,
        chapterContents: {
          ...prev.chapterContents,
          children: [...h3title],
        },
      }));
    } else {
      setModChapter(prev => ({
        ...prev,
        chapterContents: { ...prev.chapterContents, children: [] },
      }));
    }
  };

  function customUploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(file => {
            data.append('file', file);
            axiosMultipart
              .post('/api/modrule/imageupload', data)
              .then(res => resolve({ default: res.data.imageUrl }))
              .catch(err => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    const editorinstance = editor;
    editorinstance.plugins.get('FileRepository').createUploadAdapter =
      loader => {
        return customUploadAdapter(loader);
      };
  }

  const submitReplaceChapter = async () => {
    if (!modRule || !modChapter) {
      toast(t('modrule.no-rule-chap'));
      return;
    }
    if (modChapter.chapterNumber === `${modRule.ruleNumber}-`) {
      toast(t('modrule.same-rule-chap'));
      return;
    }
    if (!modChapter.chapterNumber.includes(modRule.ruleNumber)) {
      toast(t('modrule.includes-rule-chap'));
      return;
    }
    const ckInstance = editorRef.current;
    if (ckInstance) {
      const editorData = ckInstance.editor.getData({ pagination: true });
      try {
        const response = await axiosAuth({
          method: 'PUT',
          url: '/api/approval/chapter/amend',
          data: {
            currChapterId: modChapter._id,
            modRuleId: modRule._id,
            modType: modChapter.modType,
            chapterType: modChapter.chapterType,
            chapterIdx: modChapter.chapterIdx,
            chapterNumber: modChapter.chapterNumber,
            chapterName: modChapter.chapterName,
            content: editorData,
            lan: modRule.lan,
            chapterContents: modChapter.chapterContents,
            fileType: modChapter.fileType,
          },
        });
        if (response.status === 200) {
          toast(t('modrule.add-chap-text'));
          setModChapter({
            fileType: '',
            modType: '',
            content: '',
            uri: '',
            chapterIdx: 0,
            chapterNumber: '',
            chapterName: '',
            chapterContents: {
              header: '',
              children: [],
            },
          });
          setModRule(prev => ({ ...prev, ...response.data }));
        }
      } catch (error) {
        toast(t('modrule.add-chap-error'));
      }
    } else {
      alert(t('modrule.add-chap-retry'));
    }
  };

  //* 챕터 추가 'new'
  const submitAddChapter = async () => {
    const ckInstance = editorRef.current;
    if (ckInstance) {
      const editorData = ckInstance.editor.getData({ pagination: true });
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/approval/chapter/amendnew',
          data: {
            refModRuleId: modRule._id,
            modType: modChapter.modType,
            chapterType: modChapter.chapterType,
            chapterIdx: modChapter.chapterIdx,
            chapterNumber: modChapter.chapterNumber,
            chapterName: modChapter.chapterName,
            content: editorData,
            lan: modRule.lan,
            chapterContents: modChapter.chapterContents,
          },
        });
        if (response.status === 200) {
          toast(t('modrule.add-chap-text'));
          setModChapter({
            fileType: '',
            modType: '',
            content: '',
            uri: '',
            chapterIdx: 0,
            chapterNumber: '',
            chapterName: '',
            chapterContents: {
              header: '',
              children: [],
            },
          });
          setModRule(prev => ({ ...prev, ...response.data }));
        }
      } catch (error) {
        toast(t('modrule.add-chap-error'));
      }
    } else {
      alert(t('modrule.add-chap-retry'));
    }
  };

  //* 챕터 삭제 'delete'
  const submitDeleteChapter = async () => {
    if (window.confirm(t('modrule.del-chap-q'))) {
      try {
        const response = await axiosAuth({
          method: 'DELETE',
          url: '/api/approval/amend/chapter',
          data: {
            refModRuleId: modRule._id,
            chapterId: modChapter._id,
          },
        });
        if (response.status === 200) {
          toast(t('modrule.del-chap'));
          setModChapter({
            fileType: '',
            modType: '',
            content: '',
            uri: '',
            chapterIdx: 0,
            chapterNumber: '',
            chapterName: '',
            chapterContents: {
              header: '',
              children: [],
            },
          });
          setModRule(prev => ({ ...prev, ...response.data }));
        }
      } catch (error) {
        toast(t('modrule.del-chap-error'));
      }
    } else {
      toast(t('modrule.del-chap-cancel'));
    }
  };

  //! 새로 만들어야함 개정 챕터 수정
  const submitEditChapter = async () => {
    //
    if (modChapter.chapterNumber === `${modRule.ruleNumber}-`) {
      toast(t('modrule.same-rule-chap'));
      return;
    }
    if (!modChapter.chapterNumber.includes(modRule.ruleNumber)) {
      toast(t('modrule.includes-rule-chap'));
      return;
    }
    const ckInstance = editorRef.current;
    if (ckInstance) {
      const editorData = ckInstance.editor.getData({ pagination: true });
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/approval/chapter/amendnew',
          data: {
            refModRuleId: modRule._id,
            modType: modChapter.modType,
            chapterType: modChapter.chapterType,
            chapterIdx: modChapter.chapterIdx,
            chapterNumber: modChapter.chapterNumber,
            chapterName: modChapter.chapterName,
            sortNum: modChapter.sortNum,
            content: editorData,
            lan: modRule.lan,
            chapterContents: modChapter.chapterContents,
          },
        });
        if (response.status === 200) {
          toast(t('modrule.add-chap-text'));
          setModChapter({
            fileType: '',
            modType: '',
            content: '',
            uri: '',
            chapterIdx: 0,
            chapterNumber: '',
            chapterName: '',
            chapterContents: {
              header: '',
              children: [],
            },
          });
          setModRule(prev => ({ ...prev, ...response.data }));
        }
      } catch (error) {
        toast(t('modrule.add-chap-error'));
      }
    } else {
      alert(t('modrule.add-chap-retry'));
    }
  };

  if (modRule.viewType === 'files') {
    return (
      <div>
        {i18n.language === 'ko' ? (
          <>
            <p>파일 타입의 규정에는 텍스트 챕터를 등록할 수 없습니다.</p>
            <p>
              규정타입을
              <span style={{ color: 'orange' }}> 텍스트</span> 혹은
              <span style={{ color: 'orange' }}> 혼합</span>으로 변경하고
              등록해주세요.
            </p>
          </>
        ) : (
          <>
            <p>You cannot register text chapters for file-type regulations.</p>
            <p>
              Please change the regulation type to
              <span style={{ color: 'orange' }}> Text</span> or
              <span style={{ color: 'orange' }}> Mixed</span> and register it.
            </p>
          </>
        )}
      </div>
    );
  }

  return (
    <div className='addcontent'>
      <div className='addcontent__side'>
        <div className='addcontent__side__submitbtn'>
          {(() => {
            if (modChapter.oldChapterId && modChapter.oldChapterId !== '') {
              return (
                <Button style={{ width: '100%' }} onClick={submitEditChapter}>
                  <span className='chapBtnSvg'>
                    <MdOutlineKeyboardDoubleArrowLeft />
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </span>
                  {t('modrule.edit-amend')}
                </Button>
              );
            }

            if (modChapter.modType === 'new') {
              return (
                <Button style={{ width: '100%' }} onClick={submitAddChapter}>
                  <span className='chapBtnSvg'>
                    <MdOutlineKeyboardDoubleArrowLeft />
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </span>
                  {t('modrule.create-chap')}
                </Button>
              );
            }
            return (
              <>
                <Button
                  style={{ width: '100%' }}
                  onClick={submitReplaceChapter}>
                  <span className='chapBtnSvg'>
                    <MdOutlineKeyboardDoubleArrowLeft />
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </span>
                  {t('modrule.amend-chap')}
                </Button>
                <Button style={{ width: '100%' }} onClick={submitDeleteChapter}>
                  <span className='chapBtnSvg'>
                    <MdOutlineKeyboardDoubleArrowLeft />
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </span>
                  {t('modrule.del-chap-btn')}
                </Button>
              </>
            );
          })()}
        </div>
        <div className='addcontent__side__chapcontentstitle'>
          <span style={{ fontSize: '1.2rem', padding: '5px 0' }}>
            {t('modrule.content')}
          </span>
          {update && (
            <span style={{ fontSize: '1rem', padding: '5px 0' }}>
              {t('modrule.create-content')}
            </span>
          )}
        </div>
        <div
          className='addcontent__side__chapcontents'
          style={{ padding: '0 5px' }}>
          <RuleChapContents chapterContents={modChapter.chapterContents} />
        </div>
      </div>
      <div className='addcontent__editor' id='document-container'>
        <div id='toolbar-container' className='toolbar-container' />
        <div id='editor-container' className='editor-container' />
        <CKEditor
          ref={editorRef}
          editor={Editor}
          data={modChapter.content}
          config={{
            heading: {
              options: [
                {
                  model: 'paragraph',
                  title: i18n.language === 'ko' ? '본문' : 'CONTENT',
                  class: 'ck-heading_paragraph',
                },
                {
                  model: 'heading2',
                  view: 'h2',
                  title: i18n.language === 'ko' ? '제목 1' : 'TITLE 1',
                  class: 'ck-heading_heading2',
                },
                {
                  model: 'heading3',
                  view: 'h3',
                  title: i18n.language === 'ko' ? '제목 2' : 'TITLE 2',
                  class: 'ck-heading_heading3',
                },
                {
                  model: 'heading4',
                  view: 'h4',
                  title: i18n.language === 'ko' ? '제목 3' : 'TITLE 3',
                  class: 'ck-heading_heading4',
                },
              ],
            },
            list: {
              properties: {
                styles: true,
                startIndex: true,
                reversed: true,
              },
            },
            extraPlugins: [uploadPlugin],
            autosave: {
              save(editor) {
                setUpdate(true);
                const data = editor.getData({ pagination: true });
                createChapContents(data);
                setTimeout(() => {
                  setUpdate(false);
                }, 1000);
              },
              waitingTime: 2000,
            },
          }}
          onReady={editor => {
            const editorContainer = document.querySelector('#editor-container');
            const toolbarContainer =
              document.querySelector('#toolbar-container');

            toolbarContainer.appendChild(editor.ui.view.toolbar.element);
            editorContainer.appendChild(editor.ui.view.editable.element);

            editor.ui.update();
          }}
          onError={(error, editor) => {
            if (editor.willEditorRestart) {
              editor.ui.view.toolbar.element.remove();
            }
            if (error) {
              console.log(error);
            }
          }}
        />
      </div>
    </div>
  );
};

export default AmendRuleAddContent;
