import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BiUndo } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import Select from 'react-select';
import '../../scss/GroupChart/OrgMembers.scss';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import Unauthorized from '../Unauthorized';

const OrgMembers = () => {
  const [open, setOpen] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [sortingList, setSortingList] = useState([]);
  const [membersSelect, setMembersSelect] = useState([]);
  const [currentSelect, setCurrentSelect] = useState([]);
  const [sortValues, setSortValues] = useState({
    sortType: 'posi',
    sortUpDown: false,
  });
  const { id } = useParams();
  // const { state } = useLocation();
  const axiosAuth = useAxiosAuth();
  const { auth } = useAuth();
  const navi = useNavigate();
  const { t, i18n } = useTranslation();

  const goBack = () => {
    navi('/orgchart');
  };
  useEffect(() => {
    const handleSeeMore = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/org/byorg/${id}`,
        });
        setMemberList(res.data);
        setSortingList(res.data.doc);
      } catch (err) {
        console.log(err);
      }
    };
    handleSeeMore();
  }, [id]);

  const goOpen = () => {
    setOpen(!open);
  };

  // 해당 조직에 없는 구성원 불러오기 (구성원 추가)
  useEffect(() => {
    const handleSeeMore = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/org/no/${id}/${auth.company}`,
        });
        const optionsCopy = [];
        for (let i = 0; i < res.data.length; i += 1) {
          optionsCopy.push({
            label: `${res.data[i].username}(${res.data[i].position.positionName})`,
            value: res.data[i].email,
            id: res.data[i]._id,
          });
        }
        setMembersSelect(optionsCopy);
      } catch (err) {
        console.log(err);
      }
    };
    handleSeeMore();
  }, [id]);

  // 해당 조직에 구성원 추가
  const currentMembers = e => {
    setCurrentSelect(e.value);
  };
  const addMembersSubmit = async () => {
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/management/edit/userorg',
        data: {
          email: currentSelect,
          org: id,
        },
      });
      toast(response.data.message);
      // window.location.reload();
    } catch (error) {
      toast(error.response.data);
    }
  };

  // 조직도 구성원 삭제
  const handleDeleteuser = async (e, user) => {
    const { _id, email, username } = user;
    if (
      e &&
      window.confirm(
        `${
          i18n.language === 'ko'
            ? `${username}님을 삭제하시겠습니까?`
            : `Do you want to delete ${username}?`
        }`
      )
    ) {
      try {
        const response = await axiosAuth({
          method: 'DELETE',
          url: `/api/mypage/del/${_id}/${email}`,
        });
        toast(response.data);
        // window.location.reload();
      } catch (error) {
        toast(error.response.data);
      }
    }
  };

  // sorting
  const resetNumber = num => {
    const sortingFunctions = {
      org: (a, b) => {
        const orgA = a.org.name || '';
        const orgB = b.org.name || '';
        return sortValues.sortUpDown
          ? orgA.localeCompare(orgB)
          : orgB.localeCompare(orgA);
      },
      posi: (a, b) => {
        const sortNumA = a.position.sortNum;
        const sortNumB = b.position.sortNum;
        return sortValues.sortUpDown
          ? sortNumA - sortNumB
          : sortNumB - sortNumA;
      },
    };

    if (num === 'org' || num === 'posi') {
      setSortValues(prev => ({
        ...prev,
        sortType: num,
        sortUpDown: !prev.sortUpDown,
      }));

      setSortingList(prevSortingList => {
        return [...prevSortingList].sort(sortingFunctions[num]);
      });
    }
  };

  return (
    <div className='mainsection'>
      {auth.role === 'role1' || auth.role === 'role4' ? (
        <div className='OrgMembers'>
          <h4>{t('groupChart.orgchart.membersTitle')}</h4>
          <div>
            <BiUndo type='button' onClick={goBack} />

            <span>
              {memberList.orgname} ({memberList.doc && memberList.doc.length})
            </span>
            {auth.company === id ? (
              <span style={{ width: '5vw' }}>{null}</span>
            ) : (
              <Button onClick={goOpen}>
                {t('groupChart.orgchart.addMember')}
              </Button>
            )}
          </div>
          {open ? (
            <Modal
              className='OrgMembers__modal'
              show={open}
              onHide={setOpen}
              backdrop='static'
              keyboard={false}>
              <Modal.Header closeButton className='OrgMembers__modal__title'>
                <Modal.Title>{t('groupChart.orgchart.addMember')}</Modal.Title>
              </Modal.Header>
              <Modal.Body className='OrgMembers__modal__selectBox'>
                <div className='selectBox__users'>
                  <span>USER :</span>
                  <Select
                    styles={blackStyles}
                    placeholder={
                      <div>{t('groupChart.orgchart.addMemberDescription')}</div>
                    }
                    isSearchable
                    options={membersSelect}
                    onChange={currentMembers}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className='OrgMembers__modal__footer'>
                <Button
                  type='submit'
                  variant='secondary'
                  onClick={addMembersSubmit}>
                  {t('button.submit')}
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
          <div className='OrgMembers__tablewrap'>
            <Table>
              <thead>
                <tr>
                  <th width='25%'>{t('groupChart.orgchart.user')}</th>
                  <th width='10%'>
                    <Button
                      variant='secondary'
                      style={{ padding: '0', border: 'none' }}
                      onClick={() => resetNumber('posi')}>
                      {t('groupChart.orgchart.position')}
                      {sortValues.sortType === 'posi' &&
                      sortValues.sortUpDown ? (
                        <TiArrowSortedUp
                          size={20}
                          color={
                            sortValues.sortType === 'posi' ? 'orange' : 'gray'
                          }
                        />
                      ) : (
                        <TiArrowSortedDown
                          size={20}
                          color={
                            sortValues.sortType === 'posi' ? 'orange' : 'gray'
                          }
                        />
                      )}
                    </Button>
                  </th>
                  <th width='10%'>{t('groupChart.orgchart.employNum')}</th>
                  <th width='15%'>
                    <Button
                      variant='secondary'
                      style={{ padding: '0', border: 'none' }}
                      onClick={() => resetNumber('org')}>
                      {t('groupChart.orgchart.org')}
                      {sortValues.sortType === 'org' &&
                      sortValues.sortUpDown ? (
                        <TiArrowSortedUp
                          size={20}
                          color={
                            sortValues.sortType === 'org' ? 'orange' : 'gray'
                          }
                        />
                      ) : (
                        <TiArrowSortedDown
                          size={20}
                          color={
                            sortValues.sortType === 'org' ? 'orange' : 'gray'
                          }
                        />
                      )}
                    </Button>
                  </th>
                  <th width='12%'>{t('groupChart.orgchart.phoneNum1')}</th>
                  <th width='12%'>{t('groupChart.orgchart.phoneNum2')}</th>
                  <th width='11%'>{t('groupChart.orgchart.hiredate')}</th>
                  <th width='5%'>{t('groupChart.orgchart.del')}</th>
                </tr>
              </thead>
              <tbody>
                {sortingList &&
                  sortingList.map(members => (
                    <tr key={members._id}>
                      <td
                        align='left'
                        style={{
                          paddingLeft: '2vw',
                        }}>
                        {`${members.username} [ ${members.email} ]`}
                      </td>
                      <td>{members.position.positionName}</td>
                      <td>{members.employno}</td>
                      <td>{members.org.name}</td>
                      <td>{members.contact}</td>
                      <td>{members.officecontact}</td>
                      <td>{members.hiredate}</td>
                      <td>
                        <RiDeleteBin6Line
                          onClick={e => handleDeleteuser(e, members)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <Unauthorized />
      )}
    </div>
  );
};

export default OrgMembers;
