import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsFillCaretUpFill } from 'react-icons/bs';
import {
  BiSolidLeftArrowSquare,
  BiSolidRightArrowSquare,
} from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import ModRuleCirNew from './ModRuleCir/ModRuleCirNew';
import ModRuleCirDefault from './ModRuleCir/ModRuleCirDefault';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import ModRuleCirDel from './ModRuleCir/ModRuleCirDel';
import ModRuleCirEdit from './ModRuleCir/ModRuleCirEdit';
import ModRuleCirRuleCreateModal from './ModRuleCir/ModRuleCirRuleCreateModal';
import ModRuleCirRuleDeleteModal from './ModRuleCir/ModRuleCirRuleDeleteModal';
import '../../scss/ModRule/ModRuleCir.scss';

const CirItem = ({
  cate,
  setCir,
  setManageType,
  setSideOpen,
  setCirModal,
  setTargetCate,
}) => {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setClicked(prev => !prev);
  };

  const handleCirOpen = (obj, type) => {
    setSideOpen(false);
    setCirModal('');
    setManageType(type);
    setCir(obj);
  };

  const handleOpenCreateModal = () => {
    setCirModal('create');
    setManageType('');
    setTargetCate(cate);
    setClicked(true);
  };

  const handleOpenDeleteModal = () => {
    setCirModal('delete');
    setManageType('');
    setTargetCate(cate);
    setClicked(true);
  };

  return (
    <div className='circollapse'>
      <div className='circollapse__header'>
        <span className='clickarea' aria-hidden='true' onClick={handleToggle}>
          <span>
            <BsFillCaretUpFill
              className={`circollapse__toggle ${clicked ? 'open' : ''}`}
            />
          </span>
          <span
            aria-hidden='true'
            className='circollapse__title'
            title={cate && cate.category}>
            {cate && `[${cate.categoryNumber}] : ${cate.category}`}
          </span>
        </span>
        <span>
          <Button onClick={handleOpenCreateModal}>
            + {t('button.create')}
          </Button>
          <Button onClick={handleOpenDeleteModal}>
            - {t('button.delete')}
          </Button>
        </span>
      </div>
      <div className={`circollapse__child ${clicked ? 'open' : ''}`}>
        {cate.ruleRef &&
          cate.ruleRef.map(rule => (
            <div
              className='circollapse__child__item'
              key={rule._id}
              title={rule.ruleName}>
              <span>{`[${rule.ruleNumber}] : ${rule.ruleName}`}</span>
              <span>
                <Button
                  variant='secondary'
                  className='cirbtn-new'
                  title={`CIR ${t('button.submit')}`}
                  onClick={() => handleCirOpen(rule, 'new')}>
                  {t('button.submit')}
                </Button>
                <Button
                  variant='secondary'
                  className='cirbtn-del'
                  title={`CIR ${t('button.delete')}`}
                  onClick={() => handleCirOpen(rule, 'del')}>
                  {t('button.delete')}
                </Button>
                <Button
                  variant='secondary'
                  className='cirbtn-edit'
                  title={`CIR ${t('button.edit')}`}
                  onClick={() => handleCirOpen(rule, 'edit')}>
                  {t('button.edit')}
                </Button>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

const ModRuleCir = () => {
  const [cates, setCates] = useState([]);
  const [targetCate, setTargetCate] = useState({});
  const [sideOpen, setSideOpen] = useState(true);
  const [manageType, setManageType] = useState('');
  const [cir, setCir] = useState({
    _id: '',
    ruleNumber: '',
    ruleName: '',
  });
  const [cirModal, setCirModal] = useState('');
  const axiosAuth = useAxiosAuth();
  const sideRef = useRef(null);
  const { t } = useTranslation();

  const fetchData = async () => {
    try {
      const res = await axiosAuth({
        method: 'GET',
        url: '/api/cir/all',
      });
      setCates(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='modetc'>
      <div
        className={`modetc__sidelist ${sideOpen ? 'open' : 'close'}`}
        style={{ width: sideOpen ? 'auto' : '4vw' }}>
        <div className='modetc__sidelist__title'>
          {sideOpen && (
            <span className='modetc__sidelist__titlespan'>
              {t('modrule.cir-list')}
            </span>
          )}
          <Button
            className='sidelistbtn-open'
            variant='secondary'
            onClick={() => setSideOpen(!sideOpen)}>
            {sideOpen ? (
              <BiSolidLeftArrowSquare size='25' />
            ) : (
              <BiSolidRightArrowSquare size='25' />
            )}
          </Button>
        </div>
        <div style={{ maxHeight: '72vh', overflowY: 'auto' }} ref={sideRef}>
          {sideOpen ? (
            cates?.length > 0 &&
            cates.map(item => (
              <CirItem
                cate={item}
                key={item._id}
                setCir={setCir}
                setManageType={setManageType}
                setSideOpen={setSideOpen}
                setCirModal={setCirModal}
                setTargetCate={setTargetCate}
              />
            ))
          ) : (
            <div className='sidebar-closed-text'>{t('modrule.cir-list')}</div>
          )}
        </div>
        {cirModal === 'create' && (
          <ModRuleCirRuleCreateModal
            setCirModal={setCirModal}
            cate={targetCate}
            updateData={fetchData}
          />
        )}
        {cirModal === 'delete' && (
          <ModRuleCirRuleDeleteModal
            setCirModal={setCirModal}
            cate={targetCate}
            updateData={fetchData}
          />
        )}
      </div>
      <div className='modetc__manage'>
        {(() => {
          if (manageType === 'new') {
            return <ModRuleCirNew cir={cir} />;
          }
          if (manageType === 'del') {
            return <ModRuleCirDel cir={cir} />;
          }
          if (manageType === 'edit') {
            return <ModRuleCirEdit cir={cir} />;
          }
          return <ModRuleCirDefault cir={cir} sideOpen={sideOpen} />;
        })()}
      </div>
    </div>
  );
};

export default ModRuleCir;
