import React from 'react';
import { SortChaptersProvider } from '../../store/SortChaptersProvider';
import SortChapters from './ModRuleSortChapter/SortChapters';

const ModRuleSortChapter = () => {
  // backend에 chapterRef, modChapterRef 요청
  // CH , FORM , APP 구분해서 리스트
  // sort chapter context -> chlist , formlist , applist
  // ch : {chapterType : 'CH' , list : []}
  // 여기서 데이터 관리
  return (
    <SortChaptersProvider>
      <div className='enactrule__main__sortchapter'>
        <SortChapters />
      </div>
    </SortChaptersProvider>
  );
};

export default ModRuleSortChapter;
