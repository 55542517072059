import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { BiX } from 'react-icons/bi';
import { toast } from 'react-toastify';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const ToggleListDelCate = ({ openCateDel, setUpdate, resetCateAddDel }) => {
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [addLan, setAddLan] = useState('kor');
  const [editCate, setEditCate] = useState([]);

  useEffect(() => {
    const fetchEditCate = async () => {
      const res = await axiosAuth.get(`/api/category/all/${addLan}`);
      setEditCate(res.data);
    };
    fetchEditCate();
  }, []);

  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  const handleCateDel = async (cateid, num) => {
    if (window.confirm(`${num} ${t('components.toggleList.category-del-q')}`)) {
      try {
        const res = await axiosAuth({
          method: 'DELETE',
          url: `/api/category/${cateid}`,
        });
        toast(
          res.data.message ||
            `${num} ${t('components.toggleList.category-del')}`
        );
        setUpdate(prev => !prev);
        resetCateAddDel();
      } catch (err) {
        toast(t('components.toggleList.del-failed'));
        console.log(err);
      }
    }
  };

  return (
    <div className='categoryMinus'>
      <div className='categoryMinus__header'>
        <span>{t('sidenav.editCateTitle')}</span>{' '}
        <BiX size={22} onClick={openCateDel} />
      </div>
      <div className='categoryMinus__body'>
        <div className='categoryMinus__body__tabs'>
          <ButtonGroup className='lanRadiobtn'>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={radio.value}
                id={`radio-${idx}`}
                type='radio'
                variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                name='radio'
                value={radio.value}
                checked={addLan === radio.value}
                onClick={e => setAddLan(e.currentTarget.value)}>
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <div className='categoryMinus__body__cateBox'>
          <div className='categoryMinus__body__cateBox__list'>
            {editCate &&
              editCate.map(cate => (
                <div key={cate._id}>
                  <span>
                    {cate && `[${cate.categoryNumber}] : ${cate.category}`}
                  </span>
                  <Button
                    variant='secondary'
                    onClick={() =>
                      handleCateDel(cate._id, cate.categoryNumber)
                    }>
                    <BiX size={20} />
                  </Button>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleListDelCate;
