import React from 'react';
import '../../scss/MyPage/SecuritySetting.scss';
import { useTranslation } from 'react-i18next';

const SecuritySetting = () => {
  const { t } = useTranslation();
  return (
    <div className='securitySetting'>
      <div>{t('mypage.nowupdating')}</div>
    </div>
  );
};

export default SecuritySetting;
