import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAuth from '../../common/hooks/useAuth';
import useModRule from '../../common/hooks/useModRule';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import RuleApproval from './RuleApproval';
import useModChapter from '../../common/hooks/useModChapter';

const ModRuleDraftHeader = () => {
  const navi = useNavigate();
  const { state } = useLocation();
  const { auth } = useAuth();
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const axiosAuth = useAxiosAuth();
  const [payOpen, setPayOpen] = useState(false);
  const { t } = useTranslation();

  const submitModRule = async () => {
    const classificationKr =
      modRule.classification === 'enact'
        ? t('modrule.enact')
        : t('modrule.amend');
    if (modRule.examines?.length === 0 || modRule.approve?.length === 0) {
      toast(t('modrule.set-approval-line'));
    } else {
      try {
        const res = await axiosAuth({
          method: 'post',
          url: '/api/dcn/draft',
          data: {
            modruleId: modRule._id,
            number: modRule.dcnNumber?.slice(3),
          },
        });
        if (res.status === 200) {
          toast(
            `${res.data.ruleNumber} ${classificationKr} ${t(
              'modrule.complete-draft'
            )}`
          );
          navi('/modrule/dcn');
        }
      } catch (error) {
        console.log(error);
        toast(t('modrule.complete-draft-failed'));
      }
    }
  };

  const DeleteModRuleAndExit = async () => {
    if (!modRule._id) {
      navi('/modrule/dcn');
      return;
    }
    if (window.confirm(t('modrule.close-del-draft'))) {
      try {
        const res = await axiosAuth({
          method: 'post',
          url: `/api/modrule/delete`,
          data: {
            number: state.number,
            modRuleId: modRule._id,
          },
        });
        if (res.status === 200) {
          navi('/modrule/dcn');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const saveTemporary = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/approval/savetemporary',
        data: {
          modRuleId: modRule._id,
        },
      });
      if (res.status === 200) {
        toast(t('modrule.temp-save-draft'));
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.temp-save-failed'));
    }
  };

  const openRevStatus = () => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
    if (modChapter.fileType === 'revstatus') {
      setModChapter(prev => ({
        ...prev,
        fileType: '',
      }));
      return;
    }
    setModChapter(prev => ({
      ...prev,
      fileType: 'revstatus',
    }));
  };

  return (
    <div className='enactrule__main__header'>
      <div className='enactrule__main__header__dcninfo'>
        <span
          style={{
            fontSize: '1.2rem',
            display: 'inline-block',
          }}>
          {state ? `DCN${state.number}` : modRule?.dcnNumber}
        </span>
      </div>
      <div className='enactrule__main__header__btns'>
        {modRule?.classification === 'amend' && (
          <Button
            variant='secondary'
            style={{
              color: `${modChapter?.fileType === 'revstatus' ? 'orange' : ''}`,
            }}
            type='submit'
            onClick={openRevStatus}>
            {t('modrule.rev-sum')}
          </Button>
        )}
        {modRule._id && modRule._id !== '' && (
          <>
            <Button
              variant='secondary'
              type='submit'
              onClick={() => setPayOpen(true)}>
              {t('modrule.approval-line-btn')}
            </Button>
            <Button variant='secondary' type='submit' onClick={saveTemporary}>
              {t('modrule.temp-save-btn')}
            </Button>
            <Button variant='secondary' type='submit' onClick={submitModRule}>
              {t('modrule.register-draft-btn')}
            </Button>
          </>
        )}
        {payOpen ? (
          <RuleApproval user={auth} payOpen={payOpen} setPayOpen={setPayOpen} />
        ) : null}

        <Button
          variant='secondary'
          type='submit'
          onClick={DeleteModRuleAndExit}>
          {t('modrule.write-cancel-btn')}
        </Button>
      </div>
    </div>
  );
};

export default ModRuleDraftHeader;
