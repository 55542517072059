import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import '../../../scss/ModRule/ModRule.scss';
import { useTranslation } from 'react-i18next';
import ModRuleChangeApproval from './ModRuleChangeApproval';
import ModRuleConfirmApproval from './ModRuleConfirmApproval';
import ModRuleViewApproval from './ModRuleViewApproval';
// import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useModRule from '../../../common/hooks/useModRule';
import ModRuleRevStatusModal from './ModRuleRevStatusModal';

const ModRuleOneApprovalHeader = () => {
  const { modRule } = useModRule();
  // const [update, setUpdate] = useState(false); setModRule
  const [changeOpen, setChangeOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [revOpen, setRevOpen] = useState(false);
  const { t } = useTranslation();

  const changeBtn = () => {
    setChangeOpen(prev => !prev);
  };

  return (
    <>
      {modRule?.status === 'rejected' && (
        <>
          <Button
            className='modRuleOne__changeBtn'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop'
            variant='secondary'
            onClick={() => toast(t('modrule.future-update'))}>
            <span className='hide__name'>
              {t('modrule.reRegister-drafter')}
            </span>
          </Button>
          <Button
            className='modRuleOne__changeBtn'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop'
            variant='secondary'
            onClick={() => setViewOpen(true)}>
            <span className='hide__name'>{t('modrule.view-approval')}</span>
          </Button>
        </>
      )}
      {/* {modRule?.status === 'approved' && (
        <Button
          className='modRuleOne__changeBtn'
          data-bs-toggle='modal'
          data-bs-target='#staticBackdrop'
          variant='secondary'>
          <span className='completeSave'>승인 취소</span>
        </Button>
      )} */}
      {modRule?.status === 'approved' && (
        <Button
          className='modRuleOne__changeBtn'
          data-bs-toggle='modal'
          data-bs-target='#staticBackdrop'
          variant='secondary'
          onClick={() => setViewOpen(true)}>
          <span className='hide__name'>{t('modrule.view-approval')}</span>
        </Button>
      )}
      {(modRule?.status === 'examined' || modRule?.status === 'draft') && (
        <>
          <Button
            className='modRuleOne__changeBtn'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop'
            variant='secondary'
            onClick={() => setConfirmOpen(true)}>
            <span className='hide__name'>{t('modrule.proceed-approval')}</span>
          </Button>
          <Button
            className='modRuleOne__changeBtn'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop'
            variant='secondary'
            onClick={changeBtn}>
            <span className='hide__name'>
              {t('modrule.change-approval-btn')}
            </span>
          </Button>
        </>
      )}
      {modRule?.classification === 'amend' && (
        <Button variant='secondary' onClick={() => setRevOpen(prev => !prev)}>
          <span className='hide__name'>{t('modrule.rev-sum-btn')}</span>
        </Button>
      )}
      {(() => {
        if (confirmOpen) {
          return (
            <ModRuleConfirmApproval
              confirmOpen={confirmOpen}
              setConfirmOpen={setConfirmOpen}
            />
          );
        }
        if (changeOpen) {
          return (
            <ModRuleChangeApproval
              changeOpen={changeOpen}
              setChangeOpen={setChangeOpen}
            />
          );
        }
        if (viewOpen) {
          return (
            <ModRuleViewApproval
              viewOpen={viewOpen}
              setViewOpen={setViewOpen}
            />
          );
        }
        if (revOpen) {
          return (
            <ModRuleRevStatusModal revOpen={revOpen} setRevOpen={setRevOpen} />
          );
        }
        return null;
      })()}
    </>
  );
};

export default ModRuleOneApprovalHeader;
