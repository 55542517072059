import axios from 'axios';

const BASE_URL = process.env.REACT_APP_ORIGIN_URL;
// url 여러개면 instance를 다르게

export const axiosAuth = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosMultipart = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true,
});
