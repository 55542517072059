import React from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ModRuleStatusDcn from './ModRuleStatus/ModRuleStatusDcn';
import ModRuleCir from './ModRuleCir';
import ModRuleChecklist from './ModRuleChecklist';

const ModRuleStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className='modrulestatus'>
      <section className='modrulestatus__header'>
        <Button
          role='button'
          className={`modrulestatus__tabitem ${
            location.pathname.includes('/dcn') && 'active'
          }`}
          onClick={() => navigate('/modrule/dcn')}>
          DCN
        </Button>
        <Button
          role='button'
          className={`modrulestatus__tabitem ${
            location.pathname.includes('/cir') && 'active'
          }`}
          onClick={() => navigate('/modrule/cir')}>
          CIR
        </Button>
        <Button
          role='button'
          className={`modrulestatus__tabitem ${
            location.pathname.includes('/checklist') && 'active'
          }`}
          onClick={() => navigate('/modrule/checklist')}>
          CHECKLIST
        </Button>
      </section>
      <section className='modrulestatus__main'>
        {location?.pathname?.includes('/dcn') && <ModRuleStatusDcn />}
        {location?.pathname?.includes('/cir') && <ModRuleCir />}
        {location?.pathname?.includes('/checklist') && <ModRuleChecklist />}
      </section>
    </div>
  );
};

export default ModRuleStatus;
