import React, { createContext, useState, useMemo } from 'react';

const CompareContext = createContext({ newContent: '', oldContent: '' });

export const CompareProvider = ({ children, initialState }) => {
  const [compare, setCompare] = useState(initialState);
  const value = useMemo(() => ({ compare, setCompare }), [compare]);

  return (
    <CompareContext.Provider value={value}>{children}</CompareContext.Provider>
  );
};

export default CompareContext;
