import React from 'react';
import { Outlet } from 'react-router-dom';
import '../scss/ModRule/ModRule.scss';
import { ModRuleProvider } from '../store/ModRuleProvider';
import ModRuleMainHeader from '../components/ModRule/ModRuleMainHeader';

const ModRule = () => {
  return (
    <ModRuleProvider>
      <div className='modrule draftsection'>
        <ModRuleMainHeader />
        <Outlet />
      </div>
    </ModRuleProvider>
  );
};

export default ModRule;
