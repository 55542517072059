import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import '../../scss/ManagerAuth/ManagerAuthList.scss';
import { TiChevronRight } from 'react-icons/ti';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const AuthOrgList = forwardRef(
  ({ targetOrg, setTargetOrg, searchStr }, ref) => {
    const { t } = useTranslation();
    const [sidenav, setSidenav] = useState([]);
    const [filternav, setFilternav] = useState([]);
    const { auth } = useAuth();
    const axiosAuth = useAxiosAuth();
    useEffect(() => {
      const controller = new AbortController();
      const fetchSideNav = async () => {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/org/all/${auth.company}`,
        });
        setFilternav(res.data);
        setSidenav(res.data);
      };
      fetchSideNav();
      return () => {
        controller.abort();
      };
    }, []);

    const contentFilter = () => {
      const reg = new RegExp(searchStr, 'gi');
      if (searchStr === '') {
        setFilternav(sidenav);
      } else {
        const navCopy = sidenav.filter(el => el.name.match(reg));
        setFilternav(navCopy);
      }
    };

    useImperativeHandle(ref, () => ({
      contentFilter,
    }));

    const handleOrg = orgObj => {
      const { _id, path, name, company } = orgObj;
      setTargetOrg(prev => ({
        ...prev,
        _id,
        path,
        name,
        company,
      }));
    };
    return (
      <div className='ManagerAuthList'>
        <div className='ManagerAuthList__rulelistTitle'>
          <span>{t('managerauth.org')}</span>
        </div>
        <div className='Authcollapse'>
          <div>
            {filternav &&
              filternav.map(org => (
                <div
                  className={`Authcollapse__item ${
                    targetOrg.name === org.name ? 'select' : ''
                  }`}
                  key={org._id}
                  aria-hidden='true'
                  onClick={() => handleOrg(org)}>
                  <span className='Authcollapse__title'>
                    {org && `${org.name}`}
                  </span>
                  {targetOrg.name === org.name && (
                    <span>
                      <TiChevronRight size='10' />
                    </span>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
);
export default AuthOrgList;
