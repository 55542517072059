import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { MdOutlineSummarize } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import ModRuleRevModal from '../ModRule/ModRuleOne/ModRuleRevModal';
import ModRuleViewApproval from '../ModRule/ModRuleOne/ModRuleViewApproval';

const ReferenceSubMenu = ({ modRule, setUpdate }) => {
  const { t } = useTranslation();
  // const [confirmOpen, setConfirmOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [revOpen, setRevOpen] = useState(false);

  return (
    <>
      <Button
        className='modRuleOne__changeBtn'
        data-bs-toggle='modal'
        data-bs-target='#staticBackdrop'
        variant='secondary'
        onClick={() => setViewOpen(true)}>
        <span className='hide__name'>{t('reference.view-approval-line')}</span>
      </Button>

      {viewOpen && (
        <ModRuleViewApproval
          reference
          ruleModId={modRule && modRule[2]._id}
          viewOpen={viewOpen}
          setViewOpen={setViewOpen}
        />
      )}
      {modRule && modRule[2].classification === 'amend' ? (
        <Button onClick={() => setRevOpen(prev => !prev)}>
          <span className='hide__name'>{t('reference.view-rev-sum')}</span>
          <MdOutlineSummarize size={20} />
        </Button>
      ) : null}
      <Modal
        className='modRuleOne__revStatusModal'
        dialogClassName='modRuleOne__revStatusModal-modal'
        show={revOpen}
        // size='xl'
        onHide={setRevOpen}
        backdrop='static'
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className='modRuleOne__revStatusModal__title'>
            {t('reference.rev-sum')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modRuleOne__revStatusModal__body'>
          <ModRuleRevModal
            setUpdate={setUpdate}
            ruleModId={modRule && modRule[2]._id}
            ruleNumber={modRule && modRule[1].ruleNumber}
            revstatus={modRule && modRule[2].revstatus}
            setRevOpen={setRevOpen}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReferenceSubMenu;
