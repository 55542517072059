import { useRef, useEffect } from 'react';
import useLogout from './common/hooks/useLogout';
import useAuth from './common/hooks/useAuth';

const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

const AppTimer = ({ children }) => {
  const logout = useLogout();
  const { auth } = useAuth();
  const timerRef = useRef(null);

  const resetTimer1 = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };
  // 밀리세컨드 : 3600000 MLS = 60 MIN
  const handleTimer = () => {
    timerRef.current = setTimeout(() => {
      Object.values(events).forEach(item => {
        window.removeEventListener(item, resetTimer1);
      });
      logout();
    }, 3600000);
  };

  useEffect(() => {
    if (auth.accessToken) {
      Object.values(events).forEach(ev => {
        window.addEventListener(ev, () => {
          resetTimer1();
          handleTimer();
        });
      });
    }
  }, [auth]);

  return children;
};

export default AppTimer;
