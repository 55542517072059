import React, { createContext, useState, useMemo } from 'react';

const SortChaptersContext = createContext();

const initialState = {
  chapters: [],
  delChapters: [],
};

export const SortChaptersProvider = ({ children }) => {
  const [sortChapters, setSortChapters] = useState(initialState);
  const value = useMemo(
    () => ({ sortChapters, setSortChapters }),
    [sortChapters]
  );

  return (
    <SortChaptersContext.Provider value={value}>
      {children}
    </SortChaptersContext.Provider>
  );
};

export default SortChaptersContext;
