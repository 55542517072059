import { useContext, useDebugValue } from 'react';
import CompareContext from '../../store/CompareProvider';

const useCompare = () => {
  const { compare } = useContext(CompareContext);
  useDebugValue(compare, el => (el ? 'ondata' : 'offdata'));
  return useContext(CompareContext);
};

export default useCompare;
