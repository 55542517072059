import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { toast } from 'react-toastify';
import { BiUndo } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useModRule from '../../common/hooks/useModRule';
import ModRuleOneApprovalHeader from './ModRuleOne/ModRuleOneApprovalHeader';
import ModRuleOneAmend from './ModRuleOne/ModRuleOneAmend';
import ModRuleOneEnact from './ModRuleOne/ModRuleOneEnact';
import '../../scss/ModRule/ModRuleOne.scss';
import { ModChapterProvider } from '../../store/ModChapterProvider';

const ModRuleOne = () => {
  const { id } = useParams();
  const navi = useNavigate();
  const axiosAuth = useAxiosAuth();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { auth } = useAuth();
  const { modRule, setModRule } = useModRule();
  const [writeComment, setWriteComment] = useState([]);
  const [changeComment, setChangeComment] = useState([]);
  const [update, setUpdate] = useState(false);

  const { t } = useTranslation();

  const goBack = () => {
    navi(-1);
  };

  useEffect(() => {
    const modRules = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/modrule/${id}`,
        });
        setModRule(res.data);

        setUpdate(false);
      } catch (err) {
        console.log(err);
      }
    };
    modRules();
  }, [id, update]);

  const currentComment = e => {
    setWriteComment(e.target.value);
  };
  const commentSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/dev/comment/new`,
        data: {
          modRuleId: modRule._id,
          userId: auth.id,
          comment: writeComment,
        },
      });
      if (res.status === 200) {
        setModRule(prev => ({
          ...prev,
          comments: res.data?.comments,
        }));
        toast(t('modrule.submit-comment'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const targetComment = e => {
    setChangeComment(e.target.value);
  };
  const commentEdit = async el => {
    const currentCommentId = el._id;
    if (window.confirm(t('modrule.edit-comment-q'))) {
      try {
        const res = await axiosAuth({
          method: 'PUT',
          url: `/api/dev/comment/edit`,
          data: {
            modRuleId: modRule._id,
            commentId: currentCommentId,
            comment: changeComment,
          },
        });
        if (res.status === 200) {
          setModRule(prev => ({
            ...prev,
            comments: res.data?.comments,
          }));
          toast(t('modrule.edit-comment'));
        }
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const commentDel = async el => {
    const currentCommentId = el._id;
    if (window.confirm(t('modrule.del-comment-q'))) {
      try {
        const res = await axiosAuth({
          method: 'DELETE',
          url: `/api/dev/comment/del`,
          params: {
            modrule: modRule._id,
            comment: currentCommentId,
          },
        });
        if (res.status === 200) {
          setModRule(prev => ({
            ...prev,
            comments: res.data?.comments,
          }));
          toast(t('modrule.del-comment'));
        }
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <ModChapterProvider>
      <div className='modRuleOne'>
        <div className='modRuleOne__subContent'>
          <div className='modRuleOne__subContent__left'>
            <BiUndo type='button' onClick={goBack} size={32} />
          </div>
          <div className='modRuleOne__subContent__right'>
            <ModRuleOneApprovalHeader />
          </div>
        </div>
        <div className='modRuleOne__mainBody'>
          {modRule?._id !== '' && (
            <>
              <section className='modRuleOne__table'>
                <Table className='text-center'>
                  <thead>
                    <tr>
                      <td>{t('table.status')}</td>
                      <td>{t('table.category')}</td>
                      <td>{t('table.rule-title')}</td>
                      <td>{t('table.part')}</td>
                      <td>{t('table.draft-date')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width='10%'>
                        {(() => {
                          switch (modRule?.status) {
                            case 'draft':
                              return <span>{t('modrule.under-reviews')}</span>;
                            case 'rejected':
                              return (
                                <span style={{ color: 'orangered' }}>
                                  {t('modrule.tab4')}
                                </span>
                              );
                            case 'examined':
                              return <span>{t('modrule.tab5')}</span>;
                            case 'approved' &&
                              modRule?.classification === 'enact':
                              return <span>{t('modrule.pending-enact')}</span>;
                            case 'approved' &&
                              modRule?.classification === 'amend':
                              return <span>{t('modrule.pending-amend')}</span>;
                            default:
                              return <span>{t('modrule.submit-draft')}</span>;
                          }
                        })()}
                      </td>
                      <td width='25%'>
                        {modRule?.refCategory
                          ? `[${modRule.refCategory.categoryNumber}] ${modRule.refCategory.category}`
                          : `(${t('modrule.set-no-categoryName')})`}
                      </td>
                      <td width='40%'>
                        {modRule?.classification === 'enact' &&
                        modRule?.ruleNumber !== ''
                          ? `[${modRule?.ruleNumber}] ${modRule?.ruleName}`
                          : `[${modRule?.refRule?.ruleNumber}] ${modRule?.refRule?.ruleName}`}
                      </td>
                      <td width='10%'>
                        {modRule?.classification === 'enact'
                          ? t('modrule.enact')
                          : t('modrule.amend')}
                      </td>
                      <td width='10%'>
                        {modRule?.draftedAt !== '' &&
                          timezoneFormat(
                            utcToZonedTime(
                              toDate(modRule.draftedAt, {
                                timeZone: 'UTC',
                              }),
                              timezone
                            ),
                            'yyyy-MM-dd',
                            { timeZone: timezone }
                          )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </section>
              <section className='modRuleOne__content'>
                {modRule?.classification === 'amend' ? (
                  <ModRuleOneAmend />
                ) : (
                  <ModRuleOneEnact />
                )}
              </section>
              <section className='modRuleOne__comment'>
                <div className='modRuleOne__comment__box'>
                  <div className='modRuleOne__comment__box__left'>
                    <span>{t('modrule.submit-comment-btn')}</span>
                    <textarea
                      type='text'
                      name='content'
                      placeholder={t('modrule.content-placeholder')}
                      value={writeComment}
                      onChange={currentComment}
                    />
                    {modRule?.status === 'hold' ||
                    modRule?.status === 'preRevision' ? null : (
                      <Button style={{ width: '10vw' }} onClick={commentSubmit}>
                        {t('modrule.submit-comment-btn')}
                      </Button>
                    )}
                  </div>
                  <div className='modRuleOne__comment__box__right'>
                    <div className='commentTable'>
                      <div className='commentTable__thead'>
                        <span>
                          {t('modrule.coment-history')}(
                          {modRule?.comments.length})
                        </span>
                      </div>
                      <div className='commentTable__tbody'>
                        <table className='table'>
                          <tbody style={{ textAlign: 'center' }}>
                            {modRule?.comments.map(el => (
                              <tr key={el._id}>
                                <td width='18%'>
                                  {timezoneFormat(
                                    utcToZonedTime(
                                      toDate(el.createdAt, {
                                        timeZone: 'UTC',
                                      }),
                                      timezone
                                    ),
                                    'yyyy/MM/dd hh:mm',
                                    { timeZone: timezone }
                                  )}
                                </td>
                                <td width='20%'>{`${el.positionName} ${el.username}`}</td>
                                <td className='targetComment' width='62%'>
                                  {el.employno === auth.employno ? (
                                    <>
                                      <input
                                        onChange={targetComment}
                                        defaultValue={el.comment}
                                        style={{
                                          border: 'none',
                                          minWidth: '20vw',
                                        }}
                                      />
                                      <Button
                                        variant='secondary'
                                        style={{ color: 'orangered' }}
                                        onClick={() => commentDel(el)}>
                                        {t('button.delete')}
                                      </Button>
                                      <Button
                                        variant='secondary'
                                        style={{ color: '#aaa' }}
                                        onClick={() => commentEdit(el)}>
                                        {t('button.edit')}
                                      </Button>
                                    </>
                                  ) : (
                                    el.comment
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </div>
    </ModChapterProvider>
  );
};

export default ModRuleOne;
