import React from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertTriangle } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
// import useLogout from '../common/hooks/useLogout';

const NotFound = () => {
  // const logout = useLogout();

  // useEffect(() => {
  //   logout();
  // }, []);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { t } = useTranslation();

  return (
    <section className='unauthorized'>
      <div className='unauthorized__icon'>
        <TbAlertTriangle color='rgb(241, 133, 70)' size={150} />
      </div>
      <div className='unauthorized__text'>
        <p>
          {t('notfound.sorry')}
          <br />
          <span>{t('notfound.page-notfound-text')}</span>
        </p>
        <span style={{ color: 'rgb(241, 133, 70)' }}>Page Not Found</span>
      </div>

      <div className='unauthorized__backBtn'>
        <button type='button' onClick={goBack}>
          {t('notfound.back')}
        </button>
      </div>
    </section>
  );
};

export default NotFound;
