import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  MdAddCircleOutline,
  MdClose,
  MdFileDownload,
  MdFileDownloadOff,
  MdPrint,
  MdPrintDisabled,
} from 'react-icons/md';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import SearchBar from '../SearchBar';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import AuthOrgList from './AuthOrgList';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';

const ViewBadge = ({ catenum, email, updateFunc }) => {
  const axiosAuth = useAxiosAuth();
  const id = catenum._id;
  const { t } = useTranslation();

  let currentCate = catenum.categoryNumber;
  let currentClass = 'default';
  if (currentCate.includes('M')) {
    currentClass = 'm';
  } else if (currentCate.length > 7) {
    currentClass = 'etc';
    currentCate = catenum.categoryNumber.slice(0, 7);
  } else if (currentCate.includes('SP')) {
    currentClass = 'sp';
  } else if (currentCate.includes('P')) {
    currentClass = 'p';
  } else if (currentCate.includes('I')) {
    currentClass = 'i';
  } else if (currentCate.includes('OCD')) {
    currentClass = 'ocd';
  } else if (currentCate.includes('D')) {
    currentClass = 'd';
  } else if (currentCate.includes('E')) {
    currentClass = 'e';
  }

  const handleGradeDel = async () => {
    if (window.confirm(t('groupChart.gradeChart.del-permission-q'))) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/management/userviewdelete`,
          data: {
            email,
            viewId: id,
          },
        });
        toast(res.data.message || t('groupChart.gradeChart.del-permission'));
        updateFunc();
      } catch (err) {
        toast(t('groupChart.gradeChart.del-failed'));
        console.log(err);
      }
    }
  };

  const handleSwitch = async per => {
    if (window.confirm(t('groupChart.gradeChart.status-change-q'))) {
      if (per === 'print') {
        try {
          const res = await axiosAuth({
            method: 'POST',
            url: '/api/management/userviewprint',
            data: {
              email,
              viewId: id,
            },
          });
          toast(res.data.message || t('groupChart.gradeChart.status-change'));
          updateFunc();
        } catch (err) {
          toast(t('groupChart.gradeChart.status-failed'));
          console.log(err);
        }
      } else {
        try {
          const res = await axiosAuth({
            method: 'POST',
            url: '/api/management/userviewdown',
            data: {
              email,
              viewId: id,
            },
          });
          toast(res.data.message || t('groupChart.gradeChart.status-change'));
          updateFunc();
        } catch (err) {
          toast(t('groupChart.gradeChart.status-change'));
          console.log(err);
        }
      }
    }
  };

  return (
    <div className={`viewbadge ${currentClass}`}>
      <span className='viewbadge__roleBtn'>{currentCate}</span>
      <span className='viewbadge__svgBtn'>
        {catenum.print ? (
          <MdPrint size={23} onClick={() => handleSwitch('print')} />
        ) : (
          <MdPrintDisabled
            size={23}
            onClick={() => handleSwitch('print')}
            style={{ fill: '#ccc' }}
          />
        )}
      </span>
      <span className='viewbadge__svgBtn'>
        {catenum.down ? (
          <MdFileDownload size={23} onClick={() => handleSwitch('down')} />
        ) : (
          <MdFileDownloadOff
            size={23}
            onClick={() => handleSwitch('down')}
            style={{ fill: '#ccc' }}
          />
        )}
      </span>
      <span>
        <MdClose
          id='viewbadge__closeBtn'
          size={20}
          onClick={handleGradeDel}
          style={{ fill: '#000' }}
        />
      </span>
    </div>
  );
};

// isEdite
const UserRole = ({ user, setUpdate }) => {
  const {
    id,
    username,
    email,
    grade,
    position,
    notice,
    rulemanager,
    examine,
    approve,
    permissions,
  } = user;
  const axiosAuth = useAxiosAuth();
  const [changeOpen, setChangeOpen] = useState('');
  const [targetGrade, setTargetGrade] = useState({});
  const [targetPosi, setTargetPosi] = useState({});
  const [gradeList, setGradeList] = useState([]);
  const [posiList, setPosiList] = useState([]);
  const [popup, setPopup] = useState(false);
  const [rules, setRules] = useState([]);
  const { auth } = useAuth();
  const { t } = useTranslation();

  const handleOneAuth = async e => {
    e.preventDefault();
    const { checked, value } = e.target;
    const data = {
      email: user.email,
      roleName: value,
    };
    if (checked) {
      // checked는 박스가 체크되어있으면 false
      await axiosAuth
        .post('/api/management/userauthadd', data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => toast(err.response.data));
    } else {
      await axiosAuth
        .post('/api/management/userauthdelete', data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => toast(err.response.data));
    }
  };

  const handelChangeOpen = async type => {
    if (changeOpen === type) {
      setChangeOpen('');
      return;
    }
    setChangeOpen(type);
    if (type === 'grade') {
      await axiosAuth
        .get(`/api/grade/all/${auth.company}`)
        .then(res => {
          const gradescopy = res.data.map(gr => ({
            label: gr.gradeName,
            value: gr,
          }));
          setGradeList(gradescopy);
        })
        .catch(err => console.log(err));
    }
    if (type === 'posi') {
      await axiosAuth
        .get('/api/position/all')
        .then(res => {
          const sortPosi = res.data.sort((a, b) => a.sortNum - b.sortNum);
          const posiscopy = sortPosi.map(po => ({
            label: po.positionName,
            value: po,
          }));
          setPosiList(posiscopy);
        })
        .catch(err => console.log(err));
    }
  };

  const currentGrade = e => {
    setTargetGrade(e.value);
  };

  const currentPosi = e => {
    setTargetPosi(e.value);
  };

  const changeGradeSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/management/edit/usergrade`,
        data: {
          grade_id: targetGrade._id,
          user_id: id,
        },
      });
      toast(res.data.message || t('managerauth.grade-edit'));
      setUpdate(prev => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  const changePosiSubmit = async () => {
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/management/edit/userposition',
        data: {
          email,
          positionName: targetPosi.positionName,
        },
      });
      toast(response.data.message);
      setUpdate(prev => !prev);
    } catch (error) {
      toast(error.response.data);
    }
  };

  const handleGradeperAdd = async () => {
    if (popup) {
      setPopup(false);
      return;
    }
    setPopup(true);
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/management/userpers',
        data: {
          email,
        },
      });
      setRules(response.data.categories);
    } catch (error) {
      toast(error.response.data);
    }
  };

  const handleViewsAdd = async view => {
    const data = {
      email,
      categoryNumber: view.categoryNumber,
      category: view.category,
      sortNum: view.sortNum,
    };
    await axiosAuth
      .post('/api/management/userviewadd', data)
      .then(res => {
        toast(res.data);
        setUpdate(prev => !prev);
      })
      .catch(err => console.log(err));
  };

  return (
    <>
      <tr className='tableBox__body__tr'>
        <td className='td__username'>{`${username}(${email})`}</td>
        <td className='td__userchange'>
          {grade.gradeName}{' '}
          <CgArrowsExchangeAltV
            size={20}
            onClick={() => handelChangeOpen('grade')}
          />
        </td>
        <td width='10%' className='td__userchange'>
          {position.positionName}{' '}
          <CgArrowsExchangeAltV
            size={20}
            onClick={() => handelChangeOpen('posi')}
          />
        </td>
        <td>
          <input
            type='checkbox'
            checked={notice}
            name='notice'
            value='notice'
            id={`noticecheck_${id}`}
            onClick={e => handleOneAuth(e)}
            readOnly
          />
        </td>
        <td className='tableBox__body__button'>
          <label htmlFor={`draftcheck_${id}`}>
            <input
              type='checkbox'
              name='rulemanager'
              value='rulemanager'
              checked={rulemanager}
              id={`draftcheck_${id}`}
              onClick={e => handleOneAuth(e)}
              readOnly
            />
            {t('managerauth.draft')}
          </label>
          <label htmlFor={`examinecheck_${id}`}>
            <input
              type='checkbox'
              name='examine'
              value='examine'
              checked={examine}
              id={`examinecheck_${id}`}
              onClick={e => handleOneAuth(e)}
              readOnly
            />
            {t('managerauth.reference')}
          </label>
          <label htmlFor={`approvecheck_${id}`}>
            <input
              type='checkbox'
              name='approve'
              value='approve'
              checked={approve}
              id={`approvecheck_${id}`}
              onClick={e => handleOneAuth(e)}
              readOnly
            />
            {t('managerauth.approve')}
          </label>
        </td>
        <td>
          <CgArrowsExchangeAltV
            size={20}
            onClick={() => handelChangeOpen('pers')}
          />
        </td>
      </tr>
      {(() => {
        if (changeOpen === 'posi') {
          return (
            <tr className='tableBox__body__toggletr'>
              <td>
                <CgArrowsExchangeAltV size={26} />
                {t('managerauth.edituserposition.title')}
              </td>
              <td colSpan={3}>
                <Select
                  styles={blackStyles}
                  placeholder={
                    <div>{t('managerauth.edituserposition.pleaseselect')}</div>
                  }
                  isSearchable
                  options={posiList}
                  onChange={currentPosi}
                />
              </td>
              <td colSpan={2}>
                <Button
                  type='submit'
                  variant='secondary'
                  onClick={() => setChangeOpen('')}>
                  {t('button.cancel')}
                </Button>
                <Button
                  type='submit'
                  variant='secondary'
                  onClick={changePosiSubmit}>
                  {t('button.edit')}
                </Button>
              </td>
            </tr>
          );
        }
        if (changeOpen === 'grade') {
          return (
            <tr className='tableBox__body__toggletr'>
              <td>
                <CgArrowsExchangeAltV size={26} />
                {t('managerauth.editusergrade.title')}
              </td>
              <td colSpan={3}>
                <Select
                  styles={blackStyles}
                  placeholder={
                    <div>{t('managerauth.editusergrade.pleaseselect')}</div>
                  }
                  isSearchable
                  options={gradeList}
                  onChange={currentGrade}
                />
              </td>
              <td colSpan={2}>
                <Button
                  type='submit'
                  variant='secondary'
                  onClick={() => setChangeOpen('')}>
                  {t('button.cancel')}
                </Button>
                <Button
                  type='submit'
                  variant='secondary'
                  onClick={changeGradeSubmit}>
                  {t('button.edit')}
                </Button>
              </td>
            </tr>
          );
        }
        if (changeOpen === 'pers') {
          return (
            <tr className='tableBox__body__toggletr'>
              <td className='viewrolesTd' colSpan='5'>
                <div className='viewrolesTd__head'>
                  {t('managerauth.accessible')}
                </div>
                <div className='viewrolesTd__badges'>
                  {permissions.view.map(viewrole => (
                    <ViewBadge
                      key={viewrole._id}
                      catenum={viewrole}
                      email={email}
                      updateFunc={setUpdate}
                    />
                  ))}
                </div>
              </td>
              <td className='viewrolesBtns' align='center'>
                <div>
                  <MdAddCircleOutline
                    className='viewrolesTd__addBox'
                    size={26}
                    onClick={handleGradeperAdd}
                  />
                </div>
                {popup && (
                  <div className='viewrolesBtns__popup'>
                    <div>{t('managerauth.access-deny')}</div>
                    <div>
                      {rules &&
                        rules.map(view => (
                          <div className='cates__item' key={view._id}>
                            <div>
                              <MdAddCircleOutline
                                className='cates__item__addbtn'
                                size={25}
                                onClick={() => handleViewsAdd(view)}
                              />
                            </div>
                            <div>
                              <span>{`[${view.categoryNumber}]:${view.category}`}</span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </td>
            </tr>
          );
        }
        return null;
      })()}
    </>
  );
};

const AuthbyOrg = () => {
  const [targetOrg, setTargetOrg] = useState({
    _id: '',
    path: '',
    name: '',
    company: '',
  });
  // const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [update, setUpdate] = useState(false);
  const [sortValues, setSortValues] = useState({
    sortType: 'grade',
    sortUpDown: false,
    sortDisable: true,
  });
  const orglistRef = useRef({});
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const userList = async () => {
      setIsLoading(true);
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/org/byorg/${targetOrg._id}`,
        });
        const validateUsers = res.data.doc.map(user => ({
          id: user._id,
          username: user.username,
          email: user.email,
          position: user.position,
          grade: user.grade,
          notice: user.roles.map(el => el.authority).includes('notice'),
          rulemanager: user.roles
            .map(el => el.authority)
            .includes('rulemanager'),
          examine: user.roles.map(el => el.authority).includes('examine'),
          approve: user.roles.map(el => el.authority).includes('approve'),
          permissions: user.permissions,
        }));
        validateUsers.sort((a, b) => {
          return a.position.sortNum - b.position.sortNum;
        });
        setFilterUsers(validateUsers);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    if (targetOrg && targetOrg._id !== '') {
      userList();
    }
    return () => {
      controller.abort();
    };
  }, [targetOrg, update]);

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  const searchText = () => {
    orglistRef.current.contentFilter();
  };

  const handleKeyDown = e => {
    const key = e.code;
    switch (key) {
      case 'Enter':
        searchText();
        break;
      default:
    }
  };

  const handleAllOneRole = async (type, role) => {
    let targetrole = '';
    const targetaction =
      type === 'add' ? t('managerauth.add') : t('managerauth.del');
    const targeturl = type === 'add' ? '/useralladdrole' : '/useralldeleterole';

    if (role === 'notice') {
      targetrole = t('managerauth.grade-edit');
    } else if (role === 'rulemanager') {
      targetrole = t('managerauth.ea-drarfft');
    } else if (role === 'examine') {
      targetrole = t('managerauth.ea-reference');
    } else {
      targetrole = t('managerauth.ea-approve');
    }

    let data = {
      roleName: role,
    };

    if (targetOrg.path) {
      data = { ...data, orgId: targetOrg._id };
    }

    if (
      window.confirm(
        `${t('all-grade-change1')} ${targetrole} ${t(
          'all-grade-change2'
        )} ${targetaction}${t('all-grade-change3')}`
      )
    ) {
      await axiosAuth
        .post(`/api/management${targeturl}`, data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response.data);
        });
    }
  };

  // sorting
  const resetNumber = num => {
    if (num === 'grade') {
      setSortValues(prev => ({
        ...prev,
        sortType: 'grade',
        sortUpDown: !prev.sortUpDown,
        sortDisable: !prev.sortDisable,
      }));
      if (sortValues.sortUpDown) {
        filterUsers.sort((a, b) => {
          return (
            +(a.grade.sortNum > b.grade.sortNum) ||
            -(a.grade.sortNum < b.grade.sortNum)
          );
        });
      } else
        filterUsers.sort((a, b) => {
          return (
            +(a.grade.sortNum < b.grade.sortNum) ||
            -(a.grade.sortNum > b.grade.sortNum)
          );
        });
    }
    if (num === 'posi') {
      setSortValues(prev => ({
        ...prev,
        sortType: 'posi',
        sortUpDown: !prev.sortUpDown,
      }));
      if (sortValues.sortUpDown) {
        filterUsers.sort((a, b) => {
          return (
            +(a.position.sortNum > b.position.sortNum) ||
            -(a.position.sortNum < b.position.sortNum)
          );
        });
      } else
        filterUsers.sort((a, b) => {
          return (
            +(a.position.sortNum < b.position.sortNum) ||
            -(a.position.sortNum > b.position.sortNum)
          );
        });
    }
  };
  useEffect(() => {
    setSortValues(prev => ({
      ...prev,
      sortType: 'posi',
      sortUpDown: false,
    }));
  }, [targetOrg]);

  return (
    <div className='userauth__section'>
      <section className='userauth__topHeader'>
        <div className='userauth__topHeader__leftBox'>
          <div className='groupbar'>
            <SearchBar
              value={searchStr}
              onChange={handlesearchStr}
              searchText={searchText}
              handleKeyDown={handleKeyDown}
            />
          </div>
          <div className='rulenum'>
            {t('managerauth.org')}
            {`: ${targetOrg.name || ''}`}
          </div>
        </div>
      </section>
      <section className='userauth__table'>
        <div className='userauth__table__list'>
          <AuthOrgList
            ref={orglistRef}
            setTargetOrg={setTargetOrg}
            targetOrg={targetOrg}
            searchStr={searchStr}
          />
        </div>
        <div className='userauth__table__userlist'>
          <table className='tableBox'>
            <thead className='tableBox__header'>
              <tr className='tableBox__header__firstorg'>
                <th width='30%' rowSpan={2}>
                  {t('managerauth.user')}
                </th>
                <th width='10%' rowSpan={2}>
                  <Button
                    variant='secondary'
                    onClick={() => resetNumber('grade')}>
                    {t('managerauth.grade')}
                    {sortValues.sortType === 'grade' &&
                    sortValues.sortUpDown ? (
                      <TiArrowSortedUp
                        size={20}
                        color={
                          sortValues.sortType === 'grade' ? 'orange' : 'gray'
                        }
                      />
                    ) : (
                      <TiArrowSortedDown
                        size={20}
                        color={
                          sortValues.sortType === 'grade' ? 'orange' : 'gray'
                        }
                      />
                    )}
                  </Button>
                </th>
                <th width='10%' rowSpan={2}>
                  <Button
                    variant='secondary'
                    onClick={() => resetNumber('posi')}>
                    {t('managerauth.position')}
                    {sortValues.sortType === 'posi' && sortValues.sortUpDown ? (
                      <TiArrowSortedUp
                        size={20}
                        color={
                          sortValues.sortType === 'posi' ? 'orange' : 'gray'
                        }
                      />
                    ) : (
                      <TiArrowSortedDown
                        size={20}
                        color={
                          sortValues.sortType === 'posi' ? 'orange' : 'gray'
                        }
                      />
                    )}
                  </Button>
                </th>
                <th width='10%'>{t('managerauth.postnotice')}</th>
                <th width='30%'>{t('managerauth.managerule')}</th>
                <th width='10%' rowSpan={2}>
                  {t('managerauth.user')}
                </th>
              </tr>
              <tr className='tableBox__header__secondorg'>
                <th>
                  <BsCheckCircle
                    onClick={() => handleAllOneRole('add', 'notice')}
                  />
                  <BsXCircle
                    onClick={() => handleAllOneRole('del', 'notice')}
                  />
                </th>
                <th className='tableBox__header__thrulemode'>
                  <span>
                    <BsCheckCircle
                      onClick={() => handleAllOneRole('add', 'rulemanager')}
                    />
                    <BsXCircle
                      onClick={() => handleAllOneRole('del', 'rulemanager')}
                    />
                  </span>
                  <span>
                    <BsCheckCircle
                      onClick={() => handleAllOneRole('add', 'examine')}
                    />
                    <BsXCircle
                      onClick={() => handleAllOneRole('del', 'examine')}
                    />
                  </span>
                  <span>
                    <BsCheckCircle
                      onClick={() => handleAllOneRole('add', 'approve')}
                    />
                    <BsXCircle
                      onClick={() => handleAllOneRole('del', 'approve')}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className='tableBox__body__spinner'>
                <tr>
                  <td colSpan='7'>
                    <Spinner
                      animation='border'
                      role='status'
                      style={{
                        width: '4rem',
                        height: '4rem',
                      }}>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className='tableBox__body orgauth'>
                {targetOrg.name && filterUsers.length !== 0 ? (
                  filterUsers.map(user => (
                    <UserRole
                      key={user.id}
                      user={user}
                      targetOrg={targetOrg}
                      setUpdate={setUpdate}
                    />
                  ))
                ) : (
                  <tr className='tableBox__body__noselected'>
                    <td colSpan='7'>
                      <div>{t('managerauth.selectTargetorg')}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </section>
    </div>
  );
};

export default React.memo(AuthbyOrg);
