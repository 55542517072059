import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { MdLogin, MdOutlineClose } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import { BsList, BsSearch } from 'react-icons/bs';
import '../scss/Mobile/MobileMainNav.scss';
import useModalOpen from '../common/hooks/useModalOpen';
import useAuth from '../common/hooks/useAuth';
import useLogout from '../common/hooks/useLogout';
import MobileMainSearchBar from './MobileMainSearchBar';

const envtype = process.env.REACT_APP_ENVTYPE || '';

const MobileMainNav = ({ path }) => {
  const { modalOpen, setModalOpen } = useModalOpen();
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const navi = useNavigate();
  const logout = useLogout();
  const { auth } = useAuth();
  const { t } = useTranslation();

  const sidenavOpen = () => {
    setModalOpen({ ...modalOpen, leftside: true });
  };

  const sidenavClose = () => {
    setModalOpen({ ...modalOpen, leftside: false });
  };
  const goLink = route => {
    if (route === 'login') {
      navi('/login');
    } else {
      navi('/');
    }
  };
  const Logout = () => {
    logout();
    navi('/');
    setOpen(!open);
  };

  const handleSearchOpen = () => {
    // navi('/searchrule');
    setSearchOpen(prev => !prev);
    if (path === '/searchrule/:searchText' || path === '/') {
      setSearchOpen(false);
    }
  };
  useEffect(() => {
    if (path !== '/searchrule/:searchText') {
      setSearchOpen(false);
    }
  }, [path]);

  return (
    <header className='mobile-header'>
      <div className='mobile-header__wrap'>
        {auth.accessToken && (
          <div>
            {modalOpen && modalOpen.leftside ? (
              <Button
                type='button'
                variant='secondary'
                className='mobile-header__listBtn'
                onClick={() => sidenavClose()}>
                <MdOutlineClose size={25} />
              </Button>
            ) : (
              <Button
                type='button'
                variant='secondary'
                className='mobile-header__listBtn'
                onClick={() => sidenavOpen()}>
                <BsList size={25} />
              </Button>
            )}
          </div>
        )}
      </div>
      <div className='mobile-header__wrap'>
        <div>
          <Button
            type='button'
            variant='secondary'
            className='mobile-header__homeBtn'
            onClick={() => goLink('home')}>
            <span>{envtype && envtype === 'local' ? 'RULE BASE' : 'EDMS'}</span>
          </Button>
        </div>
      </div>
      <div className='mobile-header__wrap'>
        {auth.accessToken ? (
          <div>
            {path === '/' || path === '/login' ? null : (
              <Button onClick={handleSearchOpen}>
                <BsSearch size={20} />
              </Button>
            )}
            {searchOpen && (
              <div className='mobileSearchModal'>
                <div>
                  <MobileMainSearchBar />
                </div>
              </div>
            )}
            {/* )}
            {searchOpen && (
              <Modal
                className='mobileSearchModal'
                show={searchOpen}
                size='sm'
                backdrop='static'
                onHide={() => {
                  setSearchOpen(!searchOpen);
                }}>
                <div>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <span>{t('searchbar.button')}</span>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <MobileMainSearchBar setSearchOpen={setSearchOpen} />
                    </div>
                  </Modal.Body>
                </div>
              </Modal>
            )} */}
            <Button
              className='mobile-header__userBtn'
              onClick={() => {
                setOpen(!open);
              }}>
              <FaUserCircle size={20} />
            </Button>
            {open && (
              <Modal
                backdropClassName='mobileUserInfo'
                className='mobileUserInfoModal'
                show={open}
                size='sm'
                onHide={() => {
                  setOpen(!open);
                }}>
                <div className='mobileUserInfoModal__sec'>
                  <Modal.Header>
                    <Modal.Title>
                      <div className='mobileUserInfoModal__sec__name'>
                        {auth.accessToken && auth.email}
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='mobileUserInfoModal__sec__page'>
                    <NavLink to='/mypage' onClick={() => setOpen(false)}>
                      {t('mainnav.mypage')}
                    </NavLink>
                  </Modal.Body>
                  <Modal.Footer className='mobileUserInfoModal__sec__out'>
                    <Button variant='secondary' onClick={Logout}>
                      {t('mainnav.logout')}
                    </Button>
                  </Modal.Footer>
                </div>
              </Modal>
            )}
          </div>
        ) : (
          <Button
            className='mobile-header__btn'
            onClick={() => goLink('login')}>
            <MdLogin />
          </Button>
        )}
      </div>
    </header>
  );
};

export default MobileMainNav;
