import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import AuthTeamList from './AuthTeamList';
import SearchBar from '../SearchBar';
import { axiosAuth } from '../../common/axios/api';
import useAuth from '../../common/hooks/useAuth';
import '../../scss/ManagerAuth/AuthbyTeam.scss';

const UserList = ({ userList, targetOrg, setUpdate }) => {
  const { t, i18n } = useTranslation();

  const delManual = async user => {
    const { email, username } = user;
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `${username}님의 \n${targetOrg.name} 매뉴얼 권한을 삭제하시겠습니까?`
          : `Do you want to delete ${username}'s manual permissions for ${targetOrg.name}?`
      )
    )
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/manualPer/del',
          data: {
            email,
            orgId: targetOrg._id,
          },
        });
        if (response.status === 200) {
          toast(t('managerauth.manual-permission-del'));
          setUpdate(prev => !prev);
        }
      } catch (error) {
        console.log(error);
      }
  };

  const onChangeTeamManual = async (e, user) => {
    const { email } = user;
    const { value, checked } = e.target;
    const data = {
      email,
      roleName: value,
    };
    if (checked) {
      await axiosAuth
        .post('/api/management/userauthadd', data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => toast(err.response.data));
    } else {
      await axiosAuth
        .post('/api/management/userauthdelete', data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => toast(err.response.data));
    }
  };

  return (
    <>
      {userList.map(user => (
        <tr key={user.id} className='tableBox__body__selected'>
          <td
            aria-hidden='true'
            onClick={() => {
              delManual(user);
            }}>
            <MdRemoveCircleOutline size={25} />
          </td>
          <td align='left'>{`${user.username} (${user.email})`}</td>
          <td>{user.department}</td>
          <td>{user.position.positionName}</td>
          <td>
            <input
              type='checkbox'
              checked={user.authManual}
              name='teamManual'
              value='teamManual'
              onChange={e => onChangeTeamManual(e, user)}
            />
          </td>
        </tr>
      ))}
    </>
  );
};

const UnuserList = ({ unUserList, targetOrg, setUpdate }) => {
  const { t, i18n } = useTranslation();

  const addManual = async user => {
    const { username } = user;
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `${username}님에게 \n${targetOrg.name} 매뉴얼 권한을 추가하시겠습니까?`
          : `Do you want to add manual permissions for ${username} to ${targetOrg.name}?`
      )
    )
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/manualPer/add',
          data: {
            email: user.email,
            orgId: targetOrg._id,
          },
        });
        if (response.status === 200) {
          toast(t('managerauth.manual-permission-add'));
          setUpdate(prev => !prev);
        }
      } catch (error) {
        console.log(error);
      }
  };
  return (
    <>
      {unUserList.map(user => (
        <div
          key={user._id}
          aria-hidden='true'
          onClick={() => addManual(user)}
          className='authUnuserListBox__List__selected'>
          <MdAddCircleOutline size={25} />
          <span>{`${user.username} (${user.email})`}</span>
        </div>
      ))}
    </>
  );
};

const AuthbyTeam = () => {
  const { auth } = useAuth();
  const [teamList, setTeamList] = useState([]);
  const [filterTeamList, setFilterTeamList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [unUserList, setUnUserList] = useState([]);
  const [searchStr, setSearchStr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [sortValues, setSortValues] = useState({
    sortType: 'user',
    sortUpDown: false,
    sortDisable: true,
  });
  const [targetOrg, setTargetOrg] = useState({
    _id: '',
    path: '',
    name: '',
    company: '',
  });
  const id = targetOrg._id;
  const { t } = useTranslation();

  useEffect(() => {}, []);

  useEffect(() => {
    const fetchTeamList = async () => {
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/manualPer/manuals',
          data: {
            email: auth.email,
            company: auth.company,
          },
        });
        setTeamList(response.data);
        setFilterTeamList(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTeamList();
  }, []);

  useEffect(() => {
    const fetchOrgUserList = async () => {
      setIsLoading(true);
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: '/api/management/users',
        });
        const filteredUsers = res.data
          .filter(user => user.manualpermissions.org.includes(id))
          .map(user => ({
            id: user._id,
            username: user.username,
            position: user.position,
            department: user.org.name,
            authManual: user.roles.some(role => role.roleId === 'idx10'),
            roles: user.roles.map(role => role.authority),
            email: user.email,
            manualList: user.manualpermissions.org || [],
          }));
        const filteredUnuers = res.data
          .filter(user => !user.manualpermissions.org.includes(id))
          .map(user => ({
            id: user._id,
            username: user.username,
            position: user.position,
            department: user.org.name,
            authManual: user.roles.some(role => role.roleId === 'idx10'),
            roles: user.roles.map(role => role.authority),
            email: user.email,
            manualList: user.manualpermissions.org || [],
          }));
        setUserList(filteredUsers);
        setUnUserList(filteredUnuers);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchOrgUserList();
  }, [id, update]);

  // 검색기능
  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };
  useEffect(() => {
    const reg = new RegExp(searchStr, 'gi');
    if (searchStr !== '') {
      const filteredUsers = filterTeamList.filter(el => el.name.match(reg));
      setTeamList(filteredUsers);
    } else {
      setTeamList(filterTeamList);
    }
  }, [searchStr]);

  // 정렬
  const sortingItem = text => {
    setSortValues(prev => ({
      ...prev,
      sortType: text,
      sortUpDown: !prev.sortUpDown,
      sortDisable: !prev.sortDisable,
    }));

    const compareFunction = (a, b, property) => {
      return sortValues.sortUpDown
        ? +(a[property] > b[property]) || -(a[property] < b[property])
        : +(a[property] < b[property]) || -(a[property] > b[property]);
    };

    if (text === 'user') {
      userList.sort((a, b) => compareFunction(a, b, 'username'));
    } else if (text === 'dep') {
      userList.sort((a, b) => compareFunction(a, b, 'department'));
    }
  };
  useEffect(() => {
    setSortValues(prev => ({
      ...prev,
      sortType: 'user',
      sortUpDown: false,
    }));
  }, [targetOrg]);

  return (
    <div className='userauth__section'>
      <div className='userauth__topHeader'>
        <div className='userauth__topHeader__leftBox'>
          <div className='groupbar'>
            <SearchBar value={searchStr} onChange={handlesearchStr} />
          </div>
          <div className='teamName'>
            {t('managerauth.teamname')}
            {`: ${targetOrg.name || ''}`}
          </div>
        </div>
      </div>
      <section className='userauth__table teamPage__content'>
        <div className='authTeamListBox'>
          <div className='authTeamListBox__title'>
            <span>{t('managerauth.teamlist')}</span>
          </div>
          <div className='Authcollapse'>
            <AuthTeamList
              teamList={teamList}
              setTargetOrg={setTargetOrg}
              userList={userList}
              targetOrg={targetOrg}
            />
          </div>
        </div>
        <div className='authTableScrollBox'>
          <table className='tableBox'>
            <thead className='tableBox__header'>
              <tr>
                <th colSpan={2}>
                  <Button
                    variant='secondary'
                    onClick={() => sortingItem('user')}>
                    {t('managerauth.user')}
                    {sortValues.sortType === 'user' && sortValues.sortUpDown ? (
                      <TiArrowSortedUp
                        size={20}
                        color={
                          sortValues.sortType === 'user' ? 'orange' : 'gray'
                        }
                      />
                    ) : (
                      <TiArrowSortedDown
                        size={20}
                        color={
                          sortValues.sortType === 'user' ? 'orange' : 'gray'
                        }
                      />
                    )}
                  </Button>
                </th>
                <th width='20%' rowSpan={2}>
                  <Button
                    variant='secondary'
                    onClick={() => sortingItem('dep')}>
                    {t('managerauth.org')}
                    {sortValues.sortType === 'dep' && sortValues.sortUpDown ? (
                      <TiArrowSortedUp
                        size={20}
                        color={
                          sortValues.sortType === 'dep' ? 'orange' : 'gray'
                        }
                      />
                    ) : (
                      <TiArrowSortedDown
                        size={20}
                        color={
                          sortValues.sortType === 'dep' ? 'orange' : 'gray'
                        }
                      />
                    )}
                  </Button>
                </th>
                <th width='15%' rowSpan={2}>
                  {t('managerauth.position')}
                </th>
                <th width='15%'>{t('managerauth.managemanual')}</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className='tableBox__bodySpinner'>
                <tr>
                  <td colSpan='5'>
                    <Spinner
                      animation='border'
                      role='status'
                      style={{
                        width: '4rem',
                        height: '4rem',
                        margin: '30vh 0 0 23vw',
                      }}>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className='tableBox__body'>
                {targetOrg._id ? (
                  <UserList
                    userList={userList}
                    targetOrg={targetOrg}
                    setUpdate={setUpdate}
                  />
                ) : (
                  <tr className='tableBox__body__noselected'>
                    <td colSpan='5'>
                      <div>{t('managerauth.selectTargetorg')}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        <div className='authUnuserListBox'>
          <div className='authUnuserListBox__title'>
            <span>{t('managerauth.noAllowedUsers')}</span>
          </div>
          {isLoading ? (
            <Spinner
              animation='border'
              role='status'
              style={{
                width: '4rem',
                height: '4rem',
                margin: '30vh 0 0 10.5vw',
              }}>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          ) : (
            <div className='authUnuserListBox__List'>
              {targetOrg._id ? (
                <UnuserList
                  unUserList={unUserList}
                  targetOrg={targetOrg}
                  setUpdate={setUpdate}
                />
              ) : (
                <div className='authUnuserListBox__List__noselected'>
                  <span>{t('managerauth.selectTargetTeam')}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AuthbyTeam;
