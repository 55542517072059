import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ImBooks } from 'react-icons/im';
import { TbTemplate } from 'react-icons/tb';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

const ModRuleMainHeader = () => {
  const { i18n, t } = useTranslation();
  const [title, setTitle] = useState('');
  const { id } = useParams();

  const location = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (location.includes('/enactrule')) {
      if (i18n.language === 'ko') {
        setTitle('제정 기안서 작성');
      } else setTitle('ENACTMENT DRAFT');
    } else if (location.includes('/amendrule')) {
      if (i18n.language === 'ko') {
        setTitle('개정 기안서 작성');
      } else setTitle('AMENDMENT DRAFT');
    } else if (location.includes('/setting')) {
      if (i18n.language === 'ko') {
        setTitle('제•개정 설정');
      } else setTitle('ENACT/AMEND SETTING');
    } else if (location.includes(`/${id}`)) {
      if (i18n.language === 'ko') {
        setTitle('제•개정 진행현황 상세');
      } else setTitle('ENACT/AMEND DETAILS');
    } else if (location.includes('/modrule/dcn')) {
      if (i18n.language === 'ko') {
        setTitle('제•개정 진행현황 리스트');
      } else setTitle('ENACT/AMEND STATUS');
    } else if (location.includes('/modrule/cir')) {
      if (i18n.language === 'ko') {
        setTitle('CIR 관리');
      } else setTitle('MANAGEMENT CIRCULAR');
    } else if (location.includes('/modrule/checklist')) {
      if (i18n.language === 'ko') {
        setTitle('CHECKLIST 관리');
      } else setTitle('MANAGEMENT CHECKLIST');
    }
  }, [location, i18n.language]);

  return (
    <div className='modrule__header'>
      <div className='modrule__header__title'>{title}</div>
      {(() => {
        if (location === `/modrule/dcn`) {
          return (
            <div
              style={{ marginRight: '0' }}
              className='modrule__header__settingbtn'>
              <Button
                type='button'
                className='btns__item'
                onClick={() => navigate('/modrule/setting')}>
                <span className='hide__name'>{t('modrule.setting')}</span>
              </Button>
            </div>
          );
        }
        if (location.includes('/create')) {
          return (
            <div className='modrule__header__toggle'>
              <div className='modrule__header__togglewrap'>
                <span
                  className={`switch ${
                    location.includes('createrule') ? '' : 'createform'
                  }`}
                />
                <Link
                  to='createrule'
                  style={{
                    zIndex: 200,
                    cursor: 'pointer',
                  }}>
                  <label
                    className={`left-label ${
                      location.includes('/createform') && 'black-font'
                    }`}
                    htmlFor='left'>
                    <span>
                      <ImBooks size={18} />
                    </span>
                    <span>{t('modrule.rule')}</span>
                  </label>
                </Link>
                <Link
                  to='createform'
                  style={{
                    zIndex: 200,
                    cursor: 'pointer',
                  }}>
                  <label
                    className={`right-label ${
                      location.includes('/createrule') && 'black-font'
                    }`}
                    htmlFor='right'>
                    <span>
                      <TbTemplate size={18} />
                    </span>
                    <span>{t('modrule.form')}</span>
                  </label>
                </Link>
              </div>
            </div>
          );
        }
        if (location.includes('/edit')) {
          return (
            <div className='modrule__header__toggle'>
              <div className='modrule__header__togglewrap'>
                <span
                  className={`switch ${
                    location.includes('editrule') ? '' : 'editform'
                  }`}
                />
                <Link
                  to='editrule'
                  style={{
                    zIndex: 200,
                    cursor: 'pointer',
                  }}>
                  <label
                    className={`left-label ${
                      location.includes('/editform') && 'black-font'
                    }`}
                    htmlFor='left'>
                    <span>
                      <ImBooks size={18} />
                    </span>
                    <span>{t('modrule.rule')}</span>
                  </label>
                </Link>
                <Link
                  to='editform'
                  style={{
                    zIndex: 200,
                    cursor: 'pointer',
                  }}>
                  <label
                    className={`right-label ${
                      location.includes('/editrule') && 'black-font'
                    }`}
                    htmlFor='right'>
                    <span>
                      <TbTemplate size={18} />
                    </span>
                    <span>{t('modrule.form')}</span>
                  </label>
                </Link>
              </div>
            </div>
          );
        }
        return null;
      })()}
    </div>
  );
};

export default ModRuleMainHeader;
