import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import '../../../scss/ModRule/ModRuleApprovals.scss';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import useAuth from '../../../common/hooks/useAuth';
import DatePicker from '../../DatePicker/DatePicker';

const ModRuleStatusModal = ({ setUpdate, newOpen, setNewOpen }) => {
  const { id } = useAuth();
  const { t, i18n } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const location = useLocation();
  const [currentDcnNum, setCurrentDcnNum] = useState(0);
  const [revDate, setRevDate] = useState('');
  let dcnType = '';
  if (location.pathname.includes('dcn')) {
    dcnType = 'dcn';
  } else {
    dcnType = 'cir';
  }

  useEffect(() => {
    const controller = new AbortController();

    const currNum = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/dcn/latestnum/${dcnType}`,
        });
        setCurrentDcnNum(res.data.number);
      } catch (err) {
        console.log(err);
      }
    };
    currNum();
    return () => {
      controller.abort();
    };
  }, []);

  const submitNewDcn = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/dcn/add`,
        data: {
          type: dcnType,
          number: currentDcnNum,
          revisionTime: format(new Date(revDate), 'yyyy-MM-dd HH:mm:ss'),
          writer: id,
        },
      });
      if (res.status === 200) {
        toast(`DCN ${t('modrule.create-complete')}`);
        setUpdate(true);
        setNewOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRevDate = e => {
    const { value } = e.target;
    setRevDate(value);
  };

  return (
    <Modal
      className='modrulestatus__newdcnmodal'
      backdropClassName='double'
      show={newOpen}
      // size='lg'
      onHide={setNewOpen}
      backdrop='static'
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{`${dcnType.toUpperCase()} ${t('modrule.create')}`}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section>
          <div style={{ marginBottom: '2vh' }}>
            <h5>{`${t('modrule.new')} ${dcnType.toUpperCase()}${t(
              'modrule.num'
            )}`}</h5>
            <span
              style={{
                padding: '0.5vh',
                fontSize: '1.1rem',
                border: '2px solid orange',
                borderRadius: '5px',
              }}>{`${dcnType.toUpperCase()} ${currentDcnNum}`}</span>
          </div>
          <div>
            <h5>{t('modrule.enactAmend-approve-date')}</h5>
            <DatePicker
              name='revdate'
              startdate={revDate}
              onChangeDate={handleRevDate}
              language={i18n.language}
              style={{
                width: '100%',
                padding: '0.5vh',
                fontSize: '1.1rem',
              }}
            />
            {/* 안내 문구 */}
            <div
              style={{
                border: '2px solid #E71A21',
                padding: '0.5vh',
                fontSize: '1rem',
                backgroundColor: '#FFF3F2',
                color: '#333',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                marginTop: '20px',
              }}>
              <FaTriangleExclamation fill='#E71A21' size={20} />
              {i18n.language === 'ko' ? (
                <p
                  style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                  }}>
                  <span
                    style={{
                      fontSize: '1.1rem',
                      fontWeight: 'bold',
                      backgroundColor: '#FFF3F2',
                      color: '#20265B',
                    }}>
                    제 / 개정 승인날짜
                  </span>
                  는 각 규정의 제.개정일로 설정됩니다.
                </p>
              ) : (
                <p
                  style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                  }}>
                  <span
                    style={{
                      fontSize: '1.1rem',
                      fontWeight: 'bold',
                      backgroundColor: '#FFF3F2',
                      color: '#20265B',
                    }}>
                    Enactment/Amendment Approval Date
                  </span>{' '}
                  will be set as the enactment/amendment date for each
                  regulation.
                </p>
              )}
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setNewOpen(false)}>
          {t('button.cancel')}
        </Button>
        <Button
          className='modrulestatus__newdcnmodal__btn'
          data-bs-toggle='modal'
          data-bs-target='#staticBackdrop'
          variant='secondary'
          onClick={submitNewDcn}>
          {t('button.create')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModRuleStatusModal;
