import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { useParams, useNavigate } from 'react-router-dom';
import { BiUndo } from 'react-icons/bi';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const MobileRevStatusDetail = () => {
  const { t } = useTranslation();
  const { dcnNum } = useParams();
  const navi = useNavigate();
  const axiosAuth = useAxiosAuth();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    const fetchNotice = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/revstatus/dcns`,
        });
        const filteredList = res.data.filter(item =>
          item.modRuleRef.some(rule => rule.dcnNumber === `DCN${dcnNum}`)
        );
        setStatusList(filteredList);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchNotice();
  }, [axiosAuth, dcnNum]);

  const goBack = () => navi(-1);

  // 규정번호 갯수 표시
  const ruleNumCount = () => {
    let count;
    if (statusList.length > 0) {
      count = statusList[0].modRuleRef.length;
      return count;
    }
    return null;
  };

  return (
    <>
      <div className='mobileRevstatus__dcnNum'>
        <div className='mobileRevstatus__dcnNum dcnNumBack'>
          <BiUndo type='button' onClick={goBack} size={32} />
        </div>
        <p className='mobileRevstatus__dcnNum dcnNumTitle'>
          {t('modrule.rev-history')} - {dcnNum}
        </p>
      </div>

      {/* table 랜더링 */}
      <div className='mobileRevstatus__dcnNum__table'>
        <Table className='text-center'>
          <thead>
            <tr>
              <th width='33%'>{`${t(
                'table.rule-num'
              )} (${ruleNumCount()})`}</th>
              <th width='33%'>{`${t('table.chap-num')}`}</th>
              <th width='33%'>{t('table.rev-date')}</th>
            </tr>
          </thead>
          <tbody>
            {statusList.map((module, index) =>
              module.modRuleRef.map(rev => (
                <tr key={rev._id}>
                  <td align='left'>
                    <span>[{rev.ruleNumber}] </span>
                    <br />
                    {rev.ruleName}
                  </td>
                  <td align='left'>
                    {rev &&
                      rev.revstatus &&
                      rev.revstatus.map(el => (
                        <tr key={el._id} className='statusBox'>
                          <td className='chapterNumberBox'>
                            {el.chapter.map(chap => (
                              <p key={chap}>{chap}</p>
                            ))}
                          </td>
                          {/* <td className='summaryBox'>
                              {el.summary.map(sum => (
                                <p key={sum}>- {sum}</p>
                              ))}
                            </td> */}
                        </tr>
                      ))}
                  </td>
                  {index === 0 ? (
                    <td>
                      {timezoneFormat(
                        utcToZonedTime(
                          toDate(rev.revisedAt, {
                            timeZone: 'UTC',
                          }),
                          timezone
                        ),
                        'yyyy-MM-dd',
                        { timeZone: timezone }
                      )}
                    </td>
                  ) : (
                    <td>-</td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default MobileRevStatusDetail;
