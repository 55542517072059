import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Spinner,
} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../scss/ModRule/EnactRule.scss';
import { useTranslation } from 'react-i18next';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import useModRule from '../../common/hooks/useModRule';
import useAuth from '../../common/hooks/useAuth';
import RuleApproval from './RuleApproval';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import history from '../../common/hooks/history';
import AmendRuleSidelist from './AmendRule/AmendRuleSidelist';

const EditForm = () => {
  const { auth } = useAuth();
  const { modRule, setModRule } = useModRule();
  const navi = useNavigate();
  const location = useLocation();
  const [payOpen, setPayOpen] = useState(false);
  const [targetForm, setTargetForm] = useState(null);
  const [changeInfoOpen, setChangeInfoOpen] = useState(false);

  const [previews, setPreviews] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [formInputs, setFormInputs] = useState({
    formFileNum: '',
    formFileName: '',
  });
  const axiosMultipart = useAxiosMultipart();
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  useEffect(() => {
    const handleBeforeUnload = e => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.onbeforeunload = handleBeforeUnload;

    window.onbeforeunload = handleBeforeUnload;
    window.onunload = () => {
      alert(t('modrule.close-browser'));
    };

    return () => {
      window.onbeforeunload = null;
      window.onunload = null;
    };
  }, []);

  // ~ 서식 정보 변경===============
  const editFormInfo = () => {
    setChangeInfoOpen(prev => !prev);
  };
  const handlefileInputs = e => {
    const { value, name } = e.target;
    setFormInputs({
      ...formInputs,
      [name]: value,
    });
  };
  const delFormInfo = () => {
    setChangeInfoOpen(prev => !prev);
  };

  useEffect(() => {
    setFormInputs({
      formFileNum: targetForm && targetForm.formNumber,
      formFileName: targetForm && targetForm.formName,
    });
  }, [targetForm]);

  // ~ 서식 임시저장================
  const saveTemporary = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/temporary',
        data: {
          ruleModId: modRule._id,
          classification: 'enact',
        },
      });
      if (res.status === 200) {
        toast(t('modrule.temp-save'));
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.temp-save-failed'));
    }
  };

  const DeleteRuleModAndExit = async () => {
    if (window.confirm(t('modrule.close-del-draft'))) {
      try {
        const res = await axiosAuth({
          method: 'delete',
          url: `/api/modrule/${modRule._id}`,
        });
        if (res.status === 200) {
          setModRule({});
          navi(-1);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const listenBackEvent = () => {
      if (window.confirm(t('modrule.close-del-data-q'))) {
        navi(-1);
        setModRule({});
        setPreviews([]);
        setFile(null);
      } else {
        navi(location.pathname);
      }
    };
    const historyEvent = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });
    return historyEvent;
  }, []);

  useEffect(() => {
    setPreviews([]);
    setFile(null);
  }, [targetForm]);

  // ~ 서식 개정파일 미리보기==============
  const handlePreviewUpload = async e => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/modrule/formpreview',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      const docsCopy = [{ uri: res.data.url, fileType: res.data.docType }];
      setPreviews(docsCopy);
    } catch (err) {
      console.log(err);
    }
  };

  // ~ 서식 제정 modRule 연결=================
  const clickFormRev = async () => {
    if (modRule._id && targetForm._id && formInputs && modRule.lan && file) {
      setUploading(true);
      const formData = new FormData();
      formData.append('ruleModId', JSON.stringify(modRule._id));
      formData.append('oldFormId', JSON.stringify(targetForm._id));
      formData.append('formNumber', JSON.stringify(formInputs.formFileNum));
      formData.append('formName', JSON.stringify(formInputs.formFileName));
      formData.append('lan', JSON.stringify(modRule.lan));
      formData.append('file', file);
      try {
        const res = await axiosMultipart({
          method: 'POST',
          url: '/api/modrule/formchange',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
        });
        setModRule(prev => ({ ...prev, formRef: res.data.formRef }));
        setUploading(false);
        toast(t('modrule.add-amendFile'));
      } catch (err) {
        console.log(err);
      }
    } else {
      alert(t('modrule.confirm-draft'));
    }
  };

  // ~ 서식 개정이력 작성==============

  // ~ 서식 개정등록================
  const submitFormMod = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/modrule/draft',
        data: {
          ruleModId: modRule._id,
        },
      });
      if (res.status === 200) {
        toast(res.data.message);
        console.log(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='formNew'>
      <AmendRuleSidelist setTargetForm={setTargetForm} uploading={uploading} />
      <section className='formNew__maincontent'>
        <div className='formNew__maincontent__subContent'>
          <div className='formNew__maincontent__subContent__btnBox'>
            <Button
              variant='secondary'
              type='submit'
              onClick={() => setPayOpen(true)}>
              {t('modrule.approval-line-btn')}
            </Button>
            {payOpen ? (
              <RuleApproval
                user={auth}
                payOpen={payOpen}
                setPayOpen={setPayOpen}
              />
            ) : null}
            <Button variant='secondary' type='submit' onClick={saveTemporary}>
              {t('modrule.temp-save-btn')}
            </Button>
            <Button variant='secondary' type='submit' onClick={submitFormMod}>
              {t('modrule.register-draft-btn')}
            </Button>
            <Button
              variant='secondary'
              type='submit'
              onClick={DeleteRuleModAndExit}>
              {t('modrule.write-cancel-btn')}
            </Button>
          </div>
        </div>
        {!modRule?._id ? (
          <div className='formNew__maincontent__stepBox'>
            {t('modrule.select-amend-formRule')}
          </div>
        ) : (
          <div className='formNew__maincontent__viewercontainer'>
            <div className='formNew__maincontent__viewerold'>
              <div className='formNew__maincontent__viewerold__header'>
                {targetForm ? (
                  <>
                    <div>
                      <span>[{targetForm.formNumber}]</span>
                      {'  '}
                      <span>{targetForm.formName}</span>
                    </div>
                    <div>
                      <Button
                        // variant='secondary'
                        type='submit'
                        onClick={editFormInfo}>
                        {t('modrule.edit-info-btn')}
                      </Button>
                    </div>
                  </>
                ) : null}
                {changeInfoOpen && (
                  <div className='infoPopup'>
                    <div>
                      <FormGroup>
                        <FormLabel>
                          {t('modrule.form-num')}{' '}
                          <span
                            style={{
                              color: 'orangered',
                              fontSize: '0.8rem',
                              paddingLeft: '1vh',
                            }}>
                            *{t('modrule.no-edit')}
                          </span>
                        </FormLabel>
                        <FormControl
                          type='text'
                          defaultValue={formInputs.formFileNum}
                          name='formFileNum'
                          disabled
                          placeholder={t('modrule.edit-formNum-placeholder')}
                          onChange={handlefileInputs}
                        />
                      </FormGroup>
                      <br />
                      <FormGroup>
                        <FormLabel>{t('modrule.form-name')}</FormLabel>
                        <FormControl
                          type='text'
                          defaultValue={formInputs.formFileName}
                          name='formFileName'
                          placeholder={t('modrule.edit-formName-placeholder')}
                          onChange={handlefileInputs}
                        />
                      </FormGroup>
                    </div>
                    <hr />
                    <div>
                      <Button onClick={editFormInfo}>
                        {t('button.confirm')}
                      </Button>
                      <Button onClick={delFormInfo}>
                        {t('button.cancel')}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className='formNew__maincontent__viewerold__mainCon'>
                {/* 현재 서식 */}
                {targetForm ? (
                  <iframe
                    // ref={iframeRef}
                    sandbox='allow-scripts allow-same-origin allow-forms'
                    // onLoad={() => setLoading(false)}
                    title='preview'
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${targetForm.uri}`}
                    width='100%'
                    height='100%'
                    frameBorder='0'>
                    This is an embedded{' '}
                    <a href='http://office.com'>Microsoft Office</a> document,
                    powered by{' '}
                    <a href='http://office.com/webapps'>Office Online</a>.
                  </iframe>
                ) : (
                  <div
                    id='react-doc-viewer'
                    style={{
                      color: '#bbb',
                      textAlign: 'center',
                      paddingTop: '30vh',
                    }}>
                    {t('modrule.select-amend-formNum')}
                  </div>
                )}
              </div>
            </div>

            {/* 개정 서식 */}
            <div className='formNew__maincontent__viewernew'>
              <div className='formNew__maincontent__viewernew__header'>
                <div>
                  <input
                    type='file'
                    name='file'
                    // controlId='formFileMultiple'
                    encType='multipart/form-data'
                    onChange={handlePreviewUpload}
                  />
                  {uploading && (
                    <div className='updateSpinner'>
                      <Spinner />
                    </div>
                  )}
                </div>
                <div>
                  {/* <Button
                    variant='secondary'
                    type='submit'
                    onClick={addRevSummary}>
                    개정이력 작성
                  </Button> */}
                  <Button
                    // variant='secondary'
                    title={t('modrule.add-amend-form-file')}
                    onClick={e => clickFormRev(e)}>
                    {t('button.add')}
                  </Button>
                </div>
              </div>
              <div className='formNew__maincontent__viewernew__mainCon'>
                {previews.length === 0 ? (
                  <div
                    id='react-doc-viewer'
                    style={{
                      color: '#bbb',
                      textAlign: 'center',
                      paddingTop: '30vh',
                    }}>
                    {t('modrule.select-preview-amendFile')}
                  </div>
                ) : (
                  <iframe
                    sandbox='allow-scripts allow-same-origin allow-forms'
                    title='preview'
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${previews[0].uri}`}
                    width='100%'
                    height='100%'
                    frameBorder='0'>
                    This is an embedded{' '}
                    <a href='http://office.com'>Microsoft Office</a> document,
                    powered by{' '}
                    <a href='http://office.com/webapps'>Office Online</a>.
                  </iframe>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default EditForm;
