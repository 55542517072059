import React from 'react';
import '../scss/Mobile/MobileSetting.scss';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  HiOutlineDocumentSearch,
  HiOutlineShieldExclamation,
  HiOutlineQuestionMarkCircle,
} from 'react-icons/hi';
import { RiMailSendLine } from 'react-icons/ri';
import { TbCheckupList, TbLayoutGridAdd } from 'react-icons/tb';
import { MdOutlineFormatColorFill } from 'react-icons/md';

const MobileSetting = () => {
  const { t } = useTranslation();

  return (
    <div className='MobileSetting'>
      {/* <h6 className='MobileSetting__title'>설정</h6> */}
      <section className='MobileSetting__main'>
        <div>
          <Button variant='secondary'>
            <div>
              <HiOutlineShieldExclamation />
            </div>
            <span>{t('setting.releasenote')}</span>
          </Button>

          <Button variant='secondary'>
            <div>
              <HiOutlineDocumentSearch />
            </div>
            <span>{t('setting.userguide')}</span>
          </Button>
        </div>
        <div>
          <Button variant='secondary'>
            <div>
              <TbCheckupList />
            </div>
            <span>{t('setting.tac-short')}</span>
          </Button>

          <Button variant='secondary'>
            <div>
              <HiOutlineQuestionMarkCircle />
            </div>
            <span>{t('setting.faq')}</span>
          </Button>
        </div>
        <div>
          <Button variant='secondary'>
            <div>
              <MdOutlineFormatColorFill />
            </div>
            <span>{t('setting.theme')}</span>
          </Button>

          <Button variant='secondary'>
            <div>
              <TbLayoutGridAdd />
            </div>
            <span>{t('setting.quickmenu')}</span>
          </Button>
        </div>
        <div>
          <Button variant='secondary'>
            <div>
              <RiMailSendLine />
            </div>
            <span>{t('setting.mail')}</span>
          </Button>
        </div>
      </section>
    </div>
  );
};

export default MobileSetting;
