import React, { useEffect, useState } from 'react';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const Tr = ({ one }) => {
  if (one.revstatus.length === 0) {
    return (
      <tr>
        <td>{one.revno}</td>
        <td>{one.date}</td>
        <td> </td>
        <td> </td>
        <td>{one.remarks}</td>
      </tr>
    );
  }

  if (one.revstatus.length === 1) {
    return (
      <tr>
        <td>{one.revno}</td>
        <td>{one.date}</td>
        {one.revstatus.map(rev => (
          <>
            {parse(`<td class='chaptd'>${rev.chapter}</td>`)}
            {parse(`<td class='sumtd'>${rev.summary}</td>`)}
          </>
        ))}
        <td>{one.remarks}</td>
      </tr>
    );
  }

  const rownum = one.revstatus.length;
  return (
    <>
      <tr>
        <td rowSpan={rownum}>{one.revno}</td>
        <td rowSpan={rownum}>{one.date}</td>
        {parse(`<td class='chaptd'>${one.revstatus[0].chapter}</td>`)}
        {parse(`<td class='sumtd'>${one.revstatus[0].summary}</td>`)}
        <td rowSpan={rownum}>{one.remarks}</td>
      </tr>
      {one.revstatus.slice(1).map(rev => (
        <tr>
          {parse(`<td class='chaptd'>${rev.chapter}</td>`)}
          {parse(`<td class='sumtd'>${rev.summary}</td>`)}
        </tr>
      ))}
    </>
  );
};

const FormModModal = ({ ruleNumber }) => {
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [parsedRev, setParsedRev] = useState([]);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    const controller = new AbortController();
    const ruleNoticeFetch = async () => {
      await axiosAuth
        .get(`/api/revstatus/byrule/${ruleNumber}`)
        .then(res => {
          const pasingData = res.data.map(rev => ({
            id: rev.id,
            revno: rev.revno,
            date: timezoneFormat(
              utcToZonedTime(
                toDate(rev.date, {
                  timeZone: 'UTC',
                }),
                timezone
              ),
              'yyyy-MM-dd',
              { timeZone: timezone }
            ),
            revstatus: rev.revstatus.map(x => ({
              chapter: x.chapter.map(sum => `<pre>${sum}</pre>`).join(''),
              summary: x.summary.map(sum => `<pre>${sum}</pre>`).join(''),
            })),
            classification: rev.classification,
            remarks: rev.remarks,
            lan: rev.lan,
          }));
          setParsedRev(pasingData);
        })
        .catch(err => {
          console.log(err);
          setParsedRev([]);
        });
    };
    ruleNoticeFetch();
    return () => {
      controller.abort();
    };
  }, [ruleNumber]);

  return (
    <table className='modal__revstatus__table'>
      <thead>
        <tr>
          <th width='7%'>{t('table.rev-num')}</th>
          <th width='13%'>{t('table.date')}</th>
          <th width='15%'>{t('table.chapter')}</th>
          <th width='52%'>{t('table.revision-summary')}</th>
          <th width='13%'>{t('table.remark')}</th>
        </tr>
      </thead>
      <tbody>
        {parsedRev === [] ? (
          <div>{t('components.form.data-register')}</div>
        ) : (
          parsedRev.map(one => <Tr key={one._id} one={one} />)
        )}
      </tbody>
    </table>
  );
};

export default FormModModal;
