import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const ModRuleCirDescription = ({
  disOpen,
  setDisOpen,
  chapterContents,
  setChapterInputs,
}) => {
  const { t } = useTranslation();

  const [inputData, setInputData] = useState({
    header: '',
    children: [],
  });
  const [newChild, setNewChild] = useState('');
  useEffect(() => {
    setInputData(chapterContents);
  }, []);

  const handleAddChild = () => {
    setInputData({
      ...inputData,
      children: [...inputData.children, newChild.trim()],
    });
    setNewChild('');
  };

  const handleRemoveChild = index => {
    setInputData({
      ...inputData,
      children: inputData.children.filter((_, i) => i !== index),
    });
  };

  const descriptionEditSubmit = () => {
    setChapterInputs(prev => ({ ...prev, chapterContents: inputData }));
    toast(t('modrule.edit-desc'));
    setDisOpen(false);
  };
  const handleClose = () => {
    if (window.confirm(t('modrule.edit-desc-close'))) {
      setDisOpen(false);
    }
  };

  return (
    <Modal
      backdropClassName='double'
      size='lg'
      contentClassName='cirnew__popup__content'
      show={disOpen}
      backdrop='static'
      keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <span>{t('modrule.desc-register')}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{inputData.header}</h4>
        <ul>
          {inputData?.children?.length > 0 &&
            inputData.children.map((child, index) => (
              <li key={child}>
                <span>{child}</span>
                <span
                  aria-hidden='true'
                  style={{ padding: '0 10px', cursor: 'pointer', color: 'red' }}
                  onClick={() => handleRemoveChild(index)}>
                  X
                </span>
              </li>
            ))}
        </ul>
        <input
          type='text'
          value={newChild}
          onChange={e => setNewChild(e.target.value)}
          placeholder='Add new child'
        />
        <button type='button' onClick={handleAddChild}>
          {t('button.add')}
        </button>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={descriptionEditSubmit}>
          {t('modrule.desc-register')}
        </Button>
        <Button onClick={handleClose}>{t('button.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModRuleCirDescription;
