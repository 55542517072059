import React, { createContext, useState, useMemo } from 'react';

const ModChapterContext = createContext();

export const ModChapterProvider = ({ children, initialState }) => {
  const [modChapter, setModChapter] = useState(initialState);
  const value = useMemo(() => ({ modChapter, setModChapter }), [modChapter]);

  return (
    <ModChapterContext.Provider value={value}>
      {children}
    </ModChapterContext.Provider>
  );
};

export default ModChapterContext;
