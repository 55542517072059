import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../../scss/ModRule/ModRuleApprovals.scss';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { FaExclamation } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';

const ModRuleStatusPublishModal = ({
  setUpdate,
  dcnPublishOpen,
  setDcnPublishOpen,
  dcn,
}) => {
  const { t, i18n } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const location = useLocation();
  let dcnType = '';
  if (location.pathname.includes('dcn')) {
    dcnType = 'dcn';
  } else {
    dcnType = 'cir';
  }

  // dcn modruleRef_status 카운트 값
  const [statusCounts, setStatusCounts] = useState({
    draft: 0, // 검토
    rejected: 0, // 반려
    examined: 0, // 승인대기
    approved: 0, // 승인완료
  });

  //! dcn_modRuleRef_status
  useEffect(() => {
    const tempStatusCounts = {
      draft: 0,
      rejected: 0,
      examined: 0,
      approved: 0,
    };

    dcn.modRuleRef.forEach(item => {
      if (Object.hasOwn(statusCounts, item.status)) {
        // 있는지 없는지 확인ㄱ
        tempStatusCounts[item.status] += 1;
      }
    });
    setStatusCounts(tempStatusCounts);
  }, [dcn]);

  //! 승인 버튼 Func
  const submitNewDcn = async () => {
    if (window.confirm(t('modrule.approve-q'))) {
      if (statusCounts.draft > 0 || statusCounts.examined > 0) {
        toast(t('modrule.confirm-review-approval-draft'));
        return;
      }
      if (statusCounts.rejected >= 0 && statusCounts.approved === 0) {
        toast(t('modrule.no-complete-approval'));
        return;
      }
      // 승인완료가 있을 때 -> 승인생성
      if (statusCounts.approved > 0) {
        try {
          const res = await axiosAuth({
            method: 'POST',
            url: `/api/dcn/apply/all`,
            data: {
              number: dcn.number,
            },
          });
          if (res.status === 200) {
            toast(`DCN${t('modrule.distribution-approval-complete')}`);
            setUpdate(true);
            setDcnPublishOpen(false);
            window.location.reload();
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  return (
    <Modal
      className='modrulestatus__newdcnmodal'
      backdropClassName='double'
      show={dcnPublishOpen}
      size='xl'
      onHide={setDcnPublishOpen}
      backdrop='static'
      keyboard={false}>
      <Modal.Header
        closeButton
        style={{
          backgroundColor: '#FFF3F2',
          height: '5rem',
        }}>
        <Modal.Title
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
          }}>
          <FaExclamation fill='#fc3628' size={30} />
          <span
            style={{
              fontSize: '33px',
              color: '#fc3628',
            }}>{`${dcnType.toUpperCase()} ${t('modrule.distribution')}`}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <article style={{ display: 'flex' }}>
          {/* Leftt__DCN 정보 부분 */}
          <section style={{ width: '50%' }}>
            <div style={{ marginBottom: '2vh' }}>
              <h5
                style={{
                  marginBottom: '1vh',
                }}>{`${dcnType.toUpperCase()} ${t('modrule.info')}`}</h5>
              <span
                style={{
                  padding: '0.5vh',
                  fontSize: '1.1rem',
                  border: '2px solid orange',
                  borderRadius: '5px',
                }}>{`${dcnType.toUpperCase()} ${dcn?.number}`}</span>
            </div>
            <div>
              <section
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSzie: '1rem',
                }}>
                <div style={{ display: 'flex' }}>
                  <h5 style={{ marginRight: '5px' }}>
                    {t('modrule.enactAmend-approve-date')} :{' '}
                  </h5>
                  <p>{dcn?.revisionTime.slice(0, 10)}</p>
                </div>

                <div style={{ height: '20px' }} />

                <div style={{ display: 'flex' }}>
                  <h5 style={{ marginRight: '5px' }}>
                    {t('modrule.complete-approve')} :{' '}
                  </h5>
                  <p style={{ fontSize: '1.1rem' }}>
                    {statusCounts.approved} {t('modrule.cases')}
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <h5 style={{ marginRight: '5px' }}>{t('modrule.tab4')} : </h5>
                  <p style={{ fontSize: '1.1rem' }}>
                    {statusCounts.rejected} {t('modrule.cases')}
                  </p>
                </div>

                <div style={{ marginBottom: '10px' }} />

                <div style={{ display: 'flex' }}>
                  <h5 style={{ marginRight: '5px' }}>
                    {t('modrule.under-review')} :{' '}
                  </h5>
                  <p style={{ fontSize: '1.1rem' }}>
                    {statusCounts.draft} {t('modrule.cases')}
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <h5 style={{ marginRight: '5px' }}>{t('modrule.tab5')} : </h5>
                  <p style={{ fontSize: '1.1rem' }}>
                    {statusCounts.examined} {t('modrule.cases')}
                  </p>
                </div>
              </section>

              {/* 경고 문구 */}
              <div
                style={{
                  width: '60%',
                  border: '2px solid #F09C5C',
                  padding: '0.5vh',
                  fontSize: '1rem',
                  backgroundColor: '#fff',
                  color: '#333',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  borderRadius: '5px',
                  marginTop: '20px',
                }}>
                <FaTriangleExclamation
                  fill='#F09C5C'
                  size={45}
                  style={{
                    marginLeft: '20px',
                    marginRight: '10px',
                  }}
                />

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p
                    style={{
                      marginLeft: '10px',
                      marginTop: '10px',
                    }}>
                    <span
                      style={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        backgroundColor: '#FFF3F2',
                        color: '#20265B',
                        marginLeft: '5px',
                      }}>
                      {t('modrule.under-reviews')}
                    </span>{' '}
                    {t('modrule.is-drafts')}{' '}
                    <span
                      style={{
                        color: '#E71A21',
                        fontSize: '1.4rem',
                        marginRight: '3px',
                      }}>
                      {statusCounts.draft}
                    </span>
                    {t('modrule.cases')}
                  </p>
                  <p
                    style={{
                      marginLeft: '10px',
                      marginTop: '10px',
                    }}>
                    <span
                      style={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        backgroundColor: '#FFF3F2',
                        color: '#20265B',
                        marginLeft: '5px',
                      }}>
                      {t('modrule.under-approve')}
                    </span>{' '}
                    {t('modrule.is-drafts')}{' '}
                    <span
                      style={{
                        color: '#E71A21',
                        fontSize: '1.4rem',
                        marginRight: '3px',
                      }}>
                      {statusCounts.examined}
                    </span>
                    {t('modrule.cases')}
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Right__배포 확인 부분 */}
          <section style={{ width: '40%', borderLeft: '2px solid #DEE2E6' }}>
            {i18n.language === 'ko' ? (
              <>
                <p
                  style={{
                    fontSize: '1.5rem',
                    display: 'inline-block',
                    margin: '0 1rem',
                  }}>
                  DCN을 배포 하시겠습니까?
                </p>

                <p
                  style={{
                    fontSize: '1rem',
                    margin: '0 1rem',
                    marginTop: '1.5rem',
                  }}>
                  <span
                    style={{
                      color: '#E71A21',
                      marginRight: '5px',
                      fontSize: '1.2rem',
                    }}>
                    {' '}
                    승인완료 or 반려
                  </span>
                  만 있을 때 승인이 가능합니다.
                </p>
              </>
            ) : (
              <>
                <p
                  style={{
                    fontSize: '1.5rem',
                    display: 'inline-block',
                    margin: '0 1rem',
                  }}>
                  Do you want to distribute the DCN?
                </p>

                <p
                  style={{
                    fontSize: '1rem',
                    margin: '0 1rem',
                    marginTop: '1.5rem',
                  }}>
                  <span
                    style={{
                      color: '#E71A21',
                      marginRight: '5px',
                      fontSize: '1.2rem',
                    }}>
                    Approved or Rejected
                  </span>{' '}
                  status is required for distribution.
                </p>
              </>
            )}
          </section>
        </article>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setDcnPublishOpen(false)}>
          {t('button.cancel')}
        </Button>
        <Button
          className='modrulestatus__newdcnmodal__btn'
          data-bs-toggle='modal'
          data-bs-target='#staticBackdrop'
          variant='secondary'
          onClick={submitNewDcn}>
          {t('button.approve')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModRuleStatusPublishModal;
