import React from 'react';
import '../../scss/Notice/NoticeMain.scss';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NoticeMain = () => {
  const { t } = useTranslation();
  return (
    <div className='noticeMain mainsection'>
      <h4>{t('notice.title')}</h4>
      <Outlet />
    </div>
  );
};

export default NoticeMain;
