import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../scss/ModRule/EnactRule.scss';
import { useTranslation } from 'react-i18next';
import history from '../../common/hooks/history';
import { ModChapterProvider } from '../../store/ModChapterProvider';
import EnactRuleSidelist from './EnactRule/EnactRuleSidelist';
import EnactRuleRightMain from './EnactRule/EnactRuleRightMain';
import ModRuleDraftHeader from './ModRuleDraftHeader';
import useModRule from '../../common/hooks/useModRule';
import ModRuleSortChapter from './ModRuleSortChapter';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const initialState = {
  fileType: '',
  modType: '',
  content: '',
  uri: '',
  chapterIdx: 0,
  chapterContents: {
    header: '',
    children: [],
  },
};

const EnactRule = () => {
  const navi = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const axiosAuth = useAxiosAuth();
  const { modRule, setModRule } = useModRule();
  const { t } = useTranslation();

  useEffect(() => {
    const handleBeforeUnload = e => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.onbeforeunload = handleBeforeUnload;

    window.onbeforeunload = handleBeforeUnload;
    window.onunload = () => {
      alert(t('modrule.close-browser'));
    };

    return () => {
      window.onbeforeunload = null;
      window.onunload = null;
    };
  }, []);

  useEffect(() => {
    const listenBackEvent = () => {
      if (window.confirm(t('modrule.close-del-data-q'))) {
        navi('/modrule/dcn');
      } else {
        navi(location.pathname);
      }
    };

    const historyEvent = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });
    return historyEvent;
  }, []);

  useEffect(() => {
    if (id) {
      const modRules = async () => {
        try {
          const res = await axiosAuth({
            method: 'GET',
            url: `/api/modrule/${id}`,
          });
          setModRule(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      modRules();
    }
  }, [id]);

  return (
    <ModChapterProvider initialState={initialState}>
      <div className='enactrule'>
        <EnactRuleSidelist />
        <section className='enactrule__main'>
          <ModRuleDraftHeader />
          {modRule?._id && modRule.sortOpen ? (
            <ModRuleSortChapter />
          ) : (
            <EnactRuleRightMain />
          )}
        </section>
      </div>
    </ModChapterProvider>
  );
};

export default EnactRule;
