import { useContext, useDebugValue } from 'react';
import ModRuleContext from '../../store/ModRuleProvider';

const useModRule = () => {
  const { modRule } = useContext(ModRuleContext);
  useDebugValue(modRule, el => (el ? 'ondata' : 'offdata'));
  return useContext(ModRuleContext);
};

export default useModRule;
