import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormGroup,
  ToggleButton,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../scss/ModRule/NewRule.scss';
import Select from 'react-select';
import { BiX } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import blackStyles from '../../common/utils/SelectStyles';
import RuleApproval from './RuleApproval';
import RuleChapContents from './RuleChapContents';
import RuleEditRevStatus from './RuleEditRevStatus';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

window.Buffer = window.Buffer || require('buffer').Buffer;

const RuleEdit = () => {
  // 개정 (Revision)
  const { t, i18n } = useTranslation();
  const navi = useNavigate();
  const editorRef = useRef(null);
  const [payOpen, setPayOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [storageOptions, setStorageOptions] = useState([]);
  const [cateStorOp, setCateStorOp] = useState([]);
  const [listReLoad, setListReLoad] = useState(false);
  const [ruleOptions, setRuleOptions] = useState([]);
  const [lan, setLan] = useState('kor');
  const [category, setCategory] = useState({ label: '', value: '' });
  const [ruleMod, setRuleMod] = useState({ _id: '', refCategoryId: '' });
  const [rule, setRule] = useState({ _id: '', ruleName: '', ruleNumber: '' });
  const [chapter, setChapter] = useState({
    _id: '',
    chapterName: '',
    chapterNumber: '',
  });
  const [chapContent, setChapContent] = useState('');
  const [chapterContents, setChapterContents] = useState({
    header: '',
    children: [],
  });
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const [approval, setApproval] = useState({
    examines: [],
    reference: [],
    approve: [],
  });
  const [revStatus, setRevStatus] = useState([{ chapter: [], summary: [] }]);
  const [isNew, setIsNew] = useState(false);
  const [revModalOpen, setRevModalOpen] = useState(false);
  useEffect(() => {
    const categorySelectInit = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/category/all/kor',
      });
      const optionsCopy = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(optionsCopy);
    };
    categorySelectInit();
  }, []);

  useEffect(() => {
    const categoryLanChange = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/all/${lan}`,
      });
      const optionsCopy = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setCategory({ label: '', value: '' });
      setOptions(optionsCopy);
    };
    categoryLanChange();
  }, [lan]);

  const handleCategoryValue = async e => {
    try {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/${e.value._id}/${auth.id}`,
      });
      const { ruleRef } = response.data;
      const ruleOptionsArr = ruleRef.map(ruleOption => ({
        label: `${ruleOption.ruleNumber} : ${ruleOption.ruleName}`,
        value: ruleOption,
      }));
      setCategory(e);
      setRuleOptions(ruleOptionsArr);
    } catch (error) {
      console.log(error);
      toast(t('modrule.get-ruleInfo-failed'));
    }
  };

  useEffect(() => {
    const StorageListSelect = async () => {
      const { id } = auth;
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/modrule/tempmodruleA/${id}`,
      });
      const optionsCopy = response.data.map(option => ({
        label: `${option.refRuleId.ruleNumber} : ${option.refRuleId.ruleName}`,
        value: option,
      }));
      setStorageOptions(optionsCopy);
    };
    StorageListSelect();
  }, []);

  const handleStorageList = async e => {
    setListReLoad(true);
    const id = e.value._id;
    if (e.value._id) {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/modrule/${id}`,
        });
        const optionsCopy = {
          label: `${res.data[0].categoryNumber} : ${res.data[0].category}`,
          value: res.data[0],
        };
        setCateStorOp(optionsCopy);
        setRule(res.data[1]);
        setRuleMod(res.data[2]);
        const revs = res.data[2].chapterRef.map(el => ({
          chapter: [el.chapterNumber],
          summary: [],
        }));
        setRevStatus(revs);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRuleValue = async e => {
    // chapter select options 업데이트
    const { _id, ruleName, ruleNumber } = e.value;
    setRule({
      ...rule,
      _id,
      ruleName,
      ruleNumber,
    });
    try {
      if (ruleMod._id === '') {
        const response1 = await axiosAuth({
          method: 'POST',
          url: '/api/modrule/rule/new3',
          data: {
            id: _id,
            writer: auth.id,
            ruleModId: '',
          },
        });
        const { docs, modrule } = response1.data;
        setRuleMod(modrule);
        setRule(docs);
      } else {
        const response2 = await axiosAuth({
          method: 'POST',
          url: '/api/modrule/rule/new3',
          data: {
            id: _id,
            writer: auth.id,
            ruleModId: ruleMod._id,
          },
        });
        const { docs, modrule } = response2.data;
        setRuleMod(modrule);
        setRule(docs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeChapter = e => {
    const { value, name } = e.target;
    setChapter({
      ...chapter,
      [name]: value,
    });
  };

  const chapterEditSubmit = async () => {
    if (approval.examines.length === 0 || approval.approve.length === 0) {
      toast(t('modrule.set-approval-line'));
    } else if (revStatus.map(rev => rev.summary.length).includes(0)) {
      toast(t('modrule.write-rev-sum'));
    } else {
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/modrule/newr',
          data: {
            ruleModId: ruleMod._id,
            refCategoryId: category.value._id,
            refOldRuleId: rule._id,
            examines: approval.examines,
            reference: approval.reference,
            approve: approval.approve ? approval.approve : [],
            revstatus: revStatus,
          },
        });
        toast(
          `${response.data.refRuleId.ruleNumber} ${t(
            'modrule.complete-amend-draft'
          )}`
        );
        navi('/modrule');
      } catch (error) {
        console.log(error);
        toast(t('modrule.edit-chap-failed'));
      }
    }
  };

  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  function customUploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(file => {
            data.append('file', file);
            axios
              .post('/api/modrule/imageupload', data)
              .then(res => resolve({ default: res.data.imageUrl }))
              .catch(err => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    const editorinstance = editor;
    editorinstance.plugins.get('FileRepository').createUploadAdapter =
      loader => {
        return customUploadAdapter(loader);
      };
  }

  const editChapterSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/chapter/new',
        data: {
          ruleModId: ruleMod._id,
          oldChapterId: chapter._id,
          chapterNumber: chapter.chapterNumber,
          chapterName: chapter.chapterName,
          content: chapContent,
          lan,
          chapterContents,
        },
      });
      if (res.status === 200) {
        toast(t('modrule.add-chap-text'));
        setRuleMod(res.data);
        const revs = res.data.chapterRef.map(el => ({
          chapter: [el.chapterNumber],
          summary: [],
        }));
        setRevStatus(revs);
        setChapter({
          _id: '',
          chapterName: '',
          chapterNumber: '',
        });
        setChapContent('');
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.add-chap-error'));
    }
  };

  const newChapterSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/chapter/new',
        data: {
          ruleModId: ruleMod._id,
          chapterNumber: chapter.chapterNumber,
          chapterName: chapter.chapterName,
          content: chapContent,
          lan,
          chapterContents,
        },
      });
      if (res.status === 200) {
        toast(t('modrule.add-chap-text'));
        setRuleMod(res.data);
        const revs = res.data.chapterRef.map(el => ({
          chapter: [el.chapterNumber],
          summary: [],
        }));
        setRevStatus(revs);
        setChapter({
          _id: '',
          chapterName: '',
          chapterNumber: `${rule.ruleNumber}-`,
        });
        setChapContent('');
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.add-chap-error'));
    }
  };

  const goBack = async () => {
    if (ruleMod) {
      if (window.confirm(t('modrule.cancel-draft-q'))) {
        await axiosAuth
          .delete(`/api/modrule/${ruleMod._id}`)
          .then(res => {
            toast(res.status || t('modrule.cancel-draft'));
            navi(-1);
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else {
      navi(-1);
    }
  };

  const temporarySave = async () => {
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/modrule/temporary',
        data: {
          ruleModId: ruleMod._id,
          classification: 'amend',
          refCategoryId: category.value._id,
        },
      });
      if (response.status === 200) {
        toast(t('modrule.temp-save-draft-text'));
        navi('/modrule');
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.temp-save-failed'));
    }
  };

  const preventClose = async e => {
    e.returnValue = '';
  };

  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', preventClose);
    })();

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, []);

  const handleTargetChapter = (id, type) => {
    setIsNew(false);
    if (type === 'new') {
      const targetChap = ruleMod.chapterRef.filter(el => el._id === id)[0];
      setChapter(targetChap);
      setChapContent(targetChap.content);
    } else {
      const targetChap = rule.chapterRef.filter(el => el._id === id)[0];
      setChapter(targetChap);
      setChapContent(targetChap.content);
    }
  };
  const handleEditorForNew = () => {
    if (!isNew) {
      setIsNew(true);
      setChapter({
        _id: '',
        chapterName: '',
        chapterNumber: `${rule.ruleNumber}-`,
      });
      setChapContent('');
    } else {
      setChapter({
        _id: '',
        chapterName: '',
        chapterNumber: `${rule.ruleNumber}-`,
      });
      setChapContent('');
    }
  };

  const targetChapterDel = async newChapter => {
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `${newChapter.chapterNumber}를 삭제하시겠습니까?`
          : `Do you want to delete ${newChapter.chapterNumber}?`
      )
    ) {
      try {
        const res = await axiosAuth({
          method: 'post',
          url: '/api/modrule/chapter/del',
          data: {
            chapterModId: newChapter._id,
            ruleModId: ruleMod._id,
          },
        });
        if (res.status === 200) {
          setRuleMod(res.data);
          toast(t('modrule.del-chap'));
          const revs = res.data.chapterRef.map(el => ({
            chapter: [el.chapterNumber],
            summary: [],
          }));
          setRevStatus(revs);
        }
      } catch (error) {
        console.log(error);
        toast(t('modrule.del-chap-error'));
      }
    }
  };
  const paymentSetting = () => {
    setPayOpen(prev => !prev);
  };

  return (
    <div className='draft'>
      <section className='draft__subContent'>
        <div className='draft__subContent__btnBox'>
          <Button variant='secondary' type='submit' onClick={paymentSetting}>
            {t('modrule.payment.payment-line')}
          </Button>
          {payOpen ? (
            <RuleApproval
              user={auth}
              setApproval={setApproval}
              payOpen={payOpen}
              setPayOpen={setPayOpen}
            />
          ) : null}
          <Button variant='secondary' type='submit' onClick={temporarySave}>
            {t('modrule.temporary-storage')}
          </Button>
          <Button variant='secondary' type='submit' onClick={chapterEditSubmit}>
            {t('modrule.Draft-rule')}
          </Button>
          <Button variant='secondary' type='submit' onClick={goBack}>
            {t('modrule.cancel')}
          </Button>
        </div>
      </section>
      <div className='draft__mainBody'>
        <section className='draft__draftinfo'>
          <p className='draft__draftinfo__title'>{t('modrule.rule-create')}</p>
          <div className='draft__draftinfo__top'>
            <div>
              <div width='10%'>{t('modrule.lan')}</div>
              <div width='30%'>
                <ButtonGroup className='lanradiobtn'>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={radio.value}
                      id={`radio-${idx}`}
                      type='radio'
                      variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                      name='radio'
                      value={radio.value}
                      checked={lan === radio.value}
                      onChange={e => setLan(e.currentTarget.value)}>
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
            </div>
            <div className='draft__draftinfo__top__cateSelect'>
              <div width='10%'>{t('modrule.cate')}</div>
              <div width='30%'>
                {listReLoad ? (
                  <FormGroup>
                    <FormControl
                      type='text'
                      defaultValue={cateStorOp.label}
                      className='cateDefaultValueBox'
                      disabled
                    />
                  </FormGroup>
                ) : (
                  <Select
                    styles={blackStyles}
                    placeholder={t('modrule.cate-placeholder')}
                    isSearchable
                    id='select-editcategory'
                    className='cateSelectBox'
                    options={options}
                    onChange={handleCategoryValue}
                  />
                )}
              </div>
            </div>
            <div>
              <div width='10%'>{t('table.writer')}</div>
              <div width='10%'>
                {auth
                  ? `${auth.position.positionName} ${auth.username}`
                  : t('modrule.no-write-permission')}
              </div>
            </div>
          </div>
          <div className='draft__draftinfo__editbottom'>
            <div>
              <div width='10%'>{t('modrule.rule')}</div>
              <div width='30%'>
                {listReLoad ? (
                  <FormGroup>
                    <FormControl
                      type='text'
                      className='ruleDefaultValueBox'
                      value={
                        rule &&
                        `[${rule && rule.ruleNumber}] ${rule && rule.ruleName}`
                      }
                      disabled
                    />
                  </FormGroup>
                ) : (
                  <Select
                    styles={blackStyles}
                    placeholder={t('modrule.rule-placeholder')}
                    isSearchable
                    id='select-editrule'
                    className='ruleSelectBox'
                    options={ruleOptions}
                    onChange={handleRuleValue}
                  />
                )}
              </div>
            </div>
            <div>
              <div width='10%'>{t('modrule.check-rule')}</div>
              <div width='30%'>
                {rule.ruleNumber && rule.ruleName
                  ? `[${rule && rule.ruleNumber}]: ${rule && rule.ruleName}`
                  : null}
              </div>
            </div>
            <div>
              <div width='10%'>{t('modrule.temporary')}</div>
              <div width='30%'>
                {storageOptions.length !== 0 ? (
                  <Select
                    styles={blackStyles}
                    placeholder={t('modrule.temporary-placeholder')}
                    isSearchable
                    id='select-createcategory'
                    className='temporaryList'
                    options={storageOptions}
                    onChange={handleStorageList}
                  />
                ) : (
                  <span style={{ color: '#ccc' }}>
                    {t('modrule.no-temporary-placeholder')}
                  </span>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className='draft__contents'>
          <div className='draft__contents__subContents'>
            <div className='draft__contents__chapterlistWrap'>
              <div className='draft__contents__chapterlist'>
                <p className='draft__contents__chapterlist__title'>
                  {t('modrule.registered')}
                </p>
                <div className='draft__contents__chapterlist__old'>
                  {rule &&
                    rule.chapterRef &&
                    rule.chapterRef.map(oldChapter => (
                      <Button
                        className={`chapterlist__old__item ${
                          chapter._id === oldChapter._id ? 'active' : ''
                        }`}
                        key={oldChapter._id}
                        onClick={() =>
                          handleTargetChapter(oldChapter._id, 'old')
                        }>
                        {oldChapter.chapterNumber}
                      </Button>
                    ))}
                </div>
              </div>
              <div className='draft__contents__chapterlist'>
                <p className='draft__contents__chapterlist__title'>
                  {t('modrule.add-chapList')}
                </p>
                <div className='draft__contents__chapterlist__new'>
                  <div className='draft__contents__chapterlist__new__addItem'>
                    <Button
                      type='submit'
                      id='btn-chaptersubmit'
                      onClick={handleEditorForNew}>
                      {t('modrule.newChap-add')}
                    </Button>
                  </div>
                  <div className='draft__contents__chapterlist__new__list'>
                    {ruleMod &&
                      ruleMod.chapterRef &&
                      ruleMod.chapterRef.map(newChapter => (
                        <Button
                          variant='secondary'
                          className={` ${
                            chapter._id === newChapter._id ? 'active' : ''
                          }`}
                          key={newChapter._id}
                          onClick={() =>
                            handleTargetChapter(newChapter._id, 'new')
                          }>
                          <span>{newChapter.chapterNumber}</span>
                          <BiX
                            size={24}
                            onClick={() => targetChapterDel(newChapter)}
                          />
                        </Button>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className='draft__contents__chapterinfoWrap'>
              <div className='draft__contents__chapterinfo'>
                <div className='draft__contents__chapterinfo__summary'>
                  <p className='draft__contents__chapterinfo__title2'>
                    {t('table.rev-sum')}
                  </p>
                  <div className='draft__contents__chapterinfo__body'>
                    <Button onClick={() => setRevModalOpen(!revModalOpen)}>
                      {t('modrule.rev-sum')}
                    </Button>
                    {revStatus &&
                      revStatus.map(rev => (
                        <div
                          key={rev.chapter.join(',')}
                          className='revStatusBox'>
                          <span>{rev.chapter.join(',')}</span>
                          <span> : </span>
                          <span>{rev.summary.join(',')}</span>
                        </div>
                      ))}
                    {revModalOpen ? (
                      <RuleEditRevStatus
                        ruleModId={ruleMod._id}
                        revStatus={revStatus}
                        chapoptions={ruleMod.chapterRef}
                        setRevStatus={setRevStatus}
                        setRevModalOpen={setRevModalOpen}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='draft__contents__chapterinfo'>
                <div className='draft__contents__chapterinfo__chaptercontents'>
                  <p className='draft__contents__chapterinfo__title'>
                    {t('modrule.List')}
                  </p>
                  <div className='draft__contents__chapterinfo__body'>
                    <RuleChapContents
                      chapContent={chapContent}
                      chapterContents={chapterContents}
                      setChapterContents={setChapterContents}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='draft__contents__main'>
              <div className='draft__contents__main__header'>
                <div className='mainConBtn'>
                  <span>
                    {isNew ? (
                      <Button
                        type='submit'
                        id='btn-chaptersubmit'
                        onClick={newChapterSubmit}>
                        <span className='chapBtnSvg'>
                          <MdOutlineKeyboardDoubleArrowLeft />
                          <MdOutlineKeyboardDoubleArrowLeft />
                        </span>
                        <span className='chapAdd'>{t('modrule.add-chap')}</span>
                      </Button>
                    ) : (
                      <Button
                        type='submit'
                        id='btn-chaptersubmit'
                        onClick={editChapterSubmit}>
                        <span className='chapBtnSvg'>
                          <MdOutlineKeyboardDoubleArrowLeft />
                          <MdOutlineKeyboardDoubleArrowLeft />
                        </span>
                        <span className='chapAdd'>
                          {t('modrule.edit-chap')}
                        </span>
                      </Button>
                    )}
                  </span>
                  <span>
                    <label
                      className='draft__label'
                      htmlFor='select-chapternumber'>
                      {/* 챕터 No. */}
                      <input
                        type='text'
                        className='form-control'
                        id='input-chapternumber'
                        name='chapterNumber'
                        placeholder={t('modrule.chapNum-placeholder')}
                        value={chapter.chapterNumber}
                        onChange={onChangeChapter}
                        required
                      />
                    </label>
                  </span>
                  <span>
                    <label className='draft__label' htmlFor='input-chaptername'>
                      {/* 챕터 제목 */}
                      <input
                        type='text'
                        className='form-control'
                        id='input-chaptername'
                        name='chapterName'
                        placeholder={t('modrule.chapTitle-placeholder')}
                        value={chapter.chapterName}
                        onChange={onChangeChapter}
                        required
                      />
                    </label>
                  </span>
                </div>
              </div>
              <div className='draft__main__editor'>
                <CKEditor
                  ref={editorRef}
                  editor={Editor}
                  data={chapContent}
                  config={{
                    heading: {
                      options: [
                        {
                          model: 'paragraph',
                          title: i18n.language === 'ko' ? '본문' : 'CONTENT',
                          class: 'ck-heading_paragraph',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: i18n.language === 'ko' ? '제목 1' : 'TITLE 1',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: i18n.language === 'ko' ? '제목 2' : 'TITLE 2',
                          class: 'ck-heading_heading3',
                        },
                        {
                          model: 'heading4',
                          view: 'h4',
                          title: i18n.language === 'ko' ? '제목 3' : 'TITLE 3',
                          class: 'ck-heading_heading4',
                        },
                      ],
                    },
                    extraPlugins: [uploadPlugin],
                    autosave: {
                      save(editor) {
                        if (editor) {
                          const data = editor.getData();
                          setChapContent(data);
                        }
                      },
                      waitingTime: 3000,
                    },
                  }}
                  onReady={editor => {
                    editor.ui
                      .getEditableElement()
                      .parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );
                  }}
                  onError={(error, editor) => {
                    if (editor.willEditorRestart) {
                      editor.ui.view.toolbar.element.remove();
                    }
                    if (error) {
                      console.log(error);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RuleEdit;
