import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  BsCheckCircle,
  BsXCircle,
  BsDownload,
  BsPrinter,
} from 'react-icons/bs';
import Select from 'react-select';
import SearchBar from '../SearchBar';
import AuthUserList from './AuthUserList';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

// 권한관리 -> 기본권한
const Auths = ({ auths, setUpdate }) => {
  const [edit, setEdit] = useState(false);
  const [targetUser, setTargetUser] = useState({});
  const myStateRef = React.useRef(auths); // targetUser 초기값 저장
  const [openDeporPosi, setOpenDeporPosi] = useState('');
  const [grade, setGrade] = useState({});
  const [gradeList, setGradeList] = useState([]);
  const [manualList, setManualList] = useState([]);
  const [unManualList, setUnManualList] = useState([]);
  const [depList, setDepList] = useState([]);
  const [depId, setDepId] = useState('');
  const [posi, setPosi] = useState({});
  const [posiList, setPosiList] = useState([]);
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (auths) {
      setTargetUser(auths);
    }
  }, [auths]);

  useEffect(() => {
    if (
      Object.entries(myStateRef.current).toString() !==
      Object.entries(targetUser).toString()
    ) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [targetUser]);

  const currentChecked = e => {
    const { name, checked } = e.target;
    setTargetUser({ ...targetUser, [name]: checked });
  };
  const editAuths = async () => {
    try {
      const keys = Object.keys(targetUser);
      const rolesData = keys.filter(el => targetUser[el] === true);
      const response = await axiosAuth({
        method: 'post',
        url: '/api/management/edit/userrole',
        data: {
          email: targetUser.email,
          roles: rolesData,
        },
      });
      toast(response.data.message);
      setEdit(false);
      setUpdate(prev => !prev);
    } catch (error) {
      toast(error.response.data);
    }
  };

  const handleOpenDeporPosi = async type => {
    setOpenDeporPosi(type);
    if (type === 'posi') {
      // 직위 변경
      await axiosAuth
        .get('/api/position/all')
        .then(res => {
          const sortPosi = res.data.sort((a, b) => a.sortNum - b.sortNum);
          const posiscopy = sortPosi.map(po => ({
            label: po.positionName,
            value: po,
          }));
          setPosiList(posiscopy);
        })
        .catch(err => console.log(err));
    } else if (type === 'manual') {
      // 팀매뉴얼 변경
      try {
        const [manuals, unManuals] = await Promise.all([
          axiosAuth.post('/api/manualPer/manuals', {
            email: auths.email,
            company: auths.company,
          }),
          axiosAuth.post('/api/manualPer/canaddmanuals', {
            email: auths.email,
            company: auths.company,
          }),
        ]);
        const manualscopy = manuals.data.map(el => ({
          label: el.name,
          value: el,
          _id: el._id,
        }));
        const unManualscopy = unManuals.data.map(el => ({
          label: el.name,
          value: el,
          _id: el._id,
        }));
        setManualList(manualscopy);
        setUnManualList(unManualscopy);
      } catch (err) {
        console.log(err);
      }
    } else if (type === 'grade') {
      // 사용자등급 변경
      await axiosAuth
        .get(`/api/grade/all/${auth.company}`)
        .then(res => {
          const gradescopy = res.data.map(gr => ({
            label: gr.gradeName,
            value: gr,
          }));
          setGradeList(gradescopy);
        })
        .catch(err => console.log(err));
    } else {
      // 부서변경
      await axiosAuth
        .get(`/api/org/all/${auth.company}`)
        .then(res => {
          const deplistCopy = res.data.map(el => ({
            label: el.name,
            value: el._id,
          }));
          setDepList(deplistCopy);
        })
        .catch(err => console.log(err));
    }
  };

  const currentDep = e => {
    setDepId(e.value);
  };

  const changeDepSubmit = async () => {
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/management/edit/userorg',
        data: {
          email: targetUser.email,
          org: depId,
        },
      });
      toast(response.data.message);
      setEdit(false);
      setUpdate(prev => !prev);
    } catch (error) {
      toast(error.response.data);
    }
  };

  const changeManualRemove = async value => {
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `${value.name} 매뉴얼 접근권한을 삭제하시겠습니까?`
          : `Do you want to delete access permissions for the manual ${value.name}?`
      )
    ) {
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/manualPer/del',
          data: {
            email: auths.email,
            orgId: value._id,
          },
        });
        toast(response.data.message);
        setUpdate(prev => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const changeManualAdd = async value => {
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `${value.name} 매뉴얼 접근권한을 추가하시겠습니까?`
          : `Do you want to add access permissions for the manual ${value.name}?`
      )
    ) {
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/manualPer/add',
          data: {
            email: auths.email,
            orgId: value._id,
          },
        });
        toast(response.data.message);
        setUpdate(prev => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const currentPosi = e => {
    setPosi(e.value);
  };

  const changePosiSubmit = async () => {
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/management/edit/userposition',
        data: {
          email: targetUser.email,
          positionName: posi.positionName,
        },
      });
      toast(response.data.message);
      setEdit(false);
      setUpdate(prev => !prev);
    } catch (error) {
      toast(error.response.data);
    }
  };

  const changePWSubmit = async user => {
    const { username, email } = user;
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `${username}님의 비밀번호를 초기화 하시겠습니까?`
          : `Do you want to reset ${username}'s password?`
      )
    ) {
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/management/edit/password',
          data: {
            email,
          },
        });
        toast(response.data);
        setEdit(false);
        setUpdate(prev => !prev);
      } catch (error) {
        toast(error.response.data);
      }
    }
  };

  const currentGrade = e => {
    setGrade(e.value);
  };

  const changeGradeSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/management/edit/usergrade`,
        data: {
          grade_id: grade._id,
          user_id: targetUser._id,
        },
      });
      toast(res.data.message || t('managerauth.user-grade-change'));
      setEdit(false);
      setUpdate(prev => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  if (!targetUser._id) return null;

  return (
    <>
      <div className='userauth__table__authlist__title'>
        <span>{t('managerauth.basicroles')}</span>
      </div>
      <div className='auths__item authsChangeBtn'>
        <span>
          {t('managerauth.grade')}{' '}
          <Button
            variant='secondary'
            disabled={!targetUser?.email}
            onClick={() => handleOpenDeporPosi('grade')}>
            {t('button.edit')}
          </Button>
        </span>
        <span>{auths.grade && auths.grade.gradeName}</span>
      </div>
      {openDeporPosi === 'grade' ? (
        <div className='depSection'>
          <div className='depSection__header'>
            {t('managerauth.editusergrade.title')}
          </div>
          <div className='depSection__body'>
            <span>
              {auths.username}
              {t('managerauth.editusergrade.gradeis')}{' '}
              {auths.grade && auths.grade.gradeName}
              {t('managerauth.editusergrade.lastexpression')}
            </span>
            <hr />
            <Select
              styles={blackStyles}
              placeholder={
                <div>{t('managerauth.editusergrade.pleaseselect')}</div>
              }
              isSearchable
              options={gradeList}
              onChange={currentGrade}
            />
          </div>
          <div className='depSection__bottom'>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => handleOpenDeporPosi('')}>
              {t('button.cancel')}
            </Button>
            <Button
              type='submit'
              variant='secondary'
              onClick={changeGradeSubmit}>
              {t('button.edit')}
            </Button>
          </div>
        </div>
      ) : null}
      <div className='auths__item authsChangeBtn'>
        <span>
          {t('managerauth.org')}{' '}
          <Button
            variant='secondary'
            disabled={!targetUser?.email}
            onClick={() => handleOpenDeporPosi('dep')}>
            {t('button.edit')}
          </Button>
        </span>
        <span>{auths.org && auths.org.name}</span>
      </div>
      {openDeporPosi === 'dep' ? (
        <div className='depSection'>
          <div className='depSection__header'>
            {t('managerauth.edituserorg.title')}
          </div>
          <div className='depSection__body'>
            <span>
              {auths.username}
              {t('managerauth.edituserorg.gradeis')}{' '}
              {auths.org && auths.org.name}
              {t('managerauth.edituserorg.lastexpression')}
            </span>
            <hr />
            <Select
              styles={blackStyles}
              placeholder={
                <div>{t('managerauth.edituserorg.pleaseselect')}</div>
              }
              isSearchable
              options={depList}
              onChange={currentDep}
            />
          </div>
          <div className='depSection__bottom'>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => handleOpenDeporPosi('')}>
              {t('button.cancel')}
            </Button>
            <Button type='submit' variant='secondary' onClick={changeDepSubmit}>
              {t('button.edit')}
            </Button>
          </div>
        </div>
      ) : null}
      <div className='auths__item authsChangeBtn'>
        <span>
          {t('managerauth.manual')}{' '}
          <Button
            variant='secondary'
            disabled={!targetUser?.email}
            onClick={() => handleOpenDeporPosi('manual')}>
            {t('button.edit')}
          </Button>
        </span>
        <span>
          {auths.manualpers &&
          auths.manualpers.org.filter(org => org.name !== auths.org.name)
            .length >= 1 ? (
            <span>
              {auths.manualpers &&
                auths.manualpers.org
                  .filter(org => org.name === auths.org.name)
                  .map(org => org.name)}
              {'  '}외
              {auths.manualpers &&
                auths.manualpers.org.filter(org => org.name !== auths.org.name)
                  .length}
            </span>
          ) : (
            <span>
              {auths.manualpers &&
                auths.manualpers.org
                  .filter(org => org.name === auths.org.name)
                  .map(org => org.name)}
            </span>
          )}
        </span>
      </div>
      {openDeporPosi === 'manual' ? (
        <div className='manualSection'>
          <div className='manualSection__header'>
            {t('managerauth.editusermanualauth.title')}
          </div>
          <div className='manualSection__body'>
            <div className='manualListItem'>
              <span className='manualListItem__title'>
                {t('managerauth.editusermanualauth.accessible')}
              </span>
              <div className='manualListItem__body'>
                {manualList &&
                  manualList.map(el => (
                    <div key={el._id}>
                      <span>{el.label}</span>
                      <MdRemoveCircleOutline
                        size={25}
                        className='removeSvg'
                        onClick={() => changeManualRemove(el.value)}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className='manualListItem'>
              <span className='manualListItem__title'>
                {t('managerauth.editusermanualauth.addable')}
              </span>
              <div className='manualListItem__body'>
                {unManualList &&
                  unManualList.map(el => (
                    <div key={el._id}>
                      <span>{el.label}</span>
                      <MdAddCircleOutline
                        size={25}
                        className='addSvg'
                        onClick={() => changeManualAdd(el.value)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className='manualSection__bottom'>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => handleOpenDeporPosi('')}>
              {t('button.cancel')}
            </Button>
            {/* <Button
              type='submit'
              variant='secondary'
              onClick={changeManualSubmit}>
              적용
            </Button> */}
          </div>
        </div>
      ) : null}
      <div className='auths__item authsChangeBtn'>
        <span>
          {t('managerauth.position')}{' '}
          <Button
            variant='secondary'
            disabled={!targetUser?.email}
            onClick={() => handleOpenDeporPosi('posi')}>
            {t('button.edit')}
          </Button>
        </span>
        <span>{auths.position && auths.position.positionName}</span>
      </div>
      {openDeporPosi === 'posi' ? (
        <div className='PosiSection'>
          <div className='PosiSection__header'>
            {t('managerauth.edituserposition.title')}
          </div>
          <div className='PosiSection__body'>
            <span>
              {auths.username}
              {t('managerauth.edituserposition.gradeis')}{' '}
              {auths.position && auths.position.positionName}
              {t('managerauth.edituserposition.lastexpression')}
            </span>
            <hr />
            <Select
              styles={blackStyles}
              placeholder={
                <div>{t('managerauth.edituserposition.pleaseselect')}</div>
              }
              isSearchable
              options={posiList}
              onChange={currentPosi}
            />
          </div>
          <div className='PosiSection__bottom'>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => handleOpenDeporPosi('')}>
              {t('button.cancel')}
            </Button>
            <Button
              type='submit'
              variant='secondary'
              onClick={changePosiSubmit}>
              {t('button.edit')}
            </Button>
          </div>
        </div>
      ) : null}
      <div className='auths__item border__bottom authsChangeBtn'>
        <span>
          {t('managerauth.pw')}{' '}
          <Button
            variant='secondary'
            disabled={!targetUser?.email}
            onClick={() => handleOpenDeporPosi('pw')}>
            {t('button.reset')}
          </Button>
        </span>
      </div>
      {openDeporPosi === 'pw' ? (
        <div className='PWSection'>
          <div className='PWSection__header'>
            {t('managerauth.edituserpassword.title')}
          </div>
          <div className='PWSection__body'>
            <span>{t('managerauth.edituserpassword.text1')}</span>{' '}
            {auths.username}
            <span>{t('managerauth.edituserpassword.text2')}</span>
          </div>
          <div className='PWSection__bottom'>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => handleOpenDeporPosi('')}>
              {t('button.cancel')}
            </Button>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => changePWSubmit(auths)}>
              {t('button.reset')}
            </Button>
          </div>
        </div>
      ) : null}
      <div className='auths__item'>
        <span>{t('managerauth.userlog')}</span>
        <input
          type='checkbox'
          checked={targetUser.userlog}
          name='userlog'
          value='userlog'
          onChange={currentChecked}
        />
      </div>
      <div className='auths__item'>
        <span>{t('managerauth.postnotice')}</span>
        <input
          type='checkbox'
          checked={targetUser.notice}
          name='notice'
          value='notice'
          onChange={currentChecked}
        />
      </div>
      <div className='auths__item'>
        <span>{t('managerauth.draft')}</span>
        <input
          type='checkbox'
          checked={targetUser.rulemanager}
          name='rulemanager'
          value='rulemanager'
          onChange={currentChecked}
        />
      </div>
      <div className='auths__item'>
        <span>{t('managerauth.reference')}</span>
        <input
          type='checkbox'
          checked={targetUser.examine}
          name='examine'
          value='examine'
          onChange={currentChecked}
        />
      </div>
      <div className='auths__item'>
        <span>{t('managerauth.approve')}</span>
        <input
          type='checkbox'
          checked={targetUser.approve}
          name='approve'
          value='approve'
          onChange={currentChecked}
        />
      </div>
      <div className='auths__item'>
        <span>{t('managerauth.managemanual')}</span>
        <input
          type='checkbox'
          checked={targetUser.teamManual}
          name='teamManual'
          value='teamManual'
          onChange={currentChecked}
        />
      </div>
      <div className='auths__submit'>
        {edit ? <Button onClick={editAuths}>{t('button.apply')}</Button> : null}
      </div>
    </>
  );
};

// 권한관리 -> 열람가능리스트
const ViewAuths = ({ permissions, userEmail, setUpdate }) => {
  const axiosAuth = useAxiosAuth();
  const { t, i18n } = useTranslation();
  const handleViewDelete = async id => {
    const data = {
      email: userEmail,
      viewId: id,
    };
    await axiosAuth
      .post('/api/management/userviewdelete', data)
      .then(res => {
        toast(res.data);
        setUpdate(prev => !prev);
      })
      .catch(err => console.log(err));
  };

  const handleViewPrint = async id => {
    const data = {
      email: userEmail,
      viewId: id,
    };
    await axiosAuth
      .post('/api/management/userviewprint', data)
      .then(res => {
        toast(res.data);
        setUpdate(prev => !prev);
      })
      .catch(err => console.log(err));
  };

  const handleViewDown = async id => {
    const data = {
      email: userEmail,
      viewId: id,
    };
    await axiosAuth
      .post('/api/management/userviewdown', data)
      .then(res => {
        toast(res.data);
        setUpdate(prev => !prev);
      })
      .catch(err => console.log(err));
  };

  const handleAllPrint = async type => {
    const action = type === 'add' ? t('managerauth.add') : t('managerauth.del');
    const url = type === 'add' ? '/useraddprintall' : '/userdelprintall';
    const data = {
      email: userEmail,
    };
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `구성원이 가지고있는 모든 규정 인쇄권한을 ${action}하시겠습니까?`
          : `Do you want to ${action} all printing permissions for regulations held by the member?`
      )
    ) {
      await axiosAuth
        .post(`/api/management${url}`, data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => console.log(err));
    }
  };

  const handleAllDown = async type => {
    const action = type === 'add' ? t('managerauth.add') : t('managerauth.del');
    const url = type === 'add' ? '/useradddownall' : '/userdeldownall';
    const data = {
      email: userEmail,
    };
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `구성원이 가지고있는 모든 규정 다운로드 권한을 ${action}하시겠습니까?`
          : `Do you want to ${action} all download permissions for regulations held by the member?`
      )
    ) {
      await axiosAuth
        .post(`/api/management${url}`, data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => console.log(err));
    }
  };

  return (
    <>
      <div className='userauth__table__viewlist__title'>
        <div>
          <span>{t('managerauth.accessible')}</span>
        </div>
        <div>
          <div>
            <BsPrinter />
          </div>
          <div>
            <BsCheckCircle onClick={() => handleAllPrint('add')} />
            <BsXCircle onClick={() => handleAllPrint('del')} />
          </div>
        </div>
        <div>
          <div>
            <BsDownload />
          </div>
          <div>
            <BsCheckCircle onClick={() => handleAllDown('add')} />
            <BsXCircle onClick={() => handleAllDown('del')} />
          </div>
        </div>
      </div>
      <div className='userauth__table__viewlist__body'>
        {permissions &&
          permissions.map(view => (
            <div className='views__item' key={view._id}>
              <div>
                <MdRemoveCircleOutline
                  className='views__item__delbtn'
                  size={25}
                  onClick={() => handleViewDelete(view._id)}
                />
                <span>{`[${view.categoryNumber}]:${view.category}`}</span>
              </div>
              <div>
                <input
                  className='views__item__switchbtn'
                  type='checkbox'
                  checked={view.print}
                  onClick={() => handleViewPrint(view._id)}
                  readOnly
                />
                <input
                  className='views__item__switchbtn'
                  type='checkbox'
                  checked={view.down}
                  onClick={() => handleViewDown(view._id)}
                  readOnly
                />
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

// 권한관리 -> 접근 불가 리스트
const Categories = ({ categories, userEmail, setUpdate }) => {
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const handleViewsAdd = async view => {
    const data = {
      email: userEmail,
      categoryNumber: view.categoryNumber,
      category: view.category,
      sortNum: view.sortNum,
    };
    await axiosAuth
      .post('/api/management/userviewadd', data)
      .then(res => {
        toast(res.data);
        setUpdate(prev => !prev);
      })
      .catch(err => console.log(err));
  };
  return (
    <>
      <div className='userauth__table__catelist__title'>
        <span>{t('managerauth.access-deny')}</span>
      </div>
      <div className='userauth__table__catelist__body'>
        {categories &&
          categories.map(view => (
            <div className='cates__item' key={view._id}>
              <div>
                <MdAddCircleOutline
                  className='cates__item__addbtn'
                  size={25}
                  onClick={() => handleViewsAdd(view)}
                />
              </div>
              <div>
                <span>{`[${view.categoryNumber}]:${view.category}`}</span>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

const AuthbyUser = () => {
  const [userEmail, setUserEmail] = useState('');
  const [auths, setAuths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [sidenav, setSidenav] = useState([]);
  const [views, setViews] = useState([]);
  const [update, setUpdate] = useState(false);
  const [delUpdate, setDelUpdate] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const navi = useNavigate();
  const userlistRef = useRef({});
  const axiosAuth = useAxiosAuth();
  const [counting, setCounting] = useState(sidenav.length);
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/management/user',
          data: {
            email: userEmail,
          },
        });
        const {
          _id,
          username,
          email,
          position,
          org,
          manualpers,
          roles,
          grade,
          company,
        } = res.data;
        const authsCopy = {
          _id,
          username,
          email,
          position,
          org,
          manualpers,
          company,
          grade,
          admin: roles.map(el => el.authority).includes('admin'),
          userlog: roles.map(el => el.authority).includes('userlog'),
          notice: roles.map(el => el.authority).includes('notice'),
          rulemanager: roles.map(el => el.authority).includes('rulemanager'),
          examine: roles.map(el => el.authority).includes('examine'),
          approve: roles.map(el => el.authority).includes('approve'),
          teamManual: roles.map(el => el.authority).includes('teamManual'),
        };
        setAuths(authsCopy);
        const res2 = await axiosAuth({
          method: 'POST',
          url: '/api/management/userpers',
          data: {
            email: userEmail,
          },
        });
        setViews(res2.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    if (userEmail !== '') {
      fetchUser();
    }
    return () => {
      controller.abort();
    };
  }, [userEmail, update]);

  useEffect(() => {
    // 화면 초기화
    const controller = new AbortController();
    const fetchSideNav = async () => {
      setIsSpinner(true);
      try {
        const response = await axiosAuth({
          method: 'GET',
          url: '/api/management/users',
        });
        const validateUsers = response.data.map(user => ({
          id: user._id,
          username: user.username,
          position: user.position.positionName,
          department: user?.org?.name || 'none',
          roles: user.roles,
          email: user.email,
          permissions: user.permissions.view || [],
        }));
        setSidenav(validateUsers);
        setIsSpinner(false);
        setCounting(validateUsers.length);
      } catch (err) {
        console.log(err);
        if (err.response?.status === 401) {
          navi('/unauthorized');
        }
      }
    };
    fetchSideNav();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    // 화면 초기화
    const controller = new AbortController();
    const fetchSideNav = async () => {
      setIsSpinner(true);
      setIsLoading(true);
      try {
        const response = await axiosAuth({
          method: 'GET',
          url: '/api/management/users',
        });
        const validateUsers = response.data.map(user => ({
          id: user._id,
          username: user.username,
          position: user.position.positionName,
          department: user.org.name,
          roles: user.roles,
          email: user.email,
          permissions: user.permissions.view || [],
        }));
        setSidenav(validateUsers);
        setUserEmail('');
        setViews([]);
        setAuths([]);

        setCounting(validateUsers.length);
      } catch (err) {
        if (err.response?.status === 401) {
          navi('/unauthorized');
        }
      } finally {
        setIsLoading(false);
        setIsSpinner(false);
      }
    };
    fetchSideNav();
    return () => {
      controller.abort();
    };
  }, [delUpdate]);

  const goJoin = () => {
    navi('../joinup');
  };
  const goGroupJoin = () => {
    navi('../groupjoin');
  };
  const goGroupEdit = () => {
    navi('../groupedit');
  };
  // SearcBar(검색바) 함수 start ---
  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  const searchText = () => {
    userlistRef.current.contentFilter();
  };

  const handleKeyDown = e => {
    const key = e.code;
    switch (key) {
      case 'Enter':
        searchText();
        break;
      default:
    }
  };
  //! SearcBar(검색바) 함수 end ---

  return (
    <div className='userauth__section'>
      <section className='userauth__topHeader'>
        <div className='userauth__topHeader__leftBox'>
          <div className='groupbar'>
            <SearchBar
              value={searchStr}
              onChange={handlesearchStr}
              searchText={searchText}
              handleKeyDown={handleKeyDown}
            />
          </div>
          <div className='rulenum'>
            {t('managerauth.user')} {`: ${userEmail}`}
          </div>
        </div>
        <div className='userauth__topHeader__rightBox'>
          <Button
            // variant='secondary'
            onClick={goJoin}>
            {t('managerauth.registerone')}
          </Button>
          <Button
            // variant='secondary'
            onClick={goGroupJoin}>
            {t('managerauth.registermany')}
          </Button>
          <Button
            // variant='secondary'
            onClick={goGroupEdit}>
            {t('managerauth.edituserinfo')}
          </Button>
        </div>
      </section>
      <section className='userauth__table'>
        <div className='userauth__table__list'>
          <div className='ManagerAuthList'>
            <div className='ManagerAuthList__rulelistTitle'>
              <span>
                {t('managerauth.user')}
                {`: ${counting}`}
              </span>
            </div>
            <div className='Authcollapse'>
              {isSpinner ? (
                <Spinner animation='border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </Spinner>
              ) : (
                <AuthUserList
                  ref={userlistRef}
                  setUserEmail={setUserEmail}
                  userEmail={userEmail}
                  sidenav={sidenav}
                  setCounting={setCounting}
                  searchStr={searchStr}
                  setDelUpdate={setDelUpdate}
                />
              )}
            </div>
          </div>
        </div>
        {isLoading ? (
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        ) : (
          <>
            <div className='userauth__table__authlist'>
              {!userEmail && (
                <div className='userauth__table__authlist__title'>
                  <span>{t('managerauth.basicroles')}</span>
                </div>
              )}
              <Auths
                auths={auths}
                setUpdate={setUpdate}
                userEmail={userEmail}
              />
            </div>
            <div className='userauth__table__viewlist'>
              <ViewAuths
                permissions={views.permissions}
                userEmail={userEmail}
                setUpdate={setUpdate}
              />
            </div>
            <div className='userauth__table__catelist'>
              <Categories
                categories={views.categories}
                userEmail={userEmail}
                setUpdate={setUpdate}
              />
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default AuthbyUser;
