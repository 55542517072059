import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import '../../../scss/ModRule/ModRuleApprovals.scss';
// import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import { useTranslation } from 'react-i18next';
import useModRule from '../../../common/hooks/useModRule';
import { formatDate } from '../../../common/utils/tzUtiles';

const ModRuleViewApproval = ({ viewOpen, setViewOpen }) => {
  const { modRule } = useModRule();
  // const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  return (
    <Modal
      dialogClassName='modRuleOne__confirmModal'
      centered
      show={viewOpen}
      onHide={setViewOpen}
      backdrop='static'>
      <Modal.Header closeButton>
        <Modal.Title>{t('modrule.approval-line')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='modRuleOne__line'>
          <div className='modRuleOne__line__itemBox'>
            {/* 결제자 설정 */}
            <div className='drafterBox modRuleBox'>
              <div className='drafterBox__thead modRuleBox__thead'>
                <span>{t('modrule.drafter')}</span>
              </div>
              <div className='drafterBox__tbody modRuleBox__tbody'>
                {modRule._id && (
                  <ul>
                    <li>{modRule.writer.positionName}</li>
                    <li>
                      {modRule.writer.signatureImg !== '' ? (
                        <img
                          src={
                            modRule.writer.signatureImg ||
                            `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                          }
                          className='signatureimg'
                          alt='signatureImg'
                        />
                      ) : (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                          className='dd'
                          alt='signatureImg'
                        />
                      )}
                    </li>
                    <li>
                      {modRule.createdAt
                        ? modRule.createdAt.slice(0, 10)
                        : null}
                    </li>
                    <li>{modRule.writer.username}</li>
                  </ul>
                )}
              </div>
            </div>
            <div className='modRuleOne__line__itemBox__subSection'>
              {modRule._id &&
                modRule.examines.map(el => (
                  <div key={el._id} className='reviewerBox modRuleBox'>
                    <div className='reviewerBox__thead  modRuleBox__thead'>
                      <span>{t('modrule.reviewer')}</span>
                    </div>
                    <div className='reviewerBox__tbody  modRuleBox__tbody'>
                      <ul>
                        <li>{el.positionName}</li>
                        <li>
                          {el.checked === 0 && (
                            <span style={{ color: '#bbb' }}>
                              {t('modrule.in-progress')}
                            </span>
                          )}
                          {el.checked === 1 && (
                            <img
                              src={
                                el.signatureImg ||
                                `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                              }
                              className='signatureimg'
                              alt='signatureImg'
                            />
                          )}
                          {el.checked === 2 && (
                            <span style={{ color: '#bbb' }}>
                              {t('modrule.proceed-rejection')}
                            </span>
                          )}
                        </li>
                        <li>{el.checkedAt && formatDate(el.checkedAt)}</li>
                        <li>{el.username}</li>
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
            <div className='modRuleOne__line__itemBox__subSection'>
              {modRule._id &&
                modRule.approve.map(el => (
                  <div key={el._id} className='paymentBox modRuleBox'>
                    <div className='paymentBox__thead  modRuleBox__thead'>
                      <span>{t('modrule.approve')}</span>
                    </div>
                    <div className='paymentBox__tbody  modRuleBox__tbody'>
                      <ul>
                        <li>{el.positionName}</li>
                        <li>
                          {el.checked === 0 && (
                            <span style={{ color: '#bbb' }}>
                              {t('modrule.in-progress')}
                            </span>
                          )}
                          {el.checked === 1 && (
                            <img
                              src={
                                el.signatureImg ||
                                `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                              }
                              className='signatureimg'
                              alt='signatureImg'
                            />
                          )}
                          {el.checked === 2 && (
                            <span style={{ color: '#bbb' }}>
                              {t('modrule.proceed-rejection')}
                            </span>
                          )}
                        </li>
                        <li>{el.checkedAt && formatDate(el.checkedAt)}</li>
                        <li>{el.username}</li>
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
            {/* 참조자 설정 */}
            <div className='readerBox modRuleBox'>
              <div className='readerBox__thead  modRuleBox__thead'>
                <span>{t('modrule.reference')}</span>
              </div>
              <div className='readerBox__tbody  modRuleBox__tbody'>
                {modRule._id &&
                  modRule.reference.map(el => (
                    <ul key={el._id}>
                      <li>
                        {el && el.length === 0
                          ? t('modrule.no-reference')
                          : `${el.username}`}
                      </li>
                    </ul>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setViewOpen(false)}>
          {t('button.close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModRuleViewApproval;
