import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { isValid } from 'date-fns';

const RevStatusOne = ({ dcnRuleList }) => {
  const { t } = useTranslation();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <div className='table'>
      <Table className='text-center'>
        <thead>
          <tr>
            <th width='20%'>{t('table.rule-num')}</th>
            <th width='10%'>{t('table.chap-num')}</th>
            <th width='45%'>{t('table.rev-sum')}</th>
            <th width='10%'>{t('table.rev-date')}</th>
          </tr>
        </thead>
        {dcnRuleList?.length > 0 &&
          dcnRuleList.map((rev, index) => (
            <tbody key={rev._id}>
              <tr>
                <td align='left'>
                  <span>[{rev.ruleNumber}] </span>
                  {rev.ruleName}
                </td>
                <td align='left' colSpan={2}>
                  {rev &&
                    rev.revstatus &&
                    rev.revstatus.map(el => (
                      <tr key={el._id} className='statusBox'>
                        <td className='chapterNumberBox'>
                          {el.chapter.map(chap => (
                            <p key={`${rev._id}_${chap}`}>{chap}</p>
                          ))}
                        </td>
                        <td className='summaryBox'>
                          {el.summary.map(sum => (
                            <p key={`${rev._id}_${sum}`}>- {sum}</p>
                          ))}
                        </td>
                      </tr>
                    ))}
                </td>
                {index === 0 && isValid(new Date(rev.revisedAt))
                  ? timezoneFormat(
                      utcToZonedTime(
                        toDate(rev.revisedAt, { timeZone: 'UTC' }), // UTC 기준으로 변환
                        timezone // 사용자 시간대로 변환
                      ),
                      'yyyy-MM-dd',
                      { timeZone: timezone }
                    )
                  : '-'}
              </tr>
            </tbody>
          ))}
      </Table>
    </div>
  );
};

export default RevStatusOne;
