import React from 'react';
import '../../scss/MyPage/MyProfile.scss';
import { useTranslation } from 'react-i18next';
import {
  FaCalendarDay,
  FaNetworkWired,
  FaRegAddressCard,
  FaRegBuilding,
} from 'react-icons/fa';
import { MdAlternateEmail, MdPhoneAndroid } from 'react-icons/md';
import { FaBuildingUser, FaClipboardUser } from 'react-icons/fa6';
import { TbDeviceLandlinePhone } from 'react-icons/tb';

const MyProfile = ({ myProfile }) => {
  const { t } = useTranslation();
  return (
    // section에 영역으로 사용하기 flex
    <section className='myProfile'>
      {/* 프로필 사진 및 결재사인 */}
      <div className='myProfile__container'>
        <div className='img__container'>
          <div className='profileImg'>
            <img
              src={
                myProfile.profileImg && myProfile.profileImg !== ''
                  ? myProfile.profileImg
                  : `${process.env.PUBLIC_URL}/images/defaultuser.png`
              }
              alt='profileImg'
            />
            <div />
            <div className='profileImg__isRegistered'>
              <span className='title'>{t('mypage.profilephoto')} </span>
            </div>
          </div>

          <div className='signatureImg'>
            {myProfile.signatureImg && myProfile.signatureImg !== '' ? (
              <img
                src={myProfile.signatureImg}
                className='myProfile__signatureimg__img'
                alt='signatureImg'
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                className='dd'
                alt='signatureImg'
              />
            )}
            <div className='signatureImg__isRegistered'>
              <span className='title'>{t('mypage.signature')} </span>
            </div>
          </div>
        </div>
        <div className='myProfile__rightBox'>
          <div className='myProfile__item'>
            <FaRegAddressCard size={22} />
            <span className='title'>{t('mypage.username')}</span>
            <span>{myProfile && myProfile.username}</span>
          </div>
          <div className='myProfile__item'>
            <MdAlternateEmail size={22} />
            <span className='title'>{t('mypage.email')}</span>
            <span>{myProfile && myProfile.email}</span>
          </div>
          <div className='myProfile__item'>
            <FaRegBuilding size={22} />
            <span className='title'>{t('mypage.org')}</span>
            <span>{myProfile.org && myProfile.org.name}</span>
          </div>
          <div className='myProfile__item'>
            <FaBuildingUser size={22} />
            <span className='title'>{t('mypage.position')}</span>
            <span>{myProfile && myProfile.position.positionName}</span>
          </div>
          <div className='myProfile__item'>
            <FaClipboardUser size={22} />
            <span className='title'>{t('mypage.employno')}</span>
            <span>{myProfile && myProfile.employno}</span>
          </div>

          <div className='myProfile__item'>
            <FaNetworkWired size={22} />
            <span className='title'>{t('mypage.grade')}</span>
            <span>{myProfile && myProfile.grade.gradeName}</span>
          </div>
          <div className='myProfile__item'>
            <FaCalendarDay size={22} />
            <span className='title'>{t('mypage.hiredate')}</span>
            <span>{myProfile && myProfile.hiredate}</span>
          </div>
          <div className='myProfile__item'>
            <MdPhoneAndroid size={22} />
            <span className='title'>{t('mypage.contact')}</span>
            <span>{myProfile && myProfile.contact}</span>
          </div>
          <div className='myProfile__item'>
            <TbDeviceLandlinePhone size={22} />
            <span className='title'>{t('mypage.officecontact')} </span>
            <span>{myProfile && myProfile.officecontact}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyProfile;
