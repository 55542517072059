import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { BiCaretDown, BiCaretUp, BiFolderMinus } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const TeamManualAdminSort = ({ hasManuals, sethasManuals, hmRef }) => {
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  useEffect(() => {
    // 즐겨찾기 결재선
    const updateHasManuals = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/manualper/hasmanuals`,
        });
        hmRef.current = res.data;
        sethasManuals(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    updateHasManuals();
  }, []);

  const deleteOne = id => {
    const afterDelArr = hasManuals.filter(el => el._id !== id);
    sethasManuals(afterDelArr);
  };

  const moveUp = (id, index) => {
    if (index === 0) {
      toast(t('components.teamManual.team-best'));
      return;
    }
    // 객체 뽑아내기
    const targetHasManual = hasManuals.find(hm => hm._id === id);
    const hasManualsCopy = [...hasManuals];
    // 해당 객체 삭제
    hasManualsCopy.splice(index, 1);
    // 해당 객체 재배치
    hasManualsCopy.splice(index - 1, 0, targetHasManual);
    sethasManuals(hasManualsCopy);
  };

  const moveDown = (id, index) => {
    if (index >= hasManuals.length - 1) {
      toast(t('components.teamManual.last-order'));
      return;
    }
    const targetHasManual = hasManuals.find(hm => hm._id === id);
    const hasManualsCopy = [...hasManuals];
    hasManualsCopy.splice(index, 1);
    hasManualsCopy.splice(index + 1, 0, targetHasManual);
    sethasManuals(hasManualsCopy);
  };

  return (
    <div className='teammanualadmin__sortlist'>
      {hasManuals?.length > 0 &&
        hasManuals.map((hasManual, i) => (
          <div
            key={hasManual._id}
            className='hasmanual'
            style={{ display: 'flex', border: '1px solid gray' }}>
            <div style={{ flex: '10', textAlign: 'center' }}>{i + 1}</div>
            <div style={{ flex: '70' }} className='hasmanual__name'>
              {hasManual.name}
              <BiFolderMinus
                size={23}
                onClick={() => deleteOne(hasManual._id)}
              />
              {(() => {
                if (hasManual.new) {
                  return (
                    <span
                      style={{
                        borderBottom: '2px solid orange',
                        height: '3vh',
                        float: 'right',
                      }}>
                      {t('components.teamManual.new')}
                    </span>
                  );
                }
                if (hasManual.manualSort !== i) {
                  return (
                    <span
                      style={{
                        borderBottom: '2px solid orange',
                        height: '3vh',
                        float: 'right',
                      }}>{`${t('components.teamManual.changeOrder')} ${
                      hasManual.manualSort
                    } >> ${i + 1}`}</span>
                  );
                }
                return null;
              })()}
            </div>
            <div style={{ flex: '20', textAlign: 'center' }}>
              <BiCaretDown
                size={23}
                onClick={() => moveDown(hasManual._id, i)}
              />{' '}
              <BiCaretUp size={23} onClick={() => moveUp(hasManual._id, i)} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default TeamManualAdminSort;
