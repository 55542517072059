const getColorForModType = modType => {
  switch (modType) {
    case 'new':
      return 'green';
    case 'delete':
      return 'red';
    case 'replace':
      return '#f18546';
    default:
      return 'black';
  }
};

export default getColorForModType;
