import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table, Spinner } from 'react-bootstrap';
import { format, endOfDay, startOfDay, sub } from 'date-fns';
import { BsSearch } from 'react-icons/bs';
import ReactPaginate from 'react-paginate';
import SearchBar from '../SearchBar';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useLog from '../../common/hooks/useLog';
import RuleRankBox from './RuleRankBox';
import { formatFullDate } from '../../common/utils/tzUtiles';
import './paginationStyles.scss';
import DatePicker from '../DatePicker/DatePicker';

const parseAction = action => {
  let parsing = action;
  if (action === 'view') {
    parsing = 'View';
  } else if (action === 'print') {
    parsing = 'Print';
  } else if (action === 'download') {
    parsing = 'Down';
  }
  return parsing;
};

const OPTIONS = [
  { value: '', nameKo: '전체', nameEn: 'ALL' },
  { value: 'ip', nameKo: 'IP', nameEn: 'IP' },
  { value: 'username', nameKo: '이름', nameEn: 'USERNAME' },
  { value: 'email', nameKo: '사용자(ID)', nameEn: 'USER(ID)' },
  { value: 'target', nameKo: '규정명/서식명', nameEn: 'RULE/FORM' },
];

const RuleLog = () => {
  const axiosAuth = useAxiosAuth();
  const { log, setLog } = useLog();
  const [ruleLog, setRuleLog] = useState({}); // eslint-disable-line no-unused-vars
  const [totalPages, setTotalPages] = useState(1);
  const [userLogPagesNum, setUserLogPagesNum] = useState(1); // 페이지네이션_pageNum
  const [searchOption, setSearchOption] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dateInputs, setDateInputs] = useState({
    startdate: '',
    enddate: '',
  });
  const { startdate, enddate } = dateInputs;

  const handleRuleLogPrint = () => {
    window.print();
  };
  const { auth } = useAuth();
  const { i18n, t } = useTranslation();

  const fetchAllLogs = async (
    start = '',
    end = '',
    page = 1,
    search = '',
    searchBy = ''
  ) => {
    try {
      const response = await axiosAuth.get(
        `/api/log/rule-access?startDate=${start}&endDate=${end}&page=${page}&search=${search}&searchBy=${searchBy}`
      );
      setRuleLog(response.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllLogs(startdate, enddate, userLogPagesNum, searchStr, searchOption);
  }, [userLogPagesNum]);

  const handleReset = () => {
    setSearchStr('');
    fetchAllLogs();
  };

  const onChangeDate = e => {
    const { value, name } = e.target;
    setDateInputs({
      ...dateInputs,
      [name]: value,
    });
  };

  const submitDate = async () => {
    await fetchAllLogs(
      startdate,
      enddate,
      userLogPagesNum,
      searchStr,
      searchOption
    );
  };

  const handlePageClick = event => {
    const newPageNum = event.selected + 1;
    setUserLogPagesNum(newPageNum);
  };

  const handleDateClick = async i => {
    setLog(prev => ({ ...prev, period: i }));
    const formatDate = date => date.toISOString().split('T')[0];
    const today = new Date();
    let startDate;
    let endDate = formatDate(today);

    switch (i) {
      case 'all':
        await fetchAllLogs('', '', 1, searchStr, searchOption);
        setDateInputs({ startdate: '', enddate: '' });
        setUserLogPagesNum(1);
        break;
      case 'today':
        startDate = endDate;
        await fetchAllLogs(
          startDate,
          endDate,
          userLogPagesNum,
          searchStr,
          searchOption
        );
        setDateInputs({ startdate: startDate, enddate: endDate });
        setUserLogPagesNum(1);
        break;
      case 'aday':
        {
          const yesterday = new Date(today.setDate(today.getDate() - 1));
          startDate = formatDate(yesterday);
          endDate = formatDate(yesterday);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case 'week':
        {
          const lastWeek = new Date(today.setDate(today.getDate() - 7));
          startDate = formatDate(lastWeek);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case 'month':
        {
          const lastMonth = new Date(today.setMonth(today.getMonth() - 1));
          startDate = formatDate(lastMonth);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case '6months':
        {
          const lastSixMonths = new Date(today.setMonth(today.getMonth() - 6));
          startDate = formatDate(lastSixMonths);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      default:
        break;
    }
  };

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  const handleClick = () => {
    setUserLogPagesNum(1);
    fetchAllLogs(startdate, enddate, userLogPagesNum, searchStr, searchOption);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const RuleLogDownload = async () => {
    setIsLoading(true);
    try {
      // 서버에 요청을 보내서 데이터를 받아옴
      const res = await axiosAuth({
        responseType: 'blob',
        method: 'POST',
        url: `/api/log/rule-access/download?startDate=${
          startdate || ''
        }&endDate=${enddate || ''}&search=${searchStr || ''}&searchBy=${
          searchOption || ''
        }`,
      });

      // 응답이 성공적일 경우
      if (res.status === 200 && ruleLog.data) {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);

        // 다운로드 링크를 동적으로 생성하여 파일 다운로드를 트리거
        const link = document.createElement('a');
        let filename;
        if (startdate !== '' && enddate !== '') {
          filename = `${ruleLog.data[0].company}_RuleLog_${format(
            new Date(startdate),
            'yyyyMMdd'
          )}-${format(new Date(enddate), 'yyyyMMdd')}.xlsx`;
        } else if (startdate !== '' && enddate === '') {
          const today = new Date();
          filename = `${ruleLog.data[0].company}_RuleLog_${format(
            new Date(startdate),
            'yyyyMMdd'
          )}-${format(today, 'yyyyMMdd')}.xlsx`;
        } else {
          filename = `${ruleLog.data[0].company}_RuleLog.xlsx`;
        }

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // URL 객체 해제
      }
    } catch (err) {
      console.log('Error during file download:', err);
    } finally {
      setIsLoading(false); // 다운로드 완료 또는 실패 시 로딩 상태 해제
    }
  };

  return (
    <section className='userlog__body' style={{ position: 'relative' }}>
      <div className='dateSearch'>
        <div className='dateSearch__dateBox'>
          <div className='periodbtns'>
            <Button
              className={log.period === '전체' ? 'active' : ''}
              onClick={() => handleDateClick('all')}>
              {t('userlog.all')}
            </Button>
            <Button
              className={log.period === 'today' ? 'active' : ''}
              onClick={() => handleDateClick('today')}>
              {t('userlog.today')}
            </Button>
            <Button
              className={log.period === 'aday' ? 'active' : ''}
              onClick={() => handleDateClick('aday')}>
              {t('userlog.aday')}
            </Button>
            <Button
              className={log.period === 'week' ? 'active' : ''}
              onClick={() => handleDateClick('week')}>
              {t('userlog.week')}
            </Button>
            <Button
              className={log.period === 'month' ? 'active' : ''}
              onClick={() => handleDateClick('month')}>
              {t('userlog.month')}
            </Button>
            <Button
              className={log.period === '6months' ? 'active' : ''}
              onClick={() => handleDateClick('6months')}>
              {t('userlog.6months')}
            </Button>
          </div>

          <div className='dateSelect'>
            <Button
              className={`dateSelect__dateBtn ${
                log.period === 'during' ? 'active' : ''
              }`}
              onClick={() => handleDateClick('during')}>
              {t('userlog.during')}
            </Button>

            <DatePicker
              name='startdate'
              startdate={startdate}
              onChangeDate={onChangeDate}
              language={i18n.language}
              disabled={log.period !== 'during'}
            />
            <span>~</span>
            <DatePicker
              name='enddate'
              startdate={enddate}
              onChangeDate={onChangeDate}
              language={i18n.language}
              disabled={log.period !== 'during'}
            />
          </div>
          <Button onClick={submitDate}>
            <BsSearch />
          </Button>
        </div>
        <RuleRankBox />
      </div>
      <div className='userlogBody rulelogBody'>
        <div className='userlogBody__subHeader'>
          <div className='userlogSearch'>
            <select onChange={e => setSearchOption(e.target.value)}>
              {OPTIONS.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                  defaultValue={searchOption === option.value}>
                  {i18n.language === 'ko' ? option.nameKo : option.nameEn}
                </option>
              ))}
            </select>
            <div className='userlogSearch__searchBox'>
              <SearchBar
                value={searchStr}
                onChange={handlesearchStr}
                searchText={handleClick}
                handleKeyDown={e => handleKeyDown(e)}
              />
            </div>
            <Button className='reset__btn' onClick={handleReset}>
              {t('userlog.init')}
            </Button>
          </div>
          <div className='btnbox'>
            <Button className='printbtn' onClick={handleRuleLogPrint}>
              {t('userlog.print')}
            </Button>
            <Button className='filebtn' onClick={RuleLogDownload}>
              {t('userlog.down')}
            </Button>
          </div>
        </div>
        <div className='userlogBody__table'>
          <table
            id='printArea_wrapper'
            style={{
              width: '100%',
              minHeight: '52vh',
              tableLayout: 'auto',
              fontSize: '14px',
            }}>
            <thead>
              <tr>
                <th width='15%'>{t('userlog.rulelog.accessTime')}</th>
                <th width='20%'>{t('userlog.rulelog.email')}</th>
                <th width='15%'>{t('userlog.rulelog.ip')}</th>
                <th width='40%'>{t('userlog.rulelog.ruleName')}</th>
                <th width='10%'>{t('userlog.rulelog.action')}</th>
              </tr>
            </thead>
            <tbody className='userlogBody__table__content'>
              {ruleLog &&
                ruleLog.data &&
                ruleLog.data.length !== 0 &&
                ruleLog.data.map(data => (
                  <tr
                    key={data.id}
                    style={{
                      transition: 'background-color 0.2s', // transition duration-200
                      borderBottom: '1px solid #f7fafc', // border-b border-solid border-gray-100
                    }}>
                    <td
                      style={{
                        verticalAlign: 'middle', // align-middle
                        fontSize: '14px', // text-sm
                        color: '#1a202c', // text-gray-900
                        textAlign: 'center',
                      }}>
                      {data.createdAt && formatFullDate(data.createdAt)}
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle', // align-middle
                        fontSize: '14px', // text-sm
                        color: '#1a202c', // text-gray-900
                        textAlign: 'center',
                      }}>
                      {data.username}({data.email})
                    </td>
                    <td
                      style={{
                        fontSize: '14px', // text-sm
                        color: '#718096', // text-gray-500
                        textAlign: 'center',
                      }}>
                      {data.ip}
                    </td>
                    <td
                      style={{
                        verticalAlign: 'middle', // align-middle
                        fontSize: '14px', // text-sm
                        color: '#1a202c', // text-gray-900
                        textAlign: 'left',
                      }}>
                      {data.target}
                    </td>

                    <td
                      style={{
                        fontSize: '14px', // text-sm
                        color: '#1a202c', // text-gray-500
                        textAlign: 'center',
                      }}>
                      {parseAction(data.action)}
                    </td>
                  </tr>
                ))}

              {ruleLog &&
                ruleLog.data &&
                ruleLog.data.length > 0 &&
                ruleLog.data.length < 12 &&
                Array.from({ length: 12 - ruleLog.data.length }).map(
                  (_, index) => (
                    <tr
                      // eslint-disable-next-line react/no-array-index-key
                      key={`empty-${index}`}>
                      <td
                        style={{
                          verticalAlign: 'middle', // 'align-middle'
                          fontSize: '0.875rem', // 'text-sm' = font-size: 14px
                          color: '#1a202c', // 'text-gray-900' = #1a202c (gray-900)
                        }}>
                        &nbsp;
                      </td>
                      <td
                        style={{
                          fontSize: '0.875rem', // 'text-sm'
                          color: '#6b7280', // 'text-gray-500' = #6b7280 (gray-500)
                        }}>
                        &nbsp;
                      </td>
                      <td
                        style={{
                          fontSize: '0.875rem', // 'text-sm'
                          verticalAlign: 'middle', // 'align-middle'
                          color: '#1a202c', // 'text-gray-900'
                        }}>
                        &nbsp;
                      </td>
                      <td
                        style={{
                          fontSize: '0.875rem', // 'text-sm'
                          verticalAlign: 'middle', // 'align-middle'
                          color: '#1a202c', // 'text-gray-900'
                        }}>
                        &nbsp;
                      </td>
                      <td
                        style={{
                          fontSize: '0.875rem', // 'text-sm'
                          verticalAlign: 'middle', // 'align-middle'
                          color: '#1a202c', // 'text-gray-900'
                        }}>
                        &nbsp;
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        </div>
      </div>
      <ReactPaginate
        breakLabel='...'
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={totalPages > 0 ? totalPages : 1} // 최소 1을 보장
        forcePage={userLogPagesNum - 1} // 0 기반 인덱스
        previousLabel='Prev'
        nextLabel='Next'
        renderOnZeroPageCount={null}
        containerClassName='pagination'
        pageLinkClassName='pagination__link'
        activeLinkClassName='pagination__link__active'
      />
      {isLoading && (
        <div
          className='spinner-container'
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <Spinner
            animation='border'
            role='status'
            style={{
              width: '4rem',
              height: '4rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              color: '#20265b',
            }}>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      )}
    </section>
  );
};

export default RuleLog;
