import { useContext, useDebugValue } from 'react';
import ModalOpenContext from '../../store/ModalOpenProvider';

const useModalOpen = () => {
  const { modalOpen } = useContext(ModalOpenContext);
  useDebugValue(modalOpen, el => (el ? 'ondata' : 'offdata'));
  return useContext(ModalOpenContext);
};

export default useModalOpen;
