import React from 'react';
import { BsFillCaretUpFill } from 'react-icons/bs';
import { AiFillStepBackward } from 'react-icons/ai';
import { Button } from 'react-bootstrap';
import {
  MdDeleteForever,
  MdOutlineFiberNew,
  MdSubdirectoryArrowRight,
} from 'react-icons/md';
import { BiCheck, BiCheckbox, BiCheckboxSquare } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useModRule from '../../common/hooks/useModRule';
import '../../scss/ModRule/AmendRuleSidelist.scss';
import useModChapter from '../../common/hooks/useModChapter';

const DevAmendSidelist = () => {
  const axiosAuth = useAxiosAuth();
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const { t } = useTranslation();

  const handleReset = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/delete',
        data: {
          modruleId: modRule._id,
        },
      });
      if (res.status === 200) {
        setModRule({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChapterSelect = el => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
    setModChapter({
      new: false,
      modType: 'replace',
      ...el,
    });
  };

  const handleChapterNew = () => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
    setModChapter({
      new: true,
      fileType: '', // type : content / file
      modType: 'new',
      content: '',
      uri: '',
      chapterNumber: `${modRule.ruleNumber}-`,
      chapterName: '',
      chapterContents: {
        header: '',
        children: [],
      },
    });
  };

  return (
    <div className='draftpage__catelist'>
      <div className='draftpage__catelist__header'>
        <div className='draftpage__catelist__tabs'>
          <span
            className='draftpage__catelist__tab'
            role='button'
            aria-hidden='true'
            onClick={() => handleReset()}>
            <AiFillStepBackward size={20} />
          </span>
          <span
            className='draftpage__catelist__tab open'
            role='button'
            aria-hidden='true'>
            {`${t('modrule.lan')} : ${modRule?.lan.toUpperCase()}`}
          </span>
          <span className='draftpage__catelist__tab__glideright' />
        </div>
      </div>
      <div className='draftpage__catelist__collapse'>
        <div className='collapseitem'>
          <div className='collapseitem__header'>
            <span>
              <BsFillCaretUpFill className='collapseitem__toggle open' />
            </span>
            <span aria-hidden='true' className='collapseitem__title'>
              {`[${modRule?.refCategory?.categoryNumber}] : ${modRule?.refCategory?.category}`}
            </span>
          </div>
          <div className='collapseitem__rulebox open'>
            <div className='collapseitem__rulebox__sub'>
              <span>
                <MdSubdirectoryArrowRight size={18} />
                {'  '}[{modRule?.refRule?.ruleNumber}] :{'  '}
                {modRule?.refRule?.ruleName}
              </span>
            </div>
          </div>
          <div className='collapseitem__fileList'>
            <div>
              <span style={{ alignSelf: 'center' }}>
                {t('modrule.chap-list')}
              </span>{' '}
              <Button onClick={handleChapterNew}>
                {t('modrule.create-chap-btn')}
              </Button>
            </div>
            {modRule?.modChapterRef
              .filter(
                mchap => mchap.modType === 'new' && mchap.chapterType === 'CH'
              )
              .map(filterModChap => (
                <div key={filterModChap._id}>
                  <Button
                    onClick={() => handleChapterSelect(filterModChap)}
                    disabled>
                    {filterModChap.chapterNumber}
                  </Button>
                  <span style={{ color: 'green' }}>
                    <MdOutlineFiberNew size={28} />
                  </span>
                  <span
                    style={{
                      color:
                        modChapter._id === filterModChap._id
                          ? 'orange'
                          : '#fff',
                    }}>
                    {modChapter._id === filterModChap._id ? (
                      <BiCheckboxSquare size={30} />
                    ) : (
                      <BiCheckbox size={30} />
                    )}
                  </span>
                </div>
              ))}
            {modRule?.modChapterRef
              .filter(
                mchap => mchap.modType === 'new' && mchap.chapterType === 'APP'
              )
              .map(filterModChap => (
                <div key={filterModChap._id}>
                  <Button
                    onClick={() => handleChapterSelect(filterModChap)}
                    disabled>
                    {filterModChap.chapterNumber}
                  </Button>
                  <span style={{ color: 'green' }}>
                    <MdOutlineFiberNew size={28} />
                  </span>
                  <span
                    style={{
                      color:
                        modChapter._id === filterModChap._id
                          ? 'orange'
                          : '#fff',
                    }}>
                    {modChapter._id === filterModChap._id ? (
                      <BiCheckboxSquare size={30} />
                    ) : (
                      <BiCheckbox size={30} />
                    )}
                  </span>
                </div>
              ))}
            {modRule?.refRule.chapterRef
              .filter(mchap => mchap.chapterType === 'CH')
              .map(el => (
                <div key={el._id}>
                  <Button
                    onClick={() => handleChapterSelect(el)}
                    disabled={modRule.modChapterRef.some(
                      modChap => modChap.oldChapterId === el._id
                    )}>
                    {el.chapterNumber}
                  </Button>
                  <span>
                    {modRule.modChapterRef.map(modChap =>
                      modChap.oldChapterId === el._id &&
                      modChap.modType === 'replace' ? (
                        <BiCheck size={28} color='green' />
                      ) : null
                    )}
                    {modRule.modChapterRef.map(modChap =>
                      modChap.oldChapterId === el._id &&
                      modChap.modType === 'delete' ? (
                        <MdDeleteForever size={28} color='red' />
                      ) : null
                    )}
                  </span>
                  <span
                    style={{
                      color: modChapter._id === el._id ? 'orange' : '#fff',
                    }}>
                    {modChapter._id === el._id ? (
                      <BiCheckboxSquare size={30} />
                    ) : (
                      <BiCheckbox size={30} />
                    )}
                  </span>
                </div>
              ))}
            {modRule?.refRule.chapterRef
              .filter(mchap => mchap.chapterType === 'APP')
              .map(el => (
                <div key={el._id}>
                  <Button
                    onClick={() => handleChapterSelect(el)}
                    disabled={modRule.modChapterRef.some(
                      modChap => modChap.oldChapterId === el._id
                    )}>
                    {el.chapterNumber}
                  </Button>
                  <span>
                    {modRule.modChapterRef.map(modChap =>
                      modChap.oldChapterId === el._id &&
                      modChap.modType === 'replace' ? (
                        <BiCheck size={28} color='green' />
                      ) : null
                    )}
                    {modRule.modChapterRef.map(modChap =>
                      modChap.oldChapterId === el._id &&
                      modChap.modType === 'delete' ? (
                        <MdDeleteForever size={28} color='red' />
                      ) : null
                    )}
                  </span>
                  <span
                    style={{
                      color: modChapter._id === el._id ? 'orange' : '#fff',
                    }}>
                    {modChapter._id === el._id ? (
                      <BiCheckboxSquare size={30} />
                    ) : (
                      <BiCheckbox size={30} />
                    )}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevAmendSidelist;
