import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { MdOutlineFileDownload } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { BiUndo, BiX } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../scss/userSignUp/GroupJoin.scss';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import CustomFileInput from '../DatePicker/FileInput';

const GroupJoin = () => {
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [folder, setFolder] = useState(null);
  const [fileSaveOpen, setFileSaveOpen] = useState(false);
  const [fileSubmitList, setFileSubmitList] = useState([]);
  const [photoSaveOpen, setPhotoSaveOpen] = useState(false);
  const [photoSubmitList, setPhotoSubmitList] = useState([]);

  const navi = useNavigate();

  const goBack = () => {
    navi('/managerauth');
  };

  const clickFormDown = async () => {
    try {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/auth/fileDownload',
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${t('components.userSignup.xlsx')}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);
    }
  };

  const currentFile = e => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', file);
    // console.log(formData);
    try {
      const response = await axiosMultipart.post(
        '/api/auth/allsingup',
        formData
      );
      if (response.data.flag) {
        toast(response.data.message);
      } else {
        setFileSubmitList(response.data);
        setFileSaveOpen(prev => !prev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const currentFolder = e => {
    setFolder(e.target.files);
  };

  const handleFolderUpload = async () => {
    const formData = new FormData();
    // start
    for (let i = 0; i < folder.length; i += 1) {
      formData.append('files', folder[i]);
    }
    // end
    try {
      const response = await axiosMultipart.post(
        '/api/auth/alluploadphotos',
        formData
      );
      if (response.status === 200) {
        setPhotoSubmitList(response.data);
        setPhotoSaveOpen(prev => !prev);
        toast(
          response.data.message || t('components.userSignup.upload-status')
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='gropJoin mainsection'>
      <h4>{t('groupJoin.title')}</h4>
      <BiUndo type='button' onClick={goBack} />
      <section className='gropJoin__contents'>
        <div className='leftBox'>
          <div className='gropJoin__contents__orderList'>
            <h5>{t('groupJoin.addInfo.title')}</h5>
            <ul>
              <li>
                STEP 1. {t('groupJoin.addInfo.step1A')}{' '}
                <span>{t('groupJoin.addInfo.step1B')}</span>{' '}
                {t('groupJoin.addInfo.step1C')}
              </li>
              <li>STEP 2. {t('groupJoin.addInfo.step2')}</li>
              <li>
                STEP 3. {t('groupJoin.addInfo.step3A')}{' '}
                <span>{t('groupJoin.addInfo.step3B')}</span>
                {t('groupJoin.addInfo.step3C')}
              </li>
            </ul>
            <p>
              <BsCheck /> {t('groupJoin.addInfo.check1')}
            </p>
            <p>
              <BsCheck /> {t('groupJoin.addInfo.check2')}
            </p>
            <p>
              <BsCheck /> {t('groupJoin.addInfo.check3')}
            </p>
            <p>
              <BsCheck /> {t('groupJoin.addInfo.check4')}
            </p>
            <div className='gropJoin__contents__uploadFile__form'>
              <Button onClick={clickFormDown}>
                {t('groupJoin.addInfo.download')} <MdOutlineFileDownload />
              </Button>
            </div>
          </div>
          <div className='gropJoin__contents__uploadFile'>
            <div className='gropJoin__contents__uploadFile__inputBox'>
              <div>
                <Form.Group
                  controlId='formFileMultiple'
                  encType='multipart/form-data'
                  className='mb-3 noticeEdit__main__sub__file__form'>
                  <Form.Label>{t('groupJoin.addInfo.inputs')}</Form.Label>

                  <CustomFileInput
                    onChange={currentFile}
                    type='file'
                    name='file'
                    multiple
                    style={{ width: '400px', marginTop: '10px' }}
                  />
                </Form.Group>
              </div>
              <div className='saveBtn'>
                <Button onClick={handleUpload}>{t('button.submit')}</Button>
              </div>
            </div>
          </div>
        </div>
        {fileSaveOpen && (
          <div className='fileSaveCheck'>
            <div className='fileSaveCheck__header'>
              <h5>{t('groupJoin.addInfo.resultTitle')}</h5>
              <BiX
                size={30}
                onClick={() => {
                  setFileSaveOpen(prev => !prev);
                }}
              />
            </div>
            <div className='fileSaveCheck__bottom'>
              <span style={{ color: 'orangered', margin: '1vh 0' }}>
                {fileSubmitList.message}
              </span>
              <div className='fileSaveCheck__bottom__successList'>
                {t('groupJoin.addInfo.success')} : {fileSubmitList.count}
              </div>
              <div className='fileSaveCheck__bottom__failList'>
                <span className='listHeader'>
                  {t('groupJoin.addInfo.failed')}
                </span>
                <div className='listContents'>
                  {fileSubmitList.faildlist.map(el => (
                    <div key={el}>
                      <span>{el.type}</span>
                      <span> : {el.data}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='rightBox'>
          <div className='gropJoin__contents__orderList'>
            <h5>{t('groupJoin.addImage.title')}</h5>
            <ul>
              <li>
                STEP 1. {t('groupJoin.addImage.step1A')}{' '}
                <span>{t('groupJoin.addImage.step1B')}</span>
                {t('groupJoin.addImage.step1C')} <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(
                {t('groupJoin.example')} : hos001.png / hos002.jpg )
              </li>
              <li>
                STEP 2. {t('groupJoin.addImage.step2A')}{' '}
                <span>{t('groupJoin.addImage.step2B')} </span>
                {t('groupJoin.addImage.step2C')}
              </li>
            </ul>
            <p>
              <BsCheck /> {t('groupJoin.addImage.check1')}
            </p>
            <p>
              <BsCheck /> {t('groupJoin.addImage.check2')}
            </p>
            <p>
              <BsCheck /> {t('groupJoin.addImage.check3')}
            </p>
          </div>
          <div className='gropJoin__contents__uploadFile'>
            <div className='gropJoin__contents__uploadFile__inputBox'>
              <Form.Group
                controlId='formFileMultiple'
                encType='multipart/form-data'
                className='mb-3 noticeEdit__main__sub__file__form'>
                <Form.Label>{t('groupJoin.addImage.inputs')}</Form.Label>
                <CustomFileInput
                  name='folder'
                  onChange={currentFolder}
                  multiple
                  directory
                  webkitdirectory
                  style={{ width: '400px', marginTop: '10px' }}
                />
              </Form.Group>

              <div className='saveBtn'>
                <Button onClick={handleFolderUpload}>
                  {t('button.submit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {photoSaveOpen && (
          <div className='photoSaveCheck'>
            <div className='photoSaveCheck__header'>
              <h5>{t('groupJoin.addImage.resultTitle')}</h5>
              <BiX
                size={30}
                onClick={() => {
                  setPhotoSaveOpen(prev => !prev);
                }}
              />
            </div>
            <div className='photoSaveCheck__main'>
              <div className='photoSaveCheck__main__noupload'>
                <span className='listHeader'>
                  {t('groupJoin.addImage.failed')}
                </span>
                <div>
                  {photoSubmitList.noupload.map(el => (
                    <span>{el}</span>
                  ))}
                </div>
              </div>
              <div className='photoSaveCheck__main__okupload'>
                <span className='listHeader'>
                  {t('groupJoin.addImage.success')}
                </span>
                <div>
                  {photoSubmitList.okupload.map(el => (
                    <span>{el}</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default GroupJoin;
