import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './store/AuthProvider';
import { NotificationProvider } from './store/NotificationProvider';
import { ModalOpenProvider } from './store/ModalOpenProvider';
import App from './App';
import './assets/fonts/font.css';
import './i18n';

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <NotificationProvider>
        <ModalOpenProvider>
          <App />
        </ModalOpenProvider>
      </NotificationProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
