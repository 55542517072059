import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BiDownload, BiUndo } from 'react-icons/bi';
import '../../scss/Mobile/MobileNotice/MobileNotice.scss';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAuth from '../../common/hooks/useAuth';

const MobileNotice = () => {
  const { t } = useTranslation();
  const [noticeFiles, setNoticeFiles] = useState();
  const [targetNotice, setTargetNotice] = useState({});
  const { id } = useParams();
  const axiosAuth = useAxiosAuth();
  const { auth } = useAuth();

  const navi = useNavigate();

  const goBack = () => {
    navi('/notices');
  };

  useEffect(() => {
    const notice = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/notice/byone/${id}/${auth.id}`,
        });

        setTargetNotice(res.data.doc);
        const filesCopy = res.data.doc.url.map(u => {
          const lastSlash = u.lastIndexOf('/');
          const fileName = u.substring(lastSlash + 1);
          return {
            label: decodeURIComponent(fileName),
            url: u,
          };
        });
        setNoticeFiles(filesCopy);
      } catch (err) {
        console.log(err);
      }
    };
    notice();
  }, [id]);

  const downloadFile = async file => {
    try {
      const resDown = await axiosAuth({
        method: 'POST',
        url: '/api/notice/download',
        responseType: 'blob',
        data: {
          url: file.url,
        },
      });
      // desktop .ver 동일 수정
      const blob = new Blob([resDown.data]);
      const fileName = file.label;
      const downUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downUrl);
    } catch (err2) {
      console.log(`error resDown : ${err2}`);
    }
  };

  return (
    <section className='mobileNotice'>
      <div className='mobileNotice__btnList'>
        <Button variant='secondary' onClick={goBack}>
          <BiUndo type='button' size={26} />
        </Button>
      </div>
      <Table className='mainContent'>
        <thead>
          <tr className='mainContent__header'>
            <td>
              <p>{targetNotice && targetNotice.title}</p>
              <div>
                <div className='subHeader__left'>
                  <span>{t('notice.date')} : </span>
                  <span>
                    {targetNotice.updatedAt &&
                      targetNotice.updatedAt.slice(0, 10)}
                  </span>
                </div>
                <div className='subHeader__right'>
                  <span>{t('notice.views')} : </span>
                  <span>{targetNotice && targetNotice.count}</span>
                </div>
              </div>
            </td>
          </tr>
          <tr className='mainContent__fileBox'>
            <td>
              <span>
                {t('table.attachment')} (
                {targetNotice.url && targetNotice.url.length}) :
              </span>
              <div>
                {typeof targetNotice.url !== 'object' ||
                !Array.isArray(targetNotice.url) ||
                targetNotice.url.length === 0 ? (
                  <span style={{ color: '#ccc' }}>{t('notice.empty')}</span>
                ) : (
                  noticeFiles &&
                  noticeFiles.map(file => (
                    <div key={file.url}>
                      <span className='mainContent__fileBox__fileLabel__span'>
                        {file.label}
                      </span>
                      <BiDownload onClick={() => downloadFile(file)} />
                    </div>
                  ))
                )}
              </div>
            </td>
          </tr>
        </thead>
        <tbody className='mainContent__body'>
          <tr>
            <td>
              <div>{targetNotice && targetNotice.content}</div>
            </td>
          </tr>
        </tbody>
      </Table>
    </section>
  );
};

export default MobileNotice;
