import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { BiBell, BiGlobe } from 'react-icons/bi';
import { RiQuestionLine } from 'react-icons/ri';
import { FaUserCircle } from 'react-icons/fa';
import '../scss/MainNav.scss';
import MainNavSearchBar from './MainNav/MainNavSearchBar';
import NotificationCenter from './MainNav/NotificationCenter';
import useAuth from '../common/hooks/useAuth';
import useNotification from '../common/hooks/useNotification';
import MainNavUserInfo from './MainNav/MainNavUserInfo';

const envtype = process.env.REACT_APP_ENVTYPE || '';

const MainNav = ({ path }) => {
  const { i18n, t } = useTranslation();
  const [openModal, setOpenModal] = useState('');
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { notis } = useNotification();

  const mainNavRef = useRef(null);

  const handleClickOutside = event => {
    if (mainNavRef.current && !mainNavRef.current.contains(event.target)) {
      setOpenModal('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const goBack = () => {
    navigate('/');
  };

  const goAlarm = () => {
    if (openModal === 'alarm') {
      setOpenModal('');
      return;
    }
    setOpenModal('alarm');
  };

  const goManual = () => {
    if (openModal === 'manual') {
      setOpenModal('');
      return;
    }
    setOpenModal('manual');
  };

  const goUserInfo = () => {
    if (openModal === 'userinfo') {
      setOpenModal('');
      return;
    }
    setOpenModal('userinfo');
  };

  const searchPaths = [
    '/notices',
    '/ruleinfo',
    '/search',
    '/dual',
    '/forms',
    '/form',
    '/revstatus',
    '/userlog',
    '/rulechart',
  ];

  const handleLanguage = language => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <header className='header'>
      <div className='header__button'>
        <button
          type='button'
          className='header__button__Styleleft'
          onClick={goBack}>
          {t('mainnav.rulebase')}
        </button>
        <span className='header__button__splitline'>|</span>
        <button
          type='button'
          className='header__button__Styleright'
          onClick={goBack}>
          {envtype && envtype === 'local' ? 'RULE BASE' : 'EDMS'}
        </button>
      </div>

      <div className='header__rightList'>
        <div className='header__rightList__searchBox'>
          {searchPaths.filter(el => path.includes(el)).length !== 0 ? (
            <MainNavSearchBar />
          ) : null}
        </div>
        <div ref={mainNavRef} className='header__rightList__userBox'>
          <DropdownButton
            onClick={() => setOpenModal('')}
            className='userLan'
            align='end'
            title={
              i18n.language === 'ko' ? (
                <span>
                  KOR&nbsp;
                  <BiGlobe size={24} />
                </span>
              ) : (
                <span>
                  ENG&nbsp;
                  <BiGlobe size={24} />
                </span>
              )
            }
            variant='secondary'
            id='dropdown-menu-align-end'>
            <Dropdown.Item eventKey='1' onClick={() => handleLanguage('ko')}>
              KOREAN
            </Dropdown.Item>
            <Dropdown.Item eventKey='2' onClick={() => handleLanguage('en')}>
              ENGLISH
            </Dropdown.Item>
          </DropdownButton>
          {auth.accessToken ? (
            <>
              <Button className='alarm' variant='secondary'>
                <BiBell onClick={goAlarm} size={30} />
                <div className='alarm__btn'>
                  <Badge pill bg='warning' onClick={goAlarm}>
                    {notis.count}
                  </Badge>
                </div>
              </Button>
              {openModal === 'alarm' && (
                <NotificationCenter setOpenModal={setOpenModal} />
              )}
              <span
                className={`header__search__logout ${
                  openModal === 'userinfo' ? 'userDialog' : ''
                }`}
                onClick={goUserInfo}
                aria-hidden='true'>
                {auth.profileImg && auth.profileImg !== '' ? (
                  <img
                    width='32'
                    height='32'
                    style={{ borderRadius: '50%' }}
                    src={auth.profileImg}
                    alt='userprofileImg'
                  />
                ) : (
                  <FaUserCircle size={38} />
                )}
              </span>
              {openModal === 'userinfo' && (
                <MainNavUserInfo
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                />
              )}
            </>
          ) : (
            <Button
              className='header__rightList__login'
              onClick={() => navigate('/login')}>
              {t('mainnav.login')}
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default MainNav;
