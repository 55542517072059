import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';

const useLogout = () => {
  const { setAuth } = useAuth();
  const navi = useNavigate();

  const logout = async () => {
    setAuth({});
    localStorage.setItem('persist', false);
    await axios
      .get('/api/logout', {
        withCredentials: true,
      })
      .then(res => {
        if (res.status === 204) {
          console.log('logout');
        }
        navi('/login');
      })
      .catch(err => {
        if (!err?.response) {
          console.log('No Server Response');
        }
      });
  };
  return logout;
};

export default useLogout;
