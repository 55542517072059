import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { BsSearch } from 'react-icons/bs';
import { BiCalendar } from 'react-icons/bi';
import { Button, Spinner } from 'react-bootstrap';
import { TbZoomReset } from 'react-icons/tb';
import ReactPaginate from 'react-paginate';
import { MdOutlineRestartAlt } from 'react-icons/md';
import MobileSearchBar from '../MobileSearchBar';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useLog from '../../common/hooks/useLog';
import { formatFullDate } from '../../common/utils/tzUtiles';
import DatePicker from '../../components/DatePicker/DatePicker';

const parseAction = action => {
  let parsing = action;
  if (action === 'view') {
    parsing = 'View';
  } else if (action === 'print') {
    parsing = 'Print';
  } else if (action === 'download') {
    parsing = 'Download';
  }
  return parsing;
};

const OPTIONS = [
  { value: '', nameKo: '전체', nameEn: 'ALL' },
  { value: 'ip', nameKo: 'IP', nameEn: 'IP' },
  { value: 'username', nameKo: '이름', nameEn: 'USERNAME' },
  { value: 'email', nameKo: '사용자(ID)', nameEn: 'USER(ID)' },
  { value: 'target', nameKo: '규정명/서식명', nameEn: 'RULE/FORM' },
];

const MobileRuleLog = () => {
  const axiosAuth = useAxiosAuth();
  const { log, setLog } = useLog();
  const [ruleLog, setRuleLog] = useState({}); // eslint-disable-line no-unused-vars
  const [totalPages, setTotalPages] = useState(1);
  const [userLogPagesNum, setUserLogPagesNum] = useState(1); // 페이지네이션_pageNum
  const [searchOption, setSearchOption] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [dateInputs, setDateInputs] = useState({
    startdate: '',
    enddate: '',
  });
  const { startdate, enddate } = dateInputs;
  const { i18n, t } = useTranslation();

  const fetchAllLogs = async (
    start = '',
    end = '',
    page = 1,
    search = '',
    searchBy = ''
  ) => {
    try {
      const response = await axiosAuth.get(
        `/api/log/rule-access?startDate=${start}&endDate=${end}&page=${page}&search=${search}&searchBy=${searchBy}`
      );
      setRuleLog(response.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllLogs(startdate, enddate, userLogPagesNum, searchStr, searchOption);
  }, [userLogPagesNum]);

  const handleReset = () => {
    setSearchStr('');
    fetchAllLogs();
  };

  const onChangeDate = e => {
    const { value, name } = e.target;
    setDateInputs({
      ...dateInputs,
      [name]: value,
    });
  };

  const submitDate = async () => {
    await fetchAllLogs(
      startdate,
      enddate,
      userLogPagesNum,
      searchStr,
      searchOption
    );
  };

  const handlePageClick = event => {
    const newPageNum = event.selected + 1;
    setUserLogPagesNum(newPageNum);
  };

  const handleDateClick = async i => {
    setLog(prev => ({ ...prev, period: i }));
    const formatDate = date => date.toISOString().split('T')[0];
    const today = new Date();
    let startDate;
    let endDate = formatDate(today);

    switch (i) {
      case '전체':
        await fetchAllLogs('', '', 1, searchStr, searchOption);
        setDateInputs({ startdate: '', enddate: '' });
        setUserLogPagesNum(1);
        break;
      case '당일':
        startDate = endDate;
        await fetchAllLogs(
          startDate,
          endDate,
          userLogPagesNum,
          searchStr,
          searchOption
        );
        setDateInputs({ startdate: startDate, enddate: endDate });
        setUserLogPagesNum(1);
        break;
      case '전일':
        {
          const yesterday = new Date(today.setDate(today.getDate() - 1));
          startDate = formatDate(yesterday);
          endDate = formatDate(yesterday);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case '1주일':
        {
          const lastWeek = new Date(today.setDate(today.getDate() - 7));
          startDate = formatDate(lastWeek);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case '1개월':
        {
          const lastMonth = new Date(today.setMonth(today.getMonth() - 1));
          startDate = formatDate(lastMonth);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      case '6개월':
        {
          const lastSixMonths = new Date(today.setMonth(today.getMonth() - 6));
          startDate = formatDate(lastSixMonths);
          await fetchAllLogs(startDate, endDate, 1, searchStr, searchOption);
          setDateInputs({ startdate: startDate, enddate: endDate });
          setUserLogPagesNum(1);
        }
        break;
      default:
        break;
    }
  };

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  const handleClick = () => {
    setUserLogPagesNum(1);
    fetchAllLogs(startdate, enddate, userLogPagesNum, searchStr, searchOption);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  const RuleLogDownload = async () => {
    setIsLoading(true);
    try {
      // 서버에 요청을 보내서 데이터를 받아옴
      const res = await axiosAuth({
        responseType: 'blob',
        method: 'POST',
        url: `/api/log/rule-access/download?startDate=${
          startdate || ''
        }&endDate=${enddate || ''}&search=${searchStr || ''}&searchBy=${
          searchOption || ''
        }`,
      });

      // 응답이 성공적일 경우
      if (res.status === 200 && ruleLog.data) {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);

        // 다운로드 링크를 동적으로 생성하여 파일 다운로드를 트리거
        const link = document.createElement('a');
        let filename;
        if (startdate !== '' && enddate !== '') {
          filename = `${ruleLog.data[0].company}_RuleLog_${format(
            new Date(startdate),
            'yyyyMMdd'
          )}-${format(new Date(enddate), 'yyyyMMdd')}.xlsx`;
        } else if (startdate !== '' && enddate === '') {
          const today = new Date();
          filename = `${ruleLog.data[0].company}_RuleLog_${format(
            new Date(startdate),
            'yyyyMMdd'
          )}-${format(today, 'yyyyMMdd')}.xlsx`;
        } else {
          filename = `${ruleLog.data[0].company}_RuleLog.xlsx`;
        }

        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // URL 객체 해제
      }
    } catch (err) {
      console.log('Error during file download:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className='mobileUserLog__subHeader'>
        <div className='dateSearch'>
          <div className='periodbtns'>
            <Button
              className={log.period === '전체' ? 'active' : ''}
              onClick={() => handleDateClick('전체')}>
              {t('userlog.all')}
            </Button>
            <Button
              className={log.period === '당일' ? 'active' : ''}
              onClick={() => handleDateClick('당일')}>
              {t('userlog.today')}
            </Button>
            <Button
              className={log.period === '전일' ? 'active' : ''}
              onClick={() => handleDateClick('전일')}>
              {t('userlog.aday')}
            </Button>
            <Button
              className={log.period === '1주일' ? 'active' : ''}
              onClick={() => handleDateClick('1주일')}>
              {t('userlog.week')}
            </Button>
            <Button
              className={log.period === '1개월' ? 'active' : ''}
              onClick={() => handleDateClick('1개월')}>
              {t('userlog.month')}
            </Button>
            <Button
              className={log.period === '6개월' ? 'active' : ''}
              onClick={() => handleDateClick('6개월')}>
              {t('userlog.6months')}
            </Button>
          </div>

          <div className='dateSelect'>
            <Button
              className={`dateSelect__dateBtn ${
                log.period === '기간' ? 'active' : ''
              }`}
              onClick={() => handleDateClick('기간')}>
              <BiCalendar size={16} />
            </Button>
            <DatePicker
              name='startdate'
              startdate={startdate}
              onChangeDate={onChangeDate}
              language={i18n.language}
              disabled={log.period !== 'during'}
            />
            <span>~</span>
            <DatePicker
              name='enddate'
              startdate={enddate}
              onChangeDate={onChangeDate}
              language={i18n.language}
              disabled={log.period !== 'during'}
            />
            <Button onClick={submitDate}>
              <BsSearch size={12} />
            </Button>
          </div>
        </div>
      </section>
      <section className='mobileUserLogBody'>
        <div className='mobileUserLogBody__subHeader'>
          <div className='mobilelogSearch'>
            <select
              style={{ margin: '0 10px', border: 'none' }}
              onChange={e => setSearchOption(e.target.value)}>
              {OPTIONS.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                  defaultValue={searchOption === option.value}>
                  {i18n.language === 'ko' ? option.nameKo : option.nameEn}
                </option>
              ))}
            </select>
            <div className='mobilelogSearch__searchBox'>
              <MobileSearchBar
                value={searchStr}
                onChange={handlesearchStr}
                handleKeyDown={e => handleKeyDown(e)}
                searchText={handleClick}
              />
            </div>
            <Button className='reset__btn' onClick={handleReset}>
              <MdOutlineRestartAlt size={18} />
            </Button>
          </div>
        </div>
        <div className='mobileUserLogBody__table'>
          {ruleLog &&
            ruleLog.data &&
            ruleLog.data.length !== 0 &&
            ruleLog.data?.map(el => (
              <div
                key={el.id}
                className='mobileUserLogBody__table__ruleContents'>
                <div>
                  <div>
                    <span>{el.createdAt && formatFullDate(el.createdAt)}</span>
                    <span>{el.username}</span>
                  </div>
                  <div>{el.target}</div>
                </div>
                <div>{parseAction(el.action)}</div>
              </div>
            ))}
        </div>
        <ReactPaginate
          breakLabel='...'
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={totalPages > 0 ? totalPages : 1} // 최소 1을 보장
          forcePage={userLogPagesNum - 1} // 0 기반 인덱스
          previousLabel='Prev'
          nextLabel='Next'
          renderOnZeroPageCount={null}
          containerClassName='pagination-mobile'
          pageLinkClassName='pagination__link'
          activeLinkClassName='pagination__link__active'
        />

        {isLoading && (
          <div
            className='spinner-container'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}>
            <Spinner
              animation='border'
              role='status'
              style={{
                width: '4rem',
                height: '4rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                color: '#20265b',
              }}>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          </div>
        )}
      </section>
      <button
        type='button'
        onClick={RuleLogDownload}
        style={{
          marginLeft: 'auto',
          border: 'none',
          borderRadius: '5px',
          backgroundColor: '#20265b',
          color: '#fff',
          padding: '8px',
          top: '2vh',
          right: 0,
          position: 'absolute',
        }}>
        {t('userlog.down')}
      </button>
    </>
  );
};

MobileRuleLog.defaultProps = {
  logs: [],
};

export default MobileRuleLog;
