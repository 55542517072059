import React, { useEffect } from 'react';
import '../scss/Mobile/MobileMain.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ImBooks } from 'react-icons/im';
import { BiNotification } from 'react-icons/bi';
import { MdNotifications, MdOutlineScreenSearchDesktop } from 'react-icons/md';
import MobileMainSearchBar from './MobileMainSearchBar';

const MobileMain = () => {
  const { t, i18n } = useTranslation();

  const handleLanguage = language => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  useEffect(() => {
    handleLanguage('en');
  }, []);

  return (
    <div className='mobilsection'>
      <div className='mobilsection__top'>
        <MobileMainSearchBar />
      </div>
      <div className='mobilsection__body'>
        <div className='menuBox'>
          <div className='menuBox__upContents'>
            <div>
              <NavLink to='ruleinfo'>
                <ImBooks />
                <span>{t('mainmenu.rule-info')}</span>
              </NavLink>
            </div>
            <div>
              <NavLink to='revstatus'>
                <BiNotification />
                <span>{t('mainmenu.rev-status')}</span>
              </NavLink>
            </div>
          </div>
          <div className='menuBox__downContents'>
            <div>
              <NavLink to='notices'>
                <MdNotifications />
                <span>{t('mainmenu.notice')}</span>
              </NavLink>
            </div>
            <div>
              <NavLink to='userlog'>
                <MdOutlineScreenSearchDesktop />
                <span>{t('mainmenu.userlog')}</span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMain;
