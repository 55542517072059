import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import { Button, InputGroup } from 'react-bootstrap';
import '../scss/Mobile/MobileSearchBar.scss';
import { useNavigate } from 'react-router-dom';

const MobileMainSearchBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [focused, setFocused] = React.useState(false);

  const handleSearchText = e => {
    setSearchText(e.target.value);
  };
  const search = async () => {
    if (searchText === '') {
      alert(t('searchbar.placeholder'));
      return;
    }
    navigate(`/searchrule/${searchText}`);
    // setSearchOpen(false);
  };
  const handleKeyDown = e => {
    if (focused) {
      const key = e.code;
      switch (key) {
        case 'Enter':
          search();
          break;
        default:
      }
    }
  };

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <InputGroup className='MobileSearchBar'>
      <Form.Control
        className='MobileSearchBar__input'
        placeholder={t('searchbar.placeholder')}
        aria-label="Recipient's username"
        aria-describedby='basic-addon2'
        onChange={handleSearchText}
        value={searchText}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
      />
      <Button type='submit' onClick={search} className='MobileSearchBar__btn'>
        <BsSearch />
      </Button>
    </InputGroup>
  );
};

export default MobileMainSearchBar;
