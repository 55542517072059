import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { BiLinkExternal, BiTrash, BiX } from 'react-icons/bi';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import '../../scss/NotificationCenter.scss';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useNotification from '../../common/hooks/useNotification';
import useAuth from '../../common/hooks/useAuth';

const NotificationCenter = ({ setOpenModal }) => {
  const navigate = useNavigate();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const axiosAuth = useAxiosAuth();
  const { notis, setNotis } = useNotification();
  const { auth } = useAuth();
  const { t } = useTranslation();

  const notificationUpdate = async () => {
    await axiosAuth
      .get(`/api/approval/all/notification/${auth.employno}`)
      .then(res => {
        setNotis(prev => ({
          ...prev,
          data: res.data,
          count: res.data.filter(el => el.read === false).length,
        }));
      })
      .catch(err => {
        if (err) {
          console.log(err);
        }
      });
  };

  useEffect(() => {
    notificationUpdate();
  }, []);

  const popupClose = () => {
    setOpenModal('');
  };

  const goLink = async noti => {
    await axiosAuth
      .get(`/api/approval/notification/${noti._id}`)
      .then(res => {
        if (res.status === 200) {
          notificationUpdate();
          popupClose();
          if (
            noti.classification === 'cir' ||
            noti.classification === 'checklist'
          ) {
            navigate(`ruleinfo/mix/${noti.modRuleId}`);
          } else {
            navigate(`modrule/dcn/${noti.modRuleId}`);
          }
        }
      })
      .catch(err => {
        if (err) {
          toast.error(t('notificationcenter.error'));
        }
      });
  };

  const handleDelete = async notiId => {
    await axiosAuth
      .delete(`/api/approval/notification/${notiId}`)
      .then(res => {
        if (res.status === 200) {
          notificationUpdate();
        }
      })
      .catch(err => {
        if (err) {
          toast.error(t('notificationcenter.error'));
        }
      });
  };

  const clearAll = async () => {
    if (window.confirm(t('notificationcenter.del-alarm'))) {
      await axiosAuth
        .delete(`/api/approval/all/notification/${auth.employno}`)
        .then(res => {
          if (res.status === 200) {
            notificationUpdate();
            toast.success(t('notificationcenter.clear-alarm'));
          }
        })
        .catch(err => {
          if (err) {
            toast.error(t('notificationcenter.error'));
          }
        });
    }
  };

  const parsingNotiClass = classi => {
    switch (classi) {
      case 'amend':
        return t('notificationcenter.amend');
      case 'cir':
        return 'CIR';
      case 'checklist':
        return 'C L';
      default:
        return t('notificationcenter.enact');
    }
  };

  return (
    <div className='alarm__popup'>
      <div className='alarm__popup__header'>
        <span>{t('notificationcenter.title')}</span>
        <Button variant='outline-primary' onClick={() => clearAll()}>
          {t('notificationcenter.clear-all')}
        </Button>
        <BiX className='close__btn' onClick={popupClose} size={30} />
      </div>
      <div className='alarm__popup__body'>
        {notis.data.length === 0 ? (
          <span style={{ fontSize: '1rem', color: '#ccc' }}>
            {t('notificationcenter.no-alarm')}
          </span>
        ) : (
          <ul>
            {notis.data.map(noti => (
              <li key={noti._id} className={`preview ${noti.read && 'read'}`}>
                <div className='preview__tagbox'>
                  <span className={`tag__${noti.classification}`}>
                    {parsingNotiClass(noti.classification)}
                  </span>
                </div>
                <div className='preview__contents'>
                  <div>{noti.categoryInfo}</div>
                  <div>
                    <span>{noti.content}</span>
                    <span>
                      {timezoneFormat(
                        utcToZonedTime(
                          toDate(noti.createdAt, {
                            timeZone: 'UTC',
                          }),
                          timezone
                        ),
                        'yyyy-MM-dd HH:mm',
                        { timeZone: timezone }
                      )}
                    </span>
                  </div>
                </div>
                <div className='preview__svgBtns'>
                  <BiLinkExternal
                    size={20}
                    className='svgBtns__linkExternal'
                    title={t('notificationcenter.shortcut')}
                    onClick={() => goLink(noti)}
                  />
                  <BiTrash
                    size={20}
                    className='svgBtns__delete'
                    title={t('notificationcenter.del')}
                    onClick={() => handleDelete(noti._id)}
                  />
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      {/* <div className='alarm__popup__footer'>확인된 알림은 불투명색으로 처리됩니다.</div> */}
    </div>
  );
};

export default NotificationCenter;
