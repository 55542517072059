import React, { useEffect, useState } from 'react';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import parse from 'html-react-parser';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';

const RevTr = ({ revs }) => {
  const { t, i18n } = useTranslation();

  if (revs.length === 0) {
    return (
      <tr>
        <td>{t('table.unregistered')}</td>
        <td>{t('table.unregistered')}</td>
        <td>{t('table.unregistered-rev-sum')}</td>
        <td>{t('table.unregistered-rev-sum')}</td>
        <td>{t('table.unregistered')}</td>
      </tr>
    );
  }

  if (revs.length === 1) {
    return (
      <tr>
        <td>{t('table.unregistered')}</td>
        <td>{t('table.unregistered')}</td>
        {revs.map(rev => (
          <>
            {parse(`<td class='chaptd'>${rev.chapter}</td>`)}
            {parse(`<td class='sumtd'>${rev.summary}</td>`)}
          </>
        ))}
        <td>{t('table.unregistered')}</td>
      </tr>
    );
  }

  const rownum = revs.length;
  return (
    <>
      <tr>
        <td rowSpan={rownum}>{t('table.unregistered')}</td>
        <td rowSpan={rownum}>{t('table.rev-date')}</td>
        {parse(`<td class='chaptd'>${revs[0].chapter}</td>`)}
        {parse(`<td class='sumtd'>${revs[0].summary}</td>`)}
        <td rowSpan={rownum}>{t('table.unregistered')}</td>
      </tr>
      {revs.slice(1).length > 0 &&
        revs.slice(1).map(rev => (
          <tr>
            {parse(`<td class='chaptd'>${rev.chapter}</td>`)}
            {parse(`<td class='sumtd'>${rev.summary}</td>`)}
          </tr>
        ))}
    </>
  );
};

const Tr = ({ one }) => {
  if (one.revstatus.length === 0) {
    return (
      <tr>
        <td>{one.revno}</td>
        <td>{one.date}</td>
        <td> </td>
        <td> </td>
        <td>{one.remarks}</td>
      </tr>
    );
  }

  if (one.revstatus.length === 1) {
    return (
      <tr>
        <td>{one.revno}</td>
        <td>{one.date}</td>
        {one.revstatus.map(rev => (
          <>
            {parse(`<td class='chaptd'>${rev.chapter}</td>`)}
            {parse(`<td class='sumtd'>${rev.summary}</td>`)}
          </>
        ))}
        <td>{one.remarks}</td>
      </tr>
    );
  }

  const rownum = one.revstatus.length;
  return (
    <>
      <tr>
        <td rowSpan={rownum}>{one.revno}</td>
        <td rowSpan={rownum}>{one.date}</td>
        {parse(`<td class='chaptd'>${one.revstatus[0].chapter}</td>`)}
        {parse(`<td class='sumtd'>${one.revstatus[0].summary}</td>`)}
        <td rowSpan={rownum}>{one.remarks}</td>
      </tr>
      {one.revstatus.slice(1).map(rev => (
        <tr>
          {parse(`<td class='chaptd'>${rev.chapter}</td>`)}
          {parse(`<td class='sumtd'>${rev.summary}</td>`)}
        </tr>
      ))}
    </>
  );
};

const ModRuleRevModal = ({
  setUpdate,
  ruleModId,
  ruleNumber,
  revstatus,
  setRevOpen,
}) => {
  const [parsedRev, setParsedRev] = useState([]);
  const [parsedNewRev, setParsedNewRev] = useState([]);
  const [revInputs, setRevInputs] = useState([]);
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    if (revstatus) {
      const newRevCopy = revstatus.map(x => ({
        id: x._id,
        chapter: x.chapter.map(sum => `<pre>${sum}</pre>`).join(''),
        summary: x.summary.map(sum => `<pre>${sum}</pre>`).join(''),
      }));
      setParsedNewRev(newRevCopy);
      setRevInputs(revstatus);
    }
    const ruleNoticeFetch = async () => {
      await axiosAuth
        .get(`/api/revstatus/byfour/${ruleNumber}`)
        .then(res => {
          const pasingData = res.data.map(rev => ({
            id: rev.id,
            revno: rev.revno,
            date: timezoneFormat(
              utcToZonedTime(
                toDate(rev.date, {
                  timeZone: 'UTC',
                }),
                timezone
              ),
              'yyyy-MM-dd',
              { timeZone: timezone }
            ),
            revstatus: rev.revstatus.map(x => ({
              chapter: x.chapter.map(sum => `<pre>${sum}</pre>`).join(''),
              summary: x.summary.map(sum => `<pre>${sum}</pre>`).join(''),
            })),
            classification: rev.classification,
            remarks: rev.remarks,
            lan: rev.lan,
          }));
          setParsedRev(pasingData);
        })
        .catch(err => {
          console.log(err);
          setParsedRev([]);
        });
    };
    ruleNoticeFetch();
  }, []);

  const updateState = index => e => {
    const newArray = revInputs.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          [e.target.name]: [e.target.value],
        };
      }
      return item;
    });
    setRevInputs(newArray);
  };

  const close = () => {
    setRevOpen(false);
  };

  const submitRevStatus = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: `/api/modrule/revstatus/edit`,
        data: {
          ruleModId,
          revstatus: revInputs,
        },
      });
      if (res.status === 200) {
        toast(t('modrule.edit-rev-sum'));
        close();
        setUpdate(prev => !prev);
      }
    } catch (err) {
      if (err) {
        toast(t('modrule.edit-rev-sum-failed'));
      }
    }
  };

  return (
    <div className='modRuleOne__revStatusModal__bodycon'>
      <div className='modRuleOne__revStatusModal__tablecon'>
        <table className='modRuleOne__revStatusModal__table'>
          <thead>
            <tr>
              <th width='7%'>{t('table.rev-num')}</th>
              <th width='13%'>{t('table.date')}</th>
              <th width='15%'>{t('table.chapter')}</th>
              <th width='52%'>{t('table.revision-summary')}</th>
              <th width='13%'>{t('table.remark')}</th>
            </tr>
          </thead>
          <tbody>
            {parsedRev !== [] &&
              parsedRev.map(one => <Tr key={one._id} one={one} />)}
            {parsedNewRev !== [] && <RevTr revs={parsedNewRev} />}
          </tbody>
        </table>
      </div>
      <div className='modRuleOne__revStatusModal__inputs'>
        <table className='inputs__table'>
          <thead>
            <tr>
              <th width='30%'>{t('table.chapter')}</th>
              <th width='70%'>{t('table.summary')}</th>
            </tr>
          </thead>
          <tbody>
            {revInputs.length > 0 &&
              revInputs.map((revs, i) => (
                <tr key={revs.chapter.join(',')} className='oneTr'>
                  <td>{revs.chapter.join(',')}</td>
                  <td>
                    <input
                      type='text'
                      className='form-control'
                      id={`input-revsummary_${i}`}
                      name='summary'
                      placeholder={t('table.summary')}
                      value={revs.summary.join('')}
                      onChange={updateState(i)}
                      required
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className='modRuleOne__revStatusModal__bottom'>
          <Button type='submit' variant='secondary' onClick={close}>
            {t('button.close')}
          </Button>
          <Button type='submit' variant='secondary' onClick={submitRevStatus}>
            {t('button.edit')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModRuleRevModal;
