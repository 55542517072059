import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import RuleDualForm from './RuleDualForm';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import blackStyles from '../../common/utils/SelectStyles';

// lan에 반대되는 카테고리 fetch
// ruleNumber에 대응하는 초기값
const RuleDualRight = ({ lan, cateName, ruleNumber }) => {
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [cateOptions, setCateOptions] = useState([]);
  const [ruleOptions, setRuleOptions] = useState([]);
  const [targetCate, setTargetCate] = useState({ label: '', value: '' });
  const [targetRule, setTargetRule] = useState({
    ruleNumber: '',
    ruleName: '',
    lan: '',
    chapterRef: [],
    formRef: [],
  });
  const [lang, setLang] = useState(() => {
    if (lan === 'kor') {
      return 'eng';
    }
    return 'kor';
  });

  useEffect(() => {
    const controller = new AbortController();
    const fetchRight = async () => {
      let ruleNum = '';
      if (lan === 'kor') {
        ruleNum = `${ruleNumber}(E)`;
      } else {
        ruleNum = ruleNumber.replace('(E)', '');
      }
      await axios
        .all([
          axiosAuth.get(`/api/category/all-by-dualview/${lang}`),
          axiosAuth.post('/api/rule/ruledualinit', {
            lan: lang,
            ruleNumber: ruleNum,
          }),
        ])
        .then(
          axios.spread((resCates, resRules) => {
            // category options
            const optionsCopy = resCates.data.map(cateEl => ({
              label: `[${cateEl.categoryNumber}] : ${cateEl.category}`,
              value: cateEl,
            }));
            setCateOptions(optionsCopy);

            // target category
            const targetCateCopy = resCates.data.filter(
              el => el.category === cateName
            )[0];
            setTargetCate({
              label: `[${targetCateCopy.categoryNumber}] : ${targetCateCopy.category}`,
              value: targetCateCopy,
            });
            // rule options
            const rulesCopy = targetCateCopy.ruleRef.map(ruleEl => ({
              label: `[${ruleEl.ruleNumber}] : ${ruleEl.ruleName}`,
              value: ruleEl,
            }));
            setRuleOptions(rulesCopy);
            if (resRules.data) {
              setTargetRule(resRules.data);
            }
          })
        )
        .catch(err => console.log(err));
    };
    fetchRight();

    return () => {
      controller.abort();
    };
  }, []);

  const handleCategory = e => {
    const { ruleRef, categoryNumber, category } = e.value;
    const rulesCopy = ruleRef.map(ruleEl => ({
      label: `[${ruleEl.ruleNumber}] : ${ruleEl.ruleName}`,
      value: ruleEl,
    }));
    setRuleOptions(rulesCopy);

    const cateCopy = {
      label: `[${categoryNumber}] : ${category}`,
      value: e.value,
    };
    setTargetCate(cateCopy);
  };

  const handleRule = async e => {
    await axiosAuth
      .get(`/api/rule/ruledualone/${e.value._id}`)
      .then(res => {
        setTargetRule(res.data);
      })
      .catch(err => console.log(err));
  };

  const handleLanguage = async value => {
    setLang(value);
    await axiosAuth.get(`/api/category/all/${value}`).then(res => {
      const optionsCopy = res.data.map(cateEl => ({
        label: `[${cateEl.categoryNumber}] : ${cateEl.category}`,
        value: cateEl,
      }));
      setCateOptions(optionsCopy);
      const targetCateCopy = res.data.filter(el =>
        el.categoryNumber.match(
          targetCate.value.categoryNumber.replace('(E)', '')
        )
      )[0];
      setTargetCate({
        label: `[${targetCateCopy.categoryNumber}] : ${targetCateCopy.category}`,
        value: targetCateCopy,
      });
      const rulesCopy = targetCateCopy.ruleRef.map(ruleEl => ({
        label: `[${ruleEl.ruleNumber}] : ${ruleEl.ruleName}`,
        value: ruleEl,
      }));
      setRuleOptions(rulesCopy);
      setTargetRule({
        ruleNumber: '',
        ruleName: '',
        lan: '',
        chapterRef: [],
        formRef: [],
      });
    });
  };

  return (
    <div className='dual__right'>
      <div className='rightHeader'>
        <div className='rightHeader__lanBtn'>
          <label htmlFor='dualright-lanbtns'>
            {/* 언어 */}
            <div id='dualright-lanbtns'>
              <button
                type='button'
                onClick={() => handleLanguage('kor')}
                className={lang === 'kor' ? 'active' : ''}>
                KOREAN
              </button>
              <button
                type='button'
                onClick={() => handleLanguage('eng')}
                className={lang === 'eng' ? 'active' : ''}>
                ENGLISH
              </button>
            </div>
          </label>
        </div>
        <div className='rightHeader__ruleCateBtn'>
          <label htmlFor='dualright-cateselect'>
            <span>{t('ruleDual.category')} :</span>
            <Select
              className='rightHeader__ruleCateBtn__cateselect'
              id='dualright-cateselect'
              isSearchable
              value={targetCate}
              placeholder={t('ruleDual.pleaseSelect1')}
              options={cateOptions}
              onChange={handleCategory}
              styles={blackStyles}
            />
          </label>
          <label htmlFor='dualright-ruleselect'>
            <span>{t('ruleDual.rule')} :</span>
            <Select
              className='rightHeader__ruleselect'
              id='dualright-ruleselect'
              isSearchable
              placeholder={t('ruleDual.pleaseSelect2')}
              value={
                targetRule.ruleNumber !== ''
                  ? {
                      label: `[${targetRule.ruleNumber}] : ${targetRule.ruleName}`,
                      value: targetRule._id,
                    }
                  : null
              }
              options={ruleOptions}
              onChange={handleRule}
              styles={blackStyles}
            />
          </label>
        </div>
      </div>
      <RuleDualForm targetRule={targetRule} />
    </div>
  );
};

export default RuleDualRight;
