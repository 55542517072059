import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import {
  BiFolderPlus,
  BiSolidCaretRightSquare,
  BiSolidStar,
  BiSubdirectoryRight,
} from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const OrgItem = ({ org, hasManuals, sethasManuals }) => {
  const { t } = useTranslation();
  const addOneHasManual = orgObj => {
    if (hasManuals.some(hm => hm._id === orgObj._id)) {
      toast(t('components.teamManual.team-already'));
      return;
    }
    const targetOrg = { ...orgObj, new: true };
    const hasManualsCopy = [...hasManuals, targetOrg];
    sethasManuals(hasManualsCopy);
  };

  return (
    <div className='list__orgs__item'>
      <div className='list__orgs__item__title' aria-hidden='true'>
        <span>
          {(() => {
            if (!org.path) {
              return <HiOutlineOfficeBuilding />;
            }
            if (org.path === ',hos21,') {
              return <BiSolidCaretRightSquare size={23} />;
            }
            return <BiSubdirectoryRight size={23} />;
          })()}
        </span>
        <span>{org.name}</span>
        <span>
          {(() => {
            if (!org.path) {
              return null;
            }
            if (org.hasManual) {
              return <BiSolidStar color='orange' />;
            }
            return (
              <BiFolderPlus
                size={23}
                color='green'
                onClick={() => addOneHasManual(org)}
              />
            );
          })()}
        </span>
      </div>
      <div className='orgchild'>
        {org.children?.length > 0 &&
          org.children.map(child => (
            <OrgItem
              key={`org_${child._id}`}
              org={child}
              hasManuals={hasManuals}
              sethasManuals={sethasManuals}
            />
          ))}
      </div>
    </div>
  );
};

const TeamManualAdminAdd = ({ hasManuals, sethasManuals }) => {
  const [orgs, setOrgs] = useState([]);
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    // 즐겨찾기 결재선
    const updateOrgList = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/manualper/tree/hos21`,
        });
        setOrgs(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    updateOrgList();
  }, []);

  return (
    <div className='teammanualadmin__orglist'>
      <OrgItem
        org={orgs}
        key={`org_${orgs._id}`}
        hasManuals={hasManuals}
        sethasManuals={sethasManuals}
      />
    </div>
  );
};

export default TeamManualAdminAdd;
