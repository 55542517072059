import { formatInTimeZone, toDate, utcToZonedTime } from 'date-fns-tz';

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatDate = (
  date,
  formatStr = 'yyyy-MM-dd',
  timezone = getUserTimezone()
) => {
  if (!date) {
    return '2023-01-01';
  }
  const zonedDate = utcToZonedTime(toDate(date, { timeZone: 'UTC' }), timezone);
  return formatInTimeZone(zonedDate, timezone, formatStr);
};

export const formatFullDate = (
  date,
  formatStr = 'yyyy-MM-dd HH:mm:ss',
  timezone = getUserTimezone()
) => {
  const zonedDate = utcToZonedTime(toDate(date, { timeZone: 'UTC' }), timezone);
  return formatInTimeZone(zonedDate, timezone, formatStr);
};
