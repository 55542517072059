import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../scss/MyPage/ProfileUpdate.scss';
import { toast } from 'react-toastify';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import ProfileValid from './ProfileValid';
import useAuth from '../../common/hooks/useAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import CustomFileInput from '../DatePicker/FileInput';

const ProfileUpdate = ({ myProfile }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [checkText, setCheckText] = useState('');
  const [profileUpdate, setProfileUpdate] = useState({
    username: '',
    profileImg: '',
    signatureImg: '',
    contact: '',
    officecontact: '',
    position: '',
    department: '',
  });
  const [profileImgs, setProfileImgs] = useState([]);
  const [signatureImgs, setSignatureImgs] = useState([]);

  const imgRef = useRef();
  const sigImgRef = useRef();

  const onChangeProfile = e => {
    setIsModified(true);
    setProfileUpdate({ ...profileUpdate, [e.target.name]: e.target.value });
  };

  const passwordChange = e => {
    setIsModified(true);
    setPassword(e.target.value);
  };

  const passwordConfirmChange = e => {
    setIsModified(true);
    setPasswordConfirm(e.target.value);
  };

  const handleChangeProfileImg = () => {
    const files = Array.from(imgRef.current.files || []);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onloadend = () => {
      setProfileImgs(files);
      setProfileUpdate({ ...profileUpdate, profileImg: fileReader.result });
    };
  };

  const handleChangeSignatureImg = () => {
    const sigFiles = Array.from(sigImgRef.current.files || []);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(sigFiles[0]);
    fileReader.onloadend = () => {
      setSignatureImgs(sigFiles);
      setProfileUpdate({ ...profileUpdate, signatureImg: fileReader.result });
    };
  };

  const submitProfileImg = async () => {
    const formData = new FormData();
    if (profileImgs && profileImgs.length > 0) {
      formData.append('file', profileImgs[0]);
    }
    formData.append('userId', JSON.stringify(auth.id));
    try {
      const response = await axiosMultipart.post(
        '/api/mypage/profileImg',
        formData
      );
      if (response.status === 200) {
        toast(t('mypage.profileUpdate.profilephoto-success'));
        localStorage.setItem('profileImg', JSON.stringify(response.data));
        navigate('/mypage');
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitSignatureImg = async () => {
    const formData = new FormData();
    if (signatureImgs && signatureImgs.length > 0) {
      formData.append('file', signatureImgs[0]);
    }
    formData.append('userId', JSON.stringify(auth.id));
    try {
      const response = await axiosMultipart.post(
        '/api/mypage/signature',
        formData
      );
      if (response.status === 200) {
        toast(t('mypage.profileUpdate.signature-success'));
        navigate('/mypage');
        window.location.reload();
      }
    } catch (err) {
      toast(t('mypage.profileUpdate.manager-inquiry'));
      console.log(err);
    }
  };

  const checkedPassword = () => {
    if (!password || !passwordConfirm) {
      setCheckText(
        <span style={{ color: 'orange' }}>
          {t('mypage.profileUpdate.enterNewPassword')}
        </span>
      );
    } else if (password !== passwordConfirm) {
      setCheckText(
        <span style={{ color: 'red' }}>
          {t('mypage.profileUpdate.nomatchPassword')}
        </span>
      );
    } else {
      setCheckText(t('mypage.profileUpdate.matchPassword'));
    }
  };

  const ProfileSave = async () => {
    if (!isModified) {
      toast(t('mypage.profileUpdate.edit-input'));
      return;
    }
    if (password !== passwordConfirm) {
      toast(
        `${t('mypage.profileUpdate.password-no')}\n${t(
          'mypage.profileUpdate.check-again'
        )}`
      );
      return;
    }
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/mypage/edit/user',
        data: {
          email: myProfile.email,
          username: profileUpdate.username
            ? profileUpdate.username
            : myProfile.username,
          password: passwordConfirm === '' ? null : passwordConfirm,
          officecontact: profileUpdate.officecontact
            ? profileUpdate.officecontact
            : myProfile.officecontact,
          contact: profileUpdate.contact
            ? profileUpdate.contact
            : myProfile.contact,
          position: profileUpdate.position
            ? profileUpdate.position
            : myProfile.position,
        },
      });
      if (res.status === 200 && res.data.pwChanged) {
        const formatDate = timezoneFormat(
          utcToZonedTime(
            toDate(res.data.pwChanged.date, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd HH:mm:ss',
          { timeZone: timezone }
        );
        alert(
          `${t('mypage.profileUpdate.profile-success')} \n${t(
            'mypage.profileUpdate.password-changeDate'
          )}: ${formatDate}`
        );
      } else if (res.status === 200 && !res.data.pwChanged) {
        alert(t('mypage.profileUpdate.profile-success'));
      }
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='profileUpdate'>
      {!checked ? (
        <ProfileValid email={myProfile.email} setChecked={setChecked} />
      ) : (
        <div className='profileUpdate__main'>
          <div className='profileUpdate__main__content'>
            <div className='inputBox__left'>
              <div className='inputprofileimg'>
                <div className='inputprofileimg__body'>
                  <div className='inputprofileimg__container'>
                    {profileUpdate.profileImg &&
                    profileUpdate.profileImg !== '' ? (
                      <img
                        src={
                          profileUpdate.profileImg ||
                          `${process.env.PUBLIC_URL}/images/defaultuser.png`
                        }
                        alt='profileImg'
                      />
                    ) : (
                      <img
                        src={
                          myProfile.profileImg && myProfile.profileImg !== ''
                            ? myProfile.profileImg
                            : `${process.env.PUBLIC_URL}/images/defaultuser.png`
                        }
                        alt='profileImg'
                      />
                    )}
                  </div>
                  <div className='inputprofileimg__btns'>
                    <form>
                      <label
                        className='inputprofileimg-label'
                        htmlFor='formfile__profileimg'>
                        <span className='btn'>
                          {t('mypage.profileUpdate.imageUpload')}
                        </span>
                      </label>
                      <input
                        style={{ display: 'none' }}
                        type='file'
                        name='profileImg'
                        id='formfile__profileimg'
                        accept='image/*'
                        multiple
                        ref={imgRef}
                        onChange={handleChangeProfileImg}
                      />
                    </form>
                  </div>
                  {profileUpdate.profileImg && profileUpdate.profileImg !== '' && (
                    <>
                      <div>
                        <Button
                          className='inputprofileimg__submit'
                          onClick={submitProfileImg}>
                          {t('button.save')}
                        </Button>
                      </div>
                      <div>
                        <span>{t('mypage.profileUpdate.isFake')}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='inputsignatureimg'>
                <div className='inputsignatureimg__title'>
                  <span className='title'>
                    {t('mypage.profileUpdate.signatureSetting')}
                  </span>
                </div>
                <div className='inputsignatureimg__body'>
                  <div className='inputsignatureimg__wrap'>
                    <div className='inputsignatureimg__container'>
                      {profileUpdate.signatureImg &&
                      profileUpdate.signatureImg !== '' ? (
                        <img
                          src={
                            profileUpdate.signatureImg ||
                            `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                          }
                          className='inputsignatureimg__imgbox shadow-4'
                          alt='signatureImg'
                        />
                      ) : (
                        <img
                          src={
                            myProfile.signatureImg &&
                            myProfile.signatureImg !== ''
                              ? myProfile.signatureImg
                              : `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                          }
                          className='inputsignatureimg__imgbox shadow-4'
                          alt='signatureImg'
                        />
                      )}
                    </div>
                    <div className='inputsignatureimg__uploadinfo'>
                      <div>- {t('mypage.profileUpdate.fileSize')} : 46X46</div>
                      <div>
                        - {t('mypage.profileUpdate.fileExt')} : .jpg, .jpeg,
                        .png
                      </div>
                    </div>
                  </div>
                  <div className='inputsignatureimg__btns'>
                    <Form.Group
                      controlId='formFileMultiple'
                      encType='multipart/form-data'>
                      <Form.Control
                        type='file'
                        name='images'
                        accept='image/*'
                        multiple
                        ref={sigImgRef}
                        placeholder='EDIT IMAGE'
                        onChange={handleChangeSignatureImg}
                      />
                      {/* <CustomFileInput
                        ref={sigImgRef}
                        name='images'
                        accept='image/*'
                        multiple
                        onChange={handleChangeSignatureImg}
                        style={{ width: '400px' }}
                      /> */}
                    </Form.Group>

                    {profileUpdate && profileUpdate.signatureImg !== '' && (
                      <Button onClick={submitSignatureImg}>
                        {t('button.update')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <span className='centerLine' />
            <div className='inputBox__right'>
              <form>
                <table>
                  <tbody className='inputBox'>
                    <tr className='inputContent'>
                      <th>
                        <span className='title'>{t('mypage.username')} </span>
                      </th>
                      <td>
                        <input
                          type='text'
                          defaultValue={myProfile.username || ''}
                          name='username'
                          onChange={onChangeProfile}
                        />
                      </td>
                    </tr>
                    <tr className='inputContent'>
                      <th>
                        <span className='title'>
                          {t('mypage.profileUpdate.newPassword')}{' '}
                        </span>
                      </th>
                      <td>
                        <span>
                          <input
                            type='password'
                            name='password'
                            value={password || ''}
                            onChange={passwordChange}
                            placeholder={t(
                              'mypage.profileUpdate.enterNewPassword'
                            )}
                            autoComplete='off'
                          />
                        </span>
                      </td>
                    </tr>
                    <tr className='inputContent'>
                      <th>
                        <span className='title'>
                          {t('mypage.profileUpdate.againPassword')}
                        </span>
                      </th>
                      <td>
                        <span>
                          <input
                            className='inputCheck'
                            type='password'
                            name='passwordConfirm'
                            value={passwordConfirm || ''}
                            onChange={passwordConfirmChange}
                            placeholder={t(
                              'mypage.profileUpdate.enterAgainPassword'
                            )}
                            autoComplete='off'
                          />
                        </span>
                      </td>
                      <td>
                        <Button onClick={checkedPassword}>
                          {t('button.apply')}
                        </Button>
                        <span className='checkText'>{checkText}</span>
                      </td>
                    </tr>
                    <tr className='inputContent'>
                      <th>
                        <span className='title'>{t('mypage.contact')} </span>
                      </th>
                      <td>
                        <input
                          type='text'
                          defaultValue={myProfile.contact || ''}
                          name='contact'
                          onChange={onChangeProfile}
                        />
                      </td>
                    </tr>
                    <tr className='inputContent'>
                      <th>
                        <span className='title'>
                          {t('mypage.officecontact')}{' '}
                        </span>
                      </th>
                      <td>
                        <input
                          type='text'
                          defaultValue={myProfile.officecontact || ''}
                          name='officecontact'
                          onChange={onChangeProfile}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
              <div className='profileUpdate__button'>
                <Button
                  className='profileUpdate__button'
                  type='submit'
                  onClick={ProfileSave}>
                  {t('button.edit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileUpdate;
