import React, { useEffect, useRef, useState } from 'react';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { Button, Modal, Form as BsForm, InputGroup } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import '../../scss/RuleInfo/Rule.scss';
import { toast } from 'react-toastify';
import {
  BsInfoCircle,
  BsLayoutSplit,
  BsSquareHalf,
  BsWindow,
  BsPrinter,
  BsSearch,
  BsFillCaretUpFill,
  BsListOl,
} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import parse from 'html-react-parser';
import RuleBreadCrumb from './RuleBreadCrumb';
import RuleNoticeModal from './RuleNoticeModal';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAuth from '../../common/hooks/useAuth';
import Roles from '../../common/auth/Roles';
import RuleOldSelectBox from './RuleOldSelectBox';

const Rule = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { t } = useTranslation();
  const { auth } = useAuth();
  const { ruleNum, chapterNumber, searchText } = useParams();
  const axiosAuth = useAxiosAuth();
  const navi = useNavigate();
  const [onclickModal, setOnclickModal] = useState(false);
  const [searchBtn, setSearchBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [targetRule, setTargetRule] = useState(null);
  const [breadCrumbsData, setBreadCrumbsData] = useState({
    _id: '',
    ruleNumber: '',
    ruleName: '',
    refCategory: {
      id: '',
      category: '',
    },
  });
  const [datesData, setDatesData] = useState({
    _id: '',
    lan: '',
    ruleNumber: '',
    revisedAt: '',
  });
  const [contentData, setContentData] = useState([]);
  const [content, setContent] = useState([]);
  const [btnIndex, setbtnIndex] = useState(null);
  const [openIndex, setOpenIndex] = useState(null);
  const [bCount, setBCount] = useState({ current: 1, total: 0 });

  const handleToggle = index => {
    setOpenIndex(index === openIndex ? null : index);
  };

  const onClickBtn = () => {
    setOpen(!open);
  };

  const showModal = () => {
    setModalOpen(true);
  };

  // 챕터 클릭 시 해당 content scroll 기능
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      setbtnIndex(null);
      document.getElementById('printArea_wrapper').scrollIntoView(true);
    }
  }, [ruleNum]);

  const handleTargetScroll = (e, index) => {
    setSearchBtn(false);
    if (scrollRef.current && scrollRef.current.hasChildNodes()) {
      if (searchText) {
        const bElements = document.getElementsByTagName('b');
        if (!e.target) {
          if (bCount.current === 1 && bElements.length > 0) {
            bElements[0].scrollIntoView({
              behavior: 'auto',
              block: 'start',
              inline: 'start',
            });
          }
        } else {
          scrollRef.current.children[index].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
          setbtnIndex(index);
        }
        setBCount(prev => ({
          ...prev,
          total: bElements.length,
        }));
      } else {
        scrollRef.current.children[index].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        setbtnIndex(index);
      }
    }
  };

  useEffect(() => {
    if (onclickModal) {
      setbtnIndex(null);
    }
  }, [onclickModal]);

  const handleMinusScroll = () => {
    if (
      document.getElementsByTagName('b') &&
      document.getElementsByTagName('b').length > 0 &&
      bCount.current > 1
    ) {
      if (!onclickModal) {
        document.getElementsByTagName('b')[bCount.current - 2].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        setBCount(prev => ({ ...prev, current: prev.current - 1 }));
        setSearchBtn(true);
      }
    }
  };
  const handlePlusScroll = () => {
    if (
      document.getElementsByTagName('b') &&
      document.getElementsByTagName('b').length >= 0 &&
      bCount.current < bCount.total
    ) {
      if (bCount.current !== bCount.total && !onclickModal) {
        // (bCount.current === bCount.total)인 경우 스크롤 이벤트 막음
        document.getElementsByTagName('b')[bCount.current].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        setBCount(prev => ({ ...prev, current: prev.current + 1 }));
        setSearchBtn(true);
      }
    }
  };

  const replaceStrong = (text, con) => {
    const regex = new RegExp(text, 'gi');
    const cateIndex = con.toLowerCase().indexOf(text.toLowerCase());
    const originStr = con.slice(cateIndex, cateIndex + text.length);
    const contentStrong = con.replace(regex, `<b>${originStr}</b>`);
    return contentStrong;
  };

  useEffect(() => {
    const initRule = async () => {
      try {
        const res1 = await axiosAuth({
          method: 'GET',
          url: `/api/rule/${ruleNum}`,
        });
        const {
          chapterRef,
          _id,
          ruleNumber,
          ruleName,
          refCategory,
          lan,
          revisedAt,
        } = res1.data;
        setTargetRule(res1.data);
        setBreadCrumbsData(prev => ({
          ...prev,
          _id,
          ruleNumber,
          ruleName,
          refCategory,
        }));
        setDatesData(prev => ({
          ...prev,
          _id,
          lan,
          ruleNumber,
          revisedAt,
        }));
        setContentData(chapterRef);
        if (chapterNumber && searchText) {
          const targetindex = chapterRef.findIndex(
            el => el.chapterNumber === chapterNumber
          );
          const changeChap = {
            ...chapterRef[targetindex],
            content: replaceStrong(searchText, chapterRef[targetindex].content),
          };
          chapterRef.splice(targetindex, 1, changeChap);
        }

        setContent(chapterRef);
        if (chapterNumber) {
          const targetindex = chapterRef.findIndex(
            el => el.chapterNumber === chapterNumber
          );
          handleTargetScroll(targetindex);
        }
      } catch (err1) {
        console.log(`error res1 : ${err1}`);
      }
    };
    initRule();
  }, [ruleNum]);

  const dual = () => {
    navi('/dual', {
      state: {
        lan: targetRule.lan,
        category: targetRule.refCategory.category,
        ruleNumber: targetRule.ruleNumber.replace('(E)', ''),
      },
    });
    console.log(targetRule);
  };
  const compare = () => {
    navi(`/compare/${targetRule.ruleNumber}`, {
      state: {
        ...targetRule,
      },
    });
  };

  const printRef = useRef(null);

  const handlePrint = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/rule/print',
        data: {
          _id: targetRule._id,
          targetNumber: targetRule.ruleNumber,
          targetName: targetRule.ruleName,
        },
      });

      if (res.status === 200) {
        const revisedDate = timezoneFormat(
          utcToZonedTime(
            toDate(targetRule.revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        );
        const { refCategory } = breadCrumbsData;
        let printTitle = `[${refCategory.id.categoryNumber}] ${refCategory.id.category} > [${targetRule.ruleNumber}] ${targetRule.ruleName}`;
        if (printTitle.length > 75) {
          printTitle = `[${refCategory.id.categoryNumber}] ${refCategory.id.category} > <br/>[${targetRule.ruleNumber}] ${targetRule.ruleName}`;
        }
        const footerText = `${t('rulePrint.text1')}<br/>${t(
          'rulePrint.text2'
        )}`;
        const contentDiv = document.getElementById('overide-css-rulecon');
        const pri = document.getElementById(
          'print-data-container'
        ).contentWindow;
        pri.document.open();
        const html = `<html><head><title>${printTitle}</title><link rel="stylesheet" href="${process.env.PUBLIC_URL}/ck-styles.css" type="text/css"><link rel="stylesheet" href="${process.env.PUBLIC_URL}/printfont.css" type="text/css"></head><body><table><thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead><tbody style="font-size: 14px; font-weight: 800;"><tr><td><div>${contentDiv.innerHTML}</div></td></tr></tbody><tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot></table><div class="header"><table><tbody><tr><td width="25%">TITLE</td><td width="75%">${printTitle}</td></tr><tr><td width="25%" class="header_first">Effective Date</td><td width="75%">${revisedDate}</td></tr></tbody></table></div><div class="footer"><div class="footer_imgcontainer"><img class="footer_img" src="${process.env.PUBLIC_URL}/images/hmm_watermark.png" alt="hmm footer image" /></div><div class="footer_disclaimer">${footerText}
        </div></div></body></html>`;
        pri.document.write(html);

        const pageDivs = pri.document.querySelectorAll(
          '.ck-content__pagebreak'
        );
        if (pageDivs) {
          const pageDivArr = Array.from(pageDivs);
          const watermarkDiv = pri.document.createElement('div');
          watermarkDiv.className = 'watermark';
          watermarkDiv.innerHTML = `<img
              class='watermark_img'
              src='${process.env.PUBLIC_URL}/images/hmm_watermark.png'
              alt='hmm watermark'
              style='opacity: 0.15;'
            />`;
          pageDivArr.forEach(div => {
            div.appendChild(watermarkDiv);
          });
        }
        pri.document.close();
        pri.document.head.lastChild.addEventListener(
          'load',
          () => {
            pri.focus();
            pri.print();
          },
          0
        );
        return;
      }
    } catch (err) {
      toast(t('rulePrint.permission-no'));
    }
  };
  const [searchStr, setSearchStr] = useState('');

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  const contentFilter = text => {
    if (text === '') {
      setContent(contentData);
    } else {
      const contentCopy = contentData.filter(el => el.content.includes(text));
      setContent(contentCopy);
    }
  };

  useEffect(() => {
    contentFilter(searchStr.toUpperCase());
  }, [searchStr]);

  return (
    <div className='ruleinfo__rule'>
      <div className='ruleinfo__rule__header'>
        <div>
          <RuleBreadCrumb breadCrumbsData={breadCrumbsData} />
        </div>
        {searchText && (
          <div className='ruleinfo__rule__header__targetguide'>
            <div className='ruleinfo__rule__header__targetguide__textBox'>
              <span>{t('searchbar.search-text')} : </span>
              <span>{` ${searchText}`}</span>
              <span>{`(${bCount.current}/${bCount.total})`}</span>
            </div>
            <div className='ruleinfo__rule__header__targetguide__btnBox'>
              <Button
                variant='secondary'
                type='button'
                onClick={() => handleMinusScroll()}>
                <MdOutlineKeyboardArrowUp size={20} />
              </Button>
              <Button
                variant='secondary'
                type='button'
                onClick={() => handlePlusScroll()}>
                <MdOutlineKeyboardArrowDown size={20} />
              </Button>
            </div>
          </div>
        )}
      </div>
      <nav className='rule__header'>
        <RuleOldSelectBox datesData={datesData} />
        <div className='rule__header__btns'>
          <Button
            className='btns__item'
            style={{
              color: `${onclickModal ? 'orange' : '#fff'}`,
              border: 'none',
            }}
            onClick={() => setOnclickModal(prev => !prev)}>
            <span> {t('button.chapter-info')}</span>
            <span>
              <BsListOl />
            </span>
          </Button>
          <Button
            className='btns__item'
            data-bs-toggle='modal'
            data-bs-target='#staticBackdrop'
            onClick={onClickBtn}>
            <span className='hide__name'>{t('button.revision-info')}</span>
            <span className='hide__icon'>
              <BsInfoCircle />
            </span>
          </Button>
          {open ? (
            <Modal
              className='modal__revstatus'
              show={open}
              size='xl'
              scrollable
              onHide={setOpen}
              backdrop='static'
              keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title className='modal__revstatus__title'>
                  [{targetRule.ruleNumber}
                  {'  '}
                  {targetRule.ruleName}]{'  '}
                  {t('button.revision-info')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='modal__revstatus__body'>
                <RuleNoticeModal ruleId={targetRule._id} />
              </Modal.Body>
            </Modal>
          ) : null}
          <Button className='btns__item' onClick={dual}>
            <span className='hide__name'>{t('button.dual')}</span>
            <span className='hide__icon'>
              <BsLayoutSplit />
            </span>
          </Button>
          <Button className='btns__item' onClick={compare}>
            <span className='hide__name'>{t('button.compare')}</span>
            <span className='hide__icon'>
              <BsSquareHalf />
            </span>
          </Button>
          <Button className='btns__item' onClick={showModal}>
            <span className='hide__name'>{t('button.view-all')}</span>
            <span className='hide__icon'>
              <BsWindow />
            </span>
          </Button>
          {modalOpen && content && (
            <Modal
              show={modalOpen}
              onHide={setModalOpen}
              size='xl'
              backdrop='static'
              id='showModal'>
              <Modal.Header closeButton className='showModal__title'>
                {targetRule && targetRule.ruleName}
              </Modal.Header>
              <Modal.Body className='showModal__body'>
                {content &&
                  content.map(el => (
                    <div className='showModal__body__content' key={el._id}>
                      {parse(el.content)}
                    </div>
                  ))}
              </Modal.Body>
            </Modal>
          )}
          {auth.roles.includes(Roles.DEVELOPER) ||
          auth.roles.includes(Roles.ADMIN) ||
          targetRule?.per.print ? (
            <Button className='btns__item' onClick={handlePrint}>
              <span className='hide__name'>{t('button.print')}</span>
              <span className='hide__icon'>
                <BsPrinter />
              </span>
            </Button>
          ) : null}
        </div>
      </nav>
      <div className='rule__body'>
        {/* 챕터 검색 */}
        <div className='rule__body__sidenav'>
          <InputGroup id='serchbar' className='sidenav__searchbar'>
            <InputGroup.Text
              id='basic-addon1'
              className='sidenav__searchbar__icon'>
              <BsSearch className='SearchBar__btn__icon' />
            </InputGroup.Text>
            <BsForm.Control
              value={searchStr}
              onChange={handlesearchStr}
              className='sidenav__searchbar__form'
              aria-label='Text input with dropdown button'
            />
          </InputGroup>
          <div className='sidenav__title'>
            <span style={{ fontSize: '0.9rem' }}>
              {targetRule && targetRule.ruleName}
            </span>
          </div>
          {/* 챕터 목록 */}
          <div className='sidenav__chapters'>
            <div className='sidenav__chapters__chapterBox'>
              {content &&
                content.map((chapter, i) => (
                  <div key={chapter._id}>
                    <div className='sidenav__chapters__chapterBox__chapterBtn'>
                      <BsFillCaretUpFill
                        className={`sidenav__chapters__togglebtn ${
                          openIndex === i ? 'open' : 'hide'
                        }`}
                        key={chapter._id}
                        onClick={() => handleToggle(i)}
                      />
                      <Button
                        onClick={e => handleTargetScroll(e, i)}
                        className={`sidenav__chapters__item ${
                          btnIndex === i && !onclickModal && !searchBtn
                            ? 'active'
                            : ''
                        }`}>
                        {`${chapter.chapterName}`}
                      </Button>
                    </div>
                    <div
                      className={`sidenav__chapters__subBox ${
                        openIndex === i ? 'open' : 'hide'
                      }`}>
                      {openIndex === i && (
                        <div className='sidenav__chapters__subContent'>
                          {(chapter.chapterContents &&
                            chapter.chapterContents.children.map(el => (
                              <div key={el}>{el}</div>
                            ))) ||
                            `chapterName ${t('searchbar.no-chapterName')}`}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* rule content */}
        {onclickModal ? (
          <div className='rule__body__content__chapterBox'>
            <table className='table'>
              <thead>
                <tr>
                  <td width='6%'>{t('table.index')}</td>
                  <td width='16%'>{t('table.code-num')}</td>
                  <td width='48%'>{t('table.title')}</td>
                  <td width='15%'>{t('table.revision-date')}</td>
                  <td width='15%'>{t('table.remark')}</td>
                </tr>
              </thead>
              <tbody>
                {contentData &&
                  contentData.map((el, i) => (
                    <tr key={el.chapterNumber}>
                      <td>{i + 1}</td>
                      <td align='left'>{el.chapterNumber}</td>
                      <td align='left'>{el.chapterName}</td>
                      {el.createdAt ? (
                        <td>
                          {timezoneFormat(
                            utcToZonedTime(
                              toDate(el.createdAt, {
                                timeZone: 'UTC',
                              }),
                              timezone
                            ),
                            'yyyy-MM-dd',
                            { timeZone: timezone }
                          )}
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                      <td>{el.reamark ? el.reamark : '-'}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div id='printArea' className='rule__body__content'>
            <div ref={printRef} id='printArea_wrapper' className='pri'>
              <div id='override-css-ruletitle' className='content__title'>
                <span>{targetRule && targetRule.ruleName}</span>
              </div>
              <div
                ref={scrollRef}
                id='overide-css-rulecon'
                className='content__items'>
                {/* <div id='print-title' /> */}
                {content &&
                  content.map((el, i) => (
                    <div
                      key={el._id}
                      className='ck ck-content ck-content__pagebreak rulecommon'
                      style={i !== 0 ? { breakBefore: 'page' } : {}}>
                      {parse(el.content)}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
      <iframe
        id='print-data-container'
        title='print-rule'
        aria-hidden='true'
        tabIndex='-1'
      />
    </div>
  );
};

export default Rule;
