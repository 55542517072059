import React, { useEffect, useRef, useState } from 'react';
import '../../scss/Dev/DevForm.scss';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { format, startOfDay } from 'date-fns';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import DevDcnSet from './DevDcnSet';
import DatePicker from '../DatePicker/DatePicker';
import CustomFileInput from '../DatePicker/FileInput';

const DevForm = () => {
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const inputFile = useRef(null);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [lan, setLan] = useState('kor');
  const [categoryOps, setCategoryOps] = useState([]);
  const [category, setCategory] = useState({ label: '', value: '' });
  const [rule, setRule] = useState({
    _id: '',
    ruleNumber: '',
    ruleName: '',
    revisedAt: '',
  });
  const [ruleOps, setRuleOps] = useState([]);
  const [formOps, setFormOps] = useState([]);

  const [targetForm, setTargetForm] = useState({
    _id: '',
    formNumber: '',
    formName: '',
  });
  const [currRuleDate, setCurrRuleDate] = useState('');
  const [oldRuleDate, setOldRuleDate] = useState('');
  const [file, setFile] = useState();
  const [error, setError] = useState(null);
  const [dcnOpen, setDcnOpen] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const initcategorySelect = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/forms/${lan || 'lan'}`,
      });
      const cateOptionsArr = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setCategoryOps(cateOptionsArr);
    };
    initcategorySelect();
  }, [lan]);

  const handleCategoryValue = async e => {
    setCategory(e);
    const response = await axiosAuth({
      method: 'GET',
      url: `/api/category/${e.value._id}/${auth.id}`,
    });
    const { ruleRef } = response.data;
    const ruleOptionsArr = ruleRef.map(ruleOption => ({
      label: `${ruleOption.ruleNumber} : ${ruleOption.ruleName}`,
      value: ruleOption,
    }));
    setRuleOps(ruleOptionsArr);
  };

  const handleRuleValue = async e => {
    // chapter select options 업데이트
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/modrule/rule',
        data: { id: e.value._id },
      });
      const { formRef, revisedAt } = res.data;
      setRule({
        ...rule,
        ...res.data,
        revisedAt: timezoneFormat(
          utcToZonedTime(
            toDate(revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        ),
      });
      const formOptionsCopy = formRef.map(formOption => ({
        label: formOption.formNumber,
        value: formOption,
      }));
      setFormOps(formOptionsCopy);
    } catch (err) {
      console.log(err);
    }
  };

  const handleformValue = async e => {
    try {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/form/${e.value._id}`,
      });

      setTargetForm(prev => ({
        ...prev,
        ...response.data,
        fileType: response.data.docType,
      }));
    } catch (err) {
      console.log(err);
      toast(t('modrule.chapter-communication-failed'));
    }
  };

  const formchapterSave = async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('ruleId', JSON.stringify(rule._id));
    formData.append('formId', JSON.stringify(targetForm._id));
    formData.append('formNumber', JSON.stringify(targetForm.formNumber));
    formData.append('formName', JSON.stringify(targetForm.formName));
    formData.append(
      'createdAt',
      JSON.stringify(
        format(startOfDay(new Date(rule.revisedAt)), 'yyyy-MM-dd HH:mm:ss')
      )
    );
    formData.append('lan', JSON.stringify(targetForm.lan));

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/modRule/entryform',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      if (res.status === 200) {
        setTargetForm({
          _id: '',
          formNumber: '',
          formName: '',
        });
        setFile(null);
        // toast('form 한건 등록');
        toast(
          `(${res.status})${category.value} - ${t(
            'modrule.success-chap-amend'
          )}`
        );
      }

      // navi(`/forms/form/${id}`);
    } catch (err) {
      setError(err);
    }
  };

  const createOldRuleSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/oldrule/create',
        data: {
          newRuleId: rule._id,
          oldRuleDate: new Date(oldRuleDate),
        },
      });
      toast(`(${res.data.ruleNumber}) - ${t('modrule.create-old-rule')}`);
      setOldRuleDate('');
    } catch (err) {
      console.log(err);
      toast(t('modrule.create-old-rule-failed'));
    }
  };

  const editCurrRuleDate = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modRule/changedate',
        data: {
          newRuleId: rule._id,
          currRuleDate: new Date(currRuleDate),
        },
      });
      toast(
        `${t('modrule.rule-date')} ${res.data.message} - ${
          res.data.result.ruleNumber
        } : ${res.data.result.revisedAt}`
      );
      setCurrRuleDate('');
    } catch (err) {
      console.log(err);
      toast(t('modrule.edit-rule-date-failed'));
    }
  };

  const onChangeOldRuleDate = e => {
    setOldRuleDate(e.target.value);
  };

  const onChangeCurrRuleDate = e => {
    setCurrRuleDate(e.target.value);
  };

  const handleChangeFile = () => {
    setFile(inputFile.current.files[0]);
  };

  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  return (
    <div className='devform'>
      <div
        style={{
          width: '100%',
          height: '5vh',
          display: 'flex',
          gap: '1vw',
          paddingLeft: '1vw',
        }}>
        <ButtonGroup style={{ width: '10vw', height: '4vh' }}>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={radio.value}
              id={`radio-${idx}`}
              type='radio'
              variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
              name='radio'
              value={radio.value}
              checked={lan === radio.value}
              onChange={e => setLan(e.currentTarget.value)}>
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <div style={{ width: '20vw' }}>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.cate-placeholder')}</div>}
            isSearchable
            id='select-category'
            className='devform__header__selectcate'
            options={categoryOps}
            onChange={handleCategoryValue}
          />
        </div>
        <div style={{ width: '20vw' }}>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.select-form-separation')}</div>}
            isSearchable
            id='select-rule'
            className='devdraft__header__selectrule'
            options={ruleOps}
            onChange={handleRuleValue}
          />
        </div>
        <div style={{ width: '20vw' }}>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.select-form')}</div>}
            isSearchable
            id='select-form'
            className='devdraft__header__selectform'
            options={formOps}
            onChange={handleformValue}
          />
        </div>
        <div style={{ width: '10vw' }}>{`${t('table.enact-date')} : ${
          rule.revisedAt
        }`}</div>
      </div>
      <div
        style={{
          width: '100%',
          height: '4vh',
          display: 'flex',
          gap: '1vw',
          paddingLeft: '1vw',
        }}>
        <div style={{ display: 'flex' }}>
          <span>{t('ruleCompare.previousVer')} REVISEDAT</span>
          <DatePicker
            name='createdAt'
            startdate={oldRuleDate || ''}
            onChangeDate={onChangeOldRuleDate}
            language={i18n.language}
            style={{ marginLeft: '1vw', marginRight: '1vw' }}
            required
          />
          <Button
            style={{ width: '4vw' }}
            disabled={oldRuleDate === ''}
            onClick={createOldRuleSubmit}>
            {t('button.create')}
          </Button>
        </div>
        <div style={{ display: 'flex' }}>
          <span>{t('ruleCompare.latestVer')} REVISEDAT</span>

          <DatePicker
            name='createdAt'
            startdate={currRuleDate || ''}
            onChangeDate={onChangeCurrRuleDate}
            language={i18n.language}
            style={{ marginLeft: '1vw', marginRight: '1vw' }}
            required
          />
          <Button
            style={{ width: '4vw' }}
            disabled={currRuleDate === ''}
            onClick={editCurrRuleDate}>
            {t('button.edit')}
          </Button>
        </div>
        <div>
          <Button disabled={rule?._id === ''} onClick={() => setDcnOpen(true)}>
            {t('modrule.connect-old-rule-dcn')}
          </Button>
        </div>
        <div style={{ width: '30vw' }}>
          <Form.Group
            controlId='formFileMultiple'
            encType='multipart/form-data'
            className='mb-3 addchapter__main__sub__form'>
            {/* <Form.Control
              type='file'
              name='file'
              ref={inputFile}
              onChange={handleChangeFile}
              // className=' addchapter__main__sub__input'
            /> */}
            <CustomFileInput
              type='file'
              name='file'
              ref={inputFile}
              onChange={handleChangeFile}
              style={{ width: '100%' }}
            />
          </Form.Group>
          <p>{error && error.message}</p>
        </div>
      </div>
      <div>
        <div
          style={{ padding: '0.5vw' }}
          className='formNew__maincontent__viewerold__mainCon'>
          <DocViewer
            prefetchMethod='GET'
            documents={[targetForm]}
            pluginRenderers={DocViewerRenderers}
            config={{ header: { disableHeader: true } }}
            className='docviewer__container'
          />
        </div>
      </div>
      <div className='devdraft__main__footer'>
        <Button type='submit' onClick={formchapterSave}>
          {t('modrule.amend-draft')}
        </Button>
      </div>
      {dcnOpen && <DevDcnSet open={dcnOpen} setOpen={setDcnOpen} rule={rule} />}
    </div>
  );
};

export default DevForm;
