import Form from 'react-bootstrap/Form';
import React from 'react';
import { BsSearch } from 'react-icons/bs';
import { Button, InputGroup } from 'react-bootstrap';
import '../scss/Mobile/MobileSearchBar.scss';

const MobileSearchBar = ({ value, onChange, searchText, handleKeyDown }) => {
  return (
    <InputGroup className='MobileSearchBar'>
      <Form.Control
        value={value}
        onChange={onChange}
        className='MobileSearchBar__input'
        // placeholder='검색어를 입력하세요'
        aria-label="Recipient's username"
        aria-describedby='basic-addon2'
        onKeyDown={handleKeyDown}
      />
      <Button className='MobileSearchBar__btn' onClick={searchText}>
        <BsSearch className='MobileSearchBar__btn__icon' />
        {/* 검색 */}
      </Button>
    </InputGroup>
  );
};

export default MobileSearchBar;
