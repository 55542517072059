import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsFillCaretUpFill } from 'react-icons/bs';
import { HiOutlinePencil } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import '../../scss/GroupChart/OrganizationChart.scss';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import OrgMembers from './OrgMembers';

const OrgChartPage = () => {
  const [orgData, setOrgData] = useState([]);
  const [newOrgName, setNewOrgName] = useState([]);
  const [targetOrg, setTargetOrg] = useState(null);
  const [addOrgName, setAddOrgName] = useState([]);
  const [openOrg, setOpenOrg] = useState({});
  const [openSubToggle, setOpenSubToggle] = useState(false);
  const [openBox, setOpenBox] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [addChildrenOpen, setAddChildrenOpen] = useState(false);
  const [memberListPage, setMemberListPage] = useState(false);
  const navi = useNavigate();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  const handleToggleBtn = node => {
    setOpenOrg(node);
    setOpenSubToggle(prev => !prev);
  };

  const handleToggle = () => {
    setOpenBox(prev => !prev);
  };

  // 조직도 전체 불러오기
  useEffect(() => {
    const controller = new AbortController();
    const Chart = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/org/orgtree/${auth.company}`,
        });
        setOrgData(res.data);
      } catch (err) {
        if (err.response?.status === 401) {
          navi('/unauthorized');
        }
        console.log(err);
      }
    };
    Chart();
    return () => {
      controller.abort();
    };
  }, [isUpdate]);

  // 그룹 이름을 변경하는 코드
  const changeChartName = (chartToChange, newName) => {
    if (chartToChange) {
      setNewOrgName(newName);
    }
  };
  const NameChange = async chartToChange => {
    if (newOrgName) {
      try {
        const response = await axiosAuth({
          method: 'POST',
          url: '/api/org/edit',
          data: {
            _id: chartToChange._id,
            name: newOrgName,
          },
        });
        if (response.status === 200) {
          setIsUpdate(prev => !prev);
          toast(t('groupChart.orgchart.org-change'));
        }
      } catch (error) {
        console.log(error);
        toast(t('groupChart.orgchart.org-change-failed'));
      }
    } else {
      toast(t('groupChart.orgchart.re-write'));
    }
  };

  // 하위 그룹을 추가하는 코드
  const addChild = async () => {
    if (addOrgName) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/org/new',
          data: {
            paths:
              targetOrg && targetOrg.path
                ? `${targetOrg.path}${targetOrg._id},`
                : null,
            name: addOrgName,
            parentId: orgData._id,
          },
        });
        if (res.status === 200) {
          toast(t('groupChart.orgchart.add-org'));
          setIsUpdate(prev => !prev);
          setAddChildrenOpen(!addChildrenOpen);
          setAddOrgName('');
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      toast(t('groupChart.orgchart.re-write'));
    }
  };
  const handleAddChild = node => {
    setAddChildrenOpen(!addChildrenOpen);
    setTargetOrg(node);
  };
  const currentAddChild = e => {
    setAddOrgName(e.target.value);
  };

  // 조직을 삭제하는 코드
  const handleDelete = async node => {
    const { _id } = node;
    if (window.confirm(t('groupChart.orgchart.del-org-q'))) {
      try {
        const res = await axiosAuth({
          method: 'DELETE',
          url: `/api/org/byone/${_id}`,
        });
        if (res.status === 200) {
          setIsUpdate(prev => !prev);
          toast(t('groupChart.orgchart.del-org'));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // 해당 조직원리스트 불러오는 코드
  const handleSeeMore = async node => {
    setMemberListPage(true);
    navi(`/orgmembers/${node._id}`, {
      state: {
        orgName: node.name,
      },
    });
  };

  const renderChart = chartData => {
    return (
      <>
        <div className='OrgChart'>
          <div className='OrgChart__orgBox'>
            <div className='OrgChart__orgBox__mainCon'>
              <input
                type='text'
                defaultValue={chartData.name}
                onChange={e => changeChartName(chartData, e.target.value)}
              />
              <span>{chartData.num}</span>
              <Button variant='secondary' onClick={() => NameChange(chartData)}>
                <HiOutlinePencil
                  title={t('groupChart.orgchart.org-name-edit')}
                  size={16}
                />
              </Button>
              <Button
                variant='secondary'
                onClick={() => handleToggleBtn(chartData)}
                className='OrgChart__orgBox__mainCon__toggle'>
                <BsFillCaretUpFill
                  size={16}
                  className={`toggleBox ${
                    openOrg === chartData && openSubToggle ? 'open' : 'hide'
                  }`}
                />
              </Button>
            </div>
            {openOrg === chartData && openSubToggle && (
              <div
                className={`OrgChart__orgBox__subCon ${
                  openSubToggle ? 'open' : 'hide'
                }`}>
                <Button
                  variant='secondary'
                  onClick={() => handleAddChild(chartData)}>
                  {t('groupChart.orgchart.addChild')}
                </Button>
                <Button
                  variant='secondary'
                  onClick={() => handleDelete(chartData)}>
                  {t('groupChart.orgchart.delOrg')}
                </Button>
                <Button
                  variant='secondary'
                  onClick={() => handleSeeMore(chartData)}>
                  {t('groupChart.orgchart.members')}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div>
          {chartData.children &&
            chartData.children !== [] &&
            chartData.children.map(subChart => (
              <div key={subChart._id} style={{ display: 'flex' }}>
                {renderChart(subChart)}
              </div>
            ))}
        </div>
      </>
    );
  };

  return (
    <>
      <div className='companyOrg' style={{ display: 'flex' }}>
        <div className='OrgChart'>
          <div className='OrgChart__orgBox'>
            <div className='OrgChart__orgBox__mainCon'>
              <input
                type='text'
                defaultValue={orgData.name}
                onChange={e => changeChartName(orgData, e.target.value)}
              />
              <span>{orgData.num}</span>
              <Button variant='secondary' onClick={() => NameChange(orgData)}>
                <HiOutlinePencil
                  title={t('groupChart.orgchart.org-name-edit')}
                  size={16}
                />
              </Button>
              <Button
                variant='secondary'
                onClick={() => handleToggle()}
                className='OrgChart__orgBox__mainCon__toggle'>
                <BsFillCaretUpFill
                  size={16}
                  className={`toggleBox ${openBox ? 'open' : 'hide'}`}
                />
              </Button>
            </div>
            {openBox && (
              <div
                className={`OrgChart__orgBox__subCon ${
                  openBox ? 'open' : 'hide'
                }`}>
                <Button
                  variant='secondary'
                  onClick={() => handleAddChild(orgData)}>
                  {t('groupChart.orgchart.addChild')}
                </Button>
                <Button
                  variant='secondary'
                  onClick={() => handleDelete(orgData)}>
                  {t('groupChart.orgchart.delOrg')}
                </Button>
                <Button
                  variant='secondary'
                  onClick={() => handleSeeMore(orgData)}>
                  {t('groupChart.orgchart.members')}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div>
          {orgData.children &&
            orgData.children !== [] &&
            orgData.children.map(child => (
              <div key={child._id} style={{ display: 'flex' }}>
                {renderChart(child, openOrg, setOpenOrg)}
              </div>
            ))}
        </div>
      </div>
      {addChildrenOpen ? (
        <Modal
          id='modal'
          show={addChildrenOpen}
          size='m'
          onHide={setAddChildrenOpen}
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title className='modal__title'>
              {t('groupChart.orgchart.addChildTitle')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='modal__body'>
            <Form>
              <Form.Label>
                {t('groupChart.orgchart.addChildDescription')}
              </Form.Label>
              <div className='modal__body__main'>
                <Form.Control
                  type='text'
                  value={addOrgName}
                  onChange={currentAddChild}
                />
                <Button onClick={addChild}>
                  {t('groupChart.orgchart.add')}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}
      {memberListPage && <OrgMembers />}
    </>
  );
};
export default OrgChartPage;
