import React from 'react';
import { useTranslation } from 'react-i18next';

const TeamManualNone = () => {
  const { t } = useTranslation();

  return (
    <div className='teamManual__content'>
      <div className='teamManual__noneteam'>
        {t('components.teamManual.team-department-select')}
      </div>
    </div>
  );
};

export default TeamManualNone;
