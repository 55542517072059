import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import '../../scss/userSignUp/JoinUp.scss';
import { toast } from 'react-toastify';
import { BiUndo } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import DatePicker from '../DatePicker/DatePicker';

const JoinUp = () => {
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const navi = useNavigate();
  const { t, i18n } = useTranslation();

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userCall, setUserCall] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userEmployno, setUserEmployno] = useState('');
  const [userHiredate, setUserHiredate] = useState('');

  const [orgSelect, setOrgSelect] = useState([]);
  const [userOrg, setUserOrg] = useState('');
  const [positionSelect, setPositionSelect] = useState([]);
  const [userPosition, setUserPosition] = useState('');
  const [gradeSelect, setGradeSelect] = useState([]);
  const [userGrade, setUserGrade] = useState('');

  const goBack = () => {
    navi('/managerauth');
  };

  useEffect(() => {
    const fetchGrades = async () => {
      await axiosAuth
        .get(`/api/grade/all/${auth.company}`)
        .then(res => {
          const gradeOptionsCopy = [];
          for (let i = 0; i < res.data.length; i += 1) {
            gradeOptionsCopy.push({
              label: res.data[i].gradeName,
              value: res.data[i]._id,
            });
          }
          setGradeSelect(gradeOptionsCopy);
        })
        .catch(err => console.log(err));
    };
    fetchGrades();
  }, []);

  useEffect(() => {
    const orgList = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/org/all/${auth.company}`,
        });
        const orgOptionsCopy = [];
        for (let i = 1; i < res.data.length; i += 1) {
          orgOptionsCopy.push({
            label: res.data[i].name,
            value: res.data[i]._id,
          });
        }
        setOrgSelect(orgOptionsCopy);
      } catch (err) {
        console.log(err);
      }
    };
    orgList();
  }, []);

  useEffect(() => {
    const positionList = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/position/all`,
        });
        const orgOptionsCopy = res.data.map(el => ({
          label: el.positionName,
          value: el._id,
        }));
        setPositionSelect(orgOptionsCopy);
      } catch (err) {
        console.log(err);
      }
    };
    positionList();
  }, []);

  const clickSignUp = async () => {
    const userCompany = auth.company;
    if (
      userEmail &&
      userName &&
      // userPassword &&
      userPosition &&
      userPhone &&
      userCall &&
      userEmployno &&
      userHiredate &&
      userOrg
    ) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/auth/signup`,
          data: {
            email: userEmail,
            username: userName,
            company: userCompany,
            positionName: userPosition,
            contact: userPhone,
            officecontact: userCall,
            employno: userEmployno,
            hiredate: userHiredate,
            grade: userGrade,
            org: userOrg,
          },
        });
        toast(res.data.message || t('components.userSignup.user-register'));
        window.location.reload();
      } catch (err) {
        toast(err.response.data);
      }
    } else {
      toast(t('components.userSignup.userInfo-input'));
    }
  };

  const targetName = e => {
    setUserName(e.target.value);
  };
  const targetEmail = e => {
    setUserEmail(e.target.value);
  };

  const targetOrg = e => {
    setUserOrg(e.label);
  };
  const targetPosition = e => {
    setUserPosition(e.label);
  };
  const targetPhone = e => {
    setUserPhone(e.target.value);
  };
  const targetCall = e => {
    setUserCall(e.target.value);
  };
  const targetEmployno = e => {
    setUserEmployno(e.target.value);
  };
  const targetHire = e => {
    setUserHiredate(e.target.value);
  };
  const targetGrade = e => {
    setUserGrade(e.label);
  };

  return (
    <div className='joinUp mainsection'>
      <section className='joinUp__header'>
        <h4>{t('joinUp.title')}</h4>
        <BiUndo type='button' onClick={goBack} />
      </section>
      <section className='joinUp__content'>
        <div className='joinUp__content__mark'>
          <span className='requiredValue'>*</span>
          <span>{t('joinUp.isRequired')}</span>
        </div>
        <div className='joinUp__content__inputBox'>
          <div className='joinUp__content__inputBox__leftBox'>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.username')} <span className='requiredValue'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                aria-label="Recipient's username"
                aria-describedby='basic-addon2'
                className='joinUpBox__input'
                onChange={targetName}
              />
            </div>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.email')} <span className='requiredValue'>*</span>
              </Form.Label>
              <Form.Control
                type='email'
                aria-label="Recipient's username"
                aria-describedby='basic-addon2'
                className='joinUpBox__input'
                onChange={targetEmail}
              />
            </div>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.employno')} <span className='requiredValue'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                aria-label="Recipient's username"
                aria-describedby='basic-addon2'
                className='joinUpBox__input'
                onChange={targetEmployno}
              />
            </div>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.contact')} <span className='requiredValue'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                aria-label="Recipient's username"
                aria-describedby='basic-addon2'
                className='joinUpBox__input'
                onChange={targetPhone}
              />
            </div>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.officecontact')}
                <span className='requiredValue'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                aria-label="Recipient's username"
                aria-describedby='basic-addon2'
                className='joinUpBox__input'
                onChange={targetCall}
              />
            </div>
          </div>
          <div className='joinUp__content__inputBox__rightBox'>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.hiredate')} <span className='requiredValue'>*</span>
              </Form.Label>
              <DatePicker
                onChangeDate={targetHire} // 날짜가 변경될 때 호출할 함수
                language={i18n.language} // 언어를 한국어로 설정 (필요 시 'en'으로 설정)
                disabled={false} // 입력 필드 비활성화 여부
                name='hireDate' // input name 속성, 필요 시 사용
                style={{
                  border: '1px solid #ced4da',
                }}
              />
            </div>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.org')} <span className='requiredValue'>*</span>
              </Form.Label>
              <Select
                styles={blackStyles}
                placeholder={<div>{t('joinUp.pleaseSelect1')}</div>}
                isSearchable
                className='joinUpBox__select depSelect'
                options={orgSelect}
                onChange={targetOrg}
              />
            </div>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.position')} <span className='requiredValue'>*</span>
              </Form.Label>
              <Select
                styles={blackStyles}
                placeholder={<div>{t('joinUp.pleaseSelect2')}</div>}
                isSearchable
                className='joinUpBox__select'
                options={positionSelect}
                onChange={targetPosition}
              />
            </div>
            <div className='joinUpBox'>
              <Form.Label className='joinUpBox__label'>
                {t('joinUp.grade')}{' '}
              </Form.Label>
              <div className='joinUpBox__gradeSelect'>
                <Select
                  styles={blackStyles}
                  placeholder={<div>{t('joinUp.pleaseSelect3')}</div>}
                  isSearchable
                  className='joinUpBox__select'
                  options={gradeSelect}
                  onChange={targetGrade}
                />
                <span>({t('joinUp.noSelect')})</span>
              </div>
            </div>
            <Button type='submit' onClick={clickSignUp}>
              {t('button.submit')}
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JoinUp;
