import React, { useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import {
  MdDeleteOutline,
  MdOutlineEdit,
  MdOutlineRateReview,
} from 'react-icons/md';
import '../../../scss/ModRule/SortChapterDnD.scss';
import { useTranslation } from 'react-i18next';

const SortChaptersDnD = ({ chapters, setChapters }) => {
  const { t } = useTranslation();

  const [chapterInput, setChapterInput] = useState({
    id: '',
    chapterNumber: '',
    chapterName: '',
  });

  const onChangeChapterInput = e => {
    const { value, name } = e.target;
    setChapterInput({
      ...chapterInput,
      [name]: value,
    });
  };

  const draggingItem = useRef();
  const dragOverItem = useRef();

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;
    const listCopy = [...chapters];
    const draggingItemContent = listCopy[draggingItem.current];
    listCopy.splice(draggingItem.current, 1); // draggingItem.current position에서 요소 1개 제거
    listCopy.splice(dragOverItem.current, 0, draggingItemContent); // draggingItemContent를 dragoverItem position에 추가

    draggingItem.current = dragOverItem.current;
    dragOverItem.current = null;
    setChapters(listCopy);
  };

  // const handleDragEnd = e => {
  //   const listCopy = [...chapters];
  //   const draggingItemContent = listCopy[draggingItem.current];
  //   listCopy.splice(draggingItem.current, 1); // draggingItem.current position에서 요소 1개 제거
  //   listCopy.splice(dragOverItem.current, 0, draggingItemContent); // draggingItemContent를 dragoverItem position에 추가

  //   draggingItem.current = null;
  //   dragOverItem.current = null;
  //   setChapters(listCopy);
  // };

  const handleModalOpen = chap => {
    if (chapterInput?.id === '') {
      setChapterInput(prev => ({
        ...prev,
        id: chap._id,
        chapterNumber: chap.chapterNumber,
        chapterName: chap.chapterName,
      }));
    } else {
      setChapterInput(prev => ({
        ...prev,
        id: '',
        chapterNumber: '',
        chapterName: '',
      }));
    }
  };

  return (
    chapters?.length > 0 &&
    chapters.map((el, i) => (
      <>
        <div
          onDragStart={e => handleDragStart(e, i)}
          onDragEnter={e => handleDragEnter(e, i)}
          onDragOver={e => e.preventDefault()}
          key={el._id}
          draggable
          style={{
            border: '1px solid gray',
            margin: '0.7vh 0',
            height: '4vh',
            display: 'flex',
          }}>
          <div
            style={{
              borderRight: '1px solid gray',
              width: '2vw',
              textAlign: 'center',
            }}>
            <span style={{ lineHeight: '4vh' }}>{i + 1}</span>
          </div>
          <div style={{ width: '65vw', padding: '0 1vw' }}>
            <span style={{ marginRight: '10px', lineHeight: '4vh' }}>
              {el.chapterNumber}
            </span>
            <span style={{ marginRight: '10px', lineHeight: '4vh' }}> - </span>
            <span style={{ marginRight: '10px', lineHeight: '4vh' }}>
              {el.chapterName}
            </span>
            {el.chapterIdx !== i && (
              <span style={{ padding: '0 10px', backgroundColor: 'orange' }}>
                {`${t('modrule.change-order')} ${el.chapterIdx + 1} => ${
                  i + 1
                }`}{' '}
              </span>
            )}
          </div>
          <div
            style={{
              width: '9vw',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              gap: '1vw',
            }}>
            {/* <Button style={{ padding: '3px 15px' }} type='submit'></Button> */}
            <MdOutlineEdit size='24' onClick={() => handleModalOpen(el)} />
            <MdDeleteOutline size='24' />
            <MdOutlineRateReview size='24' />
          </div>
        </div>
        {chapterInput.id === el._id && (
          <div className='sortchapter__modal'>
            <div className='sortchapter__modal__header'>
              {t('modrule.change-chap')}
            </div>
            <div className='sortchapter__modal__body'>
              <div className='asortchapter__modal__numberinput'>
                <InputGroup>
                  <InputGroup.Text>{t('modrule.chap-num')}</InputGroup.Text>
                  <Form.Control
                    aria-label='chapterNumber'
                    name='chapterNumber'
                    onChange={onChangeChapterInput}
                    value={chapterInput.chapterNumber}
                  />
                </InputGroup>
              </div>
              <div className='sortchapter__modal__nameinput'>
                <InputGroup>
                  <InputGroup.Text>{t('modrule.chap-title')}</InputGroup.Text>
                  <Form.Control
                    aria-label='chapterName'
                    name='chapterName'
                    onChange={onChangeChapterInput}
                    value={chapterInput.chapterName}
                  />
                </InputGroup>
              </div>
            </div>
            <div className='sortchapter__modal__bottom'>
              <Button type='submit' variant='secondary'>
                {t('button.edit')}
              </Button>
              <Button type='submit' variant='secondary'>
                {t('button.cancel')}
              </Button>
            </div>
          </div>
        )}
      </>
    ))
  );
};

export default SortChaptersDnD;
