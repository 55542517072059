import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BiNotification } from 'react-icons/bi';
import {
  MdBarChart,
  MdNotifications,
  MdOutlineScreenSearchDesktop,
} from 'react-icons/md';
import { HiPlus } from 'react-icons/hi';
import { ImBooks } from 'react-icons/im';
import { Badge } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import MainFooter from './MainFooter';
import useAuth from '../common/hooks/useAuth';
import useAxiosAuth from '../common/hooks/useAxiosAuth';

const MainMenu = () => {
  const { t, i18n } = useTranslation();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const [notiCount, setNotiCount] = useState();

  useEffect(() => {
    const noti = async () => {
      const userId = auth.id;
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/notice/check/${userId}`,
        });
        setNotiCount(res.data.unReadNum);
      } catch (err) {
        console.log(err);
      }
    };
    if (auth.accessToken) {
      noti();
    }
  }, [auth]);

  const handleLanguage = language => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  useEffect(() => {
    handleLanguage('en');
  }, []);

  return (
    <>
      <div>
        <div className='mainicon'>
          <div>
            <NavLink to='notices'>
              {notiCount !== 0 && auth ? (
                <Badge pill bg='warning'>
                  {notiCount}
                </Badge>
              ) : (
                <HiPlus className='mainicon__zeroIcon' />
              )}
              <MdNotifications className='mainicon__style' />
              <p className='mainicon__subtitle'>{t('mainmenu.notice')}</p>
            </NavLink>
          </div>
          <div>
            <NavLink to='ruleinfo'>
              <ImBooks className='mainicon__style' />
              <p className='mainicon__subtitle'>{t('mainmenu.rule-info')}</p>
            </NavLink>
          </div>
          <div>
            <NavLink to='revstatus'>
              <BiNotification className='mainicon__style' />
              <p className='mainicon__subtitle'>{t('mainmenu.rev-status')}</p>
            </NavLink>
          </div>
          <div>
            <NavLink to='userlog'>
              <MdOutlineScreenSearchDesktop className='mainicon__style' />
              <p className='mainicon__subtitle'>{t('mainmenu.userlog')}</p>
            </NavLink>
          </div>
          <div>
            <NavLink to='rulechart'>
              <MdBarChart className='mainicon__style' />
              <p className='mainicon__subtitle'>{t('mainmenu.rulechart')}</p>
            </NavLink>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default MainMenu;
