import React, { useEffect } from 'react';
import { TiChevronRight } from 'react-icons/ti';

const AuthTeamList = ({ teamList, setTargetOrg, targetOrg }) => {
  useEffect(() => {}, []);

  const handleTeam = orgId => {
    const { _id, path, name, company } = orgId;

    setTargetOrg(prev => ({
      ...prev,
      _id,
      path,
      name,
      company,
    }));
  };

  return (
    <div>
      {teamList &&
        teamList.map(team => (
          <div
            key={team._id}
            className={`Authcollapse__item 
            ${team.name === targetOrg.name ? 'select' : ''}
            `}
            aria-hidden='true'
            onClick={() => handleTeam(team)}>
            <span>{team.name}</span>
            <span>
              <TiChevronRight size='10' />
            </span>
          </div>
        ))}
    </div>
  );
};

export default AuthTeamList;
