import React, { useEffect } from 'react';
import '../../scss/ModRule/EnactRule.scss';
import { useParams } from 'react-router-dom';
import { ModChapterProvider } from '../../store/ModChapterProvider';
import useModRule from '../../common/hooks/useModRule';
import AmendRuleRightMain from './DevAmend/AmendRuleRightMain';
import DevAmendSidelist from './DevAmendSidelist';
import ModRuleSortChapter from './ModRuleSortChapter';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import ModRuleDraftHeader from './ModRuleDraftHeader';

const initialState = {
  fileType: '',
  modType: '',
  content: '',
  uri: '',
  chapterIdx: 0,
  chapterContents: {
    header: '',
    children: [],
  },
};

const DevAmend = () => {
  const axiosAuth = useAxiosAuth();
  const { modRule, setModRule } = useModRule();
  const { id } = useParams();

  useEffect(() => {
    const fetchModRule = async () => {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/modrule/${id}`,
      });
      setModRule(res.data);
    };
    fetchModRule();
  }, []);

  return (
    <ModChapterProvider initialState={initialState}>
      <ModRuleDraftHeader />
      <div className='devamend' style={{ display: 'flex' }}>
        {modRule?._id && <DevAmendSidelist />}
        <section className='devamend__main' style={{ padding: '0 0.5vw' }}>
          {modRule?._id && modRule.sortOpen ? (
            <ModRuleSortChapter />
          ) : (
            <AmendRuleRightMain />
          )}
        </section>
      </div>
    </ModChapterProvider>
  );
};

export default DevAmend;
