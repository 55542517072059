import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormGroup,
  ToggleButton,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../scss/ModRule/NewRule.scss';
import { useNavigate } from 'react-router-dom';
import { BiX } from 'react-icons/bi';
import {
  MdOutlineKeyboardDoubleArrowDown,
  MdOutlineKeyboardDoubleArrowLeft,
} from 'react-icons/md';
import Select from 'react-select';
import blackStyles from '../../common/utils/SelectStyles';
import RuleApproval from './RuleApproval';
import RuleChapContents from './RuleChapContents';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';

window.Buffer = window.Buffer || require('buffer').Buffer;

const RuleCreate = () => {
  // 제정 (Enactment)
  const { t, i18n } = useTranslation();
  const navi = useNavigate();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const editorRef = useRef(null);
  const [payOpen, setPayOpen] = useState(false);
  const [content, setContent] = useState('');
  const [category, setCategory] = useState({ label: '', value: '' });
  const [options, setOptions] = useState([]);
  const [storageOptions, setStorageOptions] = useState([]);
  const [cateStorOp, setCateStorOp] = useState([]);
  const [listReLoad, setListReLoad] = useState(false);
  const [ruleMod, setRuleMod] = useState(null);
  const [newRule, setNewRule] = useState({ ruleNumber: '', ruleName: '' });
  const [lan, setLan] = useState('kor');
  const [chapterNumber, setChapterNumber] = useState('');
  const [chapterName, setChapterName] = useState('');
  // 결재선 parent
  const [approval, setApproval] = useState({
    examines: [],
    reference: [],
    approve: [],
  });
  const [chapterContents, setChapterContents] = useState({
    header: '',
    children: [],
  });
  const { ruleName, ruleNumber } = newRule;

  useEffect(() => {
    const categorySelectInit = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/category/all/kor',
      });
      const optionsCopy = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(optionsCopy);
    };
    categorySelectInit();
  }, []);

  useEffect(() => {
    const categoryLanChange = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/all/${lan}`,
      });
      const optionsCopy = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setCategory({ label: '', value: '' });
      setNewRule({ ruleName: '', ruleNumber: '' });
      setChapterName('');
      setOptions(optionsCopy);
    };
    categoryLanChange();
  }, [lan]);

  const handleValue = e => {
    setCategory(e);
  };
  const onChangeRuleNumber = e => {
    setNewRule(prev => ({ ...prev, ruleNumber: e.target.value }));
  };

  const onChangeRuleName = e => {
    setNewRule(prev => ({ ...prev, ruleName: e.target.value }));
  };

  const ruleSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/rule/new2',
        data: {
          writer: auth.id,
          id: category.value._id,
          category: category.value.category,
          ruleNumber: newRule.ruleNumber,
          ruleName: newRule.ruleName,
          lan,
        },
      });
      const { chapterRef, refRuleId } = res.data;
      setRuleMod(res.data);
      const chapternum = chapterRef ? chapterRef.length + 1 : 1;
      setChapterNumber(`${refRuleId.ruleNumber}-${chapternum}`);
      toast(`${refRuleId.ruleNumber} ${t('modrule.submit-complete-rule')}`);
    } catch (error) {
      console.log(error);
      toast(t('modrule.submit-enact-draft-failed'));
    }
  };

  const handleChapterNumber = e => {
    setChapterNumber(e.target.value);
  };

  const handleChapterName = e => {
    setChapterName(e.target.value);
  };
  const modruleSubmit = async () => {
    if (approval.examines.length === 0 || approval.approve.length === 0) {
      toast(t('modrule.set-approval-line'));
    } else {
      try {
        const res = await axiosAuth({
          method: 'post',
          url: '/api/modrule/newe',
          data: {
            writer: auth.id,
            ruleModId: ruleMod._id,
            refCategoryId: category.value._id,
            lan,
            examines: approval.examines,
            reference: approval.reference,
            approve: approval.approve,
          },
        });
        if (res.status === 200) {
          toast(
            `${res.data.refRuleId.ruleNumber} ${t(
              'modrule.submit-enact-draft'
            )}`
          );
          navi('/modrule');
        }
      } catch (error) {
        console.log(error);
        toast(t('modrule.submit-enact-draft-failed'));
      }
    }
  };

  const chapterSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/chapter/new',
        data: {
          ruleModId: ruleMod._id,
          chapterNumber,
          chapterName,
          content,
          lan,
          chapterContents,
        },
      });
      if (res.status === 200) {
        toast(t('modrule.submit-chap'));
        setRuleMod(res.data);
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.submit-chap-failed'));
    }
  };
  const handleTargetChapter = id => {
    if (id) {
      const targetChap = ruleMod.chapterRef.filter(el => el._id === id)[0];
      setChapterNumber(targetChap.chapterNumber);
      setChapterName(targetChap.chapterName);
      setContent(targetChap.content);
    }
  };

  const targetChapterDel = async chap => {
    if (
      window.confirm(
        i18n.language === 'ko'
          ? `${chap.chapterNumber}를 삭제하시겠습니까?`
          : `Do you want to delete ${chap.chapterNumber}`
      )
    ) {
      try {
        const res = await axiosAuth({
          method: 'post',
          url: '/api/modrule/chapter/del',
          data: {
            chapterModId: chap._id,
            ruleModId: ruleMod._id,
          },
        });
        if (res.status === 200) {
          toast(t('modrule.del-chap'));
          setRuleMod(res.data);
          setContent('');
          setChapterNumber('');
          setChapterName('');
        }
      } catch (error) {
        console.log(error);
        toast(t('modrule.del-chap-error'));
      }
    }
  };

  const temporarySave = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/temporary',
        data: {
          ruleModId: ruleMod._id,
          classification: 'enact',
          refCategoryId: category.value._id,
        },
      });
      if (res.status === 200) {
        toast(t('modrule.temp-save'));
        navi('/modrule');
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.temp-save-failed'));
    }
  };

  useEffect(() => {
    const StorageListSelect = async () => {
      const { id } = auth;
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/modrule/tempmodruleE/${id}`,
      });
      const optionsCopy = response.data.map(option => ({
        label: `${option.refRuleId.ruleNumber} : ${option.refRuleId.ruleName}`,
        value: option,
      }));
      setStorageOptions(optionsCopy);
    };
    StorageListSelect();
  }, []);

  const handleStorageList = async e => {
    setListReLoad(true);
    const id = e.value._id;
    if (e.value._id) {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/modrule/${id}`,
        });
        const optionsCopy = {
          label: `${res.data[0].categoryNumber} : ${res.data[0].category}`,
          value: res.data[0],
        };
        setCateStorOp(optionsCopy);
        setNewRule(res.data[2].refRuleId);
        setRuleMod(res.data[2]);
        // setChapterContents(res.data[0]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  function customUploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(file => {
            data.append('file', file);
            axiosMultipart
              .post('/api/modrule/imageupload', data)
              .then(res => resolve({ default: res.data.imageUrl }))
              .catch(err => reject(err));
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    const editorinstance = editor;
    editorinstance.plugins.get('FileRepository').createUploadAdapter =
      loader => {
        return customUploadAdapter(loader);
      };
  }

  const goBack = async () => {
    if (ruleMod) {
      if (window.confirm(t('modrule.cancel-draft-q'))) {
        await axiosAuth
          .delete(`/api/modrule/${ruleMod._id}`)
          .then(res => {
            toast(res.status || t('modrule.cancel-draft'));
            navi(-1);
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else {
      navi(-1);
    }
  };

  const preventClose = async e => {
    if (ruleMod) {
      await fetch(`/api/modrule/${ruleMod._id}`, {
        method: 'DELETE',
        keepalive: true,
      });
    }
    e.returnValue = '';
  };

  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', preventClose);
    })();

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, []);

  const paymentSetting = () => {
    setPayOpen(prev => !prev);
  };

  return (
    <div className='draft'>
      <div className='draft__subContent'>
        <div className='draft__subContent__btnBox'>
          <Button variant='secondary' type='submit' onClick={paymentSetting}>
            {t('modrule.payment.payment-line')}
          </Button>
          {payOpen ? (
            <RuleApproval
              user={auth}
              setApproval={setApproval}
              payOpen={payOpen}
              setPayOpen={setPayOpen}
            />
          ) : null}
          <Button variant='secondary' type='submit' onClick={temporarySave}>
            {t('modrule.temporary-storage')}
          </Button>
          <Button variant='secondary' type='submit' onClick={modruleSubmit}>
            {t('modrule.Draft-rule')}
          </Button>
          <Button variant='secondary' type='submit' onClick={goBack}>
            {t('modrule.cancel')}
          </Button>
        </div>
      </div>
      <div className='draft__mainBody'>
        <section className='draft__draftinfo'>
          <span className='draft__draftinfo__title'>
            {t('modrule.rule-create')}
          </span>
          <div className='draft__draftinfo__top'>
            <div>
              <div width='10%'>{t('modrule.lan')}</div>
              <div width='30%'>
                <ButtonGroup className='lanradiobtn'>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      // variant='secondary'
                      key={radio.value}
                      id={`radio-${idx}`}
                      type='radio'
                      // style={{
                      //   backgroundColor:
                      //     lan === radio.value ? 'rgb(233, 182, 87)' : '',
                      // }}
                      variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                      name='radio'
                      value={radio.value}
                      checked={lan === radio.value}
                      onChange={e => setLan(e.currentTarget.value)}>
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
            </div>
            <div className='draft__draftinfo__top__cateSelect'>
              <div width='10%'>{t('modrule.cate')}</div>
              <div width='30%'>
                {listReLoad ? (
                  <FormGroup>
                    <FormControl
                      type='text'
                      defaultValue={cateStorOp.label}
                      className='cateDefaultValueBox'
                      disabled
                    />
                  </FormGroup>
                ) : (
                  <Select
                    styles={blackStyles}
                    placeholder={t('modrule.cate-placeholder')}
                    isSearchable
                    id='select-createcategory'
                    className='cateSelectBox'
                    options={options}
                    onChange={handleValue}
                  />
                )}
              </div>
            </div>
            <div>
              <div width='10%'>{t('modrule.temporary')}</div>
              <div width='30%'>
                {storageOptions.length !== 0 ? (
                  <Select
                    styles={blackStyles}
                    placeholder={t('modrule.temporary-placeholder')}
                    isSearchable
                    id='select-createcategory'
                    className='temporaryList'
                    options={storageOptions}
                    onChange={handleStorageList}
                  />
                ) : (
                  <span style={{ color: '#ccc' }}>
                    {t('modrule.no-temporary-placeholder')}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='draft__draftinfo__bottom'>
            <div>
              <div width='10%'>{t('modrule.rule-num')}</div>
              <div width='30%'>
                <input
                  type='text'
                  className='form-control input__rulenumber'
                  id='input-rulenumber'
                  placeholder={t('modrule.rule-num-placeholder')}
                  value={ruleNumber}
                  onChange={onChangeRuleNumber}
                  required
                />
              </div>
            </div>
            <div>
              <div width='10%'>{t('modrule.rule-title')}</div>
              <div width='30%'>
                <input
                  type='text'
                  className='form-control'
                  id='input-rulename'
                  placeholder={t('modrule.rule-title-placeholder')}
                  value={ruleName}
                  onChange={onChangeRuleName}
                  required
                />
              </div>
            </div>
            <div>
              <div width='10%'>{t('modrule.create')}</div>
              <div width='30%'>
                <Button type='submit' onClick={ruleSubmit}>
                  <span>{t('modrule.rule-create')}</span>
                  <MdOutlineKeyboardDoubleArrowDown />
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section className='draft__contents'>
          <div className='draft__contents__subContents'>
            <div className='draft__contents__chapterlist'>
              <span className='draft__contents__chapterlist__title'>
                {t('modrule.add-chapList')}
              </span>
              <div className='draft__contents__chapterlist__contents'>
                {ruleMod &&
                  ruleMod.chapterRef.map(chap => (
                    <Button
                      variant='secondary'
                      // className={` ${._id === chap._id ? 'active' : ''}`}
                      key={chap._id}
                      onClick={() => handleTargetChapter(chap._id)}>
                      <span>{chap.chapterNumber}</span>
                      <BiX size={24} onClick={() => targetChapterDel(chap)} />
                    </Button>
                  ))}
              </div>
            </div>
            <div className='draft__contents__chapterinfo'>
              <p className='draft__contents__chapterinfo__title'>
                {' '}
                {t('modrule.List')}
              </p>
              <div className='draft__contents__chapterinfo__enactBody'>
                <RuleChapContents
                  chapContent={content}
                  chapterContents={chapterContents}
                  setChapterContents={setChapterContents}
                />
              </div>
            </div>
          </div>
          <div className='draft__contents__main'>
            <div className='draft__contents__main__header'>
              <div className='mainConBtn'>
                <span>
                  <Button
                    type='submit'
                    id='btn-chaptersubmit'
                    onClick={chapterSubmit}>
                    <span className='chapBtnSvg'>
                      <MdOutlineKeyboardDoubleArrowLeft />
                      <MdOutlineKeyboardDoubleArrowLeft />
                    </span>
                    <span className='chapAdd'>{t('modrule.add-chap')}</span>
                  </Button>
                </span>
                <span>
                  <label className='draft__label' htmlFor='input-chapternumber'>
                    {/* 챕터 No. */}
                    <input
                      type='text'
                      className='form-control'
                      id='input-chapternumber'
                      placeholder={t('modrule.chapNum-placeholder')}
                      value={chapterNumber}
                      onChange={handleChapterNumber}
                      required
                    />
                  </label>
                </span>
                <span>
                  <label className='draft__label' htmlFor='input-chaptername'>
                    {/* 챕터 제목 */}
                    <input
                      type='text'
                      className='form-control'
                      id='input-chaptername'
                      placeholder={t('modrule.chapTitle-placeholder')}
                      value={chapterName}
                      onChange={handleChapterName}
                      required
                    />
                  </label>
                </span>
              </div>
            </div>
            <div className='draft__main__editor'>
              <CKEditor
                ref={editorRef}
                editor={Editor}
                data={content}
                config={{
                  heading: {
                    options: [
                      {
                        model: 'paragraph',
                        title: i18n.language === 'ko' ? '본문' : 'CONTENT',
                        class: 'ck-heading_paragraph',
                      },
                      {
                        model: 'heading2',
                        view: 'h2',
                        title: i18n.language === 'ko' ? '제목 1' : 'TITLE 1',
                        class: 'ck-heading_heading2',
                      },
                      {
                        model: 'heading3',
                        view: 'h3',
                        title: i18n.language === 'ko' ? '제목 2' : 'TITLE 2',
                        class: 'ck-heading_heading3',
                      },
                      {
                        model: 'heading4',
                        view: 'h4',
                        title: i18n.language === 'ko' ? '제목 3' : 'TITLE 3',
                        class: 'ck-heading_heading4',
                      },
                    ],
                  },
                  extraPlugins: [uploadPlugin],
                  autosave: {
                    save(editor) {
                      if (editor) {
                        const data = editor.getData();
                        setContent(data);
                      }
                    },
                    waitingTime: 3000,
                  },
                }}
                onReady={editor => {
                  editor.ui
                    .getEditableElement()
                    .parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement()
                    );
                }}
                onError={(error, editor) => {
                  if (editor.willEditorRestart) {
                    editor.ui.view.toolbar.element.remove();
                  }
                  if (error) {
                    console.log(error);
                  }
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RuleCreate;
