import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../scss/RuleInfo/RuleList.scss';
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { Table } from 'react-bootstrap';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useEntire from '../../common/hooks/useEntire';

const RuleTr = ({ rule }) => {
  return (
    <tr>
      <td width='15%' align='center'>
        {rule.type === 'rule' && (
          <NavLink
            to={`/ruleinfo/category/${rule.categoryNumber}`}
            style={{ textDecoration: 'none', color: 'black' }}>
            {`[${rule.categoryNumber}]`}
          </NavLink>
        )}
        {rule.type === 'form' && (
          <NavLink
            to={`/ruleinfo/formlist/${rule.lan}#category-${rule.categoryNumber}`}
            style={{ textDecoration: 'none', color: 'black' }}>
            {`[${rule.categoryNumber}]`}
          </NavLink>
        )}
      </td>
      <td width='60%'>
        {rule.type === 'rule' && (
          <NavLink
            to={`/ruleinfo/category/${rule.categoryNumber}`}
            style={{ textDecoration: 'none', color: 'black' }}>
            {`[${rule.category}]`}
          </NavLink>
        )}
        {rule.type === 'form' && (
          <NavLink
            to={`/ruleinfo/formlist/${rule.lan}#category-${rule.categoryNumber}`}
            style={{ textDecoration: 'none', color: 'black' }}>
            {`[${rule.category}]`}
          </NavLink>
        )}
      </td>

      <td width='10%' className='text-center'>
        -
      </td>
    </tr>
  );
};

const DummyTr = () => {
  const len = [];

  return (
    <>
      {len.map(el => (
        <tr key={el}>
          <td />
          <td className='text-center'>
            <BsFileEarmarkPdf />
          </td>
          <td className='text-center' />
        </tr>
      ))}
    </>
  );
};

const RuleStart = () => {
  const { t } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const [cates, setCates] = useState([]);
  const { entire } = useEntire();
  const sideNavLan = entire.sideNav.lan; // sideNav 상태 변경 ['kor', 'eng']

  useEffect(() => {
    const fetchRules = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/all/${sideNavLan}`,
      });
      const categoriesDatas = response.data;
      setCates(categoriesDatas);
    };
    if (sideNavLan) fetchRules();
  }, [sideNavLan]);

  return (
    <div className='ruleinfo__rulelist'>
      <div className='ruleinfo__rulelist__header'>
        {/* <RuleBreadCrumb breadCrumbs={breadCrumbs} /> */}
      </div>
      <div className='ruleinfo__rulelist__tableBox'>
        <Table className='table rulelist__table'>
          <thead className='text-center'>
            <tr>
              <th scope='col' width='25%'>
                {t('sidenav.cateNum')}
              </th>
              <th scope='col' width='60%'>
                {t('sidenav.cateName')}
              </th>
              <th scope='col' width='15%'>
                {t('table.remark')}
              </th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            {cates && cates.map(cate => <RuleTr key={cate._id} rule={cate} />)}
            <DummyTr />
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default RuleStart;
