import React, { useEffect, useState } from 'react';
import '../../scss/TeamManual/TeamManualUpdate.scss';
import { BiUndo } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../common/hooks/useAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import { axiosAuth } from '../../common/axios/api';

const TeamManualUpdate = () => {
  const { i18n, t } = useTranslation();
  const navi = useNavigate();
  const { auth } = useAuth();
  const { manualNumber } = useParams();
  const axiosMultipart = useAxiosMultipart();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [currentManual, setCurrentManual] = useState({
    orgId: '',
    manualNum: '',
    manualName: '',
    manualSum: '',
    manualNote: '',
  });
  const { orgId, manualNum, manualName, manualSum, manualNote } = currentManual;

  useEffect(() => {}, []);

  useEffect(() => {
    const controller = new AbortController();
    const fetchManual = async () => {
      try {
        const res = await axiosAuth.get(`/api/manual/${manualNumber}`);
        setCurrentManual({
          manualNum: res.data.manual.manualNumber,
          manualName: res.data.manual.manualName,
          manualSum: '',
          manualNote: '',
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchManual();
    return () => {
      controller.abort();
    };
  }, [manualNumber]);

  const goBack = () => {
    navi(-1);
  };
  const handleDel = () => {
    if (i18n.language === 'ko') {
      if (window.confirm('작성을 취소하시겠습니까?')) {
        navi(-1);
      }
    } else if (i18n.language === 'en') {
      if (window.confirm('Are you sure you want to cancel creation?')) {
        navi(-1);
      }
    }
  };

  const changeTargetInputs = e => {
    const { value, name } = e.target;
    setCurrentManual({
      ...currentManual,
      [name]: value,
    });
  };

  const targetFile = e => {
    if (e) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (window.confirm(t('components.teamManual.manual-update-two-q'))) {
      const formData = new FormData();
      formData.append('orgId', orgId);
      formData.append('writer', auth.id);
      formData.append('manualNumber', manualNum);
      formData.append('manualName', manualName);
      formData.append('summary', manualSum);
      formData.append('note', manualNote);
      formData.append('file', file);

      setLoading(true);
      try {
        const res = await axiosMultipart({
          method: 'POST',
          url: '/api/manual/update',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
        });
        if (res.status === 200) {
          setLoading(false);
          toast(t('components.teamManual.manual-update-two'));
          navi(-1);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className='manualUpdate'>
      <div className='manualUpdate__header'>
        <Button variant='secondary'>
          <BiUndo type='button' onClick={goBack} />
        </Button>
        {loading && (
          <div className='manualUpdate__header__spinner'>
            <Spinner
              as='span'
              animation='border'
              // animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
            <span className='spinnerText'>{t('manual.registering')}</span>
          </div>
        )}
      </div>
      <section className='manualUpdate__main'>
        <form action='upload' encType='multipart/form-data'>
          <div className='manualUpdate__main__inputBox'>
            <div className='manualUpdate__main__inputBox__writer'>
              <dl>
                <dt>{t('table.writer')} : </dt>
                <dd>
                  <input
                    type='text'
                    defaultValue={auth.username}
                    className='form-control'
                    disabled
                  />
                </dd>
              </dl>
            </div>
            <div className='manualUpdate__main__inputBox__middleContents'>
              <Form.Group
                controlId='formFileMultiple'
                encType='multipart/form-data'
                className='mb-3 manualUpdate__main__inputBox__middleContents__form'>
                <Form.Label>{t('table.attachment')}: </Form.Label>
                <Form.Control
                  type='file'
                  name='file'
                  multiple
                  onChange={targetFile}
                />
              </Form.Group>
            </div>
            <div className='manualUpdate__main__inputBox__bottomContents'>
              <dl>
                <dt>{t('manual.manual-num')} :</dt>
                <dd>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={t('table.placeholder-Num')}
                    defaultValue={manualNum}
                    // value={manualNum}
                    disabled
                    name='manualNum'
                    onChange={changeTargetInputs}
                  />
                </dd>
              </dl>
              <dl>
                <dt>{t('manual.manual-title')} :</dt>
                <dd>
                  <input
                    type='text'
                    placeholder={t('table.placeholder-Title')}
                    className='form-control'
                    defaultValue={manualName}
                    // value={manualName}
                    // disabled
                    name='manualName'
                    onChange={changeTargetInputs}
                  />
                </dd>
              </dl>
            </div>
          </div>
          <div className='manualUpdate__main__additional'>
            <dl>
              <dt>{t('manual.revision-details')} : </dt>
              <dd>
                <textarea
                  type='text'
                  // className=''
                  placeholder={t('table.placeholder-input')}
                  defaultValue={manualSum}
                  name='manualSum'
                  onChange={changeTargetInputs}
                />
              </dd>
            </dl>
            <dl>
              <dt>{t('manual.cate-manualNote')} : </dt>
              <dd>
                <textarea
                  type='text'
                  placeholder={t('table.placeholder-input')}
                  // defaultValue={manualNote}
                  name='manualNote'
                  onChange={changeTargetInputs}
                  // className=''
                />
              </dd>
            </dl>
          </div>
        </form>
      </section>
      <section className='manualUpdate__bottom'>
        <Button className='manualUpdate__bottom__btn' onClick={handleDel}>
          {t('button.cancel')}
        </Button>
        <Button
          className='manualUpdate__bottom__btn'
          type='submit'
          onClick={handleUpload}>
          {t('button.submit')}
        </Button>
      </section>
    </div>
  );
};

export default TeamManualUpdate;
