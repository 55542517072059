import React, { useEffect, useState } from 'react';
import '../scss/Main.scss';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import MainMenu from '../components/MainMenu';
import useAuth from '../common/hooks/useAuth';
import MainSearchBar from '../components/MainSearchBar';
import { axiosAuth } from '../common/axios/api';

const Main = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const { auth } = useAuth();

  const pwNotiClose = () => {
    const nextChange = async () => {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/auth/updatepwalarm',
          data: {
            email: auth.email,
          },
        });
        if (res.status === 200) {
          setOpen(prev => !prev);
          toast(t('pwNotice.set-alarm'));
        } else if (res.status === 500) {
          toast(t('pwNotice.set-alarm-failed'));
        }
      } catch (err) {
        console.log(err);
      }
    };
    nextChange();
  };

  useEffect(() => {
    if (location.state?.pwExpired) {
      setOpen(location.state.pwExpired);
    }
  }, [location.state]);

  return (
    <div className='mainpage'>
      <MainSearchBar />
      <MainMenu />
      {location.state && open ? (
        <div className='header__changePassword'>
          <div className='header__changePassword__popup'>
            <div className='headerBox'>
              <h4>{t('pwNotice.title')}</h4>
            </div>
            <div className='textBox'>
              <div className='textBox__exBox'>
                <span>{t('pwNotice.text1')}</span>
                <span>{t('pwNotice.text2')}</span>
                <span>{t('pwNotice.text3')}</span>
                <span>{t('pwNotice.text4')}</span>
              </div>
              <div className='textBox__noticeBox'>
                <span>{t('pwNotice.textNoti1')}</span>
                <span>{t('pwNotice.textNoti2')}</span>
              </div>
            </div>
            <div className='btnBox'>
              <Button>
                <Link to='/mypage' style={{ color: '#fff' }}>
                  {t('pwNotice.changeBtn')}
                </Link>
              </Button>
              <Button onClick={pwNotiClose}>{t('pwNotice.closeBtn')}</Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Main;
