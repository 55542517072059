import { useContext, useDebugValue } from 'react';
import DiffContext from '../../store/DiffProvider';

const useDiff = () => {
  const { diff } = useContext(DiffContext);
  useDebugValue(diff, el => (el ? 'ondata' : 'offdata'));
  return useContext(DiffContext);
};

export default useDiff;
