import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const MainSearchBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [focused, setFocused] = React.useState(false);

  const handleSearchText = e => {
    setSearchText(e.target.value);
  };
  const search = async () => {
    navigate(`/searchrule/${searchText}`);
  };
  const handleKeyDown = e => {
    if (focused) {
      const key = e.code;
      switch (key) {
        case 'Enter':
          search();
          break;
        default:
      }
    }
  };

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <div className='mainsearch'>
      <div className='mainsearch__section'>
        <input
          onFocus={onFocus}
          onBlur={onBlur}
          className='mainsearch__section__searchbar'
          placeholder={t('searchbar.placeholder')}
          onChange={handleSearchText}
          value={searchText}
          onKeyDown={handleKeyDown}
        />
        <Button onClick={search} className='mainsearch__section__button'>
          <BsSearch className='SearchBar__btn__icon' />
        </Button>
      </div>
    </div>
  );
};

export default MainSearchBar;
