import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BsFillCaretUpFill } from 'react-icons/bs';
import { AiFillStepBackward } from 'react-icons/ai';
import { Button, Spinner } from 'react-bootstrap';
import {
  MdDeleteForever,
  MdOutlineFiberNew,
  MdSubdirectoryArrowRight,
} from 'react-icons/md';
import { BiCheck, BiCheckbox, BiCheckboxSquare } from 'react-icons/bi';
import { TbFileExport } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import useAuth from '../../../common/hooks/useAuth';
import useModRule from '../../../common/hooks/useModRule';
import '../../../scss/ModRule/AmendRuleSidelist.scss';
import useModChapter from '../../../common/hooks/useModChapter';

const CollapseItem = ({ cate }) => {
  const [clicked, setClicked] = useState(false);
  const axiosAuth = useAxiosAuth();
  const { state } = useLocation();
  const { auth } = useAuth();
  const { setModRule } = useModRule();
  const { t } = useTranslation();

  const handleToggle = () => {
    setClicked(prev => !prev);
  };

  const handleSubmit = async ruleObj => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/approval/amend/init',
        data: {
          writer: auth.id,
          number: state.number,
          type: 'dcn',
          refCategoryId: cate._id,
          ruleNumber: ruleObj.ruleNumber,
          ruleName: ruleObj.ruleName,
          refRuleId: ruleObj._id,
          lan: ruleObj.lan,
          viewType: ruleObj.viewType,
        },
      });
      setModRule(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='collapseitem'>
      <div
        aria-hidden='true'
        onClick={handleToggle}
        className='collapseitem__header'>
        <span>
          <BsFillCaretUpFill
            className={`collapseitem__toggle ${clicked ? 'open' : ''}`}
          />
        </span>
        <span
          aria-hidden='true'
          className='collapseitem__title'
          title={cate && cate.category}>
          {cate && `[${cate.categoryNumber}] : ${cate.category}`}
        </span>
      </div>
      <div className={`collapseitem__rulebox ${clicked ? 'open' : ''}`}>
        {cate.ruleRef &&
          cate.ruleRef.map(rule => (
            <div
              className='collapseitem__rulebox__sub'
              key={rule._id}
              title={rule.ruleName}>
              <span>{`[${rule.ruleNumber}] : ${rule.ruleName}`}</span>
              <span>
                <Button
                  variant='secondary'
                  className='toggle-btn'
                  title={t('modrule.revise-draft')}
                  onClick={() => handleSubmit(rule)}>
                  <TbFileExport size={24} />
                </Button>
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

const AmendRuleSidelist = () => {
  const path = useLocation().pathname;
  const axiosAuth = useAxiosAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [lan, setLan] = useState('kor');
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const res = await axiosAuth.get(`/api/approval/category/all/${lan}`);
        setCategories(res.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
    return () => {
      controller.abort();
    };
  }, [path, lan]);

  const tabSlide = newPosition => {
    const toTranslate = 100 * newPosition;
    const elem = document.querySelector('span.draftpage__catelist__tab__glide');
    elem.style.transform = `translateX(${toTranslate}%)`;
  };

  const handlelan = index => {
    const types = ['kor', 'eng'];
    const type = types[index];
    setLan(type);
    tabSlide(index);
  };

  const handleReset = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/delete',
        data: {
          modruleId: modRule._id,
        },
      });
      if (res.status === 200) {
        setModRule({});
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChapterSelect = el => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
    setModChapter({
      new: false,
      modType: 'replace',
      ...el,
    });
  };

  const handleChapterNew = () => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
    setModChapter({
      new: true,
      fileType: '', // type : content / file
      modType: '',
      content: '',
      uri: '',
      chapterNumber: `${modRule.ruleNumber}-`,
      chapterName: '',
      chapterContents: {
        header: '',
        children: [],
      },
    });
  };

  if (modRule?._id) {
    return (
      <div className='draftpage__catelist'>
        <div className='draftpage__catelist__header'>
          <div className='draftpage__catelist__tabs'>
            <span
              className='draftpage__catelist__tab'
              role='button'
              aria-hidden='true'
              onClick={() => handleReset()}>
              <AiFillStepBackward size={20} />
            </span>
            <span
              className='draftpage__catelist__tab open'
              role='button'
              aria-hidden='true'>
              {`${t('modrule.lan')} : ${modRule.lan.toUpperCase()}`}
            </span>
            <span className='draftpage__catelist__tab__glideright' />
          </div>
        </div>
        <div className='draftpage__catelist__collapse'>
          <div className='collapseitem'>
            <div className='collapseitem__header'>
              <span>
                <BsFillCaretUpFill className='collapseitem__toggle open' />
              </span>
              <span aria-hidden='true' className='collapseitem__title'>
                {`[${modRule.refCategory.categoryNumber}] : ${modRule.refCategory.category}`}
              </span>
            </div>
            <div className='collapseitem__rulebox open'>
              <div className='collapseitem__rulebox__sub'>
                <span>
                  <MdSubdirectoryArrowRight size={18} />
                  {'  '}[{modRule.ruleNumber}] :{'  '}
                  {modRule.ruleName}
                </span>
              </div>
            </div>
            <div className='collapseitem__fileList'>
              <div>
                <span style={{ alignSelf: 'center' }}>
                  {t('modrule.chap-list')}
                </span>{' '}
                <Button onClick={handleChapterNew}>
                  {t('modrule.create-chap')}
                </Button>
              </div>
              {modRule.modChapterRef
                .filter(
                  mchap => mchap.modType === 'new' && mchap.chapterType === 'CH'
                )
                .map(filterModChap => (
                  <div key={filterModChap._id}>
                    <Button
                      onClick={() => handleChapterSelect(filterModChap)}
                      disabled>
                      {filterModChap.chapterNumber}
                    </Button>
                    <span style={{ color: 'green' }}>
                      <MdOutlineFiberNew size={28} />
                    </span>
                    <span
                      style={{
                        color:
                          modChapter._id === filterModChap._id
                            ? 'orange'
                            : '#fff',
                      }}>
                      {modChapter._id === filterModChap._id ? (
                        <BiCheckboxSquare size={30} />
                      ) : (
                        <BiCheckbox size={30} />
                      )}
                    </span>
                  </div>
                ))}
              {modRule.modChapterRef
                .filter(
                  mchap =>
                    mchap.modType === 'new' && mchap.chapterType === 'APP'
                )
                .map(filterModChap => (
                  <div key={filterModChap._id}>
                    <Button
                      onClick={() => handleChapterSelect(filterModChap)}
                      disabled>
                      {filterModChap.chapterNumber}
                    </Button>
                    <span style={{ color: 'green' }}>
                      <MdOutlineFiberNew size={28} />
                    </span>
                    <span
                      style={{
                        color:
                          modChapter._id === filterModChap._id
                            ? 'orange'
                            : '#fff',
                      }}>
                      {modChapter._id === filterModChap._id ? (
                        <BiCheckboxSquare size={30} />
                      ) : (
                        <BiCheckbox size={30} />
                      )}
                    </span>
                  </div>
                ))}
              {modRule.refRule?.chapterRef
                .filter(mchap => mchap.chapterType === 'CH')
                .map(el => (
                  <div key={el._id}>
                    <Button
                      onClick={() => handleChapterSelect(el)}
                      disabled={modRule.modChapterRef.some(
                        modChap => modChap.oldChapterId === el._id
                      )}>
                      {el.chapterNumber}
                    </Button>
                    <span>
                      {modRule.modChapterRef.map(modChap =>
                        modChap.oldChapterId === el._id &&
                        modChap.modType === 'replace' ? (
                          <BiCheck size={28} color='green' />
                        ) : null
                      )}
                      {modRule.modChapterRef.map(modChap =>
                        modChap.oldChapterId === el._id &&
                        modChap.modType === 'delete' ? (
                          <MdDeleteForever size={28} color='red' />
                        ) : null
                      )}
                    </span>
                    <span
                      style={{
                        color: modChapter._id === el._id ? 'orange' : '#fff',
                      }}>
                      {modChapter._id === el._id ? (
                        <BiCheckboxSquare size={30} />
                      ) : (
                        <BiCheckbox size={30} />
                      )}
                    </span>
                  </div>
                ))}
              {modRule.refRule?.chapterRef
                .filter(mchap => mchap.chapterType === 'APP')
                .map(el => (
                  <div key={el._id}>
                    <Button
                      onClick={() => handleChapterSelect(el)}
                      disabled={modRule.modChapterRef.some(
                        modChap => modChap.oldChapterId === el._id
                      )}>
                      {el.chapterNumber}
                    </Button>
                    <span>
                      {modRule.modChapterRef.map(modChap =>
                        modChap.oldChapterId === el._id &&
                        modChap.modType === 'replace' ? (
                          <BiCheck size={28} color='green' />
                        ) : null
                      )}
                      {modRule.modChapterRef.map(modChap =>
                        modChap.oldChapterId === el._id &&
                        modChap.modType === 'delete' ? (
                          <MdDeleteForever size={28} color='red' />
                        ) : null
                      )}
                    </span>
                    <span
                      style={{
                        color: modChapter._id === el._id ? 'orange' : '#fff',
                      }}>
                      {modChapter._id === el._id ? (
                        <BiCheckboxSquare size={30} />
                      ) : (
                        <BiCheckbox size={30} />
                      )}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='draftpage__catelist'>
      <div className='draftpage__catelist__header'>
        <div className='draftpage__catelist__tabs'>
          <span
            className={`draftpage__catelist__tab ${
              lan === 'kor' ? ' open' : ''
            }`}
            role='button'
            aria-hidden='true'
            onClick={() => handlelan(0)}>
            KOREAN
          </span>
          <span
            className={`draftpage__catelist__tab ${
              lan === 'eng' ? ' open' : ''
            }`}
            role='button'
            aria-hidden='true'
            onClick={() => handlelan(1)}>
            ENGLISH
          </span>
          <span className='draftpage__catelist__tab__glide' />
        </div>
      </div>
      <div className='draftpage__catelist__collapse'>
        {isLoading ? (
          <Spinner
            animation='border'
            role='status'
            style={{
              width: '4rem',
              height: '4rem',
              margin: 'auto',
            }}>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        ) : (
          categories &&
          categories.map(cate => <CollapseItem key={cate._id} cate={cate} />)
        )}
      </div>
    </div>
  );
};

export default AmendRuleSidelist;
