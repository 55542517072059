import React, { useRef, useState, useEffect } from 'react';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../scss/Dev/NewDevRule.scss';
import Select from 'react-select';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';
import RuleChapContents from '../ModRule/RuleChapContents';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';

window.Buffer = window.Buffer || require('buffer').Buffer;

const DevModify = () => {
  // 개발자 전용 룰 개정 화면 <개정등록>
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navi = useNavigate();
  const editorRef = useRef(null);
  const [category, setCategory] = useState({ label: '', value: '' });
  const [options, setOptions] = useState([]);
  const [ruleOptions, setRuleOptions] = useState([]);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [lan, setLan] = useState('kor');
  const [rule, setRule] = useState({ _id: '', ruleNumber: '', ruleName: '' });
  const [chapter, setChapter] = useState({
    _id: '',
    chapterNumber: '',
    chapterName: '',
  });
  const [chapContent, setChapContent] = useState('');
  const [chapterContents, setChapterContents] = useState({
    header: '',
    children: [],
  });
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const [currentDate, setCurrentDate] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const initcategorySelect = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/category/all/kor',
      });
      const cateOptionsArr = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(cateOptionsArr);
    };
    initcategorySelect();
  }, []);

  useEffect(() => {
    const fetchcategoryLan = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/all/${lan}`,
      });
      const cateOptionsArr = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(cateOptionsArr);
    };
    fetchcategoryLan();
  }, [lan]);

  const handleCategoryValue = async e => {
    setCategory(e);
    const response = await axiosAuth({
      method: 'GET',
      url: `/api/category/${e.value.categoryNumber}/${auth.id}`,
    });
    const { ruleRef } = response.data;
    const ruleOptionsArr = ruleRef.map(ruleOption => ({
      label: `${ruleOption.ruleNumber} : ${ruleOption.ruleName}`,
      value: ruleOption,
    }));
    setRuleOptions(ruleOptionsArr);
  };

  const handleRuleValue = async e => {
    // chapter select options 업데이트
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/modrule/rule',
        data: { id: e.value._id },
      });
      const { chapterRef, revisedAt } = res.data;

      setRule({ ...rule, ...res.data });
      const chapterOptionsCopy = chapterRef.map(chapterOption => ({
        label: chapterOption.chapterNumber,
        value: chapterOption,
      }));
      setCurrentDate(
        timezoneFormat(
          utcToZonedTime(
            toDate(revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        )
      );
      setChapterOptions(chapterOptionsCopy);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChapterValue = async e => {
    try {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/chapter/${e.value._id}`,
      });
      const { _id, chapterNumber, chapterName, content } = response.data;
      setChapter(prev => ({
        ...prev,
        _id,
        chapterNumber,
        chapterName,
      }));
      setChapContent(content);
    } catch (error) {
      console.log(error);
      toast(t('modrule.chapter-communication-failed'));
    }
  };

  const onChangeRuleNumber = e => {
    setRule({ ...rule, ruleNumber: e.target.value });
  };

  const onChangeRuleName = e => {
    setRule({ ...rule, ruleName: e.target.value });
  };

  const onChangeChapterName = e => {
    setChapter({ ...chapter, chapterName: e.target.value });
  };

  const onChangeChapterNumber = e => {
    setChapter({ ...chapter, chapterNumber: e.target.value });
  };

  const createContents = docData => {
    const domparser = new DOMParser();
    const docStr = domparser.parseFromString(docData, 'text/html');
    if (docData.match(/<h2>/)) {
      const h2title = docStr
        .getElementsByTagName('h2')[0]
        .innerHTML.replace(/(<([^>]+)>)/gi, '')
        .replace(/&nbsp;/g, '')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>');
      setChapterContents(prev => ({
        ...prev,
        header: h2title,
      }));
    } else {
      setChapterContents(prev => ({
        ...prev,
        header: '',
      }));
    }
    if (docData.match(/<h3>/)) {
      const h3title = [...docStr.getElementsByTagName('h3')].map(tag =>
        tag.innerHTML
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/&nbsp;/g, '')
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
      );
      setChapterContents(prev => ({
        ...prev,
        children: [...h3title],
      }));
    } else {
      setChapterContents(prev => ({
        ...prev,
        children: [],
      }));
    }
  };

  const chapterEditSubmit = async () => {
    createContents(chapContent);
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/modRule/edit',
        data: {
          chapterId: chapter._id,
          chapterName: chapter.chapterName,
          chapterNumber: chapter.chapterNumber,
          content: chapContent,
          chapterContents,
        },
      });
      toast(
        `(${response.status})${category.value} - ${t(
          'modrule.success-add-chap'
        )}`
      );
      navi('/modrule');
    } catch (error) {
      console.log(error);
      toast(t('modrule.submit-chap-failed'));
    }
  };
  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  function customUploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(file => {
            data.append('file', file);
            axiosMultipart
              .post('/api/modrule/imageupload', data)
              .then(res => resolve({ default: res.data.imageUrl }))
              .catch(err => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    const editorinstance = editor;
    editorinstance.plugins.get('FileRepository').createUploadAdapter =
      loader => {
        return customUploadAdapter(loader);
      };
  }

  return (
    <div className='devdraft'>
      <div className='devdraft__side'>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='editor-lanbtn-kor'>
            {t('modrule.select-rule-lan')}
          </label>

          <ButtonGroup className='devdraft__side__lanradiobtn'>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={radio.value}
                id={`radio-${idx}`}
                type='radio'
                variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                name='radio'
                value={radio.value}
                checked={lan === radio.value}
                onChange={e => setLan(e.currentTarget.value)}>
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>

        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='select-category'>
            {t('modrule.cate')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.cate-placeholder')}</div>}
            isSearchable
            id='select-category'
            className='devdraft__side__category__select'
            options={options}
            onChange={handleCategoryValue}
          />
        </div>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='select-rule'>
            {t('modrule.rule')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.rule-placeholder')}</div>}
            isSearchable
            id='select-rule'
            className='devdraft__side__rule__select'
            options={ruleOptions}
            onChange={handleRuleValue}
          />
        </div>

        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='input-rulenumber'>
            {t('modrule.rule')} No.
          </label>
          <input
            type='text'
            className='form-control input__rulenumber'
            id='input-rulenumber'
            placeholder={`${t('modrule.rule')} No.`}
            value={rule.ruleNumber || ''}
            onChange={onChangeRuleNumber}
            required
          />
        </div>
        <div className='devdraft__rule__wrapper'>
          <label className='devdraft__label' htmlFor='input-rulename'>
            {t('modrule.rule-title')}
          </label>
          <input
            type='text'
            className='form-control input-rulename'
            id='input-rulename'
            placeholder={t('modrule.rule-title')}
            value={rule.ruleName || ''}
            onChange={onChangeRuleName}
            required
          />
        </div>
        <div className='devdraft__side__chapterinfos'>
          <div className='devdraft__side__chaptercontents'>
            <h5>{t('modrule.List')}</h5>
            <RuleChapContents
              chapContent={chapContent}
              chapterContents={chapterContents}
              setChapterContents={setChapterContents}
            />
          </div>
        </div>
      </div>

      <div className='devdraft__main'>
        <div className='devdraft__main__header'>
          <label className='devdraft__label' htmlFor='select-chapternumber'>
            {t('modrule.chap')}
            <Select
              styles={blackStyles}
              placeholder={<div>{t('revStatus.select-chap-placeholder')}</div>}
              isSearchable
              id='select-chapternumber'
              className='devdraft__side__category__select'
              options={chapterOptions}
              onChange={handleChapterValue}
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chapternumber'>
            {t('modrule.select-chap-num')}
            <input
              type='text'
              className='form-control input-chaptername'
              id='input-chapternumber'
              placeholder={t('modrule.select-chap-num')}
              value={(chapter.chapterNumber && chapter.chapterNumber) || ''}
              onChange={onChangeChapterNumber}
              required
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chaptername'>
            {t('modrule.chap-title')}
            <input
              type='text'
              className='form-control input-chaptername'
              id='input-chaptername'
              placeholder={t('modrule.chap-title')}
              value={(chapter.chapterName && chapter.chapterName) || ''}
              onChange={onChangeChapterName}
              required
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chapterdate'>
            {t('modrule.enactAmend-date')}
            <span>{currentDate || ''}</span>
          </label>
        </div>
        <div className='devdraft__main__editor' id='document-container'>
          <div id='toolbar-container' className='toolbar-container' />
          <div id='editor-container' className='editor-container' />
          <CKEditor
            ref={editorRef}
            editor={Editor}
            data={chapContent}
            config={{
              heading: {
                options: [
                  {
                    model: 'paragraph',
                    title: i18n.language === 'ko' ? '본문' : 'CONTENT',
                    class: 'ck-heading_paragraph',
                  },
                  {
                    model: 'heading2',
                    view: 'h2',
                    title: i18n.language === 'ko' ? '제목 1' : 'TITLE 1',
                    class: 'ck-heading_heading2',
                  },
                  {
                    model: 'heading3',
                    view: 'h3',
                    title: i18n.language === 'ko' ? '제목 2' : 'TITLE 2',
                    class: 'ck-heading_heading3',
                  },
                  {
                    model: 'heading4',
                    view: 'h4',
                    title: i18n.language === 'ko' ? '제목 3' : 'TITLE 3',
                    class: 'ck-heading_heading4',
                  },
                ],
              },
              extraPlugins: [uploadPlugin],
              autosave: {
                save(editor) {
                  if (editor) {
                    const data = editor.getData();
                    setChapContent(data);
                  }
                },
                waitingTime: 5000,
              },
            }}
            onReady={editor => {
              const editorContainer =
                document.querySelector('#editor-container');
              const toolbarContainer =
                document.querySelector('#toolbar-container');

              toolbarContainer.appendChild(editor.ui.view.toolbar.element);
              editorContainer.appendChild(editor.ui.view.editable.element);

              editor.ui.update();
            }}
            onError={(error, editor) => {
              if (editor.willEditorRestart) {
                editor.ui.view.toolbar.element.remove();
              }
              if (error) {
                console.log(error);
              }
            }}
          />
        </div>
        <div className='devdraft__main__footer'>
          <Button type='submit' onClick={chapterEditSubmit}>
            {t('modrule.amend-draft')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DevModify;
