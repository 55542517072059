/* eslint-disable no-shadow */

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, Collapse, Nav } from 'react-bootstrap';
import { ImBooks } from 'react-icons/im';
import { BiHighlight, BiNotification } from 'react-icons/bi';
import { BsFileEarmarkRuled } from 'react-icons/bs';
import { RiUserSettingsLine } from 'react-icons/ri';
import { HiOutlineUserGroup, HiPlus } from 'react-icons/hi';
import { AiFillRead } from 'react-icons/ai';
import { TbSettings } from 'react-icons/tb';
import {
  MdBarChart,
  MdNotifications,
  MdOutlineScreenSearchDesktop,
} from 'react-icons/md';
import '../scss/SideNav.scss';
import ToggleList from './ToggleList';
import useAuth from '../common/hooks/useAuth';
import useAxiosAuth from '../common/hooks/useAxiosAuth';
import Roles from '../common/auth/Roles';

const SideNav = ({ path }) => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const axiosAuth = useAxiosAuth();
  const [notiCount, setNotiCount] = useState();

  useEffect(() => {
    if (
      path.includes('/ruleinfo') ||
      path.includes('/forms') ||
      path.includes('/teammanual')
    ) {
      setOpen(true);
      return;
    }
    setOpen(false);
  }, [path]);

  const ruleinfoIn = ['/ruleinfo', '/compare', '/dual'];

  useEffect(() => {
    const noti = async () => {
      const userId = auth.id;
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/notice/check/${userId}`,
        });
        setNotiCount(res.data.unReadNum);
      } catch (err) {
        console.log(err);
      }
    };
    if (auth.accessToken) {
      noti();
    }
  }, [auth]);

  if (auth.accessToken && path !== '/') {
    return (
      <Nav className='sidenav'>
        <div className='sidenav__Icon'>
          <NavLink to='notices' className='sidenav__Icon__link'>
            <div
              className={`sidenav__Icon__link__block ${
                path.includes('/notices') ? 'openmenu' : ''
              }`}>
              {notiCount !== 0 && auth ? (
                <Badge pill bg='warning'>
                  {notiCount}
                </Badge>
              ) : (
                <HiPlus className='zeroIcon' />
              )}
              <MdNotifications className='sidenav__Icon__link__block__style' />

              <span>{t('sidenav.notice')}</span>
            </div>
          </NavLink>
          <NavLink to='ruleinfo' className='sidenav__Icon__link'>
            <div
              className={`sidenav__Icon__link__block ${
                ruleinfoIn.some(el => path.includes(el)) ? 'openmenu' : ''
              }`}>
              <ImBooks
                className='sidenav__Icon__link__block__style'
                aria-expanded={open}
              />
              <span>{t('sidenav.ruleinfo')}</span>
            </div>
          </NavLink>
          <NavLink
            to={`/teammanual/${auth?.org}`}
            className='sidenav__Icon__link'>
            <div
              className={`sidenav__Icon__link__block ${
                path.includes('/teammanual') ? 'openmenu' : ''
              }`}>
              <BsFileEarmarkRuled className='sidenav__Icon__link__block__style' />
              <span>{t('sidenav.teammanual')}</span>
            </div>
          </NavLink>
          <NavLink to='/revstatus' className='sidenav__Icon__link'>
            <div
              className={`sidenav__Icon__link__block ${
                path.includes('/revstatus') ? 'openmenu' : ''
              }`}>
              <BiNotification className='sidenav__Icon__link__block__style' />
              <span>{t('sidenav.revstatus')}</span>
            </div>
          </NavLink>
          {auth.accessToken &&
            auth.roles.find(role =>
              [
                Roles.ADMIN,
                Roles.DEVELOPER,
                Roles.RULEMANAGER,
                Roles.APPROVE,
              ].includes(role)
            ) && (
              <NavLink to='/modrule/dcn' className='sidenav__Icon__link'>
                <div
                  className={`sidenav__Icon__link__block ${
                    path.includes('/modrule') ? 'openmenu' : ''
                  }`}>
                  <BiHighlight className='sidenav__Icon__link__block__style' />
                  <span>{t('sidenav.modrule')}</span>
                </div>
              </NavLink>
            )}
          <NavLink to='/reference' className='sidenav__Icon__link'>
            <div
              className={`sidenav__Icon__link__block ${
                path.includes('/reference') ? 'openmenu' : ''
              }`}>
              <AiFillRead className='sidenav__Icon__link__block__style' />
              <span>{t('sidenav.reference')}</span>
            </div>
          </NavLink>
          {auth.accessToken &&
            auth.roles.find(role =>
              [Roles.ADMIN, Roles.DEVELOPER, Roles.USERLOG].includes(role)
            ) && (
              <NavLink to='/userlog' className='sidenav__Icon__link'>
                <div
                  className={`sidenav__Icon__link__block ${
                    path.includes('/userlog') ? 'openmenu' : ''
                  }`}>
                  <MdOutlineScreenSearchDesktop className='sidenav__Icon__link__block__style' />
                  <span>{t('sidenav.userlog')}</span>
                </div>
              </NavLink>
            )}
          {auth.accessToken &&
          auth.roles.find(role =>
            [Roles.ADMIN, Roles.DEVELOPER].includes(role)
          ) ? (
            <>
              <NavLink to='/orgchart' className='sidenav__Icon__link'>
                <div
                  className={`sidenav__Icon__link__block ${
                    path.includes('/orgchart') || path.includes('/orgmembers')
                      ? 'openmenu'
                      : ''
                  }`}>
                  <HiOutlineUserGroup className='sidenav__Icon__link__block__style' />
                  <span>{t('sidenav.organization')}</span>
                </div>
              </NavLink>
              <NavLink to='/managerauth' className='sidenav__Icon__link'>
                <div
                  className={`sidenav__Icon__link__block ${
                    path.includes('/managerauth') ? 'openmenu' : ''
                  }`}>
                  <RiUserSettingsLine className='sidenav__Icon__link__block__style' />
                  <span>{t('sidenav.authorization')}</span>
                </div>
              </NavLink>
            </>
          ) : null}
          <NavLink to='/rulechart' className='sidenav__Icon__link'>
            <div
              className={`sidenav__Icon__link__block ${
                path.includes('/rulechart') ? 'openmenu' : ''
              }`}>
              <MdBarChart className='sidenav__Icon__link__block__style' />
              <span>{t('sidenav.rulechart')}</span>
            </div>
          </NavLink>
        </div>
        <div className='sidenav__setting'>
          <NavLink to='/setting'>
            <div className='sidenav__setting__block'>
              <TbSettings className='sidenav__setting__block__Icon' />
            </div>
          </NavLink>
        </div>
        <Collapse in={open} dimension='width' className='toggle'>
          <div className='toggle'>
            <ToggleList />
          </div>
        </Collapse>
      </Nav>
    );
  }
  return null;
};

export default React.memo(SideNav);
