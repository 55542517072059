import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Button, Pagination } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import { formatDate } from '../../common/utils/tzUtiles';

const NoticeTable = ({ notice, index }) => {
  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <NavLink to={`${notice._id}`}>{notice.title}</NavLink>
      </td>
      <td>{notice?.url?.length}</td>
      <td>
        {notice.updatedAt
          ? formatDate(notice.updatedAt)
          : formatDate(notice.createdAt)}
      </td>
      <td> {notice.count}</td>
    </tr>
  );
};
const NoticeList = () => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const navigate = useNavigate();
  const goNew = () => {
    navigate('/notices/new');
  };

  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchNotice = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/notice/all',
      });
      setNotices(response.data);
    };
    fetchNotice();
    return () => {
      controller.abort();
    };
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 15;

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentNotice = notices.slice(indexOfFirstLog, indexOfLastLog);

  const totalPages = Math.ceil(notices.length / logsPerPage);
  const maxPageNumbers = 5;

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };
  const getPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= maxPageNumbers) {
      for (let i = 1; i <= totalPages; i += 1) {
        pageNumbers.push(i);
      }
    } else if (currentPage <= Math.ceil(maxPageNumbers / 2)) {
      for (let i = 1; i <= maxPageNumbers; i += 1) {
        pageNumbers.push(i);
      }
    } else if (currentPage >= totalPages - Math.floor(maxPageNumbers / 2)) {
      for (let i = totalPages - maxPageNumbers + 1; i <= totalPages; i += 1) {
        pageNumbers.push(i);
      }
    } else {
      const middleNumber = Math.floor(maxPageNumbers / 2);
      for (
        let i = currentPage - middleNumber;
        i <= currentPage + middleNumber;
        i += 1
      ) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const renderPageNumbers = () => {
    const pageNumbers = getPageNumbers();

    return pageNumbers.length < 1 ? null : (
      <>
        <Pagination.First
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
        />
        <Pagination.Prev
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        />

        {pageNumbers.map(pageNumber => (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => handlePageChange(pageNumber)}>
            {pageNumber}
          </Pagination.Item>
        ))}

        <Pagination.Next
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        />
        <Pagination.Last
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
        />
      </>
    );
  };

  return (
    <div>
      <section className='noticeMain__search'>
        {/* ROLE_IDX1 => 개발자 / ROLE_IDX9 => 공지사항 관리자 */}
        {auth.roles &&
          (auth.roles.includes('ROLE_IDX9') ||
            auth.roles.includes(process.env.REACT_APP_DEVELOPER)) && (
            <Button className='noticeMain__search__createbtn' onClick={goNew}>
              {t('button.post')}
            </Button>
          )}
      </section>
      {/* 공지사항 리스트 */}
      <section className='noticeMain__list'>
        <Table>
          <thead>
            <tr>
              <td>{t('table.index')}</td>
              <td>{t('table.title')}</td>
              <td>{t('table.attachment')}</td>
              <td>{t('table.date')}</td>
              <td>{t('table.views')}</td>
            </tr>
          </thead>
          <tbody>
            {currentNotice &&
              currentNotice
                .reverse()
                .map((notice, index) => (
                  <NoticeTable key={notice._id} index={index} notice={notice} />
                ))}
          </tbody>
        </Table>
      </section>

      {/* 페이지네이션 */}
      <section className='noticeMain__page'>
        <Pagination>{renderPageNumbers()}</Pagination>
      </section>
    </div>
  );
};

export default NoticeList;
