import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { TbSquareChevronDown, TbSquareChevronUp } from 'react-icons/tb';
import parse from 'html-react-parser';
import { BiUndo } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import MobileBreadCrumb from '../MobileBreadCrumb';
import '../../scss/Mobile/MobileRule/MobileRule.scss';
import { formatDate } from '../../common/utils/tzUtiles';

const Rule = () => {
  const { ruleNum, chapterNumber, searchText } = useParams();
  const axiosAuth = useAxiosAuth();
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [btnIndex, setbtnIndex] = useState(null);
  const [targetRule, setTargetRule] = useState(null); //! Rule
  const [content, setContent] = useState([]); //! ChapterRef
  const [dates, setDates] = useState([]); //! 구규정 날짜 list
  const [targetDate, setTargetDate] = useState(null); //! 구규정 날짜 select의 value
  const [chapList, setChapList] = useState({ label: '', value: '' }); //! ChapterRef select의 options
  const [targetChap, setTargetChap] = useState({ label: '', value: '' }); //! ChapterRef select의 value
  const [bCount, setBCount] = useState({ current: 0, total: 0 }); //! 통합검색 결과 갯수
  const navi = useNavigate();
  const { t } = useTranslation();

  // ! 통합검색결과 클릭 시 해당 결과의 챕터로 스크롤 기능
  const scrollRef = useRef(null);
  const handleTargetScroll = index => {
    if (searchText && scrollRef.current && scrollRef.current.hasChildNodes()) {
      const bElements = document.getElementsByTagName('b');
      scrollRef.current.children[index].scrollIntoView({
        behavior: 'auto',
        block: 'start',
        inline: 'start',
      });
      setBCount(prev => ({
        ...prev,
        total: bElements.length,
      }));
    }
  };

  // ! 통합검색어 <b></b>태그 처리
  const replaceStrong = (text, con) => {
    const regex = new RegExp(text, 'gi');
    const cateIndex = con.toLowerCase().indexOf(text.toLowerCase());
    const originStr = con.slice(cateIndex, cateIndex + text.length);
    const contentStrong = con.replace(regex, `<b>${originStr}</b>`);
    return contentStrong;
  };

  useEffect(() => {
    let isMounted = true;

    const initRule = async () => {
      try {
        const res1 = await axiosAuth({
          method: 'GET',
          url: `/api/rule/${ruleNum}`,
        });
        const {
          _id,
          chapterRef,
          ruleNumber,
          ruleName,
          lan,
          revisedAt,
          refCategory,
        } = res1.data;
        setTargetRule(res1.data);
        if (isMounted) {
          if (chapterNumber || searchText) {
            const targetindex = chapterRef.findIndex(
              el => el.chapterNumber === chapterNumber
            );
            const changeChap = {
              ...chapterRef[targetindex],
              content: replaceStrong(
                searchText,
                chapterRef[targetindex].content
              ),
            };
            chapterRef.splice(targetindex, 1, changeChap);
          }
          setContent(chapterRef);
          // chapter select
          const ChapOptions = chapterRef.map((el, i) => ({
            index: i,
            label: el.chapterName,
            value: el,
          }));
          setChapList(ChapOptions);
          setTargetChap(ChapOptions[0]);
          // date select
          setTargetDate({
            label: formatDate(revisedAt),
            value: res1.data._id,
          });
          try {
            const res2 = await axiosAuth({
              method: 'GET',
              url: `/api/rule/rules/${lan}/${ruleNumber}`,
            });
            const datesCopy = [];
            for (let i = 0; i < res2.data.length; i += 1) {
              datesCopy.push({
                label: formatDate(res2.data[i].revisedAt),
                value: res2.data[i]._id,
              });
            }
            setDates(datesCopy);
          } catch (err2) {
            if (isMounted) {
              console.log(`error res2 : ${err2}`);
            }
          }

          const breadCrumbsArr = [];
          breadCrumbsArr.push({
            path: 'category',
            _id: refCategory.id,
            name: refCategory.category,
            active: '',
          });
          breadCrumbsArr.push({
            path: 'rule',
            _id,
            name: `[${ruleNumber}] : ${ruleName}`,
            active: 'active',
          });
          setBreadCrumbs(breadCrumbsArr);
          // 통합검색시
          if (searchText) {
            const targetindex = chapterRef.findIndex(
              el => el.chapterNumber === chapterNumber
            );
            handleTargetScroll(targetindex);
          }
        }
      } catch (err1) {
        console.log(`error res1 : ${err1}`);
      }
    };
    initRule();

    return () => {
      isMounted = false;
    };
  }, [ruleNum]);

  // ! Chapter Select Box
  const handleTargetChap = e => {
    const { index } = e;
    setTargetChap(e);
    if (scrollRef.current && scrollRef.current.hasChildNodes()) {
      scrollRef.current.children[index].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setbtnIndex(index);
    }
  };

  // ! oldRule date
  const handleTargetRule = e => {
    setTargetDate(e);
  };

  // ! 통합검색 결과 up down
  const handleMinusScroll = () => {
    if (
      document.getElementsByTagName('b') &&
      document.getElementsByTagName('b').length > 0 &&
      bCount.current > 1
    ) {
      document.getElementsByTagName('b')[bCount.current - 2].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setBCount(prev => ({ ...prev, current: prev.current - 1 }));
    }
  };
  const handlePlusScroll = () => {
    if (
      document.getElementsByTagName('b') &&
      document.getElementsByTagName('b').length >= 0 &&
      bCount.current < bCount.total
    ) {
      if (bCount.current !== bCount.total) {
        // (bCount.current === bCount.total)인 경우 스크롤 이벤트 막음
        document.getElementsByTagName('b')[bCount.current].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        setBCount(prev => ({ ...prev, current: prev.current + 1 }));
      }
    }
  };

  const goBack = () => {
    navi(-1);
  };

  return (
    <div className='mobileRule'>
      <div className='mobileRule__header__back'>
        <Button onClick={goBack}>
          <BiUndo type='button' size={26} />
        </Button>
      </div>

      <div className='mobileRule__header'>
        <MobileBreadCrumb breadCrumbs={breadCrumbs} />
      </div>

      <div className='mobileRule__subHeader'>
        <div className='mobileRule__subHeader__selectBox'>
          <div className='chapterItem'>
            <Select
              value={targetChap}
              isSearchable
              placeholder={t('revStatus.select-chap-placeholder')}
              options={chapList}
              onChange={handleTargetChap}
              isDisabled={searchText}
              styles={{
                control: provided => ({
                  ...provided,
                  border: '1px solid #ccc',
                  // border: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  height: '2vh',
                  fontSize: '0.7rem',
                  padding: '0',
                  outline: 0,
                }),
                valueContainer: provided => ({
                  ...provided,
                }),
                indicatorsContainer: base => ({
                  ...base,
                }),
                menu: base => ({
                  ...base,
                  fontSize: '0.7rem',
                  zIndex: 9999,
                  marginTop: '0.5vh',
                }),
              }}
            />
          </div>
          <div className='dateItem'>
            <Select
              value={targetDate}
              isSearchable
              placeholder={t('components.ruleInfo.amend-select')}
              options={dates}
              onChange={handleTargetRule}
              styles={{
                control: provided => ({
                  ...provided,
                  border: '1px solid #ccc',
                  // border: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  height: '2vh',
                  fontSize: '0.7rem',
                  padding: '0',
                  outline: 0,
                }),
                valueContainer: provided => ({
                  ...provided,
                }),
                indicatorsContainer: base => ({
                  ...base,
                }),
                menu: base => ({
                  ...base,
                  fontSize: '0.7rem',
                  zIndex: 9999,
                  marginTop: '0.5vh',
                }),
              }}
            />
          </div>
        </div>
        {/* 통합검색 시 */}
        <div>
          {searchText && (
            <div className='mobileRule__subHeader__targetguide'>
              <div className='mobileRule__subHeader__targetguide__textBox'>
                <span>{t('searchbar.search-text')} : </span>
                <span>{` ${searchText}`}</span>
                <span>{`(${bCount.current}/${bCount.total})`}</span>
              </div>
              <div className='mobileRule__subHeader__targetguide__btnBox'>
                <Button
                  variant='secondary'
                  type='button'
                  onClick={() => handleMinusScroll()}>
                  <TbSquareChevronUp size={20} />
                </Button>
                <Button
                  variant='secondary'
                  type='button'
                  onClick={() => handlePlusScroll()}>
                  <TbSquareChevronDown size={20} />
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='mobileRule__body'>
        <div id='printArea' className='mobileRule__body__content'>
          <div id='printArea_wrapper' className='mobileRuleContent'>
            <div
              id='override-css-mobileRuleTitle'
              className='mobileRuleContent__title'>
              <span>{targetRule && targetRule.ruleName}</span>
            </div>
            <div
              ref={scrollRef}
              id='overide-css-mobileRuleCon'
              className='mobileRuleContent__items'>
              {btnIndex
                ? content &&
                  content.map((el, index) => (
                    <div key={el._id} className='ck-mobileContent'>
                      {btnIndex === index &&
                        parse(typeof el.content === 'string' ? el.content : '')}
                    </div>
                  ))
                : content &&
                  content.map(el => (
                    <div key={el._id} className='ck-mobileContent'>
                      {parse(typeof el.content === 'string' ? el.content : '')}
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rule;
