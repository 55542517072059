import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'react-bootstrap';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const LoginRankBox = () => {
  // 접속이력 top5
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [loginRanking, setLoginRanking] = useState([]);

  useEffect(() => {
    const ranking = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/log/auth/limit5`,
        });

        const loginCopy = res.data.map(el => {
          return {
            _id: el._id, // Assuming _id is available in the response
            email: el.email,
            username: el.username,
            count: el.count,
          };
        });
        setLoginRanking(loginCopy);
      } catch (err) {
        console.log(err);
      }
    };

    ranking();
  }, []);

  return (
    <div className='dateSearch__rightBox'>
      <div className='dateSearch__loginRanking'>
        <span>{t('userlog.loginRank.title')}</span>
        <div>
          <Table>
            <thead>
              <tr>
                <th>{t('userlog.loginRank.user')}</th>
                <th>{t('userlog.loginRank.views')}</th>
              </tr>
            </thead>
            <tbody>
              {loginRanking &&
                loginRanking.map(el => (
                  <tr key={el._id}>
                    <td>{el.username}</td>
                    <td>{el.count}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default LoginRankBox;
