import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import '../../scss/TeamManual/TeamManualNew.scss';
import { BiUndo } from 'react-icons/bi';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useAuth from '../../common/hooks/useAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import { axiosAuth } from '../../common/axios/api';

const TeamManualNew = () => {
  const { i18n, t } = useTranslation();
  const navi = useNavigate();
  const { auth } = useAuth();
  const axiosMultipart = useAxiosMultipart();
  const [loading, setLoading] = useState(false);
  const [depList, setDepList] = useState({ label: '', value: '' });
  const [file, setFile] = useState(null);
  const [depLabel, setDepLabel] = useState({});
  const [currentManual, setCurrentManual] = useState({
    manualNum: '',
    manualName: '',
    manualSum: '',
    manualNote: '',
  });
  const { manualNum, manualName, manualSum, manualNote } = currentManual;

  useEffect(() => {}, []);

  const goBack = () => {
    navi(-1);
  };

  const handleDel = () => {
    if (i18n.language === 'ko') {
      if (window.confirm('작성을 취소하시겠습니까?')) {
        navi(-1);
      }
    } else if (i18n.language === 'en') {
      if (window.confirm('Are you sure you want to cancel creation?')) {
        navi(-1);
      }
    }
  };

  // 권한있는 팀매뉴얼의 팀 리스트
  useEffect(async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/manualPer/manuals',
        data: {
          email: auth.email,
          company: auth.company,
        },
      });
      const optionsCopy = res.data.map(dep => ({
        label: dep.name,
        value: dep._id,
      }));
      setDepList(optionsCopy);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const targetDep = e => {
    setDepLabel(e);
  };

  const changeTargetInputs = e => {
    const { value, name } = e.target;
    setCurrentManual({
      ...currentManual,
      [name]: value,
    });
  };

  const targetFile = e => {
    if (e) {
      const fileCopy = e.target.files[0].name;
      const startIndex = fileCopy.indexOf(' ');
      const endIndex = fileCopy.lastIndexOf('.');

      setFile(e.target.files[0]);
      setCurrentManual(prevState => ({
        ...prevState,
        manualNum: fileCopy.slice(0, startIndex),
        manualName: fileCopy.slice(startIndex + 1, endIndex),
      }));
    }
  };

  const handleUpload = async () => {
    if (window.confirm(t('components.teamManual.manual-register-q'))) {
      const formData = new FormData();
      formData.append('orgId', depLabel.value);
      formData.append('writer', auth.id);
      formData.append('manualNumber', `${depLabel.value}-${manualNum}`);
      formData.append('manualName', manualName);
      formData.append('summary', manualSum);
      formData.append('note', manualNote);
      formData.append('file', file);

      setLoading(true);
      try {
        const res = await axiosMultipart({
          method: 'POST',
          url: '/api/manual/new',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
        });
        if (res.status === 200) {
          setLoading(false);
          toast(t('components.teamManual.manual-register'));
          navi(-1);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className='manualNew mainsection'>
      <div className='manualNew__header'>
        <h4>{t('manual.title-write')}</h4>
        <Button variant='secondary'>
          <BiUndo type='button' onClick={goBack} />
        </Button>
        {loading && (
          <div className='manualNew__header__spinner'>
            <Spinner
              as='span'
              animation='border'
              // animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
            <span className='spinnerText'>{t('manual.registering')}</span>
          </div>
        )}
      </div>
      <section className='manualNew__main'>
        <form action='upload' encType='multipart/form-data'>
          <div className='manualNew__main__inputBox'>
            <div className='manualNew__main__inputBox__topContents'>
              <dl>
                <dt>{t('table.writer')} : </dt>
                <dd>
                  <input
                    type='text'
                    defaultValue={auth.username}
                    className='form-control'
                    disabled
                  />
                </dd>
              </dl>
              <dl>
                <dt>{t('manual.cate-team')} : </dt>
                <dd>
                  <Select
                    // styles={blackStyles}
                    placeholder={t('manual.placeholder-team')}
                    isSearchable
                    // value={depLabel}
                    id='select-team'
                    // className=''
                    options={depList}
                    onChange={targetDep}
                    styles={{
                      control: provided => ({
                        ...provided,
                        border: '1px solid #ccc',
                        boxShadow: 'none',
                        width: '20vw',
                        outline: 0,
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                        marginTop: '0.5vh',
                      }),
                    }}
                  />
                </dd>
              </dl>
            </div>
            <div className='manualNew__main__inputBox__middleContents'>
              <Form.Group
                controlId='formFileMultiple'
                encType='multipart/form-data'
                className='mb-3 manualNew__main__inputBox__middleContents__form'>
                <Form.Label>{t('table.attachment')}: </Form.Label>
                <Form.Control
                  type='file'
                  name='file'
                  multiple
                  onChange={targetFile}
                />
              </Form.Group>
            </div>
            <div className='manualNew__main__inputBox__bottomContents'>
              <dl>
                <dt>{t('manual.manual-num')} :</dt>
                <dd>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={t('manual.placeholder-Num')}
                    defaultValue={manualNum}
                    // value={manualNum}
                    name='manualNum'
                    onChange={changeTargetInputs}
                  />
                </dd>
              </dl>
              <dl>
                <dt>{t('manual.manual-title')} :</dt>
                <dd>
                  <input
                    type='text'
                    placeholder={t('manual.placeholder-Title')}
                    className='form-control'
                    defaultValue={manualName}
                    // value={manualName}
                    name='manualName'
                    onChange={changeTargetInputs}
                  />
                </dd>
              </dl>
            </div>
          </div>

          <div className='manualNew__main__additional'>
            <dl>
              <dt>{t('manual.cate-manualSum')} : </dt>
              <dd>
                <textarea
                  type='text'
                  // className=''
                  placeholder={t('table.placeholder-input')}
                  value={manualSum}
                  name='manualSum'
                  onChange={changeTargetInputs}
                />
              </dd>
            </dl>
            <dl>
              <dt>{t('manual.cate-manualNote')} : </dt>
              <dd>
                <textarea
                  type='text'
                  placeholder={t('table.placeholder-input')}
                  value={manualNote}
                  name='manualNote'
                  onChange={changeTargetInputs}
                  // className=''
                />
              </dd>
            </dl>
          </div>
        </form>
      </section>
      <section className='manualNew__bottom'>
        <Button className='manualNew__bottom__btn' onClick={handleDel}>
          {t('button.cancel')}
        </Button>
        <Button
          className='manualNew__bottom__btn'
          type='submit'
          onClick={handleUpload}>
          {t('button.submit')}
        </Button>
      </section>
    </div>
  );
};

export default TeamManualNew;
