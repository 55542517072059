import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RuleBaseBrowser.scss';
import NotFound from './components/NotFound';
import Main from './pages/Main';
import RuleInfoMain from './components/RuleInfo/RuleInfoMain';
import RuleList from './components/RuleInfo/RuleList';
import Rule from './components/RuleInfo/Rule';
import RuleMix from './components/RuleInfo/RuleMix';
import MyPageMain from './components/MyPage/MyPageMain';
import RevStatusMain from './components/RevStatus/RevStatusMain';
import RevStatusList from './components/RevStatus/RevStatusList';
import RevStatusAmend from './components/RevStatus/RevStatusAmend';
import RevStatusEnact from './components/RevStatus/RevStatusEnact';
import RevStatusInput from './components/RevStatus/RevStatusInput';
import NoticeMain from './components/Notice/NoticeMain';
import NoticeList from './components/Notice/NoticeList';
import Notice from './components/Notice/Notice';
import NoticeNew from './components/Notice/NoticeNew';
import NoticeUpdate from './components/Notice/NoticeUpdate';
import ManagerAuth from './components/ManagerAuth/ManagerAuth';
import RuleSearch from './components/RuleInfo/RuleSearch';
import RuleCreate from './components/ModRule/RuleCreate';
import RuleEdit from './components/ModRule/RuleEdit';
import Setting from './components/Setting';
import MyProfile from './components/MyPage/MyProfile';
import UserLog from './components/UserLog/UserLog';
import GroupChart from './components/GroupChart/GroupChart';
import OrgMembers from './components/GroupChart/OrgMembers';
import OrgChartPage from './components/GroupChart/OrgChartPage';
import Reference from './components/Reference/Reference';
import RuleChart from './components/RuleChart';
import JoinUp from './components/userSignUp/JoinUp';
import GroupJoin from './components/userSignUp/GroupJoin';
import GroupEdit from './components/userSignUp/GroupEdit';
import ModRuleSetting from './components/ModRule/ModRuleSetting';
import ReferenceMain from './components/Reference/ReferenceMain';
import ReferenceList from './components/Reference/ReferenceList';
import Login from './components/Login';
import Layout from './components/Layout';
import PersistLogin from './PersistLogin';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import Roles from './common/auth/Roles';
import TeamManual from './components/TeamManual/TeamManual';
import TeamManualNew from './components/TeamManual/TeamManualNew';
import TeamManualLayout from './components/TeamManual/TeamManualLayout';
import TeamManualNone from './components/TeamManual/TeamManualNone';
import TeamManualOne from './components/TeamManual/TeamManualOne';
import TeamManualUpdate from './components/TeamManual/TeamManualUpdate';
import TeamManualEdit from './components/TeamManual/TeamManualEdit';
import EditForm from './components/ModRule/EditForm';
import Compare from './components/Compare/Compare';
import CompareRule from './components/Compare/CompareRule';
import FormDual from './components/Dual/FormDual';
import RuleDual from './components/Dual/RuleDual';
import DevMain from './components/Dev/DevMain';
import DevSet from './components/Dev/DevSet';
import DevModify from './components/Dev/DevModify';
import DevDraft from './components/Dev/DevDraft';
import DevForm from './components/Dev/DevForm';
import ModRule from './pages/ModRule';
import ModRuleOne from './components/ModRule/ModRuleOne';
import ModRuleStatus from './components/ModRule/ModRuleStatus';
import EnactRule from './components/ModRule/EnactRule';
import ProtectedModRule from './ProtectedModRule';
import AmendRule from './components/ModRule/AmendRule';
import TeamManualAdmin from './components/TeamManual/TeamManualAdmin';
import DevDcnList from './components/Dev/DevDcnList';
import DevDcn from './pages/DevDcn';
import DevAmend from './components/Dev/DevAmend';
import DevDcnListOld from './components/Dev/DevDcnListOld';
import DevAmendOld from './components/Dev/DevAmendOld';
import DevOldDraft from './components/Dev/DevOldDraft';
import FormList from './components/RuleInfo/FormList';
import RuleStart from './components/RuleInfo/RuleStart';

const RuleBaseBrowser = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          {/* Public access */}
          <Route path='login' element={<Login />} />
          <Route path='unauthorized' element={<Unauthorized />} />
          <Route path='*' element={<NotFound />} />

          <Route element={<PersistLogin />}>
            <Route index element={<Main />} />

            <Route path='setting' element={<Setting />} />

            {/* //* RequireAuth ALL : 전체 유저 권한 접근 라우터 START */}
            <Route
              element={
                <RequireAuth
                  allowedRoles={[Roles.DEVELOPER, Roles.ADMIN, Roles.USER]}
                />
              }>
              {/* //? 마이페이지 */}
              <Route path='mypage' element={<MyPageMain />}>
                <Route index element={<MyProfile />} />
              </Route>
              {/* //? 공지사항 */}
              <Route path='/notices' element={<NoticeMain />}>
                <Route index element={<NoticeList />} />
                <Route path=':id' element={<Notice />} />
                <Route path='new' element={<NoticeNew />} />
                <Route path=':id/update' element={<NoticeUpdate />} />
              </Route>
              {/* //? 규정정보 */}
              <Route path='/ruleinfo' element={<RuleInfoMain />}>
                <Route index element={<RuleStart />} />
                <Route path='category/:categoryNumber' element={<RuleList />} />
                <Route path='formlist/:lan' element={<FormList />} />
                <Route
                  path='contents/:ruleNum/:chapterNumber?/:searchText?'
                  element={<Rule />}
                />
                <Route
                  path='mix/:ruleNum/:chapterNumber?/:searchText?'
                  element={<RuleMix />}
                />
              </Route>
              {/* //? 통합검색 */}
              <Route path='/searchrule/:searchText' element={<RuleSearch />} />
              {/* //? 팀매뉴얼 */}
              <Route path='teammanual' element={<TeamManualLayout />}>
                <Route index element={<TeamManualNone />} />
                <Route path=':orgId' element={<TeamManual />} />
                <Route
                  path=':orgId/:manualNumber'
                  element={<TeamManualOne />}
                />
                <Route
                  path='manualone/:manualNumber/update'
                  element={<TeamManualUpdate />}
                />
                <Route
                  path='manualone/:manualNumber/edit'
                  element={<TeamManualEdit />}
                />
                <Route path='admin' element={<TeamManualAdmin />} />
              </Route>
              <Route path='manualcreate' element={<TeamManualNew />} />
              {/* //? 서식 2단보기 */}
              <Route path='formdual' element={<FormDual />} />

              {/* //? 2단보기 */}
              <Route path='dual' element={<RuleDual />} />

              <Route path='/compare' element={<Compare />}>
                {/* //? 규정 신구대비 */}
                <Route path=':ruleNumber' element={<CompareRule />} />
              </Route>

              {/* //? DCN 현황 */}
              <Route path='/revstatus' element={<RevStatusMain />}>
                <Route index element={<RevStatusList />} />
                <Route path='amend/:remarks' element={<RevStatusAmend />} />
                <Route path='enact/:remarks' element={<RevStatusEnact />} />
                <Route path='input' element={<RevStatusInput />} />
              </Route>
              {/* //? 유저로그 */}

              {/* //? 규정관리 */}
              <Route path='modrule' element={<ModRule />}>
                <Route path='dcn' element={<ModRuleStatus />} />
                <Route path='dcn/:id' element={<ModRuleOne />} />
                <Route path='cir' element={<ModRuleStatus />} />
                <Route path='checklist' element={<ModRuleStatus />} />
                <Route element={<ProtectedModRule />}>
                  <Route path='enactrule/:id?' element={<EnactRule />} />
                  <Route path='amendrule/:id?' element={<AmendRule />} />
                </Route>
                <Route path='createrule' element={<RuleCreate />} />
                <Route path='editrule' element={<RuleEdit />} />
                <Route path='editform' element={<EditForm />} />
                <Route path='setting' element={<ModRuleSetting />} />
              </Route>
              {/* //? 참조 */}
              <Route path='/reference' element={<ReferenceMain />}>
                <Route index element={<ReferenceList />} />
                <Route path=':id' element={<Reference />} />
              </Route>
              {/* //? 통계 */}
              <Route path='rulechart' element={<RuleChart />} />
              <Route path='dev' element={<DevMain />}>
                <Route path='set' element={<DevSet />} />
                <Route path='modify' element={<DevModify />} />
                <Route path='draft' element={<DevDraft />} />
                <Route path='form' element={<DevForm />} />
                <Route path='dcn' element={<DevDcnList />} />
                <Route path='olddraft' element={<DevOldDraft />} />
              </Route>
              <Route path='entry' element={<DevDcn />}>
                <Route path='dcn' element={<DevDcnList />} />
                <Route path='old' element={<DevDcnListOld />} />
                <Route path='mod/:id' element={<DevAmend />} />
                <Route path='oldmod/:id' element={<DevAmendOld />} />
              </Route>
            </Route>
            {/* //* RequireAuth ALL : 전체 유저 권한 접근 라우터 END */}

            <Route
              element={
                <RequireAuth
                  allowedRoles={[Roles.DEVELOPER, Roles.ADMIN, Roles.USERLOG]}
                />
              }>
              <Route path='userlog' element={<UserLog />} />
            </Route>
            {/* //^ RequireAuth ALL : 관리자 권한 접근 라우터 START */}
            <Route
              element={
                <RequireAuth allowedRoles={[Roles.DEVELOPER, Roles.ADMIN]} />
              }>
              {/* //! 권한관리 */}
              <Route path='managerauth' element={<ManagerAuth />} />
              {/* //! 조직관리 조직도 */}
              <Route path='orgchart' element={<GroupChart />}>
                <Route index element={<OrgChartPage />} />
              </Route>
              {/* //! 조직관리 조직도 구성원 */}
              <Route path='orgmembers/:id' element={<OrgMembers />} />
              {/* //! 회원가입 */}
              <Route path='joinup' element={<JoinUp />} />
              <Route path='groupjoin' element={<GroupJoin />} />
              <Route path='groupedit' element={<GroupEdit />} />
            </Route>
            {/* //^ RequireAuth ALL : 관리자 권한 접근 라우터 END */}
          </Route>
        </Route>
      </Routes>

      {/* //~ toast 알림 */}
      <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        theme='light'
      />
    </>
  );
};

export default RuleBaseBrowser;
