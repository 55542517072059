import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import '../../../scss/ModRule/ModRuleApprovals.scss';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import DatePicker from '../../DatePicker/DatePicker';

const ModRuleStatusInfoModal = ({
  setUpdate,
  dcnInfoOpen,
  setDcnInfoOpen,
  dcnNum,
}) => {
  const axiosAuth = useAxiosAuth();
  const location = useLocation();
  const [revDate, setRevDate] = useState('');
  const { t, i18n } = useTranslation();

  // 달력 min 날짜
  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const minDate = tomorrow.toISOString().slice(0, 10);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let dcnType = '';
  if (location.pathname.includes('dcn')) {
    dcnType = 'dcn';
  } else {
    dcnType = 'cir';
  }

  const submitNewDcn = async () => {
    try {
      const res = await axiosAuth({
        method: 'PATCH',
        url: `/api/dcn/date`,
        data: {
          number: dcnNum,
          revisionTime: format(new Date(revDate), 'yyyy-MM-dd'),
          timeZone: timezone,
        },
      });
      if (res.status === 200) {
        toast(`DCN${t('modrule.edit-complete-date')}`);
        setUpdate(true);
        setDcnInfoOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRevDate = e => {
    const { value } = e.target;
    setRevDate(value);
  };

  return (
    <Modal
      className='modrulestatus__newdcnmodal'
      backdropClassName='double'
      show={dcnInfoOpen}
      // size='lg'
      onHide={setDcnInfoOpen}
      backdrop='static'
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>{`${dcnType.toUpperCase()} ${t('modrule.info')}`}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section>
          <div style={{ marginBottom: '2vh' }}>
            <h5>{`${dcnType.toUpperCase()}${t('modrule.num')}`}</h5>
            <span
              style={{
                padding: '0.5vh',
                fontSize: '1.1rem',
                border: '2px solid orange',
                borderRadius: '5px',
              }}>{`${dcnType.toUpperCase()} ${dcnNum}`}</span>
          </div>
          <div>
            <h5>{t('modrule.enactAmend-approve-date')}</h5>

            <DatePicker
              name='revdate'
              startdate={revDate}
              onChangeDate={handleRevDate}
              language={i18n.language}
              min={minDate}
              style={{
                width: '100%',
                padding: '0.5vh',
                fontSize: '1.1rem',
              }}
            />
            {/* 안내 문구 */}
            <div
              style={{
                border: '2px solid #E71A21',
                padding: '0.5vh',
                fontSize: '1rem',
                backgroundColor: '#FFF3F2',
                color: '#333',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px',
                marginTop: '20px',
              }}>
              <FaTriangleExclamation fill='#E71A21' size={20} />
              {/* 경고 아이콘 */}
              {i18n.language === 'ko' ? (
                <p
                  style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                  }}>
                  <span
                    style={{
                      fontSize: '1.1rem',
                      fontWeight: 'bold',
                      backgroundColor: '#FFF3F2',
                      color: '#20265B',
                    }}>
                    제 / 개정 승인날짜
                  </span>
                  는 각 규정의 제.개정일로 설정됩니다.
                </p>
              ) : (
                <p
                  style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                  }}>
                  <span
                    style={{
                      fontSize: '1.1rem',
                      fontWeight: 'bold',
                      backgroundColor: '#FFF3F2',
                      color: '#20265B',
                    }}>
                    Enactment/Amendment Approval Date
                  </span>{' '}
                  will be set as the enactment/amendment date for each
                  regulation.
                </p>
              )}
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setDcnInfoOpen(false)}>
          {t('button.cancel')}
        </Button>
        <Button
          className='modrulestatus__newdcnmodal__btn'
          data-bs-toggle='modal'
          data-bs-target='#staticBackdrop'
          variant='secondary'
          onClick={submitNewDcn}>
          {t('button.edit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModRuleStatusInfoModal;
