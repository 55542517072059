import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../../common/hooks/useAxiosMultipart';
import EnactRuleFileDocViewer from '../EnactRule/EnactRuleFileDocViewer';
import ModRuleCirDescription from './ModRuleCirDescription';
import CustomFileInput from '../../DatePicker/FileInput';

const ModRuleCirNew = ({ cir }) => {
  const { t } = useTranslation();
  const [targetCir, setTargetCir] = useState({});
  const [chapterInputs, setChapterInputs] = useState({
    chapterNumber: '',
    chapterName: '',
    chapterContents: {
      header: '',
      children: [],
    },
  });
  const [file, setFile] = useState(null);
  const [disOpen, setDisOpen] = useState(false);
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchCir = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/cir/list/${cir._id}`,
        });
        const { ruleNumber } = res.data;
        setTargetCir(res.data);
        setFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // 파일 입력 필드 초기화
        }
        setChapterInputs(prev => ({
          ...prev,
          uri: '',
          fileType: '',
          chapterNumber: ruleNumber,
        }));
      } catch (err) {
        console.log(err);
      }
    };
    fetchCir();
  }, [cir]);

  const handlePreviewUpload = async e => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/cir/file/preview',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setChapterInputs(prev => ({
        ...prev,
        uri: res.data.url,
        fileType: res.data.docType,
      }));
    } catch (err) {
      toast.error(t('modrule.upload-failed'), {
        autoClose: 3000,
        style: { width: '20vw' },
      });
    }
  };

  const submitNewFile = async () => {
    if (!file) {
      toast.error(t('modrule.upload-cir'), {
        autoClose: 3000,
        style: { width: '20vw' },
      });
      return;
    }

    if (!chapterInputs.chapterNumber.includes(targetCir.ruleNumber)) {
      toast.error(t('modrule.upload-cir-group-num'), {
        autoClose: 3000,
        style: { width: '20vw' },
      });
      return;
    }

    if (chapterInputs.chapterNumber === targetCir.ruleNumber) {
      toast.error(
        <div>
          {t('modrule.same-cir-group-num')}
          <br />
          CIR {t('modrule.group-num')}: {targetCir.ruleNumber}
          <br />
          CIR {t('modrule.num')}: {chapterInputs.chapterNumber}
        </div>,
        { autoClose: 3000, style: { width: '25vw' } }
      );
      return;
    }

    if (
      window.confirm(
        `${t('modrule.add-cir-q')}\nCIR NAME : ${chapterInputs.chapterName}`
      )
    ) {
      const formData = new FormData();
      formData.append('cirPath', targetCir.ruleNumber);
      formData.append('ruleNumber', targetCir.ruleNumber);
      formData.append('chapterNumber', chapterInputs.chapterNumber);
      formData.append('chapterContents', chapterInputs.chapterContents);
      formData.append('chapterName', chapterInputs.chapterName);
      formData.append('chapterType', 'CH');
      formData.append('file', file);

      try {
        const res = await axiosMultipart({
          method: 'POST',
          url: '/api/cir/sign',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
        });
        if (res.status === 200) {
          toast(
            <div>
              CIRCURLAR {t('modrule.register-complete')}
              <br />
              CIR NAME : {chapterInputs.chapterName}
            </div>,
            { autoClose: 3000, style: { width: '20vw' } }
          );
          setTargetCir(res.data);
          setChapterInputs(prev => ({
            ...prev,
            chapterNumber: res.data.ruleNumber,
            uri: '',
            fileType: '',
            chapterName: '',
            chapterContents: {
              header: '',
              children: [],
            },
          }));
          setFile(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = ''; // 파일 입력 필드 초기화
          }
        }
      } catch (err) {
        toast.error(t('modrule.register-error'), {
          autoClose: 3000,
          style: { width: '20vw' },
        });
      }
    }
  };

  return (
    <div className='modcirnew'>
      <div className='modcirnew__side'>
        <h4 style={{ padding: '0 10px' }}>
          {targetCir.ruleNumber} {targetCir.ruleName}{' '}
          <span className='modcirnew__side__titletype'>
            {t('button.submit')}
          </span>
        </h4>
        <div style={{ padding: '0 10px', marginBottom: '1vh' }}>
          <Form.Group
            controlId='formFileMultiple'
            encType='multipart/form-data'
            className='mb-3 formContentTop__form'>
            <Form.Text className='text-muted'>
              {t('modrule.extension')} : doc / docx / xls / xlsx / pdf
            </Form.Text>
            <CustomFileInput
              onChange={handlePreviewUpload}
              type='file'
              name='file'
              ref={fileInputRef}
              multiple
              style={{ width: '100%', marginTop: '10px' }}
            />
          </Form.Group>
          <Form.Label>CIR NUMBER</Form.Label>
          <InputGroup className='mb-3'>
            <Form.Control
              id='cir-chapternumber'
              type='text'
              name='chapterNumber'
              value={chapterInputs.chapterNumber}
              onChange={e =>
                setChapterInputs(prev => ({
                  ...prev,
                  chapterNumber: e.target.value,
                }))
              }
            />
          </InputGroup>
          <Form.Label>CIR NAME</Form.Label>
          <InputGroup className='mb-3'>
            <Form.Control
              id='cir-chaptername'
              type='text'
              placeholder={t('modrule.cir-name-placeholder')}
              name='chapterName'
              value={chapterInputs.chapterName}
              onChange={e =>
                setChapterInputs(prev => ({
                  ...prev,
                  chapterName: e.target.value,
                  chapterContents: {
                    header: e.target.value,
                    children: [...prev.chapterContents.children],
                  },
                }))
              }
            />
          </InputGroup>

          <div style={{ textAlign: 'right' }}>
            <Button
              variant='primary'
              style={{ marginRight: '0.5vw' }}
              onClick={() => setDisOpen(true)}>
              DESCRIPTION
            </Button>
            <Button variant='primary' onClick={submitNewFile}>
              CIR{t('button.submit')}
            </Button>
          </div>
          {disOpen && (
            <ModRuleCirDescription
              disOpen={disOpen}
              setDisOpen={setDisOpen}
              chapterContents={chapterInputs.chapterContents}
              setChapterInputs={setChapterInputs}
            />
          )}
        </div>
        <h5>{t('modrule.registered-cir')}</h5>
        <div className='modcirnew__side__currentcirlist'>
          {targetCir?.chapterRef?.length > 0 &&
            targetCir.chapterRef.map(chapter => (
              <div
                className='modcirnew__side__currentcirlist__item'
                key={chapter._id}>
                {chapter.chapterNumber} {chapter.chapterName}
              </div>
            ))}
        </div>
      </div>
      <div>
        <h5>{t('modrule.file-preview')}</h5>
        <div className='modcirnew__previewer'>
          {chapterInputs?.uri && chapterInputs.uri !== '' && (
            <EnactRuleFileDocViewer
              propUri={chapterInputs.uri}
              propFileType={chapterInputs.fileType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModRuleCirNew;
