import React from 'react';
import { useLocation } from 'react-router-dom';
import '../../scss/Dual/RuleDual.scss';
import RuleDualRight from './RuleDualRight';
import RuleDualLeft from './RuleDualLeft';
import RuleBreadCrumb from '../RuleInfo/RuleBreadCrumb';

// lan, categoryId, ruleNumber
const RuleDual = () => {
  const { state } = useLocation();

  return (
    <div className='dual mainsection'>
      <div className='dual__header'>
        <RuleBreadCrumb />
      </div>
      <div className='dual__contents'>
        <RuleDualLeft
          lan={state.lan}
          cateName={state.category}
          ruleNumber={state.ruleNumber}
        />

        <RuleDualRight
          lan={state.lan}
          cateName={state.category}
          ruleNumber={state.ruleNumber}
        />
      </div>
    </div>
  );
};

export default RuleDual;
