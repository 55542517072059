import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BiInfoCircle, BiUndo } from 'react-icons/bi';
import { Button, Spinner } from 'react-bootstrap';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const MobileTeamManualOne = () => {
  const { t } = useTranslation();
  const { orgId, manualNumber } = useParams();
  const iframeRef = useRef(null);
  const navi = useNavigate();
  const axiosAuth = useAxiosAuth();
  const [currentManual, setCurrentManual] = useState({ _id: '', url: '' });
  const [writerInfo, setWriterInfo] = useState({ _id: '' });
  const [manualnfo, setManuallnfo] = useState(false);
  const [oldManual, setOldManual] = useState([]);
  const [loading, setLoading] = useState(false);
  const [manuals, setManuals] = useState([]);

  const goBack = org => {
    navi(`/teammanual/${org._id}`);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchManual = async () => {
      try {
        setLoading(true);
        const res = await axiosAuth.get(`/api/manual/${manualNumber}`);
        const { manual, oldmanual } = res.data;
        const manualCopy = {
          ...manual,
          manualNum: manual.manualNumber
            .replace(manual.orgId._id, '')
            .replace('-', ''),
          orgName: manual.orgId.name,
          lastdate: manual.createdAt.slice(0, 10),
        };
        setCurrentManual(manualCopy);

        setWriterInfo(manual?.writer);
        setOldManual(oldmanual);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchManual();
    return () => {
      controller.abort();
    };
  }, [manualNumber]);

  useEffect(() => {
    const controller = new AbortController();
    const fetchManuals = async () => {
      try {
        const res = await axiosAuth.get(`/api/manual/all/${orgId}`);
        const manualCopy = res.data.map(el => ({
          value: el.manualNumber,
          label:
            `${el.manualNumber.replace(orgId, '').replace('-', '')} ${
              el.manualName
            }` || `${el.manualNumber}${el.manualName}`,
        }));
        setManuals(manualCopy);
      } catch (error) {
        console.log(error);
      }
    };
    fetchManuals();
    return () => {
      controller.abort();
    };
  }, [orgId]);

  const handleChange = e => {
    navi(`/teammanual/${orgId}/${e.target.value}`);
  };

  return (
    <div className='teammanual__oneManual'>
      <div className='topheader'>
        <Button variant='secondary' onClick={() => goBack(currentManual.orgId)}>
          <BiUndo type='button' size={26} />
        </Button>
        <span>{currentManual.orgName}</span>
      </div>

      <div className='fakeheader'>
        <select onChange={handleChange}>
          {currentManual && (
            <option
              value='default'
              hidden>{`${currentManual.manualNum} ${currentManual.manualName}`}</option>
          )}
          {manuals.map(manu => (
            <option key={manu.value} value={manu.value}>
              {manu.label}
            </option>
          ))}
        </select>
        <BiInfoCircle
          size={24}
          onClick={() => {
            setManuallnfo(prev => !prev);
          }}
          style={{ fill: manualnfo ? 'orangeRed' : 'initial' }}
        />
      </div>
      <div className='contents'>
        <div className='infoBox'>
          {manualnfo && (
            <div className='contents__info'>
              <div className='contents__info__top'>
                <dl>
                  <dt>{t('table.writer')} </dt>
                  <dd>{writerInfo?.username}</dd>
                </dl>
                <dl>
                  <dt>{t('manual.revision-details')} </dt>
                  <dd>{currentManual.summary}</dd>
                </dl>
                <dl>
                  <dt>{t('manual.cate-manualNote')} </dt>
                  <dd>
                    {currentManual.note !== '' ? (
                      currentManual.note
                    ) : (
                      <span style={{ color: '#ccc' }}>
                        {t('components.teamManual.no-write')}
                      </span>
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt>{t('table.date')} </dt>
                  <dd>{currentManual.lastdate}</dd>
                </dl>
              </div>
              <div className='contents__info__bottom'>
                <span className='title'>[{t('manual.history')}]</span>
                <div>
                  {oldManual && oldManual.length !== 0 ? (
                    oldManual.map(el => (
                      <div className='oldManualBox' key={el._id}>
                        <dl>
                          {/* 메뉴얼 제목 및 업데이트 날짜 */}
                          <dt>
                            [{el.manualName}: {el.createdAt.slice(0, 10)}]
                          </dt>
                          {/* 개정내용 */}
                          <dd>{el.summary}</dd>
                        </dl>
                        <dl>
                          <dt>[{t('manual.cate-manualNote')}]</dt>
                          <dd>{el.note}</dd>
                        </dl>
                      </div>
                    ))
                  ) : (
                    <div style={{ color: '#ccc' }}>
                      {t('components.teamManual.no-record')}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='contents__file'>
          {loading && currentManual?.url !== '' ? (
            <Spinner
              animation='border'
              role='status'
              className='contents__file__spinner'
              style={{ width: '4rem', height: '4rem' }}>
              <span className='visually-hidden'>Loading...</span>
            </Spinner>
          ) : (
            <iframe
              ref={iframeRef}
              sandbox='allow-scripts allow-same-origin allow-forms'
              onLoad={() => setLoading(false)}
              title={currentManual.manualName}
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${currentManual.url}`}
              frameBorder='0'>
              This is an embedded{' '}
              <a href='http://office.com'>Microsoft Office</a> document, powered
              by <a href='http://office.com/webapps'>Office Online</a>.
            </iframe>
          )}
        </div>
        {/* 매뉴얼 history */}
      </div>
    </div>
  );
};

export default MobileTeamManualOne;
