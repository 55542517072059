import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Navigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TeamManualAdminAdd from './TeamManualAdminAdd';
import TeamManualAdminSort from './TeamManualAdminSort';
import '../../scss/TeamManual/TeamManualAdmin.scss';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAuth from '../../common/hooks/useAuth';
import Roles from '../../common/auth/Roles';

const TeamManualAdmin = () => {
  const hmRef = useRef([]);
  const [hasManuals, sethasManuals] = useState([]);
  const [change, setChange] = useState(false);
  const axiosAuth = useAxiosAuth();
  const { auth } = useAuth();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      hasManuals.length > 0 &&
      JSON.stringify(hmRef.current) !== JSON.stringify(hasManuals)
    ) {
      setChange(false);
    } else {
      setChange(true);
    }
  }, [hmRef, hasManuals]);

  const submitUpdate = async () => {
    if (window.confirm(t('components.teamManual.manual-update'))) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/manualper/update',
          data: {
            hasManuals,
          },
        });
        if (res.status === 200) {
          toast(t('components.teamManual.manual-edit'));
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const reset = () => {
    if (window.confirm(t('components.teamManual.manual-clear'))) {
      window.location.reload();
    }
  };

  if (
    auth &&
    auth.roles?.find(role =>
      [Roles.ADMIN, Roles.DEVELOPER, Roles.TEAMMANUAL].includes(role)
    )
  )
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <div style={{ fontSize: '1.2rem', height: '5vh' }}>
            {t('components.teamManual.manual-changeOrder')}
          </div>
          <TeamManualAdminSort
            hasManuals={hasManuals}
            sethasManuals={sethasManuals}
            hmRef={hmRef}
          />
        </div>
        <div style={{ width: '50%' }}>
          <div style={{ fontSize: '1.2rem', height: '5vh' }}>
            {t('components.teamManual.manual-add')}
            <Button
              type='submit'
              style={{ float: 'right', marginLeft: '0.5vw' }}
              onClick={submitUpdate}>
              {t('components.teamManual.update')}
            </Button>
            <Button
              disabled={change}
              style={{ float: 'right' }}
              onClick={reset}>
              {t('components.teamManual.clear')}
            </Button>
          </div>
          <TeamManualAdminAdd
            hasManuals={hasManuals}
            sethasManuals={sethasManuals}
          />
        </div>
      </div>
    );

  return auth && auth.accessToken ? (
    <Navigate to='/unauthorized' state={{ from: location }} replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};

export default TeamManualAdmin;
