import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../scss/RuleLog/UserLog.scss';
import { LogProvider } from '../../store/LogProvider';
import LoginLog from './LoginLog';
import RuleLog from './RuleLog';

const UserLog = () => {
  const [activeTab, setActiveTab] = useState('auth');
  const { t } = useTranslation();

  return (
    <LogProvider>
      <div
        className='userlog mainsection'
        style={{ width: '100%', height: 'calc(100%)' }}>
        <h4>{t('userlog.title')}</h4>
        <section className='userlog__header'>
          <Button
            role='button'
            // aria-hidden='true'
            className={`tabmenu-item ${activeTab === 'auth' ? 'active' : ''}`}
            onClick={() => setActiveTab('auth')}
            as={Link}>
            {t('userlog.loginlog.title')}
          </Button>
          <Button
            role='button'
            // aria-hidden='true'
            className={`tabmenu-item ${activeTab === 'rule' ? 'active' : ''}`}
            onClick={() => setActiveTab('rule')}
            as={Link}>
            {t('userlog.rulelog.title')}
          </Button>
        </section>
        {activeTab === 'auth' ? <LoginLog /> : <RuleLog />}
      </div>
    </LogProvider>
  );
};

export default UserLog;
