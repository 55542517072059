import React from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const EnactRuleFileDocViewer = ({ propUri, propFileType }) => {
  return (
    <DocViewer
      key='modChapter_preview'
      id='modChapter_previewviewer'
      prefetchMethod='GET'
      documents={[{ uri: propUri, fileType: propFileType }]}
      pluginRenderers={DocViewerRenderers}
      config={{ header: { disableHeader: true } }}
      className='docviewer__container'
      style={{ overflowY: 'hidden' }}
    />
  );
};

export default React.memo(EnactRuleFileDocViewer);
