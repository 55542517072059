import React from 'react';
import { TbAlertTriangle } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import '../scss/Mobile/MobileUnauthorized.scss';
import { useTranslation } from 'react-i18next';

const MobileUnauthorized = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goBack = () => navigate(-1);

  return (
    <section className='mobileUnauthorized'>
      {/* <h1>Unauthorized</h1> */}
      <div className='mobileUnauthorized__icon'>
        <TbAlertTriangle color='rgb(241, 133, 70)' size={130} />
      </div>
      <div className='unauthorized__text'>
        <p>
          {t('notfound.sorry')}
          <br />
          <span>{t('notfound.page-notfound-text')}</span>
        </p>
        <span style={{ color: 'rgb(241, 133, 70)' }}>Page Not Found</span>
      </div>
      <div className='mobileUnauthorized__backBtn'>
        <button type='button' onClick={goBack}>
          {t('unauthorized.back')}
        </button>
      </div>
    </section>
  );
};

export default MobileUnauthorized;
