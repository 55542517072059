import React, { useEffect } from 'react';
import parse from 'html-react-parser';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useModRule from '../../../common/hooks/useModRule';
import useModChapter from '../../../common/hooks/useModChapter';
import EnactRuleFileDocViewer from '../EnactRule/EnactRuleFileDocViewer';

const ModRuleOneEnact = () => {
  const { modRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const { t } = useTranslation();

  const handleChapContent = chapId => {
    const targetChap = modRule.modChapterRef.filter(
      modChap => modChap._id === chapId
    )[0];
    setModChapter(targetChap);
  };

  useEffect(() => {
    if (modRule.modChapterRef.length > 0) {
      setModChapter(modRule.modChapterRef[0]);
    }
  }, []);

  return (
    <>
      <div className='modRuleOne__content__header'>
        <div className='modRuleOne__content__header__left'>
          <span>{t('modrule.register-file-num')} : </span>
          <span> {modRule?.modChapterRef.length}</span>
        </div>
      </div>
      <div className='modRuleOne__content__enact'>
        <div className='modRuleOne__content__enact__chapterBtns'>
          {modRule?.modChapterRef &&
            modRule?.modChapterRef.map(el => (
              <div key={el._id} className='enactchapter__chapterBtn'>
                <Button
                  onClick={() => handleChapContent(el?._id)}
                  className={`enact__chapterBtn ${
                    el?._id === modChapter?._id ? 'active' : ''
                  }`}
                  variant='secondary'>
                  {el?.chapterNumber}
                </Button>
              </div>
            ))}
        </div>
        {!modChapter?.fileType || modChapter?.fileType === 'content' ? (
          <>
            <div className='modRuleOne__content__enact__chapterContents'>
              <div>{modChapter?.chapterContents.header}</div>
              <div className='chapterContents__children'>
                {modChapter?.chapterContents?.children.map(child => (
                  <div key={`keyin_${child}`}>{child}</div>
                ))}
              </div>
            </div>
            <div className='modRuleOne__content__enact__content'>
              {modChapter && parse(modChapter.content)}
            </div>
          </>
        ) : (
          <>
            <div className='modRuleOne__content__enact__chapterContents'>
              <div className='formInfo__formnumber'>
                {t('modrule.file-num')} : {modChapter.chapterNumber}
              </div>
              <div className='formInfo__formname'>
                {t('modrule.file-name')} : {modChapter.chapterName}
              </div>

              <div className='formInfo__doctype'>
                {t('modrule.extension')} : {modChapter.fileType}
              </div>
            </div>
            <div className='modRuleOne__content__enact__content'>
              {modChapter?.uri && (
                <EnactRuleFileDocViewer
                  propUri={modChapter.uri}
                  propFileType={modChapter.fileType}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ModRuleOneEnact;
