import React, { createContext, useState, useMemo } from 'react';

const ModRuleContext = createContext();
// initialState 초기화 값 컴포넌트에서 지정 가능

const initialState = {
  lan: '',
  ruleNumber: '',
  ruleName: '',
  draftedAt: '',
  comments: [],
  modChapterRef: [],
  sortOpen: false,
};

export const ModRuleProvider = ({ children }) => {
  const [modRule, setModRule] = useState(initialState);
  const value = useMemo(() => ({ modRule, setModRule }), [modRule]);

  return (
    <ModRuleContext.Provider value={value}>{children}</ModRuleContext.Provider>
  );
};

export default ModRuleContext;
