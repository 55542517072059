import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { RiDownload2Fill, RiHistoryLine } from 'react-icons/ri';
import SearchBar from '../SearchBar';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import TeamManualHistory from './TeamManualHistory';
import useAuth from '../../common/hooks/useAuth';
import Roles from '../../common/auth/Roles';

const TeamManual = () => {
  const { t } = useTranslation();
  const navi = useNavigate();
  const { orgId } = useParams();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const [open, setOpen] = useState('');
  const [manuals, setManuals] = useState([]);
  const [filterManuals, setFilterManuals] = useState([]);
  const [oldManual, setOldManual] = useState([]);
  const [searchStr, setSearchStr] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    const fetchManuals = async () => {
      try {
        const res = await axiosAuth.get(`/api/manual/all/${orgId}`);
        setManuals(
          res.data.map(el => ({
            ...el,
            parsedNumber:
              el.manualNumber.replace(orgId, el.orgId.name) || el.manualNumber,
          }))
        );
        setFilterManuals(
          res.data.map(el => ({
            ...el,
            parsedNumber:
              el.manualNumber.replace(orgId, el.orgId.name) || el.manualNumber,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchManuals();
    return () => {
      controller.abort();
    };
  }, [orgId]);

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  useEffect(() => {
    const reg = new RegExp(searchStr, 'gi');
    if (searchStr !== '') {
      const filteredUsers = manuals.filter(
        el => el.manualNumber.match(reg) || el.manualName.match(reg)
      );
      setFilterManuals(filteredUsers);
    } else {
      setFilterManuals(manuals);
    }
  }, [searchStr]);

  const goLink = () => {
    navi(`/manualcreate`);
  };

  const handleHistory = async objId => {
    setOpen(objId);
    try {
      const res = await axiosAuth.get(`/api/manual/${objId}`);
      setOldManual(res.data.oldmanual);
    } catch (err) {
      console.log(err);
    }
  };

  const goManualOne = manualNum => {
    navi(`/teammanual/${orgId}/${manualNum}`);
  };

  const downloadManualOne = async m => {
    try {
      const resDown = await axiosAuth({
        method: 'POST',
        url: '/api/manual/download',
        responseType: 'blob',
        data: {
          url: m.url,
        },
      });
      const blob = new Blob([resDown.data]);
      const fileName = `${m.manualNumber.replace(orgId, '').replace('-', '')} ${
        m.manualName
      }.${m.docType}`;
      const downUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downUrl);
    } catch (err2) {
      console.log(`error resDown : ${err2}`);
    }
  };

  return (
    <div className='teamManual__content'>
      <div className='teamManual__header'>
        <div className='teamManual__header__search'>
          <SearchBar value={searchStr} onChange={handlesearchStr} />
        </div>
        {/* 권한있는 사용자만 보이게 설정 */}
        <div className='teamManual__header__write'>
          {auth.roles.includes(Roles.TEAMMANUAL) && auth.org === orgId && (
            <Button onClick={goLink}>{t('button.write')}</Button>
          )}
        </div>
      </div>
      <div className='teamManual__body'>
        <div className='teamManualBox'>
          <Table className='teamManualBox__table'>
            <thead>
              <tr>
                <td width='10%'>{t('table.index')}</td>
                <td width='20%'>{t('manual.manual-num')}</td>
                <td width='35%'>{t('manual.manual-title')}</td>
                <td width='15%'>{t('table.date')}</td>
                <td width='10%'>{t('table.download')}</td>
                <td width='10%'>{t('table.record')}</td>
              </tr>
            </thead>
            <tbody>
              {filterManuals.length === 0 ? (
                <tr>
                  <td colSpan={6}>
                    <div>{t('components.teamManual.no-manual')}</div>
                  </td>
                </tr>
              ) : (
                filterManuals.map((manual, i) => (
                  <tr
                    key={manual._id}
                    className={`${open === manual._id && 'checkShow'}`}>
                    <td>{i + 1}</td>
                    <td
                      onClick={() => goManualOne(manual.manualNumber)}
                      aria-hidden='true'
                      align='left'
                      style={{ paddingLeft: '5%' }}>
                      {manual.parsedNumber}
                    </td>
                    <td
                      align='left'
                      onClick={() => goManualOne(manual.manualNumber)}
                      aria-hidden='true'>
                      {manual.manualName}
                    </td>
                    <td>{manual.createdAt?.slice(0, 10)}</td>
                    <td>
                      <Button
                        variant='secondary'
                        onClick={() => downloadManualOne(manual)}>
                        <RiDownload2Fill size={20} />
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant='secondary'
                        onClick={() => handleHistory(manual._id)}
                        className={`${open === manual._id && 'show'}`}>
                        <RiHistoryLine size={20} />
                      </Button>
                      {open === manual._id && (
                        <TeamManualHistory
                          manualName={manual.manualName}
                          setOpen={setOpen}
                          oldManual={oldManual}
                        />
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TeamManual;
