import React, { useState } from 'react';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiX } from 'react-icons/bi';
import { toast } from 'react-toastify';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const ToggleListAddCate = ({ openCate, setUpdate, resetCateAddDel }) => {
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [addLan, setAddLan] = useState('kor');
  const [targetCate, setTargetCate] = useState({
    targetNum: '',
    targetName: '',
  });
  const { targetNum, targetName } = targetCate;
  const [targetSortNum, setTargetSortNum] = useState(null);

  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  const handleCateName = e => {
    setTargetCate(prevState => ({
      ...prevState,
      targetName: e.target.value,
    }));
  };

  const handleCateNum = e => {
    setTargetCate(prevState => ({
      ...prevState,
      targetNum: e.target.value,
    }));
    // sortNum
    if (e.target.value.includes('M')) {
      setTargetSortNum(1);
    } else if (e.target.value.includes('SP')) {
      setTargetSortNum(2);
    } else if (e.target.value.includes('P')) {
      setTargetSortNum(3);
    } else if (e.target.value.includes('I')) {
      setTargetSortNum(4);
    } else if (e.target.value.includes('D')) {
      setTargetSortNum(5);
    } else if (e.target.value.includes('E')) {
      setTargetSortNum(6);
    } else if (e.target.value.includes('OCD')) {
      setTargetSortNum(7);
    } else {
      setTargetSortNum(16);
    }
  };

  const handleCateAdd = async () => {
    if (
      window.confirm(
        `${targetCate.targetNum} ${t('components.toggleList.category-add-q')}`
      )
    ) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/category/new`,
          data: {
            category: targetCate.targetName,
            categoryNumber: targetCate.targetNum,
            sortNum: targetSortNum,
            lan: addLan,
          },
        });
        toast(
          res.data.message ||
            `${targetCate.targetNum} ${t('components.toggleList.category-add')}`
        );
        setUpdate(prev => !prev);
        resetCateAddDel();
        setTargetCate({ targetNum: '', targetName: '' });
        setTargetSortNum(null);
      } catch (err) {
        toast(t('components.toggleList.create-failed'));
        console.log(err);
      }
    }
  };
  return (
    <div className='categoryPlus'>
      <div className='categoryPlus__header'>
        <span>{t('sidenav.createCateTitle')}</span>
        <BiX size={22} onClick={openCate} />
      </div>
      <div className='categoryPlus__body'>
        <div className='categoryPlus__body__inputBox'>
          <span>{t('sidenav.selectLan')}</span>
          <div className='lanBox'>
            <ButtonGroup className='lanRadiobtn'>
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={radio.value}
                  id={`radio-${idx}`}
                  type='radio'
                  variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                  name='radio'
                  value={radio.value}
                  checked={addLan === radio.value}
                  onChange={e => setAddLan(e.currentTarget.value)}>
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
        </div>
        <div className='categoryPlus__body__inputBox'>
          <span>{t('sidenav.cateNum')}</span>
          <input
            className='numBox'
            type='text'
            multiple
            value={targetNum}
            onChange={handleCateNum}
            placeholder={t('components.toggleList.category-num-placeholder')}
          />
        </div>
        <div className='categoryPlus__body__inputBox'>
          <span>{t('sidenav.cateName')}</span>
          <input
            className='nameBox'
            type='text'
            multiple
            value={targetName}
            onChange={handleCateName}
            placeholder={t('components.toggleList.category-title-placeholder')}
          />
        </div>
      </div>
      <div className='categoryPlus__bottom'>
        <Button onClick={handleCateAdd}>{t('button.add')}</Button>
      </div>
    </div>
  );
};

export default ToggleListAddCate;
