import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  BiSolidLeftArrowSquare,
  BiSolidRightArrowSquare,
} from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAuth from '../../common/hooks/useAuth';
import ModRuleChecklistDefault from './ModRuleChecklist/ModRuleChecklistDefault';
import ModRuleChecklistNew from './ModRuleChecklist/ModRuleChecklistNew';
import ModRuleChecklistEdit from './ModRuleChecklist/ModRuleChecklistEdit';
import ModRuleChecklistRuleCreateModal from './ModRuleChecklist/ModRuleChecklistRuleCreateModal';
import ModRuleChecklistRuleDeleteModal from './ModRuleChecklist/ModRuleChecklistRuleDeleteModal';
import '../../scss/ModRule/ModRuleChecklist.scss';

const ModRuleChecklist = () => {
  const [cate, setCate] = useState({});
  const [sideOpen, setSideOpen] = useState(true);
  const [manageType, setManageType] = useState('');
  const [checklist, setChecklist] = useState({
    _id: '',
    ruleNumber: '',
    ruleName: '',
  });
  const [modal, setModal] = useState('');
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  const fetchData = async () => {
    try {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/category/CHECK-LIST/${auth._id}`,
      });
      setCate(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChecklistOpen = (target, type) => {
    setSideOpen(false);
    setManageType(type);
    setModal('');
    setChecklist(target);
  };

  const handleOpenCreateModal = () => {
    setModal('create');
    setManageType('');
  };

  const handleOpenDeleteModal = () => {
    setModal('delete');
    setManageType('');
  };

  return (
    <div className='modetc'>
      <div
        className={`modetc__sidelist ${sideOpen ? 'open' : 'close'}`}
        style={{ width: sideOpen ? 'auto' : '4vw' }}>
        <div className='modetc__sidelist__title'>
          {sideOpen && (
            <span className='modetc__sidelist__titlespan'>
              {t('modrule.management-checkList')}
              <Button
                style={{ padding: '1px 10px', marginLeft: '5px' }}
                onClick={handleOpenCreateModal}>
                + {t('button.create')}
              </Button>
              <Button
                style={{ padding: '1px 10px', marginLeft: '5px' }}
                onClick={handleOpenDeleteModal}>
                - {t('button.delete')}
              </Button>
            </span>
          )}
          <Button
            className='sidelistbtn-open'
            variant='secondary'
            onClick={() => setSideOpen(!sideOpen)}>
            {sideOpen ? (
              <BiSolidLeftArrowSquare size='25' />
            ) : (
              <BiSolidRightArrowSquare size='25' />
            )}
          </Button>
        </div>
        <div style={{ maxHeight: '72vh', overflowY: 'auto' }}>
          {sideOpen ? (
            cate?.ruleRef?.length > 0 &&
            cate.ruleRef.map(rule => (
              <div
                className='clistside__item'
                key={rule._id}
                title={rule.ruleName}>
                <span>{`[${rule.ruleNumber}] : ${rule.ruleName}`}</span>
                <span>
                  <Button
                    variant='secondary'
                    className='clistbtn-new'
                    title={`CHECK-LIST ${t('button.submit')}`}
                    onClick={() => handleChecklistOpen(rule, 'new')}>
                    {t('button.submit')}
                  </Button>
                  <Button
                    variant='secondary'
                    className='clistbtn-edit'
                    title={`CHECK-LIST ${t('button.edit')}`}
                    onClick={() => handleChecklistOpen(rule, 'edit')}>
                    {t('button.edit')}
                  </Button>
                </span>
              </div>
            ))
          ) : (
            <div className='sidebar-closed-text'>
              {t('modrule.list-checkList')}
            </div>
          )}
        </div>
        {modal === 'create' && (
          <ModRuleChecklistRuleCreateModal
            setModal={setModal}
            cate={cate}
            updateData={fetchData}
          />
        )}
        {modal === 'delete' && (
          <ModRuleChecklistRuleDeleteModal
            setModal={setModal}
            cate={cate}
            updateData={fetchData}
          />
        )}
      </div>
      <div className='modetc__manage'>
        {(() => {
          if (manageType === 'new') {
            return <ModRuleChecklistNew clist={checklist} />;
          }

          if (manageType === 'edit') {
            return <ModRuleChecklistEdit clist={checklist} />;
          }
          return <ModRuleChecklistDefault />;
        })()}
      </div>
    </div>
  );
};

export default ModRuleChecklist;
