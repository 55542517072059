import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const ProfileValid = ({ email, setChecked }) => {
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const handleCurrentPassword = e => {
    setCurrentPassword(e.target.value);
  };

  const onPasswordCheckSubmit = async () => {
    if (currentPassword === '') {
      toast(t('mypage.valid.password-input'));
      return;
    }
    const validData = {
      email,
      password: currentPassword,
    };

    await axiosAuth
      .post('/api/mypage/check', validData)
      .then(res => {
        if (res.status === 200) {
          toast(t('mypage.profileUpdate.matchPassword'));
          setChecked(true);
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          toast(t('mypage.profileUpdate.nomatchPassword'));
          setCurrentPassword('');
          console.log(
            `${err.response.status} : ${t('mypage.valid.manager-inquiry')}`
          );
          return;
        }
        if (err.response && err.response.status === 404) {
          toast(t('mypage.profileUpdate.nomatchPassword'));
          setCurrentPassword('');
          console.log(
            `${err.response.status} : ${t('mypage.valid.manager-inquiry')}`
          );
          return;
        }
        if (err.response && err.response.status === 401) {
          toast(t('mypage.valid.no-permission'));
          setCurrentPassword('');
          console.log(
            `${err.response.status} : ${t('mypage.valid.manager-inquiry')}`
          );
          return;
        }
        console.log(err);
      });
  };

  const handleKeyDown = e => {
    if (focused) {
      const key = e.code;
      switch (key) {
        case 'Enter':
          e.preventDefault();
          onPasswordCheckSubmit();
          break;
        default:
      }
    }
  };

  return (
    <div className='profileUpdate__check'>
      <div>
        <p>{t('mypage.profileUpdate.currentPassword')} </p>
        <form className='profileUpdate__checkform'>
          <input
            onFocus={onFocus}
            onBlur={onBlur}
            type='password'
            id='profileUpdate__passwordCheck'
            name='passwordCheck'
            value={currentPassword || ''}
            onChange={handleCurrentPassword}
            onKeyDown={handleKeyDown}
            autoComplete='off'
            required
          />
          <Button type='button' onClick={onPasswordCheckSubmit}>
            {t('button.submit')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ProfileValid;
