import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import DevRuleRevStatus from './DevRuleRevStatus';
import useDcn from '../../common/hooks/useDcn';

const DevDcnList = () => {
  const { t } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const navi = useNavigate();
  const { dcn, setDcn } = useDcn();
  const [createMod, setCreateMod] = useState(false);
  const [modRule, setModRule] = useState({});
  const [dcns, setDcns] = useState([]);

  const [open, setOpen] = useState(false);
  const [lan, setLan] = useState('kor');
  const [cates, setCates] = useState([]);
  const [rules, setRules] = useState([]);
  const [rule, setRule] = useState({});

  useEffect(() => {
    setDcn({
      status: '',
      modRuleRef: [],
    });
    const fetchDcn = async () => {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/dcn/list/approved`,
      });
      setDcns(res.data);
    };
    fetchDcn();
  }, []);

  useEffect(() => {
    const fetchCates = async () => {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/category/manage/${lan}`,
      });
      const catesArr = res.data.map(cateOp => ({
        label: `${cateOp.categoryNumber} : ${cateOp.category}`,
        value: cateOp,
      }));
      setCates(catesArr);
    };
    fetchCates();
  }, [lan]);

  const goAmendRule = mod => {
    navi(`/entry/mod/${mod._id}`);
  };

  const selectDcn = dcnObj => {
    setDcn(prev => ({ ...prev, ...dcnObj }));
  };

  const openModRuleCreate = dcnObj => {
    // console.log(dcnObj);
    setCreateMod(true);
    setDcn(dcnObj);
  };

  const handleCateValue = e => {
    const { ruleRef } = e.value;
    const ruleOptionsArr = ruleRef.map(ruleOption => ({
      label: `${ruleOption.ruleNumber} : ${ruleOption.ruleName}`,
      value: ruleOption,
    }));
    setRules(ruleOptionsArr);
  };

  const handleRuleValue = e => {
    setRule(e.value);
  };

  // const submitCreate = async () => {
  //   try {
  //     const response = await axiosAuth({
  //       method: 'POST',
  //       url: '/api/modrule/createdevmod',
  //       data: {
  //         dcnId: dcn._id,
  //         number: dcn.number,
  //         newRuleId: rule._id,
  //         currRuleDate: dcn.revisionTime,
  //       },
  //     });
  //     if (response.status === 200) {
  //       toast(`MODRULE 생성`);
  //       setDcn({
  //         open: false,
  //         status: '',
  //         modRuleRef: [],
  //       });
  //       window.location.reload();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast('이력 등록 실패');
  //   }
  // };

  const submitCreate = async () => {
    // 버튼에 연결된 func
    try {
      const response = await axiosAuth({
        method: 'PUT',
        url: '/api/dev/updateone',
        data: {
          dcnId: dcn._id,
          number: dcn.number,
          createdAt: dcn.createdAt,
          revisedAt: dcn.revisionTime,
          ruleNumber: rule.ruleNumber,
          refRuleId: rule._id,
        },
      });
      if (response.status === 200) {
        toast(`MODRULE ${t('modrule.create')}`);
        setDcn({
          open: false,
          status: '',
          modRuleRef: [],
        });
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.add-history-failed'));
    }
  };

  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 4, padding: '1vh 1vw' }}>
        <p>dcn</p>
        <div style={{ maxHeight: '80vh', overflow: 'auto' }}>
          {dcns.length > 0 &&
            dcns.map(dc => (
              <div key={dc._id}>
                <button
                  type='button'
                  onClick={() => selectDcn(dc)}
                  style={{
                    minWidth: '10vw',
                    marginBottom: '1vh',
                    marginRight: '1vw',
                  }}>{`DCN${dc.number}`}</button>
                <Button
                  type='button'
                  onClick={() => openModRuleCreate(dc)}
                  style={{
                    minWidth: '3vw',
                    marginBottom: '1vh',
                  }}>
                  MODRULE {t('modrule.create')}
                </Button>
              </div>
            ))}
        </div>
      </div>
      <div style={{ flex: 6, padding: '1vh 1vw' }}>
        <p>modrule</p>
        <div>
          {dcn?.modRuleRef.length > 0 &&
            dcn?.modRuleRef.map(el => (
              <div>
                <span>{el.ruleNumber}</span>
                <button
                  type='button'
                  style={{ margin: '0 10px' }}
                  onClick={() => {
                    setOpen(true);
                    setModRule(el);
                  }}>
                  {t('modrule.rev-history')}
                </button>
                <button type='button' onClick={() => goAmendRule(el)}>
                  {t('modrule.amend')}
                </button>
              </div>
            ))}
        </div>
      </div>
      {open && (
        <DevRuleRevStatus
          open={open}
          setOpen={setOpen}
          modRule={modRule}
          setModRule={setModRule}
        />
      )}
      <Modal
        dialogClassName='dmodal'
        centered
        size='lg'
        show={createMod}
        onHide={() => setDcn(prev => ({ ...prev, open: false }))}
        backdrop='static'>
        <Modal.Header closeButton>
          <Modal.Title>{`DCN${dcn.number}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={radio.value}
              id={`radio-${idx}`}
              type='radio'
              variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
              name='radio'
              value={radio.value}
              checked={lan === radio.value}
              onChange={e => setLan(e.currentTarget.value)}>
              {radio.name}
            </ToggleButton>
          ))}
          <Select
            placeholder={<div>{t('revStatus.select-chap-placeholder')}</div>}
            isSearchable
            id='select-chapternumber'
            className='devdraft__side__category__select'
            options={cates}
            onChange={handleCateValue}
          />
          <Select
            placeholder={<div>{t('revStatus.select-chap-placeholder')}</div>}
            isSearchable
            id='select-chapternumber'
            className='devdraft__side__category__select'
            options={rules}
            onChange={handleRuleValue}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              setCreateMod(false);
              setDcn(prev => ({ ...prev, open: false }));
            }}>
            {t('button.cancel')}
          </Button>
          <Button variant='secondary' onClick={() => submitCreate()}>
            {t('button.submit')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DevDcnList;
