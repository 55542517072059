import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Table } from 'react-bootstrap';
import { BiDownload } from 'react-icons/bi';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAuth from '../../common/hooks/useAuth';
import '../../scss/Notice/Notice.scss';

const Notice = () => {
  const [noticeFiles, setNoticeFiles] = useState();
  const [targetNotice, setTargetNotice] = useState({});
  const { id } = useParams();
  const axiosAuth = useAxiosAuth();
  const { auth } = useAuth();
  const { t } = useTranslation();

  const navi = useNavigate();

  const goBack = () => {
    navi('/notices');
  };

  const goUpdate = () => {
    navi(`/notices/${id}/update`);
  };

  const del = async () => {
    if (window.confirm(t('notice.notice-del'))) {
      await axiosAuth(`/api/notice/byone/${id}`, {
        method: 'DELETE',
        data: { id },
      });
      toast(t('notice.notice-del-success'));
      goBack();
    }
  };

  useEffect(() => {
    const notice = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/notice/byone/${id}/${auth.id}`,
        });

        setTargetNotice(res.data.doc);
        const filesCopy = res.data.doc.url.map(u => {
          const lastSlash = u.lastIndexOf('/');
          const fileName = u.substring(lastSlash + 1);
          return {
            label: decodeURIComponent(fileName),
            url: u,
          };
        });
        setNoticeFiles(filesCopy);
      } catch (err) {
        console.log(err);
      }
    };
    notice();
  }, [id]);

  const downloadFile = async file => {
    try {
      const resDown = await axiosAuth({
        method: 'POST',
        url: '/api/notice/download',
        responseType: 'blob',
        data: {
          url: file.url,
        },
      });
      const blob = new Blob([resDown.data]);
      const fileName = file.label;
      const downUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downUrl);
    } catch (err2) {
      console.log(`error resDown : ${err2}`);
    }
  };

  return (
    <section className='notice'>
      <Table className='noticeTable'>
        <thead className='noticeTable__head'>
          <tr className='noticeTable__head__title'>
            <td>
              <p>{targetNotice && targetNotice.title}</p>
            </td>
          </tr>
          <tr className='noticeTable__head__sub'>
            <td className='subHeader'>
              <div className='subHeader__left'>
                <span>{t('notice.date')} :</span>
                <span>
                  {targetNotice.updatedAt &&
                    targetNotice.updatedAt.slice(0, 10)}
                </span>
              </div>
              <div className='subHeader__right'>
                <span>{t('notice.views')} :</span>
                <span>{targetNotice && targetNotice.count}</span>
              </div>
            </td>
          </tr>
          <tr className='noticeTable__head__fileBox'>
            <td className='attach'>
              <span className='attach__title'>
                {t('table.attachment')}
                {`(${targetNotice.url && targetNotice.url.length})`} :
              </span>
              <div className='attach__content'>
                {typeof targetNotice.url !== 'object' ||
                !Array.isArray(targetNotice.url) ||
                targetNotice.url.length === 0 ? (
                  <span style={{ color: '#ccc' }}>{t('notice.empty')}</span>
                ) : (
                  noticeFiles &&
                  noticeFiles.map((file, i) => (
                    <div key={file.url}>
                      <span>{i + 1}.</span>
                      <span>{file.label}</span>
                      <BiDownload
                        size='17'
                        onClick={() => downloadFile(file)}
                      />
                    </div>
                  ))
                )}
              </div>
            </td>
          </tr>
        </thead>
        <tbody className='noticeTable__body'>
          <tr>
            <td>
              <div>{targetNotice && targetNotice.content}</div>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className='notice__btnList'>
        <Button className='notice__btnList__backbtn' onClick={goBack}>
          {t('notice.golist')}
        </Button>
        {/* ROLE_IDX1 => 개발자 / ROLE_IDX9 => 공지사항 관리자 */}
        {auth.roles &&
          (auth.roles.includes('ROLE_IDX9') ||
            auth.roles.includes(process.env.REACT_APP_DEVELOPER)) && (
            <div className='notice__btnList__btns'>
              <Button onClick={goUpdate}>{t('button.edit')}</Button>
              <Button onClick={del}>{t('button.delete')}</Button>
            </div>
          )}
      </div>
    </section>
  );
};

export default Notice;
