import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { FaLongArrowAltUp } from 'react-icons/fa';
import useModRule from '../../../common/hooks/useModRule';
import useModChapter from '../../../common/hooks/useModChapter';
import EnactRuleAddContent from './EnactRuleAddContent';
import EnactRuleAddFile from './EnactRuleAddFile';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import { formatDate } from '../../../common/utils/tzUtiles';

const EnactStatus = ({ modRule }) => {
  const { t, i18n } = useTranslation();
  const { status } = modRule;

  const getStatusCode = () => {
    switch (status) {
      case 'incomplete':
        return i18n.language === 'ko' ? '작성 중' : 'In Progress';
      case 'temporary':
        return i18n.language === 'ko' ? '임시저장' : 'Temporary Save';
      default:
        return i18n.language === 'ko' ? '임시저장' : 'Temporary Save';
    }
  };

  const getStatusStyle = () => {
    switch (status) {
      case 'incomplete':
        return '#BEBEBE';
      case 'temporary':
        return '#F5F5DC';
      default:
        return 'gray';
    }
  };

  // color , 번역 , 상태에 맞는 날짜 선택
  const style = {
    display: 'inline-block',
    padding: '5px 5px',
    backgroundColor: getStatusStyle(),
    borderRadius: '10px',
    minWidth: '4vw',
  };
  return <span style={style}>{getStatusCode()}</span>;
};

const OPTIONS = [
  { i: 2, value: 'CH', label: 'CHAPTER' },
  { i: 3, value: 'APP', label: 'APPENDIX' },
];

const RuleStatusTable = ({ modRule }) => {
  const { t } = useTranslation();

  return (
    <tr key={modRule._id}>
      <td width='10%' className='text-center'>
        {modRule.classification === 'enact'
          ? t('modrule.enact')
          : t('modrule.amend')}
      </td>
      <td width='10%' className='text-center'>
        {modRule.lan.toUpperCase()}
      </td>
      <td width='40%'>
        <NavLink
          to={`${modRule._id}`}
          style={{
            textDecoration: 'none',
            color: 'black',
          }}>
          {`${modRule.refCategory.categoryNumber} - [${modRule.ruleNumber}]:${modRule.ruleName}`}
        </NavLink>
      </td>
      <td width='20%' className='text-center'>
        {formatDate(modRule.createdAt)}
      </td>
      <td width='20%' className='text-center'>
        <EnactStatus modRule={modRule} />
      </td>
    </tr>
  );
};

const EnactRuleRightMain = () => {
  const { t } = useTranslation();
  const { modRule } = useModRule();
  const { id } = useParams();
  const axiosAuth = useAxiosAuth();
  const { modChapter, setModChapter } = useModChapter();
  const [tempList, setTempList] = useState([]);

  useEffect(() => {
    if (!id) {
      const fetchTemporary = async () => {
        try {
          const res = await axiosAuth({
            method: 'GET',
            url: `api/approval/fetchTempSavedData?classification=enact`,
          });
          setTempList(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchTemporary();
    }
  }, [id]);

  const handleFileType = target => {
    const chapterIndex = modRule.modChapterRef.filter(
      el => el.chapterType === 'CH'
    ).length;

    setModChapter({
      fileType: target, // type : content / file
      modType: 'new',
      chapterType: 'CH',
      content: '',
      uri: '',
      chapterIdx: chapterIndex,
      chapterNumber: `${modRule.ruleNumber}-CH${chapterIndex + 1}`,
      chapterName: '',
      chapterContents: {
        header: '',
        children: [],
      },
    });
  };

  const handleChapterType = e => {
    e.preventDefault();
    const { value, i } = e.target;
    if (modChapter._id && modChapter._id !== '') {
      setModChapter(prev => ({
        ...prev,
        chapterType: value,
        sortNum: i,
        chapterNumber: `${modRule.ruleNumber}-${value}`,
      }));
    } else {
      const chapterIndex = modRule.modChapterRef.filter(
        el => el.chapterType === value
      ).length;
      setModChapter(prev => ({
        ...prev,
        chapterType: value,
        sortNum: i,
        chapterIdx: chapterIndex + 1,
        chapterNumber: `${modRule.ruleNumber}-${value}${chapterIndex + 1}`,
      }));
    }
  };

  const onChangeModChapter = e => {
    const { value, name } = e.target;
    setModChapter({
      ...modChapter,
      [name]: value,
    });
  };

  return !modRule?._id ? (
    <>
      <div>{t('modrule.dcn-desc')}</div>
      <div
        style={{ marginTop: '1vh' }}
        className='modrulestatus__main__table__before'>
        <Table className='modrulestatus__main__table'>
          <thead className='modrulestatus__main__table__header'>
            <tr className='text-center'>
              <th width='10%'>{t('table.part')}</th>
              <th width='10%'>{t('modrule.lan')}</th>
              <th width='40%'>
                [{t('table.rule-num')}]: {t('table.rule-title')}{' '}
              </th>
              <th width='20%'>{t('table.date')}</th>
              <th width='20%'>{t('table.status')}</th>
            </tr>
          </thead>
          <tbody className='modrulestatus__main__table__body'>
            {tempList.length > 0 &&
              tempList.map((temp, index) => (
                <RuleStatusTable key={temp._id} index={index} modRule={temp} />
              ))}
          </tbody>
        </Table>
      </div>
    </>
  ) : (
    <div className='enactrule__main__addchapter'>
      <div className='addchapter__header'>
        <div className='addchapter__header__tabs'>
          {modRule.viewType !== 'files' && (
            <button
              type='button'
              aria-hidden='true'
              className={`addchapter__tabs__item ${
                modChapter?.fileType === 'content' && 'active'
              }`}
              disabled={modChapter._id}
              onClick={() => handleFileType('content')}>
              {t('modrule.text')}
            </button>
          )}
          {modRule.viewType !== 'contents' && (
            <button
              type='button'
              aria-hidden='true'
              className={`addchapter__tabs__item ${
                ['pdf', 'xls', 'xlsx', 'doc', 'docx'].some(
                  docType => modChapter?.fileType === docType
                ) && 'active'
              }`}
              disabled={modChapter._id}
              onClick={() => handleFileType('pdf')}>
              {t('modrule.file')}
            </button>
          )}
        </div>
        {modChapter.fileType === '' ? (
          <div>{t('modrule.select-chapType')}</div>
        ) : (
          <>
            <div className='addchapter__header__modtypeselect'>
              <Form.Select
                aria-label='Default select example'
                onChange={handleChapterType}
                value={modChapter.chapterType}>
                {OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className='addchapter__header__numberinput'>
              <InputGroup>
                <InputGroup.Text>{t('modrule.chap-num')}</InputGroup.Text>
                <Form.Control
                  id='enactrule_input_chapternumber'
                  aria-label='chapterNumber'
                  name='chapterNumber'
                  onChange={onChangeModChapter}
                  value={modChapter.chapterNumber}
                />
              </InputGroup>
            </div>
            <div className='addchapter__header__nameinput'>
              <InputGroup>
                <InputGroup.Text>{t('modrule.chap-title')}</InputGroup.Text>
                <Form.Control
                  id='enactrule_input_chaptername'
                  aria-label='chapterName'
                  name='chapterName'
                  onChange={onChangeModChapter}
                  value={modChapter.chapterName}
                />
              </InputGroup>
            </div>
          </>
        )}
      </div>
      {modChapter?.fileType === '' && (
        <div>
          <FaLongArrowAltUp />
          {t('modrule.select-type')}
        </div>
      )}
      {modChapter?.fileType === 'content' && <EnactRuleAddContent />}
      {['pdf', 'xls', 'xlsx', 'doc', 'docx'].some(
        docType => modChapter?.fileType === docType
      ) && <EnactRuleAddFile />}
    </div>
  );
};

export default EnactRuleRightMain;
