import React, { createRef, useEffect, useRef, useState } from 'react';
import '../../scss/RuleInfo/FormList.scss';
import { NavLink, useParams, useLocation } from 'react-router-dom';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const ListTable = ({ category, rule, categoryRef }) => {
  const { t } = useTranslation();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <div style={{ marginBottom: '4vh' }} ref={categoryRef}>
      <table className='table'>
        <thead>
          <tr>
            <th
              colSpan='5'
              id={`category-${category.categoryNumber}`}>{`[ ${category.categoryNumber} ] ${category.category} - ${rule.ruleName}`}</th>
          </tr>
          <tr style={{ textAlign: 'center' }}>
            <th width='6%'>{t('table.index')}</th>
            <th width='16%'>{t('table.code-num')}</th>
            <th width='48%'>{t('table.title')}</th>
            <th width='15%'>{t('table.revision-date')}</th>
            <th width='15%'>{t('table.remark')}</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: 'center' }}>
          {rule?.chapterRef.map((el, i) => (
            <tr key={`${el.chapterNumber}-${el.chapterName}-${el.ruleNumber}`}>
              <td>{i + 1}</td>
              <td align='left'>
                <NavLink
                  to={`/ruleinfo/mix/${rule.ruleNumber}/${el.chapterNumber}`}
                  style={{ textDecoration: 'none', color: 'black' }}>
                  {el.chapterNumber}
                </NavLink>
              </td>
              <td align='left'>
                {' '}
                <NavLink
                  to={`/ruleinfo/mix/${rule.ruleNumber}/${el.chapterNumber}`}
                  style={{ textDecoration: 'none', color: 'black' }}>
                  {el.chapterName}
                </NavLink>
              </td>
              {el.createdAt ? (
                <td>
                  {timezoneFormat(
                    utcToZonedTime(
                      toDate(el.createdAt, {
                        timeZone: 'UTC',
                      }),
                      timezone
                    ),
                    'yyyy-MM-dd',
                    { timeZone: timezone }
                  )}
                </td>
              ) : (
                <td>-</td>
              )}
              <td>{el.reamark ? el.reamark : '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FormList = () => {
  const { lan } = useParams();
  const axiosAuth = useAxiosAuth();
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  const categoryRefs = useRef([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFormList = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `api/category/companyform/${lan}`,
      });
      setCategories(response.data);
    };
    fetchFormList();
  }, [lan]);

  useEffect(() => {
    const { hash } = location;
    if (hash && categories.length > 0) {
      const decodedHash = decodeURIComponent(hash.substring(1));
      const index = categories.findIndex(
        category => `category-${category.categoryNumber}` === decodedHash
      );
      if (
        index !== -1 &&
        categoryRefs.current[index] &&
        categoryRefs.current[index].current
      ) {
        categoryRefs.current[index].current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [location, categories]);

  // 카테고리 레퍼런스 배열 초기화
  if (categoryRefs.current.length !== categories.length) {
    categoryRefs.current = Array(categories.length)
      .fill()
      .map((_, i) => categoryRefs.current[i] || createRef());
  }

  return (
    <div className='ruleinfo__formlist'>
      <div className='ruleinfo__rulelist__header'>
        <h4>{t('components.ruleInfo.list-of-company-form')}</h4>
      </div>
      <div className='ruleinfo__formlist__tablecontainer'>
        {categories?.map((category, categoryIndex) =>
          category.ruleRef.map((rule, ruleIndex) => (
            <ListTable
              key={`${rule._id}-${rule.ruleId}-${rule.createdAt}`}
              category={category}
              // 첫 번째 ruleRef에 대해서만 ref를 할당
              categoryRef={
                ruleIndex === 0 ? categoryRefs.current[categoryIndex] : null
              }
              rule={rule}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default FormList;
