import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../scss/Mobile/MobileMyPage/MobileMyPage.scss';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const MobileMyPage = () => {
  const { t } = useTranslation();

  const [myProfile, setMyProfile] = useState({
    company: '',
    contact: '',
    email: '',
    employno: '',
    grade: '',
    hiredate: '',
    id: '',
    officecontact: '',
    org: {},
    position: {},
    refreshToken: '',
    roles: [],
    username: '',
    profileImg: '',
    signatureImg: '',
  });
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    const profile = async () => {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/mypage/user',
          data: {
            email: auth.email,
          },
        });
        setMyProfile(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    profile();
  }, []);

  return (
    <div className='mobileMyPage'>
      <div className='mobileMyPage__header'>
        {myProfile && myProfile.username}
        {t('mypage.header')}
      </div>
      <div className='mobileMyPage__contents'>
        <div className='mobileMyPage__contents__profileBox'>
          <div className='profileImg'>
            <img
              src={
                myProfile.profileImg && myProfile.profileImg !== ''
                  ? myProfile.profileImg
                  : `${process.env.PUBLIC_URL}/images/defaultuser.png`
              }
              alt='profileImg'
            />
          </div>
          <div className='profileName'>
            <span className='title'>{t('userlog.loginlog.username')} :</span>
            <span>{myProfile && myProfile.username}</span>
          </div>
        </div>
        <div className='mobileMyPage__contents__signBox'>
          <div className='signatureimg__header'>
            <span className='title'>{t('mypage.sign')} : </span>
            {myProfile.signatureImg && myProfile.signatureImg !== '' ? (
              <span>{t('mypage.registration')}</span>
            ) : (
              <span>{t('mypage.not-registered')}</span>
            )}
          </div>
          <div className='signatureimg'>
            {myProfile.signatureImg && myProfile.signatureImg !== '' ? (
              <img
                src={myProfile.signatureImg}
                className='myProfile__signatureimg__img'
                alt='signatureImg'
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                className='dd'
                alt='signatureImg'
              />
            )}
          </div>
        </div>
        <div className='mobileMyPage__contents__item'>
          <span className='title'>{t('login.email')}</span>
          <span>{myProfile && myProfile.email}</span>
        </div>
        <div className='mobileMyPage__contents__item'>
          <span className='title'>{t('groupChart.orgchart.org')}</span>
          <span>{myProfile.org && myProfile.org.name}</span>
        </div>
        <div className='mobileMyPage__contents__item'>
          <span className='title'>{t('groupChart.orgchart.position')}</span>
          <span>{myProfile && myProfile.position.positionName}</span>
        </div>
        <div className='mobileMyPage__contents__item'>
          <span className='title'>{t('groupChart.orgchart.employNum')}</span>
          <span>{myProfile && myProfile.employno}</span>
        </div>

        <div className='mobileMyPage__contents__item'>
          <span className='title'>{t('mypage.auth')}</span>
          <span>{myProfile && myProfile.grade.gradeName}</span>
        </div>
        <div className='mobileMyPage__contents__item'>
          <span className='title'>{t('groupChart.orgchart.hiredate')}</span>
          <span>{myProfile && myProfile.hiredate}</span>
        </div>
        <div className='mobileMyPage__contents__item'>
          <span className='title'>{t('groupChart.orgchart.phoneNum1')}</span>
          <span>{myProfile && myProfile.contact}</span>
        </div>
        <div className='mobileMyPage__contents__item'>
          <span className='title'>{t('groupChart.orgchart.phoneNum2')} </span>
          <span>{myProfile && myProfile.officecontact}</span>
        </div>
      </div>
    </div>
  );
};

export default MobileMyPage;
