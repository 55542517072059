import React, { useRef, useState, useEffect } from 'react';
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../scss/Dev/NewDevRule.scss';
import Select from 'react-select';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import DevChapContents from './DevChapContents';
import DevDcnSet from './DevDcnSet';
import DatePicker from '../DatePicker/DatePicker';
import CustomFileInput from '../DatePicker/FileInput';

window.Buffer = window.Buffer || require('buffer').Buffer;

const DevOldDraft = () => {
  // 개발자 전용 룰 개정 화면 <개정등록>
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const editorRef = useRef(null);
  const [category, setCategory] = useState({ label: '', value: '' });
  const [options, setOptions] = useState([]);
  const [ruleOptions, setRuleOptions] = useState([]);
  const [oldRuleOptions, setOldRuleOptions] = useState([]);
  const [oldRule, setOldRule] = useState({
    _id: '',
    ruleNumber: '',
    ruleName: '',
  });
  const [chapterOptions, setChapterOptions] = useState([]);
  const [lan, setLan] = useState('kor');
  const [rule, setRule] = useState({ _id: '', ruleNumber: '', ruleName: '' });
  const [chapter, setChapter] = useState({
    _id: '',
    modType: '',
    fileType: '',
    chapterNumber: '',
    chapterName: '',
    chapterContents: {
      header: '',
      children: [],
    },
  });
  const [oldRuleDate, setOldRuleDate] = useState('');
  const [dcnOpen, setDcnOpen] = useState(false);
  const [fileOne, setFileOne] = useState();
  const { t, i18n } = useTranslation();

  const oldChapterRef = useRef(chapter);

  useEffect(() => {
    const initcategorySelect = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/category/all/kor',
      });
      const cateOptionsArr = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(cateOptionsArr);
    };
    initcategorySelect();
  }, []);

  useEffect(() => {
    const fetchcategoryLan = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/all/${lan}`,
      });
      const cateOptionsArr = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(cateOptionsArr);
    };
    fetchcategoryLan();
  }, [lan]);

  const handleCategoryValue = async e => {
    setCategory(e);
    const response = await axiosAuth({
      method: 'GET',
      url: `/api/category/${e.value._id}/${auth.id}`,
    });
    const { ruleRef } = response.data;
    const ruleOptionsArr = ruleRef.map(ruleOption => ({
      label: `${ruleOption.ruleNumber} : ${ruleOption.ruleName}`,
      value: ruleOption,
    }));
    setRuleOptions(ruleOptionsArr);
  };

  const handleRuleValue = async e => {
    // chapter select options 업데이트
    try {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/dev/oldrulelistall/${e.value.ruleNumber}`,
      });

      const oldRules = res.data.map(oldR => ({
        label: `${oldR.ruleNumber} : ${timezoneFormat(
          utcToZonedTime(
            toDate(oldR.revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        )}`,
        value: oldR,
      }));

      setOldRuleOptions(oldRules);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOldRuleValue = async e => {
    try {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/dev/oldrulelist/${e.value._id}`,
      });
      const { chapterRef } = response.data;
      const chOps = chapterRef.map(chOp => ({
        label: `${chOp.chapterNumber} : ${chOp.chapterName}`,
        value: chOp,
      }));
      setOldRule(response.data);
      setOldRuleDate(
        timezoneFormat(
          utcToZonedTime(
            toDate(response.data.revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        )
      );
      setChapterOptions(chOps);
    } catch (error) {
      console.log(error);
      toast(t('modrule.chapter-communication-failed'));
    }
  };

  const handleChapterValue = async e => {
    const chapterObj = {
      ...e.value,
      createdAt: timezoneFormat(
        utcToZonedTime(
          toDate(e.value.createdAt, {
            timeZone: 'UTC',
          }),
          timezone
        ),
        'yyyy-MM-dd',
        { timeZone: timezone }
      ),
    };
    oldChapterRef.current = chapterObj;
    setChapter(chapterObj);
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setChapter(prev => ({ ...prev, [name]: value }));
  };

  const onChangeRuleNumber = e => {
    setRule({ ...rule, ruleNumber: e.target.value });
  };

  const onChangeRuleName = e => {
    setRule({ ...rule, ruleName: e.target.value });
  };

  const onChangeOldRuleDate = e => {
    setOldRuleDate(e.target.value);
  };

  const editCurrRuleDate = async () => {
    try {
      const res = await axiosAuth({
        method: 'PUT',
        url: '/api/dev/oldrulerev',
        data: {
          oldRuleId: oldRule._id,
          revisedAt: new Date(oldRuleDate),
        },
      });
      toast(
        `${t('modrule.rule-date')} - ${res.data.ruleNumber} : ${
          res.data.revisedAt
        }`
      );
      setOldRuleDate(
        timezoneFormat(
          utcToZonedTime(
            toDate(res.data.revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        )
      );
    } catch (error) {
      console.log(error);
      toast(t('modrule.edit-rule-date-failed'));
    }
  };

  // 변경된 필드만 추출하는 함수
  const getUpdatedFields = () => {
    const changes = {};
    Object.keys(chapter).forEach(key => {
      if (key === 'createdAt') {
        if (chapter[key] !== oldChapterRef.current[key]) {
          changes[key] = new Date(chapter[key]);
        }
      }
      if (chapter[key] !== oldChapterRef.current[key]) {
        changes[key] = chapter[key];
      }
    });
    return changes;
  };

  const chapterEditSubmit = async () => {
    // const ckInstance = editorRef.current;
    // if (ckInstance) {
    //   const editorData = ckInstance.editor.getData({ pagination: true });
    //   createContents(editorData);

    console.log(getUpdatedFields());

    try {
      const response = await axiosAuth({
        method: 'PUT',
        url: `/api/dev/editchapter/${chapter._id}`,
        data: {
          ...getUpdatedFields(),
        },
      });
      toast(
        `(${response.status})${category.value} - ${t(
          'modrule.success-add-chap'
        )}`
      );
    } catch (error) {
      console.log(error);
      toast(t('modrule.edit-chap-failed'));
    }
  };
  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  function customUploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(file => {
            data.append('file', file);
            axiosMultipart
              .post('/api/modrule/imageupload', data)
              .then(res => resolve({ default: res.data.imageUrl }))
              .catch(err => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    const editorinstance = editor;
    editorinstance.plugins.get('FileRepository').createUploadAdapter =
      loader => {
        return customUploadAdapter(loader);
      };
  }

  // const createContents = docData => {
  //   const domparser = new DOMParser();
  //   const docStr = domparser.parseFromString(docData, 'text/html');
  //   if (docData.match(/<h2>/)) {
  //     const h2title = docStr
  //       .getElementsByTagName('h2')[0]
  //       .innerHTML.replace(/(<([^>]+)>)/gi, '')
  //       .replace(/&nbsp;/g, '')
  //       .replace(/&amp;/g, '&')
  //       .replace(/&lt;/g, '<')
  //       .replace(/&gt;/g, '>');
  //     setChapterContents(prev => ({
  //       ...prev,
  //       header: h2title,
  //     }));
  //   } else {
  //     setChapterContents(prev => ({
  //       ...prev,
  //       header: '',
  //     }));
  //   }
  //   if (docData.match(/<h3>/)) {
  //     const h3title = [...docStr.getElementsByTagName('h3')].map(tag =>
  //       tag.innerHTML
  //         .replace(/(<([^>]+)>)/gi, '')
  //         .replace(/&nbsp;/g, '')
  //         .replace(/&amp;/g, '&')
  //         .replace(/&lt;/g, '<')
  //         .replace(/&gt;/g, '>')
  //     );
  //     setChapterContents(prev => ({
  //       ...prev,
  //       children: [...h3title],
  //     }));
  //   } else {
  //     setChapterContents(prev => ({
  //       ...prev,
  //       children: [],
  //     }));
  //   }
  // };

  // const submitModChapterContent = async () => {
  //   const ckInstance = editorRef.current;
  //   if (ckInstance) {
  //     const editorData = ckInstance.editor.getData({ pagination: true });
  //     // createContents(editorData);
  //     try {
  //       const response = await axiosAuth({
  //         method: 'post',
  //         url: '/api/modchapter/dev/contentnew',
  //         data: {
  //           ruleId: rule._id,
  //           oldChapterId: chapter._id,
  //           modType: 'replace',
  //           chapterType: chapter.chapterType,
  //           chapterIdx: chapter.chapterIdx,
  //           chapterNumber: chapter.chapterNumber,
  //           chapterName: chapter.chapterName,
  //           content: chapter.content,
  //           lan: chapter.lan,
  //           // chapterContents,
  //         },
  //       });
  //       if (response.status === 200) {
  //         toast('기안 완료');
  //       }
  //     } catch (error) {
  //       toast('챕터 등록 실패');
  //     }
  //   } else {
  //     alert('오류 발생, 다시 시도 해주세요');
  //   }
  // };

  const submitAddFile = async () => {
    const formData = new FormData();
    formData.append('rulePath', rule.ruleNumber);
    formData.append('ruleId', rule._id);
    formData.append('oldChapterId', chapter._id);
    formData.append('modType', 'replace');
    formData.append('chapterType', chapter.chapterType);
    formData.append('chapterIdx', chapter.chapterIdx);
    formData.append('chapterNumber', chapter.chapterNumber);
    formData.append('chapterName', chapter.chapterName);
    formData.append('lan', chapter.lan);
    formData.append('file', fileOne);

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/modchapter/dev/filenew',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      if (res.status === 200) {
        toast('기안 완료');
        setChapter({
          _id: '',
          fileType: '',
          chapterNumber: '',
          chapterName: '',
        });
        setFileOne(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePreviewUpload = async e => {
    setFileOne(e.target.files[0]);
  };

  return (
    <div className='devdraft'>
      <div className='devdraft__side'>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='editor-lanbtn-kor'>
            {t('modrule.select-rule-lan')}
          </label>

          <ButtonGroup className='devdraft__side__lanradiobtn'>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={radio.value}
                id={`radio-${idx}`}
                type='radio'
                variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                name='radio'
                value={radio.value}
                checked={lan === radio.value}
                onChange={e => setLan(e.currentTarget.value)}>
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>

        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='select-category'>
            {t('modrule.cate')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.cate-placeholder')}</div>}
            isSearchable
            id='select-category'
            className='devdraft__side__category__select'
            options={options}
            onChange={handleCategoryValue}
          />
        </div>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='select-rule'>
            {t('modrule.rule')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.rule-placeholder')}</div>}
            isSearchable
            id='select-rule'
            className='devdraft__side__rule__select'
            options={ruleOptions}
            onChange={handleRuleValue}
          />
        </div>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='select-oldrule'>
            {t('modrule.old-rule')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.rule-placeholder')}</div>}
            isSearchable
            id='select-oldrule'
            className='devdraft__side__rule__select'
            options={oldRuleOptions}
            onChange={handleOldRuleValue}
          />
        </div>

        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='input-rulenumber'>
            {t('modrule.rule')} No.
          </label>
          <input
            type='text'
            className='form-control input__rulenumber'
            id='input-rulenumber'
            placeholder='규정 No.'
            value={oldRule.ruleNumber || ''}
            onChange={onChangeRuleNumber}
            required
          />
        </div>
        <div className='devdraft__rule__wrapper'>
          <label className='devdraft__label' htmlFor='input-rulename'>
            {t('modrule.rule-title')}
          </label>
          <input
            type='text'
            className='form-control input-rulename'
            id='input-rulename'
            placeholder={t('modrule.rule-title')}
            value={oldRule.ruleName || ''}
            onChange={onChangeRuleName}
            required
          />
        </div>
        <div className='devdraft__rule__wrapper' style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <label className='devdraft__label' htmlFor='input-rulename'>
              {t('modrule.old-rule')} REVISEDAT
              <DatePicker
                name='createdAt'
                startdate={oldRuleDate || ''}
                onChangeDate={onChangeOldRuleDate}
                language={i18n.language}
                style={{ width: '100%', border: '1px solid #ccc' }}
                required
              />
            </label>
            <span> </span>
            <Button
              disabled={oldRule.revisedAt === ''}
              onClick={editCurrRuleDate}>
              {t('button.edit')}
            </Button>
          </div>
        </div>
        <div
          className='devdraft__side__chapterinfos'
          style={{ display: 'flex' }}>
          <div className='devdraft__side__chaptercontents'>
            <h5>{t('modrule.List')}</h5>
            <DevChapContents chapterContents={chapter.chapterContents} />
          </div>
        </div>
      </div>

      <div className='devdraft__main'>
        <div className='devdraft__main__header'>
          <label className='devdraft__label' htmlFor='select-chapternumber'>
            {t('modrule.chap')}
            <Select
              styles={blackStyles}
              placeholder={<div>{t('revStatus.select-chap-placeholder')}</div>}
              isSearchable
              isDisabled={chapter.modType === 'new'}
              id='select-chapternumber'
              className='devdraft__side__category__select'
              options={chapterOptions}
              onChange={handleChapterValue}
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chapternumber'>
            {t('modrule.select-chap-num')}
            <input
              type='text'
              className='form-control input-chaptername'
              id='input-chapternumber'
              placeholder={t('modrule.select-chap-num')}
              name='chapterNumber'
              value={(chapter.chapterNumber && chapter.chapterNumber) || ''}
              onChange={handleChange}
              required
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chaptername'>
            {t('modrule.chap-title')}
            <input
              type='text'
              className='form-control input-chaptername'
              id='input-chaptername'
              placeholder={t('modrule.chap-title')}
              name='chapterName'
              value={(chapter.chapterName && chapter.chapterName) || ''}
              onChange={handleChange}
              required
            />
          </label>
          <label className='devdraft__label' htmlFor='input-rulename'>
            {t('modrule.create-date')}
            <DatePicker
              name='createdAt'
              startdate={chapter.createdAt || ''}
              onChangeDate={handleChange}
              language={i18n.language}
              style={{ width: '100%', border: '1px solid #ccc' }}
            />
          </label>
        </div>
        {['xls', 'xlsx', 'doc', 'docx', 'pdf'].some(
          el => el === chapter.fileType
        ) && (
          <div>
            <div>
              <Form.Group
                controlId='formFileMultiple'
                encType='multipart/form-data'
                className='mb-3 formContentTop__form'>
                {/* <Form.Control
                  type='file'
                  name='file'
                  multiple
                  onChange={handlePreviewUpload}
                /> */}
                <CustomFileInput
                  type='file'
                  name='file'
                  multiple
                  onChange={handlePreviewUpload}
                  style={{ width: '100%' }}
                />
                <Form.Text className='text-muted'>
                  {t('modrule.extension')} : doc / docx / xls / xlsx / pdf
                </Form.Text>
              </Form.Group>
              <Button onClick={submitAddFile}>MOD {t('modrule.file')}</Button>
            </div>
          </div>
        )}{' '}
        {chapter.fileType === 'content' && (
          <div className='devdraft__main__editor' id='document-container'>
            <div id='toolbar-container' className='toolbar-container' />
            <div id='editor-container' className='editor-container' />
            <CKEditor
              ref={editorRef}
              editor={Editor}
              data={chapter.content}
              config={{
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: i18n.language === 'ko' ? '본문' : 'CONTENT',
                      class: 'ck-heading_paragraph',
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: i18n.language === 'ko' ? '제목 1' : 'TITLE 1',
                      class: 'ck-heading_heading2',
                    },
                    {
                      model: 'heading3',
                      view: 'h3',
                      title: i18n.language === 'ko' ? '제목 2' : 'TITLE 2',
                      class: 'ck-heading_heading3',
                    },
                    {
                      model: 'heading4',
                      view: 'h4',
                      title: i18n.language === 'ko' ? '제목 3' : 'TITLE 3',
                      class: 'ck-heading_heading4',
                    },
                  ],
                },
                extraPlugins: [uploadPlugin],
                autosave: {
                  save(editor) {
                    return setChapter(prev => ({
                      ...prev,
                      content: editor.getData(),
                    }));
                  },
                  waitingTime: 3000,
                },
              }}
              onReady={editor => {
                const editorContainer =
                  document.querySelector('#editor-container');
                const toolbarContainer =
                  document.querySelector('#toolbar-container');

                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                editorContainer.appendChild(editor.ui.view.editable.element);

                editor.ui.update();
              }}
              onError={(error, editor) => {
                if (editor.willEditorRestart) {
                  editor.ui.view.toolbar.element.remove();
                }
                if (error) {
                  console.log(error);
                }
              }}
            />
          </div>
        )}
        {chapter.fileType === '' && <div>{t('modrule.select-chap')}</div>}
        <div className='devdraft__main__footer'>
          <Button
            type='submit'
            disabled={chapter.fileType !== 'content'}
            onClick={chapterEditSubmit}>
            {t('modrule.amend-draft')}
          </Button>
        </div>
      </div>
      {dcnOpen && <DevDcnSet open={dcnOpen} setOpen={setDcnOpen} rule={rule} />}
    </div>
  );
};

export default DevOldDraft;
