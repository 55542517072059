import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './RuleBaseMobile.scss';
import MobileMain from './mobile/MobileMain';
import MobileLogin from './mobile/MobileLogin';
import MobileCateList from './mobile/MobileCateList';
import MobileRule from './mobile/MobileRule/MobileRule';
import MobileNoticeList from './mobile/MobileNotice/MobileNoticeList';
import MobileNotice from './mobile/MobileNotice/MobileNotice';
import MobileRevStatusList from './mobile/MobileRevStatus/MobileRevStatusList';
import MobileSetting from './mobile/MobileSetting';
import MobileLog from './mobile/MobileLog/MobileLog';
import MobileRuleChart from './mobile/MobileRuleChart';
import MobileNotFound from './mobile/MobileNotFound';
import MobileMyPage from './mobile/MobileMyPage/MobileMyPage';
import MobileRuleSearch from './mobile/MobileRule/MobileRuleSearch';
import MobileLayout from './mobile/MobileLayout';
import PersistLogin from './PersistLogin';
import RequireAuth from './components/RequireAuth';
import Roles from './common/auth/Roles';
import MobileUnauthorized from './mobile/MobileUnauthorized';
import MobileTeamManual from './mobile/MobileTeamManual/MobileTeamManual';
import MobileTeamManualOne from './mobile/MobileTeamManual/MobileTeamManualOne';
import MobileRuleMix from './mobile/MobileRule/MobileRuleMix';
import MobileRevStatusDetail from './mobile/MobileRevStatus/MobileRevStatusDetail';

const RuleBaseMobile = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<MobileLayout />}>
          {/* 로그인 */}
          <Route path='/login' element={<MobileLogin />} />
          <Route path='unauthorized' element={<MobileUnauthorized />} />
          {/* 404 */}
          <Route path='*' element={<MobileNotFound />} />
          <Route element={<PersistLogin />}>
            <Route index element={<MobileMain />} />
            {/* 마이페이지 */}
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    Roles.DEVELOPER,
                    Roles.MANAGER,
                    Roles.ADMIN,
                    Roles.USER,
                  ]}
                />
              }>
              <Route path='/mypage' element={<MobileMyPage />} />
              {/* 규정정보 */}
              <Route path='/ruleinfo' element={<MobileCateList />} />
              <Route
                path='contents/:ruleNum/:chapterNumber?/:searchText?'
                element={<MobileRule />}
              />
              <Route
                path='mix/:ruleNum/:chapterNumber?/:searchText?'
                element={<MobileRuleMix />}
              />
              <Route
                path='/searchrule/:searchText'
                element={<MobileRuleSearch />}
              />
              {/* 공지사항 */}
              <Route path='/notices' element={<MobileNoticeList />} />
              <Route path='/notices/:id' element={<MobileNotice />} />
              {/* 팀메뉴얼 */}
              <Route path='/teammanual/:orgId' element={<MobileTeamManual />} />
              <Route
                path='/teammanual/:orgId/:manualNumber'
                element={<MobileTeamManualOne />}
              />
              {/* DCN현황 */}
              <Route path='/revstatus' element={<MobileRevStatusList />} />
              {/* DCN현황 상세보기 */}
              <Route
                path='/revstatus/:dcnNum'
                element={<MobileRevStatusDetail />}
              />
              {/* 유저로그 */}
              <Route path='/userlog' element={<MobileLog />} />
              {/* 규정통계 */}
              <Route path='/rulechart' element={<MobileRuleChart />} />
              {/* 설정 */}
              <Route path='/setting' element={<MobileSetting />} />
            </Route>
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        position='top-center'
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        theme='light'
      />
    </>
  );
};

export default RuleBaseMobile;
