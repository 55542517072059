import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { BiX } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { toast } from 'react-toastify';
import '../../scss/ModRule/ModRuleSetting.scss';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const ModRuleSetting = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const [update, setUpdate] = useState(false);
  const [approvListOpen, setApprovListOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [payerList, setPayerList] = useState();
  const [payerEditList, setPayerEditList] = useState();

  const [listName, setListName] = useState('');
  const [editName, setEditName] = useState('');

  const [examine, setExamine] = useState([]);
  const [examineList, setExamineList] = useState([]);
  const [editExamList, setEditExamList] = useState([]);

  const [approve, setApprove] = useState([]);
  const [approveList, setApproveList] = useState([]);
  const [editApprList, setEditApprList] = useState([]);

  const [reference, setReference] = useState([]);
  const [referenceList, setReferenceList] = useState([]);
  const [editReferList, setEditReferList] = useState([]);
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    const UserApproveList = async () => {
      const writer = auth.id;
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/modrule/approvallist/${writer}`,
        });
        setPayerList(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    UserApproveList();
  }, []);

  useEffect(() => {
    const UserApproveList = async () => {
      const writer = auth.id;
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/modrule/approvallist/${writer}`,
        });
        setPayerList(res.data);
        setUpdate(false);
      } catch (err) {
        console.log(err);
      }
    };
    UserApproveList();
  }, [update]);

  useEffect(() => {
    const controller = new AbortController();
    const userList = async () => {
      try {
        const [approveRes, examineRes, referenceRes] = await Promise.all([
          axiosAuth.get('/api/modrule/users/approve'),
          axiosAuth.get('/api/modrule/users/examine'),
          axiosAuth.get('/api/modrule/users/refuser'),
        ]);
        const approveOptions = approveRes.data.map(el => ({
          label: `[${el.org.name}] ${el.username} (${el.position.positionName})`,
          value: el,
        }));
        const examineOptions = examineRes.data.map(el => ({
          label: `[${el.org.name}] ${el.username} (${el.position.positionName})`,
          value: el,
        }));
        const referenceOptions = referenceRes.data.map(el => ({
          label: `[${el.org.name}] ${el.username} (${el.position.positionName})`,
          value: el,
        }));
        setApprove(approveOptions);
        setExamine(examineOptions);
        setReference(referenceOptions);
      } catch (err) {
        console.log(err);
      }
    };
    userList();
    return () => {
      controller.abort();
    };
  }, []);

  const addList = () => {
    setEditOpen(false);
    setApprovListOpen(prev => !prev);
  };
  const popupClose = () => {
    setApprovListOpen(false);
    setEditOpen(false);

    setListName('');
    setExamineList('');
    setApproveList('');
    setReferenceList('');

    setEditName('');
    setEditExamList('');
    setEditApprList('');
    setEditReferList('');
  };

  const targetUserDel = (target, type) => {
    if (type === 'exam') {
      setExamineList(prevArr => prevArr.filter(item => item !== target));
    } else if (type === 'appr') {
      setApproveList(prevArr => prevArr.filter(item => item !== target));
    } else {
      setReferenceList(prevArr => prevArr.filter(item => item !== target));
    }
  };

  const updateUserDel = (target, type) => {
    if (type === 'exam') {
      setEditExamList(prevArr => prevArr.filter(item => item !== target));
    } else if (type === 'appr') {
      setEditApprList(prevArr => prevArr.filter(item => item !== target));
    } else {
      setEditReferList(prevArr => prevArr.filter(item => item !== target));
    }
  };

  // 결재선 등록
  const onChangeListName = e => {
    setListName(e.target.value);
  };
  const targetExamine = e => {
    setExamineList(prevArr => [...prevArr, e.value]);
  };
  const targetApprove = e => {
    setApproveList(prevArr => [...prevArr, e.value]);
  };
  const targetReference = e => {
    setReferenceList(prevArr => [...prevArr, e.value]);
  };
  const onSubmit = async () => {
    if (!listName) {
      toast(t('modrule.payment.paymentLine-name-placeholder'));
    } else if (examineList.length !== 0 || approveList.length !== 0) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/modrule/approvallist/new`,
          data: {
            writer: auth.id,
            listname: listName,
            examines: examineList ? examineList.map(exam => exam._id) : [],
            approve: approveList ? approveList.map(appr => appr._id) : [],
            reference: referenceList
              ? referenceList.map(refer => refer._id)
              : [],
          },
        });
        toast(`'${res.data.listname}' ${t('modrule.register-approval-line')}`);
        setUpdate(!update);
        setApprovListOpen(false);
        setListName('');
        setExamineList('');
        setApproveList('');
        setReferenceList('');
      } catch (err) {
        console.log(err);
      }
    } else {
      toast(t('modrule.select-approvalLine-user'));
    }
  };

  // 결재선 수정
  const approveListEdit = list => {
    setEditOpen(prev => !prev);
    setApprovListOpen(false);
    setPayerEditList(list);
  };
  const onChangeEditListName = e => {
    setEditName(e.target.value);
  };
  const editExamine = e => {
    setEditExamList(prevArr => [...prevArr, e.value]);
  };
  const editApprove = e => {
    setEditApprList(prevArr => [...prevArr, e.value]);
  };
  const editReference = e => {
    setEditReferList(prevArr => [...prevArr, e.value]);
  };

  const onEditSubmit = async () => {
    const { listname, _id } = payerEditList;
    if (!editName && !editExamList && !editApprList && !editReferList) {
      toast(t('modrule.edit-no-content'));
    } else {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/modrule/approvallist/edit`,
          data: {
            listId: _id,
            listname: editName && editName ? editName : listname,
            examines:
              editExamList && editExamList.length !== 0
                ? editExamList.map(exam => exam._id)
                : payerEditList.examines.map(exam => exam._id),
            approve:
              editApprList && editApprList.length !== 0
                ? editApprList.map(appr => appr._id)
                : payerEditList.approve.map(appr => appr._id),
            reference:
              editReferList && editReferList.length !== 0
                ? editReferList.map(refer => refer._id)
                : payerEditList.reference.map(appr => appr._id),
          },
        });
        toast(`${res.data.listname} ${t('modrule.edit-approval-line')}`);
        setUpdate(!update);
        setEditOpen(prev => !prev);
        setEditName('');
        setEditExamList('');
        setEditApprList('');
        setEditReferList('');
      } catch (err) {
        console.log(err);
      }
    }
  };

  // 결재선 삭제
  const approveListDel = async list => {
    const { listname, _id } = list;
    if (window.confirm(`'${listname}' ${t('modrule.del-approval-line-q')}`)) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/modrule/approvallist/del`,
          data: {
            listId: _id,
          },
        });
        toast(`${res.data}! ${t('modrule.del-approval-line')}`);
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className='modRuleSetting'>
      <section className='approvList'>
        <p> {t('modrule.payment.favorites')}</p>
        <div className='approvList__contentsBox'>
          <Button
            className='approvList__contentsBox__addList'
            variant='secondary'
            onClick={addList}>
            {t('modrule.payment.add-line')}
          </Button>
          {approvListOpen || editOpen ? (
            <div className='addListPopup'>
              <div className='addListPopup__header'>
                {approvListOpen ? (
                  <span> {t('modrule.payment.paymentLine-submit')}</span>
                ) : (
                  <span>{t('modrule.payment.edit-paymentLine')}</span>
                )}
                <BiX onClick={popupClose} size={34} />
              </div>
              {editOpen ? (
                <span style={{ color: 'orangered', fontSize: '0.9rem' }}>
                  {t('modrule.payment.edit-sum')}
                </span>
              ) : null}
              <hr />
              <div className='addListPopup__body'>
                <div className='addListPopup__body__listName'>
                  {approvListOpen ? (
                    <Form.Group encType='multipart/form-data'>
                      <Form.Label>
                        {t('modrule.payment.paymentLine-name')}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        multiple
                        value={listName}
                        onChange={onChangeListName}
                        placeholder={t(
                          'modrule.payment.paymentLine-name-placeholder'
                        )}
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group encType='multipart/form-data'>
                      <Form.Label>
                        {t('modrule.payment.paymentLine-name')}
                      </Form.Label>
                      <Form.Control
                        type='text'
                        multiple
                        defaultValue={payerEditList.listname}
                        onChange={onChangeEditListName}
                        placeholder={t('modrule.edit-approvalLine-placeholder')}
                      />
                    </Form.Group>
                  )}
                </div>
                {approvListOpen ? (
                  <div className='addListPopup__body__contents'>
                    <div className='addBox'>
                      <span>{t('modrule.payment.reviewer')}</span>
                      <Select
                        styles={blackStyles}
                        placeholder={t(
                          'modrule.payment.paymentLine-submit-placeholder'
                        )}
                        isSearchable
                        className='addSelect'
                        options={examine}
                        onChange={targetExamine}
                      />
                    </div>
                    <div className='newList'>
                      <p>{t('modrule.payment.selected-user-list')}</p>
                      <div>
                        {examineList &&
                          examineList.map(exam => (
                            <div key={exam._id} className='newList__userBox'>
                              <span>
                                {exam.username}
                                <BiX
                                  size={20}
                                  onClick={() => targetUserDel(exam, 'exam')}
                                />
                                ,
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='addListPopup__body__contents'>
                    <div className='addBox'>
                      <span>{t('modrule.payment.reviewer')}</span>
                      <Select
                        styles={blackStyles}
                        placeholder={t(
                          'modrule.payment.paymentLine-edit-placeholder'
                        )}
                        isSearchable
                        className='addSelect'
                        options={examine}
                        onChange={editExamine}
                      />
                    </div>
                    <div className='compareList'>
                      <div className='compareList__oldList'>
                        <span>{t('modrule.payment.user-list')} : </span>
                        {payerEditList &&
                          payerEditList.examines.map(exam => (
                            <span
                              key={exam._id}
                              className='compareList__oldList__userName'>
                              {exam.username},
                            </span>
                          ))}
                      </div>
                      <div className='compareList__newList'>
                        <span>
                          {t('modrule.payment.reviewer')}
                          <span style={{ color: 'orangered' }}>
                            ({t('button.edit')}){' '}
                          </span>
                          :
                        </span>
                        {editExamList &&
                          editExamList.map(exam => (
                            <span
                              key={exam._id}
                              className='compareList__newList__userName'>
                              {exam.username}
                              <BiX
                                size={20}
                                onClick={() => targetUserDel(exam, 'exam')}
                              />
                              ,
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
                {approvListOpen ? (
                  <div className='addListPopup__body__contents'>
                    <div className='addBox'>
                      <span>{t('modrule.payment.Approver')}</span>
                      <Select
                        styles={blackStyles}
                        placeholder={t(
                          'modrule.payment.paymentLine-submit-placeholder'
                        )}
                        isSearchable
                        className='addSelect'
                        options={approve}
                        onChange={targetApprove}
                      />
                    </div>
                    <div className='newList'>
                      <p>{t('modrule.payment.selected-user-list')}</p>
                      <div>
                        {approveList &&
                          approveList.map(appr => (
                            <div key={appr._id} className='newList__userBox'>
                              <span>
                                {appr.username}
                                <BiX
                                  size={20}
                                  onClick={() => targetUserDel(appr, 'appr')}
                                />
                                ,
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='addListPopup__body__contents'>
                    <div className='addBox'>
                      <span>{t('modrule.payment.Approver')}</span>
                      <Select
                        styles={blackStyles}
                        placeholder={t(
                          'modrule.payment.paymentLine-edit-placeholder'
                        )}
                        isSearchable
                        className='addSelect'
                        options={approve}
                        onChange={editApprove}
                      />
                    </div>
                    <div className='compareList'>
                      <div className='compareList__oldList'>
                        <span>{t('modrule.payment.user-list')} : </span>
                        {payerEditList &&
                          payerEditList.approve.map(appr => (
                            <span key={appr._id}>{appr.username},</span>
                          ))}
                      </div>
                      <div className='compareList__newList'>
                        <span>
                          {t('modrule.payment.Approver')}
                          <span style={{ color: 'orangered' }}>
                            ({t('button.edit')}){' '}
                          </span>
                          :
                        </span>
                        {editApprList &&
                          editApprList.map(appr => (
                            <span
                              key={appr._id}
                              className='compareList__newList__userName'>
                              {appr.username}
                              <BiX
                                size={20}
                                onClick={() => updateUserDel(appr, 'appr')}
                              />
                              ,
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
                {approvListOpen ? (
                  <div className='addListPopup__body__contents'>
                    <div className='addBox'>
                      <span>{t('modrule.payment.referrer')}</span>
                      <Select
                        styles={blackStyles}
                        placeholder={t(
                          'modrule.payment.paymentLine-submit-placeholder'
                        )}
                        isSearchable
                        className='addSelect'
                        options={reference}
                        onChange={targetReference}
                      />
                    </div>
                    <div className='newList'>
                      <p>{t('modrule.payment.selected-user-list')}</p>
                      <div>
                        {referenceList &&
                          referenceList.map(refer => (
                            <div key={refer._id} className='newList__userBox'>
                              <span>
                                {refer.username ? refer.username : ''}
                                <BiX
                                  size={20}
                                  onClick={() => targetUserDel(refer, 'refer')}
                                />
                                ,
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='addListPopup__body__contents'>
                    <div className='addBox'>
                      <span>{t('modrule.payment.referrer')}</span>
                      <Select
                        styles={blackStyles}
                        placeholder={t(
                          'modrule.payment.paymentLine-edit-placeholder'
                        )}
                        isSearchable
                        className='addSelect'
                        options={reference}
                        onChange={editReference}
                      />
                    </div>
                    <div className='compareList'>
                      <div className='compareList__oldList'>
                        <span>{t('modrule.payment.user-list')} : </span>
                        {payerEditList &&
                          payerEditList.reference.map(refer => (
                            <span key={refer._id}>{refer.username},</span>
                          ))}
                      </div>
                      <div className='compareList__newList'>
                        <span>
                          {t('modrule.payment.referrer')}
                          <span style={{ color: 'orangered' }}>
                            ({t('button.edit')}){' '}
                          </span>
                          :
                        </span>
                        {editReferList &&
                          editReferList.map(refer => (
                            <span
                              key={refer._id}
                              className='compareList__newList__userName'>
                              {refer.username}
                              <BiX
                                size={20}
                                onClick={() => updateUserDel(refer, 'refer')}
                              />
                              ,
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {approvListOpen ? (
                <Button onClick={onSubmit}>{t('button.apply')}</Button>
              ) : (
                <Button onClick={onEditSubmit}>{t('button.apply')}</Button>
              )}
            </div>
          ) : null}
          <div className='approvList__contentsBox__table'>
            <Table className='text-center'>
              <thead>
                <tr>
                  <td width='11%'>{t('modrule.payment.paymentLine-name')}</td>
                  <td width='27%'>{t('modrule.payment.reviewer')}</td>
                  <td width='27%'>{t('modrule.payment.Approver')}</td>
                  <td width='27%'>{t('modrule.payment.referrer')}</td>
                  <td width='8%'>{t('modrule.payment.management')}</td>
                </tr>
              </thead>
              <tbody>
                {payerList && payerList.length !== 0 ? (
                  payerList.map(el => (
                    <tr key={el._id}>
                      <td>{el.listname}</td>
                      <td width='27%'>
                        {el.examines &&
                          el.examines.map(exam => (
                            <span key={exam._id}>{exam.username},</span>
                          ))}
                      </td>
                      <td width='27%'>
                        {el.approve &&
                          el.approve.map(appr => (
                            <span key={appr._id}>{appr.username},</span>
                          ))}
                      </td>
                      <td width='27%'>
                        {el.reference &&
                          el.reference.map(refer => (
                            <span key={refer._id}>{refer.username},</span>
                          ))}
                      </td>
                      <td>
                        <Button
                          variant='secondary'
                          onClick={() => approveListEdit(el)}>
                          {t('button.edit')}
                        </Button>
                        <Button
                          variant='secondary'
                          onClick={() => approveListDel(el)}>
                          {t('button.delete')}
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>{t('modrule.payment.no-favorites')}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ModRuleSetting;
