import React from 'react';
import Flatpickr from 'react-flatpickr';
import { Korean } from 'flatpickr/dist/l10n/ko';
import { English } from 'flatpickr/dist/l10n/default';
import 'flatpickr/dist/flatpickr.min.css';
import { HiMiniCalendar } from 'react-icons/hi2';
import { isMobile } from 'react-device-detect';
import { format } from 'date-fns';

const DatePicker = ({
  startdate = '',
  onChangeDate,
  language = 'en',
  disabled = false,
  name,
  style = {},
  required = false,
  min = '',
}) => {
  const localeMap = {
    en: English,
    ko: Korean,
  };

  const flatpickrRef = React.useRef(null);

  return (
    <div
      style={{
        width: isMobile ? '100px' : '150px',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: '1px solid #20265b',
        borderRadius: '5px',
        overflow: 'hidden',
        padding: '0 2px',
        ...style,
      }}>
      <Flatpickr
        ref={flatpickrRef}
        value={startdate}
        options={{
          dateFormat: 'Y-m-d',
          locale: localeMap[language] || English,
          disableMobile: true,
          minDate: min,
        }}
        onChange={selectedDates => {
          const selectedDate = selectedDates[0];
          const formattedDate = format(selectedDate, 'yyyy-MM-dd'); // 로컬 날짜 포맷
          onChangeDate({
            target: { name, value: formattedDate },
          });
        }}
        style={{
          maxWidth: isMobile ? '100px' : '150px',
          backgroundColor: disabled ? '#f5f5f5' : '#fff',
          color: disabled ? '#a1a1a1' : '#000',
          cursor: disabled ? 'default' : 'pointer',
          opacity: disabled ? 0.6 : 1,
          border: 'none',
          fontWeight: 'bold',
          padding: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          outline: 'none',
        }}
        placeholder={language === 'ko' ? '연도-월-일' : 'YY-MM-DD'}
        disabled={disabled}
        required={required}
      />
      <button
        type='button'
        onClick={() => flatpickrRef.current?.flatpickr?.open()}
        style={{
          border: 'none',
          background: 'none',
          padding: '0 5px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}>
        <HiMiniCalendar
          style={{
            background: 'none',
          }}
        />
      </button>

      <style>{`
        .flatpickr-input::placeholder {
          font-weight: bold;
          color: #333;
        }
      `}</style>
    </div>
  );
};

export default DatePicker;
