import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import '../../scss/ManagerAuth/ManagerAuthList.scss';
// import { TiChevronRight } from 'react-icons/ti';
import { BiX } from 'react-icons/bi';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { axiosAuth } from '../../common/axios/api';

const AuthUserList = forwardRef(
  (
    { userEmail, setUserEmail, sidenav, searchStr, setDelUpdate, setCounting },
    ref
  ) => {
    const [filternav, setFilternav] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
      setFilternav(sidenav);
    }, []);

    const handleUser = email => {
      setUserEmail(email);
    };
    const contentFilter = () => {
      const reg = new RegExp(searchStr, 'gi');
      if (searchStr === '') {
        setFilternav(sidenav);
        setCounting(sidenav.length);
      } else {
        const navCopy = sidenav.filter(
          el => el.email.match(reg) || el.username.match(reg)
        );
        setFilternav(navCopy);
        setCounting(navCopy.length);
      }
    };

    useImperativeHandle(ref, () => ({
      contentFilter,
    }));

    // 조직도 구성원 삭제
    const handleDeleteuser = async (e, user) => {
      const { id, email, username } = user;
      if (
        e &&
        window.confirm(
          i18n.language === 'ko'
            ? `${username}님을 삭제하시겠습니까?`
            : `Do you want to delete ${username}?`
        )
      ) {
        try {
          const response = await axiosAuth({
            method: 'DELETE',
            url: `/api/mypage/del/${id}/${email}`,
          });
          toast(response.data);
          setDelUpdate(prev => !prev);
          // window.location.reload();
        } catch (error) {
          toast(error.response.data);
        }
      }
    };

    return (
      filternav &&
      filternav.map(user => (
        <div
          className={`Authcollapse__item ${
            userEmail === user.email ? 'select' : ''
          }`}
          key={user.email}
          aria-hidden='true'
          onClick={() => handleUser(user.email)}>
          <span className='Authcollapse__title'>
            {user && `${user.username} : (${user.email})`}
          </span>
          {/* {userEmail === user.email && (
            <span>
              <TiChevronRight size='10' />
            </span>
          )} */}
          <Button variant='secondary' onClick={e => handleDeleteuser(e, user)}>
            <BiX />
          </Button>
        </div>
      ))
    );
  }
);
export default AuthUserList;
