import React from 'react';
import { useTranslation } from 'react-i18next';
import CompareRuleSelect from './CompareRuleSelect';
import CompareRuleDiff from './CompareRuleDiff';
import useCompare from '../../common/hooks/useCompare';
import BreadCrumb from '../BreadCrumb';
import { DiffProvider } from '../../store/DiffProvider';

const initialState = {
  oldChapter: { fileType: '', content: '', uri: '' },
  newChapter: { fileType: '', content: '', uri: '' },
};

const CompareRule = () => {
  const { compare } = useCompare();
  const { t } = useTranslation();

  return (
    <>
      <h4>{t('components.compare.compareRule')}</h4>
      <BreadCrumb />
      <div className='compare__mainbody'>
        <DiffProvider initialState={initialState}>
          <CompareRuleSelect />
          {!compare._id ? (
            <div className='compare__mainbody__noContents'>
              {t('components.compare.noContents')}
            </div>
          ) : (
            <CompareRuleDiff />
          )}
        </DiffProvider>
      </div>
    </>
  );
};

export default CompareRule;
