import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { Button, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  BsFillPlusCircleFill,
  BsFillXCircleFill,
  BsPencilSquare,
} from 'react-icons/bs';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import DatePicker from '../DatePicker/DatePicker';

const RevStatusInputEdit = ({ targetRev, setTargetRev, targetId }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const axiosAuth = useAxiosAuth();
  const selectedIndex = useRef(0);
  const nextSummId = useRef(1);
  const nextChapId = useRef(1);
  const { type } = targetRev;
  const { t, i18n } = useTranslation();
  const [inputType, setInputType] = useState('new');
  const [revInfoInput, setRevInfoInput] = useState({
    revNumber: 0,
    dcnNumber: '',
    revisedAt: '',
    lan: '',
    classification: '',
    revstatus: [],
    refRule: '',
  });
  const [chapterItems, setChapterItems] = useState([{ id: 0, text: '' }]);
  const [summaryItems, setSummaryItems] = useState([{ id: 0, text: '' }]);

  useEffect(() => {
    if (targetRev !== '') {
      (async () => {
        const response = await axiosAuth.get(`/api/revstatus/byid/${targetId}`);
        const parsing = {
          ...response.data,
          revisedAt: timezoneFormat(
            utcToZonedTime(
              toDate(response.data.revisedAt, {
                timeZone: 'UTC',
              }),
              timezone
            ),
            'yyyy-MM-dd',
            { timeZone: timezone }
          ),
        };
        setRevInfoInput(parsing);
      })();
    }
  }, []);
  const handleClose = () => {
    setTargetRev({ type: '' });
  };

  const handlesetRevInfoInput = e => {
    const { value, name } = e.target;
    setRevInfoInput({
      ...revInfoInput,
      [name]: value,
    });
  };

  const deleteRevstatus = i => {
    const revstatusCopy = revInfoInput.revstatus;
    revstatusCopy.splice(i, 1);
    setRevInfoInput({ ...revInfoInput, revstatus: revstatusCopy });
  };

  const addChapterInput = () => {
    const item = {
      id: nextChapId.current,
      text: '',
    };
    setChapterItems([...chapterItems, item]);
    nextChapId.current += 1;
  };

  const deleteChapterInput = target => {
    setChapterItems(chapterItems.filter(item => item.id !== target));
  };

  const handleChapterChange = (e, index) => {
    if (index > chapterItems.length) return;
    const { value } = e.target;
    setChapterItems(prev =>
      prev.map(one => (one.id === index ? { ...one, text: value } : one))
    );
  };

  const addSummaryInput = () => {
    const item = {
      id: nextSummId.current,
      text: '',
    };
    setSummaryItems([...summaryItems, item]);
    nextSummId.current += 1;
  };

  const deleteSummaryInput = target => {
    setSummaryItems(summaryItems.filter(item => item.id !== target));
  };

  const handleSummaryChange = (e, index) => {
    if (index > summaryItems.length) return;
    const { value } = e.target;
    setSummaryItems(prev =>
      prev.map(one => (one.id === index ? { ...one, text: value } : one))
    );
  };

  const addOneRevstatus = () => {
    const chapterArr = chapterItems.map(chap => chap.text);
    const summaryArr = summaryItems.map(summ => summ.text);
    console.log({
      chapter: chapterArr,
      summary: summaryArr,
    });
    const revstatusCopy = [...revInfoInput.revstatus];
    revstatusCopy.push({
      chapter: chapterArr,
      summary: summaryArr,
    });
    setRevInfoInput(prev => ({
      ...prev,
      revstatus: revstatusCopy,
    }));
  };

  const selectRevStatus = index => {
    setInputType('edit');
    selectedIndex.current = index;
    const targetRS = revInfoInput.revstatus[index];
    const chapterItemArr = targetRS.chapter.map((ch, i) => ({
      id: i,
      text: ch,
    }));
    setChapterItems(chapterItemArr);
    nextChapId.current = chapterItemArr.length;
    const summaryItemArr = targetRS.summary.map((su, i) => ({
      id: i,
      text: su,
    }));
    setSummaryItems(summaryItemArr);
    nextSummId.current = summaryItemArr.length;
  };

  const editOneRevstatus = () => {
    const targetIndex = selectedIndex.current;
    const chapterArr = chapterItems.map(chap => chap.text);
    const summaryArr = summaryItems.map(summ => summ.text);
    const revstatusCopy = [...revInfoInput.revstatus];
    revstatusCopy.splice(targetIndex, 1, {
      chapter: chapterArr,
      summary: summaryArr,
    });
    setRevInfoInput(prev => ({
      ...prev,
      revstatus: revstatusCopy,
    }));
  };

  const openNewInput = () => {
    setInputType('new');
    setChapterItems([{ id: 0, text: '' }]);
    setSummaryItems([{ id: 0, text: '' }]);
    nextChapId.current = 1;
    nextSummId.current = 1;
  };

  const revEditSubmit = async () => {
    try {
      const response = await axiosAuth({
        method: 'POST',
        url: '/api/revstatus/dev/editrevstatus',
        data: {
          _id: targetId,
          classification: revInfoInput.classification,
          revNumber: revInfoInput.revNumber,
          dcnNumber: revInfoInput.dcnNumber,
          revisedAt: new Date(revInfoInput.revisedAt),
          revstatus: revInfoInput.revstatus,
        },
      });
      toast(`${response.status} ${t('revStatus.add-enactAmend-history')}`);
    } catch (error) {
      console.log(error);
      toast(t('revStatus.add-history-failed'));
    }
  };

  return (
    <Modal
      backdropClassName='double'
      dialogClassName='revInput__popup'
      contentClassName='revInput__popup__content'
      show={type === 'edit'}
      backdrop='static'
      keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <span>{t('revStatus.add-amend-history')}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th width='5%'>{t('dcn.rev-num')}</th>
              <th width='10%'>{t('dcn.table-number')}</th>
              <th width='15%'>{t('table.enact-date')}</th>
              <th width='30%'>{t('table.chap-num')}</th>
              <th width='40%'>{t('table.rev-sum')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                rowSpan={revInfoInput.revstatus.length}
                style={{ borderLeft: 'none' }}>
                <input
                  style={{ width: '100%' }}
                  type='text'
                  name='revNumber'
                  onChange={handlesetRevInfoInput}
                  value={revInfoInput.revNumber}
                />
              </td>
              <td rowSpan={revInfoInput.revstatus.length}>
                <input
                  style={{ width: '100%' }}
                  type='text'
                  name='dcnNumber'
                  onChange={handlesetRevInfoInput}
                  value={revInfoInput.dcnNumber}
                />
              </td>
              <td rowSpan={revInfoInput.revstatus.length}>
                <DatePicker
                  name='revisedAt'
                  startdate={revInfoInput.revisedAt}
                  onChangeDate={handlesetRevInfoInput}
                  language={i18n.language}
                  style={{ width: '100%' }}
                />
              </td>
              <td>
                <table>
                  {revInfoInput.revstatus.map(rev => (
                    <tr>
                      <td>
                        {rev.chapter.map(chap => (
                          <div key={chap}>{chap}</div>
                        ))}
                      </td>
                    </tr>
                  ))}
                </table>
              </td>
              <td>
                <table>
                  {revInfoInput.revstatus.map((rev, i) => (
                    <tr>
                      <td>
                        {rev.summary.map(summ => (
                          <div key={summ}>{summ}</div>
                        ))}
                      </td>
                      <td>
                        <BsPencilSquare onClick={() => selectRevStatus(i)} />
                        <BsFillXCircleFill onClick={() => deleteRevstatus(i)} />
                      </td>
                    </tr>
                  ))}
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan='5'>
                <BsFillPlusCircleFill onClick={openNewInput} />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td />
              <td />
              <td />
              <td style={{ verticalAlign: 'top' }}>
                {chapterItems.map(chap => (
                  <div key={`chap_${chap.id}`}>
                    <span style={{ width: '10%', padding: '0 5px' }}>-</span>
                    <input
                      style={{ width: '80%' }}
                      type='text'
                      onChange={e => handleChapterChange(e, chap.id)}
                      value={chap.text}
                    />
                    <span style={{ width: '10%', padding: '0 5px' }}>
                      {chap.id === 0 && (
                        <BsFillPlusCircleFill onClick={addChapterInput} />
                      )}
                      {chap.id > 0 && (
                        <BsFillXCircleFill
                          onClick={() => deleteChapterInput(chap.id)}
                        />
                      )}
                    </span>
                  </div>
                ))}
              </td>
              <td style={{ verticalAlign: 'top' }}>
                {summaryItems.map(summ => (
                  <div key={`sum_${summ.id}`}>
                    <span
                      style={{
                        width: '5%',
                        display: 'inline-block',
                        padding: '0 10px',
                      }}>
                      -
                    </span>
                    <input
                      style={{ width: '90%' }}
                      type='text'
                      onChange={e => handleSummaryChange(e, summ.id)}
                      value={summ.text}
                    />
                    <span
                      style={{
                        width: '5%',
                        display: 'inline-block',
                        padding: '0 10px',
                      }}>
                      {summ.id === 0 && (
                        <BsFillPlusCircleFill onClick={addSummaryInput} />
                      )}
                      {summ.id > 0 && (
                        <BsFillXCircleFill
                          onClick={() => deleteSummaryInput(summ.id)}
                        />
                      )}
                    </span>
                  </div>
                ))}
                <div>
                  {inputType === 'new' && (
                    <Button
                      style={{ float: 'right' }}
                      onClick={addOneRevstatus}>
                      {t('button.add-sum')}
                    </Button>
                  )}
                  {inputType === 'edit' && (
                    <Button
                      style={{ float: 'right' }}
                      onClick={editOneRevstatus}>
                      {t('button.edit-sum')}
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={revEditSubmit}>{t('button.edit-history')}</Button>
        <Button onClick={handleClose}>{t('button.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RevStatusInputEdit;
