import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { format } from 'date-fns';
import { Button, Modal } from 'react-bootstrap';
import {
  MdArrowDropDown,
  MdArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from 'react-icons/md';
import { BsCircle, BsXLg } from 'react-icons/bs';
import { HiOutlineOfficeBuilding } from 'react-icons/hi';
import '../../scss/ModRule/RuleApproval.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import SearchBar from '../SearchBar';
import useModRule from '../../common/hooks/useModRule';
import useAuth from '../../common/hooks/useAuth';
import { formatDate } from '../../common/utils/tzUtiles';

const OrgItem = ({ org, handleOrg, targetOrg }) => {
  const click = id => {
    handleOrg(id);
  };

  return (
    <div className='list__orgs__item'>
      <div
        className={`list__orgs__item__title ${
          targetOrg === org._id ? 'active' : ''
        }`}
        onClick={() => click(org._id)}
        aria-hidden='true'>
        <span>
          {(() => {
            if (!org.path) {
              return <HiOutlineOfficeBuilding />;
            }
            if (org.children.length !== 0) {
              return <MdArrowDropDown size={25} />;
            }
            return <MdArrowRight size={25} />;
          })()}
        </span>
        <span>{org.name}</span>
      </div>
      <div className='orgchild'>
        {org.children?.length > 0 &&
          org.children.map(child => (
            <OrgItem
              key={`org_${child._id}`}
              org={child}
              handleOrg={handleOrg}
              targetOrg={targetOrg}
            />
          ))}
      </div>
    </div>
  );
};

const RuleApproval = ({ user, payOpen, setPayOpen }) => {
  const { t } = useTranslation();
  const { modRule, setModRule } = useModRule();
  const { auth } = useAuth();
  const [orgs, setOrgs] = useState({});
  const [targetOrg, setTargetOrg] = useState(null);

  const [users, setUsers] = useState([]);
  const [favoritesList, setFavoritesList] = useState([]);

  const [validList, setValidList] = useState([]);
  const [targetExam, setTargetExam] = useState([]);
  const [targetAppr, setTargetAppr] = useState([]);
  const [targetRefer, setTargetRefer] = useState([]);

  const [filterUsers, setFilterUsers] = useState([]);
  const [targetUser, setTargetUser] = useState({
    _id: '',
    positionName: '',
    username: '',
    checked: false,
  });
  const [delUser, setDelUser] = useState({
    _id: '',
    positionName: '',
    username: '',
    checked: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const axiosAuth = useAxiosAuth();

  useEffect(() => {
    const controller = new AbortController();
    const userList = async () => {
      await axios
        .all([
          axiosAuth.get(`/api/org/orgtree/${user.company}`),
          axiosAuth.get('/api/modrule/users/all'),
        ])
        .then(
          axios.spread((resOrgs, resUsers) => {
            setOrgs(resOrgs.data);
            const userCopys = resUsers.data.map(el => ({
              ...el,
              fontlen: el.username.length,
            }));
            setUsers(userCopys);
            setFilterUsers(userCopys);
            setIsLoading(false);
          })
        )
        .catch(err => console.log(err));
    };
    userList();
    const { examines, approve, reference } = modRule;
    setValidList([...examines, ...approve, ...reference]);
    setTargetExam(examines);
    setTargetAppr(approve);
    setTargetRefer(reference);
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    // 즐겨찾기 결재선
    const UserApproveList = async () => {
      const writer = user.id;
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/approval/${writer}`,
        });
        const orgOptionsCopy = [];
        for (let i = 0; i < res.data.length; i += 1) {
          orgOptionsCopy.push({
            label: res.data[i] && res.data[i].listname,
            value: res.data[i],
          });
        }
        setFavoritesList(orgOptionsCopy);
      } catch (err) {
        console.log(err);
      }
    };
    UserApproveList();
  }, []);

  const handleFavorList = item => {
    setTargetExam(item ? item.value.examines : null);
    setTargetAppr(item ? item.value.approve : null);
    setTargetRefer(item ? item.value.reference : null);
  };

  const handleAddArrow = type => {
    if (!targetUser._id || targetUser._id === '') {
      toast.error(t('modrule.select-register-user'));
      return;
    }

    if (validList.find(el => el.employno === targetUser.employno)) {
      toast.error(t('modrule.already-register-user'));
      return;
    }

    if (type === 'exam') {
      if (!targetUser.roles.map(el => el.authority).includes('rulemanager')) {
        toast.error(t('modrule.no-review-permission-user'));
        return;
      }
      setTargetExam(prevArray => {
        if (!Array.isArray(prevArray)) return [targetUser];
        return [...prevArray, targetUser];
      });
      setValidList(prevArray => {
        if (!Array.isArray(prevArray)) return [targetUser];
        return [...prevArray, targetUser];
      });
    } else if (type === 'appr') {
      if (!targetUser.roles.map(el => el.authority).includes('approve')) {
        toast.error(t('modrule.no-approve-permission-user'));
        return;
      }
      setTargetAppr(prevArray => {
        if (!Array.isArray(prevArray)) return [targetUser];
        return [...prevArray, targetUser];
      });
      setValidList(prevArray => {
        if (!Array.isArray(prevArray)) return [targetUser];
        return [...prevArray, targetUser];
      });
    } else if (type === 'refer') {
      setTargetRefer(prevArray => {
        if (!Array.isArray(prevArray)) return [targetUser];
        return [...prevArray, targetUser];
      });
      setValidList(prevArray => {
        if (!Array.isArray(prevArray)) return [targetUser];
        return [...prevArray, targetUser];
      });
    }
  };

  const handleDelArrow = type => {
    if (delUser._id === '') {
      toast.error(t('modrule.select-exclude-user'));
      return;
    }
    if (type === 'exam') {
      setTargetExam(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
      setValidList(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
    } else if (type === 'appr') {
      setTargetAppr(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
      setValidList(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
    } else if (type === 'refer') {
      setTargetRefer(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
      setValidList(prev => [
        ...prev.filter(el => el.employno !== delUser.employno),
      ]);
    }
  };

  const handleOrg = id => {
    setTargetOrg(id);
    if (id === process.env.REACT_APP_HOSTCOMPANY) {
      setFilterUsers(users);
      return;
    }
    const usersCopy = users
      .filter(el => el.org?._id === id)
      .sort((a, b) => a.employno - b.employno);
    setFilterUsers(usersCopy);
  };

  const targetDel = userobj => {
    setDelUser(prev => ({
      ...prev,
      orgName: userobj.org?.name,
      positionName: userobj.position?.positionName,
      checked: false,
      ...userobj,
    }));
  };

  const handleTargetUser = userobj => {
    setTargetUser(prev => ({
      ...prev,
      employno: userobj.employno,
      orgName: userobj.org.name,
      positionName: userobj.position.positionName,
      checked: false,
      ...userobj,
    }));
  };

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  useEffect(() => {
    const reg = new RegExp(searchStr, 'gi');
    if (searchStr !== '') {
      const filteredUsers = users.filter(
        el =>
          el.username.match(reg) ||
          el.org.name.match(reg) ||
          el.position.positionName.match(reg)
      );
      setFilterUsers(filteredUsers);
    }
  }, [searchStr]);

  const saveApproval = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/approval/new',
        data: {
          writer: auth.id,
          listname: `${t('modrule.save-approval-line-date')}:${formatDate(
            new Date()
          )}`,
          examines: targetExam,
          approve: targetAppr,
          reference: targetRefer,
        },
      });

      if (res.status === 200) {
        toast.success(t('modrule.save-approval-line'));
      }
    } catch (error) {
      toast.error(t('modrule.save-approval-line-failed'));
    }
  };

  const submitApproval = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/approval/draft',
        data: {
          modRuleId: modRule._id,
          examines: targetExam,
          approve: targetAppr,
          reference: targetRefer,
        },
      });
      if (res.status === 200) {
        setModRule(prev => ({
          ...prev,
          examines: res.data?.examines,
          approve: res.data?.approve,
          reference: res.data?.reference,
        }));
        setPayOpen(prev => !prev);
        toast.success(t('modrule.submit-approval-line'));
      }
    } catch (error) {
      toast.error(t('modrule.submit-approval-line-failed'));
    }
  };

  return (
    <Modal
      dialogClassName='approvalmodal'
      centered
      show={payOpen}
      onHide={setPayOpen}
      backdrop='static'>
      <Modal.Header closeButton>
        <span style={{ fontSize: '1.4rem' }}>
          {t('modrule.set-approvalLine')}
        </span>
      </Modal.Header>
      <Modal.Body>
        <section className='approvalmodal__top'>
          <div className='leftBox'>
            <div className='payBox'>
              <span className='payBox__title'>{t('modrule.favorites')}</span>
              <span>
                {favoritesList && favoritesList.length !== 0 ? (
                  <Select
                    styles={blackStyles}
                    placeholder={
                      <div>{t('modrule.select-approval-line-list')}</div>
                    }
                    isSearchable
                    id='select-editrule'
                    className='payBox__select'
                    options={favoritesList}
                    onChange={handleFavorList}
                  />
                ) : (
                  <span className='payBox__subCon'>
                    {t('modrule.payment.no-favorites')}
                  </span>
                )}
              </span>
            </div>
            <div className='searchBox'>
              <SearchBar value={searchStr} onChange={handlesearchStr} />
            </div>
          </div>
          <div className='writerBox'>
            <div className='writerBox__thead'>
              <span>{t('modrule.payment.drafter')}</span>
            </div>
            <ul className='writerBox__tbody'>
              {user && (
                <>
                  <li>{user.position.positionName}</li>
                  <li>
                    {user.signatureImg !== '' ? (
                      <img
                        src={
                          user.signatureImg ||
                          `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                        }
                        className='signatureimg'
                        alt='signatureImg'
                      />
                    ) : (
                      <img
                        src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                        className='signatureimg'
                        alt='signatureImg'
                      />
                    )}
                  </li>
                  <li>{format(new Date(), 'yyyy-MM-dd')}</li>
                  <li>{user.username}</li>
                </>
              )}
            </ul>
          </div>
        </section>
        <section className='approvalmodal__bottom'>
          <div className='list'>
            <div className='list__orgs'>
              <div className='list__orgs__body'>
                {!isLoading && orgs._id && (
                  <OrgItem
                    org={orgs}
                    key={`org_${orgs._id}`}
                    handleOrg={handleOrg}
                    targetOrg={targetOrg}
                  />
                )}
              </div>
            </div>
            <div className='list__users'>
              <div className='list__users__body'>
                {!isLoading && (
                  <table>
                    <thead>
                      <tr>
                        <th width='25%'>{t('table.positionName')}</th>
                        <th width='50%'>{t('table.username')}</th>
                        <th width='12%'>{t('table.rulemanager')}</th>
                        <th width='13%'>{t('table.approve')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterUsers &&
                        filterUsers.map(userel => (
                          <tr
                            className={`list__users__item ${
                              targetUser.username === userel.username
                                ? 'active'
                                : ''
                            }`}
                            key={userel._id}
                            onClick={() => handleTargetUser(userel)}>
                            <td>{`${userel.position.positionName}`}</td>
                            <td
                              style={
                                userel.fontlen > 5
                                  ? {
                                      letterSpacing: '-1px',
                                      fontSize: '0.9rem',
                                    }
                                  : {}
                              }>{`${userel.username}`}</td>
                            <td className='list__users__item__rulemanager'>
                              {userel.roles
                                .map(el => el.authority)
                                .includes('rulemanager') ? (
                                <BsCircle color='green' />
                              ) : (
                                <BsXLg color='red' />
                              )}
                            </td>
                            <td className='list__users__item__approve'>
                              {userel.roles
                                .map(el => el.authority)
                                .includes('approve') ? (
                                <BsCircle color='green' />
                              ) : (
                                <BsXLg color='red' />
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className='approvalContainer'>
            <div className='examineBox'>
              <div className='arrowBox'>
                <div>
                  <MdOutlineKeyboardDoubleArrowRight
                    size={30}
                    onClick={() => handleAddArrow('exam')}
                  />
                </div>
                <div>
                  <MdOutlineKeyboardDoubleArrowLeft
                    size={30}
                    onClick={() => handleDelArrow('exam')}
                  />
                </div>
              </div>
              <div className='listContainer'>
                <span className='examinBox__title'>
                  {t('modrule.examiner-list')}
                </span>
                <div className='listBox'>
                  {targetExam &&
                    targetExam.map(ex => (
                      <Button
                        key={ex._id}
                        variant='secondary'
                        onClick={() => targetDel(ex)}>
                        <span>[{ex.orgName}]</span>
                        {'  '}
                        <span>{ex.username}</span>
                        <span>({ex.positionName})</span>
                      </Button>
                    ))}
                </div>
              </div>
            </div>
            <div className='approveBox'>
              <div className='arrowBox'>
                <div>
                  <MdOutlineKeyboardDoubleArrowRight
                    size={30}
                    onClick={() => handleAddArrow('appr')}
                  />
                </div>
                <div>
                  <MdOutlineKeyboardDoubleArrowLeft
                    size={30}
                    onClick={() => handleDelArrow('appr')}
                  />
                </div>
              </div>
              <div className='listContainer'>
                <span className='approveBox__title'>
                  {t('modrule.approve-list')}
                </span>
                <div className='listBox'>
                  {targetAppr &&
                    targetAppr.map(ap => (
                      <Button
                        key={ap._id}
                        variant='secondary'
                        onClick={() => targetDel(ap)}>
                        <span>[{ap.orgName}]</span>
                        {'  '}
                        <span>{ap.username}</span>
                        <span>({ap.positionName})</span>
                      </Button>
                    ))}
                </div>
              </div>
            </div>
            <div className='referenceBox'>
              <div className='arrowBox'>
                <div>
                  <MdOutlineKeyboardDoubleArrowRight
                    size={30}
                    onClick={() => handleAddArrow('refer')}
                  />
                </div>
                <div>
                  <MdOutlineKeyboardDoubleArrowLeft
                    size={30}
                    onClick={() => handleDelArrow('refer')}
                  />
                </div>
              </div>
              <div className='listContainer'>
                <span>{t('modrule.reference-list')}</span>
                <div className='listBox'>
                  {targetRefer &&
                    targetRefer.map(re => (
                      <Button
                        key={re._id}
                        variant='secondary'
                        onClick={() => targetDel(re)}>
                        {`${re.username} (${re.positionName})`}
                      </Button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='approvalmodal__aside'>
          <Button
            style={{ float: 'right' }}
            variant='primary'
            type='submit'
            onClick={() => saveApproval()}>
            {t('modrule.save-approvalLine-btn')}
          </Button>
        </section>
      </Modal.Body>
      <Modal.Footer className='approvalmodal__footer'>
        <Button
          variant='secondary'
          type='button'
          onClick={() => setPayOpen(false)}>
          {t('button.cancel')}
        </Button>
        <Button
          style={{ marginLeft: '1vw' }}
          variant='secondary'
          type='submit'
          onClick={() => submitApproval('record')}>
          {t('button.submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RuleApproval;
