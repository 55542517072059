import { useEffect } from 'react';
import { axiosMultipart } from '../axios/api';
import useAuth from './useAuth';
import useRefreshToken from './useRefreshToken';

const useAxiosMultipart = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  useEffect(() => {
    const requestIntercept = axiosMultipart.interceptors.request.use(
      config => {
        if (!config.headers.Authorization) {
          config.headers.Authorization = `Bearer ${auth?.accessToken}`;
        }
        return config;
      },
      error => Promise.reject(error)
    );

    const responseIntercept = axiosMultipart.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosMultipart(prevRequest);
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axiosMultipart.interceptors.request.eject(requestIntercept);
      axiosMultipart.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosMultipart;
};

export default useAxiosMultipart;
