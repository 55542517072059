import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';

const FormDualContent = ({ targetRule }) => {
  const [formDocs, setFormDocs] = useState([]);
  const [options, setOptions] = useState([]);
  const [activeDocument, setActiveDocument] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const parse = targetRule.formRef.map(el => ({
      ...el,
      fileType: el.docType,
    }));
    const optionsCopy = parse.map(el => ({
      label: `${el.formNumber} : ${el.formName}`,
      value: el,
    }));
    setFormDocs(parse);
    setActiveDocument(parse[0]);
    setOptions(optionsCopy);
    return () => {
      controller.abort();
    };
  }, [targetRule]);

  const handleDocumentChange = e => {
    setActiveDocument(e.value);
  };
  return (
    <div className='formdual__viewer'>
      <div className='formdual__viewer__select'>
        <label htmlFor='dd'>
          <span>{t('components.dual.chapter')} :</span>
          <Select
            className='formdual__viewer__chapselect'
            isSearchable
            value={
              activeDocument
                ? {
                    label: `${activeDocument.formNumber} : ${activeDocument.formName}`,
                    value: activeDocument,
                  }
                : null
            }
            placeholder={t('components.dual.chapter-placeholder')}
            options={options}
            onChange={handleDocumentChange}
            styles={blackStyles}
          />
        </label>
      </div>
      <div className='formdual__viewer__body'>
        <DocViewer
          prefetchMethod='GET'
          activeDocument={activeDocument}
          documents={formDocs}
          pluginRenderers={DocViewerRenderers}
          onDocumentChange={handleDocumentChange}
          config={{ header: { disableHeader: true } }}
          className='docviewer__container'
        />
      </div>
    </div>
  );
};

export default FormDualContent;
