import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaXmark } from 'react-icons/fa6';
import useAuth from '../../common/hooks/useAuth';
import useLogout from '../../common/hooks/useLogout';

const MainNavUserInfo = ({ openModal, setOpenModal }) => {
  const { t } = useTranslation();
  const logout = useLogout();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const goBack = () => {
    navigate('/');
  };

  const Logout = () => {
    logout();
    goBack();
    setOpenModal('');
  };

  return (
    <div className='profile-container'>
      <div className='profile-close-btn'>
        <FaXmark
          size={20}
          onClick={() => setOpenModal(false)}
          className='close-icon'
        />
      </div>
      <section className='profile-section'>
        <p className='profile-name'>{auth.accessToken && auth.email}</p>
      </section>

      <div className='divider' />

      <div className='button-group'>
        <button
          type='button'
          onClick={() => {
            navigate('/mypage');
            setOpenModal(false);
          }}
          className='btn mypage'>
          {t('mainnav.mypage')}
        </button>

        <button
          type='button'
          style={{ color: 'black' }}
          onClick={Logout}
          className='btn logout'>
          {t('mainnav.logout')}
        </button>
      </div>
    </div>
  );
};

export default MainNavUserInfo;
