import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import EnactRuleFileDocViewer from '../EnactRule/EnactRuleFileDocViewer';
import useAxiosMultipart from '../../../common/hooks/useAxiosMultipart';
import CustomFileInput from '../../DatePicker/FileInput';

const ModRuleChecklistEdit = ({ clist }) => {
  const [targetClist, setTargetClist] = useState({});
  const [chapterInputs, setChapterInputs] = useState({
    chapterNumber: '',
    chapterName: '',
  });
  const [file, setFile] = useState(null);
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const originNameRef = useRef(null);
  const fileInputRef = useRef(null);
  const { t } = useTranslation();

  const fetchClist = async id => {
    try {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/cir/list/${id}`,
      });
      setTargetClist(res.data);
      setFile(null);
      if (originNameRef.current) {
        originNameRef.current = '';
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      setChapterInputs({
        chapterNumber: '',
        chapterName: '',
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchClist(clist._id);
  }, [clist]);

  const handlePreviewUpload = async e => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/cir/file/preview',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setChapterInputs(prev => ({
        ...prev,
        uri: res.data.url,
        fileType: res.data.docType,
      }));
    } catch (err) {
      console.log(err);
    }
  };

  const submitEditName = async () => {
    if (!file && originNameRef.current === chapterInputs.chapterName) {
      toast(t('modrule.no-change'));
      return;
    }
    if (!file) {
      if (
        window.confirm(
          `${t('modrule.edit-checkList-name-q')} ${chapterInputs.chapterName}`
        )
      ) {
        try {
          const res = await axiosAuth({
            method: 'PATCH',
            url: `/api/checklist/fixname/${chapterInputs._id}`,
            data: { chapterName: chapterInputs.chapterName },
          });
          if (res.status === 200) {
            toast(t('modrule.edit-checkList-name'));
            fetchClist(clist._id);
          }
        } catch (err) {
          console.log(err);
        }
      }
      return;
    }

    if (
      window.confirm(
        `${t('modrule.edit-checkList-name-file-q')} ${
          chapterInputs.chapterName
        }\nFILE : ${file.name}`
      )
    ) {
      const formData = new FormData();
      formData.append('checkListPath', targetClist.ruleNumber);
      formData.append('chapterName', chapterInputs.chapterName);
      formData.append('file', file);
      try {
        const res = await axiosMultipart({
          method: 'PATCH',
          url: `/api/checklist/file/${chapterInputs._id}`,
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
        });
        if (res.status === 200) {
          toast(t('modrule.edit-checkList-name-file'));
          fetchClist(clist._id);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleTargetChapter = chapter => {
    setFile(null);
    originNameRef.current = chapter.chapterName;
    setChapterInputs({
      ...chapter,
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const deleteFile = async () => {
    setFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className='modclistedit'>
      <div className='modclistedit__side'>
        <h4 style={{ padding: '0 10px' }}>
          {targetClist.ruleNumber} {targetClist.ruleName}{' '}
          <span className='modclistedit__side__titletype'>
            {t('button.edit')}
          </span>
        </h4>
        <div style={{ padding: '0 10px', marginBottom: '1vh' }}>
          <Form.Group
            controlId='formFileMultiple'
            encType='multipart/form-data'
            className='mb-3 formContentTop__form'>
            <Form.Text className='text-muted'>
              {t('modrule.extension')} : doc / docx / xls / xlsx / pdf{' '}
              {file && (
                <span
                  style={{
                    color: 'orange',
                    fontSize: '1rem',
                    marginRight: '1vw',
                  }}>
                  {t('modrule.modifying')}
                </span>
              )}
              <Button
                onClick={deleteFile}
                style={{
                  padding: '3px 10px',
                }}>
                {t('modrule.del-file')}
              </Button>
            </Form.Text>
            <CustomFileInput
              onChange={handlePreviewUpload}
              type='file'
              name='file'
              disabled={!chapterInputs._id}
              ref={fileInputRef}
              multiple
              style={{ width: '100%', marginTop: '10px' }}
            />
          </Form.Group>
          <Form.Label>{t('modrule.checkList-num')}</Form.Label>
          <InputGroup className='mb-3'>
            <Form.Control
              disabled
              id='clist-chapternumber'
              type='text'
              name='chapterNumber'
              value={chapterInputs.chapterNumber}
              onChange={e =>
                setChapterInputs(prev => ({
                  ...prev,
                  chapterNumber: e.target.value,
                }))
              }
            />
          </InputGroup>
          <Form.Label>
            {t('modrule.checkList-name')}
            {originNameRef.current &&
              originNameRef.current !== chapterInputs.chapterName && (
                <span style={{ color: 'orange' }}>
                  {t('modrule.modifying')}
                </span>
              )}
          </Form.Label>
          <InputGroup className='mb-3'>
            <Form.Control
              id='clist-chaptername'
              type='text'
              name='chapterName'
              value={chapterInputs.chapterName}
              onChange={e =>
                setChapterInputs(prev => ({
                  ...prev,
                  chapterName: e.target.value,
                }))
              }
            />
          </InputGroup>

          <div style={{ textAlign: 'right' }}>
            <Button
              disabled={!chapterInputs._id}
              variant='primary'
              onClick={submitEditName}>
              {t('button.edit')}
            </Button>
          </div>
        </div>
        <h5>{t('modrule.registered-checkList')}</h5>
        <div className='modclistedit__side__currentclists'>
          {targetClist?.chapterRef?.length > 0 &&
            targetClist.chapterRef.map(chapter => (
              <div
                onClick={() => handleTargetChapter(chapter)}
                aria-hidden='true'
                className='modclistedit__side__currentclists__item'
                style={{
                  cursor: 'pointer',
                  color: chapter._id === chapterInputs._id ? 'orange' : '',
                }}
                key={chapter._id}>
                {chapter.chapterNumber} {chapter.chapterName}
              </div>
            ))}
        </div>
      </div>
      <div>
        <h5>{t('modrule.file-preview')}</h5>
        <div className='modclistedit__previewer'>
          {chapterInputs?.uri && chapterInputs.uri !== '' && (
            <EnactRuleFileDocViewer
              key={chapterInputs._id}
              propUri={chapterInputs.uri}
              propFileType={chapterInputs.fileType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModRuleChecklistEdit;
