import React, { useEffect, useState } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiUndo } from 'react-icons/bi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import parse from 'html-react-parser';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import RevStatusAmendCompare from './RevStatusAmendCompare';

const Tr = ({ one }) => {
  if (one.revstatus.length === 1) {
    return (
      <tr>
        <td style={{ borderLeft: 'none' }}>{one.revno}</td>
        <td>{one.remarks}</td>
        <td>{one.ruleNumber}</td>
        {one.revstatus.map(rev => (
          <>
            {parse(`<td>${rev.chapter}</td>`)}
            {parse(`<td >${rev.summary}</td>`)}
          </>
        ))}
        <td>{one.date}</td>
      </tr>
    );
  }

  const rownum = one.revstatus.length;
  return (
    <>
      <tr>
        <td rowSpan={rownum} style={{ borderLeft: 'none' }}>
          {one.revno}
        </td>
        <td rowSpan={rownum}>{one.remarks}</td>
        <td rowSpan={rownum}>{one.ruleNumber}</td>
        {parse(`<td>${one.revstatus[0].chapter}</td>`)}
        {parse(`<td>${one.revstatus[0].summary}</td>`)}
        <td rowSpan={rownum}>{one.date}</td>
      </tr>
      {one.revstatus.slice(1).map(rev => (
        <tr>
          {parse(`<td>${rev.chapter}</td>`)}
          {parse(`<td>${rev.summary}</td>`)}
        </tr>
      ))}
    </>
  );
};

const RevStatusAmend = () => {
  const { t } = useTranslation();
  const { remarks } = useParams();
  const { state } = useLocation();
  const axiosAuth = useAxiosAuth();
  const navi = useNavigate();
  const [modRecord, setModRecord] = useState([]);
  const [revRecord, setRevRecord] = useState({});
  const [oldChapterRef, setOldChapterRef] = useState([]);
  const [newChapterRef, setNewChapterRef] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  useEffect(() => {
    // dcn넘버로 modrule과 revstatus 1건 씩 가져오기
    setIsLoading(true);
    if (remarks) {
      const fetchWithRemarks = async () => {
        await axiosAuth
          .get(`/api/revstatus/bydcn/${remarks}`)
          .then(res => {
            if (res.status === 200) {
              const { ruleMod, revstatus } = res.data;
              setModRecord(ruleMod);
              const oneRev = revstatus.filter(
                el => el.ruleNumber === state.ruleNumber
              )[0];
              const pasingData = {
                id: oneRev._id,
                revno: oneRev.revno,
                date: timezoneFormat(
                  utcToZonedTime(
                    toDate(oneRev.date, {
                      timeZone: 'UTC',
                    }),
                    timezone
                  ),
                  'yyyy-MM-dd',
                  { timeZone: timezone }
                ),
                ruleNumber: oneRev.ruleNumber,
                revstatus: oneRev.revstatus.map(x => ({
                  chapter: x.chapter.map(sum => `<span>${sum}</span>`).join(''),
                  summary: x.summary.map(sum => `<span>${sum}</span>`).join(''),
                })),
                classification: oneRev.classification,
                remarks: oneRev.remarks,
                lan: oneRev.lan,
              };
              setRevRecord(pasingData);
              if (res.data.ruleMod.length !== 0 && res.data.ruleMod !== []) {
                setOldChapterRef(
                  res.data.ruleMod[1] ? res.data.ruleMod[1].chapterRef : []
                );
                setNewChapterRef(res.data.ruleMod[2].chapterRef);
              }
              setIsLoading(false);
            }
          })
          .catch(err => {
            console.log(err);
          });
      };
      fetchWithRemarks();
    } else {
      const fetchWithRevId = async () => {
        await axiosAuth
          .get(`/api/revstatus/byid/${state.revId}`)
          .then(res => {
            if (res.status === 200) {
              const pasingData = {
                id: res.data._id,
                revno: res.data.revno,
                date: timezoneFormat(
                  utcToZonedTime(
                    toDate(res.data.date, {
                      timeZone: 'UTC',
                    }),
                    timezone
                  ),
                  'yyyy-MM-dd',
                  { timeZone: timezone }
                ),
                ruleNumber: res.data.ruleNumber,
                revstatus: res.data.revstatus.map(x => ({
                  chapter: x.chapter.map(sum => `<span>${sum}</span>`).join(''),
                  summary: x.summary.map(sum => `<span>${sum}</span>`).join(''),
                })),
                classification: res.data.classification,
                remarks: res.data.remarks,
                lan: res.data.lan,
              };
              setRevRecord(pasingData);
            }
            setIsLoading(false);
          })
          .catch(err => {
            console.log(err);
          });
      };
      fetchWithRevId();
    }
  }, []);

  const goBack = () => {
    navi(-1);
  };
  return (
    <div className='revstatus__wrapper'>
      <div className='revstatus__subContent'>
        <div className='revstatus__subContent__left'>
          <BiUndo type='button' onClick={goBack} size={32} />
        </div>
      </div>
      {(() => {
        if (!isLoading) {
          if (modRecord.length !== 0 && modRecord !== []) {
            return (
              <div className='modRuleOne__mainBody'>
                <section className='modRuleOne__table'>
                  <Table className='text-center'>
                    <thead>
                      <tr>
                        <td>{t('table.dcn-num')}</td>
                        <td>{t('table.category')}</td>
                        <td>{t('table.rule-title')}</td>
                        <td>{t('table.draft-date')}</td>
                        <td>{t('table.part')}</td>
                        <td>{t('table.rev-date')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width='10%'>{modRecord[2].remarks || ''}</td>
                        <td width='25%'>
                          {modRecord[0]
                            ? `[${modRecord[0].categoryNumber}] ${modRecord[0].category}`
                            : t('revStatus.set-no-category')}
                        </td>
                        <td width='35%'>
                          {modRecord
                            ? `[${modRecord[2].refRuleId.ruleNumber}] ${modRecord[2].refRuleId.ruleName}`
                            : t('revStatus.set-no-ruleName')}
                        </td>
                        <td width='10%'>
                          {timezoneFormat(
                            utcToZonedTime(
                              toDate(modRecord[2].createdAt, {
                                timeZone: 'UTC',
                              }),
                              timezone
                            ),
                            'yyyy-MM-dd',
                            { timeZone: timezone }
                          )}
                        </td>
                        <td width='8%'>{t('revStatus.amend')}</td>
                        <td width='7%'>
                          {timezoneFormat(
                            utcToZonedTime(
                              toDate(modRecord[2].revisedAt, {
                                timeZone: 'UTC',
                              }),
                              timezone
                            ),
                            'yyyy-MM-dd',
                            { timeZone: timezone }
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </section>
                <section className='modRuleOne__line'>
                  <div className='modRuleOne__line__itemBox'>
                    {/* 결제자 설정 */}
                    <div className='drafterBox modRuleBox'>
                      <div className='drafterBox__thead modRuleBox__thead'>
                        <span>{t('revStatus.draft')}</span>
                      </div>
                      <div className='drafterBox__tbody modRuleBox__tbody'>
                        {modRecord && (
                          <ul>
                            <li>{modRecord[2].writer.position.positionName}</li>
                            <li>
                              {modRecord[2].writer.signatureImg !== '' ? (
                                <img
                                  src={
                                    modRecord[2].writer.signatureImg ||
                                    `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                                  }
                                  className='signatureimg'
                                  alt='signatureImg'
                                />
                              ) : (
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                                  className='dd'
                                  alt='signatureImg'
                                />
                              )}
                            </li>
                            <li>
                              {modRecord[2].createdAt
                                ? modRecord[2].createdAt.slice(0, 10)
                                : null}
                            </li>
                            <li>{modRecord[2].writer.username}</li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className='modRuleOne__line__itemBox__subSection'>
                      {modRecord &&
                        modRecord[2].examines.map(el => (
                          <div key={el._id} className='reviewerBox modRuleBox'>
                            <div className='reviewerBox__thead  modRuleBox__thead'>
                              <span>{t('revStatus.review')}</span>
                            </div>
                            <div className='reviewerBox__tbody  modRuleBox__tbody'>
                              <ul>
                                <li>{el.userId.position.positionName}</li>
                                <li style={{ color: el.checked && 'orange' }}>
                                  {el.userId.signatureImg ? (
                                    <img
                                      src={
                                        el.userId.signatureImg ||
                                        `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                                      }
                                      className='signatureimg'
                                      alt='signatureImg'
                                    />
                                  ) : (
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                                      className='signatureimg'
                                      alt='signatureImg'
                                    />
                                  )}
                                </li>
                                <li>
                                  {el.updatedAt
                                    ? el.updatedAt.slice(0, 10)
                                    : null}
                                </li>
                                <li>{el.userId.username}</li>
                              </ul>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className='modRuleOne__line__itemBox__subSection'>
                      {modRecord &&
                        modRecord[2].approve.map(el => (
                          <div key={el._id} className='paymentBox modRuleBox'>
                            <div className='paymentBox__thead  modRuleBox__thead'>
                              <span>{t('revStatus.approve')}</span>
                            </div>
                            <div className='paymentBox__tbody  modRuleBox__tbody'>
                              <ul>
                                <li>{el.userId.position.positionName}</li>
                                <li style={{ color: el.checked && 'orange' }}>
                                  {el.userId.signatureImg ? (
                                    <img
                                      src={
                                        el.userId.signatureImg ||
                                        `${process.env.PUBLIC_URL}/images/defaultsignature.png`
                                      }
                                      className='signatureimg'
                                      alt='signatureImg'
                                    />
                                  ) : (
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/defaultsignature.png`}
                                      className='signatureimg'
                                      alt='signatureImg'
                                    />
                                  )}
                                </li>
                                <li>
                                  {el.updatedAt && el.updatedAt.slice(0, 10)}
                                </li>
                                <li>{el.userId.username}</li>
                              </ul>
                            </div>
                          </div>
                        ))}
                    </div>
                    {/* 참조자 설정 */}
                    <div className='readerBox modRuleBox'>
                      <div className='readerBox__thead  modRuleBox__thead'>
                        <span>{t('revStatus.reference')}</span>
                      </div>
                      <div className='readerBox__tbody  modRuleBox__tbody'>
                        {modRecord &&
                          modRecord[2].reference.map(el => (
                            <ul key={el._id}>
                              <li>
                                {el && el.length === 0
                                  ? t('revStatus.no-reference')
                                  : `${el.userId.username}`}
                              </li>
                            </ul>
                          ))}
                      </div>
                    </div>
                  </div>
                </section>
                <section className='modRuleOne__content'>
                  <RevStatusAmendCompare
                    oldChapterRef={oldChapterRef}
                    newChapterRef={newChapterRef}
                  />
                </section>
                <section className='modRuleOne__comment'>
                  <div className='modRuleOne__comment__box'>
                    <div className='modRuleOne__comment__box__left'> </div>
                    <div className='modRuleOne__comment__box__right'>
                      <div className='commentTable'>
                        <div className='commentTable__thead'>
                          <span>
                            {t('revStatus.coment-history')}(
                            {modRecord && modRecord[2].comments.length})
                          </span>
                        </div>
                        <div className='commentTable__tbody'>
                          {modRecord &&
                            modRecord[2].comments.map(el => (
                              <div key={el._id}>
                                <span>{el.createdAt.slice(0, 10)}</span>
                                <span>{el.userId.username}</span>
                                <span>{el.comment}</span>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            );
          }
          return (
            <div className='revstatus__mainbody'>
              <Table className='text-center'>
                <thead>
                  <tr>
                    <th width='5%'>{t('dcn.rev-num')}</th>
                    <th width='10%'>{t('dcn.table-number')}</th>
                    <th width='15%'>{t('table.rule-num')}</th>
                    <th width='15%'>{t('table.chap-num')}</th>
                    <th width='40%'>{t('table.rev-sum')}</th>
                    <th width='15%'>{t('table.rev-date')}</th>
                  </tr>
                </thead>
                <tbody className='table__amend'>
                  {revRecord.id ? (
                    <Tr key={revRecord.id} one={revRecord} />
                  ) : (
                    <div>{t('revStatus.registering-data')}</div>
                  )}
                </tbody>
              </Table>
            </div>
          );
        }
        return <Spinner />;
      })()}
    </div>
  );
};

export default RevStatusAmend;
