import React, { useEffect, useState } from 'react';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { BsFillCaretUpFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import '../../scss/RevStatus/RevStatusInput.scss';
import RevStatusInputNew from './RevStatusInputNew';
import RevStatusInputEdit from './RevStatusInputEdit';

const Tr = ({ one, handleOpenEdit }) => {
  // const rownum = one.revstatus.length;
  const { t } = useTranslation();
  return (
    <tr>
      <td style={{ borderLeft: 'none' }}>{one.revNumber}</td>
      <td>{one.dcnNumber || ''}</td>
      <td>{one.refRule.ruleName}</td>
      <td style={{ padding: 0 }}>
        {one.revstatus.map((rev, i) => (
          <div key={`rev_${i + 1}`} style={{ display: 'flex' }}>
            <div
              style={{
                width: '30%',
                textAlign: 'left',
                padding: '0.5rem 0.5rem',
              }}>
              {rev.chapter.map(chap => (
                <span
                  style={{
                    marginLeft: '2px',
                  }}
                  key={chap}>
                  {chap}
                </span>
              ))}
            </div>
            <div style={{ width: '70%', padding: '0.5rem 0.5rem' }}>
              {rev.summary.map(summ => (
                <div style={{ textAlign: 'left' }} key={summ}>
                  {summ}
                </div>
              ))}
            </div>
          </div>
        ))}
      </td>
      <td>{one.revisedAt}</td>
      <td width='5%'>
        <Button onClick={() => handleOpenEdit(one._id)}>
          {t('button.edit')}
        </Button>
      </td>
    </tr>
  );
};

const CollapseItem = ({
  cate,
  openCategory,
  setOpenCategory,
  targetRule,
  setTargetRule,
  setRevStatus,
}) => {
  const { t } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const fetchRule = async rule => {
    setTargetRule(rule);
    try {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/revstatus/${rule._id}`,
      });
      const parsing = response.data.map(el => ({
        ...el,
        revisedAt: timezoneFormat(
          utcToZonedTime(
            toDate(el.revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        ),
      }));
      setRevStatus(parsing);
    } catch (error) {
      console.log(error);
      toast(t('revStatus.add-history-failed'));
    }
  };

  return (
    <div className='togglecollapse__item'>
      <div className='togglecollapse__header'>
        <span className='togglecollapse__title' title={cate && cate.category}>
          {cate && `[${cate.categoryNumber}] : ${cate.category}`}
        </span>
        <span>
          <BsFillCaretUpFill
            className={`togglecollapse__toggle ${
              cate.categoryNumber === openCategory ? 'open' : ''
            }`}
            onClick={() => setOpenCategory(cate.categoryNumber)}
          />
        </span>
      </div>
      <div
        className={`togglecollapse__content ${
          cate.categoryNumber === openCategory ? 'open' : ''
        }`}>
        {cate &&
          cate.ruleRef.map(rule => (
            <div
              className={`togglecollapse__content__children ${
                rule.ruleNumber === targetRule.ruleNumber ? 'on' : ''
              }`}
              title={rule.ruleName}
              onClick={() => fetchRule(rule)}
              aria-hidden='true'>
              {`[${rule.ruleNumber}] : ${rule.ruleName}`}
            </div>
          ))}
      </div>
    </div>
  );
};

const RevStatusInput = () => {
  const axiosAuth = useAxiosAuth();

  const [lan, setLan] = useState('kor');
  const [categories, setCategories] = useState([]);
  const [openCategory, setOpenCategory] = useState('');
  const [targetRule, setTargetRule] = useState({});
  const [revStatus, setRevStatus] = useState([]);
  const [targetRev, setTargetRev] = useState({ type: '' });
  const [targetId, setTargetId] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await axiosAuth.get(`/api/category/manage/${lan}`);
      setCategories(response.data);
    })();
  }, [lan]);

  const tabSlide = newPosition => {
    const toTranslate = 100 * newPosition;
    const elem = document.querySelector('span.toggle__menu__tab__revglide');
    elem.style.transform = `translateX(${toTranslate}%)`;
  };

  const handlelan = index => {
    const types = ['kor', 'eng'];
    const type = types[index];
    setLan(type);
    tabSlide(index);
  };

  const handleOpenEdit = id => {
    setTargetId(id);
    setTargetRev({ ...targetRev, type: 'edit', refRule: targetRule });
  };

  const handleOpenNew = () => {
    setTargetRev({ ...targetRev, type: 'new', refRule: targetRule });
  };

  return (
    <div className='revInput'>
      <div className='revInput__sidelist'>
        <div className='revInput__sidelist__header'>
          <div className='toggle__menu__tabs'>
            <span
              className={`toggle__menu__tab ${lan === 'kor' ? ' open' : ''}`}
              role='button'
              aria-hidden='true'
              onClick={() => handlelan(0)}>
              KOREAN
            </span>
            <span
              className={`toggle__menu__tab ${lan === 'eng' ? ' open' : ''}`}
              role='button'
              aria-hidden='true'
              onClick={() => handlelan(1)}>
              ENGLISH
            </span>
            <span className='toggle__menu__tab__revglide' />
          </div>
          <div className='revInput__sidelist__body'>
            <div>
              {categories &&
                categories.map(cate => (
                  <CollapseItem
                    key={cate._id}
                    cate={cate}
                    targetRule={targetRule}
                    setTargetRule={setTargetRule}
                    openCategory={openCategory}
                    setOpenCategory={setOpenCategory}
                    setRevStatus={setRevStatus}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className='revInput__managesection'>
        <div className='revInput__managesection__body'>
          <Table>
            <thead>
              <tr>
                <th width='5%'>{t('dcn.rev-num')}</th>
                <th width='10%'>{t('dcn.table-number')}</th>
                <th width='15%'>{t('table.rule-num')}</th>
                <th width='50%'>
                  <div style={{ display: 'flex' }}>
                    <div style={{ width: '30%', textAlign: 'center' }}>
                      {t('table.chap-num')}
                    </div>
                    <div style={{ width: '70%', textAlign: 'center' }}>
                      {t('table.rev-sum')}
                    </div>
                  </div>
                </th>
                <th width='15%'>{t('table.enact-date')}</th>
                <th width='5%'>+</th>
              </tr>
            </thead>
            <tbody>
              {revStatus &&
                revStatus.map(rev => (
                  <Tr
                    key={rev._id}
                    one={rev}
                    setTargetRev={setTargetRev}
                    handleOpenEdit={handleOpenEdit}
                  />
                ))}
            </tbody>
          </Table>
        </div>
        <div className='revInput__managesection__bottom'>
          <Button onClick={handleOpenNew}>{t('revStatus.register-new')}</Button>
        </div>
        {targetRev.type === 'new' && (
          <RevStatusInputNew
            targetRev={targetRev}
            setTargetRev={setTargetRev}
          />
        )}
        {targetRev.type === 'edit' && (
          <RevStatusInputEdit
            targetRev={targetRev}
            setTargetRev={setTargetRev}
            targetId={targetId}
          />
        )}
      </div>
    </div>
  );
};

export default RevStatusInput;
