import { React, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import '../../scss/RuleInfo/RuleInfo.scss';

const RuleInfoMain = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='ruleinfo mainsection'>
      <Outlet />
    </div>
  );
};

export default RuleInfoMain;
