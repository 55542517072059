import React, { createContext, useState, useMemo } from 'react';

const ModalOpenContext = createContext();

export const ModalOpenProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(() => {
    return {
      leftside: false,
      rightside: false,
    };
  });
  const value = useMemo(() => ({ modalOpen, setModalOpen }), [modalOpen]);

  return (
    <ModalOpenContext.Provider value={value}>
      {children}
    </ModalOpenContext.Provider>
  );
};

export default ModalOpenContext;
