import React, { useEffect, useState } from 'react';
import '../../scss/TeamManual/TeamManualUpdate.scss';
import { useTranslation } from 'react-i18next';
import { BiUndo } from 'react-icons/bi';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../common/hooks/useAuth';
import { axiosAuth } from '../../common/axios/api';

const TeamManualEdit = () => {
  const { i18n, t } = useTranslation();
  const navi = useNavigate();
  const { auth } = useAuth();
  const { manualNumber } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentManual, setCurrentManual] = useState({
    manualNum: '',
    manualName: '',
    manualSum: '',
    manualNote: '',
  });
  const { manualNum, manualName, manualSum, manualNote } = currentManual;

  useEffect(() => {
    const controller = new AbortController();
    const fetchManual = async () => {
      try {
        const res = await axiosAuth.get(`/api/manual/${manualNumber}`);
        setCurrentManual({
          manualNum: res.data.manual.manualNumber,
          manualName: res.data.manual.manualName,
          manualSum: res.data.manual.summary,
          manualNote: res.data.manual.note,
        });
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchManual();
    return () => {
      controller.abort();
    };
  }, [manualNumber]);

  const goBack = () => {
    navi(-1);
  };
  const handleDel = () => {
    if (i18n.language === 'ko') {
      if (window.confirm('작성을 취소하시겠습니까?')) {
        navi(-1);
      }
    } else if (i18n.language === 'en') {
      if (window.confirm('Are you sure you want to cancel creation?')) {
        navi(-1);
      }
    }
  };

  const changeTargetInputs = e => {
    const { value, name } = e.target;
    setCurrentManual({
      ...currentManual,
      [name]: value,
    });
  };

  const handleUpload = async () => {
    if (window.confirm(t('components.teamManual.manual-edit-q'))) {
      setLoading(true);
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/manual/edit',
          data: {
            writer: auth.id,
            manualNumber: manualNum,
            manualName,
            summary: manualSum,
            note: manualNote,
          },
        });
        if (res.status === 200) {
          setLoading(false);
          toast(t('components.teamManual.manual-edit'));
          navi(-1);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className='manualUpdate'>
      <div className='manualUpdate__header'>
        <Button variant='secondary'>
          <BiUndo type='button' onClick={goBack} />
        </Button>
        {loading && (
          <div className='manualUpdate__header__spinner'>
            <Spinner
              as='span'
              animation='border'
              // animation='grow'
              size='sm'
              role='status'
              aria-hidden='true'
            />
            <span className='spinnerText'>{t('manual.registering')}</span>
          </div>
        )}
      </div>
      <section className='manualUpdate__main'>
        <form action='upload' encType='multipart/form-data'>
          <div className='manualUpdate__main__inputBox'>
            <div className='manualUpdate__main__inputBox__writer'>
              <dl>
                <dt>{t('table.writer')} : </dt>
                <dd>
                  <input
                    type='text'
                    defaultValue={auth.username}
                    className='form-control'
                    disabled
                  />
                </dd>
              </dl>
            </div>
            <div className='manualUpdate__main__inputBox__bottomContents'>
              <dl>
                <dt>{t('manual.manual-num')} :</dt>
                <dd>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={t(
                      'components.teamManual.manual-num-placeholder'
                    )}
                    defaultValue={manualNum}
                    // value={manualNum}
                    disabled
                    name='manualNum'
                    onChange={changeTargetInputs}
                  />
                </dd>
              </dl>
              <dl>
                <dt>{t('manual.manual-title')} :</dt>
                <dd>
                  <input
                    type='text'
                    placeholder={t(
                      'components.teamManual.manual-title-placeholder'
                    )}
                    className='form-control'
                    defaultValue={manualName}
                    // value={manualName}
                    // disabled
                    name='manualName'
                    onChange={changeTargetInputs}
                  />
                </dd>
              </dl>
            </div>
          </div>
          <div className='manualUpdate__main__additional'>
            <dl>
              <dt>{t('manual.revision-details')} : </dt>
              <dd>
                <textarea
                  type='text'
                  // className=''
                  placeholder={t('table.placeholder-input')}
                  defaultValue={manualSum}
                  name='manualSum'
                  onChange={changeTargetInputs}
                />
              </dd>
            </dl>
            <dl>
              <dt>{t('manual.cate-manualNote')} : </dt>
              <dd>
                <textarea
                  type='text'
                  placeholder={t('table.placeholder-input')}
                  defaultValue={manualNote}
                  name='manualNote'
                  onChange={changeTargetInputs}
                />
              </dd>
            </dl>
          </div>
        </form>
      </section>
      <section className='manualUpdate__bottom'>
        <Button className='manualUpdate__bottom__btn' onClick={handleDel}>
          {t('button.cancel')}
        </Button>
        <Button
          className='manualUpdate__bottom__btn'
          type='submit'
          onClick={handleUpload}>
          {t('button.apply')}
        </Button>
      </section>
    </div>
  );
};

export default TeamManualEdit;
