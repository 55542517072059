import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillCaretUpFill } from 'react-icons/bs';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { Button, Table } from 'react-bootstrap';
import '../scss/RuleChart.scss';
import useAxiosAuth from '../common/hooks/useAxiosAuth';

const RuleChart = () => {
  const axiosAuth = useAxiosAuth();
  const { i18n, t } = useTranslation();
  const [korNav, setKorNav] = useState([]);
  const [engNav, setEngNav] = useState([]);
  const [korRuleList, setKorRuleList] = useState([]);
  const [engRuleList, setEngRuleList] = useState([]);
  const [openKorIndex, setOpenKorIndex] = useState(null);
  const [openEngIndex, setOpenEngIndex] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const modRules = async () => {
      try {
        const res = await axiosAuth.get('/api/chart/all');
        const korNavCopy = res.data.filter(
          el => el.lan === 'kor' || el.lan === 'all'
        );
        const engNavCopy = res.data.filter(
          el => el.lan === 'eng' || el.lan === 'all'
        );
        setKorNav(korNavCopy);
        setEngNav(engNavCopy);
      } catch (err) {
        console.log(err);
      }
    };
    modRules();
    return () => {
      controller.abort();
    };
  }, []);

  const toggleKorRule = async (cateNum, i) => {
    if (openKorIndex === i) {
      setOpenKorIndex(null);
    } else {
      try {
        const res = await axiosAuth.get(`/api/chart/category/${cateNum}`);
        setKorRuleList({
          _id: res.data._id,
          lan: res.data.lan,
          categoryNumber: res.data.categoryNumber,
          category: res.data.category,
          ruleRef: res.data.ruleRef,
        });
        setOpenKorIndex(i);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const toggleEngRule = async (cateNum, i) => {
    if (openEngIndex === i) {
      setOpenEngIndex(null);
    } else {
      try {
        const res = await axiosAuth.get(`/api/chart/category/${cateNum}`);
        setEngRuleList({
          _id: res.data._id,
          lan: res.data.lan,
          categoryNumber: res.data.categoryNumber,
          category: res.data.category,
          ruleRef: res.data.ruleRef,
        });
        setOpenEngIndex(i);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const makeUpdateTime = () => {
    const now = new Date();
    if (i18n.language === 'ko') {
      return `${now.getFullYear()}년 ${
        now.getMonth() + 1
      }월 ${now.getDate()}일 ${now.getHours()}시 00분`;
    }
    return `${now.getFullYear()}.${
      now.getMonth() + 1
    }.${now.getDate()} ${now.getHours()}:00`;
  };

  return (
    <div className='ruleChart mainsection'>
      <div className='ruleChart__header'>
        <h4>{t('rulechart.title')}</h4>
        <div>
          <span>
            {t('rulechart.currentUpdate')}
            {`: ${makeUpdateTime()}`}
          </span>
        </div>
      </div>
      <div className='ruleChart__lanBtn'>
        <span>{t('rulechart.korean')}</span>
        <span>{t('rulechart.english')}</span>
      </div>
      <div className='ruleChart__body'>
        <div className='ruleChart__body__left'>
          <Table>
            <thead>
              <tr>
                <td>{t('rulechart.category')}</td>
                <td>{t('rulechart.rule')}</td>
                <td>{t('rulechart.chapter')}</td>
                <td>{t('rulechart.form')}</td>
                <td>{t('rulechart.print')}</td>
                <td>{t('rulechart.down')}</td>
                <td>{t('rulechart.views')}</td>
              </tr>
            </thead>
            <tbody>
              {korNav &&
                korNav.map((el, i) => (
                  <>
                    <tr key={el._id}>
                      <td>
                        <span>
                          [{el.categoryNumber}] {el.category}
                        </span>
                        <Button
                          variant='secondary'
                          className='ruleBtn'
                          onClick={() => toggleKorRule(el.categoryNumber, i)}>
                          <BsFillCaretUpFill
                            className={`icon ${
                              el.categoryNumber ===
                                korRuleList.categoryNumber && openKorIndex === i
                                ? 'open'
                                : 'hide'
                            }`}
                            style={{
                              color:
                                el.categoryNumber ===
                                  korRuleList.categoryNumber &&
                                openKorIndex === i
                                  ? 'rgb(241, 133, 70)'
                                  : null,
                            }}
                          />
                        </Button>
                      </td>
                      <td
                        style={{
                          color: el.ruleNum === 0 ? 'red' : 'black',
                        }}>
                        {el.ruleNum}
                      </td>
                      <td>{el.chapNum}</td>
                      <td>{el.formNum}</td>
                      <td>{el.printNum}</td>
                      <td>{el.downNum}</td>
                      <td>{el.viewNum}</td>
                    </tr>
                    {el.categoryNumber === korRuleList.categoryNumber &&
                      openKorIndex === i &&
                      (korRuleList &&
                      korRuleList.ruleRef &&
                      korRuleList.ruleRef.length !== 0 ? (
                        korRuleList.ruleRef.map(rule => (
                          <tr key={rule._id} className='ruleBox'>
                            <td>
                              <MdSubdirectoryArrowRight /> [{rule.ruleNumber}]{' '}
                              {rule.ruleName}
                            </td>
                            <td colSpan={1}> </td>
                            {rule.chapterRef.length !== 0 ? (
                              <td colSpan={1}>{rule.chapterRef.length}</td>
                            ) : (
                              <td colSpan={1}> </td>
                            )}
                            {rule.formRef.length !== 0 ? (
                              <td colSpan={1}>{rule.formRef.length}</td>
                            ) : (
                              <td colSpan={1}> </td>
                            )}
                            <td colSpan={3}> </td>
                          </tr>
                        ))
                      ) : (
                        <tr className='ruleBox'>
                          <td>{t('rulechart.no-rule')}</td>
                          <td colSpan={6}> </td>
                        </tr>
                      ))}
                  </>
                ))}
            </tbody>
          </Table>
        </div>
        <div className='ruleChart__body__right'>
          <Table>
            <thead>
              <tr>
                <td>{t('rulechart.category')}</td>
                <td>{t('rulechart.rule')}</td>
                <td>{t('rulechart.chapter')}</td>
                <td>{t('rulechart.form')}</td>
                <td>{t('rulechart.print')}</td>
                <td>{t('rulechart.down')}</td>
                <td>{t('rulechart.views')}</td>
              </tr>
            </thead>
            <tbody>
              {engNav &&
                engNav.map((el, i) => (
                  <>
                    <tr key={el._id}>
                      <td>
                        <span>
                          [{el.categoryNumber}] {el.category}
                        </span>
                        <Button
                          variant='secondary'
                          className='ruleBtn'
                          onClick={() => toggleEngRule(el.categoryNumber, i)}>
                          <BsFillCaretUpFill
                            className={`icon ${
                              el.categoryNumber ===
                                engRuleList.categoryNumber && openEngIndex === i
                                ? 'open'
                                : 'hide'
                            }`}
                            style={{
                              color:
                                el.categoryNumber ===
                                  engRuleList.categoryNumber &&
                                openEngIndex === i
                                  ? 'rgb(241, 133, 70)'
                                  : null,
                            }}
                          />
                        </Button>
                      </td>
                      <td
                        style={{
                          color: el.ruleNum === 0 ? 'red' : 'black',
                        }}>
                        {el.ruleNum}
                      </td>
                      <td>{el.chapNum}</td>
                      <td>{el.formNum}</td>
                      <td>{el.printNum}</td>
                      <td>{el.downNum}</td>
                      <td>{el.viewNum}</td>
                    </tr>
                    {el.categoryNumber === engRuleList.categoryNumber &&
                      openEngIndex === i &&
                      (engRuleList &&
                      engRuleList.ruleRef &&
                      engRuleList.ruleRef.length !== 0 ? (
                        engRuleList.ruleRef.map(rule => (
                          <tr key={rule._id} className='ruleBox'>
                            <td>
                              <MdSubdirectoryArrowRight /> [{rule.ruleNumber}]{' '}
                              {rule.ruleName}
                            </td>
                            <td colSpan={1}> </td>
                            {rule.chapterRef.length !== 0 ? (
                              <td colSpan={1}>{rule.chapterRef.length}</td>
                            ) : (
                              <td colSpan={1}> </td>
                            )}
                            {rule.formRef.length !== 0 ? (
                              <td colSpan={1}>{rule.formRef.length}</td>
                            ) : (
                              <td colSpan={1}> </td>
                            )}
                            <td colSpan={3}> </td>
                          </tr>
                        ))
                      ) : (
                        <tr className='ruleBox'>
                          <td>{t('rulechart.no-rule')}</td>
                          <td colSpan={6}> </td>
                        </tr>
                      ))}
                  </>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default RuleChart;
