import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BsCaretRightFill } from 'react-icons/bs';
import { AiFillStepBackward } from 'react-icons/ai';
import {
  Button,
  Spinner,
  Form,
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
} from 'react-bootstrap';
import { MdOutlineAddBox } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import useAuth from '../../../common/hooks/useAuth';
import useModRule from '../../../common/hooks/useModRule';
import useModChapter from '../../../common/hooks/useModChapter';
import '../../../scss/ModRule/EnactRuleSidelist.scss';

const CollapseItem = ({ cate, setModCreateInputs }) => {
  const { t } = useTranslation();

  const haddleModCreateInputs = category => {
    setModCreateInputs(prev => ({
      ...prev,
      open: true,
      id: category._id,
      category: category.categoryNumber,
      ruleNumber: category.categoryNumber,
      ruleName: '',
      lan: category.lan,
    }));
  };

  return (
    <div className='itemBox'>
      <div className='itemBox__header'>
        <div
          aria-hidden='true'
          style={{ overflowX: 'hidden', maxWidth: '20vw' }}
          className='itemBox__header__toggleBox'>
          <span>
            <BsCaretRightFill className='itemBox__toggle' />
          </span>
          <span
            aria-hidden='true'
            className='itemBox__title'
            style={{ overflow: 'hidden', maxWidth: '20vw' }}
            title={cate && cate.category}>
            {cate && `[${cate.categoryNumber}] : ${cate.category}`}
          </span>
        </div>
        <div>
          <Button
            variant='secondary'
            className='toggle-btn'
            title={t('modrule.create-code')}
            onClick={() => haddleModCreateInputs(cate)}>
            <MdOutlineAddBox fill='#fff' size={24} />
          </Button>
        </div>
      </div>
    </div>
  );
};

const EnactRuleSidelist = () => {
  const { t } = useTranslation();

  const viewTypes = [
    { name: t('modrule.text'), value: 'contents', id: 1 },
    { name: t('modrule.file'), value: 'files', id: 2 },
    { name: t('modrule.mix'), value: 'mix', id: 3 },
  ];

  const { pathname, state } = useLocation();
  const axiosAuth = useAxiosAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [modCreateInputs, setModCreateInputs] = useState({
    open: false,
    category: '',
    viewType: '',
    ruleNumber: '',
    ruleName: '',
    lan: '',
    writer: '',
    id: '',
  });
  const [lan, setLan] = useState('kor');
  const { auth } = useAuth();
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();

  useEffect(() => {
    const controller = new AbortController();
    const fetchCategories = async () => {
      setIsLoading(true);
      try {
        const res = await axiosAuth.get(`/api/approval/category/all/${lan}`);
        setCategories(res.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
    return () => {
      controller.abort();
    };
  }, [pathname, lan]);

  const tabSlide = newPosition => {
    const toTranslate = 100 * newPosition;
    const elem = document.querySelector('span.draftpage__catelist__tab__glide');
    elem.style.transform = `translateX(${toTranslate}%)`;
  };

  const handlelan = index => {
    const types = ['kor', 'eng'];
    const type = types[index];
    setLan(type);
    tabSlide(index);
  };

  const handleClose = () => {
    setModCreateInputs({
      open: false,
      category: '',
      ruleNumber: '',
      ruleName: '',
      lan: '',
      writer: '',
      id: '',
    });
  };

  const handleModCreateInputs = e => {
    const { value, name } = e.target;
    setModCreateInputs({
      ...modCreateInputs,
      [name]: value,
    });
  };

  const handleViewType = value => {
    setModCreateInputs({
      ...modCreateInputs,
      viewType: value,
    });
  };

  const submitModRuleCreate = async () => {
    if (modCreateInputs.category === modCreateInputs.ruleNumber) {
      toast(t('modrule.same-rule-category'));
      return;
    }
    if (modCreateInputs.ruleName === '') {
      toast(t('modrule.rule-name-placeholder'));
      return;
    }
    if (modCreateInputs.viewType === '') {
      toast(t('modrule.rule-type-placeholder'));
      return;
    }
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/approval/enact/init',
        data: {
          writer: auth.id,
          number: state.number,
          type: 'dcn',
          lan: modCreateInputs.lan,
          viewType: modCreateInputs.viewType,
          refCategoryId: modCreateInputs.id,
          ruleNumber: modCreateInputs.ruleNumber,
          ruleName: modCreateInputs.ruleName,
        },
      });
      setModRule(res.data);
      const ruleNumberRef = res.data.ruleNumber;
      setModChapter({ ...modChapter, chapterNumber: `${ruleNumberRef}-` });
    } catch (error) {
      console.log(error);
    }
  };
  const handleReset = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modrule/delete',
        data: {
          modruleId: modRule._id,
        },
      });
      if (res.status === 200) {
        setModRule({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditModChapter = ({ _id }) => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
    const targetModChapter = modRule.modChapterRef.find(
      modChap => modChap._id === _id
    );
    setModChapter(prev => ({ ...prev, ...targetModChapter }));
  };

  const viewTypeParser = type => {
    if (type === 'contents') {
      return t('modrule.text');
    }
    if (type === 'files') {
      return t('modrule.file');
    }
    return t('modrule.mix');
  };

  const handleChapterNew = () => {
    setModRule(prev => ({ ...prev, sortOpen: false }));
    const chapterIndex = modRule.modChapterRef.filter(
      el => el.chapterType === 'CH'
    ).length;

    setModChapter({
      fileType: 'content', // type : content / file
      modType: 'new',
      chapterType: 'CH',
      content: '',
      uri: '',
      chapterIdx: chapterIndex,
      chapterNumber: `${modRule.ruleNumber}-CH${chapterIndex + 1}`,
      chapterName: '',
      chapterContents: {
        header: '',
        children: [],
      },
    });
  };

  if (modRule?._id) {
    return (
      <div className='draftpage__catelist'>
        <div className='draftpage__catelist__header'>
          <div className='draftpage__catelist__tabs'>
            <span
              className='draftpage__catelist__tab'
              role='button'
              aria-hidden='true'
              onClick={() => handleReset()}>
              <AiFillStepBackward size={20} />
            </span>
            <span
              className='draftpage__catelist__tab open'
              role='button'
              aria-hidden='true'>
              {`${t('modrule.lan')} : ${modRule.lan.toUpperCase()}`}
            </span>
            <span className='draftpage__catelist__tab__glideright' />
          </div>
        </div>
        <div className='draftpage__catelist__collase'>
          <div className='itemBox'>
            <div className='itemBox__draftinfo'>
              <span
                style={{
                  alignSelf: 'center',
                }}>
                {t('modrule.draftInfo')}
              </span>
              <span
                style={{
                  alignSelf: 'center',
                }}>
                {' '}
              </span>
              <Button
                size='sm'
                style={{
                  color: 'white',
                  backgroundColor: 'orange',
                  fontSize: '13px',
                  // padding: '1px 20px',
                }}>
                {t('button.edit')}
              </Button>
            </div>
            <div className='itemBox__content open'>
              <div className='itemBox__content__sub'>
                <div>
                  <BsCaretRightFill />
                  <span style={{ margin: '0 10px', minWidth: '2vw' }}>
                    {t('modrule.category')} :{' '}
                  </span>
                  <span>{`[${modRule.refCategory.categoryNumber}] : ${modRule.refCategory.category}`}</span>
                </div>
                <div>
                  <BsCaretRightFill />
                  <span style={{ margin: '0 10px', minWidth: '2vw' }}>
                    {t('modrule.rule-num')} :{' '}
                  </span>
                  <span>{`${modRule.ruleNumber}`}</span>
                </div>
                <div>
                  <BsCaretRightFill />
                  <span style={{ margin: '0 10px', minWidth: '2vw' }}>
                    {t('modrule.rule-title')} :{' '}
                  </span>
                  <span>{`${modRule.ruleName}`}</span>
                </div>
                <div>
                  <BsCaretRightFill />
                  <span style={{ margin: '0 10px', minWidth: '2vw' }}>
                    {t('modrule.rule-type')} :{' '}
                  </span>
                  <span>{viewTypeParser(modRule.viewType)}</span>
                </div>
              </div>
            </div>
            <div className='itemBox__fileList'>
              <div className='itemBox__fileList__title'>
                <span>{t('modrule.registered')}</span>{' '}
                <Button
                  size='sm'
                  style={{
                    color: 'white',
                    backgroundColor: 'orange',
                  }}
                  onClick={handleChapterNew}>
                  {t('modrule.newChap')}
                </Button>
              </div>
              {modRule.modChapterRef
                .filter(mchap => mchap.chapterType === 'CH')
                .map(el => (
                  <div key={`${el._id}-${el.updatedAt}`}>
                    <span
                      style={{
                        alignSelf: 'center',
                        color: modChapter._id === el._id ? 'orange' : '',
                      }}>
                      {el.chapterNumber}
                    </span>
                    {modChapter._id === el._id && (
                      <span
                        style={{
                          alignSelf: 'center',
                        }}>
                        {t('modrule.modifying')}
                      </span>
                    )}
                    <span>
                      <Button
                        size='sm'
                        style={{
                          fontSize: '13px',
                          borderColor: 'white',
                          color: 'white',
                        }}
                        onClick={() => handleEditModChapter(el)}>
                        {t('button.edit')}
                      </Button>
                    </span>
                  </div>
                ))}
              {modRule.modChapterRef
                .filter(mchap => mchap.chapterType === 'APP')
                .map(el => (
                  <div>
                    <span
                      style={{
                        alignSelf: 'center',
                        color: modChapter._id === el._id ? 'orange' : '',
                      }}>
                      {el.chapterNumber}
                    </span>
                    {modChapter._id === el._id && (
                      <span
                        style={{
                          alignSelf: 'center',
                        }}>
                        {t('modrule.modifying')}
                      </span>
                    )}
                    <span>
                      <Button
                        size='sm'
                        style={{
                          fontSize: '13px',
                          borderColor: 'white',
                          color: 'white',
                        }}
                        onClick={() => handleEditModChapter(el)}>
                        {t('button.edit')}
                      </Button>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='draftpage__catelist'>
      <div className='draftpage__catelist__header'>
        <div className='draftpage__catelist__tabs'>
          <span
            className={`draftpage__catelist__tab ${
              lan === 'kor' ? ' open' : ''
            }`}
            role='button'
            aria-hidden='true'
            onClick={() => handlelan(0)}>
            KOREAN
          </span>
          <span
            className={`draftpage__catelist__tab ${
              lan === 'eng' ? ' open' : ''
            }`}
            role='button'
            aria-hidden='true'
            onClick={() => handlelan(1)}>
            ENGLISH
          </span>
          <span className='draftpage__catelist__tab__glide' />
        </div>
      </div>
      <div className='draftpage__catelist__collase'>
        {isLoading ? (
          <Spinner
            animation='border'
            role='status'
            style={{
              width: '4rem',
              height: '4rem',
              margin: 'auto',
            }}>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        ) : (
          categories &&
          categories.map(cate => (
            <CollapseItem
              key={cate._id}
              cate={cate}
              setModCreateInputs={setModCreateInputs}
            />
          ))
        )}
      </div>
      {modCreateInputs.open && (
        <div className='draftpage__addrule'>
          <div className='draftpage__addrule__header'>
            {t('modrule.rule-create')}
          </div>
          <div className='draftpage__addrule__body'>
            <Form.Group
              as={Row}
              className='mb-3'
              controlId='formPlaintextEmail'>
              <Form.Label column sm='3'>
                {t('modrule.category')}
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={modCreateInputs.category}
                  onChange={handleModCreateInputs}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className='mb-3'
              controlId='formPlaintextEmail'>
              <Form.Label column sm='3'>
                {t('modrule.rule-num')}
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  name='ruleNumber'
                  defaultValue={modCreateInputs.ruleNumber}
                  onChange={handleModCreateInputs}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className='mb-3'
              controlId='formPlaintextEmail'>
              <Form.Label column sm='3'>
                {t('modrule.rule-title')}
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  name='ruleName'
                  defaultValue={modCreateInputs.ruleName}
                  onChange={handleModCreateInputs}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className='mb-3'
              controlId='formPlaintextEmail'>
              <Form.Label column sm='3'>
                {t('modrule.rule-type')}
              </Form.Label>
              <Col sm='9'>
                <ButtonGroup>
                  {viewTypes.map((viewType, idx) => (
                    <ToggleButton
                      key={viewType.id}
                      id={`radio-${idx}`}
                      type='radio'
                      variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                      name='radio'
                      value={viewType.value}
                      checked={modCreateInputs.viewType === viewType.value}
                      onChange={e => handleViewType(e.currentTarget.value)}>
                      {viewType.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Col>
            </Form.Group>
          </div>
          <div className='draftpage__addrule__bottom'>
            <Button type='submit' variant='secondary' onClick={handleClose}>
              {t('button.cancel')}
            </Button>
            <Button
              type='submit'
              variant='secondary'
              onClick={submitModRuleCreate}>
              {t('button.create')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnactRuleSidelist;
