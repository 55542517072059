import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserView, MobileView } from 'react-device-detect';
import RuleBaseMobile from './RuleBaseMobile';
import RuleBaseBrowser from './RuleBaseBrowser';
import './App.scss';
import AppTimer from './AppTimer';

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');

    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <AppTimer>
      <BrowserView>
        <RuleBaseBrowser />
      </BrowserView>
      <MobileView>
        <RuleBaseMobile />
      </MobileView>
    </AppTimer>
  );
};

export default App;
