import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../scss/GroupChart/GroupChart.scss';
import GradeChart from './GradeChart';
import OrgChartPage from './OrgChartPage';
import OrgPositionPage from './OrgPositionPage';
import Unauthorized from '../Unauthorized';
import useAuth from '../../common/hooks/useAuth';

const GroupChart = () => {
  const [chartTab, setChartTab] = useState('orgChart');
  const { auth } = useAuth();
  const { t } = useTranslation();

  return (
    <div>
      {auth.role === 'role1' || auth.role === 'role4' ? (
        <div className='chart mainsection'>
          <h4>{t('groupChart.title')}</h4>
          <div className='chart__tabmenu'>
            <Button
              role='button'
              aria-hidden='true'
              className={`tabmenu-item ${
                chartTab === 'orgChart' ? 'active' : ''
              }`}
              onClick={() => setChartTab('orgChart')}
              as={Link}>
              {t('groupChart.orgchart.title')}
            </Button>
            <Button
              role='button'
              aria-hidden='true'
              className={`tabmenu-item ${
                chartTab === 'gradeChart' ? 'active' : ''
              }`}
              onClick={() => setChartTab('gradeChart')}
              as={Link}>
              {t('groupChart.manageGrade.title')}
            </Button>

            <Button
              role='button'
              aria-hidden='true'
              className={`tabmenu-item ${
                chartTab === 'position' ? 'active' : ''
              }`}
              onClick={() => setChartTab('position')}
              as={Link}>
              {t('groupChart.managePosition.title')}
            </Button>
          </div>
          {(() => {
            if (chartTab === 'orgChart') {
              return <OrgChartPage />;
            }
            if (chartTab === 'gradeChart') {
              return <GradeChart />;
            }
            return <OrgPositionPage />;
          })()}
        </div>
      ) : (
        <Unauthorized />
      )}
    </div>
  );
};

export default GroupChart;
