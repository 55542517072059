import React from 'react';
import { useLocation } from 'react-router-dom';
import RuleBreadCrumb from '../RuleInfo/RuleBreadCrumb';
import FormDualLeft from './FormDualLeft';
import FormDualRight from './FormDualRight';
import '../../scss/Dual/FormDual.scss';

const FormDual = () => {
  const { state } = useLocation();

  return (
    <div className='formdual mainsection'>
      <div className='formdual__header'>
        <RuleBreadCrumb form />
      </div>
      <div className='formdual__contents'>
        <FormDualLeft
          lan={state.lan}
          cateName={state.category}
          ruleNumber={state.ruleNumber}
        />
        <FormDualRight
          lan={state.lan}
          cateName={state.category}
          ruleNumber={state.ruleNumber}
        />
      </div>
    </div>
  );
};

export default FormDual;
