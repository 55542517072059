import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import Select from 'react-select';
import SearchBar from '../SearchBar';
import AuthRuleList from './AuthRuleList';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

// isEdite
const UserRole = ({ user, setUpdate, targetCategory }) => {
  const { id, username, org, view, notice, rulemanager, examine, approve } =
    user;
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();

  const handleOnePermision = async e => {
    e.preventDefault();
    const { checked } = e.target;
    if (!checked) {
      const data1 = {
        email: user.email,
        viewId: view._id,
      };
      await axiosAuth
        .post('/api/management/userviewdelete', data1)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => console.log(err));
    } else {
      const data2 = {
        email: user.email,
        categoryNumber: targetCategory.categoryNumber,
        category: targetCategory.category,
        sortNum: targetCategory.sortNum,
      };
      await axiosAuth
        .post('/api/management/userviewadd', data2)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => console.log(err));
    }
  };

  const handleOnePrintDown = async (e, viewid) => {
    e.preventDefault();
    const { value } = e.target;
    const data = {
      email: user.email,
      viewId: viewid,
    };
    if (value === 'print') {
      await axiosAuth
        .post('/api/management/userviewprint', data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => console.log(err));
    }

    if (value === 'down') {
      await axiosAuth
        .post('/api/management/userviewdown', data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => console.log(err));
    }
  };

  const handleOneAuth = async e => {
    e.preventDefault();
    const { checked, value } = e.target;
    const data = {
      email: user.email,
      roleName: value,
    };
    if (checked) {
      // checked는 박스가 체크되어있으면 false
      await axiosAuth
        .post('/api/management/userauthadd', data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => toast(err.response.data));
    } else {
      await axiosAuth
        .post('/api/management/userauthdelete', data)
        .then(res => {
          toast(res.data);
          setUpdate(prev => !prev);
        })
        .catch(err => toast(err.response.data));
    }
  };

  return (
    <tr className='tableBox__body__tr'>
      <td width='10%'>{username}</td>
      <td width='10%'>{org && org}</td>
      <td width='10%'>
        <input
          type='checkbox'
          checked={view || false}
          value='view'
          name='view'
          id={`viewcheck_${id}`}
          readOnly
          onClick={e => handleOnePermision(e)}
        />
      </td>
      <td width='10%'>
        <input
          type='checkbox'
          checked={(view && view.print) || false}
          name='print'
          value='print'
          disabled={!view}
          id={`printcheck_${id}`}
          onClick={e => handleOnePrintDown(e, view._id)}
          readOnly
        />
      </td>
      <td width='10%'>
        <input
          type='checkbox'
          checked={(view && view.down) || false}
          name='down'
          value='down'
          disabled={!view}
          id={`downcheck_${id}`}
          onClick={e => handleOnePrintDown(e, view._id)}
          readOnly
        />
      </td>
      <td width='10%'>
        <input
          type='checkbox'
          checked={notice}
          name='notice'
          value='notice'
          id={`noticecheck_${id}`}
          onClick={e => handleOneAuth(e)}
          readOnly
        />
      </td>
      <td width='40%' className='tableBox__body__button'>
        <label htmlFor={`draftcheck_${id}`}>
          <input
            type='checkbox'
            name='rulemanager'
            value='rulemanager'
            checked={rulemanager}
            id={`draftcheck_${id}`}
            onClick={e => handleOneAuth(e)}
            readOnly
          />
          {t('managerauth.draft')}
        </label>
        <label htmlFor={`examinecheck_${id}`}>
          <input
            type='checkbox'
            name='examine'
            value='examine'
            checked={examine}
            id={`examinecheck_${id}`}
            onClick={e => handleOneAuth(e)}
            readOnly
          />
          {t('managerauth.reference')}
        </label>
        <label htmlFor={`approvecheck_${id}`}>
          <input
            type='checkbox'
            name='approve'
            value='approve'
            checked={approve}
            id={`approvecheck_${id}`}
            onClick={e => handleOneAuth(e)}
            readOnly
          />
          {t('managerauth.approve')}
        </label>
      </td>
    </tr>
  );
};

const AuthbyRule = () => {
  const { t, i18n } = useTranslation();
  const [targetCategory, setTargetCategory] = useState('');
  const [orgoptions, setOrgoptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [selectOrg, setSelectOrg] = useState(t('managerauth.all-departments'));
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [update, setUpdate] = useState(false);
  const rulelistRef = useRef({});
  const axiosAuth = useAxiosAuth();
  const [openAll, setOpenAll] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const userList = async () => {
      setIsLoading(true);
      setSelectOrg(t('managerauth.all-departments'));
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: '/api/management/users',
        });
        const orgArr = res.data.map(user => ({
          label: user.org.name,
          value: user.org._id,
        }));
        const orgoptionsArr = [...new Set(orgArr.map(JSON.stringify))].map(
          JSON.parse
        );
        setOrgoptions(orgoptionsArr);
        const validateUsers = res.data.map(user => ({
          id: user._id,
          username: user.username,
          orgId: user.org._id,
          org: user.org.name,
          email: user.email,
          view:
            user.permissions.view.filter(
              per => per.categoryNumber === targetCategory.categoryNumber
            )[0] || null,
          notice:
            user.roles.map(el => el.authority).includes('admin') ||
            user.roles.map(el => el.authority).includes('notice'),
          rulemanager:
            user.roles.map(el => el.authority).includes('admin') ||
            user.roles.map(el => el.authority).includes('rulemanager'),
          examine:
            user.roles.map(el => el.authority).includes('admin') ||
            user.roles.map(el => el.authority).includes('examine'),
          approve:
            user.roles.map(el => el.authority).includes('admin') ||
            user.roles.map(el => el.authority).includes('approve'),
        }));
        validateUsers.sort((a, b) => {
          return (
            (a.view === null) - (b.view === null) ||
            +(a.view > b.view) ||
            -(a.view < b.view)
          );
        });
        setUsers(validateUsers);
        setFilterUsers(validateUsers);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    if (targetCategory && targetCategory.categoryNumber !== '') {
      userList();
    }
    return () => {
      controller.abort();
    };
  }, [targetCategory]);

  useEffect(() => {
    const controller = new AbortController();
    const userList = async () => {
      setIsLoading(true);
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: '/api/management/users',
        });
        const orgArr = res.data.map(user => ({
          label: user.org.name,
          value: user.org._id,
        }));
        const orgoptionsArr = [...new Set(orgArr.map(JSON.stringify))].map(
          JSON.parse
        );
        setOrgoptions(orgoptionsArr);
        const validateUsers = res.data.map(user => ({
          id: user._id,
          username: user.username,
          orgId: user.org._id,
          org: user.org.name,
          email: user.email,
          view:
            user.permissions.view.filter(
              per => per.categoryNumber === targetCategory.categoryNumber
            )[0] || null,
          notice:
            user.roles.map(el => el.authority).includes('admin') ||
            user.roles.map(el => el.authority).includes('notice'),
          rulemanager:
            user.roles.map(el => el.authority).includes('admin') ||
            user.roles.map(el => el.authority).includes('rulemanager'),
          examine:
            user.roles.map(el => el.authority).includes('admin') ||
            user.roles.map(el => el.authority).includes('examine'),
          approve:
            user.roles.map(el => el.authority).includes('admin') ||
            user.roles.map(el => el.authority).includes('approve'),
        }));
        validateUsers.sort((a, b) => {
          return (
            (a.view === null) - (b.view === null) ||
            +(a.view > b.view) ||
            -(a.view < b.view)
          );
        });
        setUsers(validateUsers);
        if (selectOrg !== t('managerauth.all-departments')) {
          const usersCopy = validateUsers.filter(el => el.orgId === selectOrg);
          setFilterUsers(usersCopy);
          setIsLoading(false);
          return;
        }
        setFilterUsers(validateUsers);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    userList();
    return () => {
      controller.abort();
    };
  }, [update]);

  const listFilter = text => {
    if (text === t('managerauth.all-departments')) {
      setFilterUsers(users);
    } else {
      const usersCopy = users.filter(el => el.orgId === text);
      setFilterUsers(usersCopy);
    }
  };

  const onChangeSelect = e => {
    listFilter(e.value);
    setSelectOrg(e.label);
  };

  const handlesearchStr = e => {
    setSearchStr(e.target.value);
  };

  const searchText = () => {
    rulelistRef.current.contentFilter();
  };

  const handleKeyDown = e => {
    const key = e.code;
    switch (key) {
      case 'Enter':
        searchText();
        break;
      default:
    }
  };

  const handleAddPerAllUser = async () => {
    let data = {
      categoryNumber: targetCategory.categoryNumber,
      category: targetCategory.category,
      sortNum: targetCategory.sortNum,
    };

    if (selectOrg !== t('managerauth.all-departments')) {
      data = { ...data, orgId: selectOrg };
    }

    if (
      window.confirm(
        i18n === 'ko'
          ? `전체 구성원의 ${targetCategory.categoryNumber} 열람 권한 및 기본권한을 추가하시겠습니까?`
          : `Do you want to add viewing and default permissions for all members of ${targetCategory.categoryNumber}?`
      )
    ) {
      await axiosAuth
        .post('/api/management/addpersandroles', data)
        .then(res => {
          toast(res.data);
          setOpenAll(false);
          setUpdate(prev => !prev);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response.data);
        });
    }
  };

  const handleDeletePerAllUser = async () => {
    let data = {
      categoryNumber: targetCategory.categoryNumber,
    };

    if (selectOrg !== t('managerauth.all-departments')) {
      data = { ...data, orgId: selectOrg };
    }
    if (
      window.confirm(
        i18n === 'ko'
          ? `전체 구성원의 ${targetCategory.categoryNumber} 열람 권한 및 기본권한을 삭제하시겠습니까?`
          : `Do you want to remove viewing and default permissions for all members of ${targetCategory.categoryNumber}?`
      )
    ) {
      await axiosAuth
        .post('/api/management/deletepersandroles', data)
        .then(res => {
          toast(res.data);
          setOpenAll(false);
          setUpdate(prev => !prev);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response.data);
        });
    }
  };

  const handleAllprint = async type => {
    const url =
      type === 'add'
        ? '/api/management/useralladdprintper'
        : '/api/management/useralldeleteprintper';

    const data = {
      categoryNumber: targetCategory.categoryNumber,
      category: targetCategory.category,
      sortNum: targetCategory.sortNum,
    };
    if (
      window.confirm(
        i18n === 'ko'
          ? `전체 구성원의 ${targetCategory.categoryNumber} 인쇄 권한을 추가하시겠습니까?`
          : `Do you want to add printing permissions for all members of ${targetCategory.categoryNumber}`
      )
    ) {
      await axiosAuth
        .post(url, data)
        .then(res => {
          toast(res.data);
          setOpenAll(false);
          setUpdate(prev => !prev);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response.data);
        });
    }
  };

  const handleAlldown = async type => {
    const url =
      type === 'add'
        ? '/api/management/useralladddownper'
        : '/api/management/useralldeletedownper';

    const word = type === 'add' ? '추가' : '삭제';

    const data = {
      categoryNumber: targetCategory.categoryNumber,
      category: targetCategory.category,
      sortNum: targetCategory.sortNum,
    };

    if (
      window.confirm(
        i18n === 'ko'
          ? `전체 구성원의 ${targetCategory.categoryNumber} 다운로드 권한을 ${word}하시겠습니까?`
          : `Do you want to ${word} downloading permissions for all members of ${targetCategory.categoryNumber}`
      )
    ) {
      await axiosAuth
        .post(url, data)
        .then(res => {
          toast(res.data);
          setOpenAll(false);
          setUpdate(prev => !prev);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response.data);
        });
    }
  };

  const handleAllOneRole = async (type, role) => {
    let targetrole = '';
    const targetaction =
      type === 'add' ? t('managerauth.add') : t('managerauth.del');
    const targeturl = type === 'add' ? '/useralladdrole' : '/useralldeleterole';

    if (role === 'notice') {
      targetrole = t('managerauth.notice');
    } else if (role === 'rulemanager') {
      targetrole = t('managerauth.ea-draft');
    } else if (role === 'examine') {
      targetrole = t('managerauth.ea-reference');
    } else {
      targetrole = t('managerauth.ea-approve');
    }

    let data = {
      roleName: role,
    };

    if (selectOrg !== t('managerauth.all-departments')) {
      data = { ...data, orgId: selectOrg };
    }
    if (
      window.confirm(
        i18n === 'ko'
          ? `전체 구성원의 ${targetrole} 권한을 ${targetaction}하시겠습니까?`
          : `Do you want to ${targetaction} ${targetrole} permissions for all members?`
      )
    ) {
      await axiosAuth
        .post(`/api/management${targeturl}`, data)
        .then(res => {
          toast(res.data);
          setOpenAll(false);
          setUpdate(prev => !prev);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response.data);
        });
    }
  };

  return (
    <div className='userauth__section'>
      <section className='userauth__topHeader'>
        <div className='userauth__topHeader__leftBox'>
          <div className='groupbar'>
            <SearchBar
              value={searchStr}
              onChange={handlesearchStr}
              searchText={searchText}
              handleKeyDown={handleKeyDown}
            />
          </div>
          <div className='rulenum'>
            {t('managerauth.rule')}
            {`: ${targetCategory.categoryNumber || ''}`}
          </div>
        </div>
      </section>
      <section className='userauth__table'>
        <div className='userauth__table__list'>
          <AuthRuleList
            ref={rulelistRef}
            setTargetCategory={setTargetCategory}
            targetCategory={targetCategory}
            searchStr={searchStr}
          />
        </div>
        <div className='userauth__table__userlist'>
          <table className='tableBox'>
            <thead className='tableBox__header'>
              <tr className='tableBox__header__first'>
                <th width='10%' rowSpan={2}>
                  {t('managerauth.user')}
                </th>
                <th width='10%' rowSpan={2}>
                  <Select
                    className='depSelect'
                    aria-label='Default select example'
                    onChange={onChangeSelect}
                    value={selectOrg}
                    placeholder={selectOrg}
                    options={orgoptions}
                    styles={{
                      control: provided => ({
                        ...provided,
                        border: 'none',
                        boxShadow: 'none',
                        width: '12vw',
                        backgroundColor: '#f4f6fb',
                        outline: 0,
                      }),
                      valueContainer: provided => ({
                        ...provided,
                        backgroundColor: '#f4f6fb',
                      }),
                      indicatorsContainer: base => ({
                        ...base,
                        border: 'none',
                        backgroundColor: '#f4f6fb',
                      }),
                      menu: base => ({
                        ...base,
                        zIndex: 9999,
                        marginTop: '0.5vh',
                      }),
                    }}
                  />
                </th>
                <th width='10%'>
                  <span>{t('managerauth.viewrule')}</span>
                </th>
                <th width='10%'>{t('managerauth.printrule')}</th>
                <th width='10%'>{t('managerauth.downloadrule')}</th>
                <th width='10%'>{t('managerauth.postnotice')}</th>
                <th width='40%'>{t('managerauth.managerule')}</th>
              </tr>
              <tr className='tableBox__header__second'>
                <th width='10%'>
                  <Button
                    size='sm'
                    className='tableBox__header__btnAll'
                    onClick={() => setOpenAll(prev => !prev)}>
                    {t('managerauth.all')}
                  </Button>
                  {openAll && (
                    <div className='tableBox__header__popuphandleall'>
                      <Button
                        type='button'
                        // variant='secondary'
                        onClick={() => handleAddPerAllUser()}>
                        {t('managerauth.addall')}
                      </Button>
                      <Button
                        type='button'
                        // variant='secondary'
                        onClick={() => handleDeletePerAllUser()}>
                        {t('managerauth.delall')}
                      </Button>
                      <Button
                        type='button'
                        variant='outline-primary'
                        onClick={() => setOpenAll(false)}>
                        X
                      </Button>
                    </div>
                  )}
                </th>
                <th width='10%'>
                  <BsCheckCircle onClick={() => handleAllprint('add')} />
                  <BsXCircle onClick={() => handleAllprint('del')} />
                </th>
                <th width='10%'>
                  <BsCheckCircle onClick={() => handleAlldown('add')} />
                  <BsXCircle onClick={() => handleAlldown('del')} />
                </th>
                <th width='10%'>
                  <BsCheckCircle
                    onClick={() => handleAllOneRole('add', 'notice')}
                  />
                  <BsXCircle
                    onClick={() => handleAllOneRole('del', 'notice')}
                  />
                </th>
                <th width='40%' className='tableBox__header__thrulemode'>
                  <span>
                    <BsCheckCircle
                      onClick={() => handleAllOneRole('add', 'rulemanager')}
                    />
                    <BsXCircle
                      onClick={() => handleAllOneRole('del', 'rulemanager')}
                    />
                  </span>
                  <span>
                    <BsCheckCircle
                      onClick={() => handleAllOneRole('add', 'examine')}
                    />
                    <BsXCircle
                      onClick={() => handleAllOneRole('del', 'examine')}
                    />
                  </span>
                  <span>
                    <BsCheckCircle
                      onClick={() => handleAllOneRole('add', 'approve')}
                    />
                    <BsXCircle
                      onClick={() => handleAllOneRole('del', 'approve')}
                    />
                  </span>
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className='tableBox__body__spinner'>
                <tr>
                  <td colSpan='7'>
                    <Spinner
                      animation='border'
                      role='status'
                      style={{
                        width: '4rem',
                        height: '4rem',
                      }}>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className='tableBox__body'>
                {targetCategory.categoryNumber && filterUsers.length !== 0 ? (
                  filterUsers.map(user => (
                    <UserRole
                      key={user.id}
                      user={user}
                      targetCategory={targetCategory}
                      setUpdate={setUpdate}
                    />
                  ))
                ) : (
                  <tr className='tableBox__body__noselected'>
                    <td colSpan='7'>
                      <div>{t('managerauth.selectTargetrule')}</div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </section>
    </div>
  );
};

export default React.memo(AuthbyRule);
