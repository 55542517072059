import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const DevDcnSet = ({ open, setOpen, rule }) => {
  const { t } = useTranslation();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const axiosAuth = useAxiosAuth();
  const [oldRuleList, setOldRuleList] = useState([]);
  const [dcns, setDcns] = useState([]);
  const [oldRule, setOldRule] = useState({});
  const [dcn, setDcn] = useState({});

  useEffect(() => {
    (async () => {
      axios
        .all([
          axiosAuth.get(`/api/oldrule/all/${rule.ruleNumber}`),
          axiosAuth.get(`/api/revstatus/${rule._id}`),
        ])
        .then(
          axios.spread((res1, res2) => {
            const oldRuleOptions = res1.data.map(old => ({
              label: timezoneFormat(
                utcToZonedTime(
                  toDate(old.revisedAt, {
                    timeZone: 'UTC',
                  }),
                  timezone
                ),
                'yyyy-MM-dd',
                { timeZone: timezone }
              ),
              value: old,
            }));
            const dcnOptions = res2.data.map(dc => ({
              label: `${timezoneFormat(
                utcToZonedTime(
                  toDate(dc.revisedAt, {
                    timeZone: 'UTC',
                  }),
                  timezone
                ),
                'yyyy-MM-dd',
                { timeZone: timezone }
              )} | ${dc.dcnNumber}`,
              value: dc,
            }));
            setOldRuleList(oldRuleOptions);
            setDcns(dcnOptions);
          })
        )
        .catch(error => {
          console.log(error);
        });
    })();
  }, []);

  const connectSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/oldrule/connect',
        data: {
          oldRuleId: oldRule.value._id,
          dcnId: dcn.value._id,
        },
      });
      toast(
        `(${res.data.ruleNumber}) - dcn ${t('modrule.connection-complete')}`
      );
    } catch (error) {
      console.log(error);
      toast(t('modrule.registration-failed'));
    }
  };

  return (
    <Modal
      backdropClassName='double'
      dialogClassName='devdraft__popup'
      contentClassName='devdraft__popup__content'
      show={open}
      // onHide={() => setTargetRev({ type: '' })}
      backdrop='static'
      keyboard={false}>
      <Modal.Header>
        <Modal.Title>
          <span>{t('modrule.amend-history-rule-connection')}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{`RULENUMBER : ${rule?.ruleNumber} | RULENAME : ${rule?.ruleName}`}</div>
        <div>
          <label className='devdraft__label' htmlFor='select-rule'>
            {t('modrule.old-rule')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.connect-old-rule')}</div>}
            isSearchable
            id='select-rule'
            className='devdraft__side__rule__select'
            options={oldRuleList}
            onChange={e => setOldRule(e)}
          />
        </div>
        <div>
          <label className='devdraft__label' htmlFor='select-rule'>
            DCN {t('modrule.history')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={
              <div>{t('modrule.select-revision-history-placeholder')}</div>
            }
            isSearchable
            id='select-rule'
            className='devdraft__side__rule__select'
            options={dcns}
            onChange={e => setDcn(e)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={connectSubmit}>{t('button.submit')}</Button>
        <Button onClick={() => setOpen(false)}>{t('button.cancel')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DevDcnSet;
