import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  BsFillCaretDownSquareFill,
  BsFillCaretUpSquareFill,
  BsFillTrash3Fill,
  BsWrench,
} from 'react-icons/bs';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { axiosAuth } from '../../common/axios/api';

const OrgPosis = ({ posiList, setUpdate }) => {
  const posisRef = useRef();
  const [changing, setChanging] = useState(false);
  const [posis, setPosis] = useState();
  const [posiInput, setPosiInput] = useState({
    positionName: '',
  });
  const [openIndex, setOpenIndex] = useState(0);
  const [changeInput, setChangeInput] = useState({
    positionId: '',
    updateName: '',
  });
  const { t } = useTranslation();
  const arrayDiff = (array1, array2) => {
    return JSON.stringify(array1) === JSON.stringify(array2);
  };

  useEffect(() => {
    if (arrayDiff(posisRef.current, posis) === false) {
      setChanging(true);
    }
  }, [posisRef.current, posis]);

  useEffect(() => {
    const sortList = posiList.sort((a, b) => a.sortNum - b.sortNum);
    posisRef.current = sortList;
    setPosis(sortList);
    // setChanging(false);
  }, [posiList]);

  const onChangeForm = e => {
    const { value, name } = e.target;
    setPosiInput({
      ...posiInput,
      [name]: value,
    });
  };

  const handlePosiName = e => {
    const { value, name } = e.target;
    setChangeInput({
      ...changeInput,
      [name]: value,
    });
  };

  const addPosiOne = async () => {
    if (window.confirm(t('groupChart.orgchart.add-position'))) {
      await axiosAuth
        .post('/api/position/new', {
          positionName: posiInput.positionName,
        })
        .then(res => {
          if (res.status === 200) {
            setUpdate(prev => !prev);
          }
        })
        .catch(err => console.log(err));
    }
  };
  // sortNum 도 변경해야함
  const handleAddSortNum = async (index, position) => {
    if (index === 0) {
      return;
    }
    await axiosAuth
      .post('/api/position/edit/sort', {
        positionId: position._id,
        sortNum: position.sortNum,
        sort: -1,
      })
      .then(res => {
        if (res.status === 200) {
          setUpdate(prev => !prev);
          toast(
            `${position.positionName} ${t('groupChart.orgchart.order-change')}`
          );
        }
      })
      .catch(err => console.log(err));
  };
  // sortNum 도 변경해야함
  const handleMinusSortNum = async (index, position) => {
    if (index === posis.length - 1) {
      return;
    }
    await axiosAuth
      .post('/api/position/edit/sort', {
        positionId: position._id,
        sortNum: position.sortNum,
        sort: 1,
      })
      .then(res => {
        if (res.status === 200) {
          toast(
            `${position.positionName} ${t('groupChart.orgchart.order-change')}`
          );
          setUpdate(prev => !prev);
        }
      })
      .catch(err => console.log(err));
  };

  const submitPosiSort = async () => {
    if (window.confirm(t('groupChart.orgchart.update-position-q'))) {
      await axiosAuth
        .post('/api/position/reflection')
        .then(res => {
          if (res.status === 200) {
            toast(t('groupChart.orgchart.update-position'));
            setUpdate(prev => !prev);
          }
        })
        .catch(err => console.log(err));
    }
  };

  const handleDelPosi = async position => {
    const { _id, sortNum } = position;
    if (window.confirm(t('groupChart.orgchart.del-position-q'))) {
      await axiosAuth
        .delete(`/api/position/del/${_id}/${sortNum}`)
        .then(res => {
          if (res.status === 200) {
            toast(
              `${position.positionName} ${t(
                'groupChart.orgchart.del-position'
              )}`
            );
            setUpdate(prev => !prev);
          }
        })
        .catch(err => console.log(err));
    }
  };

  const handleOpenIndex = (i, position) => {
    setOpenIndex(i);
    setChangeInput(prev => ({
      ...prev,
      positionId: position._id,
      updateName: position.positionName,
    }));
  };

  const submitPosiName = async () => {
    await axiosAuth
      .post('/api/position/edit', {
        positionId: changeInput.positionId,
        updateName: changeInput.updateName,
      })
      .then(res => {
        if (res.status === 200) {
          toast(t('groupChart.orgchart.edit-position-name'));
          setUpdate(prev => !prev);
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <>
      <div className='positionpage__header'>
        <div className='positionpage__header__input'>
          <div>
            <InputGroup>
              <InputGroup.Text>
                {t('groupChart.managePosition.positionName')}
              </InputGroup.Text>
              <Form.Control
                type='positionName'
                name='positionName'
                placeholder={t(
                  'groupChart.managePosition.addPositionDescription'
                )}
                value={posiInput.positionName}
                onChange={onChangeForm}
                aria-label='Position Name'
              />
            </InputGroup>
          </div>
          <div>
            <Button onClick={addPosiOne}>{t('button.add')}</Button>
          </div>
        </div>
        {changing && (
          <div className='positionpage__header__title'>
            <span>
              <BsWrench /> {t('groupChart.managePosition.editing')}
            </span>
          </div>
        )}
        <div className='positionpage__header__btns'>
          <Button disabled={!changing} onClick={submitPosiSort}>
            {t('groupChart.managePosition.saveChangeOrder')}
          </Button>
        </div>
      </div>
      <div className='positionpage__body'>
        <Table className='positionpage__body__table'>
          <thead>
            <tr>
              <th width='15%'>{t('groupChart.managePosition.index')}</th>
              <th width='50%'>{t('groupChart.managePosition.positionName')}</th>
              <th width='20%'>{t('groupChart.managePosition.changeOrder')}</th>
              <th width='15%'>{t('groupChart.managePosition.del')}</th>
            </tr>
          </thead>
          <tbody>
            {posis &&
              posis.length > 0 &&
              posis.map((posi, i) => (
                <tr className='positionpage__body__item' key={posi.el_id}>
                  <td align='center'>{i + 1}</td>
                  {openIndex === i + 1 ? (
                    <td className='changeBtn'>
                      <input
                        type='updateName'
                        value={changeInput.updateName}
                        name='updateName'
                        onChange={handlePosiName}
                      />
                      <Button
                        variant='secondary'
                        onClick={() => submitPosiName()}>
                        {t('button.apply')}
                      </Button>
                      <Button
                        variant='secondary'
                        onClick={() => setOpenIndex(0)}>
                        {t('button.cancel')}
                      </Button>
                    </td>
                  ) : (
                    <td className='editBtn' align='left'>
                      <span>{posi.positionName}</span>
                      <Button
                        variant='secondary'
                        className='editBtn'
                        onClick={() => handleOpenIndex(i + 1, posi)}>
                        {t('button.edit')}
                      </Button>
                    </td>
                  )}
                  <td align='center'>
                    <span>
                      <BsFillCaretUpSquareFill
                        onClick={() => handleAddSortNum(i, posi)}
                      />
                    </span>
                    <span>
                      <BsFillCaretDownSquareFill
                        onClick={() => handleMinusSortNum(i, posi)}
                      />
                    </span>
                  </td>
                  <td align='center'>
                    <span>
                      <BsFillTrash3Fill onClick={() => handleDelPosi(posi)} />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default OrgPosis;
