import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import MobileMainNav from './MobileMainNav';
import MobileSideNav from './MobileSideNav';

const MobileLayout = () => {
  const location = useLocation().pathname;
  return (
    <>
      <MobileMainNav path={location} />
      <MobileSideNav path={location} />
      <div className='mobile-container'>
        <Outlet />
      </div>
    </>
  );
};

export default MobileLayout;
