import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

const CustomFileInput = forwardRef(
  (
    {
      onChange,
      name,
      accept,
      multiple,
      directory,
      webkitdirectory,
      disabled = false,
      style = {},
    },
    ref = ''
  ) => {
    const { t } = useTranslation();
    const [fileName, setFileName] = useState('');

    const handleFileChange = event => {
      if (disabled) return; // disabled 상태에서는 파일 변경 무시
      const { files } = event.target;
      setFileName(
        files.length > 0
          ? Array.from(files)
              .map(file => file.name)
              .join(', ')
          : t('mypage.profileUpdate.no-file')
      );
      if (onChange) onChange(event); // 부모의 onChange 호출
    };

    return (
      <div
        style={{
          width: '75%',
          height: '38px',
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: '5px',
          opacity: disabled ? 0.5 : 1, // 비활성화 시 투명도 적용
          pointerEvents: disabled ? 'none' : 'auto', // 비활성화 시 클릭 방지
          ...style,
        }}>
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            flexShrink: '0',
            height: '100%',
            backgroundColor: '#e9ecef',
            cursor: disabled ? 'not-allowed' : 'pointer',
            padding: '0.75rem',
            transition:
              'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            borderRight: '1px solid #ccc',
            margin: '0',
          }}>
          {t('mypage.profileUpdate.choose-file')}
          <input
            type='file'
            name={name}
            ref={ref}
            style={{ display: 'none' }}
            accept={accept}
            multiple={multiple}
            onChange={handleFileChange}
            disabled={disabled}
            {...(directory && { directory: '' })} // 폴더 선택 속성
            {...(webkitdirectory && { webkitdirectory: '' })} // 폴더 선택 속성
          />
        </label>
        <p
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px',
            fontSize: '14px',
            margin: '0',
          }}>
          {fileName === '' ? t('mypage.profileUpdate.no-file') : fileName}
        </p>
      </div>
    );
  }
);

export default CustomFileInput;
