import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import '../../scss/RuleInfo/RuleBreadCrumb.scss';

const RuleBreadCrumb = ({ breadCrumbsData }) => {
  const { t } = useTranslation();
  const location = useLocation().pathname;
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    if (breadCrumbsData) {
      const { _id, ruleNumber, ruleName, refCategory, viewType } =
        breadCrumbsData;
      const breadCrumbsArr = [];
      breadCrumbsArr.push({
        path: 'category',
        _id: refCategory?.id._id,
        targetNumber: refCategory?.id.categoryNumber,
        name: `[${refCategory.id.categoryNumber}] ${refCategory.category}`,
        active: '',
      });
      breadCrumbsArr.push({
        path: viewType,
        _id,
        targetNumber: ruleNumber,
        name: `[${ruleNumber}] : ${ruleName}`,
        active: 'active',
      });
      setBreadCrumbs(breadCrumbsArr);
    }
  }, [breadCrumbsData]);

  return (
    <nav className='rulebreadcrumb' aria-label='breadcrumb'>
      <ol className='breadcrumb breadcrumb-line text-mute fs-6 fw-bold'>
        <li className='breadcrumb-item'>
          <Link to='/ruleinfo'>{t('ruleBreadCrumb.ruleInfo')}</Link>
        </li>
        {breadCrumbs.map(breadCrumb => (
          <li
            className={`breadcrumb-item ${breadCrumb.active}`}
            key={`${breadCrumb.path}-${breadCrumb._id}`}>
            <Link
              to={`/ruleinfo/${breadCrumb.path}/${breadCrumb.targetNumber}`}>
              {breadCrumb.name}
            </Link>
          </li>
        ))}
        {location.includes('dual') && (
          <li className='breadcrumb-item active'>
            <Link to='/ruleinfo'>{t('ruleBreadCrumb.dual')}</Link>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default RuleBreadCrumb;
