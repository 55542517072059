import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const ReferenceList = () => {
  const { auth } = useAuth();
  const { t } = useTranslation();
  const axiosAuth = useAxiosAuth();
  const [refereceData, setReferenceData] = useState([]);
  const navi = useNavigate();
  const statusTranslate = (status, classi) => {
    switch (true) {
      case status === 'examine':
        return t('reference.under-review');
      case status === 'hold':
        return t('reference.rejected');
      case status === 'approve':
        return t('reference.pending-approval');
      case status === 'preRevision' && classi === 'enact':
        return t('reference.pending-enact');
      case status === 'preRevision' && classi === 'amend':
        return t('reference.pending-amend');
      default:
        return t('reference.submit-draft');
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const UserModRules = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/modrule/reference/byuser/${auth.id}`,
        });
        const tableData = res.data;
        const dataSet = tableData.reverse().map(el => ({
          _id: el._id,
          modRuleName: el.refRuleId
            ? `[${el.refRuleId.ruleNumber}] ${el.refRuleId.ruleName}`
            : t('reference.set-no-ruleName'),
          lan: el.lan,
          status: statusTranslate(el.status, el.classification),
          createdAt: el.createdAt.slice(0, 10),
          remarks: el.remarks ? el.remarks : '',
          classification:
            el.classification === 'enact'
              ? t('reference.enact')
              : t('reference.amend'),
          draftedAt: el.draftedAt.slice(0, 10),
          approvedAt:
            el.status === 'preRevision' ? el.updatedAt.slice(0, 10) : '',
          holdAt: el.status === 'hold' ? el.holdAt.slice(0, 10) : '',
          preRevisionAt: el.revisedAt ? el.revisedAt.slice(0, 10) : '',
        }));

        setReferenceData(dataSet);
      } catch (err) {
        console.log(err);
      }
    };
    UserModRules();
    return () => {
      controller.abort();
    };
  }, []);
  return (
    <section className='reference__list'>
      <Table>
        <thead>
          <tr>
            <th width='5%'>No.</th>
            <th width='10%'>{t('reference.draftType')}</th>
            <th width='10%'>{t('reference.lan')}</th>
            <th width='35%'>{t('reference.ruleName')}</th>
            <th width='10%'>{t('reference.status')}</th>
            <th width='15%'>{t('reference.draftedDay')}</th>
            <th width='15%'>{t('reference.revision')}</th>
          </tr>
        </thead>
        <tbody>
          {refereceData &&
            refereceData.map((data, index) => (
              <tr
                key={data._id}
                className='reference__linkitem'
                onClick={() => navi(`${data._id}`)}
                aria-hidden='true'>
                <td>{index + 1}</td>
                <td>{data.classification}</td>
                <td>{data.lan.toUpperCase()}</td>
                <td>{data.modRuleName}</td>
                <td>{data.status}</td>
                <td>{data.draftedAt}</td>
                <td>
                  {data.status === 'preRevision' ? data.preRevisionAt : '-'}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </section>
  );
};

export default ReferenceList;
