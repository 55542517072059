import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const RuleOldSelectBox = ({ datesData }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [oldRules, setOldRules] = useState([]);
  const [targetDate, setTargetDate] = useState({ label: '', value: '' });

  useEffect(() => {
    if (datesData && datesData.ruleNumber) {
      const fetchDates = async () => {
        try {
          const res = await axiosAuth({
            method: 'GET',
            url: `/api/oldrule/latest/${datesData.ruleNumber}`,
          });

          const oldsCopy = res.data.map(old => ({
            label: timezoneFormat(
              utcToZonedTime(
                toDate(old.revisedAt, {
                  timeZone: 'UTC',
                }),
                timezone
              ),
              'yyyy-MM-dd',
              { timeZone: timezone }
            ),
            value: old._id,
          }));

          setOldRules(oldsCopy);
          setTargetDate({
            label: timezoneFormat(
              utcToZonedTime(
                toDate(datesData.revisedAt, {
                  timeZone: 'UTC',
                }),
                timezone
              ),
              'yyyy-MM-dd',
              { timeZone: timezone }
            ),

            value: datesData._id,
          });
        } catch (err2) {
          console.log(`error res2 : ${err2}`);
        }
      };
      fetchDates();
    }
  }, [datesData]);

  const handleTargetRule = e => {
    // 새로운 클라이언트 라우터 생성 및 화면 연결 필요
    // navi(`/ruleinfo/rule/${e.value}`);
  };

  return (
    <div className='rule__header__select'>
      <Select
        value={targetDate}
        styles={blackStyles}
        isSearchable
        isDisabled
        placeholder={t('components.ruleInfo.amend-select')}
        options={oldRules}
        onChange={handleTargetRule}
      />
    </div>
  );
};

export default RuleOldSelectBox;
