import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../scss/Mobile/MobileLog/MobileLog.scss';
import MobileRuleLog from './MobileRuleLog';
import MobileLoginLog from './MobileLoginLog';
import { LogProvider } from '../../store/LogProvider';

const MobileLog = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('auth'); // auth (접속) || rule(규정접근이력)
  const handleClick = tab => {
    setActiveTab(tab);
  };

  return (
    <LogProvider>
      <div className='mobileUserLog' style={{ position: 'relative' }}>
        <div className='mobileUserLog__fixedHeader'>
          <section className='mobileUserLog__header'>
            <Button
              role='button'
              aria-hidden='true'
              className={`tabmenu-item ${activeTab === 'auth' ? 'active' : ''}`}
              onClick={() => handleClick('auth')}
              as={Link}>
              {t('mobilelog.tab1')}
            </Button>
            <Button
              role='button'
              aria-hidden='true'
              className={`tabmenu-item ${activeTab === 'rule' ? 'active' : ''}`}
              onClick={() => handleClick('rule')}
              as={Link}>
              {t('mobilelog.tab2')}
            </Button>
          </section>
        </div>

        {activeTab === 'auth' ? <MobileLoginLog /> : <MobileRuleLog />}
      </div>
    </LogProvider>
  );
};

export default MobileLog;
