import React from 'react';
import parse from 'html-react-parser';

const RuleDualForm = ({ targetRule }) => {
  return (
    <div>
      <div id='overide-css-dualtext' className='dual__text__container'>
        {targetRule &&
          targetRule.chapterRef.map(el => (
            <div key={el._id} className='ck-content'>
              {parse(el.content)}
            </div>
          ))}
      </div>
    </div>
  );
};

export default RuleDualForm;
