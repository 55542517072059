import React, { createContext, useState, useMemo } from 'react';

const DiffContext = createContext({ newContent: '', oldContent: '' });

export const DiffProvider = ({ children, initialState }) => {
  const [diff, setDiff] = useState(initialState);
  const value = useMemo(() => ({ diff, setDiff }), [diff]);

  return <DiffContext.Provider value={value}>{children}</DiffContext.Provider>;
};

export default DiffContext;
