import React, { useRef, useState, useEffect } from 'react';
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import '../../scss/Dev/NewDevRule.scss';
import Select from 'react-select';
import { format, startOfDay } from 'date-fns';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import DevChapContents from './DevChapContents';
import DevDcnSet from './DevDcnSet';
import DatePicker from '../DatePicker/DatePicker';
import CustomFileInput from '../DatePicker/FileInput';

window.Buffer = window.Buffer || require('buffer').Buffer;

const DevDraft = () => {
  // 개발자 전용 룰 개정 화면 <개정등록>
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navi = useNavigate();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const editorRef = useRef(null);
  const [category, setCategory] = useState({ label: '', value: '' });
  const [options, setOptions] = useState([]);
  const [dcns, setDcns] = useState([]);
  const [dcn, setDcn] = useState({});
  const [ruleOptions, setRuleOptions] = useState([]);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [lan, setLan] = useState('kor');
  const [rule, setRule] = useState({ _id: '', ruleNumber: '', ruleName: '' });
  const [chapter, setChapter] = useState({
    _id: '',
    modType: '',
    fileType: '',
    chapterNumber: '',
    chapterName: '',
  });
  const [chapContent, setChapContent] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [chapterContents, setChapterContents] = useState({
    header: '',
    children: [],
  });
  const [oldRuleDate, setOldRuleDate] = useState('');
  const [currRuleDate, setCurrRuleDate] = useState('');
  const [dcnOpen, setDcnOpen] = useState(false);
  const [fileOne, setFileOne] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const initcategorySelect = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: '/api/category/all/kor',
      });
      const cateOptionsArr = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(cateOptionsArr);
    };
    initcategorySelect();
  }, []);

  useEffect(() => {
    const fetchcategoryLan = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/category/all/${lan}`,
      });
      const cateOptionsArr = response.data.map(option => ({
        label: `${option.categoryNumber} : ${option.category}`,
        value: option,
      }));
      setOptions(cateOptionsArr);
    };
    fetchcategoryLan();
  }, [lan]);

  useEffect(() => {
    const fetchDcn = async () => {
      const res = await axiosAuth({
        method: 'GET',
        url: `/api/dcn/list`,
      });
      const dcnsArr = res.data.map(dc => ({
        label: `DCN${dc.number}`,
        value: dc,
      }));
      setDcns(dcnsArr);
    };
    fetchDcn();
  }, []);

  const handleCategoryValue = async e => {
    setCategory(e);
    const response = await axiosAuth({
      method: 'GET',
      url: `/api/category/${e.value._id}/${auth.id}`,
    });
    const { ruleRef } = response.data;
    const ruleOptionsArr = ruleRef.map(ruleOption => ({
      label: `${ruleOption.ruleNumber} : ${ruleOption.ruleName}`,
      value: ruleOption,
    }));
    setRuleOptions(ruleOptionsArr);
  };

  const handleRuleValue = async e => {
    // chapter select options 업데이트
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/modrule/rule',
        data: { id: e.value._id },
      });
      const { chapterRef, revisedAt } = res.data;

      setRule({ ...rule, ...res.data });
      const chapterOptionsCopy = chapterRef.map(chapterOption => ({
        label: chapterOption.chapterNumber,
        value: chapterOption,
      }));
      setCurrentDate(
        timezoneFormat(
          utcToZonedTime(
            toDate(revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        )
      );
      setChapterOptions(chapterOptionsCopy);
    } catch (err) {
      console.log(err);
    }
  };

  const handleChapterValue = async e => {
    try {
      const response = await axiosAuth({
        method: 'GET',
        url: `/api/chapter/${e.value._id}`,
      });
      setChapter(response.data);
      setChapContent(response.data.content);
    } catch (error) {
      console.log(error);
      toast(t('modrule.chapter-communication-failed'));
    }
  };

  const onChangeRuleNumber = e => {
    setRule({ ...rule, ruleNumber: e.target.value });
  };

  const onChangeRuleName = e => {
    setRule({ ...rule, ruleName: e.target.value });
  };

  const onChangeChapterName = e => {
    setChapter({ ...chapter, chapterName: e.target.value });
  };

  const onChangeChapterNumber = e => {
    setChapter({ ...chapter, chapterNumber: e.target.value });
  };

  const onChangeOldRuleDate = e => {
    setOldRuleDate(e.target.value);
  };

  const onChangeCurrRuleDate = e => {
    setCurrRuleDate(e.target.value);
  };

  const createOldRuleSubmit = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/oldrule/create',
        data: {
          newRuleId: rule._id,
          oldRuleDate: new Date(oldRuleDate),
        },
      });
      toast(`(${res.data.ruleNumber}) - ${t('modrule.create-old-rule')}`);
      setOldRuleDate('');
    } catch (error) {
      console.log(error);
      toast(t('modrule.create-old-rule-failed'));
    }
  };

  const editCurrRuleDate = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modRule/changedate',
        data: {
          newRuleId: rule._id,
          currRuleDate: new Date(currRuleDate),
        },
      });
      toast(
        `${t('modrule.rule-date')} ${res.data.message} - ${
          res.data.result.ruleNumber
        } : ${res.data.result.revisedAt}`
      );
      setCurrRuleDate('');
    } catch (error) {
      console.log(error);
      toast(t('modrule.edit-rule-date-failed'));
    }
  };

  const createModRule = async () => {
    try {
      const res = await axiosAuth({
        method: 'post',
        url: '/api/modRule/createdevmod',
        data: {
          dcnId: dcn._id,
          number: dcn.number,
          newRuleId: rule._id,
          currRuleDate: dcn.revisionTime,
        },
      });
      toast(`${res.data.ruleName}`);
      // setCurrRuleDate('');
    } catch (error) {
      console.log(error);
      toast(t('modrule.edit-rule-date-failed'));
    }
  };

  const chapterEditSubmit = async () => {
    // const ckInstance = editorRef.current;
    // if (ckInstance) {
    //   const editorData = ckInstance.editor.getData({ pagination: true });
    //   createContents(editorData);
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/modRule/entrychapter',
        data: {
          ruleId: rule._id,
          ruleNumber: rule.ruleNumber,
          chapterId: chapter._id,
          chapterName: chapter.chapterName,
          chapterNumber: chapter.chapterNumber,
          content: chapContent,
          chapterContents,
          createdAt: format(
            startOfDay(new Date(currentDate)),
            'yyyy-MM-dd HH:mm:ss'
          ),
        },
      });
      toast(
        `(${response.status})${category.value} - ${t('modrule.add-chap-text')}`
      );
      navi('/modrule');
    } catch (error) {
      console.log(error);
      toast(t('modrule.edit-chap-failed'));
    }
  };
  const radios = [
    { name: 'KOREAN', value: 'kor' },
    { name: 'ENGLISH', value: 'eng' },
  ];

  function customUploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          loader.file.then(file => {
            data.append('file', file);
            axiosMultipart
              .post('/api/modrule/imageupload', data)
              .then(res => resolve({ default: res.data.imageUrl }))
              .catch(err => reject(err));
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    const editorinstance = editor;
    editorinstance.plugins.get('FileRepository').createUploadAdapter =
      loader => {
        return customUploadAdapter(loader);
      };
  }

  const handleDcn = e => {
    setDcn(e.value);
  };

  const createContents = docData => {
    const domparser = new DOMParser();
    const docStr = domparser.parseFromString(docData, 'text/html');
    if (docData.match(/<h2>/)) {
      const h2title = docStr
        .getElementsByTagName('h2')[0]
        .innerHTML.replace(/(<([^>]+)>)/gi, '')
        .replace(/&nbsp;/g, '')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>');
      setChapterContents(prev => ({
        ...prev,
        header: h2title,
      }));
    } else {
      setChapterContents(prev => ({
        ...prev,
        header: '',
      }));
    }
    if (docData.match(/<h3>/)) {
      const h3title = [...docStr.getElementsByTagName('h3')].map(tag =>
        tag.innerHTML
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/&nbsp;/g, '')
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
      );
      setChapterContents(prev => ({
        ...prev,
        children: [...h3title],
      }));
    } else {
      setChapterContents(prev => ({
        ...prev,
        children: [],
      }));
    }
  };

  const submitModChapterContent = async () => {
    const ckInstance = editorRef.current;
    if (ckInstance) {
      const editorData = ckInstance.editor.getData({ pagination: true });
      createContents(editorData);
      try {
        const response = await axiosAuth({
          method: 'post',
          url: '/api/modchapter/dev/contentnew',
          data: {
            dcnNumber: `DCN${dcn.number}`,
            ruleId: rule._id,
            oldChapterId: chapter._id,
            modType: 'replace',
            chapterType: chapter.chapterType,
            chapterIdx: chapter.chapterIdx,
            chapterNumber: chapter.chapterNumber,
            chapterName: chapter.chapterName,
            content: chapContent,
            lan: chapter.lan,
            chapterContents,
          },
        });
        if (response.status === 200) {
          toast(t('modrule.complete-draft'));
        }
      } catch (error) {
        toast(t('modrule.submit-chap-failed'));
      }
    } else {
      alert(t('modrule.edit-chap-error'));
    }
  };

  const submitAddFile = async () => {
    const formData = new FormData();
    formData.append('rulePath', rule.ruleNumber);
    formData.append('dcnNumber', `DCN${dcn.number}`);
    formData.append('ruleId', rule._id);
    formData.append('oldChapterId', chapter._id);
    formData.append('modType', 'replace');
    formData.append('chapterType', chapter.chapterType);
    formData.append('chapterIdx', chapter.chapterIdx);
    formData.append('chapterNumber', chapter.chapterNumber);
    formData.append('chapterName', chapter.chapterName);
    formData.append('lan', chapter.lan);
    formData.append('file', fileOne);

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/modchapter/dev/filenew',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      if (res.status === 200) {
        toast(t('modrule.complete-draft'));
        setChapter({
          _id: '',
          fileType: '',
          chapterNumber: '',
          chapterName: '',
        });
        setFileOne(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePreviewUpload = async e => {
    setFileOne(e.target.files[0]);
  };

  return (
    <div className='devdraft'>
      <div className='devdraft__side'>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='editor-lanbtn-kor'>
            {t('modrule.select-rule-lan')}
          </label>

          <ButtonGroup className='devdraft__side__lanradiobtn'>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={radio.value}
                id={`radio-${idx}`}
                type='radio'
                variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
                name='radio'
                value={radio.value}
                checked={lan === radio.value}
                onChange={e => setLan(e.currentTarget.value)}>
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>

        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='select-category'>
            {t('modrule.category')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.cate-placeholder')}</div>}
            isSearchable
            id='select-category'
            className='devdraft__side__category__select'
            options={options}
            onChange={handleCategoryValue}
          />
        </div>
        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='select-rule'>
            {t('modrule.rule')}
          </label>
          <Select
            styles={blackStyles}
            placeholder={<div>{t('modrule.rule-placeholder')}</div>}
            isSearchable
            id='select-rule'
            className='devdraft__side__rule__select'
            options={ruleOptions}
            onChange={handleRuleValue}
          />
        </div>

        <div className='devdraft__side__wrapper'>
          <label className='devdraft__label' htmlFor='input-rulenumber'>
            {t('modrule.rule')} No.
          </label>
          <input
            type='text'
            className='form-control input__rulenumber'
            id='input-rulenumber'
            placeholder='규정 No.'
            value={rule.ruleNumber || ''}
            onChange={onChangeRuleNumber}
            required
          />
        </div>
        <div className='devdraft__rule__wrapper'>
          <label className='devdraft__label' htmlFor='input-rulename'>
            {t('modrule.rule-title')}
          </label>
          <input
            type='text'
            className='form-control input-rulename'
            id='input-rulename'
            placeholder={t('modrule.rule-title')}
            value={rule.ruleName || ''}
            onChange={onChangeRuleName}
            required
          />
        </div>
        <div className='devdraft__rule__wrapper' style={{ display: 'flex' }}>
          <div style={{ width: '50%' }}>
            <label className='devdraft__label' htmlFor='input-rulename'>
              {t('modrule.old-rule')} REVISEDAT
              <DatePicker
                startdate={oldRuleDate || ''}
                onChangeDate={onChangeOldRuleDate}
                language={i18n.language}
                required
              />
            </label>
            <span> </span>
            <Button disabled={oldRuleDate === ''} onClick={createOldRuleSubmit}>
              {t('button.create')}
            </Button>
          </div>
          <div style={{ width: '50%' }}>
            <label className='devdraft__label' htmlFor='input-rulename'>
              {t('ruleCompare.latestVer')} REVISEDAT
              <DatePicker
                startdate={currRuleDate || ''}
                onChangeDate={onChangeCurrRuleDate}
                language={i18n.language}
                required
              />
            </label>
            <span> </span>

            <Button disabled={currRuleDate === ''} onClick={editCurrRuleDate}>
              {t('button.edit')}
            </Button>
          </div>
        </div>
        <div
          className='devdraft__side__chapterinfos'
          style={{ display: 'flex' }}>
          <div className='devdraft__side__chaptercontents'>
            <h5>{t('modrule.List')}</h5>
            <DevChapContents
              chapContent={chapContent}
              chapterContents={chapterContents}
              setChapterContents={setChapterContents}
            />
          </div>
          <div className='devdraft__side__dcnset'>
            <div>
              <Select
                styles={blackStyles}
                placeholder={<div>{t('modrule.select-dcn-placeholder')}</div>}
                isSearchable
                id='select-dcn'
                className='devdraft__side__category__select'
                options={dcns}
                onChange={handleDcn}
              />
              <Button disabled={dcn._id === undefined} onClick={createModRule}>
                MOD{t('button.create')}
              </Button>
            </div>
            <div>
              <Button
                disabled={rule?._id === ''}
                onClick={() => setDcnOpen(true)}>
                {t('modrule.connect-old-rule-dcn')}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className='devdraft__main'>
        <div className='devdraft__main__header'>
          <label className='devdraft__label' htmlFor='input-chapterdate'>
            MODTYPE
            <div>
              <Button
                disabled={chapter.modType === 'new'}
                onClick={() =>
                  setChapter(prev => ({ ...prev, modType: 'new' }))
                }>
                NEW
              </Button>
              <Button
                disabled={chapter.modType !== '' && chapter.modType !== 'new'}
                onClick={() =>
                  setChapter(prev => ({ ...prev, modType: 'replace' }))
                }>
                REPLACE
              </Button>
            </div>
          </label>
          <label className='devdraft__label' htmlFor='select-chapternumber'>
            {t('modrule.chap')}
            <Select
              styles={blackStyles}
              placeholder={<div>{t('revStatus.select-chap-placeholder')}</div>}
              isSearchable
              isDisabled={chapter.modType === 'new'}
              id='select-chapternumber'
              className='devdraft__side__category__select'
              options={chapterOptions}
              onChange={handleChapterValue}
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chapternumber'>
            {t('modrule.select-chap-num')}
            <input
              type='text'
              className='form-control input-chaptername'
              id='input-chapternumber'
              placeholder={t('modrule.select-chap-num')}
              value={(chapter.chapterNumber && chapter.chapterNumber) || ''}
              onChange={onChangeChapterNumber}
              required
            />
          </label>
          <label className='devdraft__label' htmlFor='input-chaptername'>
            {t('modrule.chap-title')}
            <input
              type='text'
              className='form-control input-chaptername'
              id='input-chaptername'
              placeholder={t('modrule.chap-title')}
              value={(chapter.chapterName && chapter.chapterName) || ''}
              onChange={onChangeChapterName}
              required
            />
          </label>
        </div>
        {['xls', 'xlsx', 'doc', 'docx', 'pdf'].some(
          el => el === chapter.fileType
        ) && (
          <div>
            <div>
              <Form.Group
                controlId='formFileMultiple'
                encType='multipart/form-data'
                className='mb-3 formContentTop__form'>
                {/* <Form.Control
                  type='file'
                  name='file'
                  multiple
                  onChange={handlePreviewUpload}
                /> */}
                <CustomFileInput
                  type='file'
                  name='file'
                  multiple
                  onChange={handlePreviewUpload}
                  style={{ width: '100%' }}
                />
                <Form.Text className='text-muted'>
                  {t('modrule.extension')} : doc / docx / xls / xlsx / pdf
                </Form.Text>
              </Form.Group>
              <Button onClick={submitAddFile}>MOD {t('modrule.file')}</Button>
              <Button type='submit' onClick={submitModChapterContent}>
                MOD {t('button.delete')}
              </Button>
            </div>
          </div>
        )}{' '}
        {chapter.fileType === 'content' && (
          <div className='devdraft__main__editor' id='document-container'>
            <div id='toolbar-container' className='toolbar-container' />
            <div id='editor-container' className='editor-container' />
            <CKEditor
              ref={editorRef}
              editor={Editor}
              data={chapContent}
              config={{
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: i18n.language === 'ko' ? '본문' : 'CONTENT',
                      class: 'ck-heading_paragraph',
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: i18n.language === 'ko' ? '제목 1' : 'TITLE 1',
                      class: 'ck-heading_heading2',
                    },
                    {
                      model: 'heading3',
                      view: 'h3',
                      title: i18n.language === 'ko' ? '제목 2' : 'TITLE 2',
                      class: 'ck-heading_heading3',
                    },
                    {
                      model: 'heading4',
                      view: 'h4',
                      title: i18n.language === 'ko' ? '제목 3' : 'TITLE 3',
                      class: 'ck-heading_heading4',
                    },
                  ],
                },
                extraPlugins: [uploadPlugin],
                autosave: {
                  save(editor) {
                    return setChapContent(editor.getData());
                  },
                  waitingTime: 3000,
                },
              }}
              onReady={editor => {
                const editorContainer =
                  document.querySelector('#editor-container');
                const toolbarContainer =
                  document.querySelector('#toolbar-container');

                toolbarContainer.appendChild(editor.ui.view.toolbar.element);
                editorContainer.appendChild(editor.ui.view.editable.element);

                editor.ui.update();
              }}
              onError={(error, editor) => {
                if (editor.willEditorRestart) {
                  editor.ui.view.toolbar.element.remove();
                }
                if (error) {
                  console.log(error);
                }
              }}
            />
          </div>
        )}
        {chapter.fileType === '' && <div>{t('modrule.select-chap')}</div>}
        <div className='devdraft__main__footer'>
          <Button
            type='submit'
            disabled={chapter.fileType !== 'content'}
            onClick={submitModChapterContent}>
            MOD {t('button.delete')}
          </Button>
          <Button
            type='submit'
            disabled={chapter.fileType !== 'content'}
            onClick={submitModChapterContent}>
            MOD {t('modrule.draft')}
          </Button>
          <Button
            type='submit'
            disabled={chapter.fileType !== 'content'}
            onClick={chapterEditSubmit}>
            {t('modrule.amend-draft')}
          </Button>
        </div>
      </div>
      {dcnOpen && <DevDcnSet open={dcnOpen} setOpen={setDcnOpen} rule={rule} />}
    </div>
  );
};

export default DevDraft;
