import React, { useRef, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MdOutlineKeyboardDoubleArrowLeft } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import useModRule from '../../../common/hooks/useModRule';
import useModChapter from '../../../common/hooks/useModChapter';
import '../../../scss/ModRule/EnactRuleAddChapter.scss';
import useAxiosMultipart from '../../../common/hooks/useAxiosMultipart';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import EnactRuleFileDocViewer from '../EnactRule/EnactRuleFileDocViewer';
import CustomFileInput from '../../DatePicker/FileInput';

const AmendRuleAddFile = () => {
  const { modRule, setModRule } = useModRule();
  const { modChapter, setModChapter } = useModChapter();
  const [file, setFile] = useState();
  const prevFileUrl = useRef('');
  const [uploading, setUploading] = useState(false);
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const { t, i18n } = useTranslation();

  const handlePreviewUpload = async e => {
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/approval/file/preview',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      prevFileUrl.current = res.data.url;
      setModChapter(prev => ({
        ...prev,
        uri: res.data.url,
        fileType: res.data.docType,
      }));
    } catch (err) {
      toast(t('modrule.preview-failed'));
    }
  };

  //* 챕터 1건 추가
  const submitAddFile = async () => {
    if (modChapter.chapterNumber === `${modRule.ruleNumber}-`) {
      toast(t('modrule.same-rule-chap'));
      return;
    }
    if (!modChapter.chapterNumber.includes(modRule.ruleNumber)) {
      toast(t('modrule.includes-rule-chap'));
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append('rulePath', modRule.ruleNumber);
    formData.append('modRuleId', modRule._id);
    formData.append('modType', modChapter.modType);
    formData.append('chapterType', modChapter.chapterType);
    formData.append('chapterIdx', modChapter.chapterIdx);
    formData.append('chapterNumber', modChapter.chapterNumber);
    formData.append('chapterName', modChapter.chapterName);
    formData.append('lan', modRule.lan);
    formData.append('file', file);

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/approval/chapter/newfile',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setModRule(prev => ({ ...prev, ...res.data }));

      setModChapter({
        fileType: '',
        modType: '',
        chapterType: '',
        content: '',
        uri: '',
        chapterIdx: 0,
        chapterNumber: '',
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      setUploading(false);
    } catch (err) {
      toast(t('modrule.add-chap-failed'));
      setUploading(false);
    }
  };

  //* 챕터 1건 삭제
  const submitDeleteFile = async () => {
    if (window.confirm(t('modrule.del-chap-q'))) {
      setUploading(true);

      try {
        const res = await axiosAuth({
          method: 'DELETE',
          url: '/api/approval/chapter/deletefile/amend',
          data: {
            modRuleId: modRule._id,
            chapterId: modChapter._id,
          },
        });
        setModRule(prev => ({ ...prev, ...res.data }));
        setModChapter({
          fileType: '',
          modType: '',
          chapterType: '',
          content: '',
          uri: '',
          chapterIdx: 0,
          chapterNumber: '',
          chapterName: '',
          chapterContents: {
            header: '',
            children: [],
          },
        });
        setUploading(false);
      } catch (err) {
        toast(t('modrule.del-chap-error'));
        setUploading(false);
      }
    } else {
      toast(t('modrule.del-chap-cancel'));
    }
  };

  //* 챕터 1건 개정
  const submitReplaceFile = async () => {
    if (modChapter.chapterNumber === `${modRule.ruleNumber}-`) {
      toast(t('modrule.same-rule-chap'));
      return;
    }
    if (!modChapter.chapterNumber.includes(modRule.ruleNumber)) {
      toast(t('modrule.includes-rule-chap'));
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append('rulePath', modRule.ruleNumber);
    formData.append('oldChapterId', modChapter._id);
    formData.append('modRuleId', modRule._id);
    formData.append('modType', modChapter.modType);
    formData.append('chapterType', modChapter.chapterType);
    formData.append('chapterIdx', modChapter.chapterIdx);
    formData.append('chapterNumber', modChapter.chapterNumber);
    formData.append('chapterName', modChapter.chapterName);
    formData.append('lan', modRule.lan);
    formData.append('file', file);

    try {
      const res = await axiosMultipart({
        method: 'POST',
        url: '/api/approval/chapter/newfile',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      setModRule(prev => ({ ...prev, ...res.data }));
      setModChapter({
        fileType: '',
        modType: '',
        chapterType: '',
        content: '',
        uri: '',
        chapterIdx: 0,
        chapterNumber: '',
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      setUploading(false);
    } catch (err) {
      toast(t('modrule.edit-chap-failed'));
      setUploading(false);
    }
  };

  //* 개정등록한 챕터 수정
  const submitEditFile = async () => {
    if (modChapter.chapterNumber === `${modRule.ruleNumber}-`) {
      toast(t('modrule.same-rule-chap'));
      return;
    }
    if (!modChapter.chapterNumber.includes(modRule.ruleNumber)) {
      toast(t('modrule.includes-rule-chap'));
      return;
    }
    if (prevFileUrl === modChapter.uri) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/modrule/chapter/fileinfoedit',
          data: {
            modRuleId: modRule._id,
            modChapterId: modChapter._id,
            chapterType: modChapter.chapterType,
            chapterIdx: modChapter.chapterIdx,
            chapterNumber: modChapter.chapterNumber,
            chapterName: modChapter.chapterName,
          },
        });
        toast(
          `${t('modrule.chap')} ${modChapter.chapterNumber} ${t(
            'modrule.edit-complete'
          )}`
        );
        setModRule(prev => ({ ...prev, ...res.data }));
        setModChapter({
          fileType: '',
          modType: '',
          chapterType: '',
          content: '',
          uri: '',
          chapterIdx: 0,
          chapterNumber: '',
          chapterName: '',
          chapterContents: {
            header: '',
            children: [],
          },
        });
        setUploading(false);
      } catch (err) {
        toast(t('modrule.edit-chap-failed'));
        setUploading(false);
      }
      return;
    }
    setUploading(true);
    const formData = new FormData();
    formData.append('rulePath', modRule.ruleNumber);
    formData.append('modRuleId', modRule._id);
    formData.append('modChapterId', modChapter._id);
    formData.append('chapterType', modChapter.chapterType);
    formData.append('chapterIdx', modChapter.chapterIdx);
    formData.append('chapterNumber', modChapter.chapterNumber);
    formData.append('chapterName', modChapter.chapterName);
    formData.append('prevUrl', prevFileUrl.current);
    formData.append('file', file);

    try {
      const res = await axiosMultipart({
        method: 'PUT',
        url: '/api/approval/chapter/editfile',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData,
      });
      // edit의 경우 modChapter 초기화만 필요함
      toast(
        `${t('modrule.chap')} ${res.data.chapterNumber} ${t(
          'modrule.edit-complete'
        )}`
      );
      setModRule(prev => ({ ...prev, ...res.data }));
      setModChapter({
        fileType: '',
        modType: '',
        chapterType: '',
        content: '',
        uri: '',
        chapterIdx: 0,
        chapterNumber: '',
        chapterName: '',
        chapterContents: {
          header: '',
          children: [],
        },
      });
      setUploading(false);
    } catch (err) {
      toast(t('modrule.edit-chap-failed'));
      setUploading(false);
    }
  };

  if (modRule.viewType === 'contents') {
    return (
      <div>
        {i18n.language === 'ko' ? (
          <>
            <p>텍스트 타입의 규정에는 파일 챕터를 등록할 수 없습니다.</p>
            <p>
              규정타입을
              <span style={{ color: 'orange' }}> 파일</span> 혹은
              <span style={{ color: 'orange' }}> 혼합</span>으로 변경하고
              등록해주세요.
            </p>
          </>
        ) : (
          <>
            <p>You cannot register file chapters for text-type regulations.</p>
            <p>
              Please change the regulation type to
              <span style={{ color: 'orange' }}> File</span> or
              <span style={{ color: 'orange' }}> Mixed</span> and register it.
            </p>
          </>
        )}
      </div>
    );
  }

  return (
    <div className='addfile'>
      <div className='addfile__side'>
        <div className='addfile__side__submitbtn'>
          {(() => {
            if (modChapter.oldChapterId && modChapter.oldChapterId !== '') {
              return (
                <Button style={{ width: '100%' }} onClick={submitEditFile}>
                  {uploading && (
                    <Spinner animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  )}
                  <span className='chapBtnSvg'>
                    <MdOutlineKeyboardDoubleArrowLeft />
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </span>
                  {t('modrule.edit-amend')}
                </Button>
              );
            }

            if (modChapter.modType === 'new') {
              return (
                <Button style={{ width: '100%' }} onClick={submitAddFile}>
                  {uploading && (
                    <Spinner animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  )}
                  <span className='chapBtnSvg'>
                    <MdOutlineKeyboardDoubleArrowLeft />
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </span>
                  {t('modrule.create-chap')}
                </Button>
              );
            }
            return (
              <>
                <Button style={{ width: '100%' }} onClick={submitReplaceFile}>
                  {uploading && (
                    <Spinner animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  )}
                  <span className='chapBtnSvg'>
                    <MdOutlineKeyboardDoubleArrowLeft />
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </span>
                  {t('modrule.amend-chap')}
                </Button>
                <Button style={{ width: '100%' }} onClick={submitDeleteFile}>
                  {uploading && (
                    <Spinner animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  )}
                  <span className='chapBtnSvg'>
                    <MdOutlineKeyboardDoubleArrowLeft />
                    <MdOutlineKeyboardDoubleArrowLeft />
                  </span>
                  {t('modrule.del-chap-btn')}
                </Button>
              </>
            );
          })()}
        </div>
      </div>
      <div className='addfile__fileinput'>
        <div>
          <Form.Group
            controlId='formFileMultiple'
            encType='multipart/form-data'
            className='mb-3 formContentTop__form'>
            {/* <Form.Control
              type='file'
              name='file'
              multiple
              onChange={handlePreviewUpload}
            /> */}
            <CustomFileInput
              type='file'
              name='file'
              multiple
              onChange={handlePreviewUpload}
              style={{ width: '100%' }}
            />
            <Form.Text className='text-muted'>
              {t('modrule.extension')} : doc / docx / xls / xlsx / pdf
            </Form.Text>
          </Form.Group>
        </div>
        <div className='addfile__previewer'>
          {modChapter?.uri && modChapter.uri !== '' && (
            <EnactRuleFileDocViewer
              propUri={modChapter.uri}
              propFileType={modChapter.fileType}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AmendRuleAddFile;
