import React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../scss/Reference/ReferenceMain.scss';

const ReferenceMain = () => {
  const { t } = useTranslation();

  return (
    <div className='reference mainsection'>
      <h4>{t('reference.title')}</h4>
      <Outlet />
    </div>
  );
};

export default ReferenceMain;
