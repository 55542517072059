import React, { createContext, useState, useMemo } from 'react';

const initialState = {
  data: [],
  count: 0,
};

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notis, setNotis] = useState(initialState);
  const value = useMemo(() => ({ notis, setNotis }), [notis]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
