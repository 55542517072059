import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import '../scss/RuleInfo/RuleBreadCrumb.scss';

const BreadCrumb = ({ breadCrumbsData }) => {
  const { t } = useTranslation();
  const { state, pathname } = useLocation();
  const [breadCrumbs, setBreadCrumbs] = useState([]);

  useEffect(() => {
    if (state) {
      const { _id, ruleNumber, ruleName, refCategory, viewType } = state;
      const breadCrumbsArr = [];
      breadCrumbsArr.push({
        path: 'category',
        _id: refCategory._id,
        targetNumber: refCategory?.id.categoryNumber,
        name: `[${refCategory.id.categoryNumber}] ${refCategory.category}`,
        active: '',
      });
      breadCrumbsArr.push({
        path: viewType,
        _id,
        targetNumber: ruleNumber,
        name: `[${ruleNumber}] : ${ruleName}`,
        active: '',
      });
      setBreadCrumbs(breadCrumbsArr);
    }
  }, [breadCrumbsData]);

  return (
    <nav className='breadcrumbs' aria-label='breadcrumb'>
      <ol className='breadcrumb breadcrumb-line text-mute fw-bold'>
        <li className='breadcrumb-item'>
          <Link to='/ruleinfo'>{t('ruleBreadCrumb.ruleInfo')}</Link>
        </li>
        {breadCrumbs.map(breadCrumb => (
          <li
            className={`breadcrumb-item ${breadCrumb.active}`}
            key={`${breadCrumb.path}-${breadCrumb._id}`}>
            <Link
              to={`/ruleinfo/${breadCrumb.path}/${breadCrumb.targetNumber}`}>
              {breadCrumb.name}
            </Link>
          </li>
        ))}
        {pathname.includes('compare') && (
          <li className='breadcrumb-item active'>
            <Link to='/ruleinfo'>{t('ruleBreadCrumb.compare')}</Link>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default BreadCrumb;
