import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiUndo } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import ReferenceSubMenu from './ReferenceSubMenu';
import '../../scss/Reference/Reference.scss';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import ModRuleOneEnact from '../ModRule/ModRuleOne/ModRuleOneEnact';
import ModRuleOneAmend from '../ModRule/ModRuleOne/ModRuleOneAmend';

const Reference = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const navi = useNavigate();
  const { id } = useParams();
  const [modRule, setModRule] = useState();
  const [oldChapterRef, setOldChapterRef] = useState([]);
  const [newChapterRef, setNewChapterRef] = useState([]);
  const [changeCommentBox, setchangeCommentBox] = useState([]);
  const [update, setUpdate] = useState(false);

  const goBack = () => {
    navi(-1);
  };

  useEffect(() => {
    const modRules = async () => {
      try {
        const res = await axiosAuth({
          method: 'GET',
          url: `/api/modrule/${id}`,
        });
        setModRule(res.data);
        setOldChapterRef(res.data[1] ? res.data[1].chapterRef : []);
        setNewChapterRef(res.data[2].chapterRef);
        setUpdate(false);
      } catch (err) {
        console.log(err);
      }
    };
    modRules();
  }, [id, update]);

  const changeComment = e => {
    setchangeCommentBox(e.target.value);
  };
  const commentEdit = async el => {
    const currentCommentId = el._id;
    if (window.confirm(t('reference.edit-comment-q'))) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/modrule/comment/eidt`,
          data: {
            ruleModId: modRule && modRule[2]._id,
            commentId: currentCommentId,
            comments: [{ userId: auth.id, comment: changeCommentBox }],
          },
        });
        if (res.status === 200) {
          toast(t('reference.edit-comment'));
        }
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };
  const commentDel = async el => {
    const currentCommentId = el._id;
    if (window.confirm(t('reference.del-comment-q'))) {
      try {
        const res = await axiosAuth({
          method: 'POST',
          url: `/api/modrule/comment/del`,
          data: {
            ruleModId: modRule && modRule[2]._id,
            commentId: currentCommentId,
          },
        });
        if (res.status === 200) {
          toast(t('reference.del-comment'));
        }
        setUpdate(!update);
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <div className='reference'>
      <div className='reference__subContent'>
        <div className='reference__subContent__left'>
          <BiUndo type='button' onClick={goBack} size={32} />
        </div>
        <div className='reference__subContent__right'>
          <ReferenceSubMenu
            modRule={modRule}
            update={update}
            setUpdate={setUpdate}
          />
        </div>
      </div>
      <div className='reference__mainBody'>
        <section className='reference__table'>
          <Table className='text-center'>
            <thead>
              <tr>
                <td>{t('reference.status')}</td>
                <td>{t('reference.category')}</td>
                <td>{t('reference.ruleName')}</td>
                <td>{t('reference.draftType')}</td>
                <td>{t('reference.draft-date')}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width='10%'>
                  {(() => {
                    switch (true) {
                      case modRule && modRule[2].status === 'examine':
                        return <span>{t('reference.under-review')}</span>;
                      case modRule && modRule[2].status === 'hold':
                        return (
                          <span style={{ color: 'orangered' }}>
                            {t('reference.rejected')}
                          </span>
                        );
                      case modRule && modRule[2].status === 'approve':
                        return <span>{t('reference.pending-approval')}</span>;
                      case modRule &&
                        modRule[2].status === 'preRevision' &&
                        modRule &&
                        modRule[2].classification === 'enact':
                        return <span>{t('reference.pending-enact')}</span>;
                      case modRule &&
                        modRule[2].status === 'preRevision' &&
                        modRule &&
                        modRule[2].classification === 'amend':
                        return <span>{t('reference.pending-amend')}</span>;
                      default:
                        return <span>{t('reference.submit-draft')}</span>;
                    }
                  })()}
                </td>
                <td width='25%'>
                  {modRule
                    ? `[${modRule && modRule[0].categoryNumber}] ${
                        modRule && modRule[0].category
                      }`
                    : t('reference.set-no-category')}
                </td>
                <td width='40%'>
                  {modRule
                    ? `[${modRule[2].refRuleId.ruleNumber}] ${modRule[2].refRuleId.ruleName}`
                    : t('reference.set-no-ruleName')}
                </td>
                <td width='10%'>
                  {modRule && modRule[2].classification === 'enact'
                    ? t('reference.enact')
                    : t('reference.amend')}
                </td>
                <td width='10%'>
                  {modRule && modRule[2].createdAt.slice(0, 10)}
                </td>
              </tr>
            </tbody>
          </Table>
        </section>
        <section className='reference__summary'>
          {modRule && modRule[2].revStatus}
        </section>
        <section className='reference__content'>
          {modRule && modRule[2].classification === 'amend' ? (
            <ModRuleOneAmend
              oldChapterRef={oldChapterRef}
              newChapterRef={newChapterRef}
            />
          ) : (
            <ModRuleOneEnact newChapterRef={newChapterRef} />
          )}
        </section>
        <section className='commentTable'>
          <div className='commentTable__thead'>
            <span>
              {t('reference.comment-history')}(
              {modRule && modRule[2].comments.length})
            </span>
          </div>
          <div className='commentTable__tbody'>
            {modRule &&
              modRule[2].comments.map(el => (
                <div key={el._id}>
                  <span>{el.createdAt.slice(0, 10)}</span>
                  <span>{el.userId.username}</span>
                  {el.userId._id === auth.id ? (
                    <span className='targetComment'>
                      <input
                        onChange={changeComment}
                        defaultValue={el.comment}
                        style={{ border: 'none', width: '31vw' }}
                      />
                      <Button
                        variant='secondary'
                        style={{ color: '#aaa' }}
                        onClick={() => commentEdit(el)}>
                        {t('button.edit')}
                      </Button>
                      <Button
                        variant='secondary'
                        style={{ color: 'orangered' }}
                        onClick={() => commentDel(el)}>
                        {t('button.delete')}
                      </Button>
                    </span>
                  ) : (
                    <span>{el.comment}</span>
                  )}
                </div>
              ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Reference;
