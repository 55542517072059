import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import OrgPosis from './OrgPosis';
import '../../scss/GroupChart/OrgPositionPage.scss';

const OrgPositionPage = () => {
  const axiosAuth = useAxiosAuth();
  const [posiList, setPosiList] = useState([]);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const fetch = async () => {
      setLoading(true);
      await axiosAuth
        .get('/api/position/all')
        .then(res => {
          const parsingData = res.data.map(el => ({
            ...el,
            el_id: el._id,
          }));
          setPosiList(parsingData);
          setLoading(false);
        })
        .catch(err => console.log(err));
    };
    fetch();
    return () => {
      controller.abort();
    };
  }, [update]);

  // 리스트 변경이 있으면 변경중 띄울 것
  // 리스트 변경이 있으면 변경 버튼이 활성화 될 것

  return (
    <div className='positionpage'>
      {!loading ? (
        <div className='positionpage__wrapper'>
          <OrgPosis posiList={posiList} setUpdate={setUpdate} />
        </div>
      ) : (
        <div className='positionpage__spinner'>
          <Spinner className='m-5' style={{ width: '4rem', height: '4rem' }} />
        </div>
      )}
    </div>
  );
};

export default OrgPositionPage;
