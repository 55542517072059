import { useContext, useDebugValue } from 'react';
import LogContext from '../../store/LogProvider';

const useLog = () => {
  const { log, setLog } = useContext(LogContext);
  useDebugValue(log, setLog, el => (el ? 'ondata' : 'offdata'));
  return useContext(LogContext);
};

export default useLog;
