import React from 'react';
import { Outlet } from 'react-router-dom';
import { CompareProvider } from '../../store/CompareProvider';
import '../../scss/Compare/Compare.scss';

const initialState = {
  oldRule: {},
  dcnNumber: '',
  viewType: '',
  type: '',
  classification: '',
  lan: '',
  revstatus: [],
  refCategory: {},
  ruleNumber: '',
  ruleName: '',
  modChapterRef: [],
  revisedAt: '',
};

const Compare = () => {
  return (
    <div className='compare mainsection'>
      <CompareProvider initialState={initialState}>
        <Outlet />
      </CompareProvider>
    </div>
  );
};

export default Compare;
