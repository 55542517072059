import { useContext, useDebugValue } from 'react';
import AuthContext from '../../store/AuthProvider';

const useAuth = () => {
  const { auth } = useContext(AuthContext);
  useDebugValue(auth, el => (el ? 'OnLine' : 'OffLine'));
  return useContext(AuthContext);
};

export default useAuth;
