import axios from 'axios';
import jwtDecode from 'jwt-decode';
import useAuth from './useAuth';
import useLogout from './useLogout';

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const logout = useLogout();

  const refresh = async () => {
    await axios
      .get('/api/refresh', {
        withCredentials: true,
      })
      .then(res => {
        const user = jwtDecode(res.data.accessToken);
        setAuth(prev => ({
          ...prev,
          ...user,
          roles: res.data.roles,
          accessToken: res.data.accessToken,
        }));
        return res.data.accessToken;
      })
      .catch(err => {
        if (err) {
          console.log(err);
          logout();
        }
      });
  };
  return refresh;
};

export default useRefreshToken;
