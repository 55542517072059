import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BiUndo } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import { MdOutlineFileDownload } from 'react-icons/md';
import Select from 'react-select';
import { toast } from 'react-toastify';
import '../../scss/userSignUp/GroupEdit.scss';
import blackStyles from '../../common/utils/SelectStyles';
import useAuth from '../../common/hooks/useAuth';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import useAxiosMultipart from '../../common/hooks/useAxiosMultipart';
import CustomFileInput from '../DatePicker/FileInput';

const GroupEdit = () => {
  const navi = useNavigate();
  const { auth } = useAuth();
  const axiosAuth = useAxiosAuth();
  const axiosMultipart = useAxiosMultipart();
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [depList, setDepList] = useState([]);
  const [currentDep, setCurrentDep] = useState('');
  const [userEdit, setUserEdit] = useState({
    username: '{이름}*',
    email: '{ID(이메일)}*',
    employno: '{사번}',
    hiredate: '{입사일}',
    position: '{직위}',
    officecontact: '{사내전화}',
    contact: '{휴대전화}',
    org: '{소속조직}*',
  });

  const goBack = () => {
    navi('/managerauth');
  };

  useEffect(() => {
    const fetchOrgs = async () => {
      await axiosAuth
        .get(`/api/org/all/${auth.company}`)
        .then(res => {
          const deplistCopy = res.data.map(el => ({
            label: el.name,
            value: el._id,
          }));
          setDepList(deplistCopy);
        })
        .catch(err => console.log(err));
    };
    fetchOrgs();
  }, []);

  const editList = e => {
    const { name, checked } = e.target;
    // 체크박스 체크 해제시 : false
    if (checked === false) {
      // *이 붙었을 때
      if (userEdit[name].includes('*')) {
        setUserEdit({
          ...userEdit,
          [name]: `{${userEdit[name].replace('*', '}*')}`,
        });
      } else {
        setUserEdit({
          ...userEdit,
          [name]: `{${userEdit[name]}}`,
        });
      }
    }
    // 체크박스를 체크할 때 : true
    if (checked === true) {
      setUserEdit({
        ...userEdit,
        [name]: userEdit[name].replace('{', '').replace('}', ''),
      });
    }
  };

  const targetDep = e => {
    setCurrentDep(e.value);
  };
  const clickFormDown = async () => {
    // 다운로드시 국/영문
    try {
      const response = await axiosAuth({
        method: 'post',
        url: '/api/auth/editfileDownload',
        responseType: 'blob',
        data: {
          field: Object.values(userEdit),
          org: currentDep,
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${t('components.userSignup.xlsx-update')}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);
    }
  };

  const currentFile = e => {
    setFile(e.target.files[0]);
  };

  const handleEditUpload = async () => {
    // 다운로드시 등록시 국영문 (필요없을 수도)
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axiosMultipart.post(
        '/api/auth/allEditUsers',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='groupEdit mainsection'>
      <h4>{t('groupEdit.title')}</h4>
      <BiUndo type='button' onClick={goBack} />
      <section className='groupEdit__contents'>
        <div className='groupEdit__contents__orderList'>
          <ul>
            <h5>{t('groupEdit.title')}</h5>
            <li>
              <span>STEP 1. </span> {t('groupEdit.step1')}
            </li>
            <li>
              <span>STEP 2. </span> {t('groupEdit.step2')}
            </li>
            <li>
              <span>STEP 3. </span> {t('groupEdit.step3')}
            </li>
          </ul>
          <p>
            <BsCheck /> {t('groupEdit.check1')}
          </p>
          <p>
            <BsCheck /> {t('groupEdit.check2')}
          </p>
        </div>
        <div className='groupEdit__contents__uploadFile'>
          <div className='groupEdit__contents__uploadFile__form'>
            <span>{t('groupEdit.toBeModified')} </span>
            <div className='editList'>
              <Select
                styles={blackStyles}
                placeholder={<div>{t('groupEdit.pleaseselect')}</div>}
                isSearchable
                options={depList}
                onChange={targetDep}
              />
              <div className='editList__editFileList'>
                <div>
                  <span>{t('groupEdit.username')}</span>
                  <input
                    type='checkbox'
                    value='이름*'
                    name='username'
                    onChange={editList}
                  />
                </div>
                <div>
                  <span>{t('groupEdit.email')}</span>
                  <input
                    type='checkbox'
                    value='{ID(이메일)}*'
                    name='email'
                    disabled
                    readOnly
                    checked
                  />
                </div>
                <div>
                  <span>{t('groupEdit.employno')}</span>
                  <input
                    type='checkbox'
                    value='사번'
                    name='employno'
                    onChange={editList}
                  />
                </div>
                <div>
                  <span>{t('groupEdit.hiredate')}</span>
                  <input
                    type='checkbox'
                    value='입사일'
                    name='hiredate'
                    onChange={editList}
                  />
                </div>
                <div>
                  <span>{t('groupEdit.position')}</span>
                  <input
                    type='checkbox'
                    value='직위'
                    name='position'
                    onChange={editList}
                  />
                </div>
                <div>
                  <span>{t('groupEdit.officecontact')}</span>
                  <input
                    type='checkbox'
                    value='사내전화'
                    name='officecontact'
                    onChange={editList}
                  />
                </div>
                <div>
                  <span>{t('groupEdit.contact')}</span>
                  <input
                    type='checkbox'
                    value='휴대전화'
                    name='contact'
                    onChange={editList}
                  />
                </div>
                <div>
                  <span>{t('groupEdit.org')}</span>
                  <input
                    type='checkbox'
                    value='소속조직*'
                    name='org'
                    onChange={editList}
                  />
                </div>
              </div>
              <div className='editList__editFileBtn'>
                <span>{t('groupEdit.emailNoEdit')}</span>
                <Button
                  // variant='secondary'
                  onClick={clickFormDown}>
                  {t('groupEdit.fileDownload')}
                  <MdOutlineFileDownload size={24} />
                </Button>
              </div>
            </div>
          </div>
          <div className='groupEdit__contents__uploadFile__inputBox'>
            <Form.Group
              controlId='formFileMultiple'
              encType='multipart/form-data'
              className='mb-3 noticeEdit__main__sub__file__form'>
              <Form.Label>{t('groupEdit.fileUpload')} </Form.Label>

              <CustomFileInput
                onChange={currentFile}
                type='file'
                name='file'
                multiple
                style={{ width: '400px', marginTop: '10px' }}
              />
            </Form.Group>
            <div className='saveBtn'>
              <Button onClick={handleEditUpload}>{t('button.update')}</Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GroupEdit;
