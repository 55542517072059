import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import {
  BsFillPlusCircleFill,
  BsFillXCircleFill,
  BsPencilSquare,
} from 'react-icons/bs';
import useAxiosAuth from '../../../common/hooks/useAxiosAuth';
import '../../../scss/ModRule/AmendRuleRevStatus.scss';
import useModRule from '../../../common/hooks/useModRule';
import useDcn from '../../../common/hooks/useDcn';

const RevStatusInputEdit = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const axiosAuth = useAxiosAuth();
  const selectedIndex = useRef(0);
  const nextSummId = useRef(1);
  const nextChapId = useRef(1);
  const { t } = useTranslation();
  const { dcn } = useDcn();
  const { modRule, setModRule } = useModRule();
  const [inputType, setInputType] = useState('new');
  const [chapterItems, setChapterItems] = useState([{ id: 0, text: '' }]);
  const [summaryItems, setSummaryItems] = useState([{ id: 0, text: '' }]);

  const deleteRevstatus = i => {
    const revstatusCopy = modRule.revstatus;
    revstatusCopy.splice(i, 1);
    setModRule({ ...modRule, revstatus: revstatusCopy });
  };

  const addChapterInput = () => {
    const item = {
      id: nextChapId.current,
      text: '',
    };
    setChapterItems([...chapterItems, item]);
    nextChapId.current += 1;
  };

  const deleteChapterInput = target => {
    setChapterItems(chapterItems.filter(item => item.id !== target));
  };

  const handleChapterChange = (e, index) => {
    if (index > chapterItems.length) return;
    const { value } = e.target;
    setChapterItems(prev =>
      prev.map(one => (one.id === index ? { ...one, text: value } : one))
    );
  };

  const addSummaryInput = () => {
    const item = {
      id: nextSummId.current,
      text: '',
    };
    setSummaryItems([...summaryItems, item]);
    nextSummId.current += 1;
  };

  const deleteSummaryInput = target => {
    setSummaryItems(summaryItems.filter(item => item.id !== target));
  };

  const handleSummaryChange = (e, index) => {
    if (index > summaryItems.length) return;
    const { value } = e.target;
    setSummaryItems(prev =>
      prev.map(one => (one.id === index ? { ...one, text: value } : one))
    );
  };

  const addOneRevstatus = () => {
    const chapterArr = chapterItems.map(chap => chap.text);
    const summaryArr = summaryItems.map(summ => summ.text);
    const revstatusCopy = [...modRule.revstatus];
    revstatusCopy.push({
      chapter: chapterArr,
      summary: summaryArr,
    });
    setModRule(prev => ({
      ...prev,
      revstatus: revstatusCopy,
    }));
  };

  const selectRevStatus = index => {
    setInputType('edit');
    selectedIndex.current = index;
    const targetRS = modRule.revstatus[index];
    const chapterItemArr = targetRS.chapter.map((ch, i) => ({
      id: i,
      text: ch,
    }));
    setChapterItems(chapterItemArr);
    nextChapId.current = chapterItemArr.length;
    const summaryItemArr = targetRS.summary.map((su, i) => ({
      id: i,
      text: su,
    }));
    setSummaryItems(summaryItemArr);
    nextSummId.current = summaryItemArr.length;
  };

  const editOneRevstatus = () => {
    const targetIndex = selectedIndex.current;
    const chapterArr = chapterItems.map(chap => chap.text);
    const summaryArr = summaryItems.map(summ => summ.text);
    const revstatusCopy = [...modRule.revstatus];
    revstatusCopy.splice(targetIndex, 1, {
      chapter: chapterArr,
      summary: summaryArr,
    });
    setModRule(prev => ({
      ...prev,
      revstatus: revstatusCopy,
    }));
  };

  const openNewInput = () => {
    setInputType('new');
    setChapterItems([{ id: 0, text: '' }]);
    setSummaryItems([{ id: 0, text: '' }]);
    nextChapId.current = 1;
    nextSummId.current = 1;
  };

  const revEditSubmit = async () => {
    try {
      const response = await axiosAuth({
        method: 'POST',
        url: '/api/modrule/editrevstatus',
        data: {
          modRuleId: modRule._id,
          revstatus: modRule.revstatus,
        },
      });
      if (response.status === 200) {
        toast(`${modRule.ruleName} ${t('modrule.add-history')}`);
        setModRule(prev => ({ ...prev, revstatus: response.data.revstatus }));
      }
    } catch (error) {
      console.log(error);
      toast(t('modrule.add-history-failed'));
    }
  };

  return (
    <div className='revinput'>
      <div className='revinput__body'>
        <div className='revinput__body__tablecontainer'>
          <Table>
            <thead>
              <tr>
                <th width='10%'>DCN</th>
                <th width='15%'>{t('modrule.rev-date')}</th>
                <th width='35%'>{t('table.chap-num')}</th>
                <th width='40%'>{t('table.rev-sum')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{modRule.dcnNumber}</td>
                <td>
                  {timezoneFormat(
                    utcToZonedTime(
                      toDate(dcn.revisionTime, {
                        timeZone: 'UTC',
                      }),
                      timezone
                    ),
                    'yyyy-MM-dd',
                    { timeZone: timezone }
                  )}
                </td>
                {modRule.revstatus.length === 0 ? (
                  <td colSpan='2'>{t('modrule.add-rev-sum')}</td>
                ) : (
                  <td colSpan='2'>
                    <table
                      style={{
                        width: '100%',
                        wordWrap: 'break-word',
                        wordBreak: 'break-all',
                      }}>
                      {modRule.revstatus.map((rev, i) => (
                        <tr>
                          <td width='40%'>
                            {rev.chapter.map(chap => (
                              <p key={chap}>{chap}</p>
                            ))}
                          </td>
                          <td width='50%'>
                            {rev.summary.map(summ => (
                              <p key={summ}>{summ}</p>
                            ))}
                          </td>
                          <td width='10%'>
                            <BsPencilSquare
                              style={{ margin: '0 5px' }}
                              size={18}
                              onClick={() => selectRevStatus(i)}
                            />
                            <BsFillXCircleFill
                              style={{ margin: '0 5px' }}
                              size={18}
                              onClick={() => deleteRevstatus(i)}
                            />
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                )}
              </tr>
              <tr>
                <td />
                <td />
                <td>
                  <Button onClick={openNewInput}>+ NEW</Button>
                </td>
                <td />
              </tr>
            </tbody>
          </Table>
        </div>
        <div className='revinput__body__inputcontainer'>
          <div style={{ width: '10%', textAlign: 'center' }}>
            <div
              style={{
                padding: '3px 10px',
                border: `1px solid ${inputType === 'new' ? 'green' : 'orange'}`,
              }}>
              {inputType === 'new' ? 'NEW' : 'EDIT'}
            </div>
          </div>
          <div style={{ width: '35%' }}>
            {chapterItems.map(chap => (
              <div key={`chap_${chap.id}`} style={{ paddingBottom: '3px' }}>
                <span style={{ width: '10%', padding: '0 10px' }}>-</span>
                <input
                  style={{ width: '80%' }}
                  type='text'
                  onChange={e => handleChapterChange(e, chap.id)}
                  value={chap.text}
                />
                <span style={{ width: '10%', padding: '0 10px' }}>
                  {chap.id === 0 && (
                    <BsFillPlusCircleFill size={18} onClick={addChapterInput} />
                  )}
                  {chap.id > 0 && (
                    <BsFillXCircleFill
                      size={18}
                      onClick={() => deleteChapterInput(chap.id)}
                    />
                  )}
                </span>
              </div>
            ))}
          </div>
          <div style={{ width: '45%' }}>
            {summaryItems.map(summ => (
              <div key={`sum_${summ.id}`} style={{ paddingBottom: '3px' }}>
                <span
                  style={{
                    width: '5%',
                    display: 'inline-block',
                    padding: '0 10px',
                  }}>
                  -
                </span>
                <input
                  style={{ width: '90%' }}
                  type='text'
                  onChange={e => handleSummaryChange(e, summ.id)}
                  value={summ.text}
                />
                <span
                  style={{
                    width: '5%',
                    display: 'inline-block',
                    padding: '0 10px',
                  }}>
                  {summ.id === 0 && (
                    <BsFillPlusCircleFill size={18} onClick={addSummaryInput} />
                  )}
                  {summ.id > 0 && (
                    <BsFillXCircleFill
                      size={18}
                      onClick={() => deleteSummaryInput(summ.id)}
                    />
                  )}
                </span>
              </div>
            ))}
          </div>
          <div style={{ width: '10%', padding: '0' }}>
            {inputType === 'new' && (
              <Button style={{ float: 'right' }} onClick={addOneRevstatus}>
                {t('modrule.add-summary')}
              </Button>
            )}
            {inputType === 'edit' && (
              <Button style={{ float: 'right' }} onClick={editOneRevstatus}>
                {t('modrule.edit-summary')}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className='revinput__footer'>
        <Button onClick={revEditSubmit}>{t('button.submit')}</Button>
        <Button>{t('button.cancel')}</Button>
      </div>
    </div>
  );
};

export default RevStatusInputEdit;
