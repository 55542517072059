import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../scss/ManagerAuth/ManagerAuth.scss';
import AuthbyRule from './AuthbyRule';
import AuthbyUser from './AuthbyUser';
import AuthbyOrg from './AuthbyOrg';
import useAuth from '../../common/hooks/useAuth';
import Unauthorized from '../Unauthorized';
import AuthbyTeam from './AuthbyTeam';

const ManagerAuth = () => {
  const [authPage, setAuthPage] = useState('user');
  const { auth } = useAuth();
  const { t } = useTranslation();

  return (
    <div>
      {auth.role === 'role1' || auth.role === 'role4' ? (
        <div className='userauth mainsection'>
          <h4>{t('managerauth.title')}</h4>
          <section className='userauth__header'>
            <Button
              role='button'
              aria-hidden='true'
              className={`userauth__header__tabitem ${
                authPage === 'user' ? 'active' : ''
              }`}
              onClick={() => setAuthPage('user')}
              as={Link}>
              {t('managerauth.byuser')}
            </Button>
            <Button
              role='button'
              aria-hidden='true'
              className={`userauth__header__tabitem ${
                authPage === 'rule' ? 'active' : ''
              }`}
              onClick={() => setAuthPage('rule')}
              as={Link}>
              {t('managerauth.byrule')}
            </Button>
            <Button
              role='button'
              aria-hidden='true'
              className={`userauth__header__tabitem ${
                authPage === 'org' ? 'active' : ''
              }`}
              onClick={() => setAuthPage('org')}
              as={Link}>
              {t('managerauth.byorg')}
            </Button>
            <Button
              role='button'
              aria-hidden='true'
              className={`userauth__header__tabitem ${
                authPage === 'team' ? 'active' : ''
              }`}
              onClick={() => setAuthPage('team')}
              as={Link}>
              {t('managerauth.byTeam')}
            </Button>
          </section>
          {(() => {
            if (authPage === 'user') {
              return <AuthbyUser />;
            }
            if (authPage === 'rule') {
              return <AuthbyRule />;
            }
            if (authPage === 'team') {
              return <AuthbyTeam />;
            }
            return <AuthbyOrg />;
          })()}
        </div>
      ) : (
        <Unauthorized />
      )}
    </div>
  );
};

export default ManagerAuth;
