import React from 'react';
import { TbAlertTriangle } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import '../scss/Mobile/MobileUnauthorized.scss';
import { useTranslation } from 'react-i18next';

const MobileUnauthorized = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goBack = () => navigate(-1);

  return (
    <section className='mobileUnauthorized'>
      {/* <h1>Unauthorized</h1> */}
      <div className='mobileUnauthorized__icon'>
        <TbAlertTriangle color='rgb(241, 133, 70)' size={130} />
      </div>
      <div className='mobileUnauthorized__text'>
        <p>
          {t('unauthorized.sorry')}{' '}
          <span style={{ color: 'rgb(241, 133, 70)' }}>
            {t('unauthorized.access')}
          </span>
          {t('unauthorized.no')}
        </p>
        <p>{t('unauthorized.manager-inquiry')}</p>
      </div>
      <div className='mobileUnauthorized__backBtn'>
        <button type='button' onClick={goBack}>
          {t('unauthorized.back')}
        </button>
      </div>
    </section>
  );
};

export default MobileUnauthorized;
