import React from 'react';
import '../scss/Setting.scss';
import { Button, NavLink } from 'react-bootstrap';
import {
  HiOutlineDocumentSearch,
  HiOutlineExternalLink,
  HiOutlineShieldExclamation,
  HiOutlineQuestionMarkCircle,
} from 'react-icons/hi';
import { RiMailSendLine } from 'react-icons/ri';
import { TbCheckupList, TbLayoutGridAdd } from 'react-icons/tb';
import { MdOutlineFormatColorFill } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const Setting = () => {
  const { t } = useTranslation();
  return (
    <div className='setting mainsection'>
      <h4 className='setting__title'>{t('setting.title')}</h4>
      <section className='setting__main'>
        <div className='leftContents'>
          <div>
            <h5>{t('setting.version-info')}</h5>
            <div className='leftContents__contentsBox'>
              <div>
                <Button variant='secondary'>
                  <div>
                    <HiOutlineShieldExclamation />
                  </div>
                  <span>{t('setting.releasenote')}</span>
                </Button>
                <ul>
                  <li className='teaminfo'>{t('setting.nowupdating')}</li>
                </ul>
              </div>
              <div>
                <Button variant='secondary'>
                  <div>
                    <HiOutlineDocumentSearch />
                  </div>
                  <span>{t('setting.userguide')}</span>
                </Button>
                <ul>
                  <li className='teaminfo'>{t('setting.nowupdating')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <h5>{t('setting.tac')}</h5>
            <div className='leftContents__contentsBox'>
              <div>
                <Button variant='secondary'>
                  <div>
                    <TbCheckupList />
                  </div>
                  <span>{t('setting.tac-short')}</span>
                </Button>
                <ul>
                  <li className='teaminfo'>{t('setting.nowupdating')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='rightContents'>
          <div>
            <h5>{t('setting.settings')}</h5>
            <div className='rightContents__contentsBox'>
              <div>
                <Button variant='secondary'>
                  <div>
                    <MdOutlineFormatColorFill />
                  </div>
                  <span>{t('setting.theme')}</span>
                </Button>
                <ul>
                  <li>{t('setting.nowupdating')}</li>
                </ul>
              </div>
              <div>
                <Button variant='secondary'>
                  <div>
                    <TbLayoutGridAdd />
                  </div>
                  <span style={{ fontSize: '14px' }}>
                    {t('setting.quickmenu')}
                  </span>
                </Button>
                <ul>
                  <li>{t('setting.nowupdating')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <h5>{t('setting.contact')}</h5>
            <div className='rightContents__contentsBox'>
              <div>
                <Button variant='secondary'>
                  <div>
                    <HiOutlineQuestionMarkCircle />
                  </div>
                  <span>{t('setting.faq')}</span>
                </Button>
                <ul>
                  <li>{t('setting.nowupdating')}</li>
                </ul>
              </div>
              <div>
                <Button variant='secondary'>
                  <div>
                    <RiMailSendLine />
                  </div>
                  <span>{t('setting.mail')}</span>
                </Button>
                <ul>
                  <li className='teaminfo'>{t('setting.nowupdating')}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Setting;
