import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { Button, Modal } from 'react-bootstrap';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { toast } from 'react-toastify';
import {
  BsInfoCircle,
  BsLayoutSplit,
  BsPrinter,
  BsSquareHalf,
  BsDownload,
} from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import FormModModal from '../Forms/FormModModal';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
import '../../scss/RuleInfo/RuleMix.scss';
import { formatDate } from '../../common/utils/tzUtiles';

const RuleMix = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { t } = useTranslation();
  const { ruleNum, chapterNumber, searchText } = useParams();
  const [onclickModal, setOnclickModal] = useState(false);
  const [revInfoOpen, setRevInfoOpen] = useState(false);
  const [formInfo, setFormInfo] = useState({});
  const [formDocs, setFormDocs] = useState([]);
  const [activeDocument, setActiveDocument] = useState({
    content: '',
    fileType: '',
    uri: '',
  });
  const axiosAuth = useAxiosAuth();
  const navi = useNavigate();
  const viewerRef = useRef(null);
  //  챕터 내용 mark 스크롤 Ref
  const scrollRef = useRef(null);
  const buttonRef = useRef(null);

  const handleDocumentChange = async document => {
    // 부하 줄이기
    try {
      if (document) {
        const res = await axiosAuth({
          method: 'POST',
          url: '/api/chapter/accessform',
          data: {
            targetNumber: document.chapterNumber,
            targetName: document.chapterName,
          },
        });
        if (res.status === 200) {
          setActiveDocument(document);
          setOnclickModal(false);
        }
      }
    } catch (error) {
      toast.error(t('components.ruleInfo.error'));
      console.log(error);
    }
  };

  const handleTargetScroll = () => {
    if (scrollRef.current && scrollRef.current.hasChildNodes()) {
      if (searchText) {
        const bElements = document.getElementsByTagName('b');
        if (bElements.length > 0) {
          bElements[0].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
        }
      }
    }
  };

  const handleButtonScroll = () => {
    if (buttonRef.current) {
      buttonRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const replaceStrong = (text, con) => {
    const regex = new RegExp(text, 'gi');
    const cateIndex = con.toLowerCase().indexOf(text.toLowerCase());
    const originStr = con.slice(cateIndex, cateIndex + text.length);
    const contentStrong = con.replace(regex, `<b>${originStr}</b>`);
    return contentStrong;
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchRule = async () => {
      const response = await axiosAuth({
        method: 'GET',
        url: `api/rule/${ruleNum}`,
      });
      setFormInfo(response.data);
      const formDocsCopy = response.data.chapterRef;
      if (chapterNumber && searchText) {
        const targetindex = formDocsCopy.findIndex(
          el => el.chapterNumber === chapterNumber
        );
        const changeChap = {
          ...formDocsCopy[targetindex],
          content: replaceStrong(searchText, formDocsCopy[targetindex].content),
        };
        formDocsCopy.splice(targetindex, 1, changeChap);
        setFormDocs(formDocsCopy);
        setActiveDocument(formDocsCopy[targetindex]);
        handleTargetScroll();
        return;
      }

      if (chapterNumber) {
        const targetChapter = response.data.chapterRef.find(
          chap => chap.chapterNumber === chapterNumber
        );
        setFormDocs(formDocsCopy);
        setActiveDocument(targetChapter);
        handleButtonScroll();
        return;
      }
      setFormDocs(formDocsCopy);
      handleDocumentChange(formDocsCopy[0]);
    };
    fetchRule();

    return () => {
      controller.abort();
    };
  }, [ruleNum, chapterNumber]);

  const goFormDual = () => {
    navi('/formdual', {
      state: {
        lan: formInfo.lan,
        category: formInfo.refCategory.category,
        ruleNumber: formInfo.ruleNumber,
      },
    });
  };

  const goFormCompare = () => {
    navi(`/compare/${formInfo.ruleNumber}`, {
      state: {
        ...formInfo,
      },
    });
  };

  const printPdf = async () => {
    try {
      const res = await axiosAuth({
        method: 'POST',
        url: '/api/rule/print',
        data: {
          _id: formInfo._id,
          targetNumber: formInfo.ruleNumber,
          targetName: formInfo.ruleName,
        },
      });
      if (res.status === 200) {
        const revisedDate = timezoneFormat(
          utcToZonedTime(
            toDate(formInfo.revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        );

        let printTitle = `[${formInfo.ruleNumber}] ${formInfo.ruleName} > [${activeDocument.chapterNumber}] ${activeDocument.chapterName}`;
        if (printTitle.length > 75) {
          printTitle = `[${formInfo.ruleNumber}] ${formInfo.ruleName} > <br/> [${activeDocument.chapterNumber}] ${activeDocument.chapterName}`;
        }
        const footerText = `${t('rulePrint.text1')}<br/>${t(
          'rulePrint.text2'
        )}`;
        const contentDiv = document.getElementById('rulemix-printArea');
        const pri = document.getElementById(
          'print-data-container'
        ).contentWindow;
        pri.document.open();
        const html = `<html><head><title>${printTitle}</title><link rel="stylesheet" href="${process.env.PUBLIC_URL}/ck-styles.css" type="text/css"><link rel="stylesheet" href="${process.env.PUBLIC_URL}/printfont.css" type="text/css"></head><body><table><thead><tr><td><div class="header-space">&nbsp;</div></td></tr></thead><tbody><tr><td>${contentDiv.innerHTML}</td></tr></tbody>
        <tfoot><tr><td><div class="footer-space">&nbsp;</div></td></tr></tfoot></table><div class="header"><table><tbody><tr><td width="25%">TITLE</td><td width="75%">${printTitle}</td></tr><tr><td width="25%" class="header_first">Effective Date</td><td width="75%">${revisedDate}</td></tr></tbody></table></div><div class="footer"><div class="footer_imgcontainer"><img class="footer_img" src="${process.env.PUBLIC_URL}/images/hmm_watermark.png" alt="hmm footer image" /></div><div class="footer_disclaimer">${footerText}</div></div></body></html>`;
        pri.document.write(html);
        const pageDivs = pri.document.querySelectorAll(
          '.ck-content__pagebreak'
        );

        if (pageDivs) {
          const pageDivArr = Array.from(pageDivs);
          const watermarkDiv = pri.document.createElement('div');
          watermarkDiv.className = 'watermark';
          watermarkDiv.innerHTML = `<img
              class='watermark_img'
              src='${process.env.PUBLIC_URL}/images/hmm_watermark.png'
              alt='hmm watermark'
              style='opacity: 0.15;'
            />`;
          pageDivArr.forEach(div => {
            div.appendChild(watermarkDiv);
          });
        }

        const waitForResources = () => {
          return new Promise(resolve => {
            const checkResources = setInterval(() => {
              const styleSheetsLoaded = Array.from(
                pri.document.styleSheets
              ).every(ss => ss.href == null || ss.cssRules);
              const imagesLoaded = Array.from(pri.document.images).every(
                img => img.complete && img.naturalHeight !== 0
              );
              if (styleSheetsLoaded && imagesLoaded) {
                clearInterval(checkResources);
                resolve();
              }
            }, 500);
          });
        };
        pri.document.close();
        waitForResources().then(() => {
          // pri.open();
          pri.focus(); // Focus the iframe window
          pri.print(); // Invoke the print dialog
        });

        // pri.document.head.lastChild.addEventListener(
        //   'load',
        //   () => {
        //     pri.focus();
        //     pri.print();
        //   },
        //   0
        // );
      }
    } catch (err) {
      if (err.response.status === 401) {
        toast(t('rulePrint.permission-no-form'));
      }
      if (err.response.status === 500) {
        toast(t('rulePrint.error'));
      }
    }
  };

  const downloadDoc = async () => {
    try {
      const resDown = await axiosAuth({
        method: 'POST',
        url: '/api/chapter/download',
        responseType: 'blob',
        data: {
          targetNumber: activeDocument.chapterNumber,
          targetName: activeDocument.chapterName,
          fileUrl: activeDocument.uri,
        },
      });
      const blob = new Blob([resDown.data]);
      const fileName = `${activeDocument.chapterName}.${activeDocument.fileType}`;
      const downUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downUrl);
    } catch (err2) {
      console.log(`error resDown : ${err2}`);
    }
  };

  return (
    <div className='form'>
      <div className='form__header'>
        <div className='form__header__info'>
          <div className='form__header__title'>
            {formInfo &&
              formInfo.ruleNumber &&
              `[${formInfo.ruleNumber}] ${formInfo.ruleName}`}
          </div>
          <Button
            variant='secondary'
            style={{
              color: `${onclickModal ? '#f18546' : '#20265b'}`,
              border: 'none',
            }}
            onClick={() => setOnclickModal(prev => !prev)}>
            {t('button.rule-info')}
          </Button>
        </div>
        <div className='form__header__btns'>
          <Button
            className='btns__item'
            onClick={() => setRevInfoOpen(prev => !prev)}>
            <span className='hide__name'>{t('button.revision-info')}</span>
            <span className='hide__icon'>
              <BsInfoCircle />
            </span>
          </Button>
          {revInfoOpen ? (
            <Modal
              className='modal__revstatus'
              show={revInfoOpen}
              size='xl'
              scrollable
              onHide={setRevInfoOpen}
              backdrop='static'
              keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title className='modal__revstatus__title'>
                  {t('button.revision-info')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='modal__revstatus__body'>
                <FormModModal ruleNumber={formInfo.ruleNumber} />
              </Modal.Body>
            </Modal>
          ) : null}
          <Button className='btns__item' onClick={goFormDual}>
            <span className='hide__name'>{t('button.dual')}</span>
            <span className='hide__icon'>
              <BsLayoutSplit />
            </span>
          </Button>
          <Button className='btns__item' onClick={goFormCompare}>
            <span className='hide__name'>{t('button.compare')}</span>
            <span className='hide__icon'>
              <BsSquareHalf />
            </span>
          </Button>
          {activeDocument?.fileType === 'content' ? (
            <Button className='btns__item' onClick={printPdf}>
              <span className='hide__name'>{t('button.print')}</span>
              <span className='hide__icon'>
                <BsPrinter />
              </span>
            </Button>
          ) : (
            <Button className='btns__item' onClick={downloadDoc}>
              <span className='hide__name'>{t('button.down')}</span>
              <span className='hide__icon'>
                <BsDownload />
              </span>
            </Button>
          )}
        </div>
      </div>
      <div className='form__body'>
        <div className='form__body__sidetabs'>
          <div className='form__body__sidetabs__btns'>
            <div className='form__body__sidetabs__btns__wrapper'>
              {formDocs &&
                formDocs.length > 0 &&
                formDocs.map(formpart => (
                  <Button
                    key={formpart._id}
                    ref={formpart._id === activeDocument._id ? buttonRef : null}
                    onClick={() => handleDocumentChange(formpart)}
                    className={`form__body__sidetabs__item ${
                      activeDocument &&
                      activeDocument._id === formpart._id &&
                      !onclickModal
                        ? 'active'
                        : ''
                    }`}>
                    <span
                      style={{
                        color: formpart.version === 'cancelled' && 'gray',
                        textDecoration:
                          formpart.version === 'cancelled' &&
                          'line-through double #474747',
                      }}>
                      {`${formpart.chapterNumber.slice(
                        formpart.chapterNumber.indexOf(
                          '-',
                          formpart.chapterNumber.indexOf('-') + 1
                        ) + 1
                      )}: ${formpart.chapterName}`}
                    </span>
                  </Button>
                ))}
            </div>
          </div>
        </div>
        <div className='form__body__contents'>
          {onclickModal ? (
            <div className='form__body__contents__chapterBox'>
              <table className='table'>
                <thead>
                  <tr>
                    <td width='6%'>{t('table.index')}</td>
                    <td width='16%'>{t('table.code-num')}</td>
                    <td width='48%'>{t('table.title')}</td>
                    <td width='15%'>{t('table.revision-date')}</td>
                    <td width='15%'>{t('table.remark')}</td>
                  </tr>
                </thead>
                <tbody>
                  {formInfo.chapterRef &&
                    formInfo.chapterRef.map((el, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td align='left'>{el.chapterNumber}</td>
                        <td
                          style={{
                            textDecoration:
                              el.version === 'cancelled' && 'line-through red',
                          }}
                          align='left'>
                          {el.chapterName}
                        </td>
                        <td
                          style={{
                            color: el.version === 'cancelled' && 'red',
                          }}>
                          {el.version === 'cancelled'
                            ? 'cancelled'
                            : timezoneFormat(
                                utcToZonedTime(
                                  toDate(el.createdAt, {
                                    timeZone: 'UTC',
                                  }),
                                  timezone
                                ),
                                'yyyy-MM-dd',
                                { timeZone: timezone }
                              )}
                        </td>
                        <td>{el.reamark ? el.reamark : '-'}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              {activeDocument?.fileType === 'content' &&
              activeDocument?.content ? (
                <>
                  <div className='form__body__contents__title'>
                    <span style={{}}>
                      {activeDocument?.chapterNumber &&
                      activeDocument?.chapterName
                        ? `${activeDocument?.chapterNumber} : ${activeDocument?.chapterName}`
                        : t('components.ruleInfo.no-ruleData')}
                    </span>
                    <span>
                      {activeDocument.version === 'cancelled'
                        ? 'cancelled'
                        : `${t(
                            'components.ruleInfo.enactAmend'
                          )} : ${formatDate(activeDocument.createdAt)}`}
                    </span>
                  </div>
                  <div
                    id='rulemix-printArea'
                    ref={scrollRef}
                    className='form__body__contents__text'>
                    <div
                      key={activeDocument._id}
                      className='ck-content ck ck-content__pagebreak rulemix'>
                      {activeDocument.content &&
                        parse(activeDocument.content || '')}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='form__body__contents__title'>
                    <span
                      style={{
                        textDecoration:
                          activeDocument.version === 'cancelled' &&
                          'line-through',
                      }}>
                      {activeDocument?.chapterNumber &&
                      activeDocument?.chapterName
                        ? `${activeDocument?.chapterNumber} : ${activeDocument?.chapterName}`
                        : t('components.ruleInfo.no-ruleData')}
                    </span>
                    <span
                      style={{
                        color: activeDocument.version === 'cancelled' && 'red',
                      }}>
                      {activeDocument.version === 'cancelled'
                        ? 'cancelled'
                        : `${t(
                            'components.ruleInfo.enactAmend'
                          )} : ${formatDate(activeDocument.createdAt)}`}
                    </span>
                  </div>
                  <div className='form__body__contents__viewer'>
                    {activeDocument?._id && (
                      <DocViewer
                        key={activeDocument._id}
                        ref={viewerRef}
                        prefetchMethod='GET'
                        activeDocument={activeDocument}
                        documents={formDocs}
                        pluginRenderers={DocViewerRenderers}
                        onDocumentChange={handleDocumentChange}
                        config={{
                          header: {
                            disableHeader: true,
                          },
                          csvDelimiter: ',',
                          pdfZoom: {
                            defaultZoom: 0.9,
                          },
                          pdfVerticalScrollByDefault: true,
                        }}
                        className='docviewer__container'
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <iframe
        id='print-data-container'
        title='print-rule'
        aria-hidden='true'
        tabIndex='-1'
      />
    </div>
  );
};

export default RuleMix;
