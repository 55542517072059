import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../../scss/ModRule/EnactRule.scss';
// import history from '../../common/hooks/history';
import { useTranslation } from 'react-i18next';
import { ModChapterProvider } from '../../store/ModChapterProvider';
import ModRuleDraftHeader from './ModRuleDraftHeader';
import useModRule from '../../common/hooks/useModRule';
import AmendRuleSidelist from './AmendRule/AmendRuleSidelist';
import AmendRuleRightMain from './AmendRule/AmendRuleRightMain';
import ModRuleSortChapter from './ModRuleSortChapter';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';
// import useBlockNavi from '../../common/hooks/useBlockNavi';

const initialState = {
  fileType: '',
  modType: '',
  content: '',
  uri: '',
  chapterIdx: 0,
  chapterContents: {
    header: '',
    children: [],
  },
};

const AmendRule = () => {
  // const navi = useNavigate();
  // const location = useLocation();
  const { id } = useParams();
  const axiosAuth = useAxiosAuth();
  const { modRule, setModRule } = useModRule();
  const { t } = useTranslation();

  // useBlockNavi('dddddd');

  useEffect(() => {
    const handleBeforeUnload = e => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.onbeforeunload = handleBeforeUnload;

    window.onbeforeunload = handleBeforeUnload;
    window.onunload = () => {
      alert(t('modrule.close-browser'));
    };

    return () => {
      window.onbeforeunload = null;
      window.onunload = null;
    };
  }, []);

  // useEffect(() => {
  //   const listenBackEvent = () => {
  //     if (
  //       window.confirm(
  //         '페이지를 벗어나면 작성중인 데이터가 삭제됩니다. 페이지를 나가시겠습니까?'
  //       )
  //     ) {
  //       navi('/modrule/dcn');
  //     }
  //   };

  //   const historyEvent = history.listen(({ action }) => {
  //     if (action === 'POP') {
  //       listenBackEvent();
  //     }Z
  //   });
  //   return historyEvent;
  // }, []);

  useEffect(() => {
    if (id) {
      const modRules = async () => {
        try {
          const res = await axiosAuth({
            method: 'GET',
            url: `/api/modrule/${id}`,
          });
          setModRule(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      modRules();
    }
  }, [id]);

  return (
    <ModChapterProvider initialState={initialState}>
      <div className='enactrule'>
        <AmendRuleSidelist />
        <section className='enactrule__main'>
          <ModRuleDraftHeader />
          {modRule?._id && modRule.sortOpen ? (
            <ModRuleSortChapter />
          ) : (
            <AmendRuleRightMain />
          )}
        </section>
      </div>
    </ModChapterProvider>
  );
};

export default AmendRule;
