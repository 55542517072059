import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import parse from 'html-react-parser';
import diff_match_patch from 'diff-match-patch';
import { useTranslation } from 'react-i18next';
import blackStyles from '../../common/utils/SelectStyles';

const RevStatusAmendCompare = ({ oldChapterRef, newChapterRef }) => {
  const { t } = useTranslation();
  const [diffold, setDiffold] = useState('');
  const [diffnew, setDiffnew] = useState('');
  const [oldChap, setOldChap] = useState('');
  const [newChap, setNewChap] = useState('');
  const [newConList, setNewConList] = useState([]);

  useEffect(() => {
    if (newChapterRef.length > 0) {
      const optioncopy = newChapterRef.map(newc => ({
        label: newc.chapterNumber,
        value: newc,
      }));
      setNewConList(optioncopy);
    }
  }, [newChapterRef]);

  useEffect(() => {
    if (oldChap && newChap) {
      const dmp = new diff_match_patch();
      const diffs = dmp.diff_main(oldChap.content, newChap.content);
      dmp.diff_cleanupSemantic(diffs);
      let htmlold = '';
      let htmlnew = '';
      for (let i = 0; i < diffs.length; i += 1) {
        const diff = diffs[i];
        const [operation, text] = diff;
        if (operation === 0) {
          htmlold += text;
          htmlnew += text;
        } else if (operation === 1) {
          if (!text.includes('<img')) {
            htmlnew += `<ins>${text}</ins>`;
          } else {
            htmlnew += text.replace(
              '<img',
              `<img style='border: 4px solid green'`
            );
          }
        } else if (operation === -1) {
          if (!text.includes('<img')) {
            htmlnew += `<del style='background-color: red;'>${text}</del>`;
          } else {
            htmlnew += text.replace(
              '<img',
              `<img style='border: 4px solid red'`
            );
          }
        }
      }
      setDiffold(htmlold);
      setDiffnew(htmlnew);
    }
  }, [oldChap, newChap]);

  const handleChapter = e => {
    const oldCon = oldChapterRef.filter(
      el => el.chapterNumber === e.value.chapterNumber
    )[0];
    setNewChap(e.value);
    if (oldCon) {
      setOldChap(oldCon);
    } else {
      setOldChap({ content: t('revStatus.register-new') });
    }
  };

  return (
    <>
      <div className='revstatus__content__header'>
        <div className='revstatus__content__header__left'>
          <Select
            styles={blackStyles}
            isSearchable
            placeholder={t('revStatus.select-chap-placeholder')}
            options={newConList}
            onChange={handleChapter}
          />
          <span>{t('revStatus.amend-chap-num')} : </span>
          <span>{newChapterRef && newChapterRef.length}</span>
        </div>
      </div>
      <div className='revstatus__content__amend'>
        <div className='revstatus__content__amend__oldRule'>
          <div>
            <span>{diffold && parse(diffold)}</span>
          </div>
        </div>
        <div className='revstatus__content__amend__NewRule'>
          <span>{diffnew && parse(diffnew)}</span>
        </div>
      </div>
    </>
  );
};

export default RevStatusAmendCompare;
