import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import '../../scss/RuleInfo/RuleSearch.scss';
import parse from 'html-react-parser';
import { format as timezoneFormat, toDate, utcToZonedTime } from 'date-fns-tz';
import { Badge, Spinner } from 'react-bootstrap';
import { BiSubdirectoryRight } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import useAxiosAuth from '../../common/hooks/useAxiosAuth';

const parsingRuleResult = (result, searchText) => {
  const regex = new RegExp(searchText, 'i');

  const ruleIndex = result.ruleName
    .toLowerCase()
    .indexOf(searchText.toLowerCase());

  const originruleStr = result.ruleName.slice(
    ruleIndex,
    ruleIndex + searchText.length
  );

  return result.ruleName.replace(regex, `<mark>${originruleStr}</mark>`);
};

const parsingChapterContent = result => {
  const { texts } = result.highlights[0];
  const partContent = texts
    .map((text, i) => {
      if (text.type === 'hit') {
        return `<mark>${text.value
          .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
          .replace(/&nbsp;/g, '')}</mark>`;
      }
      if (text.type === 'text' && text.value.length > 50) {
        if (i === 0) {
          return text.value
            .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
            .replace(/&nbsp;/g, '')
            .slice(-60);
        }
        return text.value
          .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
          .replace(/&nbsp;/g, '')
          .slice(0, 60);
      }
      return text.value
        .replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
        .replace(/&nbsp;/g, '');
    })
    .join('');

  return partContent;
};

const parsingChapterName = result => {
  const { texts } = result.highlights[0];
  const originalLine = texts.map(text => text.value).join('');
  const replacements = texts.map(text => {
    if (text.type === 'hit') {
      return `<mark>${text.value}</mark>`;
    }
    return text.value;
  });
  return result.chapterName.replace(originalLine, replacements);
};

const ResultTr = ({ result, searchText }) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const regex = new RegExp(searchText, 'i');
  const { t } = useTranslation();

  if (result.search === 'category') {
    const cateIndex = result.category
      .toLowerCase()
      .indexOf(searchText.toLowerCase());
    const origincateStr = result.category.slice(
      cateIndex,
      cateIndex + searchText.length
    );
    const cateStrong = result.category.replace(
      regex,
      `<strong>${origincateStr}</strong>`
    );
    return (
      <tr>
        <td>{t('table.category')}</td>
        <td align='left'> </td>
        <td align='left'>
          <NavLink
            to={`/ruleinfo/category/${result._id}`}
            style={{ textDecoration: 'none', color: 'black' }}>
            {parse(cateStrong)}
          </NavLink>
        </td>
        <td className='text-center'>{result.updatedAt.slice(0, 10)}</td>
      </tr>
    );
  }

  if (result.search === 'chapterContent') {
    return (
      <tr>
        <td align='center'>{t('table.rule-content')}</td>
        <td align='left' className='tdrulename'>
          <NavLink
            to={
              result.rules[0]?.viewType === 'content'
                ? `/ruleinfo/contents/${result.rules[0]?.ruleNumber}/${result.chapterNumber}/${searchText}`
                : `/ruleinfo/mix/${result.rules[0]?.ruleNumber}/${result.chapterNumber}/${searchText}`
            }
            style={{
              textDecoration: 'none',
              color: 'black',
            }}>
            <div>{`${result.rules[0]?.refCategory.category}`}</div>
            <div>
              <BiSubdirectoryRight size={20} />
              {`[${result.rules[0]?.ruleNumber}] ${result.rules[0]?.ruleName}`}{' '}
            </div>
          </NavLink>
        </td>
        <td align='left' className='tdrulecontent'>
          <NavLink
            to={
              result.rules[0]?.viewType === 'content'
                ? `/ruleinfo/contents/${result.rules[0]?.ruleNumber}/${result.chapterNumber}/${searchText}`
                : `/ruleinfo/mix/${result.rules[0]?.ruleNumber}/${result.chapterNumber}/${searchText}`
            }
            style={{
              textDecoration: 'none',
              color: 'black',
              // fontSize: '10pt',
            }}>
            {parse(`<span>${parsingChapterContent(result)}</span>`)}
          </NavLink>
        </td>
        <td className='text-center'>
          {result.createdAt &&
            timezoneFormat(
              utcToZonedTime(
                toDate(result.createdAt, {
                  timeZone: 'UTC',
                }),
                timezone
              ),
              'yyyy-MM-dd',
              { timeZone: timezone }
            )}
        </td>
      </tr>
    );
  }

  if (result.search === 'chapterName') {
    return (
      <tr>
        <td align='center'>{t('table.chap-title')}</td>
        <td align='left' className='tdrulename'>
          <NavLink
            to={
              result.rules[0]?.viewType === 'content'
                ? `/ruleinfo/contents/${result.rules[0]?.ruleNumber}/${result.chapterNumber}/${searchText}`
                : `/ruleinfo/mix/${result.rules[0]?.ruleNumber}/${result.chapterNumber}`
            }
            style={{
              textDecoration: 'none',
              color: 'black',
            }}>
            <div>{`${result.rules[0]?.refCategory.category}`}</div>
            <div>
              <BiSubdirectoryRight size={20} />
              {`[${result.rules[0]?.ruleNumber}] ${result.rules[0]?.ruleName}`}{' '}
            </div>
          </NavLink>
        </td>
        <td align='left' className='tdrulecontent'>
          <NavLink
            to={
              result.rules[0]?.viewType === 'content'
                ? `/ruleinfo/contents/${result.rules[0]?.ruleNumber}/${result.chapterNumber}/${searchText}`
                : `/ruleinfo/mix/${result.rules[0]?.ruleNumber}/${result.chapterNumber}`
            }
            style={{
              textDecoration: 'none',
              color: 'black',
              // fontSize: '10pt',
            }}>
            {parse(`<span>${parsingChapterName(result)}</span>`)}
          </NavLink>
        </td>
        <td className='text-center'>
          {result.createdAt &&
            timezoneFormat(
              utcToZonedTime(
                toDate(result.createdAt, {
                  timeZone: 'UTC',
                }),
                timezone
              ),
              'yyyy-MM-dd',
              { timeZone: timezone }
            )}
        </td>
      </tr>
    );
  }

  return (
    <tr>
      <td align='center'>{t('table.rule-title')}</td>
      <td align='left'>{`[${result.ruleNumber}] ${result.ruleName}`}</td>
      <td align='left'>
        <NavLink
          to={
            result.viewType === 'contents'
              ? `/ruleinfo/contents/${result.ruleNumber}/${searchText}`
              : `/ruleinfo/mix/${result.ruleNumber}/${searchText}`
          }
          style={{ textDecoration: 'none', color: 'black' }}>
          {parse(parsingRuleResult(result, searchText))}
        </NavLink>
      </td>
      <td className='text-center'>
        {timezoneFormat(
          utcToZonedTime(
            toDate(result.revisedAt, {
              timeZone: 'UTC',
            }),
            timezone
          ),
          'yyyy-MM-dd',
          { timeZone: timezone }
        )}
      </td>
    </tr>
  );
};

const RuleSearch = () => {
  const { searchText } = useParams();
  const axiosAuth = useAxiosAuth();
  const { t } = useTranslation();
  const [resultType, setResultType] = useState('all');
  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chapCount, setChapCount] = useState(0);
  const [chapNameCount, setChapNameCount] = useState(0);
  const [ruleCount, setRuleCount] = useState(0);
  const tabSlide = newPosition => {
    const toTranslate = 100 * newPosition;
    const elem = document.querySelector('span.rulesearch__tabitem__glide');
    elem.style.transform = `translateX(${toTranslate}%)`;
  };
  useEffect(() => {
    const searchRule = async () => {
      setResultType('all');
      tabSlide(0);
      setIsLoading(true);
      const response = await axiosAuth({
        method: 'POST',
        url: '/api/rule/searchrule',
        data: { searchText },
      });
      const ruleArr = response.data.filter(re => re.search === 'rule');
      const chapArr = response.data.filter(
        re => re.search === 'chapterContent'
      );
      const chapNameArr = response.data.filter(
        re => re.search === 'chapterName'
      );
      setRuleCount(ruleArr.length);
      setChapCount(chapArr.length);
      setChapNameCount(chapNameArr.length);
      setData(response.data);
      setResults(response.data);
      setIsLoading(false);
    };
    searchRule();
  }, [searchText]);

  const handleresultType = index => {
    const statusArr = ['all', 'rule', 'chapterName', 'chapterContent'];
    const statusStr = statusArr[index];
    setIsLoading(true);
    setResultType(statusStr);
    tabSlide(index);
    if (statusStr === 'all') {
      setResults(data);
      setIsLoading(false);
    } else {
      const resultsCopy = data.filter(el => el.search === statusStr);
      setResults(resultsCopy);
      setIsLoading(false);
    }
  };

  return (
    <div className='rulesearch mainsection'>
      <div className='rulesearch__header'>
        <div className='rulesearch__header__tabs'>
          <span
            className={`rulesearch__tabitem ${
              resultType === 'all' ? 'open' : ''
            }`}
            role='button'
            aria-hidden='true'
            onClick={() => handleresultType(0)}>
            {t('components.ruleInfo.integrated-search')}
            <Badge bg='secondary'>{data.length}</Badge>
          </span>
          <span
            className={`rulesearch__tabitem ${
              resultType === 'ruletitle' ? 'open' : ''
            }`}
            role='button'
            aria-hidden='true'
            onClick={() => handleresultType(1)}>
            {t('components.ruleInfo.rule-title')}
            <Badge bg='secondary'>{ruleCount}</Badge>
          </span>
          <span
            className={`rulesearch__tabitem ${
              resultType === 'rulecontent' ? 'open' : ''
            }`}
            role='button'
            aria-hidden='true'
            onClick={() => handleresultType(2)}>
            {t('components.ruleInfo.chap-title')}
            <Badge bg='secondary'>{chapNameCount}</Badge>
          </span>
          <span
            className={`rulesearch__tabitem ${
              resultType === 'rulecontent' ? 'open' : ''
            }`}
            role='button'
            aria-hidden='true'
            onClick={() => handleresultType(3)}>
            {t('components.ruleInfo.rule-content')}
            <Badge bg='secondary'>{chapCount}</Badge>
          </span>
          <span className='rulesearch__tabitem__glide' />
        </div>
      </div>
      <div className='rulesearch__main'>
        <div className='rulesearch__main__tablecontainer'>
          <table className='rulesearch__main__table'>
            <thead className='rulesearch__main__table__header'>
              <tr>
                <th width='10%'>{t('table.division')}</th>
                <th width='26%'>{t('table.rule-title')}</th>
                <th width='54%'>{t('table.search-result')}</th>
                <th width='10%'>{t('table.enactAmend')}</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className='rulesearch__main__loading'>
                <tr>
                  <td colSpan='7'>
                    <div className='rulesearch__main__loading__spinner'>
                      <Spinner
                        animation='border'
                        role='status'
                        style={{
                          width: '4rem',
                          height: '4rem',
                        }}>
                        <span className='visually-hidden'>Loading...</span>
                      </Spinner>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className='rulesearch__main__table__body'>
                {results &&
                  results.map((result, i) => (
                    <ResultTr
                      key={`${result._id + i}`}
                      result={result}
                      index={i}
                      searchText={searchText}
                    />
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default RuleSearch;
