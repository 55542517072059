import { BiX } from 'react-icons/bi';
import React from 'react';
import {
  BsFiletypeDoc,
  BsFiletypeDocx,
  BsFiletypeXls,
  BsFiletypeXlsx,
} from 'react-icons/bs';
import { RiDownload2Fill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { axiosAuth } from '../../common/axios/api';

const TeamManualHistory = ({ setOpen, oldManual, manualName }) => {
  const { t } = useTranslation();
  const { orgId } = useParams();
  const handleHistory = () => {
    setOpen('');
  };

  const downloadManualOne = async m => {
    try {
      const resDown = await axiosAuth({
        method: 'POST',
        url: '/api/manual/download',
        responseType: 'blob',
        data: {
          url: m.url,
        },
      });
      const blob = new Blob([resDown.data]);
      const fileName = `${m.manualNumber.replace(orgId, '').replace('-', '')} ${
        m.manualName
      }.${m.docType}`;
      const downUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downUrl);
    } catch (err2) {
      console.log(`error resDown : ${err2}`);
    }
  };

  return (
    <div className='manualHistory'>
      <div className='manualHistory__header'>
        <div>{manualName}</div>
        <BiX size={22} onClick={handleHistory} />
      </div>
      <div className='manualHistory__body'>
        {/* 이전 기록 파일 다운로드 */}
        {oldManual && oldManual.length !== 0 ? (
          oldManual.map(el => (
            <div key={el._id}>
              <span title={el.manualName}>
                {' '}
                {(() => {
                  if (el.docType === 'doc') {
                    return <BsFiletypeDoc size={22} />;
                  }
                  if (el.docType === 'docx') {
                    return <BsFiletypeDocx size={22} />;
                  }
                  if (el.docType === 'xlsx') {
                    return <BsFiletypeXlsx size={22} />;
                  }
                  return <BsFiletypeXls size={22} />;
                })()}
                {el.manualName}
              </span>
              <span>{el.createdAt.slice(0, 10)}</span>
              <span>
                <Button
                  variant='secondary'
                  onClick={() => downloadManualOne(el)}>
                  <RiDownload2Fill size={20} />
                </Button>
              </span>
            </div>
          ))
        ) : (
          <div
            style={{
              color: '#ccc',
            }}>
            {t('components.teamManual.no-record')}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamManualHistory;
