import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import '../scss/MainFooter.scss';

const MainFooter = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <span>{t('footer.version')}</span>
      <span className='footer__split'>|</span>
      <span>{t('footer.edmsdocs')}</span>
    </footer>
  );
};

export default MainFooter;
