import React, { createContext, useState, useMemo } from 'react';

const EntireContext = createContext({ newContent: '', oldContent: '' });

export const EntireProvider = ({ children, initialState }) => {
  const [entire, setEntire] = useState(initialState);
  const value = useMemo(() => ({ entire, setEntire }), [entire, setEntire]);

  return (
    <EntireContext.Provider value={value}>{children}</EntireContext.Provider>
  );
};

export default EntireContext;
